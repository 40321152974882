<template>
    <section class="component-text -outs section" data-pnp-half-map="true" data-pnp-interactive-map="true">
        <div class="left-panel no-padding">
            <div class="inner alternative" :data-pnp-heading="sectionTitle" v-if="hasDefaultSlot">
                <slot></slot>
            </div>

            <div class="suggested alternative" v-if="type === 'experience' && type !== 'contact' && item"
                :class="{ '-no-padding': !hasDefaultSlot }">
                <ul class="terms">
                    <li>
                        <button-internal-presentational
                                icon="ion-ios-arrow-thin-right -arrow-right"
                                :router_object="routerObject(item.title, item.id, 'location')"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Best Alternatives', $route.path)">
                            Best locations for {{ item.title.toLowerCase() }}
                        </button-internal-presentational>
                    </li>

                    <li>
                        <button-internal-presentational
                                icon="ion-ios-arrow-thin-right -arrow-right"
                                :router_object="routerObject(item.title, item.id, 'lodge')"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Best Alternatives', $route.path)">
                            Best lodges for {{ item.title.toLowerCase() }}
                        </button-internal-presentational>
                    </li>

                    <li>
                        <button-internal-presentational
                                icon="ion-ios-arrow-thin-right -arrow-right"
                                :router_object="{name: 'safari-trip-collection'}"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Best Alternatives', $route.path)">
                            Sample trips
                        </button-internal-presentational>
                    </li>
                </ul>
            </div>

            <div class="suggested alternative " v-else-if="type === 'best' && type !== 'contact' && item"
                 :class="{ '-no-padding': !hasDefaultSlot }">
                <ul class="terms">
                    <li>
                        <button-internal-presentational
                                icon="ion-ios-arrow-thin-right -arrow-right"
                                :router_object="routerObject(item.title, item.id, 'lodge')"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Best Alternatives', $route.path)">
                            Best lodges in {{ item.title }}
                        </button-internal-presentational>
                    </li>

                    <li v-if="parents[0].title !== item.title" >
                        <button-internal-presentational
                                icon="ion-ios-arrow-thin-right -arrow-right"
                                :router_object="routerObject(item.title, item.id, 'lodge')"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Best Alternatives', $route.path)">
                            Best lodges in {{ parents[0].title }}
                        </button-internal-presentational>
                    </li>

                    <li>
                        <button-internal-presentational
                                icon="ion-ios-arrow-thin-right -arrow-right"
                                :router_object="routerObject(item.title, item.id, 'trip')"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Best Alternatives', $route.path)">
                            Best trips including {{ item.title }}
                        </button-internal-presentational>
                    </li>

                    <li v-if="parents[0].title !== item.title" >
                        <button-internal-presentational
                                icon="ion-ios-arrow-thin-right -arrow-right"
                                :router_object="routerObject(item.title, item.id, 'trip')"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Best Alternatives', $route.path)">
                            Best trips including {{ parents[0].title }}
                        </button-internal-presentational>
                    </li>
                </ul>
            </div>

            <div class="suggested alternative" v-if="show && type !== 'best' && type !== 'experience' && type !== 'contact'"
                 :class="{ '-no-padding': !hasDefaultSlot }">
                <ul class="terms">
                    <template v-if="parents.length > 0">
                        <li v-for="out in outs" v-if="!_.isEmpty(out)">

                            <button-internal-presentational
                                    icon="ion-ios-arrow-thin-right -arrow-right"
                                    :router_object="routerObject(out.title, out.id, outType)"
                                    v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Best Alternatives', $route.path)">

                                {{ title(out.title) }}

                            </button-internal-presentational>

                        </li>
                    </template>
                </ul>
            </div>

            <div class="suggested alternative" v-if="type === 'contact'">
                <ul class="terms">
                    <li>
                        <button-internal-presentational
                                icon="ion-ios-arrow-thin-right -arrow-right"
                                :router_object="{ name: 'contact-call' }"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Contact Call Us', $route.path)">
                            Call us
                        </button-internal-presentational>
                    </li>
                    <li>
                        <button-internal-presentational
                                icon="ion-ios-arrow-thin-right -arrow-right"
                                :router_object="{ name: 'call-back' }"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Contact Call Back', $route.path)">
                            Get us to call you
                        </button-internal-presentational>
                    </li>
                    <li>
                        <button-internal-presentational
                                icon="ion-ios-arrow-thin-right -arrow-right"
                                :router_object="{ name: 'quote' }"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Contact Inquiry', $route.path)">

                            Make a travel inquiry

                        </button-internal-presentational>
                    </li>
                    <!--<li>-->
                        <!--<button-internal-presentational-->
                                <!--icon="ion-ios-arrow-thin-right -arrow-right"-->
                                <!--:router_object="{ name: 'email' }"-->
                                <!--v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Contact Email', $route.path)">-->
                            <!--Send us an email-->
                        <!--</button-internal-presentational>-->
                    <!--</li>-->
                </ul>
            </div>

            <div class="suggested alternative" v-if="type === 'next-experience'">
                <ul class="terms">
                    <li>
                        <button-internal-presentational
                                icon="ion-ios-arrow-thin-right -arrow-right"
                                :router_object="{ path: hardcodedUrls.finder.experiences }"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Explore More', $route.path)">
                            Explore experiences
                        </button-internal-presentational>
                    </li>
                    <li>
                        <button-internal-presentational
                                icon="ion-ios-arrow-thin-right -arrow-right"
                                :router_object="{ path: hardcodedUrls.finder.locations }"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Explore More', $route.path)">
                            Explore locations
                        </button-internal-presentational>
                    </li>
                    <li>
                        <button-internal-presentational
                                icon="ion-ios-arrow-thin-right -arrow-right"
                                :router_object="{ path: hardcodedUrls.finder.lodges }"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Explore More', $route.path)">
                            Explore lodges
                        </button-internal-presentational>
                    </li>
                    <li>
                        <button-internal-presentational
                                icon="ion-ios-arrow-thin-right -arrow-right"
                                :router_object="{ path: hardcodedUrls.finder.trips }"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Explore More', $route.path)">
                            Explore trips
                        </button-internal-presentational>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
    import GlobalVars from '../../../global/GlobalVars';

    export default {
        props: {
            item: {
                type: Object,
                required: false
            },
            type: {
                type: String,
                required: true
            },
            sectionTitle: {
                type: String,
                required: true
            },
            prefix: {
                type: String,
                default: '',
                required: false
            },
            outType: {
                type: String,
                default: '',
                required: false
            },
            listType: {
                type: String,
                default: '',
                required: false
            }
        },
        methods: {
            routerObject(title, id, type){
                if(this.type !== 'experience') {
                    return {
                        name: 'finder',
                        query: {
                            type: type ? type : this.type,
                            location: id,
                            lname: title,
                            experience: 3,
                            ename: 'Any Experience'
                        }
                    }
                } else {
                    return {
                        name: 'finder',
                        query: {
                            type: type ? type : this.type,
                            location: 0,
                            lname: 'Africa',
                            experience: id,
                            ename: title,
                        }
                    }
                }

            },
            title(title) {
                return this.prefix ? `${this.prefix} ${title}` : title;
            }
        },
        computed: {
            _() {
                return _;
            },
            hardcodedUrls() {
                return GlobalVars.HARCODED_URLS;
            },
            googleAnalytics() {
                return window.GoogleAnalytics;
            },
            analyticsEventCategory() {
                return 'Add ' + window.GoogleAnalytics.parseVueRouteParams(this.$route.params);
            },
            show(){
                if(this.item){
                    return this.item.parents;
                }

                return false;
            },
            outs(){
                if(!this.parents){
                    return [];
                }

                let parents = this.item.parents.data;


                let firstItem = parents[0];

                let lastItem = parents[parents.length - 1];


                let criticalArrayLength = parents.filter((el) => el.critical).length;
                    criticalArrayLength = (criticalArrayLength - 1) < 0 ? 0 : criticalArrayLength - 1;

                let criticalItem = parents.filter((el) => {
                    return el.critical == true && (el.title !== lastItem.title || el.title !== firstItem.title)
                });

                    criticalItem = criticalItem[criticalArrayLength] !== undefined ? criticalItem[criticalArrayLength] : {};

                let outs = [
                    lastItem,
                    criticalItem,
                    firstItem
                ];

                let africa = {
                    title: 'all countries',
                    slug: 'africa-safari',
                    type:  this.type ? this.type : 'location',
                    id: 0
                };

                if(this.type === 'trip') {
                    return _.uniq([...outs]);
                } else if(this.type === 'location' && this.listType == 'full') {
                    return _.uniq([...outs, africa]);
                } else if(this.type === 'location'){
                    return _.uniq([firstItem, africa]);
                } else {
                    return _.uniq([...outs, africa]);
                }
            },
            parents(){
                if(this.item && this.item.parents !== undefined) {
                    return this.item.parents.data;
                }
                return [];
            },
            hasDefaultSlot () {
                return !!this.$slots.default
            }
        },
        mounted(){
        }
    }
</script>
