<template>
    <portal to="modals" v-if="open">
        <!--<transition name="fade-in" mode="out-in">-->
            <div :key="id" :class="classes">
                <div class="modal-inner">
                    <slot></slot>
                    <div class="burger-container modal-close-btn" @click="closeModal"><span class="burger -in"><i class="-nav-in"></i></span></div>
                </div>
            </div>
        <!--</transition>-->
    </portal>
</template>

<script>
    export default {
        props:['open', 'classes', 'id'],
        methods: {
            closeModal(){
                this.$emit('close_modal');
            }
        }
    }
</script>