<template>
    <div class="component-image -gallery -large-caption"
         :class="classes"
         :data-pnp-showing-gallery="slides.length > 0"
         :data-pnp-hidden="hidden"
         :data-pnp-see-more="see_more"
         :data-pnp-see-all="see_all"
         :data-block-id="_id"
         :data-pnp-internal-name="internal_name">

        <vignette-presentational :img_url="bg_img_url" :bg_img_url_mobile="bg_img_url_large" css_class="lazy-section" :alt_text="getAltText"></vignette-presentational>

        <div class="caption" :data-pnp-heading="name" v-if="isSlotEmpty">
            <p class="sub-heading" v-html="content" :contenteditable="editing" :data-block-id="_id" v-if="edit == false"></p>

            <div class="on-page-editor-container" v-else>
                <p class="sub-heading" v-html="content" :contenteditable="editing" :data-block-id="_id"></p>

                <div class="smallEditButtons">
                    <div v-on:click="createEditor()" class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton"></div>
                    <div v-on:click="saveEditor()" class="icon ion-ios-checkmark onPageEditor-smallButton btn-success smallSaveButton" v-if="editing==true"></div>
                    <div v-on:click="cancelEditor()" class="icon ion-ios-close onPageEditor-smallButton btn-danger smallCancelButton" v-if="editing==true"></div>
                </div>
            </div>

            <div class="buttons" :data-for-section="_id" :data-slide-count="slides.length">
                <div class="on-page-editor-container" v-if="edit==true">
                    <buttons-loop :firstVideo="firstVideo" :buttons="buttons" :type="type + '_section'" :edit="edit" :section_id="_id"></buttons-loop>

                    <div class="smallEditButtons">
                        <div data-toggle="modal" :data-target="'#buttonModal_' + _id"
                             class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton addEditButton"
                             :data-block-id="_id"></div>
                    </div>
                </div>

                <buttons-loop :firstVideo="firstVideo" :buttons="buttons" v-else :type="type + '_section'" :edit="edit" :section_id="_id"></buttons-loop>
            </div>
        </div>

        <div v-else>
            <slot></slot>
        </div>

        <template v-for="(slide, index) in computedSlides">
            <slide-container :firstVideo="firstVideo"
                             :slide="slide"
                             :section_id="_id"
                             :section_name="name"
                             :edit="edit"
                             :item_title="item_title"
                             :slide_index="index"
                             :type="type + '_slide'">
            </slide-container>
        </template>

        <template v-for="(action,index) in actions">
            <modal-presentational
                    v-if="action.type != 'seasonality'"
                    :div_id="action.action"
                    :key="action.action+'_'+action.media+'_'+index"
                    :article_editor="true"
                    :data_key="action.media"
                    :for_section_id="_id">
                <article-presentational
                        :title="action.article.title"
                        :content="action.article.content"
                        class="row info-window"
                        :_id="action.action"
                        :media_key="action.media"
                        :type="type +'_section'"
                        :edit="edit"
                        :section_id="_id"
                >
                </article-presentational>
            </modal-presentational>

            <!--<modal-presentational v-else-->
                                  <!--:div_id="action.action"-->
                                  <!--:key="action.action"-->
                                  <!--:article_editor="true"-->
                                  <!--:data_key="action.media"-->
                                  <!--:type="type +'_section'"-->
                                  <!--:edit="edit"-->
                                  <!--:for_section_id="_id">-->
                <!--<public-seasonality></public-seasonality>-->
            <!--</modal-presentational>-->
        </template>
    </div>
</template>

<script>
    import StateUtils from '../../../global/StateUtils';

    export default {
        props: {
            'lodge_content': {},
            'bg_img_url': {},
            'bg_img_url_large': {},
            'hidden': {},
            'see_more': {},
            'see_all': {},
            'slides': {
                default: () => {
                    return {};
                }
            },
            'heading': {},
            'content': {},
            '_id': {},
            'buttons': {},
            'internal_name': {},
            'actions': {},
            'name': {},
            'classes': {},
            'edit': {},
            'type': {},
            'item_title': {},
            'firstVideo': {},
        },
        data() {
            return {
                editing: false,
                editorName: null
            }
        },
        computed: {
            isSlotEmpty() {
                return _.isEmpty(this.$slots);
            },
            getAltText() {
                return this.item_title + ', ' + this.name;
            },
            computedSlides(){
                return this.slides;
            }
        },
        methods: {
            createEditor() {
                if(!window._.isUndefined(this.editorName) && this.editorName!==null){
                    alert('You are currently editing a different bit on the page.\nSave or Cancel it before starting editing on a new instance.');
                } else {
                    this.editing = true;
                    Vue.nextTick(() => {
                        var editor = CKEDITOR.inline(this.$el.querySelector('.on-page-editor-container .sub-heading'), window.ckeditorConfig);
                        this.$el.querySelector('.on-page-editor-container .sub-heading').click();
                        this.editorName = editor.name;
                        this.$store.commit(window.store.SET_EDITOR_CKEDITOR, true, { root: true });
                    });
                }
            },
            cancelEditor() {
                this.editing = false;
                if (this.editorName !== null) {
                    CKEDITOR.instances[this.editorName].container.$.innerHTML = this.content;
                    CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;
                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, { root: true });
                }
            },
            saveEditor() {
                this.editing = false;
                if (this.editorName != '') {
                    var stateObject = new StateUtils(this.type +'_section', this.$store).getStateObject();
                    let newContent = CKEDITOR.instances[this.editorName].container.$.innerHTML;

                    if(stateObject!==null){
                        for (var x in stateObject.allSections.data) {
                            if (stateObject.allSections.data[x].id == this._id) {
                                stateObject.allSections.data[x].content = newContent;
                            }
                        }
                        new StateUtils(this.type +'_section', this.$store).setStateObject(stateObject, null);
                    }
                    CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;
                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, { root: true });
                }
            }
        }
    }
</script>