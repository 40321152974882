<template>
    <a ref="btn" :class="buttonClasses" :data-style="data_style" @click="clicked">
        <i v-if="icon != false && is_icon_left" :class="icon"></i>
        <slot></slot>
        <i v-if="icon != false && !is_icon_left" :class="icon"></i>
    </a>
</template>


<script>
    export default {
        props: ['data_style', 'classes', 'icon', 'is_icon_left', 'type', 'transition_name', 'router_object', 'has_back_button'],
        computed: {
            buttonClasses(){
                if(this.classes == '') {
                    return 'btn -read-more -animated';
                } else if(this.classes=='-with-arrow') {
                    return 'btn -read-more -animated -with-arrow';
                } else {
                    return this.classes;
                }
            }
        },
        methods: {
            clicked(){
                if (!_.isUndefined(this.transition_name)) {
                    this.$store.commit(window.store.SET_TRANSITION, this.transition_name);
                }

                if(this.has_back_button){
                    window.bus.$emit('setShowBackButton', true);
                }

                this.$emit('btn-internal-clicked');

                if(!_.isUndefined(this.router_object)) {
                    this.$router.push(this.router_object);
                }
            }
        },
        mounted() {
            if (window.location.href.indexOf('prerendering=true') !== -1) {
                this.$refs.btn.setAttribute('href', this.$router.resolve(this.router_object).href);
            }
        }
    }
</script>
