<template>
    <section class="component-text section fullpage-next-up"
             :class="classes"
             :data-pnp-next-lodge="data_next_lodge"
             data-pnp-interactive-map="true"
             >

        <!--<div class="inner text-center"-->
             <!--:data-pnp-heading="data_heading"-->
             <!--v-if="(!this.directory) && (isMoreMapItems || (this.page === 'resource' && this.nextResult))">-->

            <!--&lt;!&ndash;@mouseenter='highlightNextMapItem'&ndash;&gt;-->
            <!--&lt;!&ndash;@mouseleave='unHighlightNextMapItem'&ndash;&gt;-->

            <!--<span-->
                  <!--@click="nextItemIsLoading">-->
                <!--<button-internal-presentational-->
                        <!--:router_object="routerObject"-->
                        <!--classes="heading4"-->
                <!--&gt;-->
                    <!--<p class="sub-text -light" v-if="!loading">{{ text_next_item }}</p>-->
                    <!--<p class="sub-text -light" v-else>LOADING ...</p>-->

                    <!--<span :class="{'link-disabled': loading}">{{this.nextResult.title}}</span>-->

                <!--</button-internal-presentational>-->
            <!--</span>-->
        <!--</div>-->

        <div class="inner text-center"
             :data-pnp-heading="data_heading">

            <button-internal-presentational
                    :router_object="routerObject"
                    classes="heading4">
                <!--<span class="sub-text -light">Next</span>-->
                {{text_explore}}
            </button-internal-presentational>
        </div>
    </section>
</template>

<script>
    import GlobalVars from '../../../global/GlobalVars';
    import {mapState} from 'vuex';

    export default {
        props: [
            'data_heading',
            'data_next_lodge',
            'next_url',
            'text_next_item',
            'text_explore',
            'classes',
            'page',
            'item',
            'directory'
        ],
        data(){
            return {
                loading: false
            }
        },
        methods: {
            toggleNextMapItem(show){
                if(show){
                    if(this.isMoreMapItems) {
                        window.pnpHelper.map.showNextMapItem(this.nextResult.lng,this.nextResult.lat, this.nextResult.id);
                    }else{
                        //Just center the map on the current map item (lodge or location)
                        window.pnpHelper.map.centerOnMarker();
                    }
                }else{
                    if(this.isMoreMapItems) {
                        this.unHighlightNextMapItem();
                        window.pnpHelper.map.hideNextMapItem(this.nextResult.id);
                    }
                }
            },
            highlightNextMapItem(){
                window.pnpHelper.map.focusOnMarker(this.nextResult.id);
            },
            unHighlightNextMapItem(){
                window.pnpHelper.map.focusOnMarker(this.item.id);
            },
            nextItemIsNotLoading(){
                this.loading = false;
            },
            nextItemIsLoading(){
                this.loading = true;

                $('body').addClass('-disable-page-wipe');
            }
        },
        computed: {
            searchResults(){
                return this.$store.state.search.results.data;
            },
            type(){
                return this.$store.state.search.criteria.params.type;
            },
            currentIndex(){
                if(this.searchResults && this.page !== 'resource'){
                    return this.searchResults.findIndex(x => x.title == this.item.title);
                }
            },
            isMoreMapItems(){
                return this.currentIndex >= 0 && this.currentIndex < (this.searchResults.length-1);
            },
            nextResult(){
                if(this.page === 'resource'){
                    for (let key in this.searchResults) {
                        let childrenArr =  this.searchResults[key].children;

                        let index = childrenArr.findIndex(x => {
                            return x.title == this.item.title
                        });

                        if(index >= 0) {
                            return childrenArr[index + 1];
                        }
                    }
                } else {
                    if(this.searchResults && this.searchResults[this.currentIndex + 1]){
                        return this.searchResults[this.currentIndex + 1];
                    } else {
                        return []
                    }
                }
            },
            routerObject(){
                return {
                    name: 'finder',
                    params : this.params
                }


                // if(!this.isMoreMapItems){
                //     if(this.page === 'location'){
                //         return {path: GlobalVars.HARCODED_URLS.finder.locations};
                //     } else if(this.page === 'lodge') {
                //         return {path: GlobalVars.HARCODED_URLS.finder.lodges};
                //     } else if(this.page === 'experience') {
                //         return {path: GlobalVars.HARCODED_URLS.finder.experiences};
                //     }else if(this.page === 'resource') {
                //         return {path: GlobalVars.HARCODED_URLS.finder.resources};
                //     }
                //
                //     return {name: 'finder'};
                //
                // } else {
                //     if(this.nextResult.vue_route_params){
                //         return {
                //             name: `single-${this.type}`,
                //             ...this.nextResult.vue_route_params,
                //             params: {
                //                 ...this.nextResult,
                //                 ...this.nextResult.vue_route_params.data
                //             }
                //         }
                //     }
                //
                //     return {
                //         name: `single-${this.type}`,
                //         ...this.nextResult,
                //         params: {
                //             ...this.nextResult,
                //         }
                //     }
                // }
            },
            ...mapState({
                params: state => state.search.criteria.params
            })
        },
        mounted(){
          //console.log(this.directory)
        },
        created(){
            window.bus.$on('showNextMapItem', this.toggleNextMapItem);
            window.bus.$on('fullPageInit', this.nextItemIsNotLoading);
        },
        destroyed() {
            window.bus.$off('showNextMapItem', this.toggleNextMapItem);
            window.bus.$on('fullPageInit', this.nextItemIsNotLoading);
        }
    }
</script>
