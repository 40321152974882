<template>
    <div>
        <div class="fp-bg full-size pnp-bg vignette vignette1" v-bind="attributes"></div>

        <div class="caption -first-slide">
            <h2 class="heading1 heading" data-pnp-heading>{{ title }}</h2>
            <p class="sub-heading" v-html="text"></p>

            <p class="buttons" v-for="button in buttons">
                <button-presentational :url="button.url"
                             :text="button.text"
                             :is_external_link="button.is_external_link"
                             :classes="button.classes">
                </button-presentational>
            </p>
        </div>

        <div v-if="is_last_slide" class="caption final-slide text-center">
            <p class="sub-text">{{ last_slide_text }}</p>
            <a rel="nofollow" href="#" class="link">continue</a>
        </div>


        <div v-if="is_last_slide" class="section" data-pnp-skip-sidenav="true"></div>
    </div>
</template>

<script>
    export default {
        props: ['title', 'text', 'buttons', 'lazy_load', 'bg_img_url', 'is_last_slide', 'last_slide_text'],
        computed: {
            attributes: function() {
                var attributes = {};

                if (this.lazy_load == null) {
                    attributes.style = 'background-image: url(' + this.bg_img_url + ')';
                } else {
                    attributes['data-lazy-bg-image'] = this.bg_img_url;
                }

                return attributes;
            }
        }
    }
</script>