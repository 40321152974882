<template>
    <div class="information-popup" :class="position" ref="currency_popup" :style="css">
        <div class="inner-panel">
            <div class="burger-container" @click="currencyChanged">
                <div class="burger -in">
                    <i></i>
                </div>
            </div>

            <div class="">
                <h2 class="panel-header heading2">
                    <slot name="header"></slot>
                </h2>

                <currency-selector @currencyChanged="currencyChanged"></currency-selector>

                <p class="info-popup-content">
                    <slot name="content"></slot>
                </p>
            </div>

        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        data(){
            return {
                position: 'top',
                months: [],
                css: ''
            }
        },
        methods: {
            setValues(value) {
                if(this.searchType === 'trip') {
                    this.$store.commit(window.store.SET_SEARCH_BUDGET_RANGE, value);
                }else{
                    this.$store.commit(window.store.SET_SEARCH_TRIP_BUDGET_RANGE, value);
                }
            },
            currencyChanged(){
                this.$emit('currencyChanged');
            }
        },
        computed: {
            ...mapState({
                rates: state => state.user.user.rates,
                selected: state => state.user.user.selected_currency,
                budgetFrom: state => state.search.criteria.params.budgetFrom,
                budgetTo: state => state.search.criteria.params.budgetTo,
                tripBudgetFrom: state => state.search.criteria.params.tripBudgetFrom,
                tripBudgetTo: state => state.search.criteria.params.tripBudgetTo,
                stateLocation: state => state.singleLocation.location,
                searchType: state => state.search.criteria.params.type
            })
        },
        mounted(){
            let popup = this.$refs.currency_popup.getBoundingClientRect();

            if(pnpHelper.bootstrap.isXs()){

                if($('body').hasClass('page-scroll-hijacked')){
                    this.css = "left:-" + (popup.left - 15) +'px; top:-' + ((popup.height) + 30) + 'px;';
                }else {
                    this.css = "left:-" + (popup.left - 15) + 'px; top:-' + ((popup.top / 3) + 30) + 'px;';
                }
            }else {
                if(popup.top > -15){
                    this.position = 'top';
                } else {
                    this.position = 'bottom';
                }
            }
        }
    }
</script>
