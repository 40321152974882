export default class LinkHashes
{
    static scrollTo(cssSelector, e = null) {
        if (cssSelector == '') {
            return;
        }

        if (e != null) {
            e.preventDefault();
        }

        let el = $('body').hasClass('showing-article-half') && pnpHelper.bootstrap.isMd() ? $('.article-content > .content-panel') : $('html, body');

        // el.scrollTop($(cssSelector).offset().top);
        window.pnpHelper.scroll.doElementScroll(el, $(cssSelector).offset().top);
    }
}