<template>
    <section class="component-text -outs section" data-pnp-half-map="true" data-pnp-interactive-map="true">
        <div class="left-panel no-padding">
            <div class="suggested alternative">
                <ul class="terms">
                    <li>
                        <button-internal-presentational
                                icon="ion-ios-arrow-thin-right -arrow-right"
                                :router_object="{name: 'safari-trip-collection'}"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Best Alternatives', $route.path)">
                            Best trips in all countries
                        </button-internal-presentational>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "BestTripOut",
        computed: {
            googleAnalytics() {
                return window.GoogleAnalytics;
            },
            analyticsEventCategory() {
                return 'Add ' + window.GoogleAnalytics.parseVueRouteParams(this.$route.params);
            }
        }
    }
</script>

<style scoped>

</style>
