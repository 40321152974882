<template>
    <div class="lodge-select" v-bind:class="{ goingBackward : goingBackward}">
        <transition name="lodge-fade" mode="out-in">
            <div
            :key="transition"
            :data-id="lodge.id"
            :data-lat="lodge.lat"
            :data-lng="lodge.lng"
            data-type="lodge"
            class="component-card -sr-single-trip -sr-trip -full-page -sr-lodge -mobile-flip">
                <div>

                    <p class="sub-text -highlight visible-xs"><span class="yellow-bar"></span>Night<span v-if="(typeof lodge.num_nights == 'string')">s</span> {{ lodge.num_nights }}</p>

                    <div class="heading2 heading -lodge visible-xs">
                        {{lodge.title}}
                    </div>

                    <div class="button-fav visible-xs"
                         v-on:click="toggleWishlist(lodge.id)"
                         v-bind:class="{ on: inWishlist(lodge.id) }"
                         aria-label="Add to Wishlist">
                    </div>

                    <!--This reserves space for the slider on mobile-->
                    <img src="/assets/img/placeholder.png" alt="Placeholder" class="visible-xs img-responsive mob-placeholder"/>

                    <p class="sub-text -highlight hidden-xs"><span class="yellow-bar"></span>Night<span v-if="(typeof lodge.num_nights == 'string')">s</span> {{ lodge.num_nights }}</p>

                    <div class="col-caption">
                        <div class="heading2 heading -lodge hidden-xs">
                            {{lodge.title}}
                            <div class="button-fav hidden-xs"
                                 v-on:click="toggleWishlist(lodge.id)"
                                 v-bind:class="{ on: inWishlist(lodge.id) }"
                                 aria-label="Add to Wishlist">
                            </div>
                        </div>

                        <div class="content" v-html="lodge.content"></div>
                    </div>

                    <div class="stats">
                        <info-container classes="col-rating" :value="lodge.rating ? lodge.rating : '0.0'">
                            <template slot="header">
                                <div class="sub-text">LODGE <br/>RATING</div>
                            </template>

                            <template slot="popup-header">
                                <p>Lodge rating</p>
                            </template>

                            <template slot="popup-text">
                                <p>
                                    This number represents the overall rating for this lodge
                                    <span v-if="month <= 0">in any month,</span>
                                    <span v-else>in {{ GlobalVars.MONTHS[month - 1] }},</span>
                                    which takes into account all the various component ratings of a property, its location,
                                    build, style, service level and experiences.
                                </p>
                            </template>
                        </info-container>

                        <div class="col-price">
                            <price :price="lodge.price">
                                <template slot="header">
                                    <span class="sub-text">
                                        GUIDE PRICE <br/>{{ user.selected_currency}} PPPN
                                    </span>
                                </template>

                                <template slot="popup-header">
                                    <p>Lodge guide price</p>
                                </template>

                                <template slot="popup-content">
                                    <span v-if="month <= 0">This number represents the average price per person per night in any month to stay at this lodge. </span>
                                    <span v-else>This number represents the average price per person per night in {{ GlobalVars.MONTHS[month - 1] }} to stay at this lodge. </span>

                                    It includes the cost of accommodation, the usual meals, drinks and activities, transport costs and park fees.
                                </template>
                            </price>
                        </div>

                        <!--<info-container v-if="route.name !== 'directory' && route.name !== 'wishlist' && trip.show_safari_rating == true && lodge.safari_rating > 0"-->
                                        <!--classes="col-rating"-->
                                        <!--:value="lodge.safari_rating">-->

                            <!--<template slot="header">-->
                                <!--<div class="sub-text">{{ trip.safari_rating_name }} <br/>RATING</div>-->
                            <!--</template>-->

                            <!--<template slot="popup-header">-->
                                <!--<p>Safari rating</p>-->
                            <!--</template>-->

                            <!--<template slot="popup-text">-->
                                <!--<p>-->
                                    <!--This number represents the rating for Safari at this lodge in-->
                                    <!--<span v-if="month <= 0">any month</span>-->
                                    <!--<span v-else>{{ GlobalVars.MONTHS[month - 1] }}</span>-->
                                    <!--and should be a good indication of the level of experience that you can expect when visiting at that time.-->
                                <!--</p>-->
                            <!--</template>-->
                        <!--</info-container>-->

                        <info-container v-if="lodge.conservation_rating >= 7"
                                        classes="col-rating"
                                        :value="lodge.conservation_rating">

                            <template slot="header">
                                <div class="sub-text">CONSERVATION <br/>RATING</div>
                            </template>

                            <template slot="popup-header">
                                <p>Location conservation rating</p>
                            </template>

                            <template slot="popup-text">
                                <p>
                                    The conservation rating is an indication of how much a particular lodge needs your visit in
                                    <span v-if="month <= 0">any month</span>
                                    <span v-else>{{ GlobalVars.MONTHS[month - 1] }}</span>
                                    to further the protection of their landscapes and wildlife. We recommend that you try to
                                    include at least one lodge with a high conservation rating into your trip if you can.
                                </p>
                            </template>
                        </info-container>
                    </div>

                    <public-search-image-slider type="lodge"
                                                v-if="lodge.images"
                                                :cover-image="lodge.images.sizes.low"
                                                :caption="lodge.images.caption"
                                                :id="Number(lodge.id)"
                    ></public-search-image-slider>

                    <div class="sr-footer lodge-select">
                        <!--<div class="btn-group" v-if="lodges.length > 1">-->
                            <!--<button-external-presentational-->
                                    <!--:class="{ 'not-active': btnActive.notActiveBack }"-->
                                    <!--icon="ion-ios-arrow-thin-left"-->
                                    <!--@click="lodgeBack">-->
                            <!--</button-external-presentational>-->

                            <!--<button-external-presentational-->
                                    <!--:class="{ 'not-active': btnActive.notActiveForward }"-->
                                    <!--icon="ion-ios-arrow-thin-right"-->
                                    <!--@click="lodgeForward">-->
                            <!--</button-external-presentational>-->

                            <!--<div class="text">Change Lodge</div>-->
                        <!--</div>-->

                        <!--<div class="btn-container">-->
                            <!--<button-internal-presentational :router_object="{ name: 'single-lodge', params: lodge.vue_route_params['data'] }" classes="sr-cta -arrow">-->
                                <!--<div>Go to Lodge</div>-->
                                <!--<i class="ion-ios-arrow-thin-right"></i>-->
                            <!--</button-internal-presentational>-->
                        <!--</div>-->

                        <div class="btn-container">
                            <button-internal-presentational :router_object="{ name: 'single-lodge', params: lodge.vue_route_params }" classes="sr-cta -arrow">
                                <div>Go to lodge</div>
                                <i class="ion-ios-arrow-thin-right"></i>
                            </button-internal-presentational>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <hr>
    </div>
</template>

<script>
    import GlobalVars from '../../../global/GlobalVars';
    import { mapState } from 'vuex';
    export default {
        props: ['locationId', 'trip', 'lodge', 'month'],
        data(){
            return {
                GlobalVars: GlobalVars,
                transition: false,
                selectedItems: [],
                lodgeNumber: 0,
                goingBackward: false,
                btnActive: {
                    notActiveBack: false,
                    notActiveForward: false
                }
            }
        },
        methods: {
            toggleWishlist(id){
                let payload = {
                    id: id,
                    type: 'lodge'
                };

                if (this.inWishlist(id)) {
                    this.$store.commit(window.store.REMOVE_WISHLIST_ITEM, payload);
                } else {
                    this.$store.dispatch(window.store.SET_WISHLIST_ITEM_BY_ID, payload);
                }
            },
            inWishlist(id){
                return this.wishlist.some((el) => {
                    return el.id == id;
                });
            }
            // lodgeBack(){
            //     if(this.lodgeNumber > 0) {
            //         this.lodgeNumber--;
            //         this.goingBackward = true;
            //         this.transition = !this.transition;
            //
            //         this.coordChange();
            //
            //         // this.$store.commit(window.store.SET_LODGE_TABLE, {
            //         //     'locationId': this.locationId,
            //         //     'lodgeIndex': this.lodgeNumber
            //         // });
            //     }
            //
            //     this.btnActiveCheck();
            // },
            // lodgeForward(){
            //     if(this.lodgeNumber < (this.totalLodges - 1)) {
            //         this.lodgeNumber += 1;
            //         this.goingBackward = false;
            //         this.transition = !this.transition;
            //
            //         this.coordChange();
            //
            //         // this.$store.commit(window.store.SET_LODGE_TABLE, {
            //         //     'locationId': this.locationId,
            //         //     'lodgeIndex': this.lodgeNumber
            //         // });
            //     }
            //
            //     this.btnActiveCheck();
            // },
            // coordChange(){
            //     let lat =  this.lodges[this.lodgeNumber].lat;
            //     let lng = this.lodges[this.lodgeNumber].lng;
            //     pnpHelper.map.centerOnCoords([lng, lat], 13);
            // },
            // btnActiveCheck(){
            //     this.btnActive.notActiveBack = (this.lodgeNumber == 0);
            //     this.btnActive.notActiveForward = (this.lodgeNumber == (this.totalLodges - 1));
            // }
        },
        computed: {
            ...mapState({
                searchType: state => state.search.criteria.params.type,
                wishlist: state => state.wishlist.selected,
                searchLocation: state => state.search.criteria.params.location.name,
            }),
            user(){
                return this.$store.state.user.user;
            },
            route(){
                return this.$route;
            }
        },
        mounted() {
            // this.btnActiveCheck();
        }
    }
</script>