export default class GoogleAnalytics
{
    _init = false;
    analyticsId = null;

    constructor(analyticsId) {
        this.analyticsId = analyticsId;
    }

    init() {
        if (this.analyticsId == null) {
            console.error('Cannot initialise Google Analytics because no ID has been provided');
            return;
        }

        if (this._init === true) {
            return;
        }

        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', this.analyticsId, 'auto');
    }

    /** Private */
    broadcastEvent(hitType, payload) {
        this.init();
        ga(hitType, payload);
        //console.log('Google analytics event broadcasted', [hitType, payload]);
    }

    fireEvent(eventCategory, eventAction, eventLabel) {
        this.broadcastEvent('send', {
            hitType: 'event',
            eventCategory,
            eventAction,
            eventLabel
        });
    }

    firePageView(page = location.pathname) {
        this.broadcastEvent('send', {
            hitType: 'pageview',
            page
        });
    }

    parseVueRouteParams(_params) {
        let params = {..._params};
        let slug = params.slug;
        delete params.slug;

        params = _.filter(params);
        params = Object.values(params);
        params = params.reverse();
        params.unshift(slug);

        return params.join(', ');
    }

    pushDataLayer(data) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
    }
}