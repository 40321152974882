<template>
    <div>
        <section class="right-side map-container" >
            <map-presentational
                    :args="mapArgs"
                    :classes="['vignette', '-vignette-transparent', 'vignette-map']"
                    type="lodge"
            >
            </map-presentational>
        </section>

        <atr-fullpage id="lodge-page" ref="atrFullpage">
            <template v-if="loaded">
                <section-intro-presentational>
                    <transition name="fade-in">
                        <div class="map-tooltip info-container" v-show="mapClicked" @click="hidePopup">
                            <div class="map-popup">
                                <div class="inner-panel  info-inner">
                                    <p class="info-popup-content">
                                        Click here to to Explore this location on a map
                                    </p>
                                </div>
                            </div>
                        </div>
                    </transition>

                    <div class="component-text -lodge-info custom-section" :data-block-id="lodge.top_section.id" @click="mapPopup($event)">
                        <div class="inner">
                            <div class="inner-container">
                                <div class="width-container">
                                    <div class="button-fav visible-xs"
                                         :class="{on: check}"
                                         aria-label="Add to Wishlist"
                                         @click="toggleWishlist">
                                    </div>

                                    <div class="breadcrumbs sub-text">
                                        <button-internal-presentational classes="sub-text -light"
                                                                        :router_object="{name: 'single-location', params: getSlugFromParent(lodge.location)}">
                                            {{ lodge.location }}
                                        </button-internal-presentational>

                                        <span class="sub-text -light"> &nbsp;/&nbsp; </span>

                                        <button-internal-presentational classes="sub-text -light" :router_object="{name: 'single-location', params: getSlugFromParent(lodge.country)}">
                                            {{ lodge.country }}
                                        </button-internal-presentational>
                                    </div>

                                    <div class="heading-container">
                                        <div class="button-fav hidden-xs"
                                             :class="{on: check}"
                                             aria-label="Add to Wishlist"
                                             @click="toggleWishlist">
                                        </div>

                                        <h1 class="heading3 heading custom-section-title"
                                            :data-pnp-heading="lodge.top_section.tag"
                                            :data-block-id="lodge.top_section.id"
                                            v-html="lodge.title">
                                        </h1>
                                    </div>

                                    <div class="summary custom-section-content" v-html="lodge.top_section.content" :contenteditable="editing" v-if="lodge.edit === false"></div>

                                    <div class="on-page-editor-container" v-else>
                                        <div class="summary custom-section-content" v-html="lodge.top_section.content" :contenteditable="editing"></div>

                                        <div class="smallEditButtons -horizontal" :class="{ '-editing': editing }">
                                            <div v-on:click="createEditor('custom-section-content')"
                                                 class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton"></div>
                                            <div v-on:click="saveEditor('custom-section-content')" class="icon ion-ios-checkmark onPageEditor-smallButton btn-success smallSaveButton"
                                                 v-if="editing===true"></div>
                                            <div v-on:click="cancelEditor('custom-section-content')" class="icon ion-ios-close onPageEditor-smallButton btn-danger smallCancelButton"
                                                 v-if="editing===true"></div>
                                        </div>
                                    </div>

                                    <hr/>
                                    <br/>

                                    <info-container classes="rating-container" :value="lodge.rating ? lodge.rating : '0.0'">
                                        <template slot="header">
                                            <div class="sub-text">LODGE <br/>RATING</div>
                                        </template>

                                        <template slot="popup-header">
                                            <p>Lodge rating</p>
                                        </template>

                                        <template slot="popup-text">
                                            <p>
                                                This number represents the <strong>overall rating</strong> for <strong>{{lodge.title}}</strong>
                                                <span v-if="month <= 0"> in <strong>any month</strong>,</span>
                                                <span v-else> in <strong>{{ months[month - 1] }}</strong>,</span>
                                                which takes into account all the various component ratings of a property, its location,
                                                build, style, service level and experiences.
                                            </p>

                                            <div class="links">
                                                <button-internal-presentational
                                                        classes="link"
                                                        :router_object="{path: hardcodedUrls.resources.overallRating}"
                                                        transition_name="wipe">
                                                    Our ratings system
                                                </button-internal-presentational>
                                            </div>
                                        </template>
                                    </info-container>

                                    <price :price="lodge.price" :minPrice="lodge.min_price">
                                        <template slot="header">
                                             <span class="sub-text" v-if="lodge.min_price">
                                                GUIDE PRICE {{ this.$store.state.user.user.selected_currency}}<br/> PER PERSON PER NIGHT
                                             </span>
                                            <span v-else class="sub-text">
                                                GUIDE PRICE <br/>{{ this.$store.state.user.user.selected_currency}} PPPN
                                            </span>
                                        </template>

                                        <template slot="popup-header">
                                            <p>Lodge guide price</p>
                                        </template>

                                        <template slot="popup-content">
                                            <p>
                                                These numbers represent the approximate range of price per person per night in
                                                <span v-if="month <= 0"><strong>any month</strong></span>
                                                <span v-else><strong>{{ months[month - 1] }}</strong></span>
                                                to stay at
                                                <strong>{{lodge.title}}</strong>.
                                                The lower end is the average low season <strong>lodge</strong> price.
                                                The upper end is the average high season <strong>lodge</strong> price plus any extras
                                                which usually need to be included,
                                                such as meals, drinks and activities, transport costs and park fees.

                                            </p>

                                            <div class="links">
                                                <button-internal-presentational
                                                        classes="link"
                                                        :router_object="{path: hardcodedUrls.resources.priceGuarantee}"
                                                        transition_name="wipe">
                                                    Price guarantee</button-internal-presentational> :

                                                <button-internal-presentational
                                                        classes="link"
                                                        :router_object="{path: hardcodedUrls.resources.howMuchToSpend}"
                                                        transition_name="wipe">
                                                    How much to spend</button-internal-presentational> :

                                                <button-internal-presentational
                                                        classes="link"
                                                        :router_object="{path: hardcodedUrls.resources.howToSaveMoney}"
                                                        transition_name="wipe">
                                                    How to save money</button-internal-presentational>
                                            </div>
                                        </template>
                                    </price>

                                    <info-container v-if="lodge.conservation_rating >= 7"
                                                    classes="consrating-container"
                                                    :value="lodge.conservation_rating">

                                        <template slot="header">
                                            <div class="sub-text">CONSERVATION <br/>RATING</div>
                                        </template>

                                        <template slot="popup-header">
                                            <p>Lodge conservation rating</p>
                                        </template>

                                        <template slot="popup-text">
                                            <p>The conservation rating is an indication of how much a particular location or lodge needs your visit to further the protection of their landscapes and wildlife.</p>
                                            <p>We recommend that you consider including at least one lodge with a high conservation rating in your trip.</p>

                                            <div class="links">
                                                <button-internal-presentational
                                                        classes="link"
                                                        :router_object="{path: hardcodedUrls.resources.conservation}"
                                                        transition_name="wipe">
                                                    Conservation rating
                                                </button-internal-presentational>
                                            </div>
                                        </template>

                                    </info-container>
                                </div>

                                <div class="scroll-btn-container ">
                                    <div class="single-scroll-btn" @click="scroll">
                                        <i class="ion-ios-arrow-thin-down"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section-intro-presentational>

                <section-container
                        v-for="section in lodge.sections.data"
                        v-if="!section.hidden && !triggerReload"
                        :key="section.id"
                        :section="section"
                        :classes="'section'"
                        :edit="lodge.edit"
                        :item_title="lodge.title"
                        type="lodge">
                </section-container>

                <!--<alternatives type="lodge" sectionTitle="Read">-->
                <!--<h2 class="heading4">Read</h2>-->
                <!--</alternatives>-->

                <wishlist-add-presentational v-if="showOuts" :name="lodge.title" :item="lodge" type="lodge">
                </wishlist-add-presentational>

                <alternatives v-if="showOuts" :item="lodge" type="trip" sectionTitle="Best" prefix="The best trips including">
                    <div class="number-container"><span class="yellow-bar"></span>02</div>
                    <h2 class="heading4">Best</h2>

                    <p class="content">If you like this look of this lodge or location, then you can take a look at some sample trips ...</p>
                </alternatives>

                <alternatives v-if="showOuts" :item="lodge" type="lodge" sectionTitle="Alternatives" prefix="Best lodges in">
                    <div class="number-container"><span class="yellow-bar"></span>03</div>
                    <h2 class="heading4">Alternatives</h2>

                    <p class="content">If you are not sure about this lodge, then here’s some options ...</p>
                </alternatives>

                <alternatives v-if="showOuts" type="contact" sectionTitle="Contact">
                    <div class="number-container"><span class="yellow-bar"></span>04</div>
                    <h2 class="heading4">Get in touch</h2>

                    <p class="content">It’s never too soon to get in touch, we are always keen to help out, no matter how formulative or advanced your plans may be ...</p>

                    <!--<div class="buttons -alternatives">-->
                    <!--<button-internal-presentational-->
                    <!--:router_object="{ name: 'contact' }"-->
                    <!--classes="-next-step"-->
                    <!--icon="ion-ios-arrow-thin-right"-->
                    <!--v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Contact Us', $route.path)">-->
                    <!--<div>How to contact us</div>-->
                    <!--</button-internal-presentational>-->
                    <!--</div>-->
                </alternatives>

                <!--<section-outs-container :section="lodge.trips_section" :edit="lodge.edit" type="lodge_trips"></section-outs-container>-->

                <!--<section-outs-container :section="lodge.other_lodges_section" :edit="lodge.edit" type="lodge_other_lodges"></section-outs-container>-->
                <next-up-presentational
                        v-if="showOuts && !isOld"
                        :classes="'-next-lodge'"
                        page="lodge"
                        :item="lodge"
                        :data_heading="'Next'"
                        :data_next_lodge="true"
                        :text_next_item="'Next lodge'"
                        :text_explore="'Explore more lodges'"
                        :directory="directory"
                >
                </next-up-presentational>
            </template>
        </atr-fullpage>

        <div v-if="lodge.edit === true">
            <template v-for="section in lodge.sections.data">
                <public-editor-buttons type="lodge_section" :section_id="section.id" :_id="section.id" :buttons="section.buttons"></public-editor-buttons>
                <template v-for="slide in section.slides.data">
                    <public-editor-buttons type="lodge_slide" :section_id="section.id" :_id="slide.id" :buttons="slide.buttons"></public-editor-buttons>
                </template>
            </template>
            <public-editor-links type="lodge_trips" :section_id="lodge.trips_section" :_id="lodge.trips_section.id" :links="lodge.trips_section.content"></public-editor-links>
            <public-editor-links type="lodge_other_lodges" :section_id="lodge.other_lodges_section" :_id="lodge.other_lodges_section.id"
                                 :links="lodge.other_lodges_section.content"></public-editor-links>
            <public-editor-meta type="lodge_"></public-editor-meta>
        </div>

        <div id="modal-veil"></div>

        <public-edit-mode-toggle />

        <!-- <transition-wipe/> -->
        
    </div>
</template>

<script>
    import StateUtils from '../../global/StateUtils';
    import pageIsReadyMixin from '../../mixins';
    import GlobalVars from "../../global/GlobalVars";
    import { mapState } from 'vuex';
    import LazyLoader from '../../global/LazyLoader';

    export default {
        mixins: [pageIsReadyMixin],
        data() {
            return {
                editing: false,
                editorName: null,
                type: 'lodge_main',
                showOuts: true,
                loaded: false,
                months: [],
                triggerReload: false,
                mapClicked: false
            }
        },
        methods: {
            createEditor(which) {
                if (!window._.isUndefined(this.editorName) && this.editorName !== null) {
                    alert('You are currently editing a different bit on the page.\nSave or Cancel it before starting editing on a new instance.');
                } else {
                    this.editing = true;
                    Vue.nextTick(() => {
                        var editor = window.CKEDITOR.inline(this.$el.querySelector('.on-page-editor-container .' + which), window.ckeditorConfig);
                        this.$el.querySelector('.on-page-editor-container .' + which).click();
                        this.editorName = editor.name;

                        this.$store.commit(window.store.SET_EDITOR_CKEDITOR, true, {root: true});
                    });
                }
            },
            cancelEditor(which) {
                this.editing = false;
                if (this.editorName !== null) {
                    window.CKEDITOR.instances[this.editorName].container.$.innerHTML = this.lodge.top_section.content;
                    window.CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;

                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, { root: true });
                }
            },
            saveEditor(which) {
                var stateObject = new StateUtils(this.type, this.$store).getStateObject();

                if (this.editorName !== null) {
                    stateObject.top_section.content = window.CKEDITOR.instances[this.editorName].container.$.innerHTML;
                    new StateUtils(this.type, this.$store).setStateObject(stateObject, null);
                    this.editing = false;
                    window.CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;

                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, { root: true });
                }
            },
            getSlugFromParent(title){
                let filteredArr = this.lodge.parents.data.filter(el => {
                    if(el.title === title){
                        return el;
                    }
                })[0];

                if(filteredArr){
                    return filteredArr.vue_route_params;
                }
                return [];
            },
            toggleWishlist(){
                let payload = {
                    id: this.lodge.id,
                    type: 'lodge'
                };

                let check = this.wishlist.some((el) => {
                    return el.id == this.lodge.id;
                });

                if(check) {
                    this.$store.commit(window.store.REMOVE_WISHLIST_ITEM, payload);
                } else {
                    this.$store.dispatch(window.store.SET_WISHLIST_ITEM_BY_ID, payload);
                }
            },
            lazyLoadHiddenImages(){
                let images = [];

                //Load any image slides (View More)
                if(this.lodge.sections.data && this.lodge.sections.data.length > 0) {
                    for (let i in this.lodge.sections.data) {
                        if (this.lodge.sections.data[i].hiddenSlides && this.lodge.sections.data[i].hiddenSlides.data.length > 0) {
                            for (let j in this.lodge.sections.data[i].hiddenSlides.data) {
                                if (pnpHelper.bootstrap.isXs()) {
                                    images.push(this.lodge.sections.data[i].hiddenSlides.data[j].bg_img_url_large);
                                } else {
                                    images.push(this.lodge.sections.data[i].hiddenSlides.data[j].img_url);
                                }
                            }
                        }
                    }
                }

                //Load any hidden section images (View All)
                if(this.lodge.hiddenSections.data && this.lodge.hiddenSections.data.length > 0) {
                    for (let i in this.lodge.hiddenSections.data) {

                        if(pnpHelper.bootstrap.isXs()){
                            images.push(this.lodge.hiddenSections.data[i].bg_img_url_large);
                        }else{
                            images.push(this.lodge.hiddenSections.data[i].bg_img_url);
                        }

                        if (this.lodge.hiddenSections.data[i].allSlides && this.lodge.hiddenSections.data[i].allSlides.data.length > 0) {
                            for (let j in this.lodge.hiddenSections.data[i].allSlides.data) {
                                if (pnpHelper.bootstrap.isXs()) {
                                    images.push(this.lodge.hiddenSections.data[i].allSlides.data[j].bg_img_url_large);
                                } else {
                                    images.push(this.lodge.hiddenSections.data[i].allSlides.data[j].img_url);
                                }
                            }
                        }
                    }
                }

                if(images.length > 0) {
                    let lazyLoader = new LazyLoader();
                    lazyLoader.loadImages(images);
                }
            },
            scroll(){
                window.bus.$emit('fullPageMoveSectionDown');
            },
            mapPopup($event){
                if(($($event.target).hasClass('-lodge-info') || $($event.target).hasClass('inner-container')) && !this.mapClicked) {
                    this.mapClicked = true;
                }
            },
            hidePopup(){
                this.mapClicked = false;
            }
        },
        computed: {
            hardcodedUrls() {
                return GlobalVars.HARCODED_URLS;
            },
            googleAnalytics() {
                return window.GoogleAnalytics;
            },
            analyticsEventCategory() {
                return 'Add ' + window.GoogleAnalytics.parseVueRouteParams(this.$route.params);
            },
            lodge() {
                let lodge = this.stateLodge;

                let { price, guide_price, conservation_rating, rating } = this.$route.params;

                return {
                    ...lodge,
                    price: price ? price : lodge.price,
                    guide_price: guide_price ? guide_price : lodge.guide_price,
                    conservation_rating: conservation_rating ? conservation_rating : lodge.conservation_rating,
                    rating: rating ? rating : lodge.rating
                };
            },
            mapArgs() {
                return [{
                    lat: this.lodge.lat,
                    lng: this.lodge.lng,
                    image: this.lodge.image,
                    id: this.lodge.id,
                    title: this.lodge.title,
                    price: this.lodge.price,
                    rating: this.lodge.rating,
                    zoom: 15
                }];
            },
            directory(){
                if(this.$route.params){
                    return this.$route.params.directory;
                }

                return false;
            },
            check(){
                return this.wishlist.some((el) => {
                    return el.id == this.lodge.id;
                })
            },
            isOld() {
                return window.globals.oldDevice;
            },
            ...mapState({
                month: state => state.search.criteria.params.month,
                stateLodge: state => state.singleLodge.lodge,
                wishlist: state => state.wishlist.selected
            })
        },
        mounted(){
            if(Object.keys(this.$route.params).length > 0 && this.$route.params.hasOwnProperty('showOuts')) {
                this.showOuts = this.$route.params.showOuts;
            }

            this.$store.dispatch(window.store.FETCH_AND_SET_LODGE, this.$route.params['slug']).then(() => {
                setTimeout(() => {
                    if(this.$refs.atrFullpage) {
                        this.$refs.atrFullpage.init();
                    }
                }, 100);

                this.loaded = true;
            }).catch(() => {
                this.$router.push({ name: 'page404' });
            });

            this.months = GlobalVars.MONTHS;
            window.bus.$once('fullPageAfterDomReady', this.declarePageIsReady);
            window.bus.$once('lazyloadAllLoaded', this.lazyLoadHiddenImages);

        },
        beforeRouteUpdate(to, from, next) {
            //Only do this if nwe're going from lodge to lodge (i.e. next up module or quicksearch)
            if($('body').hasClass('-disable-page-wipe')) {

                this.triggerReload = true;

                let transitionPromise = new Promise((resolve, reject) => {
                    $('.-lodge-info').find('.inner').removeClass('-in');

                    resolve();
                });

                transitionPromise.then(() => {
                    this.triggerReload = false;

                    this.$store.dispatch(window.store.FETCH_AND_SET_LODGE, to.params['slug']).then(() => {

                        window.bus.$emit('fullPageDestroying');
                        window.bus.$emit('fullPageDestroyed');
                        window.bus.$emit('fullPageInit');

                        next();
                    }).then(() => $('.-lodge-info').find('.inner').addClass('-in')).catch(() => {
                        this.$router.push({ name: 'page404' });

                    }).finally(() => {
                        setTimeout(() => {
                            $('body').removeClass('-disable-page-wipe')
                        }, 2000);
                    });

                });
            }else {
                next();
            }
        },
        beforeDestroy() {
            window.bus.$emit('setShowBackButton', false);
        }
    }
</script>
