<template>
    <div class="pnp-popover srch-panel -compact -month vh-fix-xs" :id="'srch-month-panel' + this.sentenceId">

        <mob-popup-header text="SELECT MONTH"></mob-popup-header>

        <public-search-month-graph
                v-bind:default-month="params.month"
                v-bind:set-current-month-callback="setMonth"
                v-bind:months="options.months">
        </public-search-month-graph>
    </div>
</template>
<script>
    import UrlMap from '../../../global/UrlMap';
    import router from '../../../router';

    export default {
        props: {
            params: {
                type: Object,
            },
            options: {
                type: Object
            },
            shouldSearch: {
                default: true,
                type: Boolean
            },
            sentenceId: {
                default: '',
                type: String
            }
        },
        methods: {
            setMonth(month) {
                // window.bus.$emit('searchSentenceDirtyUpdate', {
                //     isDirty: true,
                //     key: 'month'
                // });

                if (this.shouldSearch) {
                    this.$store.commit(window.store.SET_SEARCH_MONTH, month);
                    var queryParams = window._.defaultsDeep({month: month}, new UrlMap().searchStateToUrlParams(this.$store.state.search));
                    router.push({name: 'finder', query: queryParams});
                    window.bus.$emit('searchDoSearch');
                } else {
                    this.params.month = month;
                }
            },
        },
    }
</script>