<template>
    <div class="gs-modal-container" :data-for-section="for_section_id">
        <modal-button :div_id="div_id"  @open_modal="openModal">
            <slot name="modal-button"></slot>
        </modal-button>

        <modal-body
                :open="open"
                :classes="classes"
                @close_modal="closeModal"
                :id="div_id"
                v-if="$slots['modal-body']">
            <slot name="modal-body" :id="div_id"></slot>
        </modal-body>
    </div>
</template>

<script>
    export default {
        props: [
            'classes',
            'div_id',
            'article_editor',
            'data_key',
            'for_section_id',
            'modal_class'
        ],
        data(){
            return {
                open: false
            }
        },
        methods: {
            openModal(){
                this.open = true;
                pnpHelper.scroll.lock();
                // pnpHelper.scroll.lockIos();
            },
            closeModal(){
                this.open = false;
                pnpHelper.scroll.unlock();
                // pnpHelper.scroll.unlockIos();
            },
            closeOnEsc(e){
                if(e.key === "Escape") {
                    this.closeModal();
                }
            }
        },
        created(){
            let event = 'open_modal_' + this.div_id;

            window.bus.$on(event, this.openModal);
            window.bus.$on('close_global_modal', this.closeModal);
            $(document).on('keyup', this.closeOnEsc);
        },
        destroyed(){
            let event = 'open_modal_' + this.div_id;
            window.bus.$off(event, this.openModal);
            $(document).off('keyup', this.closeOnEsc);
        }
    }
</script>
