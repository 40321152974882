<template>
    <div class="welcome">
        <transition name="welcome-fade" mode="out-in">
            <h2 :key="activeMsg" class="welcome-title">{{activeMsg}}</h2>
        </transition>

        <p class="welcome-text">A quick introduction to who we are and what we do</p>

        <div class="buttons welcome-buttons">
            <button-internal-presentational
                    classes="btn -main-cta -ripple"
                    :router_object="{ path: hardcodedUrls.resources.gettingStarted }">
                <span><span>Start</span> <i class="ion-ios-arrow-thin-right"></i></span>
            </button-internal-presentational>
        </div>
    </div>
</template>

<script>
    import GlobalVars from "../../../global/GlobalVars";

    export default {
        data(){
            return {
                msgs: [
                    'Habari',
                    'Welcome',
                    'Wamukelekile', 'Welkom', 'Kulandila',
                    'Sidai',
                    'Bem Vinda'
                ],
                activeMsg: 'Welcome',
                timer: ''
            }
        },
        methods: {
           welcomeMsg(){
               // let i = 1;

               // this.timer = setInterval(() => {
               //     if(i === this.msgs.length) {
               //         i = 1;
               //     }
               //     this.activeMsg = false;
               //     this.activeMsg = this.msgs[i];
               //     i++;
               //
               // }, 5000);
           }
        },
        computed: {
          hardcodedUrls() {
            return GlobalVars.HARCODED_URLS;
          }
        },
        mounted(){
            // this.welcomeMsg();
        },
        beforeDestroy(){
            // clearInterval(this.timer);
        }
    }
</script>