import GlobalVars from "./GlobalVars";

export default class AtrStorage
{
    constructor() {
        this.maxNumberOfStorageItems = 20;
        this.inUseKeyName = 'current_in_use_key';
        this.keysNeverToBePopped = [this.inUseKeyName, "wishlist", "resource_results", "selected_currency", "user", "has_seen_sentence", "start_articles", "experts", "has_opened_nav","google_tracking"];
        this.isLocalStorageEnabled = null;
        this.isEdit = window.location.href.indexOf('edit=1');
        this.editCapability =!!(' '+document.getElementsByTagName('html')[0].className+' ').indexOf(' check_class ')+1;
    }

    isLocalStorage() {
        if (this.isLocalStorageEnabled != null) {
            return this.isLocalStorageEnabled;
        }

        let test = 'test';
        this.isLocalStorageEnabled = false;

        try {
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
            this.isLocalStorageEnabled = true;
        } catch (e) {}

        return this.isLocalStorageEnabled;
    }

    cleanCurrentInUseKey() {
        let key = localStorage.getItem(this.inUseKeyName);

        if(key !== null) {
            this.purge(key);
            localStorage.removeItem(this.inUseKeyName);
        }
    }

    popOldestEntry() {
        if (!this.isLocalStorage()) {
            return;
        }

        let i = 0;
        let oldestKey = null;

        while (localStorage.key(i) != null) {
            let key = localStorage.key(i);
            let item = localStorage.getItem(key);

            if (this.keysNeverToBePopped.indexOf(key) === -1) {
                oldestKey = oldestKey == null || item.created < oldestKey ? key : oldestKey;
            }

            i++;
        }

        this.purge(oldestKey);
    }

    append(key, name, value){
        let obj = {};

        obj[name] = value;

        if(!this.fetch(key)) {
            this.store(key, obj);
        } else {
            this.store(key, {...this.fetch(key), ...obj});
        }
    }

    store(key, data, expires = GlobalVars.ONE_HOUR) {
        if (this.isEdit !== -1) {
            return;
        }

        if (this.isLocalStorage() === true) {
            if ((localStorage.length + 1) >= this.maxNumberOfStorageItems) {
                this.popOldestEntry();
            }

            let metaData = {
                object: data,
                created: new Date() / 1000,
                expires: expires
            };

            localStorage.setItem(key, JSON.stringify(metaData));

            if(key !== 'wishlist'){
                localStorage.setItem(this.inUseKeyName, key);
            }
        }
    }

    fetch(key) {
        if (this.isEdit !== -1) {
            return false;
        }

        let data = this.fetchIfNotExpired(key);

        if (data !== false) {
            localStorage.setItem(this.inUseKeyName, key);
        }

        return data;
    }

    purge(key) {
        if (this.isEdit !== -1) {
            key+='_edit';
        }

        if (key.indexOf('_edit') !== -1) {
            if (this.isLocalStorage() === true) {
                localStorage.removeItem(key.replace('_edit', ''));
            }
        }

        if (this.isLocalStorage() === true) {
            localStorage.removeItem(key);
        }
    }

    fetchIfNotExpired(key) {
        if (this.isLocalStorage() === true) {
            let item = localStorage.getItem(key);

            if (item !== null) {
                item = JSON.parse(item);

                let expires = item.expires || GlobalVars.ONE_HOUR;
                let now = new Date() / 1000;

                if (item.created < now - expires) {
                    this.purge(key);
                    return false;
                }

                return item.object;
            }
        }

        return false;
    }
}