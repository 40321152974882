<template>
    <div>
        <div class="buttons button-grid" :class="buttonClasses">
            <button v-for="option in options"
                    @click="selectTab(option)"
                    class="btn -rect -large"
                    :class="[ selected.includes(option) ? '-yellow' : '-clear']">
                <span>{{ option }}</span>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            'options': Array,
            'type': {
                default: 'radio',
                type: String
            },
            'cols': {
                default: '2',
                type: String
            },
            'name': String
        },
        data(){
            return {
                selected: [this.options[0]]
            }
        },
        methods: {
            selectTab(option) {
                if(this.type == 'grid') {

                    if(this.selected.includes(option)) {
                        let index = this.selected.indexOf(option);

                        if (index > -1) {
                            this.selected.splice(index, 1);
                        }
                    } else {
                        this.selected.push(option);
                    }

                } else {
                    this.selected = []
                    this.selected.push(option);
                }

                let payload = {
                    name: this.name,
                    selected: this.selected
                }

                this.$emit('select', payload);
            }
        },
        computed: {
            buttonClasses(){
                if(this.cols == '2'){
                    return '';
                } else if(this.cols == '4'){
                    return '-quarter';
                }
            }
        }
    }
</script>