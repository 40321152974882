<template>
    <a rel="nofollow" href="#">
        <div class="lodge-card">
            <img class="image" :src="image_url" alt="">

            <h3 class="name">{{ title }}</h3>

            <p class="sub-text">{{ location }}</p>
        </div>
    </a>
</template>

<script>
    export default {
        name: "LodgeCard",
        props: {
            image_url: String,
            title: String,
            location: String
        }
    }
</script>