<template>
    <div>
        <section class="container page-404">
            <div class="headings-container">
                <h1 class="header">404 Page Not Found</h1>
                <br/>
                <p>Sorry, we can't find that page.</p>
                <br/>
                <p>Try using the search facility in the menu in the top right hand corner to find the page you'd like to go to.</p>
            </div>
        </section>

        <!-- <transition-wipe/> -->
    </div>
</template>
<script>
    import pageIsReadyMixin from '../../mixins';

    export default {
        mixins: [pageIsReadyMixin],
        mounted() {
            this.declarePageIsReady();

            this.$store.commit(window.store.SET_PAGE_TITLE, this.$route.meta['page_title']);
            this.$store.commit(window.store.SET_META_DESCRIPTION, this.$route.meta['meta_description']);
        },
    }
</script>