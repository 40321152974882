<template>
    <div>
        <template>
            <div v-for="subDirectory,index in directory"
                 class="sub-directory fade-in-and-up"
                 :key="subDirectory.id" :style="transitionDelay(index)"
                 :id="subDirectory.id"
                 >

                <div class="dir-row"
                     v-bind:class="['-l' + level,{'-has-children': subDirectory.children !== undefined && subDirectory.children.length > 0}]">

                    <div class="dir-name" :class="{'-active' : subDirectory.id === openNodeId }">
                        <a class="inner"
                           v-on:click="toggleOpenSub(subDirectory, $event)"
                           v-on:mouseover="highlightArticle(subDirectory)">
                            {{subDirectory.name}}
                        </a>

                        <template>
                            <directory-link :subDirectory="subDirectory"
                                            :search="search"
                                            :toggleItem="toggleItem"
                                            :toggleRelatedSearches="toggleRelatedSearches"
                                            :selectedItems="selectedItems"
                                            :visibleRelatedResults="visibleRelatedResults"
                                            :type="subDirectory.type"
                                            :level="level"
                                            :currentId="subDirectoryId"
                                            @cardToggle="toggleCard">
                            </directory-link>
                        </template>
                    </div>

                    <div class="dir-rating" v-bind:class="{ '-is-na': subDirectory.rating == 'N/A'}">
                        {{subDirectory.rating}}
                    </div>

                    <div v-if="subDirectory.price != 'N/A'" class="dir-price">
                        <price :price="subDirectory.price" v-if="subDirectory.type != 7"></price>
                    </div>

                    <div v-else class="dir-price -is-na">
                        N/A
                    </div>
                </div>

                <transition name="article-image">
                    <template v-if="subDirectory.children!==undefined && subDirectory.children.length > 0 && subDirectory.isOpen === true">
                        <public-directory-listing
                                v-bind:directory="subDirectory.children"
                                v-bind:level="level+1"
                                :type="type">
                        </public-directory-listing>
                    </template>
                </transition>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        props: ['directory', 'level', 'type'],
        data(){
            return {
                selectedItems: [],
                visibleRelatedResults: [],
                subDirectoryId: null
            }
        },
        methods: {
            transitionDelay(index) {
                let totalSeconds = 1;
                let interval = (totalSeconds * 1000) / this.directory.length;
                return !_.isUndefined(this.level) && this.level == 1 ? {} : { 'transition-delay': (index * interval) / 1000 + 's' };
            },
            toggleOpenSub(subDirectory, event){

                if(subDirectory.children !== undefined && subDirectory.children.length > 0) {

                    let stateDirectory = window._.defaultsDeep({}, this.$store.state.directory);
                    let recursiveResults = this.recursiveSearchForObject(subDirectory.id, stateDirectory.directory);
                    let newDirectory = {...stateDirectory, recursiveResults};

                    this.$store.commit(window.store.SET_DIRECTORY, newDirectory, {root: true});

                } else {
                    // this.$router.push({ name: "single-lodge", params : { slug: subDirectory.slug}});
                    // this.$refs.directoryLink.click();
                }

                let zoom = this.zoomSelect(subDirectory);
                if(!subDirectory.isOpen) {
                    this.$store.commit(window.store.SET_OPEN_NODE_ID, subDirectory.id, {root: true});

                    if(this.subDirectoryId !== subDirectory.id) {
                        if(window.pnpHelper.map) {

                            // pnpHelper.map.centerOnCoords([subDirectory.coords[1], subDirectory.coords[0]], zoom);
                            pnpHelper.map.showAutoCompleteMapItem({
                                id: subDirectory.id,
                                lat: subDirectory.coords[0],
                                lng: subDirectory.coords[1],
                                type: subDirectory.type
                            }, true, zoom);

                            // if(subDirectory.type == 13){
                            //     pnpHelper.map.addSingleMarkerToMap(subDirectory.coords[1], subDirectory.coords[0]);
                            // } else {
                            //     pnpHelper.map.clearMap();
                            // }
                        }
                    }

                    this.subDirectoryId = subDirectory.id;
                } else {
                    if(this.level == 1) {
                        // pnpHelper.map.clearMap();
                        pnpHelper.map.resetMapViewport();

                    }
                    this.$store.commit(window.store.SET_OPEN_NODE_ID, null, {root: true});
                    pnpHelper.map.hidePopover();

                    this.subDirectoryId = null;
                }
            },
            toggleCard(val, subDirectory, preventZoom){

                let stateDirectory = window._.defaultsDeep({}, this.$store.state.directory);
                let recursiveResults = this.recursiveCardToggle(subDirectory.id, stateDirectory.directory);
                let newDirectory = {...stateDirectory, recursiveResults};

                this.subDirectoryId = subDirectory.id;

                this.$store.commit(window.store.SET_DIRECTORY, newDirectory, {root: true});
                this.$store.commit(window.store.SET_OPEN_NODE_ID, subDirectory.id, {root: true});


                if(window.pnpHelper.map && !preventZoom) {
                    let zoom = this.zoomSelect(subDirectory);

                    pnpHelper.map.showAutoCompleteMapItem({
                        id: subDirectory.id,
                        lat: subDirectory.coords[0],
                        lng: subDirectory.coords[1],
                        type: subDirectory.type
                    }, true, zoom);
                }
            },
            recursiveCardToggle(id, results){
                for(let x in results){
                    if(results[x].id == id){
                        let subObject = window._.defaultsDeep({}, results[x]);
                        subObject.isCardOpen = !subObject.isCardOpen;
                        results[x] = subObject;
                    } else {
                        results[x].children = this.recursiveCardToggle(id, results[x].children);
                    }
                }
                return results;
            },
            recursiveSearchForObject(id, results){
                for(let x in results){
                    if(results[x].id == id){
                        let subObject = window._.defaultsDeep({}, results[x]);
                        subObject.isOpen = !subObject.isOpen;
                        results[x] = subObject;
                    } else {
                        if(results[x].children.length > 0){
                            results[x].children = this.recursiveSearchForObject(id, results[x].children);
                        }
                    }
                }
                return results;
            },
            highlightArticle(dir){
                // console.log(dir.coords[0], dir.coords[1])
                // pnpHelper.map.flyTo(lng, lat, 13);
            },
            toggleRelatedSearches(id) {
                if(this.visibleRelatedResults.includes(id)){
                    this.visibleRelatedResults.splice(
                        this.visibleRelatedResults.findIndex(function(thisId){
                            return thisId === id;
                        }), 1);
                }else{
                    this.visibleRelatedResults.push(id);
                }
            },
            toggleItem(id){
                if(this.selectedItems.includes(id)){
                    //Remove from the search criteria
                    this.selectedItems.splice(
                        this.selectedItems.findIndex(function(el){
                            return el.id === id;
                        }), 1);
                } else {
                    this.selectedItems.push(id);
                }

                bus.$emit('updateSelections.' + this.mode + '.' + this.subMode, this.selectedItems);
            },
            zoomSelect(subDirectory){
                let zoom;

                if(subDirectory.type == 13) {zoom = 9;}
                else if(this.level == 1) {zoom = 5;}
                else if(this.level == 2) {zoom = 6;}
                else if(this.level == 3) {zoom = 7;}
                else if(this.level == 4) {zoom = 8;}

                return zoom;
            }
        },
        computed: {
            searchResults: function() {
                return this.$store.state.search.results;
            },
            search: function() {
                return this.$store.state.search;
            },
            openNodeId: function(){
                return this.$store.state.directory.openNodeId;
            }
        },
        created() {
            this.$store.commit(window.store.FETCH_AND_SET_WISHLIST);
        },
        mounted() {
            this.$store.commit(window.store.SET_PAGE_TITLE, this.$route.meta['page_title']);
            this.$store.commit(window.store.SET_META_DESCRIPTION, this.$route.meta['meta_description']);
        }
    }
</script>