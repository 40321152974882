<template>
    <div class="modals" :data-for-section="for_section_id">
        <portal to="modals">
            <a @click="close" class="modal-close-icon">
                <i class="ion-ios-close-empty"></i>
            </a>
        </portal>

        <div class="modal -half -seasonality"
             :id="div_id"
             :data-key="data_key"
             :data-article-editor="article_editor"
             @close-modal="close">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'classes', 'div_id', 'article_editor',
            'data_key', 'for_section_id'
        ],
        methods: {
            close(e) {
                e.preventDefault();

                window.pnpHelper.modal_.close();
            }
        }
    }
</script>