<template>
    <div id="search">
        <div class="header-bg"></div>

        <div class="left-side">
            <div class="top-nav header-container" ref="stickyheader">
                <div class="header">
                    <router-link :to="{name: 'home'}" class="logo">
                        <img src="/assets/img/logos/atr.svg" width="66" alt="ATR" class="logo -light"/>
                        <img src="/assets/img/logos/atr-dark.svg" width="66" alt="ATR" class="logo -dark"/>
                    </router-link>
                </div>
            </div>

            <div class="links-search-sentence-container -subnav">
                <div class="links-container">
                    <h1 class="hidden" v-html="seo.pageTitle"></h1>

                    <router-link :to="{ name: 'finder'}" :class="{'router-link-active': $route.meta.component_name_body_class }" class="sub-text">Finder</router-link>

                    <template v-if="search.criteria.params.type=='lodge' || search.criteria.params.type=='location'">
                        <router-link :to="{ name: 'directory'}" class="sub-text visible-above-ipad">Directory</router-link>
                    </template>
                    <template v-else>
                        <router-link :to="{ name: 'directory'}" class="sub-text link-disabled visible-above-ipad">Directory</router-link>
                    </template>

                    <template v-if="search.criteria.params.type !=='resource'">
                        <router-link :to="{ name: 'wishlist', query: {type: search.criteria.params.type}}" class="sub-text">
                            Wishlist
                            <transition name="fade">
                                <span v-if="wishlistCount">{{ wishlistCount }}</span>
                            </transition>
                        </router-link>
                    </template>
                    <template v-else>
                        <a rel="nofollow" href="#" class="sub-text link-disabled">
                            Wishlist
                            <span v-if="wishlistCount">{{ wishlistCount }}</span>
                        </a>
                    </template>

                    <!--<a href="/search" class="sub-text active">Finder</a>-->
                    <!--<a class="sub-text" v-show="search.criteria.params.type === 'location' || search.criteria.params.type === 'lodge' || search.criteria.params.type === 'experience' || search.criteria.params.type === 'trip'">Search</a>-->
                    <!--<a href="/directory" class="sub-text" >Directory</a>-->
                    <!--<a rel="nofollow" href="#" class="sub-text">Wishlist</a>-->
                </div>
            </div>

            <router-view></router-view>
        </div>

        <public-search-item :doing_search="doingSearch"></public-search-item>

        <map-popover-portal></map-popover-portal>

        <portal-target name="sentencePopups"></portal-target>

        <!-- <transition-wipe/> -->
    </div>
</template>

<script>
    import AtrStorage from '../../global/AtrStorage';
    import GlobalVars from '../../global/GlobalVars';
    import { mapState } from 'vuex';
    import Stickyfill from 'stickyfilljs';

    export default {
        data() {
            return {
                doingSearch: false,
                stickyBitsRef: null
            }
        },
        methods: {
            updateDoingSearch(isDoingSearch){
              this.doingSearch = isDoingSearch;
            }
        },
        mounted() {
            window.bus.$emit('setShowBackButton', false);

            //IE support for sticky
          this.stickyBitsRef = Stickyfill.addOne(this.$refs.stickyheader);
        },
        computed: {
            seo() {
                return this.$store.state.seo;
            },
            // search() {
            //     return this.$store.state.search;
            // },
            wishlistCount(){
                let length = this.$store.state.wishlist.selected.filter((el) =>  el.type === this.search.criteria.params.type).length;

                return length > 0 ? `(${length})` : false;
            },
            finderQuery(){
                return (this.search.criteria.params.type !== 'lodge') ? {type: this.search.criteria.params.type} : {};
            },
            ...mapState({
                search: state => state.search,
            })
        },
        beforeRouteLeave(to, from, next) {
            if (to.name === 'single-resource' || to.name === 'single-lodge' || to.name === 'single-location' || to.name === 'single-experience' || to.name === 'single-trip') {
                window.bus.$emit('setShowBackButton', true);
            }

            next();
        },
        created() {
            window.bus.$on('doingSearch', this.updateDoingSearch);
        },
        destroyed() {
            window.bus.$off('doingSearch', this.updateDoingSearch);

            //IE support for sticky
            this.stickyBitsRef.remove();
        }
    }
</script>