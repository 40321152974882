<template>
    <div class="contact-page">
        <half-page-scroll-hijack>
            <div class="nav-veil"></div>

            <div class="left-side">
                <section data-id="0" class="container -full-page">
                    <div class="number-container"><span class="yellow-bar"></span></div>

                    <h1 class="heading4 heading">Get in touch</h1>

                    <div class="content">
                        <p>No matter what stage you are at with planning your trip to Africa, our safari experts are here to help.</p>

                        <p>In this section we will help you decide the best way to get in touch.</p>
                    </div>

                    <div class="scroll-btn">
                        <button-external-presentational
                                @click="scrollTo"
                                icon="ion-ios-arrow-thin-down"
                                classes="scroll-down-arrow">
                        </button-external-presentational>
                    </div>
                </section>

                <section data-id="1" class="container -full-page">
                    <div class="number-container"><span class="yellow-bar"></span>01</div>

                    <h2 class="heading4 heading">A quick chat</h2>

                    <div class="content">
                        <p class="f22">A call is almost always the best and most efficient way to get started. There’s no hard sell and no obligation, just safari people chatting about what we love.</p>
                    </div>

                    <div class="buttons">
                        <button-internal-presentational
                                classes="btn -main-cta -ripple"
                                :router_object="{ name: 'contact-call' }"
                                transition_name="article-to-halfpage"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent('Call', 'Button Click', getPath('contact-call'))">
                            <span><span>Call</span> <i class="ion-ios-arrow-thin-right"></i></span>
                        </button-internal-presentational>
                    </div>
                </section>

                <section data-id="2" class="container -full-page" >
                    <div class="number-container"><span class="yellow-bar"></span>02</div>

                    <h2 class="heading4 heading">Get us to call you</h2>

                    <div class="content">
                        <p class="f22">Please feel free to make an appointment for one of our safari experts to call you at a more convenient time. You can even arrange a conference call if you have others in your group who would like to join in.</p>
                    </div>

                    <div class="buttons">
                        <button-internal-presentational
                                classes="btn -main-cta -ripple"
                                :router_object="{ name: 'call-back' }"
                                transition_name="article-to-halfpage"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent('Call Back', 'Button Click', getPath('call-back'))">
                            <span><span>Call back</span> <i class="ion-ios-arrow-thin-right"></i></span>
                        </button-internal-presentational>
                    </div>
                </section>

                <section data-id="3" class="container -full-page">
                    <div class="number-container"><span class="yellow-bar"></span>03</div>

                    <h2 class="heading4 heading -first-time">Fill out a short form</h2>

                    <div class="content">
                        <p class="f22">If you prefer to start by email, then this short form will help you to quickly let us know what you have in mind, after which we should be in a position to get back to you by return with a selection of tailor-made sample itineraries.</p>
                    </div>

                    <div class="buttons">
                        <button-internal-presentational
                                classes="btn -main-cta -ripple"
                                :router_object="{ name: 'quote' }"
                                transition_name="article-to-halfpage"
                                v-on:btn-internal-clicked="googleAnalytics.fireEvent('Quote', 'Button Click', getPath('quote'))">
                            <span><span>Inquiry</span> <i class="ion-ios-arrow-thin-right"></i></span>
                        </button-internal-presentational>
                    </div>
                </section>

                <!--<div data-id="4" class="container -full-page">-->
                    <!--<div class="number-container"><span class="yellow-bar"></span>04</div>-->

                    <!--<div class="heading4 heading">Learn as you go</div>-->

                    <!--<div class="content">-->
                        <!--<p class="f22">If you are not yet sure what you want to do in Africa, or even if whether you want to go at all, then the structured inquiry section is designed to help you gently gather your thoughts, whilst providing answers to many of those key early questions.</p>-->
                    <!--</div>-->

                    <!--<div class="buttons">-->
                        <!--<button-internal-presentational-->
                                <!--classes="btn -rect -large -wide -yellow"-->
                                <!--url="/contact/inquiry">-->
                            <!--<div>Structured inquiry</div>-->
                        <!--</button-internal-presentational>-->
                    <!--</div>-->
                <!--</div>-->

                <!--<hr>-->

                <!--<section data-id="4" class="container -full-page">-->
                    <!--<div class="number-container"><span class="yellow-bar"></span>04</div>-->

                    <!--<h2 class="heading4 heading">Send an email</h2>-->

                    <!--<div class="content">-->
                        <!--<p class="f22">If you would prefer to send us a simple email, then that’s also no problem.</p>-->
                    <!--</div>-->

                    <!--<div class="buttons">-->
                        <!--<button-internal-presentational-->
                                <!--classes="btn -main-cta -ripple"-->
                                <!--:router_object="{ name: 'email' }"-->
                                <!--transition_name="article-to-halfpage"-->
                                <!--v-on:btn-internal-clicked="googleAnalytics.fireEvent('Email', 'Button Click', getPath('email'))">-->
                            <!--<span><span>Email</span> <i class="ion-ios-arrow-thin-right"></i></span>-->
                        <!--</button-internal-presentational>-->
                    <!--</div>-->
                <!--</section>-->

                <section data-id="4" class="container -full-page">
                    <div class="number-container"><span class="yellow-bar"></span>04</div>

                    <h2 class="heading4 heading">Wait until later</h2>

                    <div class="content">
                        <p class="f22">If you are not yet ready to make a inquiry, then you might like to head to the Explore section to browse the many experiences, locations, lodges and trips …</p>
                    </div>

                    <div class="buttons">
                        <button-internal-presentational
                                :router_object="{name: 'safari-trip-collection'}"
                                classes="arrow-link"
                                icon="ion-ios-arrow-thin-right">
                            <div>Explore</div>
                        </button-internal-presentational>
                    </div>
                </section>
            </div>

            <section class="right-side map-container article-images-container" v-if="!isMobile">
                <template>
                    <div class="article-images -showing-images -in no-click" >
                        <transition-group name="fade" mode="in-out">
                            <article-image-presentational
                                    :key="currentArticle.image"
                                    :image="currentArticle.image"
                                    :title="currentArticle.title"
                                    :content="currentArticle.content"
                                    :route_params="currentArticle.vue_route_params"
                                    :transition_name="transitionName">
                            </article-image-presentational>
                        </transition-group>
                    </div>
                </template>
            </section>
        </half-page-scroll-hijack>

        <!-- <transition-wipe/> -->
    </div>
</template>

<script>
    import LazyLoader from '../../global/LazyLoader';
    import pageIsReadyMixin from '../../mixins';
    import RouterHelper from '../../helpers/RouterHelper';

    export default {
        mixins: [pageIsReadyMixin],
        data(){
            return {
                articlesArr: [
                    { id: 'CONTACT_1', type: 'resource', image: 'https://atrimages.azureedge.net/cdn/contact/0/01/01ATR0300-IM0001-atr-safari-contact-1475.jpg', display_type: 0 },
                    { id: 'CONTACT_2', type: 'resource', image: 'https://atrimages.azureedge.net/cdn/contact/0/02/02ATR0300-IM0002-atr-safari-contact-1475.jpg', display_type: 0 },
                    { id: 'CONTACT_3', type: 'resource', image: 'https://atrimages.azureedge.net/cdn/contact/0/03/03ATR0300-IM0003-atr-safari-contact-1475.jpg', display_type: 0 },
                    { id: 'CONTACT_4', type: 'resource', image: 'https://atrimages.azureedge.net/cdn/contact/0/04/04ATR0300-IM0004-atr-safari-contact-1475.jpg', display_type: 0 },
                    // { id: 'CONTACT_5', type: 'resource', image: 'https://atrimages.azureedge.net/cdn/contact/0/05/05ATR0300-IM0005-atr-safari-contact-1475.jpg', display_type: 0 },
                    { id: 'CONTACT_6', type: 'resource', image: 'https://atrimages.azureedge.net/cdn/contact/0/06/06ATR0300-IM0006-atr-safari-contact-1475.jpg', display_type: 0 },
                    { id: 'CONTACT_7', type: 'resource', image: 'https://atrimages.azureedge.net/cdn/contact/0/07/07ATR0300-IM0007-atr-safari-contact-1475.jpg', display_type: 0 },
                ]
            }
        },
        methods: {
            getPath(name) {
                return RouterHelper.getRoutePathFromName(name);
            },
            scrollTo() {
                window.bus.$emit('half-page-hijack-nextSection');
            },
            transitionName() {
                return this.currentArticle.display_type === 1 ? 'article-to-fullpage' : 'article-to-halfpage';
            },
            scrollifyBeforeMove(index, sections){
                this.$store.commit(window.store.SET_CURRENT_ARTICLE, this.articlesArr[index]);
            },
            scrollifyInit(index){
                this.$store.commit(window.store.SET_CURRENT_ARTICLE, this.articlesArr[index]);
            }
        },
        computed:{
          currentArticle(){
                return this.$store.state.transitionManager.currentArticle;
            },
            isMobile(){
                return window.pnpHelper.bootstrap.isXs();
            },
            googleAnalytics() {
                return window.GoogleAnalytics;
            }
        },
        // beforeRouteLeave(to, from, next) {
        //     if (to.name === 'inquiry') {
        //         window.bus.$emit('setShowBackButton', true);
        //     }
        //
        //     next();
        // },
        mounted(){
            this.$store.commit(window.store.SET_PAGE_TITLE, this.$route.meta['page_title']);
            this.$store.commit(window.store.SET_META_DESCRIPTION, this.$route.meta['meta_description']);

            if(!this.isMobile) {
                let lazyLoader = new LazyLoader();
                lazyLoader.loadArticleImages(this.articlesArr);
            }

            this.declarePageIsReady();
        },
        created(){
            window.bus.$on('scrollify-beforeMove', this.scrollifyBeforeMove);
            window.bus.$on('scrollify-init', this.scrollifyInit);
        },
        destroyed() {
            window.bus.$off('scrollify-beforeMove', this.scrollifyBeforeMove);
            window.bus.$off('scrollify-init', this.scrollifyInit);
        }
    }
</script>