export default {
    methods: {
        declarePageIsReady() {
            window.bus.$emit('pageIsReady');

            /**
             * Announce page is ready to be prerendered via node
             * after a second grace period
             */
            setTimeout(() => {
                window.prerenderReady = true;
            }, 1000);
        }
    }
};