<template>
    <div class="modal-nav nav-container">
        <!-- <div class="burger-container -main-nav" @click="toggleNav"> -->
            <!--<transition name="fade" mode="in-out">-->
                <!--<span class="link" v-if="!hasEverOpenedNav">-->
                    <!--MENU-->
                <!--</span>-->
            <!--</transition>-->

            <!-- <span class="burger" :class="{'-in' : this.open}">
                <i :class="{'-nav-in' : this.open}"></i>
            </span>
        </div> -->

        <portal to="nav">
            <div key="modal-nav" class="gs-modal -full -nav" v-if="this.open || isPrerender" :class="{'hidden':isPrerender}">
                <div class="modal-inner">
                    <global-search></global-search>
                </div>
            </div>
        </portal>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                open: false
            }
        },
        methods: {
            openNav(){
                if(!this.open) {
                    this.open = true;
                    $('body').addClass('nav-open');
                    $('body').addClass('nav-opening');

                    setTimeout(() => {
                        $('body.nav-open').addClass('lock-scroll');
                    }, 50);

                    setTimeout(() => {
                        if(this.open) {
                            pnpHelper.scroll.lockIos();
                        }
                    }, 500);

                    setTimeout(() => {
                        $('body.nav-opening').removeClass('nav-opening');
                    }, 1200);

                    $('body').addClass('-in-context-menu');

                    if (pnpHelper.sidenav) {
                        pnpHelper.sidenav.close();
                    }

                    window.bus.$emit('lockScrolling');

                    if(!this.hasEverOpenedNav){
                        this.$store.commit(window.store.SET_HAS_OPENED_NAV, true);
                    }
                }
            },
            closeNav(){
                if(this.open) {
                    this.open = false;
                    $('body').removeClass('nav-open');
                    $('body').removeClass('-in-context-menu');
                    $('body').removeClass('lock-scroll');
                    pnpHelper.scroll.unlockIos();
                    window.bus.$emit('unlockScrolling');

                    setTimeout(() => {
                        if(!this.open) {
                            $('body.nav-open').removeClass('lock-scroll');
                        }
                    }, 300);

                    if (pnpHelper.sidenav) {
                        pnpHelper.sidenav.close();
                    }
                }
            },
            toggleNav(){
                if(this.open){
                    this.closeNav();
                }else{
                    this.openNav();
                }
            },
            closeOnEsc(e){
                if(e.key === "Escape") {
                    this.closeNav();
                }
            }
        },
        computed: {
            hasEverOpenedNav() {
                return this.$store.state.user.user.hasOpenedNav;
            },
            isPrerender(){
                return window.location.href.indexOf('prerendering=true') !== -1;
            }
        },
        created(){
            window.bus.$on('open_nav', this.openNav);
            window.bus.$on('close_nav', this.closeNav);
            $(document).on('keyup', this.closeOnEsc);
        },
        destroyed(){
            window.bus.$off('open_nav', this.openNav);
            window.bus.$off('close_nav', this.closeNav);
            $(document).off('keyup', this.closeOnEsc);
        }
    }
</script>