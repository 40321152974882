require('../libs/2_jquery.fullpage.parallax');
require('../libs/3_jquery.fullpage.extensions.min');

export default class AtrFullpage
{
    constructor()
    {
        this.deferredTimeoutId = null;
        this.deferredAnimationIsFinished = false;

        this.halfMapIn = false;
        this.showingNextLodge = false;
        this.lastSectionParent = null;

        this.throttleScroll = false;
    }

    afterLoad(anchorLink, index)
    {
        //window.fullpage.reBuild();

        var $this = $(document).find('.section').eq(index);

        //I dont know why the above works, but its not the current section. I'm not touch it though.
        var _$this = $(document).find('.section').eq(index - 1);


        // $('.header-container.-left').html(_$this.height() + '/' + _$this.innerHeight() + '/' + $(window).height() + "/" + $('.section:first').css('height') + "/" + $('.fp-bg:first').outerHeight());
        // $('.header-container.-left').html(_$this.height() + '/' + $('#sidenav').outerHeight());
        //
        // if(_$this.height() != $('#sidenav').outerHeight()){
        //     $('.header-container.-right').html('FUCKED');
        //
        //     let newHeight = $('#sidenav').outerHeight() + 'px';
        //
        //     //$('.section').css('height', newHeight);
        //     //$('.fp-tableCell').css('height', newHeight);
        //     //$('.fp-bg').css('height', newHeight);
        //     //$('body').css('height', );
        //     //window.fullpage.reBuild();
        // }else{
        //     $('.header-container.-right').html('');
        // }
        //

        //setTimeout(function(){
            //window.fullpage.reBuild();
        //},2000);


        if(_$this.attr('data-pnp-half-map')){
            $('body').addClass('-half-map -add-nav-veil');
        }else{
            $('body').removeClass('-half-map -add-nav-veil');
        }

        this.deferredAnimationIsFinished = false;

        //Add animation classes
        if ($this.hasClass('-lodge-info') && this.deferredTimeoutId != null) {
            $this.find('.inner').addClass('-in');
        }

        if ($this.data('pnp-interactive-map')) {
            //window.$html.addClass('interactive-map');
        } else {
            window.$html.removeClass('interactive-map');
        }

        //If the last section was a child section (e.g. a page within Safari Basics), remove the children
        //and rebuild fullpagejs
        if (this.lastSectionParent) {
            var numChildren = 0;

            $('[data-pnp-parent-section="' + this.lastSectionParent + '"]').each(function (index) {
                $this.remove();
                numChildren = index;
            });

            //Remove the custom sidenav scroll
            window.pnpHelper.sidenav.closeSubBar(this.lastSectionParent);

            //Rebuild the page
            var activeSectionIndex = $('.fp-section.active').index();

            window.fullpage.destroy('all');

            //setting the active section as before
            $('.section').eq(activeSectionIndex).addClass('active');

            // $.initFullPage();
            window.fullpage.init(this.getArgs());

            this.lastSectionParent = null;
        }

        //Iterate through all the sections above and if they contain galleries, enable the galleries and put them on the last slide
        // $('.fullpage-wrapper .section').each(function(thisIndex){
        //     if(thisIndex < (index-1)){
        //         //If the next section has a gallery but it hasn't been initialised
        //         if($this.data('pnp-showing-gallery') && !$this.hasClass('-show-gallery') && !$this.data('pnp-see-more')){
        //             window.pnpHelper.imageGallery.initGalleryAtEnd($this);
        //         }
        //     }
        // });

        /**
         * FIXME: In Vue mode this triggers a scroll back to section x when you scroll away
         */
        // if (window.location.hash && window.location.hash.length && window.location.hash.indexOf('section')) {
        //     setTimeout(function () {
        //         var sectionIndex = window.location.hash.replace('#section', '');
        //         sectionIndex = parseInt(sectionIndex);
        //         window.fullpage.moveTo(sectionIndex);
        //     }, 1000);
        // }

        window.bus.$emit('fullPageAfterSectionChange');
    }

    // onLeaveDebouncer(index, nextIndex, direction)
    // {
    //     if (!this.allowDebouncedScroll) {
    //         console.log('blocked by debounce');
    //         return false;
    //     }
    //
    //     this.allowDebouncedScroll = false;
    //
    //     setTimeout(() => {
    //         this.allowDebouncedScroll = true;
    //     }, 1400);
    //
    //     return this.onLeave(index, nextIndex, direction);
    // }

    onLeave(index, nextIndex, direction)
    {

        // if(pnpHelper.bootstrap.isXs()) {
        //     alert('inner: ' + window.innerHeight + ', outer: ' + $(window).outerHeight() + ', actual: ' + $('.section').outerHeight());
        //
        //     if (window.innerHeight != $('.section').outerHeight()) {
        //         alert('resizing');
        //         $('.section').css('height', window.innerHeight + 'px');
        //         $('.fp-tableCell').css('height', window.innerHeight + 'px');
        //         $('.fp-bg').css('height', window.innerHeight + 'px');
        //     }else{
        //         alert('not resizing');
        //     }
        // }

        /** Locking the scroll on modal open here to avoid a setTimeout for 1500 */
        if ($('body').hasClass('modal-open')) {
            window.fullpage.setAllowScrolling(false);
            return false;
        }

        //console.log('Leaving: ' + index + '. nextIndex: ' + nextIndex);

        var $this = $(document).find('.section').eq(index - 1);
        var lastSlideIndex = $('.fullpage-wrapper .section').length;
        var $thisSection = $this;
        var $nextSection = $('.fullpage-wrapper .section').eq(nextIndex - 1);

        //Remove the zoom animation on fp-bg
        $thisSection.find('.fp-bg.-in').removeClass('-in');



        //If this slide is the last slide, but there's a back cta
        if ($thisSection.data('pnp-last-slide')) {
            if (direction === "down") {
                //prevent the next one from loading
                if (!$thisSection.hasClass('-continue-slide')) {
                    $thisSection.addClass('-continue-slide');
                }

                return false;

            } else if (direction === "up") {

                if ($thisSection.hasClass('-continue-slide')) {
                    $thisSection.removeClass('-continue-slide');
                    $thisSection.find('.fp-bg').css('transform', 'translate3d(0px, 0px, 0px)');
                    return false;
                }
            }
        }

        if (window.$body.hasClass('page-scroll-hijacked') && $('#content > .no-scroll-hijack').length == 0) {
            if (nextIndex === 1 && !window._.isUndefined(window.pnpHelper) && !window._.isUndefined(window.pnpHelper.map)) {
                window.pnpHelper.map.focusOnMarker();
                window.pnpHelper.map.centerOnMarker(null, true);
                this.halfMapIn = false;
            }

            //If nextslide is half page map
            if ($nextSection.attr('data-pnp-half-map') && !this.halfMapIn && window.pnpHelper.bootstrap.isMd()) {
                if(!_.isUndefined(window.pnpHelper.map)) {
                    window.pnpHelper.map.shiftMapRight();
                }
                this.halfMapIn = true;
            }

            if(!$nextSection.attr('data-pnp-half-map')) {
                $('body').removeClass('-add-nav-veil');
            }else{
                window.bus.$emit('fullPageEnteringHalfScreen');
            }

            //If the slide is the next lodge
            if ($nextSection.attr('data-pnp-next-lodge')) {
                if(!_.isUndefined(window.pnpHelper.map)) {
                    this.halfMapIn = false;
                    this.showingNextLodge = true;
                    window.bus.$emit('showNextMapItem', true);
                }
            }else if(this.showingNextLodge){
                this.showingNextLodge = false;
                window.bus.$emit('showNextMapItem', false);
            }

            //Allows for fading between slides that have a fixed background (e.g. map on the lodge page)
            if ($thisSection.attr('data-pnp-fade-out') && this.deferredAnimationIsFinished === false) {
                //animating out
                $this.addClass('fp-leaving');
                $this.addClass('fp-direction-' + direction);

                clearTimeout(this.deferredTimeoutId);

                this.deferredTimeoutId = setTimeout(function () {
                    this.deferredAnimationIsFinished = true;
                    $('.fullpage-wrapper').addClass('-no-transition');
                    window.fullpage.silentMoveTo(nextIndex);
                    $('.fullpage-wrapper').removeClass('-no-transition');

                    setTimeout(function () {
                        $this.removeClass('fp-leaving');
                        $this.removeClass('fp-direction-' + direction);

                        // if($this.hasClass('-lodge-info')) {
                        //     $this.find('.inner').removeClass('-in');
                        // }
                    }, 900);

                }, 500);

                return this.deferredAnimationIsFinished;
            }

            //If we're in a gallery
            if ($thisSection.attr('data-pnp-showing-gallery') && !window.globals.oldDevice) {

                if(this.throttleScroll === true){
                    return false
                }

                this.throttleScroll = true;
                setTimeout(() => {
                    this.throttleScroll = false;
                },900);

                var changeSection = window.pnpHelper.imageGallery.changeImage(direction, index, nextIndex);

                if (changeSection) {
                    if ($nextSection.hasClass('-show-gallery') && direction === "up") {
                        var $galleryImages = $nextSection.find('.pnp-slide');
                        var imageIndexVisible = $nextSection.find('.pnp-slide.-in').index() - 2;
                        window.pnpHelper.sidenav.setSlide($nextSection.attr('id'), imageIndexVisible, $galleryImages.length);
                    } else {
                        window.pnpHelper.sidenav.setSlide($nextSection.attr('id'));
                        this.beforeLeaveEvents(index, nextIndex);
                    }
                }

                return changeSection;
            }
        }

        this.beforeLeaveEvents(index, nextIndex);

        //If the next section doesn't explicitly  belong in the nav
        if (!$nextSection.data('pnp-skip-sidenav')) {
            if ($nextSection.data('pnp-sidenav-minor-progress')) {
                var progress = $nextSection.data('pnp-sidenav-minor-progress');
                window.pnpHelper.sidenav.setSlide($nextSection.data('pnp-parent-section'), progress[0], progress[1]);
            } else {
                window.pnpHelper.sidenav.setSlide($nextSection.attr('id'));
            }
        }

        if ($thisSection.data('pnp-parent-section') && !$nextSection.data('pnp-parent-section')) {
            this.lastSectionParent = $thisSection.data('pnp-parent-section');
        }

        //Add a leaving class for animation goodness
        // $this.addClass('fp-leaving');
        // $this.addClass('fp-' + direction);
        // setTimeout(function () {
        //     $this.removeClass('fp-leaving');
        //     $this.removeClass('fp-' + direction);
        // }, 800);
    }
    
    getArgs()
    {
        var fullPageOpts = {
            scrollBar: false,
            scrollingSpeed: 800,
            loopHorizontal: false,
            normalScrollElements:'.modal,.gs-modal,.search-results-list,.vue-portal-target,#sidenav',
            parallax: true,
            parallaxOptions: {
                type: 'reveal',
                percentage: 75
                // percentage: pnpHelper.bootstrap.isIpadOrSmaller() && pnpHelper.isTouch() && pnpHelper.browser.isIOS ? 75 : 75
            },
            afterLoad: (anchorLink, index) => {
                return this.afterLoad(anchorLink, index);
            },
            onLeave: (index, nextIndex, direction) => {
                return this.onLeave(index, nextIndex, direction);
            },
            afterRender: () => {
                window.bus.$emit('fullPageAfterDomReady');

                //Fix for ipad where for some reason the first movement of fullpage hangs.
                // Spoof the first movement here so the hang occurs during the loading screen
                if($body.hasClass('doing-trans-wipe') &&
                    pnpHelper.bootstrap.isIpadOrSmaller() &&
                    pnpHelper.bootstrap.isSm() &&
                    pnpHelper.browser.isIOS) {

                    setTimeout(() => {
                        if(window.fullpage) {
                            window.fullpage.moveTo(2);

                            setTimeout(() => {
                                if(window.fullpage) {
                                    window.fullpage.moveTo(1);
                                }
                            }, 100);
                        }

                    }, 300);
                }
            }
        };

        return fullPageOpts;
    }

    beforeLeaveEvents(index, nextIndex)
    {
        /** Locking the scroll on modal open here to avoid a setTimeout for 1500 */
        if ($('body').hasClass('modal-open')) {
            window.fullpage.setAllowScrolling(false);
            return false;
        }
    }
}