<template>
    <div class="hide-during-trans contact-btn-container" style="position: relative">
        <div @click="toggleOpen()" class="btn -call-us">
            <span class="sr-only">Contact Us</span>
            <i class="ion-android-call"></i>
        </div>

        <div class="call-menu" v-show="open" style="max-height: 80vh; overflow:auto;">
            <div class="contact-details">
                USA and Canada<br>
                888-487-5418
            </div>

            <div class="contact-details">
                UK<br>
                01306 880770
            </div>

            <div class="contact-details">
                Europe and ROW<br>
                +44 1306 880770
            </div>

            <div class="contact-details">
                Australia<br>
                +61 800 821 832
            </div>

            <div class="small-text">
                West Coast : 1am-6pm<br>
                East Coast : 4am-9pm<br>
                London : 9am-9pm<br>
                Central Europe : 10am-10pm<br>
                Sydney : 6pm-6am
            </div>

            <div class="contact-details" style="margin-top:30px;">
                <a href="mailto:salesoffice@atrsafari.com">salesoffice@atrsafari.com</a>
            </div>
        
        </div>
    </div>
</template>

<script>
import { current } from 'jquery-scrollify';

    export default {
        data() {
            return {
                open: false
            }
        },
        mounted() {
            document.addEventListener('click', e => {
                const clickedElement = e.target;

                const contactMenu = clickedElement.closest('.contact-btn-container');

                if(!contactMenu){
                    this.open = false;
                }

            });
        },
        methods: {
            toggleOpen(){
                this.open = !this.open;
            }
        }
    }
</script>
