<template>
    <transition name="fade-in">
        <slot />
    </transition>
</template>

<script>
    export default {
        name: "FadeIn"
    }
</script>

<style scoped>

</style>