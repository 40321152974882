<template>
    <span>

        <div class="links-search-sentence-container">
            <search-sentence portalTo="sentencePopups" sentenceId="mainSearchPage" />
        </div>

        <sticky-bar id="search-bar" classes="search-bar row no-gutter" :class="{'-underline' : search.criteria.params.type === 'trip'}">
            <div class="col-xs-8 bar-content lodges">
                <div class="count">
                    <span v-if="isOldDevice">
                        10+
                    </span>
                    <span v-else>
                        {{ search.results.count }}<span v-if="search.results.count === 100">+ </span>
                    </span>
                    {{sentenceTypeByKey(search.criteria.params.type, search.results.count)}}
                </div>
            </div>

            <div class="col-xs-4 bar-content text-right">
                <a class="link -scroll-top" v-on:click="scrollToTop()">TOP <i class="ion-android-arrow-dropup"></i></a>
            </div>

            <div class="clearfix"></div>
        </sticky-bar>

        <public-search-results mode="MainSearch" :doing_search="doingSearch"></public-search-results>

        <div id="veil" class="veil" ref="veil"></div>

        <!-- <transition-wipe/> -->
    </span>
</template>

<script>
    import pageIsReadyMixin from '../../mixins';
    import AtrStorage from '../../global/AtrStorage';
    import GlobalVars from "../../global/GlobalVars";

    export default {
        mixins: [pageIsReadyMixin],
        data() {
            return {
                globals: window.globals,
                searchHasRun: false,
                allLocations: [],
                allFacets: [],
                doingSearch: false,
                initalLoad: false,
                prevRoute: null,
                title: ''
            };
        },
        methods: {
            sentenceTypeByKey(key, count){
                if(count !== 1){
                    return GlobalVars.SENTENCE_KEY_VALUES(key);
                }
                return GlobalVars.SENTENCE_KEY_VALUE_SINGULAR(key);
            },
            scrollToTop(){
                window.pnpHelper.scroll.doScroll(0);
            },
            searchDoSearchEvent(){
                if (this.prevRoute != null && this.prevRoute.name === 'single-trip') {
                    return;
                }

                this.$store.dispatch(window.store.FETCH_AND_SET_SEARCH_RESULTS_COUNT, null, { root: true });

                if(this.search.criteria.params.type !== 'resource' && this.search.criteria.params.type !== 'article' && this.search.criteria.params.type !== 'trip_collection') {
                    this.$store.dispatch(window.store.FETCH_AND_SET_SEARCH_CRITERIA_OPTIONS, null, {root: true});
                }

                this.doSearch();
            },
            doSearch(){
                this.doingSearch = true;
                window.bus.$emit('doingSearch', true);
                this.searchHasRun = true;
                this.initalLoad = true;

                this.$store.dispatch(window.store.FETCH_AND_SET_SEARCH_RESULTS).then((response) => {
                    if (this.search.criteria.params.type === "experience") {
                        //Check that the current article is an experience within the current results
                        var experienceArticleIsSet = false;
                        var currentArticleTitle = this.$store.state.activeArticle.article.title;

                        if(this.$store.state.activeArticle.article.id && this.search.results.data){
                            for(var i=0; i<this.search.results.data.length; i++){
                                if(this.search.results.data[i].title === currentArticleTitle){
                                    experienceArticleIsSet = true;
                                    break;
                                }
                            }
                        }

                        //Set the first result to load on the right hand side
                        if (this.search.results && this.search.results.data && this.search.results.data.length > 0 && !experienceArticleIsSet) {
                            // this.currentArticle = this.searchResults.data[0].id;
                            this.$store.commit(window.store.SET_ACTIVE_ARTICLE, this.search.results.data[0], {root: true});
                        }
                    } else if(this.search.criteria.params.type === "resource" || this.search.criteria.params.type === "article" || this.search.criteria.params.type === "trip_collection") {
                        //Only change the article if we're not coming back from another article
                        if (!$('body').hasClass('doing-trans-article-to-fullpage') && !$('body').hasClass('doing-trans-article-to-halfpage')) {
                            this.$store.commit(window.store.SET_ACTIVE_ARTICLE, this.search.results.data[0], {root: true});
                        }

                        // console.log('article title: ', this.activeArticle);
                        // if(!this.activeArticle.title) {
                        //     console.log('GOT RESOURCES FROM SEARCH, SETTING ARTICLE TO FIRST RESULT', this.activeArticle);
                        //     this.$store.commit(window.store.SET_ACTIVE_ARTICLE, this.search.results.data[0], {root: true});
                        // }
                    } else if((this.search.criteria.params.type === "lodge" || this.search.criteria.params.type === "location")){


                        let typeId;

                        if (this.search.criteria.params.type == 'lodge') {
                            typeId = GlobalVars.LODGE;
                        } else if (this.search.criteria.params.type == 'location') {
                            typeId = GlobalVars.LOCATION;
                        }

                        if(this.$route.name === "wishlist"){
                            window.pnpHelper.map.displaySearchResults(this.searchResults.data);

                        }else {
                            this.$store.dispatch(window.store.FETCH_AND_SET_MAP_MARKERS, typeId).then(() => {
                                if(window.pnpHelper.map) {
                                    window.pnpHelper.map.displaySearchResults(this.searchResults.data, this.$store.state.search.mapMarkers);
                                }
                            });
                        }
                    }

                    if (!_.isUndefined(this.search.criteria.params)) {
                        if(this.search.criteria.params.type !== "resource") {
                            this.title = 'Find the best safari ' + this.search.criteria.params.type + 's in ' + this.search.criteria.params.location.name;

                            if (this.search.criteria.params.type !== "experience" &&
                                this.search.criteria.params.experience &&
                                this.search.criteria.params.experience.name !== 'Any Experience') {
                                this.title += ' for ' + this.search.criteria.params.experience.name;
                            }

                            if (this.search.criteria.params.month != "0") {
                                this.title += ' in ' + (GlobalVars.MONTHS[this.search.criteria.params.month - 1]);
                            }
                        }else{
                            this.title = 'Find the best travel advice for safari holidays'
                        }

                        this.title += ' with Africa Travel Resource.';

                        this.$store.commit(window.store.SET_PAGE_TITLE, this.title);
                    }

                    window.bus.$emit('searchComplete', false);
                    this.doingSearch = false;
                    window.bus.$emit('doingSearch', false);


                    this.declarePageIsReady();
                });
            },
            highlightMap(entity) {
                if (!_.isUndefined(pnpHelper.map)) {
                    if (this.search.results.type === "trip") {
                        pnpHelper.map.renderTripSummary(entity);
                    } else {
                        pnpHelper.map.focusOnMarker(entity.id);
                    }
                }
            },
            highlightArticle(article) {
                this.$store.commit(window.store.SET_ACTIVE_ARTICLE, article, {root: true});
            },
            watchPopState(){
                if(window.location.href.indexOf('/safari-explore/finder') > -1) {
                    // console.log('pop', window.location.href);
                    setTimeout(() => {
                        window.bus.$emit("searchRefresh");
                    });
                }
            }
        },
        computed: {
            search() {
                return this.$store.state.search;
            },
            wishlist() {
                return [...this.$store.state.wishlist.selected];
            },
            searchResults() {
                if(this.$route.name === 'wishlist') {
                    let list = this.wishlist;
                    let type = this.$store.state.search.criteria.params.type;

                    list = this.wishlist.sort((a, b) => {
                        if (_.isUndefined(a['title']) || _.isUndefined(b['title'])) {
                            return true;
                        }

                        return a['title'].toLowerCase().localeCompare(b['title'].toLowerCase());
                    });

                    let data = {
                        data: list.filter((el) =>  el.type === type),
                        type: type,
                    };

                    return data;
                } else {
                    return this.$store.state.search.results;
                }
            },
            activeArticle() {
                return this.$store.state.activeArticle.article
            },
            isOldDevice(){
                return window.globals.oldDevice;
            }
        },
        beforeRouteEnter(to, from, next) {
            /** Need to resolve route before emitting event */
            next((v) => {
                // v.prevRoute = from;
            });

            if((!from.name && from.path.indexOf('/finder/') && to.name === 'finder')){
                //If the route being changed from a ppc finder page to the finder page proper
                // (e.g. someone changed a search criteria) then don't refresh the search (the
                // update of that type will do that).
            }else {
                window.bus.$emit("searchRefresh");
            }
        },
        mounted() {
            window.bus.$emit('setShowBackButton', false);
            window.bus.$on('searchDoSearch', this.searchDoSearchEvent);
            $(window).on('popstate', this.watchPopState);

            setTimeout(this.searchDoSearchEvent, 500);
        },
        destroyed() {
            window.bus.$off('searchDoSearch', this.searchDoSearchEvent);
            $(window).off('popstate', this.watchPopState);
        },
    }
</script>
