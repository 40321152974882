<script>
    import Slide from '../presentational/Slide.vue';

    export default {
        props: ['slide', 'section_id', 'section_name', 'type', 'edit', 'item_title', 'slide_index', 'firstVideo'],
        render(createElement) {
            var props = {...this.slide};

            props.firstVideo = this.firstVideo;
            props._id = this.slide.id;
            props.type = this.type;
            props.edit = this.edit;
            props.section_name = this.section_name;
            props.section_id = this.section_id;
            props.item_title = this.item_title;
            props.slide_id = this.slide.id;
            props.slide_index = this.slide_index;

            return createElement(Slide, { props: props });
        }
    }
</script>