import router from '../router';

Vue.directive('filter-content', {
    bind(el, binding) {
        // let dictionary = _.isUndefined(binding.value) ? window.CONTENT_FILTER_DICTIONARY_DEFAULT : binding.value;
        // el.contentFilter = new FilterContent(el.innerHTML, dictionary);
        // el.innerHTML = el.contentFilter.content;
    },
    update(el) {
        // el.contentFilter._content = el.innerHTML;
        // el.innerHTML = el.contentFilter.content;
    },
});

class FilterContent {
    constructor(content, dictionary) {
        this._content = content;
        this.dictionary = dictionary;
    }

    get content() {
        if (window.location.href.indexOf('edit=1') !== -1 || router.currentRoute.name === 'single-resource') {
            return this._content;
        }

        _.forEach(this.dictionary, (value) => {
            this._content = this._content.replace(new RegExp(value.old, 'g'), value.new);
        });

        return this._content;
    }
}