<template>
    <section class="component-text -outs section wishlist-add"
             data-pnp-half-map="true"
             data-pnp-interactive-map="true"
             v-if="item.id !== false">

        <div class="left-panel no-padding wishlist-add">
            <div class="inner" data-pnp-heading="Save" v-if="!hideHeading">
                <div v-if="!hideHeading" class="number-container"><span class="yellow-bar"></span>01</div>

                <h2 v-if="!hideHeading" class="heading4">Save</h2>

                <p v-if="type !== 'experience'" class="content">If you like this look of this {{type}} or area, then you can add them to your wishlist …</p>
            </div>

            <div class="suggested -no-padding">
                <ul class="terms">
                    <li class="wishlist-term">
                        <a @click="toggleWishlist(item.id)"
                           :class="{active: inWishlist(item.id)}"
                           class="wishlist-add">

                            <div class="icon-container -size-4">
                                <i class="ion-ios-heart"></i>
                            </div>

                            <span v-if="!inWishlist(item.id)">{{ name }}</span>

                            <span v-else>Remove {{ name }} from wishlist</span>
                        </a>
                    </li>

                    <template v-if="this.item.parents">
                        <li v-for="(parent, index) in parents"
                            v-if="parent.title !== item.title && parent.title"
                            class="wishlist-term"
                            :class="{'-no-padding-bottom': index == parents.length - 1}">

                            <a @click="toggleWishlist(parent.id, 'location')"
                               :class="{active: inWishlist(parent.id)}"
                               class="wishlist-add">

                                <div class="icon-container -size-4">
                                    <i class="ion-ios-heart"></i>
                                </div>

                                <span v-if="!inWishlist(parent.id)">Add {{ parent.title }} to wishlist</span>

                                <span v-else>Remove {{ parent.title }} from wishlist</span>
                            </a>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        props: {
            hideHeading: {
                type: Boolean,
                default: false
            },
            item: {
                type: Object,
                required: true
            },
            name: {
                type: String,
            },
            type: {
                type: String,
                required: true
            }
        },
        methods: {
            toggleWishlist(id, type){
                let payload = {
                    id: id,
                    type: type ? type : this.type
                };

                let check = this.wishlist.some((el) => {
                    return el.id == id;
                });

                if(check) {
                    this.$store.commit(window.store.REMOVE_WISHLIST_ITEM, payload);
                } else {
                    this.$store.dispatch(window.store.SET_WISHLIST_ITEM_BY_ID, payload);
                }
            },
            inWishlist(id){
                if(this.wishlist && this.item) {
                    return this.wishlist.some((el) => {
                        return el.id == id;
                    });
                }
            }
        },
        computed: {
            wishlist(){
                return this.$store.state.wishlist.selected;
            },
            parents(){
                let parents = this.item.parents.data

                let firstItem = parents[0];

                let lastItem = parents[parents.length - 1].title !== firstItem.title ? parents[parents.length - 1] : {} ;

                let criticalArrayLength = parents.filter((el) => el.critical).length;
                    criticalArrayLength = (criticalArrayLength - 1) < 0 ? 0 : criticalArrayLength - 1;

                let criticalItem = parents.filter((el) => {
                   return el.critical == true && (el.title !== lastItem.title || el.title !== firstItem.title)
                });

                criticalItem = criticalItem[criticalArrayLength] !== undefined ? criticalItem[criticalArrayLength] : {};

                let outs = [
                    lastItem,
                    criticalItem,
                    firstItem
                ];

                //
                // if(this.type == 'location') {
                //     return _.uniqBy(outs, 'title');
                // }

                return _.uniqBy(outs, 'title');
            }
        },
        mounted(){
        },
        created(){
            this.$store.commit(window.store.FETCH_AND_SET_WISHLIST);
        }
    }
</script>
