export default class Scroll {
    keys = {
        32: 1, //spacebar
        33: 1, //pageup
        34: 1, //pagedown
        35: 1, //end
        36: 1, //home
        37: 1, //left
        38: 1, //up
        39: 1, //right
        40: 1  //down
    };
    supportsPassive = false;
    wheelOpt = false;
    wheelEvent = 'mousewheel';

    constructor() {
        try {
            window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                get: () => {
                    this.supportsPassive = true;
                }
            }));
        } catch (e) {
        }
        this.wheelOpt = this.supportsPassive ? {passive: false} : false;
        this.wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    }

    preventDefault(e) {
        e = e || window.event;
        if (e.preventDefault)
            e.preventDefault();
        e.returnValue = false;
    }

    preventDefaultForScrollKeys(e) {
        try {
            if (this.keys[e.keyCode]) {
                this.preventDefault(e);
                return false;
            }
        } catch (e) {

        }
    }

    disableScroll() {
        window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
        window.addEventListener(this.wheelEvent, this.preventDefault, this.wheelOpt); // modern desktop
        window.addEventListener('touchmove', this.preventDefault, this.wheelOpt); // mobile
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
    }

    enableScroll() {
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.removeEventListener(this.wheelEvent, this.preventDefault, this.wheelOpt);
        window.removeEventListener('touchmove', this.preventDefault, this.wheelOpt);
        window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
    }
}
