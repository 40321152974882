import { mapState } from 'vuex';
import SeoManager from "./SeoManager";
import SchemaData from "./SchemaData";

export default {
    data() {
        return {
            transitionName: 'wipe',
            transitionMode: 'out-in',
            transitionDuration: 1000, //Note, corresponding speed in wipe.scss needs changing
            transitionBlanketTime: this.transitionBlanketTime = this.transitionDuration/2 + 200, //The time in the transition when nothing is visible (magic things can happen in that time)
            transitionKey: null,

            previousPageScrollPosition: {
                yOffset: null, //The y scroll position on the body
                section: null, //For hijack page sections
                slide: null //For slides within hijack page sections
            },

            customScrollPosition: null,

            fwdBtn: {
                url: false,
                isClicked: false,
                transitionName: false
            },
            backBtn: {
                url: false,
                isClicked: false,
                transitionName: false
            }
        }
    },
    computed: {
        transitionManager() {
            return this.$store.state.transitionManager;
        },
        ...mapState({
            fromSearch: state => state.transitionManager.fromSearch,
            pageDepth: state => state.transitionManager.pageDepth
        })
    },
    watch: {
        '$route'(oldTo, oldFrom) {

            this.exploreModeHistory(oldTo, oldFrom)


            var preventTransitions = false;

            window.bus.$emit('clear-map-search');

            //Prevent transitions/any route udpates on map params being added to urls
            if(oldTo && oldFrom && oldTo.name === oldFrom.name){

                var toMap = oldTo.query.map ? oldTo.query.map : null;
                var fromMap = oldFrom.query.map ? oldFrom.query.map : null;

                var toPopover = oldTo.query.popover ? oldTo.query.popover : null;
                var fromPopover = oldFrom.query.popover ? oldFrom.query.popover : null;

                if(toMap !== fromMap || toPopover !== fromPopover){
                    preventTransitions = true;
                }
            }

            let from = {...oldFrom};
            let to = {...oldTo};

            if(Object.keys(to.query) == 'open'){

            }

            // if(to.query)

            if(from && !from.name && from.path.indexOf('/safari-explore/') > -1){
                from.name = "finder";
            }

            if(to && !to.name && to.path.indexOf('/safari-explore/') > -1){
                to.name = "finder";
            }


            if(window.globals.oldDevice
                && (from && from.name && to && to.fullPath)
                && from.name !== to.name
                && !(from.fullPath.indexOf('/safari-explore/') > -1 && to.fullPath.indexOf('/safari-explore/') > -1)) {
                window.location.href = to.fullPath;
                return;
            }


            //console.log('--------------------------------------------------------');
            // console.log('DOING TRANSITION FROM ' + from.name + ' TO ' + to.name);

            /** Reset to defaults first */
            this.transitionName = 'wipe';
            this.transitionMode = 'out-in';
            this.transitionDuration = 1000; //Note, corresponding speed in wipe.scss needs changing
            this.transitionBlanketTime = this.calcBlanketTime(); //The time in the transition when nothing is visible (magic things can happen in that time)


            //If transition name set in route.js
            if(to.meta.transitionName){
                //console.log('got transition from meta: ' + to.meta.transitionName);
                this.$store.commit(window.store.SET_TRANSITION, to.meta.transitionName); //Dont know what this is doing
            }


            //If explicit transition is set, show back button?
            if(this.transitionManager.transitionToUse !== false) {
                // console.log('got trans to use, showing back button');
                window.bus.$emit('setShowBackButton', true);

            }else if(!$('body').hasClass('showing-back-button')){
                // console.log('page is not show back button, scrolling to top');

                //First time on the page, ensure scrolled to the top
                //this.setScrollPosition(null, 0);
            }


            //Back button clicked
            if (this.backBtn.isClicked === true) {
                // console.log('back button clicked');

                this.customScrollPosition = this.previousPageScrollPosition;
                //console.log('customScrollPosition', this.customScrollPosition);

                this.transitionName = this.backBtn.transitionName;
                this.fwdBtn.transitionName = this.backBtn.transitionName;
                this.fwdBtn.url = from.fullPath;
                this.backBtn.transitionName = false;

                if(this.transitionName === 'article-to-halfpage' || this.transitionName === 'article-to-fullpage'){
                    this.transitionDuration = 800;
                }

                // if(this.transitionName === 'article-to-fullpage'){
                //     this.transitionDuration = 5000;
                // }


                //Forward button clicked
            } else if (this.fwdBtn.isClicked === true) {
                //console.log('forward button clicked');

                this.customScrollPosition = this.previousPageScrollPosition;

                this.transitionName = this.fwdBtn.transitionName;
                this.backBtn.transitionName = this.fwdBtn.transitionName;
                this.backBtn.url = from.fullPath;
                this.fwdBtn.transitionName = false;


            } else {
                // console.log('forward and back not clicked');

                if (this.transitionManager.transitionToUse !== false) {
                    //console.log('found explicit transition, using ' + this.transitionManager.transitionToUse);

                    this.transitionName = this.transitionManager.transitionToUse;
                    this.$store.commit(window.store.SET_TRANSITION, false); //Dont know what this is doing


                    if(this.transitionName === 'article-to-halfpage' || this.transitionName === 'article-to-fullpage'){
                        //console.log('is ' + this.transitionName + ' so, setting duration of 1 second');

                        this.transitionDuration = 1000;
                        this.transitionBlanketTime = this.calcBlanketTime();
                    }
                }

                //console.log('setting back button transition to ' + this.transitionName);
                this.backBtn.transitionName = this.transitionName;
                this.backBtn.url = from.fullPath;
            }


            //Always use wipe for mobile
            if(pnpHelper.bootstrap.isIpadOrSmaller() && pnpHelper.isTouch()){
                this.fwdBtn.transitionName = 'wipe';
                this.backBtn.transitionName = 'wipe';
                this.transitionName = 'wipe';
                this.transitionMode = 'out-in';
                this.transitionDuration = 1000;

                if(pnpHelper.browser.isIOS && pnpHelper.bootstrap.isSm()){
                    this.transitionDuration = 2000;
                }

            }

            this.backBtn.isClicked = false;
            this.fwdBtn.isClicked = false;
// console.log(from);
// console.log(to);
            if(from.name !== to.name) {
                //TODO: Only do the below when ATR back button was clicked
                if(to.name === "finder" && from && from.name && from.name.match(/(single)/g) && $('body').hasClass('back-btn-clicked')){
                    this.customScrollPosition = this.previousPageScrollPosition;
                    //console.log('customScrollPosition', this.customScrollPosition);
                } else if(to.name.match(/(single)/g) && from.name==="finder"){
                    this.customScrollPosition = 0;
                    //console.log("single res scroll", this.customScrollPosition);
                }
                 //console.log('previousPageScrollPosition', this.previousPageScrollPosition);
            }

            //Store the position of the page for scroll hijack
            if($('body').hasClass('page-scroll-hijacked')){
                this.previousPageScrollPosition = {
                    yOffset: this.previousPageScrollPosition.yOffset,
                    section :$('.section.active').index() + 1,
                    slide: $('.section.active .pnp-slide.-in').length ? $('.section.active .pnp-slide.-in').index() - 2 : null
                };

                // console.log('set previous section to ' + this.previousPageScrollPosition.section);
            }else {
                this.previousPageScrollPosition = {
                    yOffset: $('body').hasClass('showing-article-half') && pnpHelper.bootstrap.isMd() ? $('.article-content > .content-panel').scrollTop() : $('html, body').scrollTop(),
                    section: null,
                    slide: null
                }
            }

            if(from.name === to.name && from.name.indexOf('single-') > -1 && !$('body').hasClass('-disable-page-wipe')){
                //Get a unique key to force a page transition
                this.transitionKey = new Date().getTime();
            }

            //There is no blanket time if we didn't come from anywhere
            if(!from || !from.name){
                //console.log('setting blanket time to 0');
                this.transitionBlanketTime = 0;
            }

            // console.log('DOING TRANSITION WITH: \n Name: ' + this.transitionName + ' \n Duration: ' + this.transitionDuration + ' \n transitionBlanketTime: ' + this.transitionBlanketTime);
                this.updateBodyPageClass(to.name, from.name, this.transitionBlanketTime);
                this.updateBodyRouterClass(to, from, this.transitionBlanketTime);
            if(preventTransitions === false) {
                this.updateBodyTransClass(this.transitionName, this.transitionDuration, to, from);
                this.doBlanketTasks(this.transitionBlanketTime);
            }

            /** Flag to component to not fade in and up upon re-entry */
            if (to.name === 'search') {
                $('body').addClass('changed-route');
            }

            if((to.name === 'contact-call' || to.name === 'call-back' || to.name === 'quote' || to.name === 'email')) {

                this.$store.commit(window.store.INCREMENT_PAGE_DEPTH, true);

            } else if((to.name && (!!to.name.match(/(single)/g)))) {

                this.$store.commit(window.store.INCREMENT_PAGE_DEPTH, true);

            } else {
                this.$store.commit(window.store.RESET_PAGE_DEPTH);
            }

            SeoManager.removeJsonSchema('single');
            SeoManager.removeCanonicalUrl();

        }
    },
    mounted() {
        this.updateBodyPageClass(this.$route.name, null, null);

        SeoManager.addJsonSchema(new SchemaData().getNavigationSchema(), 'global');
        SeoManager.addJsonSchema(new SchemaData().getSignificantLinksSchema(), 'global');

        $(window).on('popstate', () => {
            let destinationUrl = window.location.href.replace(window.location.origin, '');

            if (destinationUrl === this.backBtn.url) {
                this.backBtn.isClicked = true;
            } else if (destinationUrl === this.fwdBtn.url) {
                this.fwdBtn.isClicked = true;
            }
        });
    },
    created(){
        window.bus.$on('setShowBackButton', this.setShowBackButtonEvent);
    },
    destroyed(){
        window.bus.$off('setShowBackButton', this.setShowBackButtonEvent);
    },
    methods: {
        exploreModeHistory(to, from){
            let searchType = this.$store.state.search.criteria.params.type;

            if(to.name == 'directory' && (searchType == 'lodge' || searchType == 'location')){

                this.$store.commit(window.store.SET_EXPLORE_TYPE, to.name);

            } else if(to.name == 'finder' && (searchType == 'lodge' || searchType == 'location')){

                this.$store.commit(window.store.SET_EXPLORE_TYPE, to.name);

            }
        },
        setShowBackButtonEvent(showBackButton){
            setTimeout(() => {
                if(showBackButton){
                    $('body').addClass('showing-back-button');
                }else if($('body').hasClass('showing-back-button')){
                    $('body').removeClass('showing-back-button');
                }
            }, this.transitionDuration);
        },
        updateBodyPageClass(routeNameToAdd = null, routeNameToRemove = null, timeout) {
            function doClassChange(){
                if (routeNameToRemove != null) {
                    $('body').removeClass('page-' + routeNameToRemove);
                }

                if (routeNameToAdd != null) {
                    $('body').addClass('page-' + routeNameToAdd);
                }
            }

            if (routeNameToAdd == null && !_.isUndefined(this.$route.meta.component_name_body_class)) {
                routeNameToAdd = this.$route.meta.component_name_body_class;
            }

            if(timeout && timeout > 0){
                setTimeout(doClassChange, timeout);
            }else{
                doClassChange();
            }
        },
        updateBodyRouterClass(to, from, timeout) {
            function doClassChange() {
                //If there's a to and a from
                if (to != null && to.meta.cssClass != null && from != null && from.meta.cssClass != null) {
                    $('body').removeClass(from.meta.cssClass).addClass(to.meta.cssClass);

                } else if (from != null && from.meta.cssClass != null) {
                    $('body').removeClass(from.meta.cssClass);

                } else if (to != null && to.meta.cssClass != null) {
                    $('body').addClass(to.meta.cssClass);
                }
            }

            if(timeout && timeout > 0){
                setTimeout(doClassChange,timeout);
            }else{
                doClassChange();
            }
        },
        updateBodyTransClass(transitionName, transitionDuration, to, from){
            let className = 'doing-trans-'+transitionName + ' trans-to-' + to.name + ' trans-from-'+from.name;

            // if($('body').hasClass('nav-open')){
            //     className += ' trans-from-nav';
            // }

            if(from && from.name == to.name){
                className += ' trans-to-same-page';
            }

            $('body').addClass(className);

            setTimeout(() => {
                $('body').removeClass(className);
            }, transitionDuration * 2);
        },
        doBlanketTasks(timeout){
            if(timeout) {
                setTimeout(() => {
                    window.bus.$emit('do-blanket-tasks');

                    setTimeout(function(){
                        if(pnpHelper.bootstrap.isIpadOrSmaller()) {
                            $('.vh-fix-once').css('height', $('#first-load-veil').outerHeight() + 'px').removeClass('.vh-fix-once');
                        }
                    },1000);
                }, timeout);
            }
        },
        calcBlanketTime(){
          return this.transitionDuration/2 + 200;
        },
        transBeforeLeave(){
            $('body').removeClass('transition-finished').addClass('transition-in-progress');
        },
        transAfterLeave(){
            $('body').addClass('transition-finished').removeClass('transition-in-progress');

            setTimeout(() => {
                $('body').removeClass('transition-finished')
            }, this.transitionDuration*3);
        },
        transEnter(){
            setTimeout(() => {
                if (this.customScrollPosition !== null) {
                    // console.log('got customScrollPosition', this.customScrollPosition );

                    if (this.customScrollPosition.section !== null) {
                        // console.log('SCROLLING TO SECTION ' + this.customScrollPosition.section);
                        let hijackScrollPosition = this.customScrollPosition;

                        //Its a section of a fullpage hijack page
                        window.bus.$once('fullPageAfterDomReady', function(){
                            let $section = $('body .section').eq(hijackScrollPosition.section - 1);

                            //Go to the correct section
                            window.fullpage.moveTo(hijackScrollPosition.section);

                            //Move all prior galleries to the end
                            pnpHelper.imageGallery.moveGalleriesToEnd(hijackScrollPosition.section - 1);

                            //Go to the correct slide
                            if(hijackScrollPosition.slide !== null) {
                                pnpHelper.imageGallery.moveToImage($section,hijackScrollPosition.slide);
                            }
                        });

                    }

                    if (this.customScrollPosition.yOffset !== null){
                        // console.log('SETTING SCROLL TO ', this.customScrollPosition.yOffset);

                        let y = this.customScrollPosition.yOffset;
                        let el = $('body').hasClass('showing-article-half') && pnpHelper.bootstrap.isMd() ? $('.article-content > .content-panel') : $('html, body');

                        let scrollToY = () => {
                            el.scrollTop(y);
                        };

                        let delayedScrollToY = () => {
                            setTimeout(() => {
                                el.scrollTop(y);
                            },300);
                        };

                        setTimeout(scrollToY);

                        //If coming from a hijack page, wait till it's destroyed
                        window.bus.$once('fullPageDestroyed', scrollToY);
                        window.bus.$once('pageIsReady', delayedScrollToY);

                        setTimeout(() => {
                            window.bus.$off('fullPageDestroyed', scrollToY);
                            window.bus.$off('pageIsReady', delayedScrollToY);
                        }, this.transitionDuration);
                    }

                    this.customScrollPosition = null;
                } else {
                    // console.log('SCROLLING TO TOP');

                    if(this.$route.hash && this.$route.hash.includes('section')){
                        let openAllSections = false;
                        let sectionIndex;

                        if(this.$route.hash.includes('open-section')){
                            sectionIndex = this.$route.hash.replace('#open-section', '');

                            //Open all the 'view all' buttons on the page
                            openAllSections = true;

                        }else {
                            sectionIndex = this.$route.hash.replace('#section', '');
                        }

                        //If the section passed in is an int
                        if(!isNaN(parseInt(sectionIndex))){
                            sectionIndex = parseInt(sectionIndex);

                            window.bus.$once('sidenavReady', function(){
                                if(openAllSections){
                                    //Open all the sections on the page first
                                    window.bus.$emit('openViewAllSections');

                                    setTimeout(() => {
                                        window.fullpage.moveTo(sectionIndex);
                                    },1000);
                                }else {
                                    window.fullpage.moveTo(sectionIndex);
                                }
                            });
                        }
                    }else {

                        $(window).scrollTop(0);
                    }
                }
            });
        }
    }
};

