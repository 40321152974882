<template>
    <div v-if="searchResult" v-waypoint="{ active: waypointActive, callback: onWaypoint, options: intersectionOptions }" :class="{'card-active': currentSearchResult == searchResult.id}">
        <div v-if="this.$route.name === 'directory'" class="directory-close-btn" @click="close">
            <i class="ion-android-close"></i>
        </div>

        <div class="button-fav visible-xs"
             @click="toggleItem(searchResult, $event)"
             v-bind:class="{ on: checkIfOn(searchResult) }"
             aria-label="Add to Wishlist">
        </div>

        <!--This reserves space for the slider on mobile-->
        <img src="/assets/img/placeholder.png" alt="Placeholder" class="visible-xs img-responsive mob-placeholder"/>

        <div class="col-caption">
            <p class="sub-text">
                {{searchResult.location}}
            </p>

            <div class="heading2 heading">
                <button-internal-presentational :router_object="{ name: 'single-lodge', params: params }">
                    {{searchResult.title}}
                </button-internal-presentational>

                <div class="button-fav hidden-xs"
                     @click="toggleItem(searchResult, $event)"
                     :class="{ on: checkIfOn(searchResult) }"
                     aria-label="Add to Wishlist">
                </div>
            </div>

            <button-internal-presentational :router_object="{ name: 'single-lodge', params: params }">
                <div class="content" v-html="searchResult.content"></div>
            </button-internal-presentational>
        </div>

        <div class="stats" :class="{'-show-4' : searchResult.conservation_rating >= 7 && (search.criteria.params.experience && search.criteria.params.experience.id != 3)}">
            <info-container classes="col-rating" :value="searchResult.rating ? searchResult.rating : '0.0'">
                <template slot="header">
                    <div class="sub-text">LODGE <br/>RATING</div>
                </template>

                <template slot="popup-header">
                    <p>Lodge rating</p>
                </template>

                <template slot="popup-text">
                    <p>
                        This number represents the <strong>overall rating</strong> for <strong>{{searchResult.title}}</strong>
                        <span v-if="month <= 0"> in <strong>any month</strong>,</span>
                        <span v-else> in <strong>{{ months[month - 1] }}</strong>,</span>
                        which takes into account all the various component ratings of a property, its location,
                        build, style, service level and experiences.
                    </p>

                    <div class="links">
                        <button-internal-presentational
                                classes="link"
                                :router_object="{path: hardcodedUrls.resources.overallRating}"
                                transition_name="wipe">
                            Our ratings system
                        </button-internal-presentational>
                    </div>
                </template>
            </info-container>

            <div class="col-price" :class="{'min-price' : searchResult.min_price}">
                <span class="heading2 price">
                    <price :price="searchResult.price" :minPrice="searchResult.min_price">
                         <template slot="header">
                             <span class="sub-text" v-if="searchResult.min_price">
                                GUIDE PRICE {{ this.$store.state.user.user.selected_currency}}<br/>PER PERSON PER NIGHT
                             </span>
                             <span v-else class="sub-text">
                                GUIDE PRICE <br/>{{ this.$store.state.user.user.selected_currency}} PPPN
                             </span>
                         </template>

                        <template slot="popup-header">
                            <p>Lodge guide price</p>
                        </template>

                        <template slot="popup-content">
                            <p>

                                These numbers represent the approximate range of price per person per night in
                                <span v-if="month <= 0"><strong>any month</strong></span>
                                <span v-else><strong>{{ months[month - 1] }}</strong></span>
                                to stay at
                                <strong>{{searchResult.title}}</strong>.
                                The lower end is the average low season <strong>lodge</strong> price.
                                The upper end is the average high season <strong>lodge</strong> price plus any extras
                                which usually need to be included,
                                such as meals, drinks and activities, transport costs and park fees.

                            </p>

                            <div class="links">
                                <button-internal-presentational
                                        classes="link"
                                        :router_object="{path: hardcodedUrls.resources.priceGuarantee}"
                                        transition_name="wipe">
                                    Price guarantee</button-internal-presentational> :

                                <button-internal-presentational
                                        classes="link"
                                        :router_object="{path: hardcodedUrls.resources.howMuchToSpend}"
                                        transition_name="wipe">
                                    How much to spend</button-internal-presentational> :

                                <button-internal-presentational
                                        classes="link"
                                        :router_object="{path: hardcodedUrls.resources.howToSaveMoney}"
                                        transition_name="wipe">
                                    How to save money</button-internal-presentational>
                            </div>
                        </template>
                    </price>
                </span>
            </div>

            <info-container v-if="showExperienceStat"
                            classes="col-rating"
                            :value="searchResult.search_rating">

                <template slot="header">
                    <div class="sub-text">{{ search.criteria.params.experience.name }} <br/>RATING</div>
                </template>

                <template slot="popup-header">
                    <p>{{ search.criteria.params.experience.name }} rating</p>
                </template>

                <template slot="popup-text">
                    <p>
                        This number represents the rating for <strong>{{ search.criteria.params.experience ? search.criteria.params.experience.name.toLowerCase() : '' }}</strong> at <strong>{{searchResult ? searchResult.title : ''}}</strong> in
                        <span v-if="month <= 0"><strong>any month</strong></span>
                        <span v-else><strong>{{ months[month - 1] }}</strong></span>
                        and should be a good indication of the level of experience that you can expect when visiting at that time.
                    </p>

                    <div class="links">
                        <button-internal-presentational
                                classes="link"
                                :router_object="{path: hardcodedUrls.resources.overallRating}"
                                transition_name="wipe">
                            Our ratings system
                        </button-internal-presentational>
                    </div>
                </template>
            </info-container>

            <info-container v-if="searchResult.conservation_rating >= 7 && !showExperienceStat"
                            classes="col-rating hidden-xs"
                            :value="searchResult.conservation_rating">

                <template slot="header">
                    <div class="sub-text">CONSERVATION <br/>RATING</div>
                </template>

                <template slot="popup-header">
                    <p>Lodge conservation rating</p>
                </template>

                <template slot="popup-text">
                    <p>The conservation rating is an indication of how much a particular location or lodge needs your visit to further the protection of their landscapes and wildlife.</p>
                    <p>We recommend that you consider including at least one lodge with a high conservation rating in your trip.</p>

                    <div class="links">
                        <button-internal-presentational
                                classes="link"
                                :router_object="{path: hardcodedUrls.resources.conservation}"
                                transition_name="wipe">mapboxgl-popup
                            Conservation rating
                        </button-internal-presentational>
                    </div>
                </template>
            </info-container>
        </div>

        <public-search-image-slider type="lodge"
                                    v-if="searchResult.images"
                                    :cover-image="searchResult.images.data.sizes.low"
                                    :caption="searchResult.images.data.caption"
                                    :button="searchResult.images.data.buttons.data[0]"
                                    :id="Number(searchResult.id)">
        </public-search-image-slider>

        <div class="sr-footer">
            <a class="sr-cta -dropdown" :class="{'-loading link-disabled': loadingRelated, '-open': visibleRelatedResults.includes(searchResult.id)}" @click="getRelated">
                Smart links
                <i class="ion-ios-arrow-down"></i>
            </a>

            <button-internal :router_object="{ name: 'single-lodge', params: params }" classes="sr-cta -arrow">
                Go to lodge
                <i class="ion-ios-arrow-thin-right"></i>
            </button-internal>

            <div class="clearfix"></div>

            <transition name="slide-down">
                <div class="related-searches-container" v-if="visibleRelatedResults.includes(searchResult.id)">

                    <outs-wishlist :classes="['smart-wishlist']"
                                   :list="[{title:searchResult.title, id: searchResult.id, type:'lodge'}, ...relatedItems]"
                                   itemType="LODGE">
                    </outs-wishlist>

                    <!--<outs-list :classes="['related-searches']"-->
                               <!--:prefix="['Best trips including']"-->
                               <!--:list="relatedItems"-->
                               <!--:types="['trip']"-->
                               <!--:retainSearch="true">-->
                    <!--</outs-list>-->

                    <best-trips-link></best-trips-link>

                    <outs-list :classes="['related-searches']"
                               :prefix="['Best lodges in']"
                               :list="[...relatedItems,{title:'Africa', id: '0', type:'location'}]"
                               :types="['lodge']"
                               :retainSearch="true">
                    </outs-list>

                    <ul class="related-searches">
                        <li>
                            <button-internal-presentational
                                    class="link"
                                    :router_object="{ name: 'contact-call' }"
                                    v-on:btn-internal-clicked="googleAnalytics.fireEvent('Call', 'Click Contact Call Us', $route.path)">
                                Call us
                            </button-internal-presentational>
                        </li>
                        <li>
                            <button-internal-presentational
                                    class="link"
                                    :router_object="{ name: 'call-back' }"
                                    v-on:btn-internal-clicked="googleAnalytics.fireEvent('Call Back', 'Click Contact Call Back', $route.path)">
                                Get us to call you
                            </button-internal-presentational>
                        </li>
                        <li>
                            <button-internal-presentational
                                    class="link"
                                    :router_object="{ name: 'quote' }"
                                    v-on:btn-internal-clicked="googleAnalytics.fireEvent('Quote', 'Click Contact Inquiry', $route.path)">
                                Make a travel inquiry
                            </button-internal-presentational>
                        </li>
                        <!--<li>-->
                            <!--<button-internal-presentational-->
                                    <!--class="link"-->
                                    <!--:router_object="{ name: 'email' }"-->
                                    <!--v-on:btn-internal-clicked="googleAnalytics.fireEvent('Email', 'Click Contact Email', $route.path)">-->
                                <!--Send us an email-->
                            <!--</button-internal-presentational>-->
                        <!--</li>-->
                    </ul>
                </div>
            </transition>
        </div>
    </div>

</template>

<script>
    import ButtonInternal from "../../partials/presentational/ButtonInternal";
    import GlobalVars from "../../../global/GlobalVars";
    import { mapState } from 'vuex';

    export default {
        props: [
            'type',
            'searchResult',
            'search',
            // 'toggleItem',
            'toggleRelatedSearches',
            'selectedItems',
            'visibleRelatedResults',
            'directory'
        ],
        components: {
            ButtonInternal
        },
        data(){
            return {
                loadingRelated: false,
                months: [],
                relatedItems: [],
                waypointActive: true,
                intersectionOptions: {
                    root: null,
                    rootMargin: '0px',
                    threshold: 0.7,
                    currentId: null
                },
                currentSearchResult: null
            }
        },
        methods: {
            close() {
              this.$emit('close');
            },
            onWaypoint({ direction, el }){
                if(direction && this.waypointActive) {
                    this.highlightMap(this.searchResult);
                }
            },
            activateWaypoint(isActive){
                if(pnpHelper.bootstrap.isSm()) {
                    this.waypointActive = isActive;
                }else{
                    this.waypointActive = false;
                }
            },
            toggleItem(searchItem, event) {
                event.preventDefault();

                let newSearchItem =  _.defaultsDeep(searchItem, {
                    type: 'lodge'
                });

                let newSelectedItems = [...this.wishlist];

                let check = newSelectedItems.some((el) => {
                    return el.id == newSearchItem.id;
                });

                if(check){
                    newSelectedItems = newSelectedItems.filter((el) => {
                        return el.id != newSearchItem.id;
                    });

                    this.$store.commit(window.store.SET_WISHLIST, newSelectedItems);
                } else {
                    newSelectedItems.push(newSearchItem);
                    this.$store.commit(window.store.SET_WISHLIST, newSelectedItems);
                }
            },
            checkIfOn(obj) {
                return this.wishlist.some((el) => {
                    return el.id == obj.id;
                });
            },
            highlightMap(entity) {
                if(!_.isUndefined(pnpHelper.map) && !pnpHelper.page.isDirectoryPage()) {
                    pnpHelper.map.focusOnMarker(entity.id);
                }
            },
            getRelated(event, preventToggle){
                if(this.visibleRelatedResults.includes(this.searchResult.id) && !preventToggle){
                    this.toggleRelatedSearches(this.searchResult.id);
                    return;
                }

                this.loadingRelated = true;

                this.$store.dispatch(window.store.FETCH_AND_RETURN_LODGE_PARENTS, this.searchResult.vue_route_params.data.slug)
                    .then((res) => {
                        let resArr = [
                            res.data[res.data.length - 1],
                            res.data[res.data.length - 2],
                            res.data[0]
                        ];

                        res = [...resArr].map((el) => {
                            return {
                                ...el,
                                type: 'location'
                            }
                        });

                        this.relatedItems = _.uniqBy(res, 'title');

                        if(!preventToggle) {
                            this.toggleRelatedSearches(this.searchResult.id);
                        }

                        this.loadingRelated = false;
                    });
            },
            setCurrentSearchResult(id){
                this.currentSearchResult = id;
            }
        },
        computed: {
            showExperienceStat(){
              return this.$route.name !== 'directory' &&
                  this.$route.name !== 'wishlist' &&
                  (this.search.criteria.params.experience && this.search.criteria.params.experience.id != 3);
            },
            hardcodedUrls() {
                return GlobalVars.HARCODED_URLS;
            },
            url() {
                if(this.searchResult.url) {
                    return this.searchResult.url;
                }
                return this.searchResult.links.self
            },
            params(){
                return {
                    directory: this.directory ? this.directory : false,
                    ...this.searchResult,
                    ...this.searchResult.vue_route_params.data
                }
            },
            googleAnalytics() {
                return window.GoogleAnalytics;
            },
            ...mapState({
                searchType: state => state.search.criteria.params.type,
                month: state => state.search.criteria.params.month,
                wishlist: state => state.wishlist.selected,
                searchLocation: state => state.search.criteria.params.location.name,
            })
        },
        mounted(){
            this.months = GlobalVars.MONTHS;

            if(this.visibleRelatedResults.includes(this.searchResult.id)) {
                this.getRelated(null, true);
            }
        },
        created(){
            window.bus.$on('activate_waypoints', this.activateWaypoint);
            bus.$on('focussedOnMarker', this.setCurrentSearchResult);
        },
        destroyed() {
            window.bus.$off('activate_waypoints', this.activateWaypoint);
            bus.$off('focussedOnMarker', this.setCurrentSearchResult);
        }
    }
</script>
