<template>
    <div class="information-popup" :class="position" ref="information_popup" :style="css">
        <div class="inner-panel">
            <div class="burger-container" @click="closeModal">
                <div class="burger -in">
                    <i></i>
                </div>
            </div>

            <div @click="prevent">
                <h2 class="panel-header heading2">
                    <slot name="header"></slot>
                </h2>

               <p class="info-popup-content">
                   <slot name="text"></slot>
               </p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        props: ['id'],
        data(){
            return {
                position: 'top',
                css: '',
            }
        },
        methods: {
            setValues(value) {
                this.$store.commit(window.store.SET_SEARCH_BUDGET_RANGE, value);
            },
            closeModal(){
                let el = $('#currency' + this.id);
                el.removeClass('open-facet-panel');
                window.pnpHelper.veil.hide();
                el.fadeOut(150);

                window.bus.$emit('popupClose');
            },
            currencyChanged(){
                this.$emit('currencyChanged');
            },
            prevent(e){
                if(e){
                    e.preventDefault();
                    e.stopPropagation();
                }
            }
        },
        computed: {
            ...mapState({
                // rates: state => state.user.user.rates,
                // selected: state => state.user.user.selected_currency,
                // budgetFrom: state => state.search.criteria.params.budgetFrom,
                // budgetTo: state => state.search.criteria.params.budgetTo
            })
        },
        mounted(){
            let popup = this.$refs.information_popup.getBoundingClientRect();

            if(pnpHelper.bootstrap.isXs()){
                if($('body').hasClass('page-scroll-hijacked')){
                    this.css = "left:-" + (popup.left - 15) +'px; top:-' + ((popup.height) + 30) + 'px;';
                }else {
                    this.css = "left:-" + (popup.left - 15) + 'px; top:-' + ((popup.top / 3) + 30) + 'px;';
                }

            }else {
                if (popup.top > -15) {
                    this.position = 'top';
                } else {
                    this.position = 'bottom';
                }
            }
        }
    }
</script>
