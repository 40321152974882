<template>
    <a @click.prevent="back" :class="{ 'button-back-container': true, 'default': !hasSlot }">
        <template v-if="hasSlot">
            <slot></slot>
        </template>
        <template v-else>
            <div class="header">
                <div class="logo">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.865 13.267" class="left-arrow">
                        <g transform="translate(-429.635 -122.494)">
                            <line class="arrow" stroke="#fff" x2="48" transform="translate(430.5 129.5)"/>
                            <path class="arrow" stroke="#fff" d="M-8548.726-9300.937l-7.449,6.4,7.449,5.7" transform="translate(8987 9424)"/>
                        </g>
                    </svg>

                    <img src="/assets/img/logos/atr.svg" width="66" alt="ATR" class="logo -light"/>
                    <img src="/assets/img/logos/atr-dark.svg" width="66" alt="ATR" class="logo -dark"/>
                </div>
            </div>
        </template>
    </a>
</template>

<script>
    import { mapState } from 'vuex';
    import GlobalVars from '../../../global/GlobalVars';

    export default {
        props: ['transition_name'],
        methods: {
            back(elem){
                if(!$('body').hasClass('modal-open')) {

                    if(elem.target.tagName && elem.target.tagName === "IMG"){
                        this.$router.push({name: 'home'});
                    }else {

                        // if (!_.isUndefined(window.fullpage)) {
                        //     window.fullpage.moveTo(1);
                        // }

                        $('body').addClass('back-btn-clicked');
                        window.bus.$emit('back-btn-click');

                        setTimeout(() => {
                            $('body').removeClass('back-btn-clicked');
                        }, 3000);

                        // console.log(this.calcPageDepth);

                        // if(this.fromSearch){
                        //     this.$store.commit(window.store.SET_TRANSITION, this.transition);
                        //     this.$router.push({ path: this.getPath });
                        // } else {
                        this.$router.go(-Math.abs(this.calcPageDepth));
                        // }
                    }
                }
            }
        },
        computed: {
            hasSlot() {
                return !_.isEmpty(this.$slots['default']);
            },
            calcPageDepth(){
                return -Math.abs(this.pageDepth);
            },
            getPath(){
                switch (this.searchType) {
                    case 'location':
                        return GlobalVars.HARCODED_URLS.finder.locations;
                        break;
                    case 'experience':
                        return GlobalVars.HARCODED_URLS.finder.experiences;
                        break;
                    case 'lodge':
                        return GlobalVars.HARCODED_URLS.finder.lodges;
                        break;
                    case 'resource':
                        return GlobalVars.HARCODED_URLS.finder.resources;
                        break;
                    default:
                        return '';
                        break;
                }
            },
            ...mapState({
                fromSearch: state => state.transitionManager.fromSearch,
                transition: state => state.transitionManager.transitionToUse,
                pageDepth: state => state.transitionManager.pageDepth,
                searchType: state => state.search.criteria.params.type
            })
        }
    }
</script>