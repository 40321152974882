<template>
    <div>
        <div class="fp-bg full-size pnp-bg vignette vignette1"
             v-if="lazy_load"
             :class="css_class"
             :data-pnp-heading="data_heading"
             :data-lazy-bg-image="getImageUrl">
        </div>

        <div class="fp-bg full-size pnp-bg vignette vignette1"
             v-else
             :class="css_class"
             :data-pnp-heading="data_heading"
             :style="{ backgroundImage: 'url(' + getImageUrl + ')' }">
        </div>

        <noscript v-if="canRenderNoScriptImage">
            <img :src="getImageUrl" :alt="getAltText"/>
        </noscript>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                canRenderNoScriptImage : false
            }
        },
        props: {
            data_heading: {
                default: null,
                type: String
            },
            lazy_load: {
                default: true,
                type: Boolean
            },
            img_url: {
                default: null,
                type: String
            },
            bg_img_url_mobile: {
                default: null,
                type: String
            },
            css_class: {
                default: null,
                type: String
            },
            alt_text: {
                default: null,
                type: String
            }
        },
        mounted() {
            /*DJ: A little bit hacky, but noscript tags seem to render in all major browsers - guessing its something to do with
            vue adding the noscript tag to the page and the browser wont recognise that as a legit noscript tag. If the noscript tag
            renders, all the images are downloaded in 1 go, negating the lazy load stuff. This only renders the noscript tag if not
            major browser.*/

            if (window.location.href.indexOf('prerendering=true') !== -1) {
                //if(!pnpHelper.browser.isChrome && !pnpHelper.browser.isFirefox && !pnpHelper.browser.isIOS && !pnpHelper.browser.isIE && !pnpHelper.browser.isSafari && !pnpHelper.browser.isAndroid) {
                this.canRenderNoScriptImage = true;
            }
        },
        computed: {
            getAltText(){
                if(this.bg_img_url_mobile && this.bg_img_url_mobile.length > 0) {
                    if (pnpHelper.bootstrap.isXs()) {
                        return this.alt_text + ' (Small)';
                    } else {
                        return this.alt_text;
                    }
                } else {
                    return this.alt_text;
                }
            },
            getImageUrl(){
                if(this.bg_img_url_mobile && this.bg_img_url_mobile.length > 0) {
                    if (pnpHelper.bootstrap.isXs()) {
                        return this.bg_img_url_mobile;
                    } else {
                        return this.img_url;
                    }
                } else {
                    return this.img_url;
                }
            }
        },
    }
</script>