<template>
    <div>
        <slot />

        <p>Have a great day, speak soon!</p>
        <p>Lenny and the ATR team<br> <a href="mailto:salesoffice@atrsafari.com" style="color: #676767; text-decoration: underline;">salesoffice@atrsafari.com</a></p>


    </div>
</template>

<script>

    export default {
        props: {
            progress: {
                type: Number,
                required: false,
                default: 0,
            },
        },
    }

</script>