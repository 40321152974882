<template>
    <div class="start-page" v-if="loaded">
        <half-page-scroll-hijack>
            <div class="nav-veil"></div>

            <div class="left-side">
                <section data-id="0" class="container -full-page">
                    <div class="number-container"><span class="yellow-bar"></span>01</div>

                    <h1 class="heading4 heading -first-time">So you are thinking about going on safari?</h1>

                    <p class="content">If this is your first time, then you are in for a real treat. A proper safari should deliver a truly life-punctuating set of experiences. But beware, it can become seriously addictive!</p>

                    <p class="content">If you have been before, then welcome back, we very much look forward to helping you get back out in the bush.</p>

                    <div class="scroll-btn">
                        <button-external-presentational
                                @click="scrollTo"
                                icon="ion-ios-arrow-thin-down"
                                classes="scroll-down-arrow">
                        </button-external-presentational>
                    </div>
                </section>

                <section data-id="1" class="container -full-page">
                    <div class="number-container"><span class="yellow-bar"></span>02</div>

                    <h2 class="heading4 heading">What makes a good safari?</h2>

                    <p class="content">Safari is so much more than driving around looking at animals. Beyond the mainstream there lies an intimate world of remote and remarkable places, of special and exciting experiences, of wonderful and dedicated people.</p>
                </section>

                <section data-id="2" class="container -full-page" >
                    <div class="number-container"><span class="yellow-bar"></span>03</div>

                    <h2 class="heading4 heading">Arranging a great safari should be easy and fun.</h2>

                    <div class="content">
                        <p>Virtually all authentic safaris are arranged through specialist safari agencies like ours. We are here to help you to access this wonderful world. There’s no hard sell, no hassle, just safari people chatting about what we love.</p>
                    </div>
                </section>

                <section data-id="3" class="container -full-page">
                    <div class="number-container"><span class="yellow-bar"></span>04</div>

                    <h2 class="heading4 heading">How it works</h2>

                    <div class="content">
                        <p>All safari trips are carefully tailor-made. So at some point we need to discover a little bit about you and your ambitions for your trip.</p>

                        <p>This is much easier and simpler than it sounds. Once you have had a quick chat with one of our safari experts, they will be able to efficiently guide you through the various options and help you to identify the perfect trip.</p>
                    </div>
                </section>

                <section data-id="4" class="container -full-page">
                    <div class="number-container"><span class="yellow-bar"></span>05</div>

                    <h2 class="heading4 heading">Your safari expert</h2>

                    <div class="content">
                        <p>Your choice of safari company will be absolutely critical to the success of your trip.</p>

                        <p>All of our safari experts have all lived, worked and travelled extensively in Africa. Most have guided and managed at top safari camps across the continent. All of them know the world of safari inside out. They are all passionate, knowledgeable, enthusiastic and efficient.</p>

                        <div class="select-container hidden-xs">
                            <select v-model="selectedExpertId" @change="changeExpertSelection()">
                                <option v-for="expert in expertOptions" v-bind:value="expert.value">
                                    {{expert.text}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!--<div class="buttons visible-xs">-->
                        <!--<button-internal-presentational-->
                                <!--classes="btn -main-cta -ripple"-->
                                <!--:router_object="activeExpert.vue_route_params"-->
                                <!--has_back_button="true">-->
                            <!--<span><span>View expert</span> <i class="ion-ios-arrow-thin-right"></i></span>-->
                        <!--</button-internal-presentational>-->
                    <!--</div>-->
                </section>

                <section data-id="5" class="container -full-page">
                    <div class="number-container"><span class="yellow-bar"></span>06</div>

                    <h2 class="heading4 heading">A quick word about conservation</h2>

                    <div class="content">
                        <p>It’s really important to understand that conservation in Africa is utterly dependent on sustainable tourism. Your visit will inherently make a very positive contribution to the protection of the wonderful places and animals that you visit.</p>

                        <p>All of us here have dedicated the best part of our working lives to advancing conservation in Africa. It’s a big deal for us.</p>
                    </div>
                </section>

                <section data-id="6" class="container -full-page">
                    <div class="number-container"><span class="yellow-bar"></span>07</div>

                    <h2 class="heading4 heading">And a word about prices</h2>

                    <div class="content">
                        <p>Good safari trips can literally range from USD 3,000 to USD 50,000 per person, depending on the ingredients you choose.</p>

                        <p>So there is enormous flexibility to tailor-make a trip to meet your target budget. We really do know the ropes and will do everything we can to make sure you get the best possible safari for the price.</p>

                        <p>Importantly, we do not charge for what we do, the lodges pay us to help you get to them, so you really do get our expert services for free!</p>
                    </div>
                </section>

                <section data-id="7" class="container -full-page">
                    <div class="number-container"><span class="yellow-bar"></span>08</div>

                    <h2 class="heading4 heading">Great, that’s the basics done</h2>

                    <p class="content">
                        Hopefully you are starting to get a handle on who we are and what we do.
                        Now we need to figure the best way for you to proceed from here. There are two options …
                    </p>
                </section>

                <section data-id="8" class="container -full-page">
                    <div class="number-container"><span class="yellow-bar"></span>09</div>

                    <h2 class="heading4 heading">Get started</h2>

                    <div class="content">
                        <p>If you are ready to get the ball rolling now, then you can click the button and decide how best to get in touch.</p>

                        <p>If you are still in two minds about going on safari, then it can still be a great idea to get in touch sooner rather than later. One of our safari experts should be able to quickly help you out with any sticky questions and make some early suggestions to help you decide.</p>
                    </div>

                    <div class="buttons">
                        <button-internal-presentational
                                classes="btn -main-cta -ripple"
                                :router_object="{ name: 'contact' }">
                            <span><span>Contact</span> <i class="ion-ios-arrow-thin-right"></i></span>
                        </button-internal-presentational>
                    </div>
                </section>

                <section data-id="9" class="container -full-page">
                    <div class="number-container"><span class="yellow-bar"></span>10</div>

                    <h2 class="heading4 heading">Go for a graze</h2>

                    <div class="content">
                        <p>If you are the kind of person who likes to take a really good look around first, then we have all the best experiences, locations, lodges and trips right here on the website, carefully collated for you to explore.</p>
                    </div>

                    <div class="buttons">
                        <button-internal-presentational
                                :router_object="{path: hardcodedUrls.finder.trip_collections}"
                                classes="-next-step"
                                icon="ion-ios-arrow-thin-right">
                            <div>Explore</div>
                        </button-internal-presentational>
                    </div>
                </section>

                <!--<hr>-->

                <!--<div data-id="10" class="container -full-page">-->
                <!--<div class="number-container"><span class="yellow-bar"></span>11</div>-->

                <!--<div class="heading4 heading">Learn as you go</div>-->

                <!--<div class="content">-->
                <!--<p>If you prefer a slightly more guided approach, then our structured inquiry is designed to answer some of your basic questions, whilst helping you to formulate and collate your thoughts during those early planning stages.</p>-->
                <!--</div>-->

                <!--<div class="buttons">-->
                <!--<button-internal-presentational-->
                <!--url="/contact/inquiry"-->
                <!--class="btn -rect -large -yellow -wide">-->
                <!--<div>Structured Inquiry</div>-->
                <!--</button-internal-presentational>-->
                <!--</div>-->
                <!--</div>-->
            </div>

            <section class="right-side map-container article-images-container" v-if="loaded && currentArticle && !isMobile">
                <template>
                    <div class="article-images -showing-images -in vh-fix"  :class="{ 'no-click': !currentArticle }">
                        <transition-group name="fade" mode="in-out" tag="div">
                            <article-image-presentational
                                    :key="currentArticle.image"
                                    :image="currentArticle.image"
                                    :title="currentArticle.title"
                                    :content="currentArticle.content"
                                    :route_params="routeObject"
                                    :transition_name="currentArticle.display_type === 1 ? 'article-to-fullpage' : 'article-to-halfpage'"
                                    :is_link="!!currentArticle.vue_route_params">

                                <modal-global v-if="currentArticle.title === this.articlesArr[0].title" classes="gs-modal -full" div_id="video-modal" slot="custom-btn">
                                    <template slot="modal-button">
                                        <p class="buttons">
                                            <button class="btn -rounded -main-cta -ripple -rounded -video-btn">
                                                <span><span>Play video</span> <i class="ion-ios-arrow-thin-right"></i></span>
                                            </button>
                                        </p>

                                    </template>

                                    <template slot="modal-body">
                                        <!--<video-player src="/assets/video/start.mp4"></video-player>-->
                                        <video-player
                                                src_low="https://player.vimeo.com/external/304634970.sd.mp4?s=7834f9a849ba6a95e2366403bb15836ed054d349&profile_id=164"
                                                src_medium=""
                                                src_high="https://player.vimeo.com/external/304634970.hd.mp4?s=ea98a2068f33dfddf8435899272a5a2ce653ab4f&profile_id=175">

                                        </video-player>
                                        <!--<iframe src="https://player.vimeo.com/video/304634970" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>-->
                                    </template>
                                </modal-global>

                            </article-image-presentational>
                        </transition-group>
                    </div>
                </template>
            </section>
        </half-page-scroll-hijack>
        <!-- <transition-wipe/> -->
    </div>
</template>

<script>
    import LazyLoader from '../../global/LazyLoader';
    import GlobalVars from '../../global/GlobalVars';
    import pageIsReadyMixin from '../../mixins';
    import SchemaData from "../../global/SchemaData";
    import SeoManager from "../../global/SeoManager";

    export default {
        mixins: [pageIsReadyMixin],
        data(){
            return {
                loaded: false,
                idArr: [],
                selectedExpertId: null,
                articlesArr: [
                    {
                        title: 'Introduction',
                        content: 'An important video overview to get you started.',
                        url: '',
                        image: 'https://atrimages.azureedge.net/cdn/start/0/01/01ATR0200-IM0001-atr-safari-start-1475.jpg',
                        display_type: 1,
                        type: GlobalVars.RESOURCE,
                        index: 1,
                        id:'START_1',
                        is_static: true
                    },
                    { id: this.idArr[0], type: GlobalVars.RESOURCE, index: 2 },
                    { id: this.idArr[1], type: GlobalVars.RESOURCE, index: 3 },
                    { id: this.idArr[2], type: GlobalVars.RESOURCE, index: 4 },
                    //TODO: select random team member, ids: (7 - 35) -> Math.floor(Math.random()*(35-6+1)+6)
                    { id: this.idArr[3], type: GlobalVars.RESOURCE, index: 5 },
                    { id: this.idArr[4], type: GlobalVars.RESOURCE, index: 6 },
                    { id: this.idArr[5], type: GlobalVars.RESOURCE, index: 7 },
                    {
                        image: 'https://atrimages.azureedge.net/cdn/start/0/08/08ATR0200-IM0008-atr-safari-start-1475.jpg',
                        url: undefined,
                        display_type: 1,
                        type: GlobalVars.RESOURCE,
                        index: 8,
                        id:'START_2',
                        is_static: true

                    },
                    {
                        image: 'https://atrimages.azureedge.net/cdn/start/0/09/09ATR0200-IM0009-atr-safari-start-1475.jpg',
                        url: undefined,
                        display_type: 1,
                        type: GlobalVars.RESOURCE,
                        index: 9,
                        id:'START_3',
                        is_static: true

                    },
                    {
                        image: 'https://atrimages.azureedge.net/cdn/start/0/10/10ATR0200-IM0010-atr-safari-start-1475.jpg',
                        url: undefined,
                        display_type: 1,
                        type: GlobalVars.RESOURCE,
                        index: 10,
                        id:'START_4',
                        is_static: true

                    },
                    {
                        image: 'https://atrimages.azureedge.net/cdn/start/0/11/11ATR0200-IM0011-atr-safari-start-1475.jpg',
                        url: undefined,
                        display_type: 1,
                        type: GlobalVars.RESOURCE,
                        index: 11,
                        id:'START_5',
                        is_static: true

                    }
                ]
            }
        },
        methods: {
            scrollTo() {
                window.bus.$emit('half-page-hijack-nextSection');
            },
            scrollifyBeforeMove(index, sections){
                //If the current article is the default expert
                if(this.articlesArr[index].id === 7){
                    //console.log('setting random expert', this.activeExpert);
                    this.$store.commit(window.store.SET_CURRENT_ARTICLE, this.activeExpert);
                    this.$store.commit(window.store.SET_ACTIVE_ARTICLE, this.activeExpert);
                }else {
                    let article = this.getArticleById(this.articlesArr[index].id);
                    // console.log('setting article', article);

                    this.$store.commit(window.store.SET_CURRENT_ARTICLE, article);
                    this.$store.commit(window.store.SET_ACTIVE_ARTICLE, article);
                }
            },
            scrollifyInit(index){
                //If the current article is the default expert
                if(this.articlesArr[index].id === 7){
                    this.$store.commit(window.store.SET_CURRENT_ARTICLE, this.activeExpert);
                    this.$store.commit(window.store.SET_ACTIVE_ARTICLE, this.activeExpert);
                }else {
                    let article = this.getArticleById(this.articlesArr[index].id);
                    this.$store.commit(window.store.SET_CURRENT_ARTICLE, article);
                    this.$store.commit(window.store.SET_ACTIVE_ARTICLE, article);
                }
            },
            getArticleById(id){
                for(let i in this.articles){
                    if(this.articles[i].id == id){
                        return this.articles[i];
                    }
                }

                console.warn('Unable to find article with id ' + id);
                return;
            },
            getExpertById(id){
                for(let i in this.experts){
                    if(this.experts[i].id == id){
                        return this.experts[i];
                    }
                }

                console.warn('Unable to find expert with id ' + id);
                return;
            },
            changeExpertSelection(){
                let expert = this.getExpertById(this.selectedExpertId);
                this.$store.commit(window.store.SET_ACTIVE_EXPERT, expert);
                this.$store.commit(window.store.SET_CURRENT_ARTICLE, expert);
                this.$store.commit(window.store.SET_ACTIVE_ARTICLE, expert);
            },
            initExperts(){
                //Fetch the experts
                if(this.experts.length === 0) {
                    this.$store.dispatch(window.store.FETCH_AND_SET_EXPERTS)
                        .then((res) => {

                            this.selectedExpertId = this.activeExpert.id;
                            this.loaded = true;

                            let lazyLoader = new LazyLoader();
                            lazyLoader.loadArticleImages(this.experts);

                            this.declarePageIsReady();
                        });
                }else{
                    this.selectedExpertId = this.activeExpert.id;
                    this.loaded = true;

                    let lazyLoader = new LazyLoader();
                    lazyLoader.loadArticleImages(this.experts);

                    this.declarePageIsReady();
                }
            }
        },
        computed: {
            articles() {
                return this.$store.state.startPage.articles;
            },
            experts() {
                return this.$store.state.startPage.experts;
            },
            activeExpert() {
                return this.$store.state.startPage.activeExpert;
            },
            expertOptions(){
                if(!this.experts){
                    return null;
                }else {
                    var options = [];

                    for (var i in this.experts) {
                        options.push({
                            text: this.experts[i].title,
                            value: this.experts[i].id
                        });
                    }

                    return options;
                }
            },
            currentArticle(){
                return this.$store.state.transitionManager.currentArticle;
            },
            isMobile(){
                return window.pnpHelper.bootstrap.isXs();
            },
            routeObject(){
                if(!this.currentArticle.vue_route_params){
                    return {};
                } else {
                    return {
                        ...this.currentArticle.vue_route_params,
                        params: {
                            ...this.currentArticle.vue_route_params.params,
                            showOuts: false
                        }
                    }
                }
            },
            hardcodedUrls() {
                return GlobalVars.HARCODED_URLS;
            }
        },
        mounted(){
            this.$store.commit(window.store.SET_PAGE_TITLE, this.$route.meta['page_title']);
            this.$store.commit(window.store.SET_META_DESCRIPTION, this.$route.meta['meta_description']);

            SeoManager.addJsonSchema(new SchemaData().getStartVideoSchema(), 'video');

            if(this.articles.length === 0) {
                this.$store.dispatch(window.store.FETCH_AND_SET_START_ARTICLES, this.articlesArr).then((res) => {
                    // this.articlesArr = res;
                    this.articlesArr = this.articles;

                    if(!this.isMobile) {
                        let lazyLoader = new LazyLoader();
                        lazyLoader.loadArticleImages(this.articles);
                    }

                    this.initExperts();
                });
            } else {
                this.initExperts();
            }

        },
        beforeCreate(){
            if(window.environment.isDev) {
                this.idArr = [37, 3, 5, 7, 4, 134];
            } else {
                this.idArr = [37, 3, 5, 7, 4, 134];
            }
        },
        created(){
            window.bus.$on('scrollify-beforeMove', this.scrollifyBeforeMove);
            window.bus.$on('scrollify-init', this.scrollifyInit);
        },
        destroyed() {
            window.bus.$off('scrollify-beforeMove', this.scrollifyBeforeMove);
            window.bus.$off('scrollify-init', this.scrollifyInit);
            SeoManager.removeJsonSchema('video');
        }
    }
</script>