<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
    import scrollify from 'jquery-scrollify';

    export default {
        props: ['beforeMove', 'disableOnMobile'],
        methods: {
            scrollifyNextSection() {
                scrollify.next();
            },
            setContainerHeights(){
                $('.container.-full-page').css('height', $(window).height())
            },
            scrollifyInit(){
                let vm = this;

                if(this.isMobile) {
                    this.setContainerHeights();
                    pnpHelper.onResizeFinished(function(){
                        vm.setContainerHeights();
                        if(scrollify) {
                            $('.container.-full-page').addClass('-in');

                            //     console.log(scrollify.currentIndex());
                            //     $('.container.-full-page.-in').removeClass('-in');
                            //     $('.container.-full-page').eq(scrollify.currentIndex() + 1).addClass('-in');
                            //     window.bus.$emit('scrollify-init', scrollify.currentIndex());
                        }
                    });
                }

                $('body').addClass('half-page-hijack');

                scrollify({
                    section : ".container.-full-page",
                    updateHash: false,
                    setHeights: this.isMobile,
                    standardScrollElements:".gs-modal",
                    before(index, sections){

                        //If vue hasn't dicked about with the page (which happens when you submit the form on contact pages)
                        if(sections.length === $('.container.-full-page').length) {
                            // console.log(index);
                            $('.container.-full-page.-in').removeClass('-in');
                            sections[index].addClass('-in');
                            window.bus.$emit('scrollify-beforeMove', index, sections);
                        }else{
                            vm.showAllSections();
                        }
                    }
                });

                //A patch for Edge on touch devices, which scrollify doesn't seem to support
                if(pnpHelper.isTouch() && pnpHelper.browser.version.ie > 11){
                    this.showAllSections();
                }

                if(this.isMobile) {
                    if (vm.disableOnMobile === true) {
                        scrollify.disable();
                        this.showAllSections(true);
                        $('body').addClass('scrollify-disabled');
                    } else if(scrollify.isDisabled()){
                        scrollify.enable();
                        $('body').removeClass('scrollify-disabled');
                    }
                }

                setTimeout(() => {
                    $('.container.-full-page').eq(scrollify.currentIndex()).addClass('-in');
                    window.bus.$emit('scrollify-init', scrollify.currentIndex());
                },200);
            },
            scrollifyDestroy(){
                $('body').removeClass('scrollify-disabled').removeClass('half-page-hijack');
                scrollify.destroy();
            },
            showAllSections(addVhFix){
                $(".container.-full-page").addClass('-in');

                if(addVhFix){
                    $(".container.-full-page,.right-side").addClass('vh-fix');
                }
            }
        },
        computed: {
          isMobile(){
              return pnpHelper.bootstrap.isIpadOrSmaller() && pnpHelper.isTouch();
          }
        },
        mounted() {
            setTimeout(() => {
                this.scrollifyInit();
            });
        },
        beforeDestroy(){
            if(this.isMobile){
                $(".container.-full-page.-in").removeClass('-in');
            }else{
                this.scrollifyDestroy();
            }
        },
        created(){
            window.bus.$on('half-page-hijack-nextSection', this.scrollifyNextSection);
            window.bus.$on('half-page-init', this.scrollifyInit);
            window.bus.$on('half-page-destroy', this.scrollifyDestroy);
        },
        destroyed(){
            if(this.isMobile){
                window.bus.$once('do-blanket-tasks', this.scrollifyDestroy);
            }else{
                $('body').removeClass('half-page-hijack');
            }

            window.bus.$off('half-page-hijack-nextSection', this.scrollifyNextSection);
            window.bus.$off('half-page-init', this.scrollifyInit);
            window.bus.$off('half-page-destroy', this.scrollifyDestroy);
        }
    }
</script>