import AtrStorage from "./AtrStorage";
import LinkHashes from "./LinkHashes";

export default {
    created() {
        window.bus.$on('pageIsReady', this.doPageReadyActions);
    },
    destroyed(){
        window.bus.$off('pageIsReady', this.doPageReadyActions);
    },
    methods: {
        doPageReadyActions(){
            this.maybeScrollToHash();
            var googleTrackingStore = new AtrStorage();

            if('utm_source' in this.$route.query){
                googleTrackingStore.append('google_tracking', 'utm_source', this.$route.query['utm_source']);
            };

            if('utm_medium' in this.$route.query){
                googleTrackingStore.append('google_tracking', 'utm_medium', this.$route.query['utm_medium']);
            };

            if('keyword' in this.$route.query){
                googleTrackingStore.append('google_tracking', 'keyword', this.$route.query['keyword']);
            };

            if('device' in this.$route.query){
                googleTrackingStore.append('google_tracking', 'device', this.$route.query['device']);
            };

            if('gclid' in this.$route.query){
                googleTrackingStore.append('google_tracking', 'gclid', this.$route.query['gclid']);
            };

            this.$store.dispatch(window.store.FETCH_HAS_OPENED_NAV, this.$store.state.user);
            this.$store.dispatch(window.store.FETCH_AND_SET_USER, this.$store.state.user);
            window.GoogleAnalytics.firePageView();

            if(window.$zopim) {
                $zopim(function() {
                    // update visitor's path with current page's location and title
                    $zopim.livechat.sendVisitorPath();
                });
            }

            if($('body').hasClass('first-load')) {
                let $body = $('body');
                setTimeout(() => {
                    $body.addClass('first-load-done');
                    $body.removeClass('first-load');

                    setTimeout(() => {
                        $body.removeClass('first-load-done');
                    },2000);
                },1800);
            }
        },
        maybeScrollToHash() {
            if (!_.isUndefined(this.$route.hash) && this.$route.hash != '') {
                setTimeout(() => {
                    LinkHashes.scrollTo(this.$route.hash);
                }, 1000);
            }
        }
    }
};

