(function($) {
	/*
	 Description:
	 Toggles the given class on/off on the given element

	 Usage:
	 <a href="#"
	 data-pnp-toggle-class
	 data-pnp-target="#element-to-toggle"
	 data-pnp-class="class-to-toggle"
	 >
	 Toggle Class
	 </a>
	 */
	window.$document.on('click', '[data-pnp-toggle-class]', function () {
		$($(this).data('pnp-target')).toggleClass($(this).data('pnp-class'));
		$(this).toggleClass($(this).data('pnp-class'));
		return false;
	});

	/*
	 Description:
	 Adds the given class to the given element

	 Usage:
	 <a href="#"
	 data-pnp-add-class
	 data-pnp-target="#element-to-toggle"
	 data-pnp-class="class-to-toggle"
	 >
	 Add Class
	 </a>
	 */
	window.$document.on('click', '[data-pnp-add-class]', function () {
		$($(this).data('pnp-target')).addClass($(this).data('pnp-class'));
		return false;
	});

	/*
	 Description:
	 Remove the given class to the given element

	 Usage:
	 <a href="#"
	 data-pnp-remove-class
	 data-pnp-target="#element-to-toggle"
	 data-pnp-class="class-to-toggle"
	 >
	 Remove Class
	 </a>
	 */
	window.$document.on('click', '[data-pnp-remove-class]', function () {
		$($(this).data('pnp-target')).removeClass($(this).data('pnp-class'));
		return false;
	});

	/*
	 Description:
	 Hides a particular element, and shows another

	 Usage:
	 <a href="#"
	 data-pnp-show-toggle
	 data-pnp-hide-target="#element-to-hide"
	 data-pnp-show-target-class="#element-to-hide"
	 >
	 Hide/Show Something
	 </a>
	 */
	window.$document.on('click', '[data-pnp-show-toggle]', function () {
		$($(this).data('pnp-hide-target')).hide();
		$($(this).data('pnp-show-target')).show();

		$.event.trigger({type: "pnp-show-toggle-complete"});

		return false;
	});

	/*
	 Description:
	 Does a jQuery SlideToggle on a particular element on Click

	 Usage:
	 <a href="#"
	 data-pnp-slide-toggle="#target"
	 >
	 Slide Toggle Something
	 </a>
	 */
	window.$document.on('click', '[data-pnp-slide-toggle]', function () {
		if ($($(this).data('pnp-slide-toggle')).is(':visible')) {
			$(this).addClass('-out').removeClass('-in');
		} else {
			$(this).addClass('-in').removeClass('-out');
		}

		$($(this).data('pnp-slide-toggle')).slideToggle();

		return false;
	});

	/*
	 Description:
	 Does a jQuery SlideUp on a particular element on Click

	 Usage:
	 <a href="#"
	 data-pnp-slide-up="#target"
	 >
	 Slide Toggle Something
	 </a>
	 */
	window.$document.on('click', '[data-pnp-slide-up]', function () {
		$($(this).data('pnp-slide-up')).slideUp();

		return false;
	});

	/*
	 Description:
	 Does a jQuery SlideDown on a particular element on Click

	 Usage:
	 <a href="#"
	 data-pnp-slide-down="#target"
	 >
	 Slide Toggle Something
	 </a>
	 */
	window.$document.on('click', '[data-pnp-slide-down]', function () {
		$($(this).data('pnp-slide-down')).slideDown();

		return false;
	});

	/*
	 Description:
	 Does a jQuery SlideToggle on a particular element on Change

	 Usage:
	 <input type="checkbox"
	 data-pnp-slide-toggle="#target"
	 >
	 */
	window.$document.on('change', '[data-pnp-slide-toggle-change]', function () {
		if ($($(this).data('pnp-slide-toggle-change')).is(':checked')) {
			$(this).addClass('-out').removeClass('-in');
		} else {
			$(this).addClass('-in').removeClass('-out');
		}

		$($(this).data('pnp-slide-toggle-change')).slideToggle();
	});

	/*
	 Description:
	 Goes back to the previous page

	 Usage:
	 <a href="#" data-pnp-back>Back</a>
	 */
	window.$document.on('click', '[data-pnp-back]', function () {
		window.history.back();
		return false;
	});

	/*
	 Description:
	 Returns false, simples.

	 Usage:
	 <a href="#" data-pnp-return-false>Do Nothing</a>
	 */
	window.$document.on('click', '[data-pnp-return-false]', function () {
		return false;
	});

	/*
	 Description:
	 Scrolls to or past the passed element. If data-pnp-closest is past, the element
	 will be searched for using jQuerys .closest function.

	 Usage:
	 <a href="#" data-pnp-scroll-past=".parallax" data-pnp-closest="true">
	 Scroll past the closest parallax section
	 </a>

	 <a href="#" data-pnp-scroll-to=".site-footer">
	 Scroll to the footer
	 </a>
	 */
	window.$document.on('click', '[data-pnp-scroll-to],[data-pnp-scroll-past]', function () {
		//The element to scroll past
		if ($(this).data('pnp-scroll-past')) {
			var selector = $(this).data('pnp-scroll-past');
			window.pnpHelper.scroll.scrollPast($(this).closest(selector));

		} else if ($(this).data('pnp-scroll-to')) {
			window.pnpHelper.scroll.scrollTo($(this).data('pnp-scroll-to'));
		}

		return false;
	});

	/*
	 Useage: <div data-pnp-equate-height=".stretch" data-resize-mobile="false">
	 Will resize all child divs to match the largest of the defined elements.
	 */
	window.$window.bind('resize', function () {

		$('[data-pnp-equate-height]').each(function () {
			$(this).find($(this).data('pnp-equate-height')).css('height', 'auto');

			var elements = $(this).find($(this).data('pnp-equate-height')).map(function () {
				return $(this).outerHeight();
			}).get();

			var biggest = Math.max.apply(null, elements);

			if (window.pnpHelper.bootstrap.isSm() || $(this).data('resize-mobile') == true) {
				$(this).find($(this).data('pnp-equate-height')).css('height', biggest + 'px');
			}
		});
	}).trigger('resize');

}(jQuery));