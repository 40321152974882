<template>
    <div>
        <div class="above-ipad-portrait video-container" @mousemove="showControls" :class="{'show-controls': show}">
            <!-- Video -->
            <video @click="play"
                   @timeupdate="seekUpdate"
                   @loadedmetadata="setDuration"
                   ref="video"
                   id="video"
                   width="100%"
                   height="auto"
                   :src="src"
                   crossOrigin="anonymous"
                   type="video/mp4">
            </video>

            <!-- Video Controls -->
            <div class="video-controls" >
                <div>
                    <button @click="play" type="button" class="btn">
                        <transition name="fade" mode="out-in" duration="200">
                            <i v-if="!playing" class="ion-ios-play" key="0"></i>
                            <i v-else class="ion-pause" key="1"></i>
                        </transition>
                    </button>
                </div>

                <div class="range-container">
                    <vue-slider
                            ref="slider"
                            tooltip="false"
                            :processStyle="{'backgroundColor': '#f4b400'}"
                            :sliderStyle="{'backgroundColor': '#f4b400', 'box-shadow': 'none'}"
                            :bgStyle="{'backgroundColor': 'rgba(255, 255, 255, 0.3)'}"
                            :height="2"
                            :dotSize="14"
                            v-model="value"
                            @drag-start="() => { this.drag = true }"
                            @drag-end="() => { this.drag = false; isFinished() }">
                    </vue-slider>
                </div>

                <div class="video-duration">
                    {{ formatTime }}
                </div>

                <mute-button
                    v-if="!isIpadOrSmaller"
                    :parentRefs="$refs"
                    :source="this.$refs.video"
                    :click="mute"
                    :value="muted">
                </mute-button>
            </div>
        </div>
        <div v-if="useiFrame" class="below-ipad-portrait vimeo-player">
            <div class="inner-container">
                <iframe :src="src" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</template>

<script>
    import vueSlider from 'vue-slider-component';

    export default {
        components: {
            vueSlider
        },
        props: ['src_low', 'src_medium', 'src_high'],
        data(){
            return {
                duration: 0,
                playing: false,
                muted: false,
                value: 0,
                drag: false,
                show: false,
                useiFrame: false
            }
        },
        methods: {
            showControls(){
                this.show = true;

                this.debouncedMethod();
            },
            debouncedMethod: _.debounce(function(Waypoint) {
                this.show = false
            }, 3000),
            play(){
                let video = this.$refs.video;

                if(video.paused == true) {
                    video.play();
                    this.playing = true;
                } else {
                    this.pause();
                }
            },
            pause(){
                let video = this.$refs.video;
                video.pause();
                this.playing = false;
            },
            mute(){
                let video = this.$refs.video;
                video.muted = video.muted == false;
                this.muted = video.muted == true;
            },
            seekUpdate(){
                let video = this.$refs.video;

                if(!video) {
                    return;
                }

                this.value = (100 / video.duration) * video.currentTime;
                this.duration = Math.floor(video.duration - video.currentTime);
            },
            setDuration(){
                let video = this.$refs.video;
                this.duration = Math.floor(video.duration);
            },
            spacebarPlay(){
                if (event.keyCode === 32) {
                    this.play();
                }
            },
            isFinished() {
                if (this.value >= 100) {
                    this.$emit('finished');
                }
            }
        },
        computed: {
            formatTime(){
                let date = new Date(null);
                date.setSeconds(this.duration);
                let result = date.toISOString().substr(14, 5);

                return '-' + result;
            },
            src() {
                if (this.useiFrame) {
                  let matches = this.src_low.match(/external\/(\d*?)\./);
                  return 'https://player.vimeo.com/video/' + matches[1] + '?autoplay=1';
                } else {
                  return this.src_high;
                }
            },
            isIpadOrSmaller(){
                return pnpHelper.bootstrap.isIpadOrSmaller() && pnpHelper.browser.isIOS;
            }
        },
        mounted(){
            if (pnpHelper.bootstrap.isIpadPortraitOrSmaller()) {
                this.useiFrame = true;
            } else {
                this.play();
            }

            window.addEventListener('keyup', this.spacebarPlay);
            pnpHelper.scroll.lock();
        },
        destroyed() {
            window.removeEventListener('keyup', this.spacebarPlay);
            pnpHelper.scroll.unlock();
        },
        watch: {
            'value': function(){
                let video = this.$refs.video;
                let sliderVal = Math.floor(this.$refs.slider.getValue());

                if(sliderVal === this.value) {
                    let time = video.duration * (this.value / 100);
                    video.currentTime = time;
                }

                if(this.drag) {
                    this.pause();
                    let time = video.duration * (this.value / 100);
                    video.currentTime = time;
                } else {
                    this.isFinished();
                }

            }
        }
    }
</script>
