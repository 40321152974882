<template>
    <div :class="classObject" @click.prevent="showPopup">

        <div class="info-inner">
            <div class="content heading2">{{value}}</div>

            <slot name="header"></slot>

            <transition name="fade" :duration="fade">
                <info-popup v-if="show" :id="id">
                    <template slot="header">
                        <slot name="popup-header"></slot>
                    </template>

                    <template slot="text">
                        <slot name="popup-text"></slot>
                    </template>
                </info-popup>
            </transition>
        </div>

        <div class="veil -info-veil"
             :ref="'info-veil' + id"
             @click="closeVeil($event)">
        </div>

    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        props: ['classes', 'value'],
        data(){
            return {
                show: false,
                id: Math.random(),
                zHide: false,
                fade: 200
            }
        },
        methods:{
            showPopup(e){
                window.bus.$emit('popupOpen', this.id);

                if(e){
                    e.preventDefault();
                    e.stopPropagation();
                }

                if(!this.show){
                    $(this.$refs['info-veil' + this.id]).fadeIn(this.fade, function() {
                        $(this).addClass('-in');
                    });
                } else {
                    $(this.$refs['info-veil' + this.id]).fadeOut(this.fade, function() {
                        $(this).removeClass('-in');
                    });
                }

                this.show = !this.show;
            },
            closeVeil(e){
                window.bus.$emit('popupClose', this.id);

                if(e){
                    e.preventDefault();
                    e.stopPropagation();
                }

                $(this.$refs['info-veil' + this.id]).fadeOut(this.fade, function() {
                    $(this).removeClass('-in');
                });

                this.show = false;
            },
            updatedZindex(id){
                if(id !== this.id){
                    this.zHide = true;
                } else {
                    this.zHide = false;
                }
            },
            popupClose(){
                setTimeout(()=>{
                    this.zHide = false;
                }, this.fade)
            }
        },
        computed: {
            classObject(){
                if(this.zHide) {
                    return [this.classes, 'zHide', 'info-container'];
                }

                return [this.classes, 'info-container'];

            },
            ...mapState({
            })
        },
        created() {
            window.bus.$on('popupOpen', this.updatedZindex);
            window.bus.$on('popupClose', this.popupClose);
            window.bus.$on('closeVeil', this.closeVeil);
        },
        destroyed() {
            window.bus.$off('popupOpen', this.updatedZindex);
            window.bus.$off('popupClose', this.popupClose);
            window.bus.$off('closeVeil', this.closeVeil);
        }
    }
</script>