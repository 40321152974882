<template>
    <div class="call-back-page split-page">

        <half-page-scroll-hijack :disableOnMobile="true">
            <section class="left-side article-images-container -in" v-if="!isMobile">
                <template>
                    <div class="article-images -showing-images -in">
                        <transition-group name="fade" mode="in-out">
                            <article-image-presentational
                                    :key="currentArticle.image"
                                    :image="currentArticle.image"
                                    :title="currentArticle.title"
                                    :content="currentArticle.content"
                                    :route_params="currentArticle.vue_route_params">
                            </article-image-presentational>
                        </transition-group>
                    </div>
                </template>
            </section>

            <div class="right-side">
                <div class="nav-veil -right"></div>

                <form @submit="postForm" v-if="success === null">
                    <section class="container -full-page">
                        <span class="yellow-bar"></span>

                        <h1 class="heading4 heading">Call back</h1>

                        <p class="content">It will be a pleasure to call you back to discuss your trip plans and answer your questions.</p>

                        <p class="content">We just need a few details …</p>

                        <div class="scroll-btn">
                            <button-external-presentational
                                    @click="scrollTo"
                                    icon="ion-ios-arrow-thin-down"
                                    classes="scroll-down-arrow">
                            </button-external-presentational>
                        </div>
                    </section>

                    <section class="container -full-page">
                        <div class="number-container"><span class="yellow-bar"></span>01</div>

                        <div class="input-heading">What time and day should we call you?</div>

                        <input @input="updatePhoneDetails"
                               :value="time"
                               type="text"
                               name="time"
                               class="form-control"
                               placeholder="Time, day, date*">

                        <div class="input-heading">What is your time zone or location?</div>

                        <input @input="updatePhoneDetails"
                               :value="timeZone"
                               type="text"
                               name="timeZone"
                               class="form-control"
                               placeholder="Enter time zone*">

                        <div class="input-heading">What is your phone number?</div>

                        <div class="input-row">
                            <input @input="updatePhoneDetails"
                                   :value="number"
                                   class="form-control home-number"
                                   type="text"
                                   name="number"
                                   placeholder="Number*">

                            <input @input="updatePhoneDetails"
                                   :value="backUpNumber"
                                   type="text"
                                   name="backUpNumber"
                                   class="form-control"
                                   placeholder="Back up number">
                        </div>
                    </section>

                    <section class="container -full-page">
                        <div class="number-container"><span class="yellow-bar"></span>02</div>

                        <!--<input @input="updatePhoneDetails"-->
                               <!--:value="time"-->
                               <!--type="text"-->
                               <!--name="time"-->
                               <!--placeholder="Enter time*">-->

                        <div class="input-heading">Your name</div>

                        <div class="input-row">
                            <input @input="updatePersonalDetails"
                                   :value="firstName"
                                   type="text"
                                   name="firstName"
                                   class="form-control"
                                   placeholder="First name*">

                            <input @input="updatePersonalDetails"
                                   :value="familyName"
                                   type="text"
                                   name="familyName"
                                   class="form-control"
                                   placeholder="Family name*">
                        </div>

                        <div class="input-heading">Your email address</div>

                        <input @input="updatePersonalDetails"
                               :value="email"
                               type="text"
                               name="email"
                               class="form-control"
                               placeholder="Enter email address*">

                        <div class="input-heading">Your location</div>

                        <input @input="updatePersonalDetails"
                               :value="location"
                               type="text"
                               name="location"
                               class="form-control"
                               placeholder="Home town, county, state, country">
                    </section>

                    <section class="container -full-page">
                        <div class="number-container"><span class="yellow-bar"></span>03</div>

                        <div class="input-heading">Your comments</div>

                        <p class="content">Please do feel free to add any notes. It would be particularly useful if you could mention where you want to go, so that we can allocate a suitably experienced safari expert.</p>

                        <textarea @input="updatePersonalDetails"
                                  :value="comments"
                                  class="form-control"
                                  name="comments"
                                  placeholder="Add your comments">
                        </textarea>

                        <!--<div class="buttons">-->
                            <!--<button-internal-presentational-->
                                    <!--url="#"-->
                                    <!--classes="btn -rect -clear">-->
                                <!--<span>Submit</span>-->
                            <!--</button-internal-presentational>-->
                        <!--</div>-->


                        <div class="buttons">
                            <button type="submit" class="btn -main-cta -ripple" :disabled="posting">
                                <span><span v-html="submitButtonText"></span> <i class="ion-ios-arrow-thin-right"></i></span>
                            </button>


                            <div class="form-errors" v-if="errors.length > 0">
                                <p v-for="error in errors" v-html="error"></p>
                            </div>
                        </div>
                    </section>

                    <!--<div class="container -full-page -sign-in">-->
                        <!--<div class="number-container"><span class="yellow-bar"></span>04</div>-->

                        <!--<div class="tabs-header">-->
                            <!--<div @click="signInSelect" :class="{active: !signIn}">Password</div>-->
                            <!--<div @click="signInSelect" :class="{active: signIn}">Sign In</div>-->
                        <!--</div>-->

                        <!--<div class="tabs-body">-->
                            <!--<transition name="tab-fade" mode="out-in">-->
                                <!--<div v-if="!signIn" key="1">-->
                                    <!--<div>-->
                                        <!--<p class="content">Please enter a password with a minimum of eight characters. If you already have an account, please click on the sign in link.</p>-->
                                    <!--</div>-->

                                    <!--<div>-->
                                        <!--<input @input="updateAccDetails"-->
                                               <!--:value="password"-->
                                               <!--class="password-input"-->
                                               <!--type="password"-->
                                               <!--name="password"-->
                                               <!--placeholder="Create password">-->

                                        <!--<input @input="updateAccDetails"-->
                                               <!--:value="confirmPassword"-->
                                               <!--type="password"-->
                                               <!--name="confirmPassword"-->
                                               <!--placeholder="Confirm password">-->
                                    <!--</div>-->
                                <!--</div>-->

                                <!--<div v-else key="2">-->
                                    <!--<div>-->
                                        <!--&lt;!&ndash;<input @input="updatePersonalDetails"&ndash;&gt;-->
                                               <!--&lt;!&ndash;:value="email"&ndash;&gt;-->
                                               <!--&lt;!&ndash;class="password-input"&ndash;&gt;-->
                                               <!--&lt;!&ndash;type="text"&ndash;&gt;-->
                                               <!--&lt;!&ndash;name="email"&ndash;&gt;-->
                                               <!--&lt;!&ndash;placeholder="Enter Email">&ndash;&gt;-->

                                        <!--<input @input="updateAccDetails"-->
                                               <!--:value="password"-->
                                               <!--type="password"-->
                                               <!--name="password"-->
                                               <!--placeholder="Password">-->

                                        <!--<span class="sub-text forgot-password">-->
                                            <!--Forgot password?-->
                                        <!--</span>-->
                                    <!--</div>-->
                                <!--</div>-->
                            <!--</transition>-->
                        <!--</div>-->

                        <!--<div>-->
                            <!--<p class="sub-text">{{ passwordErrorMessage }}</p>-->
                        <!--</div>-->

                        <!--<div class="input-heading">In order that we can save your wishlist we need you to sign in to the website</div>-->

                        <!--<button-external-presentational-->
                                <!--@click="signInSelect"-->
                                <!--classes="-next-step -spaced"-->
                                <!--icon="ion-ios-arrow-thin-right">-->
                            <!--<div>Create an account </div>-->
                        <!--</button-external-presentational>-->

                        <!--<hr>-->

                        <!--<div :class="{closed: signIn}" class="acc-body">-->
                            <!--<input @input="updateAccDetails"-->
                                   <!--:value="password"-->
                                   <!--class="password-input"-->
                                   <!--type="text"-->
                                   <!--name="password"-->
                                   <!--placeholder="Enter password">-->

                            <!--<input @input="updateAccDetails"-->
                                   <!--:value="confirmPassword"-->
                                   <!--type="text"-->
                                   <!--name="confirmPassword"-->
                                   <!--placeholder="Confirm password">-->

                            <!--<label for="subscribe" class="sub-label">-->
                                <!--<input @click="updateSubscription" type="checkbox" name="subscribe" id="subscribe" :checked="!!subscribe">-->

                                <!--<span class="checkmark"></span>-->

                                <!--Subscribe to safari newsletter-->
                            <!--</label>-->
                        <!--</div>-->

                        <!--<button-external-presentational-->
                                <!--@click="signInSelect"-->
                                <!--classes="-next-step -spaced"-->
                                <!--icon="ion-ios-arrow-thin-right">-->
                            <!--<div>Sign In</div>-->
                        <!--</button-external-presentational>-->

                        <!--<hr>-->

                        <!--<div :class="{closed: !signIn}" class="acc-body">-->
                            <!--<input type="text" name="signInPassword" placeholder="Enter password">-->

                            <!--<input type="text" name="signInConfirmPassword" placeholder="Confirm password">-->
                        <!--</div>-->
                    <!--</div>-->
                </form>

                <div class="container -full-page -in" v-if="success === true">
                    <span class="yellow-bar"></span>
                    <div class="email-confirm">
                        <span>
                            <h2 class="heading4 heading">Success!</h2>

                            <div class="content">
                                <p>We will email you with confirmation of the arrangement and then call you at the appointed time. Don’t worry if you cannot answer, we will try again later and email you again if we fail to get through.</p>
                                <p>Go in search of more experiences, locations, lodges and trips …</p>
                            </div>
                        </span>

                        <button-internal-presentational
                                :router_object="{name: 'finder'}"
                                classes="-next-step"
                                icon="ion-ios-arrow-thin-right">
                            <div>Explore</div>
                        </button-internal-presentational>

                    </div>
                </div>

                <contact-form-error v-if="success === false || isTestingFail" :retry="retry"></contact-form-error>
            </div>
        </half-page-scroll-hijack>

        <!-- <transition-wipe/> -->
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import LazyLoader from '../../global/LazyLoader';
    import pageIsReadyMixin from '../../mixins';

    export default {
        mixins: [pageIsReadyMixin],
        data(){
            return {
                posting: false,
                success: null,
                errors: [],
                submitButtonText: 'Send request',

                currentArticle: {
                    image: 'https://atrimages.azureedge.net/cdn/contact/0/03/03ATR0300-IM0003-atr-safari-contact-1475.jpg',
                    content:"",
                    title:"",
                    url: undefined
                },
                articlesArr: [
                    { image: 'https://atrimages.azureedge.net/cdn/contact/0/03/03ATR0300-IM0003-atr-safari-contact-1475.jpg' },
                    { image: 'https://atrimages.azureedge.net/cdn/contact/callback/0/02/02ATR0302-IM0002-atr-safari-callback-1475.jpg' },
                    { image: 'https://atrimages.azureedge.net/cdn/contact/callback/0/03/03ATR0302-IM0003-atr-safari-callback-1475.jpg' },
                    { image: 'https://atrimages.azureedge.net/cdn/contact/callback/0/04/04ATR0302-IM0004-atr-safari-callback-1475.jpg' },
                    { image: 'https://atrimages.azureedge.net/cdn/contact/callback/0/05/05ATR0302-IM0005-atr-safari-callback-1475.jpg' },
                    { image: 'https://atrimages.azureedge.net/cdn/contact/callback/0/06/06ATR0302-IM0006-atr-safari-callback-1475.jpg' },
                    { image: 'null' }
                ],
                // signIn: false
            }
        },
        methods: {
            // signInSelect(){
            //     this.signIn = !this.signIn;
            // },
            updatePhoneDetails (e) {
                this.$store.commit('contactInfo/SET_STATE', {
                    call: {
                        [e.target.name]: e.target.value,
                    }
                });
            },
            updatePersonalDetails(e) {
                this.$store.commit('contactInfo/SET_STATE', {
                    personal: {
                        [e.target.name]: e.target.value,
                    }
                });
            },
            updateAccDetails(e) {
                this.$store.commit('contactInfo/SET_STATE', {
                    account: {
                        [e.target.name]: e.target.value,
                    }
                });
            },
            updateSubscription(e){
                this.$store.commit('contactInfo/SET_STATE', {
                    account: {
                        subscribe: !this.subscribe,
                    }
                });
            },
            scrollTo() {
                window.bus.$emit('half-page-hijack-nextSection');
            },
            scrollifyBeforeMove(index, sections){
                this.currentArticle.image = this.articlesArr[index].image;
            },
            setCurrentArticleImage(){
                this.$store.commit(window.store.SET_CURRENT_ARTICLE_IMAGE, this.currentArticle.image);
            },
            isValidEmail(email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },
            retry(){
                this.success = null;
                this.posting = false;
                this.submitButtonText = 'Send request';
                
                setTimeout(() => {
                    window.bus.$emit('half-page-init');
                });
            },
            postForm(e){
                e.preventDefault();
                this.errors = [];

                if(!this.email){
                    this.errors.push('Please enter your message');
                }

                if(!this.time){
                    this.errors.push('Please enter a time for the call');
                }

                if(!this.timeZone){
                    this.errors.push('Please enter your timezone');
                }

                if(!this.number){
                    this.errors.push('Please enter your phone number');
                }

                if(!this.firstName){
                    this.errors.push('Please enter your first name');
                }

                if(!this.familyName){
                    this.errors.push('Please enter your family name');
                }

                if(!this.email){
                    this.errors.push('Please enter your email address');
                }else{
                    if(!this.isValidEmail(this.email)){
                        this.errors.push('Please enter a valid email address');
                    }
                }

                if(this.errors.length === 0){
                    this.posting = true;
                    this.submitButtonText = 'Please wait...';

                    this.$store.dispatch(window.store.SEND_CALLBACK_REQUEST, null, {root: true}).then((response) => {
                        this.success = true;
                        window.GoogleAnalytics.fireEvent('Call Back', 'Send Request', this.$route.path);
                    }, (error) => {
                        this.success = false;

                    }, () => {
                        //Finally
                        window.bus.$emit('half-page-destroy');
                        this.posting = false;
                        this.success = false;
                        this.submitButtonText = 'Send request';
                    });
                }
            }
        },
        computed: {
            ...mapState({
                time: state => state.contactInfo.call.time,
                timeZone: state => state.contactInfo.call.timeZone,
                number: state => state.contactInfo.call.number,
                backUpNumber: state => state.contactInfo.call.backUpNumber,
                firstName: state => state.contactInfo.personal.firstName,
                familyName: state => state.contactInfo.personal.familyName,
                email: state => state.contactInfo.personal.email,
                location: state => state.contactInfo.personal.location,
                comments: state => state.contactInfo.personal.comments,
                password: state => state.contactInfo.account.password,
                confirmPassword: state => state.contactInfo.account.confirmPassword,
                subscribe: state => state.contactInfo.account.subscribe,

            }),
            isTestingFail(){
                return window.location.href.indexOf('testfail') > -1;
            },
            isMobile(){
                return window.pnpHelper.bootstrap.isXs();
            }
            // submitActive(){
            //     if(this.signIn === false) {
            //         return this.password === this.confirmPassword && this.password.length > 7;
            //     }
            //
            //     return this.password.length > 7;
            // },
            // passwordErrorMessage(){
            //     if(this.confirmPassword.length > 0 && !this.signIn) {
            //         if(this.password !== this.confirmPassword){
            //             return 'Passwords do not match';
            //         } else if(this.password.length < 8) {
            //             return 'Passwords must have a minimum length of 8 character';
            //         }
            //     }
            // }
        },
        mounted(){
            window.location.href = '/contact/inquiry';
            this.$store.commit(window.store.SET_PAGE_TITLE, this.$route.meta['page_title']);
            this.$store.commit(window.store.SET_META_DESCRIPTION, this.$route.meta['meta_description']);

            if(!this.isMobile) {
                let lazyLoader = new LazyLoader();
                lazyLoader.loadArticleImages(this.articlesArr);
            }
        },
        beforeDestroy(){
            window.bus.$emit('setShowBackButton', false);
        },
        created(){
            $(window).scrollTop(0);

            window.bus.$on('scrollify-beforeMove', this.scrollifyBeforeMove);
            window.bus.$on('back-btn-click', this.setCurrentArticleImage);
            this.declarePageIsReady();
        },
        destroyed() {
            window.bus.$off('scrollify-beforeMove', this.scrollifyBeforeMove);
            window.bus.$off('back-btn-click', this.setCurrentArticleImage);
        }
    }
</script>