<template>
    <div>
        <div v-for="(subDirectory, index) in directory" class="sub-directory fade-in-and-up"
             :key="subDirectory.id" :style="transitionDelay(index)">

            <div class="dir-row"
                 :data-id="subDirectory.id"
                 v-bind:class="['-l' + level,{'-has-children': subDirectory.children.length > 0}, {'-no-children': subDirectory.children.length === 0}]">

                <div class="dir-name">
                    <a class="inner"
                       :href="getPrerenderHref(subDirectory)"
                       @click="openDirectory(subDirectory)"
                       v-hover-intent:220="updateImage.bind(null, subDirectory)"
                       :class="{'-in' : (activeArticle && (activeArticle.id === subDirectory.id))}"
                       :key="subDirectory.id"
                       v-html="subDirectory.title"></a>
                </div>
            </div>

            <transition name="article-image">
                <public-resource-listing
                        v-if="subDirectory.children.length > 0 && subDirectory.isOpen"
                        v-bind:directory="subDirectory.children"
                        v-bind:level="level+1"
                        v-bind:type="type">
                </public-resource-listing>
            </transition>
        </div>
    </div>
</template>

<script>
    import LazyLoader from "../../global/LazyLoader";

    export default {
        props: ['directory', 'level', 'type'],
        methods: {
            getPrerenderHref(subdirectory) {
                if ((window.location.href.indexOf('prerendering=true') === -1) || (!_.isUndefined(subDirectory.children) && subDirectory.children.length > 0)) {
                    return false;
                }

                return this.$router.resolve(this.getRouteObject(subdirectory)).href;
            },
            getRouteObject(subDirectory) {
                let internalType = 'single-resource';
                if (this.type === 'trip_collection') {
                    internalType = 'single-trip-intelligence';
                } else if (this.type === 'article') {
                    internalType = 'single-article';
                }
                return {
                    name: internalType,
                    params: {
                        'slug': subDirectory.vue_route_params.data.slug
                    }
                };
            },
            transitionDelay(index) {
                var totalSeconds = 1;
                var interval = (totalSeconds * 1000) / this.directory.length;
                return !_.isUndefined(this.level) && this.level == 1 ? {} : {'transition-delay': (index * interval) / 1000 + 's'};
            },
            openDirectory(subDirectory) {
                if (subDirectory.children.length === 0) {
                    this.$store.commit(
                        window.store.SET_TRANSITION,
                        subDirectory.display_type === 1 ? 'article-to-fullpage' : 'article-to-halfpage'
                    );

                    this.$router.push(this.getRouteObject(subDirectory));
                } else {
                    let subObject = window._.defaultsDeep({}, subDirectory);
                    subObject.isOpen = !subObject.isOpen;

                    this.$store.commit(window.store.SET_ACTIVE_ARTICLE, subObject, {root: true});

                    let results = window._.defaultsDeep({}, this.searchResults);

                    results.data = this.recursiveSearchForObject(subDirectory.id, results.data);

                    if (!this.isMobile) {
                        let lazyLoader = new LazyLoader();
                        lazyLoader.loadImages(_.map(subObject.children, function (o) {
                            return o.images.data.sizes.extralarge;
                        }));
                    }

                    this.$store.commit(window.store.SET_SEARCH_RESULTS, results, {root: true});
                }
            },
            updateImage(subDirectory) {
                _.debounce(() => {
                    this.$store.commit(window.store.SET_ACTIVE_ARTICLE, window._.defaultsDeep({}, subDirectory), {root: true});
                }, 100)();
            },
            recursiveSearchForObject(id, results) {
                for (var x in results) {
                    if (results[x].id == id) {
                        let subObject = window._.defaultsDeep({}, results[x]);
                        subObject.isOpen = !subObject.isOpen;
                        results[x] = subObject;
                    } else {
                        if (results[x].children.length > 0) {
                            results[x].children = this.recursiveSearchForObject(id, results[x].children);
                        }
                    }
                }
                return results;
            }
        },
        computed: {
            searchResults() {
                return this.$store.state.search.results;
            },
            activeArticle() {
                return this.$store.state.activeArticle.article
            },
            isMobile() {
                return pnpHelper.bootstrap.isXs();
            },
        },
        mounted() {
            // let results = window._.defaultsDeep({}, this.searchResults);
            //
            // results.data = this.recursiveSearchForObject(this.$route.query.id, results.data);
            //
            // this.$store.commit(window.store.SET_SEARCH_RESULTS, results, {root: true});
        }
    }
</script>
