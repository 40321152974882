<template>
    <div class="social-icons">
        <!--<a rel="nofollow" href="#" class="btn-round -no-expand"><i class="ion-ios-heart"></i></a>-->
        <!--<a rel="nofollow" href="#" class="icon-container"><i class="ion-android-share-alt"></i></a>-->
        <router-link
                :to="{ name: 'contact' }"  class="btn-round -no-expand"
                @click.native="googleAnalytics.fireEvent('Call', 'Button Click', $route.path)">
            <i class="ion-android-call"></i>
        </router-link>
    </div>
</template>

<script>
    export default {
        props: ['data_style', 'url', 'classes', 'icon', 'is_icon_left', 'type'],
        computed: {
            googleAnalytics() {
                return window.GoogleAnalytics;
            },
        }
    }
</script>