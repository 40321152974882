<template>
    <div v-if="experience">
        <section class="right-side map-container vignette" :style="{'background-image': 'url(' + backgroundImage + ')' }">
        </section>

        <atr-fullpage id="lodge-page" ref="atrFullpage">
            <section-intro-presentational>
                <div class="component-text -lodge-info custom-section">
                    <div class="fp-bg full-size pnp-bg vignette vignette1"
                         :style="bg_img_url">
                    </div>

                    <div class="inner">
                        <div class="inner-container">
                            <div class="width-container">
                                <div class="heading-container">
                                    <div class="button-fav"
                                         :class="{on: check}"
                                         aria-label="Add to Wishlist"
                                         @click="toggleWishlist">
                                    </div>

                                    <h1 class="heading3 heading custom-section-title" :data-pnp-heading="experience.top_section.tag" v-html="experience.title"></h1>
                                </div>

                                <div class="summary custom-section-content" v-html="experience.top_section.content" :contenteditable="editing" v-if="experience.edit === false"></div>

                                <div class="on-page-editor-container" v-else>
                                    <div class="summary custom-section-content" v-html="experience.top_section.content" :contenteditable="editing"></div>
                                    <div class="smallEditButtons -horizontal" v-bind:class="{ '-editing': editing }">
                                        <div v-on:click="createEditor('custom-section-content')"
                                             class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton"></div>
                                        <div v-on:click="saveEditor('custom-section-content')" class="icon ion-ios-checkmark onPageEditor-smallButton btn-success smallSaveButton"
                                             v-if="editing===true"></div>
                                        <div v-on:click="cancelEditor('custom-section-content')" class="icon ion-ios-close onPageEditor-smallButton btn-danger smallCancelButton"
                                             v-if="editing===true"></div>
                                    </div>
                                </div>
                                <hr>
                                <br/>

                                <info-container classes="rating-container" :value="experience.rating ? experience.rating : '0.0'">
                                    <template slot="header">
                                        <div class="sub-text">EXPERIENCE <br/>RATING</div>
                                    </template>

                                    <template slot="popup-header">
                                        <p>Experience rating</p>
                                    </template>
                                    <template slot="popup-text">
                                        <p>
                                            This number represents the peak rating for <strong>{{ experience.title.toLowerCase() }}</strong> at any lodge in <strong>{{ locationName }}</strong>
                                            <span v-if="month <= 0">&nbsp;in <strong>any month</strong></span>
                                            <span v-else>&nbsp;in <strong>{{ months[month - 1] }}</strong></span>
                                            and is a simple indication of how good this experience can get.
                                        </p>

                                        <div class="links">
                                            <button-internal-presentational
                                                    classes="link"
                                                    :router_object="{path: hardcodedUrls.resources.overallRating}"
                                                    transition_name="wipe">
                                                Our ratings system
                                            </button-internal-presentational>
                                        </div>
                                    </template>
                                </info-container>

                                <price :price="String(experience.guide_price)" :minPrice="experience.min_price">
                                    <template slot="header">
                                         <span class="sub-text" v-if="experience.min_price">
                                            GUIDE PRICE {{ this.$store.state.user.user.selected_currency}}<br/> PER PERSON PER NIGHT
                                        </span>
                                        <span v-else class="sub-text">
                                            GUIDE PRICE <br/>{{ this.$store.state.user.user.selected_currency}} PPPN
                                        </span>
                                    </template>

                                    <template slot="popup-header">
                                        Experience guide price
                                    </template>

                                    <template slot="popup-content">
                                        These numbers represent
                                        the approximate range of price per person per night at all of the <strong>lodges</strong> in
                                        <strong>{{ locationName }}</strong> which are able to deliver the
                                        <strong>{{ experience.title.toLowerCase() }}</strong> experience
                                        to a high level in
                                        <span v-if="month <= 0"><strong>any month</strong></span>
                                        <span v-else><strong>{{ months[month - 1] }}</strong></span>.

                                        The lower end is the average low season <strong>lodge</strong> price. The upper end is the average high season
                                        <strong>lodge</strong> price plus any extras which usually need to be included, such as meals,
                                        drinks and activities, transport costs and park fees.


                                        <div class="links">
                                            <button-internal-presentational
                                                    classes="link"
                                                    :router_object="{path: hardcodedUrls.resources.priceGuarantee}"
                                                    transition_name="wipe">
                                                Price guarantee</button-internal-presentational> :

                                            <button-internal-presentational
                                                    classes="link"
                                                    :router_object="{path: hardcodedUrls.resources.howMuchToSpend}"
                                                    transition_name="wipe">
                                                How much to spend</button-internal-presentational> :

                                            <button-internal-presentational
                                                    classes="link"
                                                    :router_object="{path: hardcodedUrls.resources.howToSaveMoney}"
                                                    transition_name="wipe">
                                                How to save money</button-internal-presentational>
                                        </div>
                                    </template>
                                </price>

                                <info-container v-if="experience.conservation_rating >= 7"
                                                classes="consrating-container"
                                                :value="experience.conservation_rating">
                                    <template slot="header">
                                        <div class="sub-text">CONSERVATION <br/>RATING</div>
                                    </template>

                                    <template slot="popup-header">
                                        <p>Experience conservation rating</p>
                                    </template>

                                    <template slot="popup-text">
                                        <p>
                                            The conservation rating is an indication of how much a particular location or lodge needs your visit to further the protection
                                            of their landscapes and wildlife.
                                        </p>
                                        <p>
                                            We recommend that you consider including at least one lodge with a high conservation rating into your trip.
                                        </p>
                                        <p>
                                            So this particular number represents the <strong>peak conservation rating</strong> in
                                            <span v-if="month <= 0"><strong>any month</strong></span>
                                            <span v-else><strong>{{ months[month - 1] }}</strong></span>
                                            for lodges in <strong>{{ locationName }}</strong> which are able to deliver the <strong>{{ experience.title.toLowerCase() }}</strong> experience to a high level.
                                        </p>

                                        <div class="links">
                                            <button-internal-presentational
                                                    classes="link"
                                                    :router_object="{path: hardcodedUrls.resources.conservation}"
                                                    transition_name="wipe">
                                                Conservation rating
                                            </button-internal-presentational>
                                        </div>
                                    </template>
                                </info-container>
                            </div>

                            <div class="scroll-btn-container ">
                                <div class="single-scroll-btn" @click="scroll">
                                    <i class="ion-ios-arrow-thin-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section-intro-presentational>

            <section-container
                    v-if="experienceLoaded"
                    v-for="section in experience.sections.data"
                    :key="section.id"
                    :section="section"
                    :classes="'section'"
                    :edit="experience.edit"
                    :item_title="experience.title"
                    type="experience">
            </section-container>

            <!--<alternatives type="experience" sectionTitle="Read">-->
                <!--<h2 class="heading4">Read</h2>-->
            <!--</alternatives>-->

            <wishlist-add-presentational v-if="showOuts" :name="experience.title.toLowerCase()" :item="experience" type="experience"></wishlist-add-presentational>

            <alternatives v-if="showOuts" :item="experience" type="experience" sectionTitle="Best" prefix="Find the best trips in">

                <div class="number-container"><span class="yellow-bar"></span>02</div>
                <h2 class="heading4">Best</h2>

                <p class="content">If you like this look of this experience, then you can use these links to dig deeper ...</p>
            </alternatives>

            <alternatives v-if="showOuts" type="contact" sectionTitle="Contact">
                <div class="number-container"><span class="yellow-bar"></span>03</div>
                <h2 class="heading4">Get in touch</h2>

                <p class="content">It’s never too soon to get in touch, we are always keen to help out, no matter how formulative or advanced your plans may be ...</p>
            </alternatives>

            <alternatives v-if="showOuts" :item="experience" type="next-experience" sectionTitle="Next">

                <div class="number-container"><span class="yellow-bar"></span>04</div>
                <h2 class="heading4">Next</h2>

                <p class="content">We have all the best safari experiences, locations, lodges, and trips carefully collated for you to explore ...</p>

                <div @click="loadNextResult">
                    <button-internal-presentational
                            v-if="nextExperience && loadingNextResult === false"
                            classes="link-bottom"
                            :router_object="nextExperience"
                            transition_name="wipe">
                            Next experience
                        <i class="ion-ios-arrow-down down-chevron"></i>
                    </button-internal-presentational>
                </div>

                <span class="link-bottom" v-if="loadingNextResult">Loading ...</span>

            </alternatives>

            <!--<next-up-half-page-->
                    <!--v-if="showOuts"-->
                    <!--page="experience"-->
                    <!--:item="experience"-->
                    <!--:data_heading="'Next'"-->
                    <!--:data_next_lodge="true"-->
                    <!--:text="'Next experience'"-->
                    <!--text_next_item="Next"-->
                    <!--text_explore="Explore experiences"-->
                    <!--:image_url="last_bg_img_url">-->
            <!--</next-up-half-page>-->
        </atr-fullpage>

        <div v-if="experience.edit === true">
            <template v-for="section in experience.sections.data">
                <public-editor-buttons type="experience_section" :section_id="section.id" :_id="section.id" :buttons="section.buttons"></public-editor-buttons>

                <template v-for="slide in section.slides.data">
                    <public-editor-buttons type="experience_slide" :section_id="section.id" :_id="slide.id" :buttons="slide.buttons"></public-editor-buttons>
                </template>
            </template>

            <public-editor-links type="experience_trips" :section_id="experience.trips_section" :_id="experience.trips_section.id"
                                 :links="experience.trips_section.content"></public-editor-links>

            <public-editor-links type="experience_other_experiences" :section_id="experience.other_experiences_section" :_id="experience.other_experiences_section.id"
                                 :links="experience.other_experiences_section.content"></public-editor-links>
            <public-editor-meta type="experience_"></public-editor-meta>
        </div>

        <div id="modal-veil"></div>

        <public-edit-mode-toggle />

        <!-- <transition-wipe/> -->
    </div>
</template>

<script>
    import StateUtils from '../../global/StateUtils';
    import GlobalVars from '../../global/GlobalVars';
    import store from '../../store';
    import { mapState } from 'vuex';
    import pageIsReadyMixin from '../../mixins';
    import LazyLoader from '../../global/LazyLoader';

    export default {
        mixins: [pageIsReadyMixin],
        data() {
            return {
                editing: false,
                editorName: null,
                type: 'experience_main',
                showOuts: true,
                months: [],
                experienceLoaded: false,
                loadingNextResult: false,
                backgroundImage: ''
            }
        },
        methods: {
            createEditor(which) {
                if (!window._.isUndefined(this.editorName) && this.editorName !== null) {
                    alert('You are currently editing a different bit on the page.\nSave or Cancel it before starting editing on a new instance.');
                } else {
                    this.editing = true;
                    Vue.nextTick(() => {
                        var editor = window.CKEDITOR.inline(this.$el.querySelector('.on-page-editor-container .' + which), window.ckeditorConfig);
                        this.$el.querySelector('.on-page-editor-container .' + which).click();
                        this.editorName = editor.name;

                        this.$store.commit(window.store.SET_EDITOR_CKEDITOR, true, {root: true});
                    });
                }
            },
            loadNextResult(){
                $('body').addClass('-disable-page-wipe');
            },
            cancelEditor(which) {
                this.editing = false;
                if (this.editorName !== null) {
                    window.CKEDITOR.instances[this.editorName].container.$.innerHTML = this.lodge.top_section.content;
                    window.CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;

                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, { root: true });
                }
            },
            saveEditor(which) {
                var stateObject = new StateUtils(this.type, this.$store).getStateObject();

                if (this.editorName !== null) {
                    stateObject.top_section.content = window.CKEDITOR.instances[this.editorName].container.$.innerHTML;
                    new StateUtils(this.type, this.$store).setStateObject(stateObject, null);
                    this.editing = false;
                    window.CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;

                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, { root: true });
                }

            },
            toggleWishlist(){
                let payload = {
                    id: this.experience.id,
                    type: 'experience'
                };

                let check = this.check;

                if(check) {
                    this.$store.commit(window.store.REMOVE_WISHLIST_ITEM, payload);
                } else {
                    this.$store.dispatch(window.store.SET_WISHLIST_ITEM_BY_ID, payload);
                }
            },
            lazyLoadHiddenImages(){
                let images = [];

                //Load any image slides (View More)
                if(this.experience.sections.data && this.experience.sections.data.length > 0) {
                    for (let i in this.experience.sections.data) {
                        if (this.experience.sections.data[i].hiddenSlides && this.experience.sections.data[i].hiddenSlides.data.length > 0) {
                            for (let j in this.experience.sections.data[i].hiddenSlides.data) {
                                if (pnpHelper.bootstrap.isXs()) {
                                    images.push(this.experience.sections.data[i].hiddenSlides.data[j].bg_img_url_large);
                                } else {
                                    images.push(this.experience.sections.data[i].hiddenSlides.data[j].img_url);
                                }
                            }
                        }
                    }
                }

                //Load any hidden section images (View All)
                if(this.experience.hiddenSections.data && this.experience.hiddenSections.data.length > 0) {
                    for (let i in this.experience.hiddenSections.data) {

                        if(pnpHelper.bootstrap.isXs()){
                            images.push(this.experience.hiddenSections.data[i].bg_img_url_large);
                        }else{
                            images.push(this.experience.hiddenSections.data[i].bg_img_url);
                        }

                        if (this.experience.hiddenSections.data[i].allSlides && this.experience.hiddenSections.data[i].allSlides.data.length > 0) {
                            for (let j in this.experience.hiddenSections.data[i].allSlides.data) {
                                if (pnpHelper.bootstrap.isXs()) {
                                    images.push(this.experience.hiddenSections.data[i].allSlides.data[j].bg_img_url_large);
                                } else {
                                    images.push(this.experience.hiddenSections.data[i].allSlides.data[j].img_url);
                                }
                            }
                        }
                    }
                }

                if(images.length > 0) {
                    let lazyLoader = new LazyLoader();
                    lazyLoader.loadImages(images);
                }
            },
            setBackgroundImage(){
                if(this.backgroundImage.length === 0) {
                    let expImgArr = this.experience.allSections.data;

                    if (this.experience && this.experience.image) {
                        this.backgroundImage = this.experience.image;
                    }
                }
            },
            scroll(){
                window.bus.$emit('fullPageMoveSectionDown');
            }
        },
        computed: {
            hardcodedUrls() {
                return GlobalVars.HARCODED_URLS;
            },
            nextExperience(){
                let search = this.$store.state.search;
                if(search && search.criteria.params.type === 'experience' && search.results) {
                    let currentIndex = search.results.data.findIndex(x => x.id == this.experience.id);

                    if(currentIndex > -1 && currentIndex !== search.results.data.length){
                        let nextExperience = search.results.data[currentIndex + 1];

                        if(nextExperience && nextExperience.vue_route_params){
                            //console.log('Next experience is ' + nextExperience.title);
                            return {
                                name: 'single-experience',
                                params: {
                                    ...nextExperience,
                                    ...nextExperience.vue_route_params.data
                                }
                            }
                        }
                    }
                }

                return false;
            },
            googleAnalytics() {
                return window.GoogleAnalytics;
            },
            analyticsEventCategory() {
                return 'Add ' + window.GoogleAnalytics.parseVueRouteParams(this.$route.params);
            },
            experience() {
                if(this.experienceLoaded) {
                    let experience = this.stateExperience;

                    let {price, guide_price, conservation_rating, rating} = this.$route.params;

                    return {
                        ...experience,
                        price: experience.price,
                        guide_price: experience.guide_price,
                        conservation_rating: conservation_rating ? conservation_rating : experience.conservation_rating,
                        rating: rating ? rating : experience.rating
                    };
                }else if(this.currentArticle.title && this.currentArticle.images){
                    let data = this.currentArticle.images.data;

                    if (_.isArray(data)) {
                        data = data[0];
                    }

                    //Return a temporary response whilst the real experience is loaded.
                    return {
                        title: "",
                        top_section: {
                            tag: null,
                            content: data.caption,
                        },
                        image: data.sizes.extralarge,
                        images: {
                            sizes: {
                                extralarge: data.sizes.extralarge,
                            }
                        },
                        sections: {
                            data: []
                        },
                        allSections: {
                            data: []
                        },
                        edit: false
                    }
                }else{
                    return false;
                }

            },
            currentArticle() {
                return this.$store.state.activeArticle.article;
            },
            bg_img_url() {
                if(this.experience.images.sizes.extralarge) {
                    return "background-image:url('" + this.experience.images.sizes.extralarge + "')";
                }else{
                    return;
                }
            },
            check(){
                return this.wishlist.some((el) => {
                    return el.id == this.experience.id;
                });
            },
            locationName(){
                // return this.searchLocation ? this.searchLocation.name : 'Africa';
                return 'Africa';
            },
            ...mapState({
                stateExperience: state => state.singleExperience.experience,
                month: state => state.search.criteria.params.month,
                searchLocation: state => state.search.criteria.params.location,
                stateLocation: state => state.singleLocation.location,
                wishlist: state => state.wishlist.selected
            })
        },
        beforeRouteUpdate(to, from, next) {
            if($('body').hasClass('-disable-page-wipe')) {
                this.loadingNextResult = true;

                this.$store.dispatch(window.store.FETCH_AND_SET_EXPERIENCE, to.params['slug']).then(() => {
                    window.bus.$emit('fullPageDestroying');
                    window.bus.$emit('fullPageDestroyed');
                    window.bus.$emit('fullPageInit');

                    next();

                }).catch(() => {
                    this.$router.push({name: 'page404'});

                }).finally(() => {
                    this.loadingNextResult = false;

                    setTimeout(() => {
                        $('body').removeClass('-disable-page-wipe')
                    },2000);
                });
            }else{
                next();
            }
        },
        beforeRouteLeave(to, from, next) {

            //Only do this if nwe're going from location to location (i.e. next up module)
            if($('body').hasClass('-disable-page-wipe')) {
                if (to['name'] === 'single-experience') {

                    /** Before we attempt to enter this route trigger an update from the store */
                    store.dispatch(window.store.FETCH_AND_SET_EXPERIENCE, to.params['slug']).then(() => {
                        next();
                    }).catch(() => {
                        this.$router.push({name: 'page404'});
                    });
                } else {
                    next();
                }

            }else{
                next();
            }
        },
        mounted() {

            //window.bus.$emit('setShowBackButton', false);
            this.$store.dispatch(window.store.FETCH_AND_SET_EXPERIENCE, this.$route.params['slug']).then(() => {
                let {price, guide_price, conservation_rating, rating} = this.$route.params;

                if (Object.keys(this.$route.params).length > 0 && this.$route.params.hasOwnProperty('showOuts')) {
                    this.showOuts = this.$route.params.showOuts
                }
                this.loadingNextResult = false;
                this.experienceLoaded = true;

                // this.$store.dispatch(window.store.FETCH_AND_SET_EXPERIENCE, this.$route.params['slug']).then((response) => {
                setTimeout(() => {
                    if(this.$refs.atrFullpage) {
                        this.$refs.atrFullpage.init();
                    }
                });
                // });


                if((pnpHelper.bootstrap.isIpadOrSmaller() && pnpHelper.browser.isIOS && pnpHelper.bootstrap.isSm())) {
                }else{
                    this.setBackgroundImage();
                }

                window.bus.$once('lazyloadAllLoaded', this.lazyLoadHiddenImages);


            }).catch(() => {
                this.$router.push({ name: 'page404' });
            });

            this.months = GlobalVars.MONTHS;
            window.bus.$once('fullPageAfterDomReady', this.declarePageIsReady);
        },
        beforeDestroy() {
            window.bus.$emit('setShowBackButton', false);
        },
        created(){
            window.bus.$on('fullPageEnteringHalfScreen', this.setBackgroundImage);
        },
        destroyed() {
            window.bus.$off('fullPageEnteringHalfScreen', this.setBackgroundImage);
        }
    }
</script>
