<template>
    <section class="right-side map-container -half-page-map"
             :class="{'article-images-container': (!doing_search && isHalfImageType),
             '-loaded': !doing_search,
             '-loading': doing_search,
             '-show-map' : (!doing_search && (search.criteria.params.type === 'lodge' || search.criteria.params.type === 'location' || search.criteria.params.type === 'trip'))}">

        <template v-if="searchResults && !doing_search && isHalfImageType && selectedResult.images && (search.criteria.params.type !== 'lodge' && search.criteria.params.type !== 'location' && search.criteria.params.type !== 'trip')">
            <div class="article-images -showing-images -in vh-fix" :class="{'-no-hover': selectedResult.children && selectedResult.children.length === 0}">
                <transition-group name="fade" mode="in-out">
                    <article-image-presentational :transition_name="transitionName"
                                                  :key="selectedResult.id"
                                                  v-if="(searchResults.type=='resource' || searchResults.type=='article' || searchResults.type=='trip_collection') && selectedResult.images.data.sizes.extralarge"
                                                  :image="selectedResult.images.data.sizes.extralarge"
                                                  :title="selectedResult.title"
                                                  :content="selectedResult.images.data.caption ? selectedResult.images.data.caption : selectedResult.content"
                                                  :is_link="selectedResult.children.length === 0"
                                                  :route_params="getRouteParams">
                    </article-image-presentational>

                    <article-image-presentational :transition_name="transitionName"
                                                  :key="selectedResult.id"
                                                  v-if="searchResults.type=='experience' && selectedResult.images.data[0].sizes.extralarge"
                                                  :image="selectedResult.images.data[0].sizes.extralarge"
                                                  :title="selectedResult.title"
                                                  content=""
                                                  :is_link="true"
                                                  :route_params="getRouteParams">
                    </article-image-presentational>
                </transition-group>
            </div>
        </template>

        <template v-if="loadMap" v-show="searchResults && !isHalfImageType">
            <map-presentational :classes="['vignette', 'vignette-half-map', 'vignette-map']"
                                :lazy_load="searchResults && (searchResults.type == 'experience' || searchResults.type == 'resource')"></map-presentational>
        </template>
    </section>
</template>

<script>
    import ButtonInternal from "../partials/presentational/ButtonInternal";
    import pageIsReadyMixin from '@/mixins';
    import GlobalVars from "@/global/GlobalVars";
    import UrlMap from '@/global/UrlMap';

    export default {
        mixins: [pageIsReadyMixin],
        components: {ButtonInternal},
        props: [
            'doing_search'
        ],
        computed: {
            typeInt() {
                /** TODO: Refactor into generic helper method */
                if (this.search.criteria.params.type == 'lodge') {
                    return GlobalVars.LODGE;
                }

                if (this.search.criteria.params.type == 'location') {
                    return GlobalVars.LOCATION;
                }

                return false;
            },
            getRouteParams() {
              let objType = this.search.criteria.params.type;
              if (objType === 'trip_collection') {
                objType = 'trip-intelligence'
              }
              return {
                name: 'single-' + objType,
                params: this.selectedResult.vue_route_params.data
              }
            },
            searchResults() {
                return this.$store.state.search.results;
            },
            search() {
                return this.$store.state.search;
            },
            selectedResult() {
                return this.$store.state.activeArticle.article;
            },
            transitionName() {
                if(this.search.criteria.params.type === 'experience'){
                    return 'wipe';
                }else {
                    return this.selectedResult.display_type === 1 ? 'article-to-fullpage' : 'article-to-halfpage';
                }
            },
            loadMap() {
                return !window.pnpHelper.bootstrap.isXs();
            }
        },
        mounted() {
            this.$store.commit(window.store.SET_PAGE_TITLE, this.$route.meta['page_title']);
            this.$store.commit(window.store.SET_META_DESCRIPTION, this.$route.meta['meta_description']);
            window.bus.$once('MapLoaded', this.declarePageIsReady);
            window.bus.$on('smallGlobalSearch', this.lodgeOrLocationAutocompleteClick);
        },
        methods: {
            lodgeOrLocationAutocompleteClick(result) {

                if(this.$route.name === "directory"){

                    window.bus.$emit('expand-directory-node', {directoryId: result.id, preventZoom:false});

                }else {
                    //Update the location
                    let newLocation;

                    if (result.type == GlobalVars.LOCATION) {
                        newLocation = {
                            id: result.id,
                            name: result.title
                        };

                    } else if (result.type == GlobalVars.LODGE) {
                        newLocation = {
                            id: result.critical_parent.data.id,
                            name: result.critical_parent.data.title
                        };
                    }

                    this.$store.commit(window.store.SET_SEARCH_LOCATION_AND_URL, newLocation);

                    //Reset the experience
                    let newExperience = {
                        id: 3,
                        name: 'Any Experience'
                    };

                    this.$store.commit(window.store.SET_SEARCH_EXPERIENCE, newExperience, { root: true });
                    this.$store.commit(window.store.SET_SEARCH_EXPERIENCES, [newExperience]);
                    // window.bus.$emit('searchDoSearch');

                    this.$store.commit(window.store.SET_SEARCH_BUDGET_RANGE, [0,5000]);

                    //Set the type to lodge always
                    this.$store.commit(window.store.SET_SEARCH_TYPE_URL, 'lodge');


                    setTimeout(() => {
                        $('html,body').animate({
                            scrollTop: 0
                        }, 500);

                        window.bus.$emit("searchRefresh");
                    },0);

                    setTimeout(() => {
                        window.pnpHelper.map.showAutoCompleteMapItem(result);
                    }, 2000);
                }
            },
            isHalfImageType(){
                let t = GlobalVars.IS_HALF_IMAGE_TYPE(this.search.criteria.params.type);
                console.log(t)
                return t;
            }
        }
    }
</script>
