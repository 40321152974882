<template>
    <section class="trip-builder-cta">
        <div class="container">
            <div class="cta-content">
                <div class="text-content">
                    <h3 class="heading">Your trip</h3>
                    <p>It's never to soon to get in touch.</p>
                    <p>Let us know your thoughts, no matter how vague or specific, and one of our experienced safari experts will help you to develop the perfect trip.</p>
                    <button-internal-presentational
                            classes="btn -main-cta -ripple"
                            :router_object="{path: hardcodedUrls.resources.gettingStarted}">
                        <span><span>Start Planning Your Safari</span> <i class="ion-ios-arrow-thin-right"></i></span>
                    </button-internal-presentational>
                </div>
                <div class="image-container">
                    <img v-lazy="'/assets/img/cta-trip-builder.jpg'" alt="Trip Builder CTA Image" class="cta-image" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import GlobalVars from '../../../global/GlobalVars';
    export default {
        computed: {
            hardcodedUrls() {
                return GlobalVars.HARCODED_URLS;
            }
        }   
    }
</script>