<template>
    <div @click="click">
        <slot></slot>

        <canvas class="mute-canvas" ref="myCanvas"></canvas>
    </div>
</template>

<script>
    export default {
        props: ['click', 'source', 'parentRefs', 'value'],
        methods: {
            startAnimation(){
                let audioCtx = new (window.AudioContext || window.webkitAudioContext)();
                let analyser = audioCtx.createAnalyser();
                let canvas = this.$refs.myCanvas;
                let canvasCtx = canvas.getContext("2d");
                let WIDTH = canvas.width;
                let HEIGHT = canvas.height;
                let bufferLength = analyser.frequencyBinCount;
                let dataArray = new Uint8Array(bufferLength);
                let video = this.parentRefs.video;

                analyser.fftSize = 128;

                canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

                let source = audioCtx.createMediaElementSource(video);
                source.connect(analyser);
                analyser.connect(audioCtx.destination);

                let draw = () => {
                    let drawVisual = requestAnimationFrame(draw);


                    canvasCtx.fillStyle = 'rgba(33, 33, 33, 0)';
                    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
                    canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

                    let barWidth = WIDTH/9;
                    let barHeight;
                    let x = 0;
                    let barColor = 'rgba(255, 255, 255, 1)';

                    if(video.paused === true || video.muted === true){
                        dataArray = [190, 150, 210, 180, 160, 200, 170, 150, 150];
                        barColor = 'rgba(255, 31, 15, 1)';
                    } else {
                        dataArray = new Uint8Array(bufferLength);
                        analyser.getByteFrequencyData(dataArray);
                        barColor = 'rgba(255, 255, 255, 1)';
                    }

                    for(let i = 0; i < bufferLength; i+=2) {
                        barHeight = dataArray[i]/1.6;

                        canvasCtx.fillStyle = barColor;

                        canvasCtx.fillRect(x,HEIGHT-barHeight/1.2,barWidth,barHeight);

                        x += barWidth + 28;
                    }
                };

                draw();
            }
        },
        mounted(){
            this.startAnimation();
        }
    }
</script>

<style scoped>

</style>