<template>
    <div class="wishlist-button">
        <div>
            <a @click="toggleWishlist">
                <div class="icon-container -size-2">
                    <i :class="icon"></i>
                </div>

                <span class="wishlist-btn-text">{{text}}</span>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WishListButton",
        props: [
            'icon',
            'text',
        ],
        methods: {
            toggleWishlist(){
                return;
            }
        }
    }
</script>