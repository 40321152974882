<template>
    <div>
        <ul class="facets" v-bind:class="[facetClass]" v-if="dataSet.length > 1">
            <li>
                <a v-for="(facet, key) in dataSet"
                   v-bind:class="{active: currentFacet === facet}"
                   v-bind:style="{backgroundColor: facetColour, opacity: facetOpacity[key]}"
                   v-on:click="setCurrentFacet(facet)"
                   class="facet">

                   {{key.replace('_', ' ')}}
                </a>
            </li>
        </ul>

        <div class="seasonality-graph">
            <div class="data-point" v-for="i in 12">
                <a class="label"
                   v-on:click="setCurrentMonth(i);setCurrentMonthCallback(i)"
                   v-bind:class="{active: currentMonth === i}">
                    {{globals.months[i].shortName}}
                </a>

                <a class="bar"
                   v-on:click="setCurrentMonth(i);setCurrentMonthCallback(i)"
                     v-bind:style="{height: (currentFacet[i-1] * 10) + '%', backgroundColor: facetColour, opacity: (currentFacet[i-1]/10 + 0.2)}">
                </a>
            </div>

            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['dataSet', 'facetColour', 'facetClass', 'defaultFacet', 'defaultMonth', 'setCurrentMonthCallback'],

        data() {
            return {
                globals: window.globals,
                currentFacet: '',
                currentMonth: '',
                facetOpacity: []
            };
        },

        mounted() {
            this.setCurrentFacet(this.dataSet[this.defaultFacet]);
            this.setCurrentMonth(this.defaultMonth);
        },

        methods: {
            setCurrentMonth(newMonth){
                this.currentMonth = newMonth;

                //Set this facets facet scores for this month
                for(var i in this.dataSet){
                    var facet = this.dataSet[i];

                    this.facetOpacity[i] = (facet[this.currentMonth]/10) + 0.2;
                }

                //Temp, to force an update of the opacity. Bug?
                var temp = this.currentFacet;
                this.setCurrentFacet(null);
                this.setCurrentFacet(temp);
            },

            setCurrentFacet(newFacet){
                this.currentFacet = newFacet;
            }
        },

        watch: {
            defaultMonth: function(newVal, oldVal) {
                this.setCurrentMonth(newVal);
            }
        },
    }
</script>