<template>
    <table class="trip-summary-table">
        <tr class="table-header">
            <th><p class="sub-text">LODGE</p></th>
            <th><p class="sub-text -center">NIGHTS</p></th>
            <th><p class="sub-text -right">USD</p></th>
        </tr>

        <template v-for="(location, index) in trip.locations">
            <tr v-if="location.lodges" class="lodge-row">
                <td class="f27 lodge">{{ selectedLodge(location.lodges).title }}</td>

                <td class="f27 -center">{{ selectedLodge(location.lodges).nights }}</td>

                <td class="f27 -right">{{selectedLodge(location.lodges).price}}</td>
            </tr>
        </template>

        <template v-for="(item, index) in trip.transport">
            <tr class="transport-fees" :data-id="index" :key="index">
                <td class="f27 lodge">{{item.title}}</td>
                <td style=""></td>
                <td class="f27 -right -bold">{{ item.price }}</td>
            </tr>
        </template>

        <tr class="lodge-row -line-break">
            <td class="f27 lodge"><hr></td>
            <td class="f27 lodge"><hr></td>
            <td class="f27 lodge"><hr></td>
        </tr>

        <tr class="lodge-row">
            <td class="f27 lodge">Total</td>
            <td class="f27 -center">{{trip.num_nights}}</td>
            <td class="f27 -right">{{trip.price}}</td>
        </tr>
    </table>
</template>

<script>
    export default {
        name: "LodgePriceTable",
        props: ['trip'],
        data(){
            return {
                lodgeNumber: 0
            }
        },
        methods: {
            selectedLodge(lodges){
                let selectedLodge = lodges.filter((lodge)=> {
                    return lodge.selected == true;
                });

                return selectedLodge[0];
            }
        },
        computed: {
            totalNights(){
                return this.trip.locations.map((location) => {
                   if(location.lodges){
                       return this.calcNightNum(this.selectedLodge(location.lodges));
                   }
                    return 0
                }).reduce((acc, currVal) => {
                   return acc + currVal;
                });
            },
            totalPrice(){
                return this.trip.locations.map((location) => {
                    if(location.lodges){
                        return this.selectedLodge(location.lodges).price;
                    }
                    return 0
                }).reduce((acc, currVal) => {
                    return parseInt(acc) + parseInt(currVal);
                });
            }
        }
    }
</script>