export default class UrlMap {
    constructor() {
    }

    urlEncode(items) {
        let encodedItems = null;

        if (items.length > 0) {
            for(let i=0; i<items.length; i++){
                if(encodedItems === null){
                    encodedItems = "";
                }else{
                    encodedItems += ",";
                }

                encodedItems += items[i].id + ":" + items[i].name;
            }
        }

        if(encodedItems !== null){
            return encodeURIComponent(encodedItems);
        }else{
            return null;
        }
    }

    urlDecode(items){
        let decodedItems = null;

        if(items && items.length > 0){
            decodedItems = [];
            items = decodeURIComponent(items);
            let itemsArr = items.split(',');

            for(let i=0; i<itemsArr.length; i++){
                let item = itemsArr[i].split(':');

                decodedItems.push({
                   id:  parseInt(item[0]),
                   name:  item[1]
                });
            }
        }

        return decodedItems;
    }

    searchStateToUrlParams(state) {

        let obj = {
            location: _.isUndefined(state.criteria.params.location) || state.criteria.params.location === null ? 0 : state.criteria.params.location.id,
            lname: _.isUndefined(state.criteria.params.location) || state.criteria.params.location === null ? 0 : state.criteria.params.location.name,
            lprimary: _.isUndefined(state.criteria.params.location) || state.criteria.params.location === null ? true : state.criteria.params.location.lprimary,
            experience: _.isUndefined(state.criteria.params.experience) || state.criteria.params.experience === null ? 0 : state.criteria.params.experience.id,
            ename: _.isUndefined(state.criteria.params.experience) || state.criteria.params.experience === null ? 0 : state.criteria.params.experience.name,
            eprimary: _.isUndefined(state.criteria.params.experience) || state.criteria.params.experience === null ? true : state.criteria.params.experience.primary,
            month: state.criteria.params.month,
            bto: state.criteria.params.budgetTo,
            bfrom: state.criteria.params.budgetFrom,
            tripbto: state.criteria.params.tripBudgetTo,
            tripbfrom: state.criteria.params.tripBudgetFrom,
            sort: state.criteria.params.sortOrder.order,
            sname: state.criteria.params.sortOrder.label,
            type: state.criteria.params.type,
            duration: state.criteria.params.duration
        };

        let locations = this.urlEncode(state.criteria.params.locations);

        if(locations){
            obj['locations'] = locations;
        }

        let experiences = this.urlEncode(state.criteria.params.experiences);

        if(experiences){
            obj['experiences'] = experiences;
        }

        return obj;
    }

    getDataSource(route) {
        let params = ['month', 'location', 'experience', 'sort', 'type', 'duration', 'bfrom', 'bto', 'tripbfrom', 'tripbto'];

        if (!_.isUndefined(route['query']) && _.difference(params, Object.keys(route['query'])).length !== params.length) {
            return 'query';
        }

        if (!_.isUndefined(route['meta']) && _.difference(params, Object.keys(route['meta'])).length !== params.length) {
            return 'meta';
        }
        
        return false;
    }

    urlToSearchState(route, store) {
        let dataSource = this.getDataSource(route);
        
        if (dataSource === false) {
            return false;
        }

        if (route[dataSource].hasOwnProperty('locations')) {
            let locations = this.urlDecode(route[dataSource].locations);
            store.commit(window.store.SET_SEARCH_LOCATIONS, locations);
        }

        if (route[dataSource].hasOwnProperty('experiences')) {
            let experiences = this.urlDecode(route[dataSource].experiences);
            store.commit(window.store.SET_SEARCH_EXPERIENCES, experiences);
        }

        if (route[dataSource].hasOwnProperty('month')) {
            store.commit(window.store.SET_SEARCH_MONTH, route[dataSource].month);
        }

        if (route[dataSource].hasOwnProperty('location')) {
            store.commit(window.store.SET_SEARCH_LOCATION, {
                id: route[dataSource].location,
                name: route[dataSource].lname,
                lprimary: route[dataSource].lprimary
            });
        }

        if (route[dataSource].hasOwnProperty('experience')) {
            store.commit(window.store.SET_SEARCH_EXPERIENCE, {
                id: route[dataSource].experience,
                name: route[dataSource].ename,
                primary: route[dataSource].eprimary

            });
        }

        if (route[dataSource].hasOwnProperty('duration')) {
            store.commit(window.store.SET_SEARCH_DURATION, parseInt(route[dataSource].duration));
        }

        if (route[dataSource].hasOwnProperty('bfrom') && route[dataSource].hasOwnProperty('bto')) {
            store.commit(window.store.SET_SEARCH_BUDGET_RANGE, [parseInt(route[dataSource].bfrom), parseInt(route[dataSource].bto)]);

            // }else{
            // if(route[dataSource].hasOwnProperty('type') === 'trip'){
            //     store.commit(window.store.SET_SEARCH_BUDGET_RANGE, [0,50000]);
            // }else{
            //     store.commit(window.store.SET_SEARCH_BUDGET_RANGE, [0,5000]);
            // }
        }

        if (route[dataSource].hasOwnProperty('tripbfrom') && route[dataSource].hasOwnProperty('tripbto')) {
            store.commit(window.store.SET_SEARCH_TRIP_BUDGET_RANGE, [parseInt(route[dataSource].tripbfrom), parseInt(route[dataSource].tripbto)]);

            // }else{
            // if(route[dataSource].hasOwnProperty('type') === 'trip'){
            //     store.commit(window.store.SET_SEARCH_BUDGET_RANGE, [0,50000]);
            // }else{
            //     store.commit(window.store.SET_SEARCH_BUDGET_RANGE, [0,5000]);
            // }
        }

        if (route[dataSource].hasOwnProperty('sort')) {
            store.commit(window.store.SET_SEARCH_SORT_ORDER, {
                order: route[dataSource].sort,
                label: route[dataSource].sname
            });
        }

        if (route[dataSource].hasOwnProperty('type')) {
            store.commit(window.store.SET_SEARCH_TYPE, route[dataSource].type);

            //If it's a trip and there is no locations, but there is a location, create the locations
            if(route[dataSource].type === 'trip' && route[dataSource].hasOwnProperty('location') && !route[dataSource].hasOwnProperty('locations')) {
                store.commit(window.store.SET_SEARCH_LOCATIONS, [store.state.search.criteria.params.location]);
            }

            //If it's a trip and there is no experiences, but there is an experience, create the experiences
            if(route[dataSource].type === 'trip' && route[dataSource].hasOwnProperty('experience') && !route[dataSource].hasOwnProperty('experiences')) {
                store.commit(window.store.SET_SEARCH_EXPERIENCES, [store.state.search.criteria.params.experience]);
            }

            window.bus.$emit('searchDoSearch');
        }
        
        return true;
    }
}