export default class GlobalVars {
    static get CODE_VERSION() {
        return 22;
    }

    static get LODGE() {
        return 13;
    }

    static get LOCATION() {
        return 7;
    }

    static get RESOURCE() {
        return 14;
    }

    static get TRIP() {
        return 15;
    }

    static get EXPERIENCE() {
        return 6;
    }

    static get TRIP_COLLECTION() {
        return 18;
    }

    static get ARTICLES() {
        return 17;
    }


    static get TRANSIT_LOCATION() {
        return 98;
    }

    static get sm() {
        return 768;
    }

    static get TWELVE_HOURS() {
        return 43200;
    }

    static get ONE_HOUR() {
        return 3600;
    }

    static get F0REVER() {
        //100 earth years
        return 3155760000;
    }

    static get MONTHS() {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }

    static get HARCODED_URLS() {
        return {

            trips: {
                africa: '/safari-locations/africa',
                botswana: '/safari-locations/botswana',
                tanzania: '/safari-locations/tanzania',
                southAfrica: '/safari-locations/south-africa',
                kenya: '/safari-locations/kenya',
                zambia: '/safari-locations/zambia',
                zimbabwe: '/safari-locations/zimbabwe',
                uganda: '/safari-locations/uganda',
                namibia: '/safari-locations/namibia',
                rwanda: '/safari-locations/rwanda',
                malawi: '/safari-locations/malawi',
                congo: '/safari-locations/congo',
                egypt: '/safari-locations/egypt',
                madagascar: '/safari-locations/madagascar',
                antarctica: '/safari-locations/antarctica',
                argentina: '/safari-locations/argentina',
                bhutan: '/safari-locations/bhutan',
                bolivia: '/safari-locations/bolivia',
                brazil: '/safari-locations/brazil',
                chile: '/safari-locations/chile',
                ecuador: '/safari-locations/ecuador',
                galapagos: '/safari-locations/galapagos',
                india: '/safari-locations/india',
                maldives: '/safari-locations/maldives',
                peru: '/safari-locations/peru',
                sriLanka: '/safari-locations/sri-lanka',
            },

            finder:{
                trips: '/safari-explore/finder/trips',
                resources: '/safari-explore/finder/resources',
                locations: '/safari-explore/finder/locations',
                experiences: '/safari-explore/finder/experiences',
                lodges: '/safari-explore/finder/lodges',
                articles: '/safari-explore/finder/articles',
                trip_collections: '/safari-explore/finder/trip-collections',
            },

            locations: {
                botswana: '/safari-locations/botswana',
                tanzania: '/safari-locations/tanzania',
                southAfrica: '/safari-locations/south-africa',
                kenya: '/safari-locations/kenya',
                zambia: '/safari-locations/zambia',
                namibia: '/safari-locations/namibia',
                zimbabwe: '/safari-locations/zimbabwe'
            },

            experiences: {
                leopards: '/safari-experiences/the-best-leopards-in-africa',
                migration: '/safari-experiences/the-best-migration-in-africa',
                rhinos: '/safari-experiences/the-best-rhinos-in-africa',
                cheetahs: '/safari-experiences/the-best-cheetahs-in-africa',
                wildDogs: '/safari-experiences/the-best-wild-dogs-in-africa',
                meerkats: '/safari-experiences/the-best-meerkats-in-africa',
                lions: '/safari-experiences/the-best-lions-in-africa',
                elephants: '/safari-experiences/the-best-elephants-in-africa',
                vehicleSafari: '/safari-experiences/the-best-vehicle-safaris-in-africa',
                walkingSafari: '/safari-experiences/the-best-walking-safaris-in-africa',
                balloonSafari: '/safari-experiences/the-best-balloon-safaris-in-africa',
                nightVehicleSafari: '/safari-experiences/the-best-night-vehicle-safaris-in-africa',
                motorboatSafari: '/safari-experiences/the-best-motorboat-safaris-in-africa',
                horseRiding: '/safari-experiences/the-best-horse-riding-in-africa',
                hides: '/safari-experiences/the-best-hides-in-africa',
                mokoro: '/safari-experiences/the-best-mokoro-canoe-safaris-in-africa',
            },

            resources: {
                overallRating: '/safari-resources/overall-rating',
                priceGuarantee: '/safari-resources/price-guarantee',
                howMuchToSpend: '/safari-resources/how-much-to-spend',
                howToSaveMoney: '/safari-resources/how-to-save-money',
                conservation: '/safari-resources/conservation',
                howToBook: '/safari-resources/how-to-book',
                whatMakesGood: '/safari-resources/what-makes-a-good-safari',
                firstTime: '/safari-resources/first-time-safari',
                advancedSafariOptions: '/safari-resources/advanced-safari-options',
                howLongToSpend: '/safari-resources/how-long-to-spend-in-africa',
                kidsOnSafari: '/safari-resources/kids-on-safari',
                whenToTravel: '/safari-resources/when-to-travel-to-africa',
                gettingStarted: '/safari-resources/getting-started',
                aboutUs: '/safari-resources/a-little-bit-about-us',
            }
        }
    }

    static get FULL_LOCATIONS_LIST() {
        return {
            antarctica: {
                name: 'Antarctica',
                url: '/safari-locations/antarctica',
                children: [
                    {
                        name: 'Antarctic Peninsula',
                        url: '/safari-locations/antarctic-peninsula',
                    },
                    {
                        name: 'Queen Maud Land',
                        url: '/safari-locations/qeen-maud-land',
                    },
                    {
                        name: 'South Georgia',
                        url: '/safari-locations/south-georgia',
                    }
                ]
            },
            argentina: {
                name: 'Argentina',
                url: '/safari-locations/argentina',
                children: [
                    {
                        name: 'Iguazu Falls',
                        url: '/safari-locations/iaguazu-falls',
                    },
                    {
                        name: 'Los Glaciares',
                        url: '/safari-locations/las-glaciares',
                    },
                    {
                        name: 'Penninsula Valdes',
                        url: '/safari-locations/penninsula-valdes',
                    },
                    {
                        name: 'Salta',
                        url: '/safari-locations/salta',
                    }
                ]
            },
            bhutan: {
                name: 'Bhutan',
                url: '/safari-locations/bhutan',
                children: [
                    {
                        name: 'Bumthang',
                        url: '/safari-locations/bumthang',
                    },
                    {
                        name: 'Punakha',
                        url: '/safari-locations/punakha',
                    },
                ]
            },
            bolivia: {
                name: 'Bolivia',
                url: '/safari-locations/bolivia',
                children: [
                    {
                        name: 'Salar de Uyuni',
                        url: '/safari-locations/salar-de-uyuni',
                    },
                ]
            },
            botswana: {
                name: 'Botswana',
                url: '/safari-locations/botswana',
                children: [
                    {
                        name: 'Linyanti Waterfront',
                        url: '/safari-locations/linyanti-waterfront',
                    },
                    {
                        name: 'Makgadikgadi',
                        url: '/safari-locations/makgadikgadi',
                    },
                    {
                        name: 'Okavango Delta',
                        url: '/safari-locations/okavango-delta',
                    },
                ]
            },
            brazil: {
                name: 'Brazil',
                url: '/safari-locations/brazil',
                children: [
                    {
                        name: 'Manaus Amazon',
                        url: '/safari-locations/manaus-amazon',
                    },
                    {
                        name: 'Pantanal',
                        url: '/safari-locations/pantanal',
                    },
                    {
                        name: 'Rio de Janeiro',
                        url: '/safari-locations/rion-de-janeiro',
                    },
                    {
                        name: 'Iguazu Falls',
                        url: '/safari-locations/iaguazu-falls',
                    },
                   
                ]
            },
            centralAfricanRepublic: {
                name: 'Central African Republic',
                url: '/safari-locations/cenral-african-republic',
            },
            chad: {
                name: 'Chad',
                url: '/safari-locations/chad',
            },
            chile: {
                name: 'Chile',
                url: '/safari-locations/chile',
                children: [
                    {
                        name: 'Atacama',
                        url: '/safari-locations/atacama',
                    },
                    {
                        name: 'Easter Island',
                        url: '/safari-locations/eastr-island',
                    },
                    {
                        name: 'Torres del Paine',
                        url: '/safari-locations/torres-del-paine',
                    },
                ]
            },
            congo: {
                name: 'Congo',
                url: '/safari-locations/congo',
            },
            ecuador: {
                name: 'Ecuador',
                url: '/safari-locations/ecuador',
                children: [
                    {
                        name: 'Yasuni Amazon',
                        url: '/safari-locations/yausni-amazon',
                    },
                ]
            },
            egypt: {
                name: 'Egypt',
                url: '/safari-locations/egypt',
                children: [
                    {
                        name: 'Aswan',
                        url: '/safari-locations/aswan',
                    },
                    {
                        name: 'Giza',
                        url: '/safari-locations/giza',
                    },
                    {
                        name: 'Luxor',
                        url: '/safari-locations/luxor',
                    },
                ]
            },
            ethiopia: {
                name: 'Ethiopia',
                url: '/safari-locations/ethiopia',
                children: [
                    {
                        name: 'Lalibela',
                        url: '/safari-locations/lalibela',
                    },
                    {
                        name: 'Lower Omo Valley',
                        url: '/safari-locations/lower-omo-valley',
                    },
                ]
            },
            galapagos: {
                name: 'Galapagos',
                url: '/safari-locations/galapagos',
            },
            india: {
                name: 'India',
                url: '/safari-locations/india',
                children: [
                    {
                        name: 'Agra',
                        url: '/safari-locations/agra',
                    },
                    {
                        name: 'Bandhavgarh',
                        url: '/safari-locations/bandhavgarh',
                    },
                    {
                        name: 'Jaipur',
                        url: '/safari-locations/jaipur',
                    },
                    {
                        name: 'Jodhpur',
                        url: '/safari-locations/jodhpur',
                    },
                    {
                        name: 'Kanha',
                        url: '/safari-locations/kanha',
                    },
                    {
                        name: 'Kaziranga',
                        url: '/safari-locations/kaziranga',
                    },
                    {
                        name: 'Ladakh',
                        url: '/safari-locations/ladakh',
                    },
                    {
                        name: 'Ranthambore',
                        url: '/safari-locations/ranthambore',
                    },
                    {
                        name: 'Udaipur',
                        url: '/safari-locations/udaipur',
                    },
                ]
            },
            kenya: {
                name: 'Kenya',
                url: '/safari-locations/kenya',
                children: [
                    {
                        name: 'Amboseli',
                        url: '/safari-locations/amoseli',
                    },
                    {
                        name: 'Chyulu Hills',
                        url: '/safari-locations/chyulu-hills',
                    },
                    {
                        name: 'Masai Mara',
                        url: '/safari-locations/masai-mara',
                    },
                    {
                        name: 'Samburu',
                        url: '/safari-locations/samburu',
                    },
                ]
            },
            madagascar: {
                name: 'Madagascar',
                url: '/safari-locations/madagascar',
                children: [
                    {
                        name: 'Andasibe',
                        url: '/safari-locations/andasibe',
                    },
                    {
                        name: 'Ankarana',
                        url: '/safari-locations/ankarana',
                    },
                    {
                        name: 'Masoala',
                        url: '/safari-locations/masoala',
                    },
                    {
                        name: 'Nosy Be',
                        url: '/safari-locations/nosy-be',
                    },
                ]
            },
            malawi: {
                name: 'Malawi',
                url: '/safari-locations/malawi',
                children: [
                    {
                        name: 'Liwonde',
                        url: '/safari-locations/liwonde',
                    },
                ]
            },
            maldives: {
                name: 'Maldives',
                url: '/safari-locations/maldives',
            },
            mozambique: {
                name: 'Mozambique',
                url: '/safari-locations/mozambique',
                children: [
                    {
                        name: 'Bazaruto',
                        url: '/safari-locations/bazaruto',
                    },
                ]
            },
            namibia: {
                name: 'Namibia',
                url: '/safari-locations/namibia',
                children: [
                    {
                        name: 'Damaraland North',
                        url: '/safari-locations/damaraland-north',
                    },
                    {
                        name: 'Namibrand',
                        url: '/safari-locations/namibrand',
                    },
                    {
                        name: 'Skeleton Coast North',
                        url: '/safari-locations/skeleton-coast-north',
                    },
                    {
                        name: 'Sossusvlei',
                        url: '/safari-locations/sossusvlei',
                    },
                ]
            },
            nepal: {
                name: 'Nepal',
                url: '/safari-locations/nepal',
                children: [
                    {
                        name: 'Annapurna',
                        url: '/safari-locations/annaurna',
                    },
                    {
                        name: 'Chitwan',
                        url: '/safari-locations/chitwan',
                    },
                    {
                        name: 'Everest',
                        url: '/safari-locations/everest',
                    },
                    {
                        name: 'Mustang',
                        url: '/safari-locations/mustang',
                    },
                ]
            },
            rwanda: {
                name: 'Rwanda',
                url: '/safari-locations/rwanda',
                children: [
                    {
                        name: 'Virunga Mountains',
                        url: '/safari-locations/virunga-mountains',
                    },
                    {
                        name: 'Sao Tome and Principe',
                        url: '/safari-locations/sao-tome-and-principe',
                    },
                ]
            },
            peru: {
                name: 'Peru',
                url: '/safari-locations/peru',
                children: [
                    {
                        name: 'Cusco',
                        url: '/safari-locations/cusco',
                    },
                    {
                        name: 'Iquitos Amazon',
                        url: '/safari-locations/iqutios-amazon',
                    },
                    {
                        name: 'Machu Picchu',
                        url: '/safari-locations/machu-picchu',
                    },
                    {
                        name: 'Tambopata Amazon',
                        url: '/safari-locations/tambopata-amazon',
                    }
                ]
            },
            southAfrica: {
                name: 'South Africa',
                url: '/safari-locations/south-africa',
                children: [
                    {
                        name: 'Cape Town',
                        url: '/safari-locations/cape-town',
                    },
                    {
                        name: 'Cape Winelands',
                        url: '/safari-locations/cape-winelands',
                    },
                    {
                        name: 'Kruger',
                        url: '/safari-locations/kruger',
                    },
                    {
                        name: 'Tswalu',
                        url: '/safari-locations/tswalu',
                    },
                ]
            },
            sriLanka: {
                name: 'Sri Lanka',
                url: '/safari-locations/sri-lanka',
                children: [
                    {
                        name: 'Wilpattu',
                        url: '/safari-locations/wilpattu',
                    },
                    {
                        name: 'Yalla',
                        url: '/safari-locations/yalla',
                    },
                ]
            },
            sudan: {
                name: 'Sudan',
                url: '/safari-locations/sudan',
            },
            seychelles: {
                name: 'Seychelles',
                url: '/safari-locations/seyshelles',
            },
            tanzania: {
                name: 'Tanzania',
                url: '/safari-locations/tanzania',
                children: [
                    {
                        name: 'Kilimanjaro',
                        url: '/safari-locations/kilimanjaro',
                    },
                    {
                        name: 'Ngorongoro Crater',
                        url: '/safari-locations/ngorongoro-crater',
                    },
                    {
                        name: 'Ruaha',
                        url: '/safari-locations/ruaha',
                    },
                    {
                        name: 'Serengeti',
                        url: '/safari-locations/seregenti',
                    },
                    {
                        name: 'Selous',
                        url: '/safari-locations/selous',
                    },
                    {
                        name: 'Zanzibar',
                        url: '/safari-locations/zanzibar',
                    },
                ]
            },
            uganda: {
                name: 'Uganda',
                url: '/safari-locations/uganda',
                children: [
                    {
                        name: 'Bwindi Forest',
                        url: '/safari-locations/bwindi-forest',
                    },
                    {
                        name: 'Kibale Forest',
                        url: '/safari-locations/kibale-forest',
                    },
                    {
                        name: 'Murchison Falls',
                        url: '/safari-locations/murchison-falls',
                    },
                ]
            },
            zambia: {
                name: 'Zambia',
                url: '/safari-locations/zambia',
                children: [
                    {
                        name: 'Kafue',
                        url: '/safari-locations/kafue',
                    },
                    {
                        name: 'Lower Zambezi',
                        url: '/safari-locations/lowe-zambezi',
                    },
                    {
                        name: 'South Luangwa',
                        url: '/safari-locations/south-luangwa',
                    },
                ]
            },
            zimbabwe: {
                name: 'Zimbabwe',
                url: '/safari-locations/zimbabwe',
                children: [
                    {
                        name: 'Hwange',
                        url: '/safari-locations/hwange',
                    },
                    {
                        name: 'Mana Pools',
                        url: '/safari-locations/mana-pools',
                    },
                    {
                        name: 'Victoria Falls',
                        url: '/safari-locations/victoria-falls',
                    },
                ]
            },    
        }
    }

    static SINGLE_URL(type){
        switch (type) {
            case `${GlobalVars.EXPERIENCE}`:
                return 'single-experience';
            case `${GlobalVars.RESOURCE}`:
                return 'single-resource';
            case `${GlobalVars.TRIP_COLLECTION}`:
                return 'single-trip-intelligence';
            case `${GlobalVars.ARTICLES}`:
                return 'single-article';
            case `${GlobalVars.LOCATION}`:
                return 'single-location';
            case `${GlobalVars.LODGE}`:
                return 'single-lodge';
            default:
                return '';
        }
    }

    static get APP_URL() {
        return window.location.origin;
    }

    static get ASSETS_URL() {
        return GlobalVars.APP_URL + '/assets';
    }

    static get LOGO_URL() {
        return GlobalVars.ASSETS_URL + '/img/logos/atr.svg';
    }

    static get DARK_LOGO_URL() {
        return GlobalVars.ASSETS_URL + '/img/logos/atr-dark.svg';
    }

    static IS_HALF_IMAGE_TYPE(type){
        return search.criteria.params.type === 'experience' ||
               search.criteria.params.type === 'resource' ||
               search.criteria.params.type === 'article'  ||
               search.criteria.params.type === 'trip_collection';
    }

    static SENTENCE_KEY_VALUES(type){
        let types = {
            'lodge' : 'lodges',
            'location' : 'locations',
            'experience': 'experiences',
            'resource': 'resources',
            'trip': 'trips',
            'article': 'articles',
            'trip_collection': 'trips'
        };
        if(types.hasOwnProperty(type)){
            return types[type];
        }
        return `${type}s`;
    }

    static SENTENCE_KEY_VALUE_SINGULAR(type){
        let types = {
            'lodge' : 'lodge',
            'location' : 'location',
            'experience': 'experience',
            'resource': 'resource',
            'trip': 'trip',
            'article': 'magazine',
            'trip_collection': 'trip'
        };
        if(types.hasOwnProperty(type)){
            return types[type];
        }
        return `${type}s`;
    }
}

