<template>
    <div>
        <p><strong>How would you like us to get back to you?</strong></p>

        <ul class="multi-step-radios-list">
            <li>
                <input type="radio" id="contact_method-online" name="contact_method" value="contact_method-email" @change="updateContactMethod" v-model="selectedOption">
                <label for="contact_method-online">
                    <div>
                        <strong>Email</strong>
                        <p>Sometimes an email is the most convenient way to get started. Hopefully we <br>
        will have enough information to enable one of our safari experts to build <br>
        some great sample trips. From there it shouldn't take long to reach the <br>
        perfect trip.</p>
                    </div>
                </label>
            </li>
            <li>
                <input type="radio" id="contact_method-call" name="contact_method" value="contact_method-call" @change="updateContactMethod" v-model="selectedOption">
                <label for="contact_method-call">
                    <div>
                        <strong>Call appointment</strong>
                    <p>There's nothing quite like a call to get a trip off to a flying start. Select this <br>
    option to set up a time and date for one of our safari experts to call you for a <br>
    quick chat. No hard sell, just safari people chatting about what we love.</p>
                    </div>
                    
                </label>
            </li>
        </ul>
    </div>
</template>

<script>

    export default {
        props: ['selected', 'step', 'step_index'],
        data(){
            return {
                selectedOption: 'contact_method-email',
                stepData: null,
                stepIndex: null,
            }
        },
        methods: {
            updateContactMethod(e){
                const showSteps = {
                    'contact_method-email': [0, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
                    'contact_method-call': [0, 4, 5, 6, 7, 8, 9, 10, 11, 15, 16, 17],
                }

                Object.entries(showSteps).forEach(([key, value]) => {
                    if(key === e.target.value){
                        this.$emit('update-steps', {'type': 'contactMethod', 'key': key, 'value': value});
                    }
                });

            }
        },
        mounted(){
            this.selectedOption = this.selected;

            this.stepData = this.step;
            this.stepIndex = this.step_index;

            if(this.stepData.index !== this.stepIndex){
                return;
            }
            const checkedRadio = document.querySelector('input[value="'+this.selectedOption+'"]');

            checkedRadio.checked;

            checkedRadio.dispatchEvent(new Event('change'));
        }
    }

</script>