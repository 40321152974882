var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hide-during-trans contact-btn-container",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"btn -call-us",on:{"click":function($event){return _vm.toggleOpen()}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Contact Us")]),_c('i',{staticClass:"ion-android-call"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],staticClass:"call-menu",staticStyle:{"max-height":"80vh","overflow":"auto"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-details"},[_vm._v("\n            USA and Canada"),_c('br'),_vm._v("\n            888-487-5418\n        ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-details"},[_vm._v("\n            UK"),_c('br'),_vm._v("\n            01306 880770\n        ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-details"},[_vm._v("\n            Europe and ROW"),_c('br'),_vm._v("\n            +44 1306 880770\n        ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-details"},[_vm._v("\n            Australia"),_c('br'),_vm._v("\n            +61 800 821 832\n        ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"small-text"},[_vm._v("\n            West Coast : 1am-6pm"),_c('br'),_vm._v("\n            East Coast : 4am-9pm"),_c('br'),_vm._v("\n            London : 9am-9pm"),_c('br'),_vm._v("\n            Central Europe : 10am-10pm"),_c('br'),_vm._v("\n            Sydney : 6pm-6am\n        ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-details",staticStyle:{"margin-top":"30px"}},[_c('a',{attrs:{"href":"mailto:salesoffice@atrsafari.com"}},[_vm._v("salesoffice@atrsafari.com")])])
}]

export { render, staticRenderFns }