<template>
    <div>
        <div class="links-search-sentence-container">
            <search-sentence portalTo="sentencePopups" sentenceId="mainSearchPage" />
        </div>

        <!--<div class="search-controls directory-controls">-->
            <sticky-bar id="search-bar" classes="search-bar row no-gutter">
                <div class="col-xs-8 bar-content lodges">
                    <div class="count" v-if="lodgeNum === 0 && locationNum === 0"></div>

                    <div class="count" v-else>
                        {{ lodgeNum }} lodges, {{ locationNum }} locations
                    </div>
                </div>

                <div class="col-xs-4 bar-content text-right">
                    <a class="link -scroll-top" v-on:click="scrollToTop()">TOP <i class="ion-android-arrow-dropup"></i></a>
                </div>

                <div class="clearfix"></div>
            </sticky-bar>

            <div class="directory -lodges-locations">
                <div class="search-results-outer" :class="{'-loading' : lodgeNum === 0 && locationNum === 0}">
                    <public-directory-listing
                            :directory="directory.directory"
                            :level="1"
                            :type="type">
                    </public-directory-listing>
                </div>
            </div>
        <div id="veil" class="veil" ref="veil"></div>

        <!-- <transition-wipe/> -->
    </div>
</template>

<script>
    import pageIsReadyMixin from '../../mixins';
    import GlobalVars from "../../global/GlobalVars";

    export default {
        mixins: [pageIsReadyMixin],
        data(){
            return {
                stateDirectroy: {},
                prevRoute: null
            }
        },
        methods: {
            scrollToTop() {
                window.pnpHelper.scroll.doScroll(0);
            },
            expandNode(obj){
                // console.log('expanding directory node ', id);
                var id = obj.directoryId;

                this.stateDirectroy = window._.cloneDeep(this.directory.directory);

                let newDirectory = this.stateDirectroy;

                let newDirectory2 = {
                    directory: this.recursive(id, newDirectory)
                };

                this.$store.commit(window.store.SET_DIRECTORY, newDirectory2, {root: true});

                setTimeout(()=> {
                    $("html, body").animate({scrollTop: $("#" + id).offset().top - ($window.outerHeight()/2), duration: 100});

                    window.bus.$emit('openCard' + id, obj.preventZoom);
                }, 300);
            },
            recursive(id, results){
                for(let x in results){

                    if(results[x].id == id){

                        results[x].isOpen = true;


                        if(results[x].parent_id){
                            this.recursive(results[x].parent_id,this.stateDirectroy)
                        }

                    } else {
                        results[x].isOpen = false;

                        if(results[x].children != undefined && results[x].children.length > 0){
                            this.recursive(id, results[x].children);
                        }
                    }
                }

                return results;
            }
        },
        computed: {
            search() {
                return this.$store.state.search;
            },
            directory() {
                return this.$store.state.directory;
            },
            type() {
                // if(this.search.criteria.params.type == 'lodge') {
                //     return 13;
                // }
                // return 7;

                return this.search.criteria.params.type;
            },
            lodgeNum(){
                return this.$store.state.directory.lodgeCount;
            },
            locationNum(){
                return this.$store.state.directory.locationCount;
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            })
        },
        mounted() {
            this.$store.dispatch(window.store.FETCH_AND_SET_DIRECTORY).then(() => {
                this.declarePageIsReady();

                this.$store.dispatch(window.store.FETCH_AND_SET_MAP_MARKERS, GlobalVars.LODGE).then(() => {
                    window.pnpHelper.map.searchResultsToMarkers(this.$store.state.search.mapMarkers);
                    window.pnpHelper.map.refreshOnDemandMarkers();

                    if(this.$store.state.search.criteria.params.locations.length && this.prevRoute && this.prevRoute.name === "finder"){
                        var location = this.$store.state.search.criteria.params.locations[0];
                        window.bus.$emit('expand-directory-node', {directoryId: location.id, preventZoom:false});
                    }
                });
            });

        },
        created(){
            window.bus.$on('expand-directory-node', this.expandNode);
        },
        destroyed() {
            window.bus.$off('expand-directory-node', this.expandNode);
        }
    }
</script>