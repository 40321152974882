<template>
    <section class="about-atr">
        <div class="top-section container">
            <div class="about-content">
                <h3 class="heading">About us</h3>
                <p>ATR is widely recognised as the world's leading tailor-made safari company. We will help you to find a more authentic, more exciting, better value safari. </p>
            </div>
            <div class="footer-section">
                <div class="information-grid">
                    <div class="information-item">
                        <h4 class="heading">1999</h4>
                        <p>The year we started building great safaris</p>
                    </div>
                    <div class="information-item">
                        <h4 class="heading">Expert</h4>
                        <p>Our safari experts are uncommonly knowledgable and experienced</p>
                    </div>
                    <div class="information-item">
                        <h4 class="heading">124,755</h4>
                        <p>The number of superb safaris we have created</p>
                    </div>
                    <div class="information-item">
                        <h4 class="heading">Seamless</h4>
                        <p>Arranging your trip should be almost as much fun as your safari</p>
                    </div>
                    <div class="information-item">
                        <h4 class="heading">Value</h4>
                        <p>Your trip should cost you no more than if you booked it direct</p>
                    </div>
                    <div class="information-item">
                        <h4 class="heading">Tailored</h4>
                        <p>Your trip will be perfectly tuned to meet your requirements</p>
                    </div>
                </div>
                <button-internal-presentational
                        classes="btn -main-cta -ripple"
                        :router_object="{path: hardcodedUrls.resources.gettingStarted}">
                        <span><span>Start Planning Your Safari</span> <i class="ion-ios-arrow-thin-right"></i></span>
                </button-internal-presentational>
            </div>
        </div>
        <div class="reviews-content">
            <div class="container">
                <div class="reviews-container">
                    <div class="text-content">
                        <h3 class="heading">What our guests say</h3>
                        <div class="reviews">
                            <div class="review">
                                <p class="review-content"><em>“ATR are experts in their field and I wouldn't trust anyone else.”</em></p>
                                <div class="name-container">
                                    <strong class="review-name">Tara Stewart</strong>
                                    <div class="stars">
                                        <strong>★</strong> <strong>★</strong> <strong>★</strong> <strong>★</strong> <strong>★</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="reviews">
                            <div class="review">
                                <p class="review-content"><em>“We've also used many different travel organizers and agents, but one truly stands like a giraffe, head and shoulders above all others.”</em></p>
                                <div class="name-container">
                                    <strong class="review-name">Linda Goddard</strong>
                                    <div class="stars">
                                        <strong>★</strong> <strong>★</strong> <strong>★</strong> <strong>★</strong> <strong>★</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="reviews">
                            <div class="review">
                                <p class="review-content"><em>“ATR are honest in their appraisal of individual lodges, you can trust their judgement”</em></p>
                                <div class="name-container">
                                    <strong class="review-name">Graeme Gales</strong>
                                    <div class="stars">
                                        <strong>★</strong> <strong>★</strong> <strong>★</strong> <strong>★</strong> <strong>★</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="total-reviews">
                            <div class="review-summary">
                                <strong>4.9</strong>
                                <div class="stars">
                                    <strong>★</strong> <strong>★</strong> <strong>★</strong> <strong>★</strong> <strong>★</strong>
                                </div>
                            </div>
                            <strong>70 Google Reviews</strong>
                        </div>

                        <button-internal-presentational
                                classes="btn -main-cta -ripple"
                                :router_object="{path: hardcodedUrls.resources.gettingStarted}">
                                <span><span>Start Planning Your Safari</span> <i class="ion-ios-arrow-thin-right"></i></span>
                        </button-internal-presentational>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import GlobalVars from '../../../global/GlobalVars';
    export default {
        computed: {
            hardcodedUrls() {
                return GlobalVars.HARCODED_URLS;
            }
        }   
    }
</script>