<template>
    <div>
        <header class="header-container -left" style="display: flex;" onclick>
            <!--<div class="header main-header" v-if="!showBackButton">-->
            <div class="header main-header" >
                <router-link :to="{name: 'home'}" class="logo" @click="maybeConfirm">
                    <img :src="globalVars.LOGO_URL" width="66" alt="ATR" class="logo -light"/>
                    <img :src="globalVars.DARK_LOGO_URL" width="66" alt="ATR" class="logo -dark"/>
                </router-link>
            </div>


            

<!--            <div v-if="navigation.subheading" class="subheading">{{ navigation.subheading }}</div>-->

            <!--<transition name="fade">-->
                <!--<button-back-presentational v-if="showBackButton"></button-back-presentational>-->
            <!--</transition>-->
        </header>

        <div class="header-container -right -over-map_">
            <explore-btn :backBtn="showBackButton"></explore-btn>
            <auto-complete-search />

            <!--<transition-group name="fade" mode="in-out">-->
                <!--<a rel="nofollow" href="tel:01306880770" class="link -phone visible-xs" key="phone1" v-if="userCountry === 'GB'">UK : 01306 880770</a>-->
                <!--<a rel="nofollow" href="tel:3137442871" class="link -phone visible-xs" key="phone2" v-if="userCountry === 'US'">USA : 313 744 2871</a>-->

                <!--<router-link :to="{ name: 'contact' }"  class="link -phone hidden-xs" key="phone3" v-if="userCountry === 'GB' && hasEverOpenedNav">UK : 01306 880770</router-link>-->
                <!--<router-link :to="{ name: 'contact' }"  class="link -phone hidden-xs" key="phone4" v-if="userCountry === 'US' && hasEverOpenedNav">USA : 313 744 2871</router-link>-->
            <!--</transition-group>-->

            <contact-btn></contact-btn>

            <call-menu></call-menu>
            <modal-nav></modal-nav>

            <!--<div>-->
                <!--<router-link :to="{path: '/new/safari-lodges/tanzania/ruaha/kichaka-ruaha'}">-->
                    <!--new lodge-->
                <!--</router-link>-->
                <!--<router-link :to="{path: '/new/safari-locations/south-africa/western-cape'}">-->
                    <!--new location-->
                <!--</router-link>-->
            <!--</div>-->
        </div>
    </div>
</template>

<script>
    import GlobalVars from "../../../global/GlobalVars";

    export default {
        name: "NavBar",
        data() {
            return {
                showBackButton: false,
                showNav: false
            }
        },
        created() {
            window.bus.$on('setShowBackButton', this.setShowBackButtonEvent);
            window.bus.$on('do-blanket-tasks', this.hideMobNav);
        },
        destroyed(){
            window.bus.$off('setShowBackButton', this.setShowBackButtonEvent);
            window.bus.$off('do-blanket-tasks', this.hideMobNav);
        },
        computed: {
            globalVars() {
                return GlobalVars;
            },
            userCountry() {
                if(this.$store.state.user.user && this.$store.state.user.user.geo_data){
                    return this.$store.state.user.user.geo_data.iso_code;
                }else{
                    return;
                }
            },
            hasEverOpenedNav() {
                return this.$store.state.user.user.hasOpenedNav;
            },
            navigation() {
                return this.$store.state.navigation;
            }
        },
        methods: {
            setShowBackButtonEvent(value){
                this.showBackButton = value;
            },
            toggleNav(){
              this.showNav = !this.showNav;

              if(this.showNav){
                  window.bus.$emit('open_nav');
              }else{
                  window.bus.$emit('close_nav');
              }
            },
            hideMobNav(){
              this.showNav = false;
              window.bus.$emit('close_nav');
            },
            maybeConfirm(event) {
                return $('html').hasClass('user-is-editing') &&
                       this.$store.state.editor.editor.isDirty &&
                       !confirm('Are you sure you want to leave? Any unsaved changes will be lost.')
                    ? event.preventDefault() : true;
            }
        }
    }
</script>
