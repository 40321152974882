<template>
    <div class="site-footer" v-show="shouldShow">
        <trip-builder-cta />

        <about-atr />

       <section class="logos">
           <div class="container">
               <h3 class="header">Recommended and endorsed by all the leading lodge owners in Africa</h3>

               <div class="logos-desktop">
                   <div class="row">
                   <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                       <div class="logo -wilderness">
                           <img v-lazy="'/assets/img/temp/logos/update/wilderness-logo.png'" alt="Wilderness Safari Logo">
                       </div>
                   </div>

                   <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                       <div class="logo -londolozi">
                           <img v-lazy="'/assets/img/temp/logos/update/londolozi.png'" alt="Londolozi Logo">
                       </div>
                   </div>

                   <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                       <div class="logo -asilia">
                           <img v-lazy="'/assets/img/temp/logos/update/asilia-logo.png'" alt="Asilia Safaris Logo">
                       </div>
                   </div>

                   <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                       <div class="logo -beyond">
                           <img v-lazy="'/assets/img/temp/logos/update/andbeyond-logo.png'" alt="Beyond Logo">
                       </div>
                   </div>

                   <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                       <div class="logo -singita">
                           <img v-lazy="'/assets/img/temp/logos/update/singita-logo.png'" alt="Singita Logo">
                       </div>
                   </div>

                   <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                       <div class="logo -kwando">
                           <img v-lazy="'/assets/img/temp/logos/update/kwando-logo.png'" alt="Kwando Safaris Logo">
                       </div>
                   </div>
               </div>

               <div class="row">
                   <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                       <div class="logo -legend">
                           <img v-lazy="'/assets/img/temp/logos/update/legend-logo.png'" alt="Legend Logo">
                       </div>
                   </div>

                   <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                       <div class="logo -elewana">
                           <img v-lazy="'/assets/img/temp/logos/update/elewana-collection-logo.png'" alt="Elwana Logo">
                       </div>
                   </div>

                   <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                       <div class="logo -natural-selection">
                           <img v-lazy="'/assets/img/temp/logos/update/natural-selection-logo.png'" alt="Natural Selection Logo">
                       </div>
                   </div>

                   <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                       <div class="logo -wilderness">
                           <img v-lazy="'/assets/img/temp/logos/update/nomad-logo.png'" alt="Nomad Tanzania Logo">
                       </div>
                   </div>

                   <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                       <div class="logo -norman-carr">
                           <img v-lazy="'/assets/img/temp/logos/update/norman-carr-logo.png'" alt="Norman Carr Safaris Logo">
                       </div>
                   </div>

                   <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                       <div class="logo -robin-pope">
                           <img v-lazy="'/assets/img/temp/logos/update/robin-pope-logo.png'" alt="Robin Pope Safaris Logo">
                       </div>
                   </div>
               </div>
               </div>

               <carousel :perPage="1" paginationColor="#D7D7D7" paginationActiveColor="#767676">
                   <slide>
                       <div class="row">
                           <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                               <div class="logo -wilderness">
                                   <img v-lazy="'/assets/img/temp/logos/update/wilderness-logo.png'" alt="Wilderness Safaris Logo">
                               </div>
                           </div>

                           <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                               <div class="logo -londolozi">
                                   <img v-lazy="'/assets/img/temp/logos/update/londolozi.png'" alt="Londolozi Logo">
                               </div>
                           </div>

                           <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                               <div class="logo -asilia">
                                   <img v-lazy="'/assets/img/temp/logos/update/asilia-logo.png'" alt="Asilia Safaris Logo">
                               </div>
                           </div>

                           <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                               <div class="logo -beyond">
                                   <img v-lazy="'/assets/img/temp/logos/update/andbeyond-logo.png'" alt="Beyond Logo">
                               </div>
                           </div>

                           <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                               <div class="logo -singita">
                                   <img v-lazy="'/assets/img/temp/logos/update/singita-logo.png'" alt="Singita Logo">
                               </div>
                           </div>

                           <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                               <div class="logo -kwando">
                                   <img v-lazy="'/assets/img/temp/logos/update/kwando-logo.png'" alt="Kwando Safaris Logo">
                               </div>
                           </div>
                       </div>
                   </slide>
                   <slide>
                       <div class="row">
                           <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                               <div class="logo -legend">
                                   <img v-lazy="'/assets/img/temp/logos/update/legend-logo.png'" alt="Legend Logo">
                               </div>
                           </div>

                           <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                               <div class="logo -elewana">
                                   <img v-lazy="'/assets/img/temp/logos/update/elewana-collection-logo.png'" alt="Elwana Collection Logo">
                               </div>
                           </div>

                           <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                               <div class="logo -natural-selection">
                                   <img v-lazy="'/assets/img/temp/logos/update/natural-selection-logo.png'" alt="Natural Selection Logo">
                               </div>
                           </div>

                           <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                               <div class="logo -wilderness">
                                   <img v-lazy="'/assets/img/temp/logos/update/nomad-logo.png'" alt="Nomad Tanzania Logo">
                               </div>
                           </div>

                           <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                               <div class="logo -norman-carr">
                                   <img v-lazy="'/assets/img/temp/logos/update/norman-carr-logo.png'" alt="Norman Carr Safaris Logo">
                               </div>
                           </div>

                           <div class="col-xs-6 col-sm-6 col-md-3 col-lg-2">
                               <div class="logo -robin-pope">
                                   <img v-lazy="'/assets/img/temp/logos/update/robin-pope-logo.png'" alt="Robin Pope Safaris Logo">
                               </div>
                           </div>
                       </div>
                   </slide>
               </carousel>
           </div>
       </section>

       <section class="locations-listing">
            <div class="container">
                <div class="heading-section">
                    <h3 class="header">Which of these fabulous places could be your next epic trip?</h3>
                </div>
                <div class="location-columns">
                    <ul>
                        <li v-for="location in locationList" class="locations-column">
                            <a :href="location.url">{{ location.name }}</a>
                            
                            <ul v-if="location.children">
                                <li v-for="child in location.children">
                                    <a :href="child.url">{{ child.name }}</a>
                                </li>
                            </ul>
                            
                        </li>
                    </ul>
                    
                </div>
            </div>
       </section>

        <footer class="home-footer container office-locations">
            <img src="/assets/img/logos/atr.svg" width="221" height="88" alt="Africa Travel Resource">
            <p style="text-align: center;">USA and Canada 888-487 5418 : UK 01306 880770 : Europe and ROW +44 1306 880770 : Australia +61 800 821 832</p>
            <p style="text-align: center;"><a href="mailto:salesoffice@atrsafari.com">salesoffice@atrsafari.com</a></p>
        </footer>

    </div>
</template>

<script>
    import GlobalVars from '../../../global/GlobalVars';
    import slide from '../../../libs/vue-carousel/src/Slide';
    import carousel from '../../../libs/vue-carousel/src/Carousel';
    export default {
        data: function(){
            return {
                locationList: GlobalVars.FULL_LOCATIONS_LIST,
                shouldShow: false,
            }
        },
        components: {
            carousel,
            slide
        },
        mounted(){
            setTimeout(() => {
                this.shouldShow = true;
            },2000)
            
        },
        computed: {
            hardcodedUrls() {
                return GlobalVars.HARCODED_URLS;
            }
        }   
    }
</script>