<template>
    <div>
        <ul class="multi-step-radios-list">
            <li>
                <input type="radio" id="met_before-new" name="have_we_met_before_step" value="New guest" v-model="selectedOption" @change="updateHaveWeMetBefore">
                <label for="met_before-new"><span style="color: #696969"><strong>New guest :</strong> I have not enquired with or travelled with ATR before</span></label>
            </li>
            <li>
                <input type="radio" id="met_before-talked" name="have_we_met_before_step" value="Enquired but not booked" v-model="selectedOption" @change="updateHaveWeMetBefore">
                <label for="met_before-talked"><span style="color: #696969"><strong>We've talked :</strong> I've enquired but not booked with ATR before</span></label>
            </li>
            <li>
                <input type="radio" id="met_before-returning" name="have_we_met_before_step" value="Returning guest" v-model="selectedOption" @change="updateHaveWeMetBefore">
                <label for="met_before-returning"><span style="color: #696969"><strong>Returning guest :</strong> I have travelled with ATR before</span></label>
            </li>
            <li>
                <input type="radio" id="met_before-referred" name="have_we_met_before_step" value="Referred guest" v-model="selectedOption" @change="updateHaveWeMetBefore">
                <label for="met_before-referred"><span style="color: #696969"><strong>Referred guest :</strong> Somebody I know has recommended ATR</span></label>

                <input type="text" class="recommended-guest-input" style="margin:20px 0 0 50px; width:calc(100% - 50px)" v-show="selectedOption == 'Referred guest'" id="met_before-referred-name" name="name_of_the_person_who_recommended_atr" placeholder="Please feel free to share the name of the person who recommended us." v-model="referred_by" @change="updateReferredBy">
            </li>
        </ul>

        <input type="text" :value="formContent.have_we_met_before" name="have_we_met_before" style="opacity:0; height:0;">
    </div>
    
</template>

<script>

    export default {
        props: ['formContent'],
        data(){
            return {
                selectedOption: 'New guest',
                referred_by: '',
            }
        },
        mounted(){
            this.selectedOption = this.formContent.have_we_met_before || 'New guest';
            this.referred_by = this.formContent.name_of_the_person_who_recommended_atr;

            if(typeof this.formContent.have_we_met_before === "undefined" || this.formContent.have_we_met_before === null){
                this.$emit('update-data', {key: 'have_we_met_before', value: this.selectedOption});
            }
        },
        methods: {
            updateHaveWeMetBefore(e){
                this.$emit('update-data', { key: 'have_we_met_before', value: e.target.value });
            },
            updateReferredBy(e){
                this.$emit('update-data', { key: 'name_of_the_person_who_recommended_atr', value: e.target.value });
            }
        }
    }

</script>