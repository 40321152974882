<template> 
    <div class="modal fade" :id="'buttonModal_' + _id" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" :id="'buttonModal_' + _id">&times;</button>
                    <h4 class="modal-title">Slide Buttons</h4>
                </div>
                <div class="modal-body">
                    <div :id="'currentButtons_' + _id">
                        <template v-for="(btn, index) in localButtons"> 
                            <div>
                                <div class="panel panel-info">
                                    <div class="panel-heading">{{ btn.text }}
                                        <button class="btn btn-info pull-right" data-toggle="collapse" :data-target="'#' + section_id + '_' + _id + '_modal_button_' + index">Edit
                                            Button
                                        </button>
                                    </div>
                                    <div class="panel-body">
                                        <div :id="section_id + '_' + _id +'_modal_button_' + index" class="collapse">
                                            <div class="row form-inline">
                                                <div class="form-group col-md-4">
                                                    <label class="control-label">Button Type</label>
                                                    <select class="form-control" name="button-action" v-model="localButtons[index].action">
                                                        <option value="">Select</option>
                                                        <option value="openArticle">Open Article</option>
                                                        <option value="openExternal">External Link</option>
                                                        <option value="showMore">View More Images</option>
                                                        <option value="showAll">View All Sections</option>
                                                        <option value="seasonality">Seasonality</option>
                                                        <option value="socialIcons">Social Icons</option>
                                                        <option value="videoPlayer">Play Video</option>
                                                    </select>
                                                </div>
                                                <div class="form-group  col-md-4" v-if="localButtons[index].action != 'videoPlayer'">
                                                    <label class="control-label">Button Style</label>
                                                    <select class="form-control" name="button-style" v-model="localButtons[index].style">
                                                        <option value="underline_arrow_right">Underlined with Arrow Right</option>
                                                        <option value="underline">Underlined</option>
                                                        <option value="plus_icon_left">Plus Icon to the Left</option>
                                                        <option value="plus_icon_right">Plus Icon to the Right</option>
                                                        <option value="large_round_arrow_right">Large Rounded with Arrow Right</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-md-4" v-if="localButtons[index].action != 'videoPlayer'">
                                                    <label class="control-label">Button Text</label>
                                                    <input class="form-control" type="text" name="button-text" v-model="localButtons[index].text"/>
                                                </div>
                                                <div class="col-md-12 mt-10" v-if="localButtons[index].action == 'openExternal' || localButtons[index].action == 'videoPlayer'">
                                                    <label class="control-label">External Link</label>
                                                    <input class="form-control full-width" type="text" placeholder="External Link" name="button-text" v-model="localButtons[index].link"/>
                                                </div>
                                                <div class="col-md-12 mt-10" v-if="localButtons[index].action == 'openArticle'">
                                                    <label class="control-label">Article</label>
                                                    <select class="form-control full-width" name="button-article" v-model="localButtons[index].article">
                                                        <option value="">New Article</option>
                                                        <template v-for="(item, index) in articles.data">
                                                            <option v-bind:value="item.article_id">{{ item.title }}</option>
                                                        </template>
                                                    </select>
                                                </div>
                                                <div class="col-md-12 mt-10" v-if="localButtons[index].action == 'showAll'">
                                                    <label class="control-label">Included Sections</label>
                                                    <select class="form-control full-width" name="button-article" v-model="localButtons[index].sections" multiple>
                                                        <template v-for="(item, index) in sections">
                                                            <option v-if="(type.indexOf('_slide') !== -1 && item.id !== section_id) || (type.indexOf('_section')!== -1 && item.id !== _id)" :key="item.id" :value="item.id">{{ item.name }}</option>
                                                        </template>
                                                    </select>
                                                </div>
                                                <div class="col-md-12">
                                                    <button class="btn btn-sm btn-danger pull-right" @click="removeMe(index)"><i class="ion ion-trash-b"></i> Delete</button>
                                                    <button class="btn btn-sm btn-success pull-right" @click="updateButton(index)"><i class="ion ion-ios-checkmark"></i> Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="panel panel-warning mt-5">
                        <div class="panel-heading">Add New Button
                            <button class="btn btn-warning pull-right" data-toggle="collapse" :data-target="'#' + section_id + '_' + _id + '_addNewButton' + _id">Add Button
                            </button>
                        </div>
                        <div class="panel-body">
                            <div :id="section_id + '_' + _id + '_addNewButton' + _id" class="row form-inline collapse">
                                <div class="form-group col-md-4">
                                    <label class="control-label">Button Type</label>
                                    <select class="form-control" name="button-action" v-model="btnType" @change="setBtnType">
                                        <option value="">Select</option>
                                        <option value="openArticle">Open Article</option>
                                        <option value="openExternal">External Link</option>
                                        <option value="showMore">View More Images</option>
                                        <option value="showAll">View All Sections</option>
                                        <option value="seasonality">Seasonality</option>
                                        <option value="socialIcons">Social Icons</option>
                                        <option value="videoPlayer">Play Video</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4" v-if="button.action != 'videoPlayer'">
                                    <label class="control-label">Button Style</label>
                                    <select class="form-control" name="button-style" v-model="button.style">
                                        <option value="underline_arrow_right">Underlined with Arrow Right</option>
                                        <option value="underline">Underlined</option>
                                        <option value="plus_icon_left">Plus Icon to the Left</option>
                                        <option value="plus_icon_right">Plus Icon to the Right</option>
                                        <option value="large_round_arrow_right">Large Rounded with Arrow Right</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4" v-if="button.action != 'videoPlayer'">
                                    <label class="control-label">Button Text</label>
                                    <input class="form-control" type="text" name="button-text" value="Read More" v-model="button.text"/>
                                </div>
                                <div class="form-group col-md-12 mt-10" v-if="button.action == 'openExternal' || button.action == 'videoPlayer'">
                                    <label class="control-label">External Link</label>
                                    <input class="form-control full-width" type="text" placeholder="External Link" name="button-text" v-model="button.link"/>
                                </div>
                                <div class="col-md-12 mt-10" v-if="button.action == 'openArticle'">
                                    <label class="control-label">Article</label>
                                    <select class="form-control full-width" name="button-article" v-model="button.article">
                                        <option value="">New Article</option>
                                        <template v-for="(item, index) in articles.data">
                                            <option v-bind:value="item.article_id">{{ item.title }}</option>
                                        </template>
                                    </select>
                                </div>
                                <div class="col-md-12 mt-10" v-if="button.action == 'showAll'">
                                    <label class="control-label">Included Sections</label>
                                    <select class="form-control full-width" name="button-article" v-model="button.sections" multiple>
                                        <template v-for="(item, index) in sections">
                                            <option v-if="(type.indexOf('_slide') !== -1 && item.id !== section_id) || (type.indexOf('_section')!== -1 && item.id !== _id)" :key="item.id" :value="item.id">{{ item.name }}</option>
                                        </template>
                                    </select>
                                </div>
                                <div class="form-group col-md-12">
                                    <button class="btn btn-sm btn-primary pull-right addButtonToPage" @click="addNewButton()"><i class="ion ion-plus"></i> Add</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import StateUtils from '../../global/StateUtils'

    export default {
        computed: {
            articles: function () {
                var obj = new StateUtils(this.type, this.$store).getArticlesStateObject();
                if (obj !== null && !window._.isUndefined(obj)) {
                    return obj;
                }
                return {
                    data: []
                };
            },
            sections: function () {
                var stateObject = new StateUtils(this.type, this.$store).getStateObject();
                if (stateObject !== null) {
                    return stateObject.allSections.data;
                }
            }
        },
        props: ['type', '_id', 'buttons', 'section_id'],
        data() {
            return {
                btnType: '',
                button: {
                    text: 'Button Text',
                    style: 'underline',
                    action: '',
                    link: '',
                    article: '',
                    sections: [],
                },
                buttonDefaults: {
                    text: 'Button Text',
                    style: 'underline',
                    action: '',
                    link: '',
                    article: '',
                    sections: [],
                },
                localButtons: [],
                sectionContent: [],

            };
        },
        watch: {
          buttons: function(){
              this.refreshLocalButtons();
          }
        },
        created() {
            this.refreshLocalButtons();
        },
        methods: {
            refreshLocalButtons(){
                this.localButtons = [];
                for(let t in this.buttons){
                    let btn = window._.defaultsDeep({}, this.buttons[t]);
                    this.localButtons.push(btn);
                }
            },
            setBtnType(val) {
                var val = val.target.value;
                var btnText = 'Read More';

                if (val == 'openArticle') {
                    btnText = 'Read More'
                } else if (val == 'openExternal') {
                    btnText = 'Open Link'
                } else if (val == 'showMore') {
                    btnText = 'View More'
                } else if (val == 'showAll') {
                    btnText = 'View All'
                } else if (val == 'seasonality') {
                    btnText = 'Seasonality'
                } else if(val == 'socialIcons') {
                    btnText = 'Auto generated'
                } else if(val == 'videoPlayer') {
                    btnText = 'Video'
                }

                this.button.action = this.btnType;
                this.button.text = btnText;
            },
            addNewButton() {
                let buttonData = {
                    action: this.button.action,
                    article: this.button.article,
                    link: this.button.link,
                    modal: '',
                    style: this.button.style,
                    text: this.button.text,
                    type: this.button.action,
                    sections: this.button.sections
                };

                this.updateButton(null, buttonData);

                this.button.text = this.buttonDefaults.text;
                this.button.style = this.buttonDefaults.style;
                this.button.action = this.buttonDefaults.action;
                this.button.link = this.buttonDefaults.link;
                this.button.article = this.buttonDefaults.article;
                this.button.sections = this.buttonDefaults.sections;
                this.btnType = '';
            },
            removeMe(index) {
                var btn = null;
                var stateObject = new StateUtils(this.type, this.$store).getStateObject();

                if (stateObject !== null) {
                    console.log('remove buttons with index '+ index);
                    this.localButtons.splice(index, 1);
                    if (this.type.indexOf('_section') !== -1) {
                        if (stateObject.allSections) {
                            for (let x in stateObject.allSections.data) {
                                if (stateObject.allSections.data[x].id === this._id) {
                                    btn = stateObject.allSections.data[x].buttons[index];
                                    stateObject.allSections.data[x].buttons.splice(index, 1);
                                    if (btn.modal !== '') {
                                        var actions = [];
                                        for (let a in stateObject.allSections.data[x].actions) {
                                            if (stateObject.allSections.data[x].actions[a].action !== btn.modal) {
                                                actions.push(stateObject.allSections.data[x].actions[a]);
                                            }
                                        }
                                        stateObject.allSections.data[x].actions = actions;
                                    }

                                    if (btn.action === 'showMore') {
                                        for (let t in stateObject.allSections.data[x].allSlides.data) {
                                            stateObject.allSections.data[x].allSlides.data[t].hidden = 0;
                                            stateObject.allSections.data[x].see_more = 0;
                                        }
                                    } else if (btn.action === 'showAll') {
                                        for (let t in stateObject.allSections.data) {
                                            for (let u in stateObject.allSections.data[x].see_all) {
                                                if (stateObject.allSections.data[x].see_all[u] === stateObject.allSections.data[t].id) {
                                                    stateObject.allSections.data[t].hidden = 0;
                                                }
                                            }
                                            stateObject.allSections.data[x].see_all = [];
                                        }
                                    }
                                }
                            }
                        }
                    } else if (this.type.indexOf('_slide') !== -1) {
                        if (stateObject.allSections) {
                            for (let x in stateObject.allSections.data) {
                                if (stateObject.allSections.data[x].id === this.section_id) {
                                    for (let y in stateObject.allSections.data[x].allSlides.data) {
                                        if (stateObject.allSections.data[x].allSlides.data[y].id === this._id) {
                                            btn = stateObject.allSections.data[x].allSlides.data[y].buttons[index];
                                            stateObject.allSections.data[x].allSlides.data[y].buttons.splice(index, 1);
                                            this.localButtons.splice(index, 1);
                                            if (btn.modal !== '') {
                                                var actions = [];
                                                for (let a in stateObject.allSections.data[x].allSlides.data[y].actions) {
                                                    if (stateObject.allSections.data[x].allSlides.data[y].actions[a].action !== btn.modal) {
                                                        actions.push(stateObject.allSections.data[x].allSlides.data[y].actions[a]);
                                                    }
                                                }
                                                stateObject.allSections.data[x].allSlides.data[y].actions = actions;
                                            }


                                            if (btn.action === 'showMore') {
                                                for (let t in stateObject.allSections.data[x].allSlides.data) {
                                                    stateObject.allSections.data[x].allSlides.data[t].hidden = 0
                                                    stateObject.allSections.data[x].see_more = 0;
                                                }
                                            } else if (btn.action == 'showAll') {
                                                for (let t in stateObject.allSections.data) {
                                                    for (let u in stateObject.allSections.data[x].see_all) {
                                                        if (stateObject.allSections.data[x].see_all[u] === stateObject.allSections.data[t].id) {
                                                            stateObject.allSections.data[t].hidden = 0;
                                                        }
                                                    }
                                                    stateObject.allSections.data[x].see_all = [];
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                    new StateUtils(this.type, this.$store).setStateObject(stateObject, btn);
                }
            },
            generateActionForButton(buttonData, buttonIndex) {
                let generatedAction = false;
                var articles = this.articles.data;
                if (buttonData.action === 'openArticle' || buttonData.action === 'seasonality') {
                    if (buttonData.action === 'openArticle') {
                        if (buttonData.article === '') {
                            let action = {
                                action: 'modal_' + buttonIndex + '_' + this._id,
                                article: {
                                    title: '',
                                    content: ''
                                },
                                media: 'provisional_' + this._id + '_' + buttonIndex,
                                type: 'article'
                            };
                            buttonData.article = action.media;
                            buttonData.modal = action.action;
                            generatedAction = action;
                        } else {

                            var article = {
                                title: '',
                                content: ''
                            };
                            for (let t in articles) {
                                if (articles[t].article_id === buttonData.article) {
                                    article.title = articles[t].title;
                                    article.content = articles[t].content;
                                }
                            }
                            let action = {
                                action: 'modal_' + buttonIndex + '_' + this._id,
                                article: {
                                    title: article.title,
                                    content: article.content
                                },
                                media: buttonData.article,
                                type: 'article'
                            };
                            buttonData.article = action.media;
                            buttonData.modal = action.action;
                            generatedAction = action;
                        }
                    } else {
                        let action = {
                            action: 'modal_' + buttonIndex + '_' + this._id,
                            article: {
                                title: '',
                                content: ''
                            },
                            media: buttonData.article,
                            type: 'seasonality'
                        };
                        buttonData.article = action.media;
                        buttonData.modal = action.action;
                        generatedAction = action;
                    }
                }
                return {
                    act: generatedAction,
                    btn: buttonData
                }
            },
            updateButton(index, btn) {
                if(window._.isUndefined(btn)) {
                    var buttonData = {
                        action: this.localButtons[index].action,
                        article: this.localButtons[index].article,
                        link: this.localButtons[index].link,
                        modal: this.localButtons[index].modal,
                        style: this.localButtons[index].style,
                        text: this.localButtons[index].text,
                        type: this.localButtons[index].action,
                        sections: !window._.isUndefined(this.localButtons[index].sections) ? this.localButtons[index].sections : []
                    };
                } else {
                    var buttonData = window._.defaultsDeep({}, btn);
                }
                var stateObject = new StateUtils(this.type, this.$store).getStateObject();
                if (stateObject !== null) {
                    var showMoreIndex = 0;
                    if (this.type.indexOf('_section') !== -1) {
                        if (stateObject.allSections) {
                            for (let x in stateObject.allSections.data) {
                                if (stateObject.allSections.data[x].id === this._id) {
                                    if (stateObject.allSections.data[x].buttons === undefined) {
                                        stateObject.allSections.data[x].buttons = [];
                                    }
                                    if(index === null){
                                        index = stateObject.allSections.data[x].buttons.length;
                                    }
                                    let actionAndButton = this.generateActionForButton(buttonData, index);
                                    if (actionAndButton.act !== false) {
                                        if (stateObject.allSections.data[x].actions === undefined) {
                                            stateObject.allSections.data[x].actions = [];
                                        }
                                        stateObject.allSections.data[x].actions.push(actionAndButton.act);
                                        if(stateObject.articleLinks){
                                            let obj = window._.find(stateObject.articleLinks.data, function(item){
                                                return item.article_id === actionAndButton.act.media;
                                            })
                                            if(window._.isUndefined(obj)){
                                                stateObject.articleLinks.data.push({
                                                    'action': actionAndButton.act.action,
                                                    'article_id': actionAndButton.act.media,
                                                    'content': actionAndButton.act.article.content,
                                                    'title': actionAndButton.act.article.title,
                                                });
                                            }
                                        }
                                    }
                                    stateObject.allSections.data[x].buttons[index] = actionAndButton.btn;
                                    this.localButtons[index] = actionAndButton.btn;
                                    if (actionAndButton.btn.action === 'showMore') {
                                        stateObject.allSections.data[x].see_more = 1;
                                        if (!window._.isUndefined(stateObject.allSections.data[x].allSlides.data)) {
                                            for (let y in stateObject.allSections.data[x].allSlides.data) {
                                                stateObject.allSections.data[x].allSlides.data[y].hidden = 1;
                                            }
                                        }
                                    } else if (actionAndButton.btn.action === 'showAll') {
                                        stateObject.allSections.data[x].see_all = actionAndButton.btn.sections;
                                        for (let t in stateObject.allSections.data) {
                                            for (let u in stateObject.allSections.data[x].see_all) {
                                                if (stateObject.allSections.data[x].see_all[u] === stateObject.allSections.data[t].id) {
                                                    stateObject.allSections.data[t].hidden = 1;
                                                }
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    } else if (this.type.indexOf('_slide') !== -1) {
                        if (stateObject.allSections) {
                            for (let x in stateObject.allSections.data) {
                                if (stateObject.allSections.data[x].id === this.section_id) {
                                    for (let y in stateObject.allSections.data[x].allSlides.data) {
                                        if (stateObject.allSections.data[x].allSlides.data[y].id === this._id) {
                                            if (stateObject.allSections.data[x].allSlides.data[y].buttons === undefined) {
                                                stateObject.allSections.data[x].allSlides.data[y].buttons = [];
                                            }
                                            if(index === null){
                                                index = stateObject.allSections.data[x].allSlides.data[y].buttons.length;
                                            }
                                            let actionAndButton = this.generateActionForButton(buttonData, index);
                                            if (actionAndButton.act !== false) {
                                                stateObject.allSections.data[x].allSlides.data[y].actions.push(actionAndButton.act);
                                                if(stateObject.articleLinks){
                                                    let obj = window._.find(stateObject.articleLinks.data, function(item){
                                                        return item.article_id === actionAndButton.act.media;
                                                    })
                                                    if(window._.isUndefined(obj)){
                                                        stateObject.articleLinks.data.push({
                                                            'action': actionAndButton.act.action,
                                                            'article_id': actionAndButton.act.media,
                                                            'content': actionAndButton.act.article.content,
                                                            'title': actionAndButton.act.article.title,
                                                        });
                                                    }
                                                }
                                            }
                                            stateObject.allSections.data[x].allSlides.data[y].buttons[index] = actionAndButton.btn;
                                            this.localButtons[index] = actionAndButton.btn;
                                            if (actionAndButton.btn.action === 'showMore') {
                                                showMoreIndex = y;
                                                stateObject.allSections.data[x].see_more = parseInt(y) + 2;
                                            }
                                        } else {
                                            if (buttonData.action === 'showMore') {
                                                if (y > showMoreIndex) {
                                                    stateObject.allSections.data[x].allSlides.data[y].hidden = 1;
                                                } else {
                                                    stateObject.allSections.data[x].allSlides.data[y].hidden = 0;
                                                }
                                            }
                                        }
                                    }
                                    if (buttonData.action === 'showAll') {
                                        stateObject.allSections.data[x].see_all = buttonData.sections;
                                        for (let t in stateObject.allSections.data) {
                                            for (let u in stateObject.allSections.data[x].see_all) {
                                                if (stateObject.allSections.data[x].see_all[u] === stateObject.allSections.data[t].id) {
                                                    stateObject.allSections.data[t].hidden = 1;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    new StateUtils(this.type, this.$store).setStateObject(stateObject, buttonData);
                }
            }
        }
    }
</script>