<script>
import SectionOuts from '../presentational/SectionOuts.vue';

export default {
    props: {
        section: {
            type: Object,
            default: function () {
                return {
                    content: [],
                    tag: '',
                    title: '',
                    _id: 1
                }
            }
        },
        edit: {
            default: false
        },
        type: {
            default: ''
        }
    },
    render(createElement) {
        var props = {...this.section};
        props._id = this.section.id;
        props.edit = this.edit;
        props.type = this.type;

        return createElement(SectionOuts, { props: props });
    }
}
</script>