<script>
    import ButtonInternalPresentational from '../presentational/ButtonInternal.vue';
    import ButtonExternalPresentational from '../presentational/ButtonExternal.vue';
    import ButtonActionPresentational from '../presentational/ButtonAction.vue';
    import ButtonSocialPresentational from '../presentational/ButtonSocial';
    import ButtonVideoPlayer from '../presentational/ButtonVideoPlayer';

    export default {
        props: {
            button: {
                default: () => {
                    return {
                        style: ''
                    }
                }
            },
            type: {},
            section_id: {},
            slide_id: {}
        },
        render(createElement) {
            var button = null;
            var props = {...this.button};

            if (this.button == null) {
                return null;
            }

            props.icon = this.icon;
            props.type = this.type;
            props.is_icon_left = this.button.style == 'plus_icon_left';
            props.classes = this.classes;
            props.data_sections = this.button.sections;
            props.data_action = this.button.action;
            props.data_article = this.button.article;
            props.data_sections = this.button.sections;
            props.data_style = this.button.style;
            props.section_id = this.section_id;
            props.slide_id = this.slide_id;
            props.text = this.button.text;
            props.isClicked = this.button.isClicked;
            props.url = props.link;

            if (this.button.action == 'videoPlayer') {
                button = ButtonVideoPlayer;
            } else if (this.button.action == 'internal') {
                button = ButtonInternalPresentational;
            } else if (this.isAction) {
                button = ButtonActionPresentational;
            } else if (this.button.action === 'socialIcons') {
                button = ButtonSocialPresentational;
            } else {
                button = ButtonExternalPresentational;
            }

            return createElement(button, {props: props}, this.$slots.default);
        },
        computed: {
            classes() {
                if (this.button == null) {
                    return '';
                }

                var _classes = _.isEmpty(this.button.classes) ? [] : this.button.classes;

                switch (this.button.style) {
                    case 'underline_arrow_right' :
                        _classes.push('-with-arrow');
                        break;
                    case 'large_round_arrow_right' :
                        _classes.push('-with-arrow');
                        break;
                }
                if (this.button.isClicked === true) {
                    _classes.push('-disabled');
                }

                return _classes;
            },
            icon() {
                if (this.button == null) {
                    return '';
                }

                var _classes = false;

                switch (this.button.style) {
                    case 'underline_arrow_right' :
                        return 'ion-ios-arrow-thin-right';
                        break;
                    case 'large_round_arrow_right' :
                        return 'ion-ios-arrow-thin-right';
                        break;
                    case 'plus_icon_left' :
                        return 'ion-android-add-circle';
                        break;
                    case 'plus_icon_right' :
                        return 'ion-android-add-circle';
                        break;
                }

                return _classes;
            },
            isAction() {
                var validActions = ['openArticle', 'seasonality', 'showAll', 'showMore'];
                return validActions.indexOf(this.button.action) !== -1;
            }
        }
    }
</script>