<script>
    export default {
        name: "Shortcodes",
        props: {
            content: {
                required: true
            }
        },
        render(createElement, context) {
            let str = this.content;

            _.forEach(this.filterSliderShortcodes(str), shortcode => {
                str = str.split(shortcode['before']).join(shortcode['after']);
            });

            return createElement('div', [
                createElement(Vue.compile('<div><p>' + str + '</p></div>'))
            ]);
        },
        methods: {
            filterSliderShortcodes(text) {
                let matches = text.match(/\[image-slider(\s.*?)?\](?:([^\[]+)?\])?/g);
                let results = [];

                _.forEach(matches, shortcode => {
                    let attrMatches = [...shortcode.match(/(type|geoid|start_offset)="([^"]*)"/g)];
                    let attrs = attrMatches.join(' ');
                    attrs = attrs.replace('geoid=', ':id=');
                    attrs = attrs.replace('start_offset=', ':start-offset=');
                    if(attrs.indexOf(':start-offset=')===-1){
                        attrs+=' :start-offset=0';
                    }
                    results.push({
                        before: shortcode,
                        after: '<public-search-image-slider ' + attrs + ' />'
                    });
                });

                matches = _.forEach(text.match(/\[article([^\]]*)\]/gm), match => {
                    results.push({
                        before: match,
                        after: ''
                    })
                });

                results.push({
                    before: '[/article]',
                    after: ''
                });

                return results;
            }
        }
    }
</script>
