<template>
    <div>
        <div class="messages-container">
            <div class="errors-container" v-if="error">
                <p>Please select a budget.</p>
            </div>
        </div>

        <div class="budgets-container">
            <div class="currency-selector">
                <button type="button" class="currency-toggle" data-toggle="dropdown" @click="currenciesMenuOpen = !currenciesMenuOpen">
                    <div style="display:flex; align-items: center;">
                        <div class="currency-icon">
                            <img :src="'/assets/images/'+formContent.budget_currency_on_form_input+'.png'" alt="currency" class="currency-flag">
                        </div>
                        
                        <span>{{ formContent.budget_currency_on_form_input }}</span>
                    </div>
                    
                    
                    <div class="menu-arrow" :class="currenciesMenuOpen ? '-open' : ''">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 512 512">
                            <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"/>
                        </svg>
                    </div>
                </button>
                
                <div class="currency-list" v-show="currenciesMenuOpen">
                    <div class="currency-item" v-for="(currency, index) in currencies" :key="index" @click="setCurrency(currency.value); currenciesMenuOpen = false;">
                        <div class="currency-icon">
                            <img :src="'/assets/images/'+currency.label+'.png'" alt="currency" class="currency-flag">
                        </div>
                        <span>{{ currency.value }}</span>
                    </div>
                </div>
            </div>
            <ul class="buttons-list wrapper-display">
                <li class="budget-option" v-for="(budget, index) in budgets" :key="index">
                    <input type="radio" :id="'budget-'+index" name="select_approximate_budget_in_usd_per_adult" :value="budget.value" @change="setBudget(budget.value)" v-model="selectedBudget">
                    <label :for="'budget-'+index">
                        {{ budget.value }}
                    </label>
                    <div class="label-display">{{ parseInt(budget.value).toLocaleString('en-US') }}<span v-if="index === budgets.length - 1">+</span></div>
                </li>
            </ul>
        </div>
        <slot name="additionalText" />
    </div>
    
</template>

<script>

    export default {
        props: ['formContent', 'checkValidation', 'stepIndex', 'step'],
        data() {
            return {
                selectedBudget: null,
                selectedCurrency: 'USD',
                budgets: null,
                currencies: null,
                currenciesMenuOpen: false,
                error:false,
            }
        },
        mounted() {
            this.getBudgets();
            this.selectedCurrency = this.formContent.budget_currency_on_form_input || 'USD';
            this.selectedBudget = this.formContent.select_approximate_budget_in_usd_per_adult;

            this.clickAway();

            if(typeof this.formContent.budget_currency_on_form_input === "undefined" || this.formContent.budget_currency_on_form_input === null){
                this.$emit('update-data', {key: 'budget_currency_on_form_input', value: this.selectedCurrency});
            }

            if(this.stepIndex === this.step.index && typeof this.formContent.select_approximate_budget_in_usd_per_adult !== "undefined") {
                this.validateStep();
            }


        },
        watch: {
            checkValidation: function(e){
                if(e) {
                    this.validateStep();
                }
                
            },
        },

        methods: {
            getBudgets() {
                let budgets = window.localStorage.getItem('hs_budgets');
                let currencies = window.localStorage.getItem('hs_currencies');

                if(typeof budgets === 'undefined' || budgets === null || budgets === '') {
                    
                    let budgetsPromise = axios.get(`${process.env.VUE_APP_HUBSPOT_API_URL}property/select_approximate_budget_in_usd_per_adult`);
                    let currenciesPromise = axios.get(`${process.env.VUE_APP_HUBSPOT_API_URL}property/budget_currency_on_form_input`);

                    budgetsPromise.then((response) => {
                        const data = JSON.stringify(response.data.options);
                        budgets = data;
                        window.localStorage.setItem('hs_budgets', data)
                    }).catch((error) => {
                        console.log(error);
                    });

                    currenciesPromise.then((response) => {
                        const data = JSON.stringify(response.data.options);
                        currencies = data;
                        window.localStorage.setItem('hs_currencies', data)
                    }).catch((error) => {
                        console.log(error);
                    });

                }

                this.budgets = JSON.parse(budgets);
                this.currencies = JSON.parse(currencies);
            },
            setCurrency(e){
                if(typeof e === 'undefined' || e.length === 0 || e === null){
                    this.$emit('update-data', {key: 'budget_currency_on_form_input', value: null});
                    return;
                }

                this.$emit('update-data', {key: 'budget_currency_on_form_input', value: e});
            },
            setBudget(e){
                console.log(e)
                if(typeof e === 'undefined' || e.length === 0 || e === null){
                    this.$emit('update-data', {key: 'select_approximate_budget_in_usd_per_adult', value: null});
                    return;
                }

                this.$emit('update-data', {key: 'select_approximate_budget_in_usd_per_adult', value: e});
                this.validateStep();

            },
            clickAway() {
                document.addEventListener('click', e => {
                    const currentTarget = e.target;
                    const targetParent = currentTarget.closest('.currency-selector');
                    if(typeof targetParent === 'undefined' || targetParent === null) {
                        this.currenciesMenuOpen = false;
                    }
                }, false);
                
            },
            validateStep(){
                if(typeof this.formContent.select_approximate_budget_in_usd_per_adult === 'undefined' || this.formContent.select_approximate_budget_in_usd_per_adult === null || this.formContent.select_approximate_budget_in_usd_per_adult === ''){
                    this.error = true;

                    this.$emit('update-validation', {step: this.step.index, value: false});
                } else {
                    this.error = false;

                    this.$emit('update-validation', {step: this.step.index, value: true});
                    
                }
            }

        }
    }

</script>