<template>
    <div class="search-image-slider" :class="{'init': initialised, 'initialising': initialising}">
        <div class="initialising" @mouseover="mouseOver">
            <img src="/assets/img/loading.gif" class="loading-img -gif" alt="Loading"/>
            <img src="/assets/img/loading.svg" class="loading-img -svg" alt="Loading"/>
        </div>

        <div class="title" v-if="title">
            <div class="sub-text" v-if="subTitle">{{ subTitle }}</div>
            <h4 class="heading">{{ title }}</h4>
        </div>

        <div class="image-container">
            <figure class="vignette vignette2" :class="{in: imageToShow === -1}">
                <img class="img-responsive img -slide" :src="coverImage" :alt="caption"/>
                <figcaption class="caption" v-html="caption" v-if="showCaptions"></figcaption>
            </figure>

            <figure class="vignette vignette2" v-for="(image, index) in images" :class="{in: imageToShow === index}">
                <img class="img-responsive img -slide" :src="image.image_loaded" :alt="image.caption"/>
                <figcaption class="caption" v-html="image.caption" v-if="showCaptions"></figcaption>
            </figure>
        </div>

        <transition name="fade-fast">
            <button-container v-if="firstVideo != null" :button="firstVideo" />
        </transition>

        <a v-on:click="nextSlide"
           class="arrow -right -yellow"
           :class="{in: (currentImage < (images.length - 1)) || !initialised }">

            <div class="arrow-container">
                <i class="ion-ios-arrow-right"
                   @mouseover="mouseOver"
                   @mouseleave="mouseLeave"></i>
            </div>

        </a>

        <a v-on:click="prevSlide"
           class="arrow -left"
           :class="{in: currentImage > 0 && initialised }"
        >
            <div class="arrow-container">

                <i class="ion-ios-arrow-left"
                   @mouseover="mouseOver"
                   @mouseleave="mouseLeave"></i>
            </div>
        </a>

        <div class="slider-progress">
            <div class="inner" :style="{width: progress + '%'}"></div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            coverImage: {
                type: String,
                default: ''
            },
            caption: String,
            id: Number,
            type: {
                type: String,
                default: 'lodge'
            },
            startOffset: {
                type: Number,
                default: 1
            },
            showCaptions: {
                default: true
            },
            button: {
                default: null
            },
            subTitle: String,
            title: String
        },
        data() {
            return {
                initialising: false,
                initialised: false,
                images: [],
                currentImage: -1,
                imagesLoadedUpTo: 0,
                imageToShow: -1,
                progress: 0,
                firstVideo: null
            };
        },
        mounted() {
            if (this.coverImage == '') {
                this.init();
            } else {
                if(this.button != null){
                    this.firstVideo = this.button;
                }
            }
        },
        computed: {
            button_() {
                if (!_.isUndefined(this.images)) {
                    return _.isUndefined(this.images[this.currentImage]) || this.images[this.currentImage]['buttons'] == null ?
                        null :
                        this.images[this.currentImage]['buttons'][Object.keys(this.images[this.currentImage]['buttons'])[0]];
                } else {
                    return null;
                }
            }
        },
        methods: {
            init(doNextSlide) {
                this.initialising = true;

                this.$store.dispatch(window.store.FETCH_AND_RETURN_SLIDESHOW, {
                    type: this.type,
                    id: this.id,
                    offset: this.startOffset
                }).then(response => {
                    if (response.data.data) {
                        let images = [];

                        if (this.coverImage != '') {
                            images.push({
                                caption: this.caption,
                                image: this.coverImage,
                                image_loaded: this.coverImage,
                                loaded: true,
                                buttons: this.button
                            });
                        }

                        for (let i = 0; i < response.data.data.length; i++) {
                            images.push({
                                caption: response.data.data[i].caption,
                                image: response.data.data[i].sizes.low,
                                image_loaded: null,
                                loaded: false,
                                buttons: response.data.data[i].buttons.data
                            });

                            if (this.firstVideo == null) {
                                _.each(response.data.data[i].buttons.data, button => {
                                    if (button != null && button.action == 'videoPlayer') {
                                        this.firstVideo = button;
                                        return false;
                                    }

                                    return true;
                                });
                            }
                        }

                        this.images = images;
                        this.initialised = true;
                        this.initialising = false;

                        if (doNextSlide) {
                            this.nextSlide();
                        } else if (this.coverImage == '') {
                            this.goToSlide(0);
                        }
                    } else {
                        console.warn('Unable to find data for slideshow images');
                    }
                });
            },
            loadImages() {
                let batchSize = 7;
                let imagesBeforeToLoad = 4;
                let vm = this;
                let timeoutInterval = 50;
                let nextTimeout = 0;

                // console.log('Image ' + this.imagesLoadedUpTo + ' is not loaded, current image ' + this.currentImage);

                if (this.images[this.currentImage].loaded === false) {
                    this.initialising = true;
                }

                if (this.currentImage > (this.imagesLoadedUpTo - imagesBeforeToLoad)) {
                    // console.log('doing load because ' + this.currentImage + ' > ' + (this.imagesLoadedUpTo - imagesBeforeToLoad));

                    //Download batchSize every time we get numImagesBeforeLoad images before the end of the loaded ones
                    for (let i = this.imagesLoadedUpTo; i < (this.imagesLoadedUpTo + batchSize); i++) {

                        if (i < vm.images.length) {
                            setTimeout(() => {
                                var img = new Image();

                                img.onload = function () {
                                    vm.images[i].loaded = true;
                                    vm.images[i].image_loaded = vm.images[i].image;

                                    if (i === vm.currentImage) {
                                        vm.initialising = false;
                                        vm.imageToShow = vm.currentImage;
                                    }
                                };

                                img.src = vm.images[i].image;
                                // console.log('loading image ' + img.src);
                            }, nextTimeout);

                            nextTimeout = timeoutInterval * i;
                        } else {
                            //console.warn('Reached the end');
                        }
                    }

                    this.imagesLoadedUpTo += batchSize;
                }
            },
            goToSlide(slide) {
                if (!_.isUndefined(this.images[slide])) {
                    this.currentImage = slide;

                    if (this.images[this.currentImage].loaded) {
                        this.imageToShow = this.currentImage;
                    }

                    this.loadImages();
                }

                this.updateProgress();
            },
            nextSlide() {
                if (!this.initialised) {
                    this.init(true);
                } else {
                    if (this.images && this.currentImage < (this.images.length + 1)) {

                        if (this.currentImage === -1) {
                            this.currentImage = 1;
                        } else {
                            this.currentImage++;
                        }

                        if (this.images[this.currentImage]) {
                            if (this.images[this.currentImage].loaded) {
                                this.imageToShow = this.currentImage;
                            }

                            this.loadImages();
                        }

                        this.updateProgress();
                    }
                }
            },
            prevSlide() {
                if (this.currentImage > 0) {
                    this.currentImage--;

                    if (this.images[this.currentImage].loaded) {
                        this.imageToShow = this.currentImage;
                    }

                    this.updateProgress();
                }
            },
            updateProgress() {
                this.progress = (100 / (this.images.length + 1) * this.currentImage);
            },
            mouseOver() {
                this.$emit('arrowhover', true);
            },
            mouseLeave() {
                this.$emit('arrowhover', false);
            }
        }
    }
</script>
