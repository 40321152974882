(function($) {
    var oldSubtitleText;

    //Will load more slides below the current slide. The new slides should be contained in the passed id,
    //which could be replaced by an ajax callback instead
    window.$document.on('click', '[data-pnp-load-page] .caption', function(){
        //Load in more slides dynamically
        var $thisSection = $(this).closest('[data-pnp-load-page]');
        var selector = $thisSection.data('pnp-load-page');

        if($(selector).length) {
            $('#content').find('.section.active').after($(selector).html());
            //$(selector).remove();

            $('#content .hidden-section').each(function () {
                $(this).removeClass('hidden-section').addClass('section');
                $(this).attr('data-pnp-parent-section', $thisSection.attr('id'));
            });

            //Remembering the active section
            var activeSectionIndex = $('.fp-section.active').index();

            $.fn.fullpage.destroy('all');

            //setting the active section as before
            $('.section').eq(activeSectionIndex).addClass('active');

            oldSubtitleText = $('.header-container .subtitle').html();
            $('.header-container .subtitle').html($thisSection.find('.heading').text());

            $.initFullPage();

            $.fn.fullpage.moveSectionDown();

            return false;
        }
    });

    $.initButtonClickEffect = function(){
        window.$document.on('click', '.-ripple', function(e){
            var rippler = $(this);

            // create .ink element if it doesn't exist
            if(rippler.find(".ink").length == 0) {
                rippler.append("<span class='ink'></span>");
            }

            var ink = rippler.find(".ink");

            // prevent quick double clicks
            ink.removeClass("animate");

            // set .ink diametr
            if(!ink.height() && !ink.width())
            {
                var d = Math.max(rippler.outerWidth(), rippler.outerHeight());
                ink.css({height: d, width: d});
            }

            // get click coordinates
            var x = e.pageX - rippler.offset().left - ink.width()/2;
            var y = e.pageY - rippler.offset().top - ink.height()/2;

            // set .ink position and add class .animate
            ink.css({
                top: y+'px',
                left:x+'px'
            }).addClass("animate");
        });
    };

    //This patches the annoying situation on android chrome where the address bar hiding/showing
    //causes 100vh to be unreliable
    $.initFullHeightMobShim = function (){
        if(pnpHelper.bootstrap.isIpadOrSmaller() && pnpHelper.isTouch()) {
            let $heightElem = $('#first-load-veil');
            let $fullVh = $('#full-vh');
            let lastHeight = null;

            function resizeFullHeightElems(){
                $('.vh-fix').css('height', $heightElem.outerHeight() + 'px');

                if(pnpHelper.bootstrap.isXs()) {
                    $('.vh-fix-xs').css('height', $heightElem.outerHeight() + 'px');
                }

                if(lastHeight && lastHeight !== $heightElem.outerHeight()){
                    window.bus.$emit('mobileBrowserResized');
                }

                lastHeight = $heightElem.outerHeight();

                setTimeout(resizeFullHeightElems, 1000);
            }

           // setTimeout(function(){
                /**
                 * Check once on preprod
                 */
                // if(pnpHelper.bootstrap.isIpadOrSmaller()) {
                //     $('.vh-fix-once').css('height', $heightElem.outerHeight() + 'px').removeClass('.vh-fix-once');
                // }
           // },500);

            resizeFullHeightElems();
        }
    };

    $.initEnvironmentDetection = function(){
      if(window.environment.isDev){
          $('html').addClass('env-development');
      }else if(window.environment.isPreProd){
          $('html').addClass('env-preproduction');
      }else if(window.environment.isProd){
          $('html').addClass('env-production');
      }
    };

    //This function initiliases all the JS. It is called whenever a modal is
    //open to init stuff inside the modal.
    $.initGlobalLibs = function() {
        $.initButtonClickEffect();
        $.initFullHeightMobShim();
        $.initEnvironmentDetection();
    };

    //Initialise global stuff when the page is ready
    $.initGlobalLibs();

}(jQuery));


