export default {
    methods: {
        navWhiteOn(){
            $('.header-container').addClass('-scroll-100');
        },
        navWhiteOff(){
            $('.header-container').removeClass('-scroll-100');
        },
        checkNavPosition(){
            let vm = this;

            let throttleCheckNav =  _.throttle(function(){
                if ($(window).scrollTop() > $(window).outerHeight()/3) {
                    vm.navWhiteOn();
                } else {
                    vm.navWhiteOff();
                }
            }, 250);

            throttleCheckNav();
        },
    },
    mounted() {
        this.checkNavPosition();
    },
    created(){
        window.addEventListener('scroll', this.checkNavPosition);
    },
    destroyed() {
        window.removeEventListener('scroll', this.checkNavPosition);
        window.bus.$once('do-blanket-tasks', this.navWhiteOff);
    }
};