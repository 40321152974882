<template>
    <div>
        <slot name="intro"/>

        <textarea name="tell_us_more_about_your_planned_trip" rows="10" placeholder="Type here" v-model="tell_us_more_about_your_planned_trip" @change="setValue"></textarea>
        <slot name="content" />

        <slot name="additionalText" />
    </div>
</template>

<script>

    export default {
        props: ['formContent'],
        data() {
            return {
                tell_us_more_about_your_planned_trip: '',
            }
        },
        mounted() {
            this.tell_us_more_about_your_planned_trip = this.formContent.tell_us_more_about_your_planned_trip || '';
        },
        methods: {
            setValue(e) {
                this.$emit('update-data', { key: 'tell_us_more_about_your_planned_trip', value: e.target.value });
                this.$emit('update-data', { key: 'tell_us_more_about_your_trip___character_count', value: e.target.value.length });
            },
        }
    }

</script>