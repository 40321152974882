<template>
    <div class="pnp-popover srch-panel -compact -location vh-fix-xs"
         :id="'srch-location-panel' + this.sentenceId"
         v-observe-visibility="visibilityChanged">

        <mob-popup-header text="SELECT LOCATION"></mob-popup-header>

        <header class="panel-search">
            <input type="text"
                   ref="input"
                   v-if="!toggleWishlist"
                   v-model="locationAutocomplete"
                   v-on:keyup="doLocationAutocomplete()"
                   v-bind:class="{ dirty: locationAutocomplete }"
                   class="form-control"
                   placeholder="Search Locations">

            <input type="text"
                   v-else
                   ref="input"
                   v-model="locationAutocomplete"
                   @keyup="doWishlistAutocomplete()"
                   :class="{ dirty: locationAutocomplete }"
                   class="form-control"
                   placeholder="Search Locations">
        </header>

        <div class="panel-results" :class="{'-max-reached': this.locations.length === maxLocations}">
            <template v-if="options.locations
                            && !autocompleteLocationResults
                            && toggleWishlist === false">

                <!--SELECTED LOCATIONS-->
                <template v-if="enableMultiSelection">
                    <div class="form-group checkbox-container" :class="{'active': locationIsSelected(anyLocation)}">
                        <input type="checkbox" :checked="locationIsSelected(anyLocation)"/>

                        <label class="filter-option" @click="toggleLocations(anyLocation)">
                            {{anyLocation.name}}
                        </label>
                    </div>

                    <div class="form-group checkbox-container" :class="{'active': locationIsSelected(location)}"
                         v-for="location in initialLocations"
                    v-if="location.id != 0">

                        <input type="checkbox" :checked="locationIsSelected(location)"/>

                        <label class="filter-option" @click="toggleLocations(location)">
                            {{location.name}}
                        </label>
                    </div>

                </template>
                <template v-else-if="params.location">
                    <a class="filter-option active"
                       v-on:click="setLocation(params.location)">
                        {{params.location.name }}
                    </a>
                </template>

                <!--MAIN LOCATION LISTING-->
                <template v-if="enableMultiSelection">
                    <div class="form-group checkbox-container" :class="{'active': locationIsSelected(location)}"
                         v-for="location in options.locations"
                         v-if="!locationInInitialLocations(location) && location.id != 0">

                        <input type="checkbox" :checked="locationIsSelected(location)"/>

                        <label class="filter-option" @click="toggleLocations(location)">
                            {{location.name}}
                        </label>
                    </div>

                </template>
                <template v-else>
                    <template v-if="params.location">
                        <a class="filter-option"
                           v-for="location in options.locations"
                           v-if="location.id != params.location.id"
                           v-on:click="setLocation(location)">

                            {{location.name }}

                        </a>
                    </template>
                </template>
            </template>

            <!--LOCATIONS SEARCH RESULTS-->
            <template v-else-if="autocompleteLocationResults && !toggleWishlist">
                <template v-if="enableMultiSelection">

                    <div class="form-group checkbox-container" :class="{'active': locationIsSelected(location)}"
                         v-for="location in autocompleteLocationResults">

                        <input type="checkbox" :id="location.id" :checked="locationIsSelected(location)"/>

                        <label :for="location.id" class="filter-option" @click="toggleLocations(location)">
                            {{location.name}}
                        </label>
                    </div>

                </template>
                <template v-else>
                    <a class="filter-option"
                       v-for="location in autocompleteLocationResults"
                       v-on:click="setLocation(location)">
                        {{location.name}}
                    </a>
                </template>
            </template>

            <!--WISHLIST-->
            <template v-else-if="toggleWishlist && !autocompleteWishlistResults">
                <p v-if="wishlistLocations.length === 0">You do not have any locations in your wishlist.</p>

                <template v-else-if="enableMultiSelection">

                    <div class="form-group checkbox-container" :class="{'active': locationIsSelected(location)}"
                         v-for="location in wishlistLocations">

                        <input type="checkbox" :checked="locationIsSelected(location)"/>

                        <label class="filter-option" @click="toggleLocations(location)">
                            {{location.title}}
                        </label>
                    </div>
                </template>
                <template v-else>
                    <a class="filter-option"
                       v-for="location in wishlistLocations"
                       @click="setLocation(location)">
                        {{location.title}}
                    </a>
                </template>
            </template>

            <!--WISHLIST SEARCH RESULTS-->
            <template v-else-if="toggleWishlist && autocompleteWishlistResults">
                <p v-if="wishlistLocations.length === 0">You do not have any locations in your wishlist.</p>

                <template v-else-if="enableMultiSelection">

                    <div class="form-group checkbox-container" :class="{'active': locationIsSelected(location)}"
                         v-for="location in autocompleteWishlistResults">

                        <input type="checkbox" :checked="locationIsSelected(location)"/>

                        <label class="filter-option" @click="toggleLocations(location)">
                            {{location.title}}
                        </label>
                    </div>
                </template>
                <template v-else>
                    <a class="filter-option"
                       v-for="location in autocompleteWishlistResults"
                       @click="setLocation(location)">
                        {{location.title}}
                    </a>
                </template>
            </template>

            <template v-else>
                <div class="text-center">
                    Loading ...
                </div>
            </template>
        </div>

        <footer class="panel-footer" :class="{'-apply-mode': enableMultiSelection && locationsAreDirty, '-multi-mode': enableMultiSelection}">
            <div class="form-group checkbox-container wishlist-checkbox-container">
                <input type="checkbox" id="wishlist_location" v-model="toggleWishlist"/>

                <label for="wishlist_location">
                    <div class="wishlsit-select">
                        &nbsp;
                        <span class="wishlist-single">SHOW ONLY LOCATIONS IN MY WISHLIST</span>
                        <span class="wishlist-multi">SHOW WISHLIST</span>
                    </div>
                </label>
            </div>

            <a @click="setLocations" class="btn -main-cta -small -ripple apply-btn apply">
                <span>
                    <span>Apply</span> <i class="ion-ios-arrow-thin-right"></i>
                </span>
            </a>
        </footer>
    </div>
</template>
<script>
    import UrlMap from '../../../global/UrlMap';
    import router from '../../../router';

    export default {
        props: {
            params: {
                type: Object,
            },
            options: {
                type: Object
            },
            shouldSearch: {
                default: true,
                type: Boolean
            },
            sentenceId: {
                default: '',
                type: String
            },
            enableMultiSelection: {
                default: false,
                type: Boolean
            }
        },
        data() {
            return {
                globals: window.globals,
                locationAutocomplete: '',
                autocompleteLocationResults: '',
                autocompleteWishlistResults: '',
                toggleWishlist: false,
                locations: [],
                maxLocations: 5,
                initialLocations: [],
                anyLocation:{
                    id: 0,
                    name: "Africa"
                },
                oldSelectedLocations: []
            };
        },
        methods: {
            doLocationAutocomplete() {
                if (this.locationAutocomplete && this.locationAutocomplete.length >= 1) {

                    this.autocompleteLocationResults = this.sort(this.options.locations, 'name');

                } else {
                    this.autocompleteLocationResults = null;
                }
            },
            doWishlistAutocomplete(){
                if (this.locationAutocomplete && this.locationAutocomplete.length >= 1) {

                    this.autocompleteWishlistResults = this.sort(this.wishlistLocations, 'title');

                } else {
                    this.autocompleteWishlistResults = null;
                }
            },
            sort(options, key){
                let results, resultsMiddleMatch = [];

                // Match results starting at first letter of first word
                results = options.filter((obj) => {
                    return new RegExp('\\b^' + this.locationAutocomplete, "gi").test(obj[key]);
                });

                // Sort A-Z
                results.sort((a, b) => {
                    return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
                });

                // Match results starting anywhere
                resultsMiddleMatch = options.filter((obj) => {
                    return new RegExp(this.locationAutocomplete, "gi").test(obj[key]);
                });

                // Removed first word results from, anywhere results
                resultsMiddleMatch = resultsMiddleMatch.filter((obj) => {
                    return results.indexOf(obj) === -1;
                });

                // Sort A-Z
                resultsMiddleMatch.sort((a, b) => {
                    return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
                });

                let concatResults = results.concat(resultsMiddleMatch);

                return concatResults;
            },
            locationIsSelected(location){
                return this.getLocationIndexInLocations(location) > -1;
            },
            getLocationIndexInLocations(location){
                for(let i=0; i<this.locations.length; i++){
                    if(this.locations[i].id == location.id){
                        return i;
                    }
                }

                return -1;
            },
            locationInInitialLocations(location){
                for(let i=0; i<this.initialLocations.length; i++){
                    if(this.initialLocations[i].id === location.id){
                        return true;
                    }
                }

                return false;
            },
            toggleLocations(location){
                let locationIndex = this.getLocationIndexInLocations(location);

                if(location.id === 0){
                    if(locationIndex > -1){
                        //Removing any location to the search criteria
                        if(this.oldSelectedLocations.length > 0){
                            //Restore the locations to their state before Any location was added
                            this.locations = this.oldSelectedLocations;
                            this.oldSelectedLocations = [];
                        }else{
                            this.locations.splice(locationIndex, 1);
                        }

                    }else{
                        //Adding any location to the search criteria
                        this.oldSelectedLocations = this.locations;

                        this.locations = [this.anyLocation];
                    }
                }else {
                    this.oldSelectedlocations = [];

                    if (locationIndex > -1) {
                        this.locations.splice(locationIndex, 1);
                    } else {
                        if (this.locations.length < this.maxLocations) {
                            this.locations.push({
                                id: location.id,
                                name: location.name ? location.name : location.title
                            });

                            if(this.locationIsSelected(this.anyLocation)){
                                let anyLocationIndex = this.getLocationIndexInLocations(this.anyLocation);
                                this.locations.splice(anyLocationIndex, 1);
                            }
                        }
                    }
                }

                if(this.locations.length === 0){
                    this.locations = [this.anyLocation];
                }
            },
            setLocations(){
                this.$store.commit(window.store.SET_SEARCH_LOCATIONS, this.locations);
                this.$store.commit(window.store.SET_SEARCH_LOCATION, this.locations[0]);

                let urlMap = new UrlMap();

                let queryParams = window._.defaultsDeep({
                    locations: urlMap.urlEncode(this.locations),
                    location: this.locations[0].id,
                    lname: this.locations[0].name,
                    lprimary: true
                }, urlMap.searchStateToUrlParams(this.$store.state.search));

                router.push({name: 'finder', query: queryParams});

                window.bus.$emit('searchDoSearch');
            },
            setLocation(location) {
                if (this.shouldSearch) {

                    let newLocation = location;

                    if(location.wishlist) {
                        newLocation = {
                            ...newLocation,
                            name: location.title
                        };
                    }

                    this.$store.commit(window.store.SET_SEARCH_LOCATION, newLocation);
                    this.$store.commit(window.store.SET_SEARCH_LOCATIONS, [newLocation]);

                    let urlMap = new UrlMap();

                    let queryParams = window._.defaultsDeep({
                        location: location.id,
                        lname: location.name,
                        lprimary: location.lprimary,
                        locations: urlMap.urlEncode([newLocation])
                    }, new UrlMap().searchStateToUrlParams(this.$store.state.search));

                    router.push({name: 'finder', query: queryParams});

                    window.bus.$emit('searchDoSearch');

                }else {
                    this.params.location = location;
                }

                // window.bus.$emit('searchSentenceDirtyUpdate', {
                //     isDirty: true,
                //     key: 'location'
                // });
            },
            visibilityChanged(isVisible){
                if(isVisible) {
                    if(!pnpHelper.bootstrap.isXs()) {
                        this.$refs.input.focus();
                    }

                    this.locations = [...this.selectedLocations];

                    this.initialLocations = [...this.selectedLocations];
                } else {
                    this.locationAutocomplete = '';
                    this.autocompleteLocationResults = false;
                    this.toggleWishlist = false;
                }
            }
        },
        computed: {
            selectedLocations(){
                if(this.enableMultiSelection) {
                    this.locations = [{
                        id: 0,
                        name: "Africa"
                    }];

                    if (this.$store.state.search.criteria.params.locations.length === 0) {
                        this.$store.commit(window.store.SET_SEARCH_LOCATIONS, this.locations);
                    }
                }

                return this.$store.state.search.criteria.params.locations;
            },
            wishlistLocations(){
                let wishlist = this.$store.state.wishlist.selected;

                wishlist = wishlist.filter((el) => {
                    return el.type === 'location';
                }).sort((a, b) => {
                    return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
                });

                return wishlist;
            },
            locationsAreDirty(){
                if(this.locations.length !== this.selectedLocations.length){
                    return true;
                }else{
                    for(let i=0; i<this.locations.length; i++){
                        let found = false;

                        for(let j=0; j<this.selectedLocations.length; j++){
                            if(this.locations[i].id === this.selectedLocations[j].id){
                                found = true;
                            }
                        }

                        if(found === false){
                            return true;
                        }
                    }
                }

                return false;
            }
        },
        mounted(){
            this.$refs.input.focus();
        },
        watch:{
            toggleWishlist(){
                this.experienceAutocomplete = '';
                this.autocompleteExperienceResults = '';
                this.autocompleteWishlistResults = '';
            }
        }
    }
</script>