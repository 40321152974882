<template>
    <div>
        <span class="extra-buttons">
            <button-container v-if="!edit" :button="firstVideo" :type="type" :section_id="section_id" :slide_id="slide_id">
                Video
            </button-container>
           <button-container v-for="(button, _key) in buttons"
                             v-if="(button.action !== 'videoPlayer' || edit) && button.action !== 'seasonality' && !(button.action === 'showMore' && isOld)"
                             :button="button" :key="_key" :type="type" :section_id="section_id" :slide_id="slide_id">
                {{ button.text }}
            </button-container>
        </span>
    </div>
</template>

<script>
    export default {
        props: ['buttons', 'edit', 'type', 'section_id', 'slide_id', 'firstVideo'],
        computed: {
            isOld() {
                return window.globals.oldDevice;
            }
        }
    }
</script>