<template>
    <ul :class="classes">
        <template v-for="(pre, index) in prefix">
            <li v-for="out in outs" v-if="out.title">
                <button-internal-presentational v-on:btn-internal-clicked="emitUpdateSearchEvent()"
                                                classes="link"
                                                :router_object="routerObject(calcTypes[index], out)">
                    {{ pre }}

                    <span class="related-name">{{out.title}}</span>

                </button-internal-presentational>
            </li>
        </template>
    </ul>
</template>

<script>
    export default {
        props: {
            classes: {
                type: Array
            },
            prefix: {
                type: Array,
                required: true
            },
            retainSearch:{
              type: Boolean
            },
            list: {
                type: Array,
            },
            item: {
                type: Object
            },
            type: {
                type: String
            },
            types: {
                type: Array
            },
            itemType: {
                type: String
            }
        },
        methods: {
            emitUpdateSearchEvent() {
                /** FIXME: Implement a proper fix, too risky to make large changes before go-live so setTimeout will have to do */
                setTimeout(() => {
                    window.bus.$emit("searchRefresh");

                    $('html,body').animate({
                        scrollTop: 0
                    }, 500);

                }, 100);
            },
            routerObject(type, item){
                let thisType = type ? type : this.type;

                let returnObj;

                if(this.itemType !== 'experience') {
                    if(this.retainSearch) {
                        returnObj = {
                            name: 'finder',
                            query: {
                                type: thisType,
                                location: item ? item.id : 0,
                                lname: item ? item.title : ''
                            }
                        }
                    }else{
                        returnObj = {
                            name: 'finder',
                            query: {
                                type: thisType,
                                location: item ? item.id : 0,
                                lname: item ? item.title : '',
                                experience: 3,
                                ename: 'Any Experience'
                            }
                        }
                    }
                } else {
                    if(this.retainSearch) {
                        returnObj = {
                            name: 'finder',
                            query: {
                                type: thisType,
                                experience: item ? item.id : 0,
                                ename: item ? item.title : '',
                                lname: 'Africa',
                                location: 0
                            }
                        }
                    }else {
                        returnObj = {
                            name: 'finder',
                            query: {
                                type: thisType,
                                experience: item ? item.id : 0,
                                ename: item ? item.title : '',
                                lname: 'Africa',
                                location: 0
                            }
                        }
                    }
                }

                if(!this.retainSearch){
                    let defaults;

                    //Reset the month and budget
                    if(thisType === 'trip'){
                        defaults = {
                            tripbfrom:0,
                            tripbto:10000,
                            month: 0
                        }
                    }else{
                        defaults = {
                            bfrom:0,
                            bto:5000,
                            month: 0
                        }
                    }

                    returnObj.query = {...returnObj.query, ...defaults};
                }

                return returnObj;
            },
        },
        computed: {
            outs(){
                if(this.list){
                    return this.list;
                }

                if(!this.item.parents){
                    return [];
                }

                let parents = this.item.parents.data

                let firstItem = parents[0];

                let lastItem = parents[parents.length - 1];

                let criticalArrayLength = parents.filter((el) => el.critical).length;
                    criticalArrayLength = (criticalArrayLength - 1) < 0 ? 0 : criticalArrayLength - 1;

                let criticalItem = parents.filter((el) => {
                    return el.critical == true && (el.title !== lastItem.title || el.title !== firstItem.title)
                });

                criticalItem = criticalItem[criticalArrayLength] !== undefined ? criticalItem[criticalArrayLength] : {};

                let outs = [
                    lastItem,
                    criticalItem,
                    firstItem
                ];

                let africa = {
                    title: 'Africa',
                    slug: 'africa-safari',
                    type:  this.type ? this.type : 'location',
                    id: 0
                };

                if(this.type === 'best' && this.itemType === 'location') {
                    return _.uniqBy([lastItem, firstItem], 'title');

                } else if(this.type === 'trip' || this.type === 'best'){
                     return _.uniqBy([...outs], 'title');

                } else if (this.type === 'location' && this.itemType === 'location'){
                    outs = outs.filter((el) => el.title !== this.item.title);
                    return _.uniqBy([firstItem, africa], 'title');

                } else {
                    return _.uniqBy([...outs, africa], 'title');
                }
            },
            calcTypes(){
                return this.types ? this.types : []
            }
        }
    }
</script>
