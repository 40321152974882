<template>
    <div class="email-page split-page">

        <half-page-scroll-hijack :disableOnMobile="true">
            <section class="left-side article-images-container -in" v-if="!isMobile">
                <template>
                    <div class="article-images -showing-images -in" >
                        <transition-group name="fade" mode="in-out" duration="2000" tag="div">
                            <article-image-presentational
                                    :key="currentArticle.image"
                                    :image="currentArticle.image"
                                    :title="currentArticle.title"
                                    :content="currentArticle.content"
                                    :route_params="currentArticle.vue_route_params">
                            </article-image-presentational>
                        </transition-group>
                    </div>
                </template>
            </section>

            <div class="right-side">
                <div class="nav-veil -right"></div>

                    <form class="container -full-page" @submit="postForm" v-if="success === null">
                        <span class="yellow-bar"></span>

                        <div class="heading4 heading">Email</div>

                        <p class="content">Please enter your message here. Don’t forget to include your name and email address if you want us to reply.</p>

                        <input @input="updatePersonalDetails"
                               :value="email"
                               type="text"
                               name="email"
                               class="form-control"
                               placeholder="Enter email address*">

                        <textarea @input="updateEmailDetails"
                                  name="content"
                                  class="form-control -small"
                                  :value="content"
                                  placeholder="Message">
                        </textarea>

                        <div class="buttons">
                            <button type="submit" class="btn -main-cta -ripple" :disabled="posting">
                                <span><span v-html="submitButtonText"></span> <i class="ion-ios-arrow-thin-right"></i></span>
                            </button>

                            <div class="form-errors" v-if="errors.length > 0">
                                <p v-for="error in errors" v-html="error"></p>
                            </div>
                        </div>
                    </form>

                    <div class="container -full-page -in" v-if="success === true">
                        <span>
                            <h2 class="heading4 heading">Success!</h2>

                            <div class="content">
                                <p class="content">We aim to get back to you within one working day. Please do feel free to contact us by phone in the meantime if you want a more urgent response or if we need chasing up for any reason.</p>
                            </div>
                        </span>


                        <button-internal-presentational
                                :router_object="{ name: 'contact' }"
                                classes="-next-step"
                                icon="ion-ios-arrow-thin-right">
                            <div>Contact</div>
                        </button-internal-presentational>

                        <br>

                        <div class="content">Go in search of more experiences, locations, lodges and trips …</div>

                        <button-internal-presentational
                                :router_object="{ name: 'explore' }"
                                classes="-next-step"
                                icon="ion-ios-arrow-thin-right">
                            <div>Explore</div>
                        </button-internal-presentational>
                    </div>

                <contact-form-error v-if="success === false || isTestingFail" :retry="retry"></contact-form-error>
            </div>
        </half-page-scroll-hijack>
        <!-- <transition-wipe/> -->
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import LazyLoader from '../../global/LazyLoader';
    import pageIsReadyMixin from '../../mixins';

    export default {
        mixins: [pageIsReadyMixin],
        data(){
            return {
                posting: false,
                success: null,
                errors: [],
                submitButtonText: 'Send',

                currentArticle: {
                    image: 'https://atrimages.azureedge.net/cdn/contact/0/06/06ATR0300-IM0006-atr-safari-contact-1475.jpg',
                    content:"",
                    title:"",
                    url: undefined
                },
                articlesArr: [
                    { image: 'https://atrimages.azureedge.net/cdn/contact/0/06/06ATR0300-IM0006-atr-safari-contact-1475.jpg' },
                    { image: 'https://atrimages.azureedge.net/cdn/contact/0/06/06ATR0300-IM0006-atr-safari-contact-1475.jpg' },
                ]
            }
        },
        beforeDestroy() {
            window.bus.$emit('setShowBackButton', false);
        },
        methods: {

            isValidEmail(email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },
            retry(){
                this.success = null;
                this.posting = false;
                this.submitButtonText = 'Send';

                setTimeout(() => {
                    window.bus.$emit('half-page-init');
                });
            },
            updatePersonalDetails(e) {
                let payload = {
                    personal: {
                        [e.target.name]: e.target.value,
                    }
                };
                this.$store.commit('contactInfo/SET_STATE', payload);
            },
            updateEmailDetails(e){
                this.$store.commit('contactInfo/SET_STATE', {
                    email: {
                        [e.target.name]: e.target.value
                    }
                });
            },
            postForm(e){
                e.preventDefault();
                this.errors = [];

                if(!this.email){
                    this.errors.push('Please enter your email address');
                }else{
                    if(!this.isValidEmail(this.email)){
                        this.errors.push('Please enter a valid email address');
                    }
                }

                if(!this.content){
                    this.errors.push('Please enter your message');
                }

                if(this.errors.length === 0){
                    this.posting = true;
                    this.submitButtonText = 'Please wait...';

                    this.$store.dispatch(window.store.SEND_EMAIL, null, {root: true}).then((response) => {
                        this.posting = false;
                        this.success = true;
                        this.submitButtonText = 'Send';
                        window.GoogleAnalytics.fireEvent('Email', 'Send', this.$route.path);
                    }, (error) => {
                        this.posting = false;
                        this.success = false;
                        this.submitButtonText = 'Send';
                    });
                }
            }
        },
        computed: mapState({
            content: state => state.contactInfo.email.content,
            email: state => state.contactInfo.personal.email,
            isMobile(){
                return window.pnpHelper.bootstrap.isXs();
            },
            isTestingFail(){
                return window.location.href.indexOf('testfail') > -1;
            }
        }),
        mounted(){
            window.location.href = '/contact/inquiry';
            this.$store.commit(window.store.SET_PAGE_TITLE, this.$route.meta['page_title']);
            this.$store.commit(window.store.SET_META_DESCRIPTION, this.$route.meta['meta_description']);

            if(!this.isMobile) {
                let lazyLoader = new LazyLoader();
                lazyLoader.loadArticleImages(this.articlesArr);
            }

            this.declarePageIsReady();
        }
    }
</script>