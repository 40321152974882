<template>
    <div class="contact-btn-dropdown" >
        <a href="/contact/inquiry" class="btn -contact" @click="toggleDropDown">
            Get in touch
        </a>

        <!-- <transition name="fade">
            <div class="contact-dropdown" v-if="show">
                <span><a href="tel:3137442871" @click="toggleDropDown">USA : 313-744-2871</a></span>
                <span><a href="tel:+13137442871" @click="toggleDropDown">USA : +1 313-744-2871</a></span>
                <span><a href="tel:01306880770" @click="toggleDropDown">UK : 01306 880770</a></span>
                <span><a href="tel:+441306880770" @click="toggleDropDown">UK : +44 1306 880770</a></span>

                <span><a href="tel:8884875418" @click="toggleDropDown">USA : 888-487-5418</a></span>
                <span><a href="tel:01306880770" @click="toggleDropDown">UK : 01306 880770</a></span>
                <span><a href="tel:01306880770" @click="toggleDropDown">ROW : +44 1306 880770</a></span>


                <span class="divider"></span>

                <span><a href="/contact/call-back" @click="toggleDropDown">Get us to call you</a></span>
                <span><a href="/contact/inquiry" @click="toggleDropDown">Travel inquiry form</a></span>

                <span class="divider"></span>

                <span><a href="/contact" @click="toggleDropDown">How best to get in touch</a></span>
            </div>
        </transition>

        <transition name="fade">
            <div class="contact-veil" v-if="show" @click="toggleDropDown"></div>
        </transition> -->
    </div>
</template>

<script>
    export default {
        data(){
            return {
                show: false
            }
        },
        methods: {
            toggleDropDown(){
                this.show = !this.show;
            }
        }
    }
</script>