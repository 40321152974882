<template>
    <div class="search-results-outer" :class="{'-loading': doing_search}">
        <template v-if="searchResults">
            <section id="search-results" class="search-results">

                <template v-if="searchResults.type === 'lodge'">
                    <div class="component-card -sr-list -sr-lodge"
                         v-for="searchResult in searchResults.data"
                         :id="['sr-' + searchResult.id]"
                         :key="searchResult.id">

                        <search-lodge-card type="lodge"
                                           :searchResult="searchResult"
                                           :search="search"
                                           :toggleItem="toggleItem"
                                           :toggleRelatedSearches="toggleRelatedSearches"
                                           :selectedItems="selectedItems"
                                           :visibleRelatedResults="visibleRelatedResults">
                        </search-lodge-card>
                    </div>
                </template>

                <template v-else-if="searchResults.type === 'experience'">
                    <div class="component-card -sr-list -sr-experience"
                       v-for="searchResult in searchResults.data"
                       :id="['sr-' + searchResult.id]"
                       :key="searchResult.id">

                        <search-experience-card :searchResult="searchResult"
                                                :toggleRelatedSearches="toggleRelatedSearches"
                                                :visibleRelatedResults="visibleRelatedResults">
                        </search-experience-card>
                    </div>
                </template>

                <template v-else-if="searchResults.type === 'location'">
                    <div class="component-card -sr-list -sr-lodge"
                         v-for="searchResult in searchResults.data"
                         :id="['sr-' + searchResult.id]"
                         :href="searchResult.url"
                         :key="searchResult.id">

                        <search-location-card type="location"
                                              :searchResult="searchResult"
                                              :search="search"
                                              :toggleItem="toggleItem"
                                              :toggleRelatedSearches="toggleRelatedSearches"
                                              :selectedItems="selectedItems"
                                              :visibleRelatedResults="visibleRelatedResults">
                        </search-location-card>
                    </div>
                </template>

                <template v-else-if="searchResults.type === 'trip' && this.$route.name !== 'wishlist'">
                    <div class="trip-container"
                         v-for="searchResult in searchResults.data"
                         :key="searchResult.id">

                        <div class="component-card -sr-list -sr-trip-family"
                             :id="['sr-' + searchResult.id]"
                             v-hover-intent:200="highlightMap.bind(null, searchResult, true)"
                             :class="{'-active': searchResult.id == currentTripFamily.id, '-open' : !highlightTripFamily}"
                             @click="toggleTripFamily(searchResult.id)">

                            <div class="col-caption">
                                <div class="heading2 heading" :class="{'-highlighting-location': currentTripFamilyLocationIndex != null}">
                                    <span class="trip-location"
                                          :class="{'-active': currentTripFamilyLocationIndex == index && currentTripFamily.id == searchResult.id}"
                                          v-for="(location,index) in searchResult.geographical_summary"
                                          v-hover-intent:100="highlightTripLocation.bind(null, location.lat, location.lng, index)"
                                    >
                                        {{location.title}}<span v-if="index < (searchResult.geographical_summary.length - 1)">,&nbsp;</span>
                                    </span>
                                </div>

                                <div class="sub-text -line">
                                    POPULARITY {{searchResult.popularity}} :
                                    <template v-if="searchResult.trip_count == 1">TRIP {{searchResult.trip_count}}</template>
                                    <template v-else> TRIPS {{searchResult.trip_count}}</template>
                                    : PEAK RATING {{searchResult.peak_rating}}
                                </div>

                                <i class="arrow ion-ios-arrow-down" :class="[ activeAccordion == searchResult.id ? '-up' : '' ]"></i>
                            </div>
                        </div>

                        <!--<transition name="slide-down-trip-family" mode="in-out" duration="3000">-->
                        <div class="trip-families" v-if="activeAccordion == searchResult.id" key="families">
                            <div :id="['trip-family-' + searchResult.id]">
                                <div v-if="hasTrips(searchResult.id)">
                                    <div class="component-card -sr-list -sr-trip"
                                         v-for="(trip, index) in trips[searchResult.id]"
                                         v-if="index < getMaxTripsToShow"
                                         v-hover-intent:200="highlightMap.bind(null, trip, false)"
                                         :id="['sr-' + trip.id]">

                                        <search-trip-card
                                                :trip="trip"
                                                :searchResult="searchResult"
                                                :search="search"
                                                :currentTrip="currentTrip"
                                                :highlightTrip="!highlightTripFamily">
                                        </search-trip-card>
                                    </div>

                                    <a class="view-more-container" v-if="hasMoreTripsToShow(searchResult.id)" @click="increasePage">
                                        <span class="text">View more</span>
                                    </a>
                                </div>
                                <div v-else class="component-card -sr-list -sr-trip">
                                    <br/>
                                    Loading ...
                                    <br/>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-else-if="searchResults.type === 'trip' && this.$route.name === 'wishlist'">

                    <div class="trip-container"
                         v-for="searchResult in searchResults.data"
                         :key="searchResult.id">

                        <div class="component-card -sr-list -sr-trip"
                             :id="searchResult.id"
                             v-hover-intent:200="highlightMap.bind(null, searchResult)"
                        >
                            <!--:href="trip.url"-->

                            <search-trip-card
                                    :trip="searchResult"
                                    :searchResult="searchResult.trip"
                                    :search="search"
                                    :currentTrip="currentTrip">
                            </search-trip-card>
                        </div>
                    </div>
                </template>

                <template v-else-if="searchResults.type === 'resource' || searchResults.type === 'trip_collection' || searchResults.type === 'article'">
                    <div class="search-controls -directory">
                        <div class="directory -resources">
                            <public-resource-listing
                                    :directory="searchResults.data"
                                    :level="1"
                                    :type="searchResults.type"
                            >
                            </public-resource-listing>
                        </div>
                    </div>
                </template>

                <!--<infinite-loading @infinite="loadMore"></infinite-loading>-->

                <!--<a class="btn -not-rounded" @click="loadMoreResults()" style="margin:5% 0">Load More</a>-->
            </section>
        </template>

        <transition-group name="fade" mode="in-out" tag="div">
            <div v-if="doing_search" class="search-message" key="loading">
                Loading ...
            </div>

            <div v-if="searchResults.count === 0 && !doing_search" class="search-message" key="no-results">
                Please try another search.
            </div>

            <div v-if="searchResults.data && !searchResults.data.length && this.$route.name === 'wishlist'" class="search-message" key="no-wishlist">
                There are presently no {{searchResults.type}}s in your wishlist.
            </div>
        </transition-group>
    </div>
</template>

<script>
    import ButtonInternal from "../partials/presentational/ButtonInternal";
    import AtrMap from "../../old/map";

    export default {
        data() {
            return {
                numberOfTripsToShow: 8,
                currentArticle: null,
                selectedItems: [],
                visibleRelatedResults: [],
                loaded: false,
                currentTripFamily: {},
                currentTripFamilyLocationIndex: null,
                currentTrip: {},
                highlightTripFamily: false,
            }
        },
        components: {
            ButtonInternal
        },
        props: [
            'mode',
            'subMode',
            'doing_search'
        ],
        methods: {
            increasePage() {
                this.$store.commit(window.store.SET_ACTIVE_TRIP_ACCORDION_PAGE, this.currentPage + 1);
            },
            hasMoreTripsToShow(familyId) {
                return Object.keys(this.trips[familyId]).length > this.getMaxTripsToShow;
            },
            hasTrips(familyId) {
                return !_.isUndefined(this.trips[familyId]) && Object.keys(this.trips[familyId]).length > 0;
            },
            toggleTripFamily(id) {
                //Preserve the scroll position of the family you just clicked
                let tripFamilyPosTopOffset;
                if(this.activeAccordion && $('#sr-' + id).length) {
                    tripFamilyPosTopOffset = $(window).scrollTop() - $('#sr-' + id).offset().top;
                }

                this.$store.commit(window.store.SET_ACTIVE_TRIP_ACCORDION_PAGE, 1);

                this.$store.commit(
                    window.store.SET_ACTIVE_TRIP_ACCORDION,
                    this.activeAccordion == id && this.activeAccordion != null ? null : id
                );

                if (this.activeAccordion != null) {
                    this.$store.dispatch(window.store.FETCH_AND_SET_TRIPS_FOR_TRIP_FAMILY, this.activeAccordion);
                }

                //Preserve the scroll position of the family you just clicked
                if(tripFamilyPosTopOffset) {
                    setTimeout(() =>{
                        let tripFamilyNewPosTopOffset = $('#sr-' + id).offset().top;
                        $(window).scrollTop(tripFamilyNewPosTopOffset + tripFamilyPosTopOffset);
                    });
                }

                // $('#trip-family-' + id).slideToggle(1000);
            },
            toggleRelatedSearches(id) {
                if(this.visibleRelatedResults.includes(id)){
                    this.visibleRelatedResults.splice(
                        this.visibleRelatedResults.findIndex(function(thisId){
                            return thisId === id;
                        }), 1);
                } else {
                    this.visibleRelatedResults.push(id);
                }
            },
            toggleItem(searchItem, event, trip){
                event.preventDefault();
                let newSearchItem;

                if(trip){
                    newSearchItem = {
                        ...searchItem,
                        type: this.$store.state.search.criteria.params.type,
                        trip: trip
                    };
                } else {
                    newSearchItem = {...searchItem, type: this.$store.state.search.criteria.params.type };
                }

                let newSelectedItems = [...this.wishlist];

                let check = newSelectedItems.some((el) => {
                    return el.id === newSearchItem.id;
                });

                if(check){
                    //Remove from the search criteria
                    newSelectedItems = newSelectedItems.filter((el) => {
                        return el.id !== newSearchItem.id;
                    });

                    this.$store.commit(window.store.SET_WISHLIST, newSelectedItems);
                } else {
                    newSelectedItems.push(newSearchItem);
                    this.$store.commit(window.store.SET_WISHLIST, newSelectedItems);
                }

                this.selectedItems = newSelectedItems;
            },
            highlightMap(entity, isTripFamily) {
                if(!_.isUndefined(pnpHelper.map)) {
                    if (this.search.results.type === "trip") {
                        pnpHelper.map.renderTripSummary(entity, isTripFamily);

                        if(!pnpHelper.isTouch()) {
                            if (isTripFamily) {
                                this.currentTripFamily = entity;
                                this.currentTripFamilyLocationIndex = null;
                                this.highlightTripFamily = true;
                            } else {
                                this.currentTrip = entity;
                                this.highlightTripFamily = false;
                            }
                        }
                    } else {
                        pnpHelper.map.focusOnMarker(entity.id);
                    }
                }
            },
            highlightTripLocation(lat, lng, locationIndex){
                if(!pnpHelper.isTouch()) {
                    if (!locationIndex) {
                        for (let i = 0; i < this.currentTripFamily.geographical_summary.length; i++) {
                            if (this.currentTripFamily.geographical_summary[i].lat == lat && this.currentTripFamily.geographical_summary[i].lng == lng) {
                                locationIndex = i;
                                break;
                            }
                        }
                    }

                    this.currentTripFamilyLocationIndex = locationIndex;
                    pnpHelper.map.focusOnMarker(locationIndex);

                    window.bus.$once('tripSummaryRendered', () => {
                        this.currentTripFamilyLocationIndex = locationIndex;
                        pnpHelper.map.focusOnMarker(locationIndex);
                    });
                }
            },
            highlightArticle(article) {
                this.$store.commit(window.store.SET_ACTIVE_ARTICLE, article, {root:true});
            },
            updateTripResultView(){
                if(this.search.results.type === 'trip' && this.searchResults.data.length === 1){
                    this.toggleTripFamily(this.searchResults.data[0].id);
                }//else{
                    // if(this.activeAccordion) {
                    //     this.toggleTripFamily(this.activeAccordion);
                    // }
                // }

                if(this.search.results.type === 'trip' && this.activeAccordion) {
                    this.$store.dispatch(window.store.FETCH_AND_SET_TRIPS_FOR_TRIP_FAMILY, this.activeAccordion);

                    if(pnpHelper.map){
                        pnpHelper.map.clearMap();
                    }
                }
            },
            recursiveSearchForObject(id, results){
                for(var x in results){
                    if(results[x].id == id){
                        let subObject = window._.defaultsDeep({}, results[x]);
                        subObject.isOpen = !subObject.isOpen;
                        results[x] = subObject;
                    } else {
                        if(results[x].children.length > 0){
                            results[x].children = this.recursiveSearchForObject(id, results[x].children);
                        }
                    }
                }
                return results;
            },
            recursiveSearchAndOpenObject(id, results){
                for(var x in results){
                    if(results[x].id == id){
                        let subObject = window._.defaultsDeep({}, results[x]);
                        subObject.isOpen = true;
                        results[x] = subObject;
                    } else {
                        if(results[x].children.length > 0){
                            results[x].children = this.recursiveSearchForObject(id, results[x].children);
                        }
                    }
                }
                return results;
            }
        },
        computed: {
            currentPage() {
                return this.$store.state.search.tripMeta.currentPage;
            },
            activeAccordion() {
                return this.$store.state.search.tripMeta.activeAccordion;
            },
            getMaxTripsToShow() {
                return this.numberOfTripsToShow * this.currentPage;
            },
            trips() {
                return this.$store.state.search.trips;
            },
            searchResults() {
                if(this.$route.name === 'wishlist') {
                    let list = this.wishlist;
                    let type = this.$store.state.search.criteria.params.type;

                    list = this.wishlist.sort((a, b) => {
                        if (_.isUndefined(a['title']) || _.isUndefined(b['title'])) {
                            return true;
                        }

                        return a['title'].toLowerCase().localeCompare(b['title'].toLowerCase());
                    });

                    let data = {
                        data: list.filter((el) =>  el.type === type),
                        type: type,
                    };

                    return data;
                } else {
                    return this.$store.state.search.results;
                }
            },
            search() {
                return this.$store.state.search;
            },
            wishlist() {
                return [...this.$store.state.wishlist.selected];
            }
        },
        mounted() {
            setTimeout(()=>{
                this.loaded = true;
            },1400)
        },
        watch: {
            loaded() { // watch it
                if(this.$route.query && this.$route.query.id && this.$route.query.type == 'resource') {
                    let results = window._.defaultsDeep({}, this.searchResults);

                    results.data = this.recursiveSearchAndOpenObject(this.$route.query.id, results.data);

                    this.$store.commit(window.store.SET_SEARCH_RESULTS, results, {root: true});

                    //Scroll to meet the team
                    pnpHelper.scroll.scrollTo('#search-results');
                }
            }
        },
        updated() {
            if(this.search.results.type === 'lodge' || this.search.results.type === 'location'){
                if(!_.isUndefined(this.searchResults) && !_.isUndefined(this.searchResults.data) && !_.isUndefined(this.searchResults.data[0])) {
                    // this.highlightMap(this.searchResults.data[0]);
                }
            }else if(this.search.results.type === 'trip'){
                if(_.isEmpty(this.currentTrip) && _.isEmpty(this.currentTripFamily) && pnpHelper.map){
                    pnpHelper.map.clearMap();
                }

                // if(!_.isUndefined(this.searchResults) && !_.isUndefined(this.searchResults.data) && !_.isUndefined(this.searchResults.data[0]) && this.currentTripFamily.id != this.searchResults.data[0].id) {
                //     console.log('doing update');
                //     this.highlightMap(this.searchResults.data[0]);
                // }
            }

            if(this.search.results.type !== 'trip'){
                this.currentTrip = {};
                this.currentTripFamily = {};
            }
        },
        created() {
            let eventSuffix = this.mode;

            if(this.subMode){
                eventSuffix += '.' + this.subMode;
            }

            bus.$on('doSearch.' + eventSuffix, this.doSearch);
            bus.$on('searchComplete', this.updateTripResultView);
            bus.$on('highlightTripLocation', this.highlightTripLocation);

            this.$store.commit(window.store.FETCH_AND_SET_WISHLIST);
        },
        destroy(){
            bus.$off('doSearch.' + eventSuffix, this.doSearch);
            bus.$off('searchComplete', this.updateTripResultView);
            bus.$off('highlightTripLocation', this.highlightTripLocation);
        },
    }
</script>
