//Variables global to all js. Use your power wisely.
window.$window = $(window);
window.$document = $(document);
window.$body = $('body');
window.$html = $('html');

window.$navBar = $('#nav');
window.pnpHelper = {};
window.g_isAboutToRewindVertical = null;
window.isEditMode = () => {
    return $('#edit-controls').length && $('#edit-controls').hasClass('-edit-mode');
};