<template>
    <span>
        <a class="btn -read-more -animated"
           :class="classes"
           :data-action="data_action"
           :data-style="data_style"
           :data-article="data_article"
           :data-sections="data_sections"
           :data-pnp-modal-open="modalToOpen"
           v-on:click="executeClick()">
            <i v-if="icon != false && is_icon_left" :class="icon"></i>
            <slot></slot>
            <i v-if="icon != false && !is_icon_left" :class="icon"></i>
        </a>
    </span>
</template>

<script>
    import StateUtils from '../../../global/StateUtils'
    export default {
        props: [
            'data_style', 'data_article', 'data_sections',
            'data_action', 'classes', 'modal', 'type', 'icon', 'is_icon_left', 'section_id', 'slide_id', 'text'
        ],
        computed: {
            modalToOpen: function() {
                return window._.isEmpty(this.modal) ? false : '#' + this.modal;
            }
        },
        mounted() {
            if(this.data_action === 'showAll') {
                window.bus.$on('openViewAllSections', this.delayExecuteClick);
            }
        },
        destroyed() {
            if(this.data_action === 'showAll') {
                window.bus.$off('openViewAllSections', this.delayExecuteClick);
            }
        },
        methods: {
            delayExecuteClick(){
                let vm = this;
                setTimeout(() => {
                    vm.executeClick();
                },500);
            },
            executeClick() {
                if (this.data_action === 'showAll' || this.data_action === 'showMore') {
                    var stateObject = new StateUtils(this.type, this.$store).getStateObject();
                    if(stateObject!==null) {
                        if (this.data_action === 'showAll') {
                            for(var st in this.data_sections){
                                for (var x in stateObject.allSections.data) {
                                    if(this.data_sections[st] === stateObject.allSections.data[x].id){
                                        stateObject.allSections.data[x].hidden = 0;
                                    }
                                }
                            }
                        } else if (this.data_action === 'showMore') {
                            for (var x in stateObject.allSections.data) {
                                if (stateObject.allSections.data[x].id === this.section_id) {
                                    for (var t  in stateObject.allSections.data[x].allSlides.data) {
                                        stateObject.allSections.data[x].allSlides.data[t].hidden = 0;
                                    }
                                }
                            }
                        }

                        if(this.type.indexOf('_slide')!==-1){
                            for (var x in stateObject.allSections.data) {
                                if (stateObject.allSections.data[x].id === this.section_id) {
                                    for (var t  in stateObject.allSections.data[x].allSlides.data) {
                                        if (stateObject.allSections.data[x].allSlides.data[t].id === this.slide_id) {
                                            for(var y in stateObject.allSections.data[x].allSlides.data[t].buttons){
                                                if(
                                                    stateObject.allSections.data[x].allSlides.data[t].buttons[y].modal === this.modal &&
                                                    stateObject.allSections.data[x].allSlides.data[t].buttons[y].article === this.data_article &&
                                                    stateObject.allSections.data[x].allSlides.data[t].buttons[y].action === this.data_action &&
                                                    stateObject.allSections.data[x].allSlides.data[t].buttons[y].text === this.text
                                                ){
                                                    stateObject.allSections.data[x].allSlides.data[t].buttons[y].isClicked = true;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            for (var x in stateObject.allSections.data) {
                                if (stateObject.allSections.data[x].id === this.section_id) {
                                    for(var y in stateObject.allSections.data[x].buttons){
                                        if(
                                            stateObject.allSections.data[x].buttons[y].modal === this.modal &&
                                            stateObject.allSections.data[x].buttons[y].article === this.data_article &&
                                            stateObject.allSections.data[x].buttons[y].action === this.data_action &&
                                            stateObject.allSections.data[x].buttons[y].text === this.text
                                        ){
                                            stateObject.allSections.data[x].buttons[y].isClicked = true;
                                        }
                                    }
                                }
                            }
                        }

                        new StateUtils(this.type, this.$store).setStateObject(stateObject, {
                            action: this.data_action
                        });
                    }
                }
            }
        }
    }
</script>