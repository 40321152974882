<template>
    <div style="margin-top:18px;">
        <div class="messages-container">
            <div class="errors-container" v-if="error">
                <p>Please include at least 1 adult guest.</p>
            </div>
        </div>
        <div class="guestsSelector">
            <div class="selectorContainer">
                <div class="selectorLabel">Adults (18+)</div>
                <div class="buttonGroup">
                    <button type="button" data-age-group="adults" @click="updateValue('decrease', 'adult', $event);">-</button>
                    <div class="counter">{{ totalAdults }}</div>
                    <button type="button" data-age-group="adults" @click="updateValue('increase', 'adult', $event);">+</button>
                </div>
            </div>
        </div>

        <div style="display:flex; flex-direction: column;">
            <div v-show="addChildren" style="margin-top:10px;">
                <div class="guestsSelector" style="margin-bottom: 10px;">
                    <div class="selectorContainer">
                        <div class="selectorLabel">Teens (13 - 17)</div>
                        <div class="buttonGroup">
                            <button type="button" data-age-group="teens" @click="updateValue('decrease', 'teen', $event);">-</button>
                            <div class="counter">{{ totalTeens }}</div>
                            <button type="button" data-age-group="teens" @click="updateValue('increase', 'teen', $event);">+</button>
                        </div>
                    </div>
                </div>
                <div class="guestsSelector" style="margin-bottom: 10px;">
                    <div class="selectorContainer">
                        <div class="selectorLabel">Children (3 - 12)</div>
                        <div class="buttonGroup">
                            <button type="button" data-age-group="childrens" @click="updateValue('decrease', 'children', $event);">-</button>
                            <div class="counter">{{ totalChildrens }}</div>
                            <button type="button" data-age-group="childrens" @click="updateValue('increase', 'children', $event);">+</button>
                        </div>
                    </div>
                </div>
                <div class="guestsSelector">
                    <div class="selectorContainer">
                        <div class="selectorLabel">Infants (0-2)</div>
                        <div class="buttonGroup">
                            <button type="button" data-age-group="infants" @click="updateValue('decrease', 'infant', $event);">-</button>
                            <div class="counter">{{ totalInfants }}</div>
                            <button type="button" data-age-group="infants" @click="updateValue('increase', 'infant', $event);">+</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="addChildrenButton">
                <button type="button" @click="toggleChildren()">{{ addChildren ? 'Remove children' : 'Add children' }}</button>
            </div>
        </div>
        <slot name="additionalText" />

        <input type="text" name="how_many_adults_travelling" :value="totalAdults" style="opacity:0; height:0;">
        <input type="text" name="how_many_teens_13_17_travelling" :value="totalTeens" style="opacity:0; height:0;">
        <input type="text" name="how_many_children_2_12_travelling" :value="totalChildrens" style="opacity:0; height:0;">
        <input type="text" name="how_many_infants_0_2_travelling" :value="totalInfants" style="opacity:0; height:0;">
    </div>
</template>

<script>

    export default {
        props: ['formContent', 'checkValidation', 'stepIndex'],
        data() {
            return {
                totalAdults: null,
                totalTeens: null,
                totalChildrens: null,
                totalInfants: null,
                addChildren: false,
                error: false,
            }
        },
        mounted() {
            this.totalAdults = this.formContent.how_many_adults_travelling || 2;
            this.totalTeens = this.formContent.how_many_teens_13_17_travelling || 0;
            this.totalChildrens = this.formContent.how_many_children_2_12_travelling || 0;
            this.totalInfants = this.formContent.how_many_infants_0_2_travelling || 0;

            this.addChildren = this.totalNonAdultGuests > 0 || false;

            if(typeof this.formContent.how_many_adults_travelling === "undefined" || this.formContent.how_many_adults_travelling === null){
                this.$emit('update-data', {key: 'how_many_adults_travelling', value: this.totalAdults});
            }
            if(typeof this.formContent.how_many_teens_13_17_travelling === "undefined" || this.formContent.how_many_teens_13_17_travelling === null){
                this.$emit('update-data', {key: 'how_many_teens_13_17_travelling', value: this.totalTeens});
            }
            if(typeof this.formContent.how_many_children_2_12_travelling === "undefined" || this.formContent.how_many_children_2_12_travelling === null){
                this.$emit('update-data', {key: 'how_many_children_2_12_travelling', value: this.totalChildrens});
            }
            if(typeof this.formContent.how_many_infants_0_2_travelling === "undefined" || this.formContent.how_many_infants_0_2_travelling === null){
                this.$emit('update-data', {key: 'how_many_infants_0_2_travelling', value: this.totalInfants});
            }
        
        },
        watch: {
            checkValidation: function(){
                this.validateStep();
            },
        },
        methods: {
            updateValue(action, ageGroup, e){
                if(action === 'increase'){
                    this[`total${ageGroup.charAt(0).toUpperCase() + ageGroup.slice(1)}s`] += 1;
                }else{
                    if(this[`total${ageGroup.charAt(0).toUpperCase() + ageGroup.slice(1)}s`] > 0){
                        this[`total${ageGroup.charAt(0).toUpperCase() + ageGroup.slice(1)}s`] -= 1;
                    }
                }

                this.setValue(e);
            },
            setValue(e){
                
                const ageFields = {
                    adults: {key: 'how_many_adults_travelling', value: this.totalAdults},
                    teens: {key: 'how_many_teens_13_17_travelling', value: this.totalTeens},
                    childrens: {key: 'how_many_children_2_12_travelling', value: this.totalChildrens},
                    infants: {key: 'how_many_infants_0_2_travelling', value: this.totalInfants},
                };

                this.$emit('update-data', ageFields[e.target.dataset.ageGroup]);

                this.validateStep();

            },
            toggleChildren(){
                this.addChildren = !this.addChildren;

                if(!this.addChildren){
                    this.totalTeens = 0;
                    this.totalChildrens = 0;
                    this.totalInfants = 0;
                }

                this.$emit('update-data', {key: 'how_many_teens_13_17_travelling', value: null});
                this.$emit('update-data', {key: 'how_many_children_2_12_travelling', value: null});
                this.$emit('update-data', {key: 'how_many_infants_0_2_travelling', value: null});
            },
            validateStep(){
                if(this.formContent.how_many_adults_travelling < 1){
                    this.error = true;

                    this.$emit('update-validation', {step: this.step.index, value: false});
                } else {
                    this.error = false;

                    this.$emit('update-validation', {step: this.step.index, value: true});
                    
                }
            }
        },
        computed: {
            totalNonAdultGuests(){
                return this.totalTeens + this.totalChildrens + this.totalInfants;
            }
        }
    }

</script>