import PrimitiveUtilities from "../helpers/PrimitiveUtilities";
import GlobalVars from "./GlobalVars";

export default class SchemaData
{
    minimumReviewCount = 3;

    constructor() {
        this.primitiveUtilities = new PrimitiveUtilities();
    }

    getLodgeSchema(lodge) {
        return {
            "@context": "http://schema.org",
            "@type": "Product",
            // "aggregateRating": {
            //     "@type": "AggregateRating",
            //     "bestRating": "10",
            //     "ratingValue": lodge.rating,
            //     "reviewCount": String(this.primitiveUtilities.getRandomNumberFromString(lodge.title, this.minimumReviewCount))
            // },
            "description": this.primitiveUtilities.stripHtmlFromString(lodge.top_section.content),
            "name": "Safari to " + lodge.title + " with Africa Travel Resource",
            "image": lodge.images.sizes[Object.keys(lodge.images.sizes)[0]],
            "offers": {
                "@type": "Offer",
                "price": lodge.price,
                "priceCurrency": "USD"
            }
        }
    }

    getLocationSchema(location) {
        return {
            "@context": "http://schema.org",
            "@type": "Product",
            // "aggregateRating": {
            //     "@type": "AggregateRating",
            //     "bestRating": "10",
            //     "ratingValue": location.rating,
            //     "reviewCount": String(this.primitiveUtilities.getRandomNumberFromString(location.title, this.minimumReviewCount))
            // },
            "description": this.primitiveUtilities.stripHtmlFromString(location.top_section.content),
            "name": "Safari to " + location.title + " with Africa Travel Resource",
            "image": GlobalVars.APP_URL + location.image,
            "offers": {
                "@type": "Offer",
                "price": location.price,
                "priceCurrency": "USD"
            }
        }
    }

    getExperienceSchema(experience) {
        return {
            "@context": "http://schema.org",
            "@type": "Product",
            // "aggregateRating": {
            //     "@type": "AggregateRating",
            //     "bestRating": "10",
            //     "ratingValue": experience.rating,
            //     "reviewCount": String(this.primitiveUtilities.getRandomNumberFromString(experience.title, this.minimumReviewCount))
            // },
            "description": experience.content,
            "name": "Safari to " + experience.title + " with Africa Travel Resource",
            "image": experience.image,
            "offers": {
                "@type": "Offer",
                "price": experience.guide_price,
                "priceCurrency": "USD"
            }
        }
    }

    getResourceSchema(resource) {
        if (resource.display_type == 0) {
            return this.getHalfPageResourceSchema(resource);
        }
    }

    getHalfPageResourceSchema(resource) {
        return {
            "@context": "http://schema.org",
            "@type": "Blog",
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": resource.full_url
            },
            "headline": resource.title,
            "image": [
                resource.images.sizes[Object.keys(resource.images.sizes)[0]],
            ],
            "publisher": {
                "@type": "Organization",
                "name": "Africa Travel Resource",
                "logo": {
                    "@type": "ImageObject",
                    "url": GlobalVars.LOGO_URL
                }
            },
            "description": "Information and advice on " + resource.title + " from Africa Travel Resource"
        }
    }

    getNavigationSchema() {
        return {
            // "@context": "http://schema.org",
            // "@type": "ItemList",
            // "itemListElement": [
            //     {
            //         "@type": "SiteNavigationElement",
            //         "position": 1,
            //         "name": "ATR",
            //         "url": GlobalVars.APP_URL
            //     },
            //     {
            //         "@type": "SiteNavigationElement",
            //         "position": 2,
            //         "name": "Start",
            //         "url": GlobalVars.APP_URL + "/start"
            //     },
            //     {
            //         "@type": "SiteNavigationElement",
            //         "position": 3,
            //         "name": "Experiences",
            //         "url": GlobalVars.APP_URL + "/safari-explore/finder/experiences"
            //     },
            //     {
            //         "@type": "SiteNavigationElement",
            //         "position": 4,
            //         "name": "Lodges",
            //         "url": GlobalVars.APP_URL + "/safari-explore/finder/lodges"
            //     },
            //     {
            //         "@type": "SiteNavigationElement",
            //         "position": 5,
            //         "name": "Locations",
            //         "url": GlobalVars.APP_URL + "/safari-explore/finder/locations"
            //     },
            //     {
            //         "@type": "SiteNavigationElement",
            //         "position": 6,
            //         "name": "Trips",
            //         "url": GlobalVars.APP_URL + "/safari-explore/finder/trips"
            //     },
            //     {
            //         "@type": "SiteNavigationElement",
            //         "position": 7,
            //         "name": "Resources",
            //         "url": GlobalVars.APP_URL + "/safari-explore/finder/resources"
            //     },
            //     {
            //         "@type": "SiteNavigationElement",
            //         "position": 8,
            //         "name": "Explore",
            //         "url": GlobalVars.APP_URL + "/safari-explore/finder"
            //     },
            //     {
            //         "@type": "SiteNavigationElement",
            //         "position": 9,
            //         "name": "Contact",
            //         "url": GlobalVars.APP_URL + "/contact"
            //     },
            // ]
        }
    }

    getSignificantLinksSchema() {
        return {
            "@context": "http://schema.org",
            "@type": "WebPage",
            "significantLink": [
                GlobalVars.APP_URL + "/safari-resources/about-us",
                GlobalVars.APP_URL + "/safari-explore/directory"
            ]
        };
    }

    getStartVideoSchema() {
        return {
            "@context": "http://schema.org",
            "@type": "VideoObject",
            "name": "ATR - The Safari Experts",
            "description": "Our experienced Africa travel experts can create awesome tailor-made safaris to Africa. Call us to arrange your perfect Africa safari vacation or to book a superb safari holiday to Africa.",
            "thumbnailUrl": [
                GlobalVars.ASSETS_URL + "/img/thumbnails/1x1/thumbnail-photo.jpg",
                GlobalVars.ASSETS_URL + "/img/thumbnails/4x3/thumbnail-photo.jpg",
                GlobalVars.ASSETS_URL + "/img/thumbnails/16x9/thumbnail-photo.jpg"
            ],
            "uploadDate": "2018-12-05T08:00:00+08:00",
            "duration": "PT1M58S",
            "contentUrl": GlobalVars.APP_URL,
            "embedUrl": GlobalVars.ASSETS_URL + "/video/start.mp4"
        }
    }

    getHomepageSchema() {
        return {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Africa Travel Resource",
            "legalName" : "Africa Travel Resource Limited",
            "url": GlobalVars.APP_URL,
            "logo": GlobalVars.LOGO_URL,
            "foundingDate": "2000",
            "founders": [
                {
                    "@type": "Person",
                    "name": "Greg Welby"
                }
            ],
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Milton Heath House",
                "addressLocality": "Westcott Road ",
                "addressRegion": "Surrey",
                "postalCode": "RH4 3NB",
                "addressCountry": "England"
            },
            "contactPoint": [
                {
                    "@type" : "ContactPoint",
                    "telephone" : "+44 1306 880770",
                    "contactType" : "sales"
                },
                {
                    "@type" : "ContactPoint",
                    "telephone" : "+1 888 487 5418",
                    "contactType" : "sales",
                    "areaServed" : [ "US", "CA" ]
                },
                {
                    "@type" : "ContactPoint",
                    "telephone" : "+1 313 744 2871",
                    "contactType" : "sales",
                    "areaServed" : [ "US", "CA" ]
                }
            ],
            "sameAs": [
                "http://www.facebook.com/africatravelresource",
                "https://twitter.com/ATR_Safaris",
            ]
        }
    }
}