<template>
    <div class="single-trip-page">
        <div class="nav-veil"></div>

        <div class="left-side" :class="{'full-width-trip' : oldDeviceCheck}" v-if="loaded">

            <div v-waypoint="{ active: !isMobile, callback: recenterMap, options: intersectionOptions }">
                <div class="component-card -sr-single-trip -sr-trip -full-page -intro-card vh-fix"
                    :data-id="trip.id">
                    <div>
                        <div class="col-caption">
                            <div class="button-fav visible-xs"
                                 @click="toggleWishlist(trip.id, 'trip')"
                                 :class="{ on: inWishlist(trip.id) }"
                                 aria-label="Add to Wishlist">
                            </div>

                            <div class="sub-text">
                                TRIP CODE : {{trip.trip_code}} &nbsp;&nbsp;&nbsp;
                                TRIP TIMING : {{trip.month | monthToString}}
                            </div>

                            <div class="heading4 heading -single-trip-heading ">
                                {{trip.title}}
                                <div class="button-fav hidden-xs"
                                     @click="toggleWishlist(trip.id, 'trip')"
                                     :class="{ on: inWishlist(trip.id) }"
                                     aria-label="Add to Wishlist">
                                </div>
                            </div>

                            <p class="sub-text">
                                {{ trip.lodge_title }}
                            </p>

                            <!--<div class="trip-content">-->
                                <!--{{trip.content}}-->
                            <!--</div>-->
                        </div>

                        <!--TODO make the stats module in SearchTripCard.vue reusable and put here? It's the same-->
                        <div class="stats" :class="{'-show-4': trip.consrating >= 7}">
                            <info-container classes="col-price" :value="trip.num_nights ? trip.num_nights : '0'">
                                <template slot="header">
                                    <div class="sub-text">TRIP <br/>NIGHTS</div>
                                </template>

                                <template slot="popup-header">
                                    <p>Trip nights</p>
                                </template>

                                <template slot="popup-text">
                                    <p>This number represents the number of nights on this trip, but does not include any nights needed in transit when traveling to and from Africa.</p>
                                </template>
                            </info-container>

                            <div class="col-price">
                                <price :price="trip.price">
                                    <template slot="header">
                                         <span class="sub-text">
                                            GUIDE PRICE <br/>{{ selectedCurrency }} PP
                                         </span>
                                    </template>

                                    <template slot="popup-header">
                                        Trip guide price
                                    </template>

                                    <template slot="popup-content">
                                        <span v-if="month <= 0">This number represents the approximate price per person in any month to undertake this trip. </span>
                                        <span v-else>This number represents the approximate price per person in {{ GlobalVars.MONTHS[month - 1] }} to undertake this trip. </span>

                                        It includes the cost of accommodation, the usual meals, drinks and activities, transport costs and park fees.
                                        On top of this guide price you will need to consider potentially significant additional costs such as long-haul
                                        flights to Africa, visas, tips, insurance and any expensive optional
                                        extras that you choose to include, such as balloon safari and diving.
                                    </template>
                                </price>
                            </div>

                            <info-container classes="col-rating" :value="trip.rating ? trip.rating : '0.0'">
                                <template slot="header">
                                    <div class="sub-text">TRIP <br/>RATING</div>
                                </template>

                                <template slot="popup-header">
                                    <p>Trip rating</p>
                                </template>

                                <template slot="popup-text">
                                    <p>
                                        This number represents an indication of the overall quality of a trip.
                                        It is calculated from a weighted average of the overall ratings for each of the
                                        lodges in the trip during
                                        <span v-if="month <= 0"> any month,</span>
                                        <span v-else>{{ GlobalVars.MONTHS[month - 1] }},</span> which take into account all the various
                                        component ratings of each property, their locations, builds, styles, service
                                        levels and experiences.
                                    </p>
                                </template>
                            </info-container>

                            <info-container classes="col-rating" v-if="trip.consrating >= 7" :value="trip.consrating ? trip.consrating : '0.0'">
                                <template slot="header">
                                    <div class="sub-text">conservation <br/>RATING</div>
                                </template>

                                <template slot="popup-header">
                                    <p>Trip conservation rating</p>
                                </template>

                                <template slot="popup-text">
                                    <p>
                                        The conservation rating is an indication of how much a particular lodge needs your visit in
                                        <span v-if="month <= 0">any month</span>
                                        <span v-else>{{ GlobalVars.MONTHS[month - 1] }}</span>
                                        to further the protection of their landscapes and wildlife. We recommend that you try to include at least one lodge with a high conservation rating into your trip if you can. This trip conservation rating is therefore the peak value achieved by any of the lodges in your trip.
                                    </p>
                                </template>
                            </info-container>
                        </div>
                    </div>
                    <div class="scroll-btn">
                        <button-external-presentational
                                @click="scrollTo"
                                icon="ion-ios-arrow-thin-down"
                                classes="scroll-down-arrow">
                        </button-external-presentational>
                    </div>
                </div>
            </div>

            <hr>

            <div class="container -full-page summary" ref="summary" v-waypoint="{ active: !isMobile, callback: recenterMap, options: intersectionOptions }">
                <div class="yellow-bar summary"></div>
                <div class="heading2 heading">Overview</div>

                <div v-if="this.$store.state.singleTrip.trip.sections"
                     v-html="this.$store.state.singleTrip.trip.sections.summary.content">
                </div>
            </div>

            <hr>

            <div v-for="location in trip.locations">
                <div v-waypoint="{ active: !isMobile, callback: onWaypoint, options: intersectionOptions }">
                    <div :data-id="location.id"
                         :data-lat="location.lodges ? location.lodges[0].lat : location.lat"
                         :data-lng="location.lodges ? location.lodges[0].lng : location.lng"
                         :data-type="location.type"
                         class="component-card -sr-single-trip -sr-trip -full-page -sr-lodge -mobile-flip"
                         :class="{'-transit-location' : location.type === GlobalVars.TRANSIT_LOCATION}">
                        <div>
                            <p class="sub-text -highlight visible-xs"><span class="yellow-bar"></span>{{ location.location_label }}</p>

                            <div class="heading2 heading visible-xs">
                                {{location.title}}
                            </div>

                            <div class="button-fav visible-xs"
                                 v-if="location.type != GlobalVars.TRANSIT_LOCATION && location.type > 0"
                                 v-on:click="toggleWishlist(location.id, 'location')"
                                 v-bind:class="{ on: inWishlist(location.id) }"
                                 aria-label="Add to Wishlist">
                            </div>

                            <!--This reserves space for the slider on mobile-->
                            <img v-if="location.type != GlobalVars.TRANSIT_LOCATION && location.type > 0" src="/assets/img/placeholder.png" alt="Placeholder" class="visible-xs img-responsive mob-placeholder"/>

                            <p class="sub-text -highlight hidden-xs"><span class="yellow-bar"></span>{{ location.location_label }}</p>

                            <div class="col-caption">
                                <div class="heading2 heading hidden-xs">
                                    {{location.title}}

                                    <!--<p class="sub-text" v-if="location.lodges">-->
                                        <!--&lt;!&ndash;DO NOT RE FORMAT&ndash;&gt;-->
                                        <!--<span v-for="(lodge, index) in location.lodges"><template v-if="index > 0">,&nbsp;</template>{{lodge.title}}</span>-->
                                    <!--</p>-->

                                    <div class="button-fav hidden-xs"
                                         v-if="location.type != GlobalVars.TRANSIT_LOCATION && location.type > 0"
                                         v-on:click="toggleWishlist(location.id, 'location')"
                                         v-bind:class="{ on: inWishlist(location.id) }"
                                         aria-label="Add to Wishlist">
                                    </div>
                                </div>

                                <div class="content" v-html="location.content"></div>
                            </div>

                            <div v-if="location.type >= 0">
                                <public-search-image-slider type="location" v-if="location.images" :cover-image="location.images.sizes.low" :caption="location.images.caption" :id="location.id"></public-search-image-slider>

                                <div class="sr-footer" v-if="location.type != GlobalVars.TRANSIT_LOCATION && location.type > 0">
                                    <div class="btn-container">
                                        <!--<button-internal-presentational :router_object="{ name: 'single-location', params: location.vue_router_params['data'] }" classes="sr-cta -arrow">-->
                                            <!--<div>Go to Location</div>-->
                                            <!--<i class="ion-ios-arrow-thin-right"></i>-->
                                        <!--</button-internal-presentational>-->

                                        <button-internal-presentational classes="sr-cta -arrow" :router_object="{ name: 'single-location', params: location.vue_route_params }">
                                            <div>Go to location</div>
                                            <i class="ion-ios-arrow-thin-right"></i>
                                        </button-internal-presentational>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <div v-if="location.lodges">
                    <div v-waypoint="{ active: !isMobile, callback: onWaypoint, options: intersectionOptions }" v-for="lodge in location.lodges" :key="lodge.id">
                        <lodge-select :locationId="location.id" :trip="trip" :lodge="lodge" :month="trip.month"></lodge-select>
                    </div>

                </div>
            </div>

            <!--<div class="seasonality -full-page"-->
                 <!--v-waypoint="{ active: true, callback: recenterMap, options: intersectionOptions }">-->
                <!--<div>-->
                    <!--<div class="container">-->
                        <!--<div class="heading2 heading">Seasonality</div>-->
                    <!--</div>-->

                    <!--<div class="chart-img">-->
                        <!--<img src="/assets/img/temp/season-chart.png" alt="">-->
                    <!--</div>-->

                    <!--<div class="container">-->
                        <!--<div v-if="this.$store.state.singleTrip.trip.sections"-->
                             <!--v-html="this.$store.state.singleTrip.trip.sections.seasonality.content">-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->

            <!--<hr>-->

            <div class="price-summary container -full-page"
                 v-waypoint="{ active: !isMobile, callback: recenterMap, options: intersectionOptions }"
                 data-type="summary">

                <div class="yellow-bar summary"></div>

                <div class="heading2 heading summary">Price Summary</div>

                <!--<div v-if="this.$store.state.singleTrip.trip.sections"-->
                     <!--v-html="this.$store.state.singleTrip.trip.sections.priceSummary.content">-->
                <!--</div>-->

                <div v-html="trip.sections.price.content_before"></div>

                <lodge-price-table :trip="trip"></lodge-price-table>

                <br>
                <br>

                <div v-html="trip.sections.price.content_after"></div>

            </div>

            <hr>

            <div class="container -full-page wishlist">
                <div class="inner wishlist-header">
                    <div class="yellow-bar summary"></div>
                    <h2 class="heading">Wishlist</h2>
                </div>

                <p>If you like the look for this trip, then you can add it to your wishlist, in order that you can compare it with others or use it as part of an inquiry whenever you are ready.</p>

                <div class="suggested -trip-page">
                    <ul class="terms">
                        <li>
                            <a @click="toggleWishlist(trip.id, 'trip')"
                               :class="{active: inWishlist(trip.id)}"
                               class="wishlist-add">

                                <div class="icon-container -size-4">
                                    <i class="ion-ios-heart"></i>
                                </div>

                                <span v-if="!inWishlist(trip.id)">Add trip {{trip.trip_code}} to your wishlist</span>

                                <span v-else>Remove trip {{trip.trip_code}} from your wishlist</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <hr>

            <div class="container -full-page wishlist">
                <div class="inner wishlist-header">
                    <div class="yellow-bar summary"></div>
                    <h2 class="heading">Trip quotations</h2>
                </div>

                <p>This presentation is very much a sample trip, at some point we need to get you a proper itinerary and quotation. If you feel that you might be close to knowing what you want to do in Africa, then you can request a proper quotation for this specific trip or any other variant you have in your wishlist or would like to describe to us. It’s really no trouble, we have a very efficient safari building system and there is no obligation on your part.</p>

                <div class="buttons">
                    <button-internal-presentational
                            :router_object="{name: 'quote'}"
                            classes="arrow-link"
                            icon="ion-ios-arrow-thin-right">
                        <div>Get a quotation</div>
                    </button-internal-presentational>
                </div>
            </div>

            <hr>

            <div class="container -full-page wishlist">
                <div class="inner wishlist-header">
                    <div class="yellow-bar summary"></div>
                    <h2 class="heading">Get in touch</h2>
                </div>

                <p>Hopefully by now you will be starting to think that we might be the right people to help organise your trip. It’s never too soon to get in touch, we are always keen to help out, no matter how formulative or advanced your plans may be.</p>

                <div class="suggested alternative">
                    <ul class="terms">
                        <li>
                            <button-internal-presentational
                                    icon="ion-ios-arrow-thin-right -arrow-right"
                                    :router_object="{ name: 'contact-call' }"
                                    v-on:btn-internal-clicked="googleAnalytics.fireEvent('Call', 'Click Contact Call Us', $route.path)">
                                Call us
                            </button-internal-presentational>
                        </li>
                        <li>
                            <button-internal-presentational
                                    icon="ion-ios-arrow-thin-right -arrow-right"
                                    :router_object="{ name: 'call-back' }"
                                    v-on:btn-internal-clicked="googleAnalytics.fireEvent('Call Back', 'Click Contact Call Back', $route.path)">
                                Get us to call you
                            </button-internal-presentational>
                        </li>
                        <li>
                            <button-internal-presentational
                                    icon="ion-ios-arrow-thin-right -arrow-right"
                                    :router_object="{ name: 'quote' }"
                                    v-on:btn-internal-clicked="googleAnalytics.fireEvent('Quote', 'Click Contact Inquiry', $route.path)">
                                Make a travel inquiry
                            </button-internal-presentational>
                        </li>
                        <!--<li>-->
                            <!--<button-internal-presentational-->
                                    <!--icon="ion-ios-arrow-thin-right -arrow-right"-->
                                    <!--:router_object="{ name: 'email' }"-->
                                    <!--v-on:btn-internal-clicked="googleAnalytics.fireEvent('Email', 'Click Contact Email', $route.path)">-->
                                <!--Send us an email-->
                            <!--</button-internal-presentational>-->
                        <!--</li>-->
                    </ul>
                </div>
            </div>
        </div>

        <section class="right-side map-container -half-page-map" v-if="!isMobile">
            <map-presentational v-if="!oldDeviceCheck" :args="mapArgs" :classes="['vignette', 'vignette-half-map', 'vignette-map']"></map-presentational>
        </section>
        <!-- <transition-wipe/> -->
    </div>
</template>

<script>
    import GlobalVars from '../../global/GlobalVars';
    import pageIsReadyMixin from '../../mixins';
    import filters from '../../filters';
    import { mapState } from 'vuex';

    export default {
        name: "SingleTrip",
        mixins: [pageIsReadyMixin],
        data(){
            return {
                selectedItems: [],
                intersectionOptions: {
                    root: null,
                    rootMargin: "0px",
                    threshold: [0.5],
                    currentId: null
                },
                lodgeNumber: 0,
                loaded: false,
                GlobalVars: GlobalVars
            }
        },
        methods: {
            scrollTo() {
                window.pnpHelper.scroll.scrollTo(this.$refs.summary);
            },
            onWaypoint({ direction, el }) {
                let element = $(el).find('.component-card');
                let id = element.attr('data-id');
                let lat = element.attr('data-lat') ? element.attr('data-lat') : '';
                let lng = element.attr('data-lng') ? element.attr('data-lng') : '';
                let type = element.attr('data-type') ? element.attr('data-type') : '';

                if(this.intersectionOptions.currentId !== id && id === this.trip.id && direction !== undefined) {
                    this.centerMap();
                } else if(this.intersectionOptions.currentId !== id && direction !== undefined) {

                    let zoom;
                    let animationDuration;

                    // Higher the number close the zoom
                    if(type == GlobalVars.LODGE || type === 'lodge') {
                        zoom = 11;
                    } else if(type == GlobalVars.LOCATION || type === 'location') {
                        zoom = 9;
                    } else if(type == GlobalVars.TRANSIT_LOCATION) {
                        zoom = 7;
                        animationDuration = 5000;
                    } else {
                        zoom = 7;
                    }

                    pnpHelper.map.centerOnCoords([lng, lat], zoom, animationDuration);
                }

                this.intersectionOptions.currentId = id;
            },
            recenterMap({ direction, el }){
                if(direction != undefined && this.intersectionOptions.currentId !== 0) {
                   this.centerMap();
                    this.intersectionOptions.currentId = 0;
                }
            },
            centerMap(){
                if(pnpHelper.map) {
                    let bounds = new mapboxgl.LngLatBounds();

                    for (let i in this.trip.geographical_summary) {
                        bounds.extend([this.trip.geographical_summary[i].lng, this.trip.geographical_summary[i].lat]);
                    }

                    pnpHelper.map.fitBounds(bounds, {
                        padding: {
                            left: 175,
                            bottom: 175,
                            top: 175,
                            right: 175
                        },
                        duration: 4000
                    });
                }
            },
            lodgeBack(){
                if(this.lodgeNumber > 0) {
                    this.lodgeNumber--;
                }
            },
            lodgeForward(){
                this.lodgeNumber += 1;
            },
            toggleWishlist(id, type){
                let payload = {
                    id: id,
                    type: type ? type : this.type
                };

                if (this.inWishlist(id)) {
                    this.$store.commit(window.store.REMOVE_WISHLIST_ITEM, payload);
                } else {
                    this.$store.dispatch(window.store.SET_WISHLIST_ITEM_BY_ID, payload);
                }
            },
            inWishlist(id){
                return this.wishlist.some((el) => {
                    return el.id == id;
                });
            }
        },
        computed: {
            mapArgs() {
                return [{
                    lat: '-7.44713873',
                    lng: '35.23497118',
                    image: 'https://atrimages.azureedge.net/cdn/05explore/locations-and-lodges/africa/tanzania/s/ruaha/kichaka-ruaha/0/stills/02location/01TKKR-IM0201-kichaka-ruaha-1024.jpg',
                    id: 927,
                    title: 'Trip Lodge',
                    price: '2,000',
                    rating: '11/10',
                }];
            },
            trip(){
                // return {
                //     ...this.$store.state.singleTrip.trip,
                // };

                let trip = this.$store.state.singleTrip.trip;

                let {numnights, price, consrating, rating } = this.$route.params;

                return {
                    ...trip,
                    price: price ? price : trip.price,
                    consrating: consrating ? consrating : trip.consrating,
                    rating: rating ? rating : trip.rating,
                    num_nights: numnights ? numnights : trip.num_nights
                };
            },
            isMobile(){
                return pnpHelper.bootstrap.isXs();
            },
            month(){
              return this.trip.month;
            },
            oldDeviceCheck() {
                return false;
            },
            ...mapState({
                searchType: state => state.search.criteria.params.type,
                wishlist: state => state.wishlist.selected,
                searchLocation: state => state.search.criteria.params.location.name,
                selectedCurrency:  state => state.user.user.selected_currency
            })
        },
        mounted(){
            let data = {
                slug: this.$route.params.slug,
            };
            if(this.$route.query.experience!=undefined){
                data['experience'] = this.$route.query.experience;
            }
            this.$store.dispatch('singleTrip/FETCH_AND_SET_TRIP', data).then(() => {
                this.$store.commit(window.store.SET_MAP, this.trip, {root: true});

                this.loaded = true;
                this.declarePageIsReady();
            }).catch(() => {
                this.$router.push({ name: 'page404' });
            });

            if(this.oldDeviceCheck){
                $('.header-container').addClass('-scroll-100');
            }
        },
        created(){
            this.$store.commit(window.store.FETCH_AND_SET_WISHLIST);
        }
    }
</script>