import VueRouter from 'vue-router';
import InternalLinkWatcher from './global/InternalLinkWatcher';

Vue.use(VueRouter);

require('./constants');
require('./global/Components');

let routes = eval(ROUTES);
/** ROUTES is pulled in from external routes.js file in the assets folder */
routes.push({path: "*", component: Vue.component('page404'), name: 'page404'});

routes.push({
    "path": "/old/safari-lodges/:parent_1?/:parent_2?/:parent_3?/:parent_4?/:parent_5?/:slug",
    "component": Vue.component("single-lodge"),
    "name": "single-old-lodge",
    "meta": {
        "type": "lodge",
        "page_title": "Safari to {{ NAME }} with Africa Travel Resource",
        "meta_description": "Comprehensive imagery, description and best prices for {{ NAME }}. Get in touch for expert advice and truly authentic tailormade safari trips.",
        "component_name_body_class": "single-lodge"
    }
});

routes.push({
    "path": "/old/safari-locations/:parent_1?/:parent_2?/:parent_3?/:parent_4?/:parent_5?/:slug",
    "component": Vue.component("single-location"),
    "name": "single-old-location",
    "meta": {
        "type": "location",
        "page_title": "{{ NAME }} safaris with Africa Travel Resource",
        "meta_description": "Explore all the best safari options in {{ NAME }}. Detailed information on all the best camps and lodges across Africa. Get in touch for expert advice and truly authentic tailormade trips.",
        "component_name_body_class": "single-location"
    }
});

routes.push({
    "path": "/old/safari-experiences/:parent_1?/:parent_2?/:parent_3?/:parent_4?/:parent_5?/:slug",
    "component": Vue.component("single-experience"),
    "name": "single-old-experience",
    "meta": {
        "type": "experience",
        "page_title": "The best locations for {{ NAME }} with Africa Travel Resource",
        "meta_description": "Explore all the best locations and lodges for {{ NAME }} in Africa. Get in touch for expert advice and truly authentic tailormade trips.",
        "component_name_body_class": "single-experience"
    }
});

routes.push({
    "path": "/safari-explore/finder/articles",
    "component": Vue.component("explore"),
    "children": [{
        "path": "/safari-explore/finder/articles",
        "meta": {
            "type": "article",
            "location": "0",
            "lname": "Africa",
            "experience": "3",
            "ename": "Any Experience",
            "eprimary": "true",
            "month": "0",
            "sort": "",
            "sname": "",
            "duration": "12",
            "tripbto": "10000",
            "tripbfrom": "0",
            "bto": "5000",
            "bfrom": "0",
            "page_title": "Articles of Africa",
            "meta_description": "Advanced search capability for finding all the best safari resources and travel advice for Africa. Get in touch for expert advice and truly authentic tailormade trips.",
            "component_name_body_class": "finder",
            "cssClass": "dark-nav"
        },
        "component": Vue.component("public-search"),
        "name": "safari-articles"
    }]
});

routes.push({
    "path": "/safari-article/:parent_1?/:parent_2?/:parent_3?/:parent_4?/:parent_5?/:slug",
    "component": Vue.component("single-article"),
    "name": "single-article",
    "meta": {
        "page_title": "Safari to {{ NAME }} with Africa Travel Resource",
        "meta_description": "Comprehensive imagery, description and best prices for {{ NAME }}. Get in touch for expert advice and truly authentic tailormade safari trips.",
        "component_name_body_class": "single-article"
    }
});

routes.push({
    "path": "/safari-explore/finder/trip-collections",
    "component": Vue.component("explore"),
    "children": [{
        "path": "/safari-explore/finder/trip-collections",
        "meta": {
            "type": "trip_collection",
            "location": "0",
            "lname": "Africa",
            "experience": "3",
            "ename": "Any Experience",
            "eprimary": "true",
            "month": "0",
            "sort": "",
            "sname": "",
            "duration": "12",
            "tripbto": "10000",
            "tripbfrom": "0",
            "bto": "5000",
            "bfrom": "0",
            "page_title": "Articles of Africa",
            "meta_description": "Advanced search capability for finding all the best safari resources and travel advice for Africa. Get in touch for expert advice and truly authentic tailormade trips.",
            "component_name_body_class": "finder",
            "cssClass": "dark-nav"
        },
        "component": Vue.component("public-search"),
        "name": "safari-trip-collection"
    }]
});

routes.push({
    "path": "/safari-trip-intelligence/:parent_1?/:parent_2?/:parent_3?/:parent_4?/:parent_5?/:slug",
    "component": Vue.component("single-trip-intelligence"),
    "name": "single-trip-intelligence",
    "meta": {
        "page_title": "Safari to {{ NAME }} with Africa Travel Resource",
        "meta_description": "Comprehensive imagery, description and best prices for {{ NAME }}. Get in touch for expert advice and truly authentic tailormade safari trips.",
        "component_name_body_class": "single-trip-intelligence"
    }
});

routes.push({
    "path": "/contact/get-in-touch",
    "component": Vue.component("get-in-touch"),
    "name": "get-in-touch",
    "props": {
        "fullVersion": false
    }
});
// routes.push({
//     "path": "/contact/inquire",
//     "component": Vue.component("get-in-touch"),
//     "name": "inquire",
//     "props": {
//         "fullVersion": false
//     }
// });


const router = new VueRouter({
    mode: 'history',
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        window.savedPosition = savedPosition;
    }
});

new InternalLinkWatcher(router);

/**
 * This is needed to allow jQuery events (specifically
 * popstate) to be fired before the $route object is updated
 * without this $route is updated first which causes the wrong
 * transition to be used
 */
router.beforeEach((to, from, next) => {
    window.bus.$emit('close_global_modal');

    if (from.path != to.path || to.hash == '') {
        setTimeout(next);
    }
});

export default router;
