<script>
    import Section from '../presentational/Section.vue';

    export default {
        props: ['section', 'classes', 'type', 'edit', 'item_title', 'firstVideo'],
        render(createElement) {
            var props = {...this.section};

            props.firstVideo = this.firstVideo;
            props.classes = this.classes;
            props.type = this.type;
            props.edit = this.edit;
            props.item_title = this.item_title;
            props.actions = this.section.actions;
            props.slides = this.section.slides.data;
            props.buttons = this.section.buttons;
            props._id = this.section.id;

            return createElement(Section, { props: props });
        }
    }
</script>