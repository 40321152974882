<template>
    <div class="quote-page showing-article-half split-page">
        
            <section class="left-side article-images-container -in" v-if="!isMobile">
                <template>
                    <div class="article-images -showing-images -in" >
                        <transition-group name="fade" mode="in-out" duration="2000" tag="div">
                            <article-image-presentational
                                    :key="currentArticle.image"
                                    :image="currentArticle.image"
                                    :title="currentArticle.title"
                                    :content="currentArticle.content"
                                    :route_params="currentArticle.vue_route_params">
                                    <template>
                                        <div class="reviews-content">
                                            <div class="review-image-container">
                                                <img src="/assets/images/stars-group.png" width="132" height="81" alt="google reviews" class="review-image">
                                            </div>
                                            <div class="reviews">
                                                <div class="review">
                                                    <p class="review-content"><span class="review-text"><em>“ATR are experts in their field and I wouldn't trust anyone else.”</em></span> <span class="reviewer-name"><em>Tara Stewart</em></span> </p>
                                                </div>
                                                <div class="review">
                                                    <p class="review-content"><span class="review-text"><em>“ATR truly stands like a giraffe, head and shoulders above all others.”</em></span> <span class="reviewer-name"><em>Linda Goddard</em></span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                            </article-image-presentational>
                        </transition-group>
                    </div>
                </template>
            </section>

            <div class="right-side">
                <div class="nav-veil -right"></div>
                <div class="form-loading" v-show="loading">
                    <div class="loading-container">
                        <p>Just a moment<span v-show="!finalPage">, there's one more really important page!</span></p>
                        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                </div>
                <multi-step-form
                    id="enquiry-form"
                    name="enquiry-form"
                    :step-data="stepData"
                    @onComplete="onComplete"
                    @hsSubmit="hsSubmit"
                    @finalSubmit="finalSubmit"
                    @validateStep="validate"
                    @onNext="updateImage"
                    
                >
                    <template slot="header" slot-scope="{step, progress, id}">
                        <header-progress :title="step.step_title" :progress="progress" :id="id" />
                    </template>
                    <!-- <template slot="step0" slot-scope="{currentStep, stepIndex}">
                        <get-in-touch-step @update-data="updateFormData" @update-steps="updateSteps" :formContent="formData" :selected="getInTouch" :step="currentStep" :stepIndex="stepIndex"/>
                    </template>
                    <template slot="step1" slot-scope="{currentStep, stepIndex}">
                        <call-method-step @update-steps="updateSteps" :selected="callMethod" :step="currentStep" :step_index="stepIndex" />
                    </template>
                    <template slot="step2" slot-scope="{currentStep, stepIndex}">
                        <call-intro-step />
                    </template>
                    <template slot="step3" slot-scope="{currentStep, stepIndex}">
                        <call-now-step />
                    </template> -->
                    <template slot="step0" slot-scope="{currentStep, stepIndex}">
                        <choose-country-step @update-data="updateFormData" @update-validation="updateStepValidation" :formContent="formData" :step="currentStep" :stepIndex="stepIndex" :checkValidation="validateStep === 0" >
                            <template slot="additionalText" v-if="fullVersion">
                                <div class="text-section" style="margin-top:90px;">
                                    <p>Please feel free to select as many countries as you like. You can decide how you might want to combine them into one or more trips later on.</p>
                                </div>
                            </template> 
                        </choose-country-step>
                    </template>
                    <template slot="step1" slot-scope="{currentStep, stepIndex}">
                        <select-dates-step @update-data="updateFormData" @update-validation="updateStepValidation" :formContent="formData" :step="currentStep" :stepIndex="stepIndex" :checkValidation="validateStep === 1">
                            <template slot="additionalText" v-if="fullVersion">
                                <div class="text-section" style="margin-top:20px;">
                                    <p>With safari travel being so very seasonal, it is critical to carefully tune the dates and experiences. If you could select your most likely travel window here, a little later you will be able to decribe your availability in more detail.</p>
                                </div>
                            </template> 
                        </select-dates-step>
                    </template>
                    <template slot="step2" slot-scope="{currentStep, stepIndex}">
                        <duration-step @update-data="updateFormData" @update-validation="updateStepValidation" :formContent="formData" :step="currentStep" :stepIndex="stepIndex" :checkValidation="validateStep === 2">
                            <template slot="additionalText" v-if="fullVersion">
                                <div class="text-section" style="margin-top:20px;">
                                    <p>Please consider all nights away from home, including any overnight flights. An approximation will be fine for now, you can fine tune it as the trip develops.</p>
                                    <p>If you only want to book a part of your trip with us, the choose the closest duration for that section only.</p>
                                </div>
                            </template> 
                        </duration-step>
                    </template>
                    <template slot="step3" slot-scope="{currentStep, stepIndex}">
                        <guests-step @update-data="updateFormData" @update-validation="updateStepValidation" :formContent="formData" :step="currentStep" :stepIndex="stepIndex" :checkValidation="validateStep === 3" >
                            <template slot="additionalText" v-if="fullVersion">
                                <div class="text-section" style="margin-top:20px;">
                                    <p>Please enter your best guess of the number of people that will be travelling in your group. Don't worry if you're not yet sure of who can make it, you can tune this later.</p>
                                    <p>Note that child ages should be at the most likely time of travel.</p>
                                </div>
                            </template> 
                        </guests-step>
                    </template>
                    <template slot="step4" slot-scope="{currentStep, stepIndex}">
                        <budget-step @update-data="updateFormData" @update-validation="updateStepValidation" :formContent="formData" :step="currentStep" :stepIndex="stepIndex" :checkValidation="validateStep === 4">
                            <template slot="additionalText" v-if="fullVersion">
                                <div class="text-section" style="margin-top:20px;">
                                    <p>You can assume that this budget includes all transport, accommodation, meals and activities, but excludes your long-haul flights and local tips.</p>
 
                                    <p>Here are some quick guidelines...</p>
                                    
                                    <p><strong>2500 :</strong> Authentic shorter trips, stripped down.</p>
                                    <p><strong>5000 :</strong> Authentic trips.</p>
                                    <p><strong>7500 :</strong> Authentic trips with treats.</p>
                                    <p><strong>10000 :</strong> Blend of authentic and luxury.</p>
                                    <p><strong>15000 :</strong> Luxury trips.</p>
                                </div>
                            </template> 
                        </budget-step>
                    </template>
                    <template slot="step5">
                        <have-we-met-step @update-data="updateFormData" :formContent="formData" />
                    </template>
                    <template slot="step6" slot-scope="{currentStep, stepIndex}">
                        <your-details-step @update-data="updateFormData" @update-validation="updateStepValidation" :formContent="formData" :step="currentStep" :stepIndex="stepIndex" :checkValidation="validateStep === 6" />
                    </template>
                    <!-- <template slot="step11" slot-scope="{currentStep, stepIndex}">
                        <contact-method-step  @update-steps="updateSteps" :selected="contactMethod" :step="currentStep" :step_index="stepIndex"/>
                    </template> -->
                    <template slot="step7">
                        <tell-us-more-step @update-data="updateFormData" :formContent="formData">
                            <template slot="additionalText" v-if="showFullVersion">
                                <p><strong>Please feel free to add lots of notes here.</strong></p>
                                <p>We understand that you may be in the early stages of planning your trip, but there should still be lots of things you can tell us which will be incredibly useful. For example...</p>

                                <ul style="padding-left:14px;">
                                    <li>How outdoorsy are you?</li>
                                    <li>What's the best trip you've ever been on?</li>
                                    <li>What animals or locations do you most want to see?</li>
                                    <li>Do you like simple accommodation or upmarket hotels?</li>
                                    <li>Do you have any unusual diets or other needs?</li>
                                </ul>
 <br>
                                <p>We always appreciate an enquiry with loads of notes, it really helps. It's the ones with no comments here that are really challenging for us to respond to.</p>
                            </template>
                        </tell-us-more-step>
                    </template>
                    <template slot="step8">
                        <final-step>
                            <div style="margin-bottom:30px;" >
                            <p>We should now have enough information to be able to come back to you within 1-2 working days, with a range of tailor-made sample trips. From there you will be able to adjust it until we are all completely confident that we have found the perfect solution for you.</p>
<p>If you'd like to speed the process up, give us a call now. We are available to talk to on the phone Monday to Friday from 0800-1800hrs London / 4am-1pm New York / 1am- 10am hrs Los Angeles.</p>
<p>USA &amp; Canada Toll Free : 888-487 5418<br>
UK &amp; Europe : +44 (0)1306 880770<br>
Australia Toll Free : +61 800 821 832
</p>
                            </div>
                        </final-step>
                    </template>
                    <!-- <template slot="step14">
                        <your-details-step @update-data="updateFormData" :formContent="formData" />
                    </template>
                    <template slot="step15" slot-scope="{currentStep, stepIndex}">
                        <call-request-details-step @update-data="updateFormData" @update-validation="updateStepValidation" :formContent="formData" :step="currentStep" :stepIndex="stepIndex" :checkValidation="validateStep === 15" />
                    </template>
                    <template slot="step16">
                        <tell-us-more-step @update-data="updateFormData" :formContent="formData">
                            <template slot="content">
                                <p>Please feel free to add any further information, either relating to our upcoming call or
to possibilities for the trip. You can write as much or as little as you wish, we can get 
to the details on the call.</p>
                            </template>
                        </tell-us-more-step>
                    </template>
                    <template slot="step17">
                        <final-step>
                            <div style="margin-bottom:30px;" >
                            <p>We very much look forward to chatting.</p>

<p>Don't worry if the time turns out to be inconvenient, you can simply ignore the call and 
we will either try again later or reach out by email to arrange another time.</p>
<p>If you would like to contact us in the meantime, either to add thoughts or change the 
timing of the call, the please email us on <a href="mailto:salesoffice@africatravelresource.com" style="color: #676767; text-decoration: underline;">salesoffice@africatravelresource.com</a></p>
                            </div>
                        </final-step>
                    </template>
                    <template slot="step18">
                        <trade-enquiry-step />
                    </template> -->
                </multi-step-form>
            </div>
            <!-- <transition-wipe/> -->
    </div>
</template>

<script>

    import LazyLoader from '../../global/LazyLoader';
    import pageIsReadyMixin from '../../mixins';
    import AtrStorage from './../..//global/AtrStorage';

    export default {
        mixins: [pageIsReadyMixin],
        props: ["fullVersion"],
        data(){
            return {
                cookieName: 'atr_form_data',
                stepData:[],
                loading: false,
                finalPage: false,

                validateStep: null,

                errors: {},

                currentArticle: {
                    image: "/assets/images/yellow-canyon.jpg",
                    content:"",
                    title:"",
                    url: undefined
                },

                getInTouch: 'Webform',
                callMethod: 'call_method-arrange',
                contactMethod: 'contact_method-email',

                // Set defaults to ensure HS leadscoring works
                formData: {
                    'method_of_enquiry': 'Webform',
                    'tell_us_more_about_your_trip___character_count': 0, 
                    'preferred_call_method': 'Yes',
                },

                contactID: '',

                moreDetailsShow: false,
            }
        },
        methods: { 
            hsSubmit(){
                this.loading = true;
                const observer = new PerformanceObserver((list) => {
                    list.getEntries().forEach((entry) => {
                        if(!entry.name.includes('/collected-forms/submit/form')) {
                            return;
                        }

                        if(typeof entry.responseEnd !== "undefined" && entry.responseEnd > 0) {
                            setTimeout(() => {
                                this.upsertContact();
                            }, 1750);
                        }
                    });
                });

                observer.observe({ type: "resource", buffered: true });
            },
            finalSubmit(){
                this.finalPage = true;
            },
            onComplete(){
                window.location.href = '/';
            },
            updateSteps(e){
                let currentSteps = this.stepData;
                currentSteps.forEach((step, index) => {
                    if(e.value.includes(index)){
                        currentSteps[index]['step_skip'] = false;
                    } else {
                        currentSteps[index]['step_skip'] = true;
                    }
                });

                this[`${e.type}`] = e.key;

                this.stepData = currentSteps;
            },
            updateStepValidation(e){
                this.stepData[e.step]['step_valid'] = e.value;
            },
            updateFormData(data){
                if(data.value === null){
                    delete this.formData[data.key];
                } else {
                    this.formData[data.key] = data.value;
                }

                this.setCookie(this.cookieName, JSON.stringify(this.formData), 30);

            },
            validate(stepIndex){
                this.validateStep = stepIndex;
                window.dispatchEvent(new CustomEvent('validateStep', { detail: stepIndex }));
            },
            getCookie(name){
                let cookieName = name + '=';

                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');

                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(cookieName) == 0) {
                        return c.substring(cookieName.length, c.length);
                    }
                }
                return '';
            },
            setCookie(name, value, exdays){
                const d = new Date();

                d.setTime(d.getTime() + (exdays*24*60*60*1000));

                let expires = 'expires='+ d.toUTCString();
                document.cookie = name + '=' + value + ';' + expires + ';path=/';

            },
            upsertContact(){

                if(this.formData.email === undefined){
                    return null;
                }

                const formattedData = this.formatFormData();

                let promise = axios.patch(`${process.env.VUE_APP_HUBSPOT_API_URL}hubspot/contact/update/${this.formData.email}`, formattedData);

                promise.then((response) => {
                    console.log(response);
                }).then(() => {
                    this.setCookie(this.cookieName, JSON.stringify({
                        'method_of_enquiry': 'Webform',
                    }), 1);
                    axios.post(window.API_URL + 'contact/get-in-touch', {...formattedData, submission_type: 'get-in-touch'});
                    window.GoogleAnalytics.fireEvent('Quote', 'Send Request', this.$route.path);
                    window.GoogleAnalytics.pushDataLayer({
                      "event": "ec_form_submit",
                      "email": this.formData.email,
                      "phone_number": this.formData.telephone,
                    });
                    this.loading = false;
                }).catch((error) => {
                    console.log(error);
                });

                // let promise = axios.get(`${process.env.VUE_APP_HUBSPOT_API_URL}hubspot/contact/${this.formData.email}`);

                // promise.then((response) => {
                //     const data = response.data.results;

                //     if(data.length > 0){
                //         return data;
                //     } else {
                //         return null;
                //     }
                // }).then(response => {
                //     if(typeof response === 'undefined' || response === null || response === ''){
                //         let promise = axios.post(`${process.env.VUE_APP_HUBSPOT_API_URL}hubspot/contact/create`, formattedData);

                //         promise.then((response) => {
                //             console.log(response);
                //         }).catch((error) => {
                //             console.log(error);
                //         });
                //     } else {
                //         let promise = axios.patch(`${process.env.VUE_APP_HUBSPOT_API_URL}hubspot/contact/update/${response[0].id}`, formattedData);

                //         promise.then((response) => {
                //             console.log(response);
                //         }).catch((error) => {
                //             console.log(error);
                //         });
                //     }
                // }).then(() => {
                //     this.setCookie(this.cookieName, JSON.stringify({
                //         'method_of_enquiry': 'Webform',
                //     }), 1);
                //     axios.post(window.API_URL + 'contact/get-in-touch', {...formattedData, submission_type: 'get-in-touch'});
                //     window.GoogleAnalytics.fireEvent('Quote', 'Send Request', this.$route.path);
                // }).catch((error) => {
                //     console.log(error);
                // });
                
            },
            formatFormData(){
                let data = this.formData;
                let formattedData = {};

                let countries = '';
                
                
                for(let i = 0; i < data.destinations_of_interest.length; i++){
                    countries += data.destinations_of_interest[i];

                    if(i !== data.destinations_of_interest.length - 1){
                        countries += ';';
                    }
                }
                
                for (const [key, value] of Object.entries(data)) {
                    if(key === 'destinations_of_interest'){
                        formattedData[key] = countries;
                        continue;
                    }
                    formattedData[key] = value;
                }

                return formattedData;
            },
            getGclid(){
                const gclid = new URL(location.href).searchParams.get('gclid');

                if(typeof gclid !== 'undefined' && gclid !== null && gclid !== ''){
                    this.formData['hs_google_click_id'] = gclid;
                }
            },
            updateImage(){
                if(typeof this.formData.destinations_of_interest === "undefined" || this.formData.destinations_of_interest.length === 0){
                    return;
                }

                const currentLocation = this.formData.destinations_of_interest[this.formData.destinations_of_interest.length - 1];

                this.currentArticle = {
                    image: `/assets/images/locations/${currentLocation.replace(' ', '-')}.jpg`,
                    content:"",
                    title:"",
                    url: undefined
                };

            }
        },
        mounted(){
            let formDataCookie = this.getCookie(this.cookieName);

            if(formDataCookie !== ''){
                this.formData = JSON.parse(formDataCookie);
            }

            this.getGclid();

            const steps = [
                {
                    'step_title': 'Where might you like to travel?',
                    'step_valid': false,
                    'step_skip': false,
                    'show_back_button': true,
                    'show_next_button': true,
                    'last_step': false,
                },
                {
                    'step_title': 'When do you want to go?',
                    'step_valid': false,
                    'step_skip': false,
                    'show_back_button': true,
                    'show_next_button': true,
                    'last_step': false,
                },
                {
                    'step_title': 'What is your ideal trip duration?',
                    'step_valid': false,
                    'step_skip': false,
                    'show_back_button': true,
                    'show_next_button': true,
                    'last_step': false,
                },
                {
                    'step_title': 'Who is travelling?',
                    'step_valid': true,
                    'step_skip': false,
                    'show_back_button': true,
                    'show_next_button': true,
                    'last_step': false,
                },
                {
                    'step_title': 'What is your maximum budget per person?',
                    'step_valid': false,
                    'step_skip': false,
                    'show_back_button': true,
                    'show_next_button': true,
                    'last_step': false,
                },
                {
                    'step_title': 'Have we met before?',
                    'step_skip': false,
                    'show_back_button': true,
                    'show_next_button': true,
                    'last_step': false,
                },
                {
                    'step_title': 'Your details',
                    'step_valid': false,
                    'step_skip': false,
                    'show_back_button': true,
                    'show_next_button': true,
                    'last_step': false,
                    'hs_submit': true,
                },
                {
                    'step_title': 'Tell us more',
                    'step_valid': true,
                    'step_skip': false,
                    'show_back_button': true,
                    'show_next_button': true,
                    'last_step': false,
                    'hs_submit': true,
                    'submit_form': true,
                },
                {
                    'step_title': 'Thank you!',
                    'step_skip': false,
                    'show_back_button': true,
                    'show_next_button': true,
                    'last_step': true,
                }
            ]
            steps.forEach((step) => {
                //these all are default and optional values
                this.stepData.push(step);
            });

            if(!this.isMobile) {
                let lazyLoader = new LazyLoader();
                lazyLoader.loadArticleImages(this.articlesArr);
            }

            this.declarePageIsReady();

            const hs_refresh = window.localStorage.getItem('hs_refresh');

            const dt1 = new Date();
            const dt2 = dt1.setDate(dt1.getDate() + 1);
            if(typeof hs_refresh === "undefined" || hs_refresh === null || hs_refresh === ''){
                window.localStorage.setItem('hs_refresh', dt2);
            } else {
                if(dt1 > hs_refresh){
                    window.localStorage.setItem('hs_refresh', dt2);
                    window.localStorage.removeItem('hs_destinations');
                    window.localStorage.removeItem('hs_durations');
                    window.localStorage.removeItem('hs_budgets');
                    window.localStorage.removeItem('hs_currencies');
                }
            }
        },
        computed: {
            isMobile(){
                return window.pnpHelper.bootstrap.isXs();
            }
        }
    }
</script>