<template>
    <div class="sticky-bar" ref="stickybar" :class="[classes, showBar ? '-in' : '', isStuck ? '-stuck' : '']" :id="id">
        <slot></slot>
    </div>
</template>

<script>
import Stickyfill from 'stickyfilljs';

    var lastScrollTop=0;
    var stickyBarPositionTop=0;

    export default {
        props: [
            'classes', 'id'
        ],
        data(){
            return {
                showBar: false,
                isStuck: false,
                scrollHandler: null,
                stickyBitsRef: null
            }
        },
        methods: {
            updateBarPosition() {
                var yPosition = 0;
                var element = this.$refs.stickybar;

                while(element) {
                    yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
                    element = element.offsetParent;
                }

                stickyBarPositionTop = yPosition;
            },

            handleScroll () {
                let _this = this;
                this.scrollHandler = _.throttle(function(){
                    _.debounce(_this.updateBarPosition, 1000);

                    var st = window.pageYOffset || document.documentElement.scrollTop;
                    if((stickyBarPositionTop - window.scrollY) < 0) {

                        if(!_this.isStuck) {
                            _this.isStuck = true;
                            _this.addStickyBar();
                        }

                        if (st > lastScrollTop) {
                            if(_this.showBar) {
                                //Scrolling down
                                _this.showBar = false;
                            }
                        } else {

                            if(!_this.showBar) {
                                //Scrolling up
                                _this.showBar = true;
                            }
                        }

                    }else{
                        if(_this.showBar) {
                            _this.showBar = false;
                        }

                        if(_this.isStuck) {
                            _this.isStuck = false;
                            _this.removeStickyBar();
                        }
                    }

                    lastScrollTop = st <= 0 ? 0 : st;
                }, 500);

                window.addEventListener('scroll', this.scrollHandler);
            },
            removeStickyBar(){
                $('body').removeClass('sticky-bar-stuck');
            },
            addStickyBar(){
                $('body').addClass('sticky-bar-stuck');
            }
        },
        mounted() {
            this.updateBarPosition();
            this.handleScroll();

            //IE support for sticky
            this.stickyBitsRef = Stickyfill.addOne(this.$refs.stickybar);
        },
        created() {
            window.addEventListener('resize', this.updateBarPosition);
        },
        destroyed() {
            window.removeEventListener('scroll', this.scrollHandler);
            window.removeEventListener('resize', this.updateBarPosition);
            window.bus.$once('do-blanket-tasks', this.removeStickyBar);

            //IE support for sticky
            this.stickyBitsRef.remove();
        }
    }
</script>