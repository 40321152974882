<template>
    <transition appear name="fade">
        <div id="edit-controls" :class="{'-edit-mode': editMode}">
            <a rel="nofollow" :href="editHref" v-if="isEditing === false" data-toggle-edit class="btn -not-rounded" title="Enter Edit Mode" @click="cleanLocalStorage">
                <i class="ion-edit"></i>
            </a>

            <a rel="nofollow" v-if="isEditing === true" class="btn -not-rounded" title="Edit page meta" data-toggle="modal" data-target="#page_metadata_modal" @click="showPageMetaForm">
                <i class="ion-android-alert"></i>
            </a>

            <a rel="nofollow" href="?" v-if="isEditing === true" data-toggle-edit class="btn -not-rounded" title="Exit Edit Mode" @click="cleanLocalStorage">
                <i class="ion-edit"></i>
            </a>

            <a rel="nofollow" class="btn -not-rounded red" title="Clear Cache" @click="cleanCache">
                <i class="ion-loop"></i>
            </a>

            <div v-if="editor.isDirty" id="buttonSavePage">
                <button class="btn -not-rounded -save" @click="storeData" :disabled="saving === true">{{ saveLabel }}</button>
            </div>
        </div>
    </transition>
</template>

<script>
    import AtrStorage from '../../global/AtrStorage';
    export default {
        data(){
            return {
                saveLabel: 'Save',
                saving: false,
                activeSection: null,
                activeSlide: null,
            }
        },
        methods: {
            cleanCache(){
                axios.get(window.API_URL + 'clearCache').then(() => {
                    this.cleanLocalStorage();
                    window.location.reload();
                });
            },
            storeData(){
                if(this.ckEditor === true){
                    alert('You are currently editing the page.\nSave or Cancel it before saving the data.');
                } else {
                    var _this = this;
                    _this.saveLabel = 'Saving...';
                    _this.saving = true;
                    this.$store.dispatch(window.store.STORE_DATA, this.$route.params['slug'])
                        .then((response) => {
                            _this.saveLabel = 'Save';
                            _this.saving = false;
                        });
                }
            },
            cleanLocalStorage(){
                new AtrStorage().cleanCurrentInUseKey();
            },
            fullPageAfterSectionChangeEvent(){
                var index = $('.section.active').index('.section');
                this.activeSection = index == 1 ? null : index;
            },
            changedSlideEvent(){
                Vue.nextTick(() => {
                    var slideIndex = window.pnpHelper.imageGallery.getCurrentIndex() + 1;
                    this.activeSlide = slideIndex == 0 ? null : slideIndex;
                });
            },
            closedGalleryEvent(){
                Vue.nextTick(() => {
                    this.activeSlide = null;
                });
            },
            showPageMetaForm(){
                console.log('Show page meta form');
            },
            fullPageAfterDomReadyEvent(){
                Vue.nextTick(() => {
                    window.fullpage.silentMoveTo(parseInt(this.$route.query.section) + 1);

                    if (!_.isUndefined(this.$route.query.slide)) {
                        Vue.nextTick(() => {
                            window.pnpHelper.imageGallery.changeImage(
                                null,
                                parseInt(this.$route.query.section) + 1,
                                parseInt(this.$route.query.slide)
                            );
                        });
                    }
                });
            }
        },
        computed: {
            editor() {
                return this.$store.state.editor.editor;
            },
            ckEditor(){
                return this.$store.state.editor.CKEDITOR;
            },
            isEditing(){
                return !_.isUndefined(this.$route.query.edit) && this.$route.query.edit == '1';
            },
            editMode(){
                return this.$store.state.user.user.can_edit;
            },
            editHref() {
                var str = '?edit=1';
                str = this.activeSection != null ? str + '&section=' + this.activeSection : str;
                return this.activeSlide != null ? str + '&slide=' + this.activeSlide : str;
            }
        },
        mounted() {
            window.bus.$on('fullPageAfterSectionChange', this.fullPageAfterSectionChangeEvent);
            window.bus.$on('changedSlide', this.changedSlideEvent);
            window.bus.$on('closedGallery', this.closedGalleryEvent);

            if (!_.isUndefined(this.$route.query.section) && !_.isUndefined(this.$route.query.edit)) {
                window.bus.$once('fullPageAfterDomReady', this.fullPageAfterDomReadyEvent);
            }

            //Note the below is now called in PageReady.js
            //this.$store.dispatch(window.store.FETCH_AND_SET_USER, this.$store.state.user.user);
        },
        destroyed() {
            window.bus.$off('fullPageAfterSectionChange', this.fullPageAfterSectionChangeEvent);
            window.bus.$off('changedSlide', this.changedSlideEvent);
            window.bus.$off('closedGallery', this.closedGalleryEvent);
            window.bus.$off('fullPageAfterDomReady', this.fullPageAfterDomReadyEvent);
        }
    }
</script>
