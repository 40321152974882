<template>
    <div :class="['page-new-' + routeType + '-page']" class="no-scroll-hijack">
        <section class="right-side map-container" :class="[routeType == 'experience' ? 'vignette' : '']" :style="{'background-image': 'url(' + objType.image + ')' }">
            <map-presentational
                    v-if="routeType != 'experience'"
                    :args="mapArgs"
                    :classes="['vignette', '-vignette-transparent', 'vignette-map']"
                    :type="routeType">
            </map-presentational>
        </section>

        <atr-fullpage id="new-lodge-page" ref="atrFullpage">
            <template v-if="loaded">
                <div ref="slider-container" style="position:relative; overflow:hidden">
                    <div style="z-index: 1; position: relative;">
                        <section-intro-presentational class="intro-presentational">
                            <transition name="fade-in">
                                <div class="map-tooltip info-container" v-show="mapClicked" @click="hidePopup">
                                    <div class="map-popup">
                                        <div class="inner-panel  info-inner">
                                            <p class="info-popup-content">
                                                Click here to to Explore this location on a map
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </transition>

                            <div class="component-text -lodge-info custom-section vh-fix-once"
                                 :data-block-id="objType.top_section.id">
                                <div class="inner -in">
                                    <div class="inner-container">
                                        <div class="width-container">
                                            <div class="button-fav visible-xs"
                                                 :class="{on: check}"
                                                 aria-label="Add to Wishlist"
                                                 @click="toggleWishlist">
                                            </div>

                                            <div class="breadcrumbs sub-text">
                                                <button-internal-presentational classes="sub-text -light"
                                                                                :router_object="{name: 'single-location', params: getSlugFromParent(objType.location)}">
                                                    {{ objType.location }}
                                                </button-internal-presentational>

                                                <span v-if="getSlugFromParent(objType.location) != ''" class="sub-text -light"> &nbsp;/&nbsp; </span>

                                                <button-internal-presentational classes="sub-text -light"
                                                                                :router_object="{name: 'single-location', params: getSlugFromParent(objType.country)}">
                                                    {{ objType.country }}
                                                </button-internal-presentational>
                                            </div>

                                            <div class="heading-container">
                                                <div class="button-fav hidden-xs"
                                                     :class="{on: check}"
                                                     aria-label="Add to Wishlist"
                                                     @click="toggleWishlist">
                                                </div>

                                                <h1 class="heading3 heading custom-section-title"
                                                    :data-pnp-heading="objType.top_section.tag"
                                                    :data-block-id="objType.top_section.id"
                                                    v-html="objType.title">
                                                </h1>
                                            </div>

                                            <div class="summary custom-section-content"
                                                 v-html="objType.top_section.content" :contenteditable="editing"
                                                 v-if="objType.edit === false"></div>

                                            <div class="on-page-editor-container" v-else>
                                                <div class="summary custom-section-content"
                                                     v-html="objType.top_section.content"
                                                     :contenteditable="editing"></div>

                                                <div class="smallEditButtons -horizontal"
                                                     :class="{ '-editing': editing }">
                                                    <div v-on:click="createEditor('custom-section-content')"
                                                         class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton"></div>
                                                    <div v-on:click="saveEditor('custom-section-content')"
                                                         class="icon ion-ios-checkmark onPageEditor-smallButton btn-success smallSaveButton"
                                                         v-if="editing===true"></div>
                                                    <div v-on:click="cancelEditor('custom-section-content')"
                                                         class="icon ion-ios-close onPageEditor-smallButton btn-danger smallCancelButton"
                                                         v-if="editing===true"></div>
                                                </div>
                                            </div>

                                            <hr/>
                                            <br/>

                                            <info-container classes="rating-container"
                                                            :value="objType.rating ? objType.rating : '0.0'">
                                                <template slot="header">
                                                    <div class="sub-text">{{ routeType }} <br/>RATING</div>
                                                </template>

                                                <template slot="popup-header">
                                                    <p class="-capitalise">{{ routeType }} rating</p>
                                                </template>

                                                <template slot="popup-text">
                                                    <p>
                                                        This number represents the <strong>overall rating</strong>
                                                        for
                                                        <strong>{{objType.title}}</strong>
                                                        <span v-if="month <= 0"> in <strong>any month</strong>,</span>
                                                        <span v-else> in <strong>{{ months[month - 1] }}</strong>,</span>
                                                        which takes into account all the various component ratings
                                                        of a
                                                        property, its location,
                                                        build, style, service level and experiences.
                                                    </p>

                                                    <div class="links">
                                                        <button-internal-presentational
                                                                classes="link"
                                                                :router_object="{path: hardcodedUrls.resources.overallRating}"
                                                                transition_name="wipe">
                                                            Our ratings system
                                                        </button-internal-presentational>
                                                    </div>
                                                </template>
                                            </info-container>

                                            <price :price="!objType.price ? objType.guide_price : objType.price" :minPrice="objType.min_price">
                                                <template slot="header">
                                                     <span class="sub-text" v-if="objType.min_price">
                                                        GUIDE PRICE {{ this.$store.state.user.user.selected_currency}}<br/> PER PERSON PER NIGHT
                                                     </span>
                                                    <span class="sub-text" v-else>
                                                        GUIDE PRICE <br/>{{ this.$store.state.user.user.selected_currency}} PPPN
                                                    </span>
                                                </template>

                                                <template slot="popup-header">
                                                    <p class="-capitalise">{{ routeType }} guide price</p>
                                                </template>

                                                <template slot="popup-content">
                                                    <p>
                                                        These numbers represent the approximate range of price per
                                                        person per night in
                                                        <span v-if="month <= 0"><strong>any month</strong></span>
                                                        <span v-else><strong>{{ months[month - 1] }}</strong></span>
                                                        to stay at
                                                        <strong>{{objType.title}}</strong>.
                                                        The lower end is the average low season
                                                        <strong>{{ routeType }}</strong>
                                                        price.
                                                        The upper end is the average high season
                                                        <strong>{{ routeType }}</strong>
                                                        price plus any extras
                                                        which usually need to be included,
                                                        such as meals, drinks and activities, transport costs and
                                                        park
                                                        fees.

                                                    </p>

                                                    <div class="links">
                                                        <button-internal-presentational
                                                                classes="link"
                                                                :router_object="{path: hardcodedUrls.resources.priceGuarantee}"
                                                                transition_name="wipe">
                                                            Price guarantee
                                                        </button-internal-presentational>
                                                        :

                                                        <button-internal-presentational
                                                                classes="link"
                                                                :router_object="{path: hardcodedUrls.resources.howMuchToSpend}"
                                                                transition_name="wipe">
                                                            How much to spend
                                                        </button-internal-presentational>
                                                        :

                                                        <button-internal-presentational
                                                                classes="link"
                                                                :router_object="{path: hardcodedUrls.resources.howToSaveMoney}"
                                                                transition_name="wipe">
                                                            How to save money
                                                        </button-internal-presentational>
                                                    </div>
                                                </template>
                                            </price>

                                            <info-container v-if="objType.conservation_rating >= 7"
                                                            classes="consrating-container"
                                                            :value="objType.conservation_rating">

                                                <template slot="header">
                                                    <div class="sub-text">CONSERVATION <br/>RATING</div>
                                                </template>

                                                <template slot="popup-header">
                                                    <p class="-capitalise">{{ routeType }} conservation rating</p>
                                                </template>

                                                <template slot="popup-text">
                                                    <p>The conservation rating is an indication of how much a
                                                        particular
                                                        location or lodge needs your visit to further the protection
                                                        of
                                                        their landscapes and wildlife.</p>
                                                    <p>We recommend that you consider including at least one lodge
                                                        with
                                                        a high conservation rating in your trip.</p>

                                                    <div class="links">
                                                        <button-internal-presentational
                                                                classes="link"
                                                                :router_object="{path: hardcodedUrls.resources.conservation}"
                                                                transition_name="wipe">
                                                            Conservation rating
                                                        </button-internal-presentational>
                                                    </div>
                                                </template>

                                            </info-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section-intro-presentational>
                    </div>
                    <new-carousel :safari-object="objType" :safari-object-type="this.routeType"/>
                </div>

                <div class="content-block">
                    <div class="mobile-only">
                        <div class="container-fluid">
                            <div class="images-heading">
                                Images
                            </div>
                        </div>
                        <shortcodes :content="imageShortcode" />
                    </div>
                    <div v-if="accordion.length" class="container-fluid">
                        <div ref="sidenavContainer" class="row">
                            <div ref="sidenavContent" class="col-md-3">
                                <div class="side-nav">
                                    <div v-for="(item, i) in accordion" v-if="item.children.length" class="accordion-nav-item-container relative"
                                         :class="[activeAccordionKey == i ? '-opened' : '']" :ref="'accordion-'+i">

                                        <div class="accordion-nav-item-title" @click="scrollAndSetActiveAccordion(i, 0, 'accordion-'+i)">
                                            <div class="relative">
                                                {{ item.name }}
                                                <i class="accordion-icon" :class="['ion-arrow-' + (activeAccordionKey == i ? 'up' : 'down') + '-b']"></i>
                                            </div>
                                        </div>
                                        <slide-up-down :active="activeAccordionKey == i">
                                            <div class="content-container -read-more-container" :class="[readMore ? 'reading-more' : '']">
                                                <template v-for="(childItem, j) in item.children">
                                                    <div class="accordion-nav-item-container -inner-item-container">
                                                        <div @click="scrollAndSetActiveAccordion(i, j)"
                                                             class="accordion-nav-item-title -no-border hidden-xs"
                                                             :class="{'-active': activeAccordionKey == i && activeChildAccordionKey == j}">
                                                            <div class="relative">
                                                                {{ childItem.name|striphtml }}
                                                                <i v-if="activeAccordionKey == i && activeChildAccordionKey == j" class="ion-ios-arrow-thin-right"></i>
                                                            </div>
                                                        </div>
                                                        <div class="mobile-only">
                                                            <shortcodes :content="childItem.text" />
                                                        </div>
                                                        <div class="mobile-only read-more" @click="readMoreClick(childItem, j)"><div><span>Read more</span></div></div>
                                                    </div>
                                                </template>
                                            </div>
                                        </slide-up-down>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-9 bodyContent" ref="bodyContent">
                                <div v-if="activeAccordion != false" :use-hidden="false" :active="readMore" class="desktop-only content-container">
                                    <shortcodes :content="activeAccordion.text" />
                                    <div v-if="hasNextSection || hasNextChildSection" @click="goToNextSection" class="next-section">Next section</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="content-block">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="accordion-nav-item-container -outs" ref="wishlist" @click="scrollAndSetActiveAccordion('wishlist', 0, 'wishlist'), readMore = false">
                                    <div class="heading4 -margin-mobile">
                                        Add to wishlist
                                       <i class="accordion-icon mobile-only" :class="['ion-arrow-' + (activeAccordionKey == 'smart-links' ? 'up' : 'down') + '-b']"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-9 outs-content-container">
                               <slide-up-down :active="isAccordionActiveMobileOnly('wishlist')">
                                    <wishlist-add-presentational :hide-heading="true" :name="objType.title" :item="objType"
                                                                 :type="routeType" class="full-width h-auto"/>
                               </slide-up-down>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div v-if="showOuts" class="content-block">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="accordion-nav-item-container -outs" ref="smart-links" @click="scrollAndSetActiveAccordion('smart-links', 0, 'smart-links'), readMore = false">
                                    <!-- <div class="heading4 -margin-mobile">
                                        Smart links
                                       <i class="accordion-icon mobile-only" :class="['ion-arrow-' + (activeAccordionKey == 'smart-links' ? 'up' : 'down') + '-b']"></i>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-md-9 outs-content-container">
                                <div class="content-container">
                                    <!--                                <slide-up-down :active="isAccordionActiveMobileOnly('smart-links')">-->
<!--                                    <alternatives v-if="routeType != 'experience' && routeType != 'location'"-->
<!--                                                  :item="objType" type="trip" sectionTitle="Best" prefix="The best trips including">-->
<!--                                        <p class="content">-->
<!--                                            If you like this look of this {{ routeType }}, then you can take a look at some sample trips ...-->
<!--                                        </p>-->
<!--                                    </alternatives>-->

                                    <alternatives :item="objType" :type="routeType" outType="lodge" listType="full" sectionTitle="Alternatives"
                                                  prefix="Best lodges in" :style="[routeType != 'experience' ? 'margin-top: 10%;' : '']">

<!--                                        <p v-if="routeType != 'experience' && routeType != 'location'" class="content">-->
<!--                                            If you are not sure about this {{ routeType }}, then here’s some options ...-->
<!--                                        </p>-->

                                    </alternatives>

                                    <span v-if="routeType == 'location' || routeType == 'lodge'">
                                        <br>

                                        <alternatives :item="objType" :type="routeType" sectionTitle="Alternatives"
                                                      prefix="Best locations in" >
                                        </alternatives>

                                        <br>

                                        <best-trip-out></best-trip-out>
                                    </span>
<!--                                </slide-up-down>-->
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div v-if="showOuts" class="content-block border-b-2-px-gray">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="accordion-nav-item-container -outs" ref="get-in-touch" @click="scrollAndSetActiveAccordion('get-in-touch', 0, 'get-in-touch'), readMore = false">
                                    <div class="heading4 -margin-mobile">
                                        Get in touch
                                       <i class="accordion-icon mobile-only" :class="['ion-arrow-' + (activeAccordionKey == 'get-in-touch' ? 'up' : 'down') + '-b']"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-9 outs-content-container">
                               <slide-up-down :active="isAccordionActiveMobileOnly('get-in-touch')">
                                    <alternatives type="contact" sectionTitle="Contact">
                                       <p class="content">It’s never too soon to get in touch, we are always keen to help out, no matter how formulative or advanced your plans may be ...</p>
                                    </alternatives>
                               </slide-up-down>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <next-up-presentational
                        v-if="showOuts && !isOld"
                        :classes="'-next-lodge'"
                        :page="routeType"
                        :item="objType"
                        :data_heading="'Next'"
                        :data_next_lodge="true"
                        :text_next_item="'Next ' + routeType"
                        :text_explore="'Explore'"
                        :directory="directory" /> -->
            </template>
        </atr-fullpage>

        <site-footer />

        <div v-if="objType.edit === true">
            <template v-for="section in objType.sections.data">
                <public-editor-buttons :type="routeType + '_section'" :section_id="section.id" :_id="section.id" :buttons="section.buttons"></public-editor-buttons>

                <template v-for="slide in section.slides.data">
                    <public-editor-buttons :type="routeType + '_slide'" :section_id="section.id" :_id="slide.id" :buttons="slide.buttons"></public-editor-buttons>
                </template>
            </template>
            <public-editor-meta v-if="routeType!==''" :type="routeType + '_'"></public-editor-meta>
        </div>

        <div id="modal-veil"></div>

        <public-edit-mode-toggle />

        <!-- <transition-wipe/> -->
    </div>
</template>

<script>
    import slide from '../../libs/vue-carousel/src/Slide';
    import carousel from '../../libs/vue-carousel/src/Carousel';
    import StateUtils from '../../global/StateUtils';
    import pageIsReadyMixin from '../../mixins';
    import GlobalVars from "../../global/GlobalVars";
    import {mapState} from 'vuex';
    import LazyLoader from '../../global/LazyLoader';
    // import Sticky from 'vue-sticky-directive';
    import SlideUpDown from 'vue-slide-up-down';
    import NavScrollMixin from "../../global/NavScrollMixin";
    import Vue from 'vue';
    import _ from 'lodash'

    export default {
        mixins: [pageIsReadyMixin, NavScrollMixin],
        // directives: {Sticky},
        data() {
            return {
                routeType: '',
                editing: false,
                editorName: null,
                showOuts: true,
                loaded: false,
                months: [],
                triggerReload: false,
                mapClicked: false,
                readMore: false,
                activeAccordionKey: 0,
                activeChildAccordionKey: 0,
                relatedItems: [],
                accordionId: null
            }
        },
        components: {
            carousel,
            slide
        },
        methods: {
            readMoreClick(item, j){
                this.readMore = !this.readMore;
            },
            goToNextSection() {
                if (this.hasNextChildSection) {
                    this.scrollAndSetActiveAccordion(this.activeAccordionKey, this.activeChildAccordionKey + 1);
                } else if (this.hasNextSection) {
                    this.scrollAndSetActiveAccordion(this.activeAccordionKey + 1);
                }
            },
            setActiveAccordion(i, j = 0) {
                this.activeAccordionKey = i;
                this.activeChildAccordionKey = j;
                this.readMore = false;

                let payload = {};

                payload[this.accordionId] = {
                    'activeAccordionKey': this.activeAccordionKey,
                    'activeChildAccordionKey': this.activeChildAccordionKey
                };

                this.$store.commit(window.store.SET_ACCORDION_HISTORY, payload);
            },
            scrollAndSetActiveAccordion(i, j = 0, elRef = null) {
                let pixelScrollThreshold = 50;
                this.readMore = false;
                if (pnpHelper.bootstrap.isXs() && elRef != null) {
                    i = this.activeAccordionKey == i ? -1 : i;
                    j = this.activeChildAccordionKey == i ? -1 : j;
                }

                if (elRef && !elRef.includes('accordion') && !window.pnpHelper.bootstrap.isXs()) {
                    return;
                }
                let offset = (document.documentElement.scrollTop || document.body.scrollTop) - $('.bodyContent').offset().top;
                if(offset < 0){
                    offset *= -1;
                }
                if (offset  > pixelScrollThreshold) {
                    if (elRef && window.pnpHelper.bootstrap.isXs()) {
                        this.setActiveAccordion(i, j);
                        setTimeout(() => {
                            window.pnpHelper.scroll.doScroll($(this.$refs[elRef]).offset().top - 70, () => {

                            });
                        }, 580);

                    } else {
                        window.pnpHelper.scroll.doScroll($(this.$refs['bodyContent']).offset().top - 150, () => {
                            this.setActiveAccordion(i, j);
                        });

                    }
                } else {
                    this.setActiveAccordion(i, j);
                }
            },
            isAccordionActiveMobileOnly(key) {
                return window.pnpHelper.bootstrap.isMd() || this.activeAccordionKey == key;
            },
            createEditor(which) {
                if (!window._.isUndefined(this.editorName) && this.editorName !== null) {
                    alert('You are currently editing a different bit on the page.\nSave or Cancel it before starting editing on a new instance.');
                } else {
                    this.editing = true;
                    Vue.nextTick(() => {
                        var editor = window.CKEDITOR.inline(this.$el.querySelector('.on-page-editor-container .' + which), window.ckeditorConfig);
                        this.$el.querySelector('.on-page-editor-container .' + which).click();
                        this.editorName = editor.name;

                        this.$store.commit(window.store.SET_EDITOR_CKEDITOR, true, {root: true});
                    });
                }
            },
            cancelEditor(which) {
                this.editing = false;
                if (this.editorName !== null) {
                    window.CKEDITOR.instances[this.editorName].container.$.innerHTML = this.objType.top_section.content;
                    window.CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;

                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, {root: true});
                }
            },
            saveEditor(which) {
                var stateObject = new StateUtils(this.type, this.$store).getStateObject();

                if (this.editorName !== null) {
                    stateObject.top_section.content = window.CKEDITOR.instances[this.editorName].container.$.innerHTML;
                    new StateUtils(this.type, this.$store).setStateObject(stateObject, null);
                    this.editing = false;
                    window.CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;

                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, {root: true});
                }
            },
            getSlugFromParent(title) {
                if (_.isUndefined(this.objType.parents)) {
                    return [];
                }

                let filteredArr = this.objType.parents.data.filter(el => {
                    if (el.title === title) {
                        return el;
                    }
                })[0];

                // console.log( filteredArr.vue_route_params );

                if (filteredArr) {
                    return filteredArr.vue_route_params;
                }
                return [];
            },
            toggleWishlist() {
                let payload = {
                    id: this.objType.id,
                    type: this.routeType
                };

                let check = this.wishlist.some((el) => {
                    return el.id == this.objType.id;
                });

                if (check) {
                    this.$store.commit(window.store.REMOVE_WISHLIST_ITEM, payload);
                } else {
                    this.$store.dispatch(window.store.SET_WISHLIST_ITEM_BY_ID, payload);
                }
            },
            lazyLoadHiddenImages() {
                let images = [];

                //Load any image slides (View More)
                if (this.objType.sections.data && this.objType.sections.data.length > 0) {
                    for (let i in this.objType.sections.data) {
                        if (this.objType.sections.data[i].hiddenSlides && this.objType.sections.data[i].hiddenSlides.data.length > 0) {
                            for (let j in this.objType.sections.data[i].hiddenSlides.data) {
                                if (pnpHelper.bootstrap.isXs()) {
                                    images.push(this.objType.sections.data[i].hiddenSlides.data[j].bg_img_url_large);
                                } else {
                                    images.push(this.objType.sections.data[i].hiddenSlides.data[j].img_url);
                                }
                            }
                        }
                    }
                }

                //Load any hidden section images (View All)
                if (this.objType.hiddenSections.data && this.objType.hiddenSections.data.length > 0) {
                    for (let i in this.objType.hiddenSections.data) {

                        if (pnpHelper.bootstrap.isXs()) {
                            images.push(this.objType.hiddenSections.data[i].bg_img_url_large);
                        } else {
                            images.push(this.objType.hiddenSections.data[i].bg_img_url);
                        }

                        if (this.objType.hiddenSections.data[i].allSlides && this.objType.hiddenSections.data[i].allSlides.data.length > 0) {
                            for (let j in this.objType.hiddenSections.data[i].allSlides.data) {
                                if (pnpHelper.bootstrap.isXs()) {
                                    images.push(this.objType.hiddenSections.data[i].allSlides.data[j].bg_img_url_large);
                                } else {
                                    images.push(this.objType.hiddenSections.data[i].allSlides.data[j].img_url);
                                }
                            }
                        }
                    }
                }

                if (images.length > 0) {
                    let lazyLoader = new LazyLoader();
                    lazyLoader.loadImages(images);
                }
            },
            mapPopup($event) {
                if (($($event.target).hasClass('-location-info') || $($event.target).hasClass('inner-container')) && !this.mapClicked) {
                    this.mapClicked = true;
                }
            },
            hidePopup() {
                this.mapClicked = false;
            },
            setAccordionFromHistory(accordionId){
                let accordionHistory = this.accordionHistory[accordionId];

                if(!_.isEmpty(accordionHistory)){
                    this.scrollAndSetActiveAccordion(accordionHistory.activeAccordionKey, accordionHistory.activeChildAccordionKey);

                    window.pnpHelper.scroll.doScroll($(this.$refs['bodyContent']).offset().top - 150);
                }
            }
        },
        computed: {
            hasNextSection() {
                if(this.accordion[this.activeAccordionKey + 1]){
                    return this.accordion[this.activeAccordionKey + 1];
                }
            },
            hasNextChildSection() {
                if(this.accordion[this.activeAccordionKey]){
                    return (this.activeChildAccordionKey + 1) < this.accordion[this.activeAccordionKey].children.length;
                }
            },
            type() {
                return this.routeType + '_main';
            },
            storeAddress() {
                switch(this.routeType)
                {
                    case 'lodge' :
                        return window.store.FETCH_AND_SET_LODGE;
                    case 'location' :
                        return window.store.FETCH_AND_SET_LOCATION;
                    case 'experience' :
                        return window.store.FETCH_AND_SET_EXPERIENCE;
                }
            },
            imageShortcode() {
                let shouldSkipFirstSlide = this.routeType == 'lodge' || this.routeType == 'location' ? 0 : 1;

                return '[image-slider geoid="' + this.objType.id + '" type="' + this.routeType + '" start_offset="' + shouldSkipFirstSlide + '"]';
            },
            activeAccordion() {
                if (_.isUndefined(this.accordion[this.activeAccordionKey]) || _.isUndefined(this.accordion[this.activeAccordionKey].children[this.activeChildAccordionKey])) {
                    return false;
                } else {
                    return this.accordion[this.activeAccordionKey].children[this.activeChildAccordionKey];
                }
            },
            accordion() {
                let textRegex = /\[article([^\]]*)\]([\s\S]*?)\[\/article\]/gm;

                return _.filter(_.flatMap(this.objType.allSections.data, section => {
                    let data = [].concat(section.actions || [], _.flatMap(section.allSlides.data, slide => slide.actions));

                    return _.map(data, action => {
                        if (!_.isUndefined(action.article) &&
                            !_.isUndefined(action.article.content))
                        {
                            return {
                                name: action.article.title,
                                children: Array.from(action.article.content.matchAll(textRegex), match => {
                                    let headingRegex = /heading="(.*?)"/g; // Needs to be redefined on each loop to reset internal pointer
                                    let headingMatches = headingRegex.exec(match[1]);

                                    return {
                                        name: headingMatches == null ? '' : headingMatches[1],
                                        text: match[2]
                                    }
                                })
                            };
                        }
                    });
                }), section => !_.isUndefined(section) && section.children.length);
            },
            hardcodedUrls() {
                return GlobalVars.HARCODED_URLS;
            },
            googleAnalytics() {
                return window.GoogleAnalytics;
            },
            analyticsEventCategory() {
                return 'Add ' + window.GoogleAnalytics.parseVueRouteParams(this.$route.params);
            },
            objType() {
                let obj = null;

                switch (this.routeType) {
                    case 'lodge':
                        obj = this.$store.state.singleLodge.lodge;
                    break;
                    case 'location':
                        obj = this.$store.state.singleLocation.location;
                    break;
                    case 'experience':
                        obj = this.$store.state.singleExperience.experience;
                    break;
                }

                let {price, guide_price, conservation_rating, rating} = this.$route.params;

                let result = null;
                /**
                 * Set the video button from here if the page meta has a video type meta
                 */
                if(obj!== null && !_.isUndefined(obj)) {
                    if(!_.isUndefined(obj.page_meta)){
                        for(let x in obj.page_meta){
                            if(obj.page_meta[x].type === 'video' && obj.page_meta[x].data !== ""){
                                result = {
                                    action: "videoPlayer",
                                    article: null,
                                    link: obj.page_meta[x].data,
                                    modal: null,
                                    style: "underline",
                                    text: "Video",
                                    type: "videoPlayer",
                                    sections: [],
                                    isClicked: false,
                                }
                            }
                        }
                    }
                }
                return {
                    ...obj,
                    price: price ? price : ((obj !== null) ? obj.price : 0.0),
                    guide_price: guide_price ? guide_price : ((obj !== null) ? obj.guide_price : 0.0),
                    conservation_rating: conservation_rating ? conservation_rating : ((obj !== null) ? obj.conservation_rating : 0),
                    rating: rating ? rating : ((obj !== null) ? obj.rating : 0.0),
                    firstVideo: result
                };
            },
            mapArgs() {
                return [{
                    lat: this.objType.lat,
                    lng: this.objType.lng,
                    image: this.objType.image,
                    id: this.objType.id,
                    title: this.objType.title,
                    price: this.objType.price,
                    rating: this.objType.rating,
                    zoom: 15
                }];
            },
            directory() {
                if (this.$route.params) {
                    return this.$route.params.directory;
                }

                return false;
            },
            check() {
                return this.wishlist.some((el) => {
                    return el.id == this.objType.id;
                })
            },
            isOld() {
                return window.globals.oldDevice;
            },
            bg_img_url() {
                return this.objType.images.sizes.extralarge ? "background-image: url('" + this.objType.images.sizes.extralarge + "')" : '';
            },
            ...mapState({
                month: state => state.search.criteria.params.month,
                wishlist: state => state.wishlist.selected,
                accordionHistory: state => state.transitionManager.accordionHistory
            })
        },
        mounted() {
            this.routeType = this.$route.meta['type'];
            this.accordionId = this.$route.fullPath.replace(/\//g, '');

            if (window.pnpHelper.bootstrap.isLg()) {
                setTimeout(() => {
                    $(this.$refs['sidenavContent']).fixTo(this.$refs['sidenavContainer'], {
                        mind: 'header.header-container',
                        top: 15
                    });
                }, 1000);
            }

            window.bus.$on('modal-open', () => {
                $(window).scrollTop(0);
            });

            window.bus.$on('fullPageMoveSectionDown', () => {
                window.pnpHelper.scroll.scrollPast(this.$refs['slider-container']);
            });

            if (Object.keys(this.$route.params).length > 0 && this.$route.params.hasOwnProperty('showOuts')) {
                this.showOuts = this.$route.params.showOuts;
            }

            this.$store.dispatch(this.storeAddress, this.$route.params['slug']).then(() => {
                setTimeout(() => {
                    if (this.$refs.atrFullpage) {
                        this.$refs.atrFullpage.init();
                    }
                }, 100);

                this.$store.commit('navigation/SET_SUBHEADING', this.objType.title);

                this.loaded = true;
            }).catch(() => {
                this.$router.push({name: 'page404'});
            });

            this.months = GlobalVars.MONTHS;

            window.bus.$once('fullPageAfterDomReady', () => {
                Vue.nextTick(() => {
                    window.bus.$emit('fullPageDestroying');
                    $('#sidenav').attr('style', '');
                    $('body').attr('style', '');
                    this.declarePageIsReady();

                    this.setAccordionFromHistory(this.accordionId);
                    $('body').removeClass('-has-side-nav');
                    $('#sidenav, .mob-sidenav-toggle').remove();
                });
            });

            window.bus.$once('lazyloadAllLoaded', this.lazyLoadHiddenImages);


            // this.$store.dispatch(window.store.FETCH_AND_RETURN_LOCATION_PARENTS, this.searchResult.vue_route_params.data.slug)
            //     .then((res) => {
            //         let resArr = [
            //             res.data[res.data.length - 1] ? res.data[res.data.length - 1] : {},
            //             res.data[res.data.length - 2] ? res.data[res.data.length - 2] : {},
            //             res.data[0] ? res.data[0] : {}
            //         ];
            //
            //         res = [...resArr].filter((el) => {
            //             if(el.title) {
            //                 return el;
            //             }
            //         });
            //
            //         this.relatedItems = _.uniqBy(res, 'title');
            //
            //         if(!preventToggle) {
            //             this.toggleRelatedSearches(this.searchResult.id);
            //         }
            //
            //         this.loadingRelated = false;
            //     });

        },
        beforeRouteUpdate(to, from, next) {
            //Only do this if nwe're going from location to location (i.e. next up module or quicksearch)
            if ($('body').hasClass('-disable-page-wipe')) {

                this.triggerReload = true;

                let transitionPromise = new Promise((resolve, reject) => {
                    $('.-location-info').find('.inner').removeClass('-in');

                    resolve();
                });

                transitionPromise.then(() => {
                    this.triggerReload = false;

                    this.$store.dispatch(this.storeAddress, to.params['slug']).then(() => {

                        window.bus.$emit('fullPageDestroying');
                        window.bus.$emit('fullPageDestroyed');
                        window.bus.$emit('fullPageInit');

                        this.$store.commit('navigation/SET_SUBHEADING', this.objType.title);

                        this.routeType = this.$route.meta['type'];

                        next();
                    }).then(() => $('.-location-info').find('.inner').addClass('-in')).catch(() => {
                        this.$router.push({name: 'page404'});

                    }).finally(() => {
                        setTimeout(() => {
                            $('body').removeClass('-disable-page-wipe');
                        }, 2000);
                    });

                });
            } else {
                next();
            }
        },
        beforeDestroy() {
            window.bus.$emit('setShowBackButton', false);
            this.$store.commit('navigation/SET_SUBHEADING', false);
        }
    }
</script>
