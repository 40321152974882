<template>
    <div>
        <p><strong>Please select an option...</strong></p>

        <ul class="multi-step-radios-list">
            <li>
                <input type="radio" id="call_method-arrange" name="call_method" value="call_method-arrange" @change="updateCallMethod" v-model="selectedOption">
                <label for="call_method-arrange">
                    <div>
                        <strong>Arrange a time for us to call you</strong>
                        <p>Let us know a convenient time and we will get one of our genuine safari <br>
experts to call you back for an introductory chat.</p>
                    </div>
                    
                </label>
            </li>
            <li>
                <input type="radio" id="call_method-now" name="call_method" value="call_method-now" @change="updateCallMethod" v-model="selectedOption">
                <label for="call_method-now">
                    <div>
                        <strong>Call now</strong>
                    <p>You can call our safari experts directly on regular working days<br>
during the hours 4am-4pm East Coast, 1am-1pm West Coast, <br>
9am-9pm London.</p>
                    </div>
                    
                </label>
            </li>
        </ul>
    </div>
</template>

<script>

    export default {
        props: ['selected', 'step', 'step_index'],
        data(){
            return {
                selectedOption: 'call_method-arrange',
                stepData: null,
                stepIndex: null,
            }
        },
        methods: {
            updateCallMethod(e){
                const showSteps = {
                    'call_method-arrange': [0, 1, 2, 4, 5, 6, 7, 8, 9, 15, 16, 17],
                    'call_method-now': [0, 1, 3],
                }

                Object.entries(showSteps).forEach(([key, value]) => {
                    if(key === e.target.value){
                        this.$emit('update-steps', {'type': 'callMethod', 'key': key, 'value': value});
                    }
                });

            }
        },
        mounted(){
            this.selectedOption = this.selected;

            this.stepData = this.step;
            this.stepIndex = this.step_index;

            if(this.stepData.index !== this.stepIndex){
                return;
            }
            const checkedRadio = document.querySelector('input[value="'+this.selectedOption+'"]');

            checkedRadio.checked;

            checkedRadio.dispatchEvent(new Event('change'));
        }
    }

</script>