<template>
    <div>
        <p style="margin-bottom:50px"><strong>Please choose a contact method based upon the nature of your enquiry ...</strong></p>

        <div class="text-section">
            <strong>Operations department</strong>
            <p>If you are a supplier with any questions relating to trip which is presently travelling, please <br>
contact our our office in South Africa on <a href="mailto:operations@africatravelresource.com">operations@africatravelresource.com</a></p>
        </div>
        <div class="text-section">
            <strong>Purchasing department</strong>
            <p>If you are a supplier with any questions relating to a booking or purchase order, please contact<br>
our office in South Africa on <a href="mailto:purchasing@africatravelresource.com">purchasing@africatravelresource.com</a></p>
        </div>
        <div class="text-section">
            <strong>Product department</strong>
            <p>If you are a supplier wanting to talk to us about your services, please contact our office in <br>
London on <a href="mailto:web@africatravelresource.com">web@africatravelresource.com</a></p>
        </div> 
    </div>
    
</template>

<script>

    export default {
        props: {
            progress: {
                type: Number,
                required: false,
                default: 0,
            },
        },
    }

</script>