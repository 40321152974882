<template>
    <div>
        <section class="right-side map-container">
            <map-presentational
                    :args="mapArgs"
                    :classes="['vignette', '-vignette-transparent', 'vignette-map']"
                    type="location"></map-presentational>
        </section>

        <atr-fullpage id="lodge-page" ref="atrFullpage">
            <template v-if="loaded">
                <section-intro-presentational>

                    <transition name="fade-in">
                        <div class="map-tooltip " v-show="mapClicked" @click="hidePopup">
                            <div class="map-popup info-container">
                                <div class="inner-panel info-inner">
                                    <p class="info-popup-content">
                                        Click here to to Explore this location on a map
                                    </p>
                                </div>
                            </div>
                        </div>
                    </transition>

                    <div class="component-text -lodge-info custom-section" :data-block-id="location.top_section.id" @click="mapPopup($event)">
                        <div class="inner">
                            <div class="inner-container">
                                <div class="width-container">
                                    <div class="button-fav visible-xs"
                                         :class="{on: check}"
                                         aria-label="Add to Wishlist"
                                         @click="toggleWishlist">
                                    </div>

                                    <div class="breadcrumbs sub-text">
                                        <button-internal-presentational v-if="this.location.parents"
                                                                        classes="sub-text -light"
                                                                        :router_object="{name: 'single-location', params: getSlugFromParent(location.country)}">
                                            {{ location.country }}
                                        </button-internal-presentational>

                                        <div v-else class="sub-text">{{ location.country }}</div>

                                        <!--<span classes="sub-text -light">{{ location.country }}</span>-->
                                    </div>

                                    <div class="heading-container">
                                        <div class="button-fav hidden-xs"
                                             :class="{on: check}"
                                             aria-label="Add to Wishlist"
                                             @click="toggleWishlist">
                                        </div>

                                        <h1 class="heading3 heading custom-section-title" :data-pnp-heading="location.top_section.tag" :data-block-id="location.top_section.id">{{ location.title }}</h1>
                                    </div>

                                    <div class="summary custom-section-content" v-html="location.top_section.content" :contenteditable="editing" v-if="location.edit === false"></div>
                                    <div class="on-page-editor-container" v-else>
                                        <p class="summary custom-section-content" v-html="location.top_section.content" :contenteditable="editing"></p>
                                        <div class="smallEditButtons -horizontal" :class="{ '-editing': editing }">
                                            <div @click="createEditor('custom-section-content')" class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton"></div>
                                            <div @click="saveEditor('custom-section-content')" class="icon ion-ios-checkmark onPageEditor-smallButton btn-success smallSaveButton"
                                                 v-if="editing===true"></div>
                                            <div @click="cancelEditor('custom-section-content')" class="icon ion-ios-close onPageEditor-smallButton btn-danger smallCancelButton"
                                                 v-if="editing===true"></div>
                                        </div>
                                    </div>

                                    <hr />

                                    <br />

                                    <info-container classes="rating-container" :value="location.rating ? location.rating : '0.0'">
                                        <template slot="header">
                                            <div class="sub-text">Location <br/>RATING</div>
                                        </template>

                                        <template slot="popup-header">
                                            <p>Location rating</p>
                                        </template>

                                        <template slot="popup-text">
                                            <p>
                                                This number represents the <strong>peak rating at any lodge</strong> in <strong>{{location.title}}</strong>
                                                <span v-if="month <= 0"> in <strong>any month</strong>.</span>
                                                <span v-else> in <strong>{{ months[month - 1] }}</strong>.</span>

                                                The lodge rating is an overall score which takes into account all the various component ratings of a property, its location,
                                                build, style, service level and experiences. So this number is a simple indication of how good staying in this location can get.
                                            </p>

                                            <div class="links">
                                                <button-internal-presentational
                                                        classes="link"
                                                        :router_object="{path: hardcodedUrls.resources.overallRating}"
                                                        transition_name="wipe">
                                                    Our ratings system
                                                </button-internal-presentational>
                                            </div>
                                        </template>
                                    </info-container>

                                    <price :price="location.price" :minPrice="location.min_price">
                                        <template slot="header">
                                            <span class="sub-text" v-if="location.min_price">
                                                GUIDE PRICE {{ this.$store.state.user.user.selected_currency}}<br/> PER PERSON PER NIGHT
                                            </span>
                                            <span v-else class="sub-text">
                                                GUIDE PRICE <br/>{{ this.$store.state.user.user.selected_currency}} PPPN
                                            </span>
                                        </template>

                                        <template slot="popup-header">
                                            Location guide price
                                        </template>

                                        <template slot="popup-content">
                                            <p>

                                                These numbers represent the approximate range of price per person per night in
                                                <span v-if="month <= 0"><strong>any month</strong></span>
                                                <span v-else><strong>{{ months[month - 1] }}</strong></span> for the highly rated <strong>lodges</strong> in
                                                <strong>{{location.title}}</strong>.
                                                The lower end is the average low season <strong>lodge</strong> price.
                                                The upper end is the average high season <strong>lodge</strong> price plus any
                                                extras which usually need to be included, such as meals,
                                                drinks and activities, transport costs and park fees.

                                            </p>

                                            <div class="links">
                                                <button-internal-presentational
                                                        classes="link"
                                                        :router_object="{path: hardcodedUrls.resources.priceGuarantee}"
                                                        transition_name="wipe">
                                                    Price guarantee</button-internal-presentational> :

                                                <button-internal-presentational
                                                        classes="link"
                                                        :router_object="{path: hardcodedUrls.resources.howMuchToSpend}"
                                                        transition_name="wipe">
                                                    How much to spend</button-internal-presentational> :

                                                <button-internal-presentational
                                                        classes="link"
                                                        :router_object="{path: hardcodedUrls.resources.howToSaveMoney}"
                                                        transition_name="wipe">
                                                    How to save money</button-internal-presentational>
                                            </div>
                                        </template>
                                    </price>

                                    <info-container classes="consrating-container" :value="location.conservation_rating" v-if="location.conservation_rating >= 7">
                                        <template slot="header">
                                            <div class="sub-text">CONSERVATION <br/>RATING</div>
                                        </template>

                                        <template slot="popup-header">
                                            <p>Location conservation rating</p>
                                        </template>

                                        <template slot="popup-text">
                                            <p>The conservation rating is an indication of how much a particular location or lodge needs your visit to further the protection of their landscapes and wildlife.</p>
                                            <p>We recommend that you consider including at least one lodge with a high conservation rating in your trip.</p>

                                            <p>This particular number represents the <strong>highest conservation rating</strong>
                                                for <strong>any lodge</strong> in <strong>{{location.title}}</strong> in
                                                <span v-if="month <= 0"><strong>any month</strong>.</span>
                                                <span v-else><strong>{{ months[month - 1] }}</strong>.</span>
                                            </p>

                                            <div class="links">
                                                <button-internal-presentational
                                                        classes="link"
                                                        :router_object="{path: hardcodedUrls.resources.conservation}"
                                                        transition_name="wipe">
                                                    Conservation rating
                                                </button-internal-presentational>
                                            </div>
                                        </template>
                                    </info-container>
                                </div>

                                <div class="scroll-btn-container ">
                                    <div class="single-scroll-btn" @click="scroll">
                                        <i class="ion-ios-arrow-thin-down"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section-intro-presentational>

                <section-container
                    v-for="section in location.sections.data"
                    v-if="!section.hidden && !triggerReload"
                    :key="section.id"
                    :section="section"
                    :classes="'section'"
                    :edit="location.edit"
                    :item_title="location.title"
                    type="location">
                </section-container>

                <wishlist-add-presentational v-if="showOuts" :item="location" type="location" :name="location.title"></wishlist-add-presentational>

                <alternatives v-if="showOuts" :item="location" type="best" sectionTitle="Best" prefix="Find the best trips including">
                    <div class="number-container"><span class="yellow-bar"></span>02</div>
                    <h2 class="heading4">Best</h2>

                    <p class="content">If you like this look of this experience, then you can use these links to dig deeper ...</p>
                </alternatives>

                <alternatives v-if="showOuts" :item="location" type="location" sectionTitle="Alternatives" prefix="Find the best locations in">
                    <div class="number-container"><span class="yellow-bar"></span>03</div>
                    <h2 class="heading4">Alternatives</h2>

                    <p class="content">If you are not sure about this location, then here’s some options ...</p>
                </alternatives>

                <alternatives v-if="showOuts" :item="location" type="contact" sectionTitle="Contact">
                    <div class="number-container"><span class="yellow-bar"></span>04</div>
                    <h2 class="heading4">Get in touch</h2>

                    <p class="content">It's never too soon to get in touch, we are always keen to help out, no matter how formulative or advanced your plans may be ...</p>

                    <!--<div class="buttons -alternatives">-->
                        <!--<button-internal-presentational-->
                                <!--:router_object="{ name: 'contact' }"-->
                                <!--classes="-next-step"-->
                                <!--icon="ion-ios-arrow-thin-right"-->
                                <!--v-on:btn-internal-clicked="googleAnalytics.fireEvent(analyticsEventCategory, 'Click Contact Us', $route.path)">-->
                            <!--<div>How to contact us</div>-->
                        <!--</button-internal-presentational>-->
                    <!--</div>-->
                </alternatives>

                <!--<section-outs-container :section="location.trips_section" :edit="location.edit" type="location_trips"></section-outs-container>-->

                <!--<section-outs-container :section="location.other_locations_section" :edit="location.edit" type="location_other_lodges"></section-outs-container>-->

                <!-- <next-up-presentational
                        v-if="showOuts"
                        :classes="'-next-lodge'"
                        page="location"
                        :item="location"
                        :data_heading="'Next'"
                        :data_next_lodge="true"
                        :text_next_item="'Next location'"
                        :text_explore="'Explore other locations'"
                        :directory="directory"
                >
                </next-up-presentational> -->
                <site-footer />
            </template>
        </atr-fullpage>

        <div v-if="location.edit === true">
            <template v-for="section in location.sections.data">
                <public-editor-buttons type="location_section" :section_id="section.id" :_id="section.id" :buttons="section.buttons"></public-editor-buttons>
                <template v-for="slide in section.slides.data">
                    <public-editor-buttons type="location_slide" :section_id="section.id" :_id="slide.id" :buttons="slide.buttons"></public-editor-buttons>
                </template>
            </template>
            <public-editor-links type="location_trips" :section_id="location.trips_section" :_id="location.trips_section.id" :links="location.trips_section.content"></public-editor-links>
            <public-editor-links type="location_other_locations" :section_id="location.other_locations_section" :_id="location.other_locations_section.id" :links="location.other_locations_section.content"></public-editor-links>
            <public-editor-meta type="location_"></public-editor-meta>
        </div>

        <div id="modal-veil"></div>
        <public-edit-mode-toggle />
        <!-- <transition-wipe/> -->
    </div>
</template>

<script>
    import StateUtils from '../../global/StateUtils';
    import GlobalVars from '../../global/GlobalVars';
    import pageIsReadyMixin from '../../mixins';
    import { mapState } from 'vuex';
    import LazyLoader from '../../global/LazyLoader';

    export default {
        mixins: [pageIsReadyMixin],
        data(){
            return {
                editing: false,
                editorName: null,
                type: 'location_main',
                showOuts: true,
                loaded: false,
                months: [],
                checked: false,
                triggerReload: false,
                mapClicked: false
            }
        },
        methods: {
            createEditor(which) {
                if(!window._.isUndefined(this.editorName) && this.editorName!==null){
                    alert('You are currently editing a different bit on the page.\nSave or Cancel it before starting editing on a new instance.');
                } else {
                    this.editing = true;
                    Vue.nextTick(() => {
                        var editor = window.CKEDITOR.inline(this.$el.querySelector('.on-page-editor-container .' + which), window.ckeditorConfig);
                        this.$el.querySelector('.on-page-editor-container .' + which).click();
                        this.editorName = editor.name;
                        this.$store.commit(window.store.SET_EDITOR_CKEDITOR, true, {root: true});
                    });
                }
            },
            cancelEditor(which) {
                this.editing = false;
                if (this.editorName !== null) {
                    window.CKEDITOR.instances[this.editorName].container.$.innerHTML = this.lodge.top_section.content;
                    window.CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;
                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, { root: true });
                }
            },
            saveEditor(which) {
                var stateObject = new StateUtils(this.type, this.$store).getStateObject();

                if (this.editorName !== null) {
                    stateObject.top_section.content = window.CKEDITOR.instances[this.editorName].container.$.innerHTML;
                    new StateUtils(this.type, this.$store).setStateObject(stateObject, null);
                    this.editing = false;
                    window.CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;
                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, { root: true });
                }
            },
            getSlugFromParent(title){
                let filteredArr = this.location.parents.data.filter(el => {
                    if(el.title === title){
                        return el;
                    }
                })[0];

                if(filteredArr){
                    return filteredArr.vue_route_params;
                }
                return [];
            },
            toggleWishlist(){
                let payload = {
                    id: this.location.id,
                    type: 'location'
                };

                // let check = this.wishlist.some((el) => {
                //     return el.id == this.location.id;
                // });

                let check = this.check;

                if(check) {
                    this.$store.commit(window.store.REMOVE_WISHLIST_ITEM, payload);
                } else {
                    this.$store.dispatch(window.store.SET_WISHLIST_ITEM_BY_ID, payload);
                }
            },
            lazyLoadHiddenImages(){
                let images = [];

                //Load any image slides (View More)
                if(this.location.sections.data && this.location.sections.data.length > 0) {
                    for (let i in this.location.sections.data) {
                        if (this.location.sections.data[i].hiddenSlides && this.location.sections.data[i].hiddenSlides.data.length > 0) {
                            for (let j in this.location.sections.data[i].hiddenSlides.data) {
                                if (pnpHelper.bootstrap.isXs()) {
                                    images.push(this.location.sections.data[i].hiddenSlides.data[j].bg_img_url_large);
                                } else {
                                    images.push(this.location.sections.data[i].hiddenSlides.data[j].img_url);
                                }
                            }
                        }
                    }
                }

                //Load any hidden section images (View All)
                if(this.location.hiddenSections.data && this.location.hiddenSections.data.length > 0) {
                    for (let i in this.location.hiddenSections.data) {

                        if(pnpHelper.bootstrap.isXs()){
                            images.push(this.location.hiddenSections.data[i].bg_img_url_large);
                        }else{
                            images.push(this.location.hiddenSections.data[i].bg_img_url);
                        }

                        if (this.location.hiddenSections.data[i].allSlides && this.location.hiddenSections.data[i].allSlides.data.length > 0) {
                            for (let j in this.location.hiddenSections.data[i].allSlides.data) {
                                if (pnpHelper.bootstrap.isXs()) {
                                    images.push(this.location.hiddenSections.data[i].allSlides.data[j].bg_img_url_large);
                                } else {
                                    images.push(this.location.hiddenSections.data[i].allSlides.data[j].img_url);
                                }
                            }
                        }
                    }
                }

                if(images.length > 0) {
                    let lazyLoader = new LazyLoader();
                    lazyLoader.loadImages(images);
                }
            },
            scroll(){
                window.bus.$emit('fullPageMoveSectionDown');
            },
            mapPopup($event){
                if(($($event.target).hasClass('-lodge-info') || $($event.target).hasClass('inner-container')) && !this.mapClicked) {
                    this.mapClicked = true;
                }
            },
            hidePopup(){
                this.mapClicked = false;
            }
        },
        computed: {
            hardcodedUrls() {
                return GlobalVars.HARCODED_URLS;
            },
            googleAnalytics() {
                return window.GoogleAnalytics;
            },
            analyticsEventCategory() {
                return 'Add ' + window.GoogleAnalytics.parseVueRouteParams(this.$route.params);
            },
            location() {
                let location = this.stateLocation;

                let { price, guide_price, conservation_rating, rating } = this.$route.params;

                return {
                    ...location,
                    price: price ? price : location.price,
                    guide_price: guide_price ? guide_price : location.guide_price,
                    conservation_rating: conservation_rating ? conservation_rating : location.conservation_rating,
                    rating: rating ? rating : location.rating
                };
            },
            mapArgs() {
                return [{
                    lat: this.location.lat,
                    lng: this.location.lng,
                    image: this.location.image,
                    id: this.location.id,
                    title: this.location.title,
                    price: this.location.price,
                    rating: this.location.rating,
                    zoom: 15
                }];
            },
            directory(){
                if(this.$route.params){
                    return this.$route.params.directory;
                }

                return false;
            },
            check(){
                return this.wishlist.some((el) => {
                    return el.id == this.location.id;
                });
            },
            ...mapState({
                month: state => state.search.criteria.params.month,
                stateLocation: state => state.singleLocation.location,
                wishlist: state => state.wishlist.selected
            })
        },
        beforeRouteUpdate(to, from, next) {
            //Only do this if nwe're going from location to location (i.e. next up module or quicksearch)
            if($('body').hasClass('-disable-page-wipe')) {

                this.triggerReload = true;

                let transitionPromise = new Promise((resolve, reject) => {
                    $('.-lodge-info').find('.inner').removeClass('-in');

                    resolve();
                });

                transitionPromise.then(() => {
                    this.triggerReload = false;

                    this.$store.dispatch(window.store.FETCH_AND_SET_LOCATION, to.params['slug']).then(() => {
                        window.bus.$emit('fullPageDestroying');
                        window.bus.$emit('fullPageDestroyed');
                        window.bus.$emit('fullPageInit');

                        next();

                    }).then(() => $('.-lodge-info').find('.inner').addClass('-in')).catch(() => {
                        this.$router.push({ name: 'page404' });

                    }).finally(() => {
                        setTimeout(() => {
                            $('body').removeClass('-disable-page-wipe')
                        },2000);
                    });;

                })
            }else {
                next();
            }
        },
        mounted() {
            //window.bus.$emit('setShowBackButton', false);

            if(Object.keys(this.$route.params).length > 0 && this.$route.params.hasOwnProperty('showOuts')) {
                this.showOuts = this.$route.params.showOuts;
            }

            this.$store.dispatch(window.store.FETCH_AND_SET_LOCATION, this.$route.params['slug']).then(() => {
                setTimeout(() => {
                    if(this.$refs.atrFullpage) {
                        this.$refs.atrFullpage.init();
                    }
                }, 200);

                this.loaded = true;
            }).catch(() => {
                this.$router.push({ name: 'page404' });
            });

            window.bus.$once('fullPageAfterDomReady', this.declarePageIsReady);
            window.bus.$once('lazyloadAllLoaded', this.lazyLoadHiddenImages);

            this.months = GlobalVars.MONTHS;
        },
        beforeDestroy() {
            window.bus.$emit('setShowBackButton', false);
        }
    }
</script>
