import Promise from 'es6-promise';

export default class StateUtils {
    constructor(type, store) {
        this.type = type;
        this.$store = store;
        this.isEdit = window.location.href.indexOf('edit=1');
    }

    getStateObject() {
        var stateObject = null;
        if (this.type.indexOf('lodge_') !== -1) {
            stateObject = window._.defaultsDeep({}, this.$store.state.singleLodge.lodge);
        } else if (this.type.indexOf('resource_') !== -1) {
            stateObject = window._.defaultsDeep({}, this.$store.state.singleResource.resource);
        } else if (this.type.indexOf('experience_') !== -1) {
            stateObject = window._.defaultsDeep({}, this.$store.state.singleExperience.experience);
        } else if (this.type.indexOf('location_') !== -1) {
            stateObject = window._.defaultsDeep({}, this.$store.state.singleLocation.location);
        } else if (this.type.indexOf('multi_re_') !== -1) {
            stateObject = window._.defaultsDeep({}, this.$store.state.singleMultiResource.resource);
        }
        return stateObject;
    }

    getArticlesStateObject() {
        var stateObject = null;
        if (this.type.indexOf('lodge_') !== -1) {
            stateObject = window._.defaultsDeep({}, this.$store.state.singleLodge.lodge.articleLinks);
        } else if (this.type.indexOf('resource_') !== -1) {
            stateObject = window._.defaultsDeep({}, this.$store.state.singleResource.resource.articleLinks);
        } else if (this.type.indexOf('experience_') !== -1) {
            stateObject = window._.defaultsDeep({}, this.$store.state.singleExperience.experience.articleLinks);
        } else if (this.type.indexOf('location_') !== -1) {
            stateObject = window._.defaultsDeep({}, this.$store.state.singleLocation.location.articleLinks);
        } else if (this.type.indexOf('multi_re_') !== -1) {
            stateObject = window._.defaultsDeep({}, this.$store.state.singleMultiResource.resource.articleLinks);
        }
        return stateObject;
    }

    setStateObject(stateObject, button) {
        if (button !== null && button.action !== 'openExternal' && !$('.no-scroll-hijack').length) {

            this.destroyFullPage().then(() => {
                this.manageState(stateObject);
                this.reinitFullPage().then(() => {
                    if (button.action === 'showMore' || button.action === 'showAll') {
                        Vue.nextTick(() => {
                            window.bus.$emit('fullPageMoveSectionDown');
                        });
                    }
                });
            });
        } else {
            if (button !== null && !_.isUndefined(button.action) && (button.action == 'showMore' || button.action == 'showAll') ) {
                window.bus.$emit('goToNextSlide');
            }

            this.manageState(stateObject);
        }
    }

    manageState(stateObject){
        var editorType = null;
        if (this.type.indexOf('lodge_') !== -1) {
            editorType = window.editorTypes.LODGE;
            this.$store.commit(window.store.SET_LODGE, stateObject);
        } else if (this.type.indexOf('resource_') !== -1) {
            editorType = window.editorTypes.RESOURCE;
            this.$store.commit(window.store.SET_RESOURCE, stateObject);
        } else if (this.type.indexOf('experience_') !== -1) {
            editorType = window.editorTypes.EXPERIENCE;
            this.$store.commit(window.store.SET_EXPERIENCE, stateObject);
        } else if (this.type.indexOf('location_') !== -1) {
            editorType = window.editorTypes.LOCATION;
            this.$store.commit(window.store.SET_LOCATION, stateObject);
        } else if (this.type.indexOf('multi_re_') !== -1) {
            editorType = window.editorTypes.MULTI_RESOURCE;
            this.$store.commit(window.store.SET_MULTI_RESOURCE, stateObject);
        }
        if (this.isEdit !== -1 && editorType !== null) {
            this.$store.dispatch(window.store.SET_EDITOR_DIRTY, editorType, {root: true});
        }
    }

    destroyFullPage() {
        var promise = new Promise(function (resolve, reject) {
            if (!_.isUndefined(window.fullpage)) {
                //window.fullpage.destroy('all');
                var activeSectionIndex = window.$('.fp-section.active').index();
                window.bus.$emit('fullPageDestroying', {
                    promise: {
                        resolve: resolve,
                        reject: reject
                    }
                });
                $('.section').eq(activeSectionIndex).addClass('active');
            }
        });
        return promise;
    }

    reinitFullPage() {
        var promise = new Promise(function (resolve, reject) {
            window.bus.$emit('fullPageInit', {
                promise: {
                    resolve: resolve,
                    reject: reject
                }
            });
        });
        return promise;
    }
}
