<template>
    <div>
        <p><strong>Please choose a number based upon your location ...</strong></p>

        <div class="text-section">
            <strong>USA and Canada : 888-487 5418</strong>
            <p>This freecall number provides access to our safari experts on regular working days. 4am-4pm East <br>
Coast, 1am-1pm West Coast. During the earlier part of those periods, calls may be fielded by <br>
London, but you can be referred back to America if you wish. If you can't get through, return to the <br>
previous page and get us to return your call.</p>
        </div>
        <div class="text-section">
            <strong>UK : 01306 880770</strong>
            <p>This number provides access to our safari experts on regular working days. 9am-9pm London.<br> 
In the evenings, calls may be fielded by America, but you can be referred back to London if you <br>
wish. If you cannot get through, return to the previous page and get us to return your call.</p>
        </div>
        <div class="text-section">
            <strong>Europe : +44 1306 880770</strong>
            <p>This number provides access to our safari experts on regular working days 10am-10pm Central <br>
Europe. In the evenings, calls may be fielded by America, but you can be referred back to London <br>
if you wish. If you cannot get through, return to the previous page and get us to return your call.</p>
        </div> 
        <div class="text-section">
            <strong>Australia : +61 800 821 832</strong>
            <p>This number provides access to our safari experts on regular working days 6pm-6am Sydney. <br>
Lines are fielded by our UK and American offices. If you cannot get through, please go back to <br>
the previous page and request that we return your call.</p>
        </div> 

    </div>
    
</template>

<script>

    export default {
        props: {
            progress: {
                type: Number,
                required: false,
                default: 0,
            },
        },
    }

</script>