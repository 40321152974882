<template>
    <div class="section" data-pnp-interactive-map="true">
        <slot />
    </div>
</template>

<script>
    import Shortcodes from "./Shortcodes";
    export default {
        components: {Shortcodes},
    }
</script>