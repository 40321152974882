<template>
    <div class="call-page split-page">
        <half-page-scroll-hijack>
            <section class="left-side article-images-container -in" v-if="!isMobile">
                <template>
                    <div class="article-images -showing-images -in">
                        <transition-group name="fade" mode="in-out">
                            <article-image-presentational
                                    :key="currentArticle.image"
                                    :image="currentArticle.image"
                                    :title="currentArticle.title"
                                    :content="currentArticle.content"
                                    :route_params="currentArticle.vue_route_params">
                            </article-image-presentational>
                        </transition-group>
                    </div>
                </template>
            </section>

            <div class="right-side">
                <div class="nav-veil -right"></div>

                <section class="container -full-page">
                    <span class="yellow-bar"></span>

                    <h1 class="heading4 heading">Call</h1>

                    <p class="content">
                        It will be a pleasure to speak with you on the phone. Please scroll down to find the most appropriate number on which to call us …
                    </p>

                    <div class="scroll-btn">
                        <button-external-presentational
                                @click="scrollTo"
                                icon="ion-ios-arrow-thin-down"
                                classes="scroll-down-arrow">
                        </button-external-presentational>
                    </div>
                </section>

                <section class="container -full-page">
                    <div class="number-container"><span class="yellow-bar"></span>01</div>

                    <p class="content">
                        If you live in Canada and USA then it is probably best to call our office in Michigan …
                        <br>
                    </p>

                    <p class="content">
                        <span class="contact-details">
                            <div>
                                <div class="contact-label">USA Freecall</div>
                                <div class="contact-label">USA</div>
                                <!--<div class="contact-label">International</div>-->
                            </div>

                            <div class="phone-numbers">
                                <a class="contact-value" href="tel:+8884875418">888-487-5418</a>
                                <a class="contact-value" href="tel:+3137442871">313-744-2871</a>
                                <!--<a class="contact-value" href="tel:+13137442871">+ 1 313-744-2871</a>-->
                            </div>
                        </span>
                    </p>

                    <p class="content">
                        Office hours Mon-Fri …<br/>
                    </p>

                    <p class="content">
                        <span class="contact-details">
                             <div>
                                <div class="contact-label">US East Coast</div>
                                <div class="contact-label">US Pacific Coast</div>
                            </div>

                            <div class="phone-numbers">
                                <div class="contact-value">11am-9pm</div>
                                <div class="contact-value">8am-6pm</div>
                            </div>
                        </span>
                    </p>

                    <p class="content">If you call earlier then you may be transferred to our UK office, where they are several hours ahead and should be able to assist you equally well.</p>
                </section>

                <section class="container -full-page">

                    <!--<div class="heading4 heading">Call</div>-->

                    <!--<span class="yellow-bar"></span>-->

                    <div class="number-container"><span class="yellow-bar"></span>02</div>

                    <p class="content">
                        If you live in Africa, Asia or Australasia, Europe, UK, Central America or South America then it’s probably best for you to call our UK office …<br>
                    </p>

                    <p class="content">

                        <span class="contact-details">
                             <div>
                                <div class="contact-label">International</div>
                                <div class="contact-label">UK</div>
                            </div>

                            <div class="phone-numbers">
                                <a href="tel:+441306880770" class="contact-value">+ 44 1306 880770</a>
                                <a href="tel:01306880770" class="contact-value">01306 880770</a>
                            </div>
                        </span>
                    </p>

                    <p class="content">
                        Office hours Mon-Sat …<br/>
                    </p>

                    <p class="content">
                        <span class="contact-details">
                             <div>
                                <div class="contact-label">Australia East</div>
                                <div class="contact-label">Europe Central</div>
                                <div class="contact-label">UK</div>
                                <div class="contact-label">Gulf</div>
                            </div>

                            <div class="phone-numbers">
                                <div class="contact-value">1800-0700 hrs</div>
                                <div class="contact-value">0900-2000 hrs</div>
                                <div class="contact-value">0800-1900 hrs</div>
                                <div class="contact-value">1200-2300 hrs</div>
                            </div>
                        </span>
                    </p>

                    <p class="content">Please note that Saturday hours tend to be a little shorter. If you call later than these times, you may be transferred to our USA office, which is several hours behind and should be able to assist you equally well.</p>
                </section>

                <section class="container -full-page" >
                    <div class="number-container"><span class="yellow-bar"></span>03</div>

                    <p class="content">If you already know who you want to speak with, then you can look up their contact details in the Meet the Team section …</p>

                    <button-internal-presentational
                            :router_object="{path: hardcodedUrls.finder.resources}"
                            classes="-next-step"
                            icon="ion-ios-arrow-thin-right">
                        <div>Resources</div>
                    </button-internal-presentational>
                </section>

                <section class="container -full-page" >
                    <div class="number-container"><span class="yellow-bar"></span>04</div>

                    <p class="content">If you are a trade partner then you can contact us on the following numbers …</p>

                    <p class="content">
                        <span class="contact-details">
                             <div>
                                <div class="contact-label">Product</div>
                                <div class="contact-label">Accounts</div>
                            </div>

                            <div class="phone-numbers">
                                <a href="tel:+441306880770" class="contact-value">+ 44 1306 880770</a>
                                <a href="tel:+27218764913" class="contact-value">+ 27 21 300 2323</a>
                            </div>
                        </span>
                    </p>
                </section>

                <section class="container -full-page">
                    <div class="number-container"><span class="yellow-bar"></span>05</div>

                    <!--<p class="content">Please don’t forget to sign into the website so that we can save your wishlist items ...</p>-->

                    <!--<button-internal-presentational-->
                            <!--url="#"-->
                            <!--classes="-next-step"-->
                            <!--icon="ion-ios-arrow-thin-right">-->
                        <!--<div>Sign in</div>-->
                    <!--</button-internal-presentational>-->

                    <p class="content">If you are not yet ready to make a call, then you can explore the site for more experiences, locations, lodges and trips and use our Livechat feature (during opening hours) if you need us for anything in the meantime.</p>

                    <button-internal-presentational
                            :router_object="{name: 'explore'}"
                            classes="-next-step"
                            icon="ion-ios-arrow-thin-right">
                        <div>Explore</div>
                    </button-internal-presentational>
                </section>

        </div>
        </half-page-scroll-hijack>

        <!-- <transition-wipe/> -->
    </div>
</template>

<script>
    import LazyLoader from '../../global/LazyLoader';
    import pageIsReadyMixin from '../../mixins';
    import GlobalVars from '../../global/GlobalVars';

    export default {
        mixins: [pageIsReadyMixin],
        data(){
            return {
                currentArticle: {
                    image: 'https://atrimages.azureedge.net/cdn/contact/0/02/02ATR0300-IM0002-atr-safari-contact-1475.jpg',
                    content:"",
                    title:"",
                    url: undefined
                },
                articlesArr: [
                    { image: 'https://atrimages.azureedge.net/cdn/contact/0/02/02ATR0300-IM0002-atr-safari-contact-1475.jpg' },
                    { image: 'https://atrimages.azureedge.net/cdn/contact/call/0/01/01ATR0301-IM0001-atr-safari-call-1475.jpg' },
                    { image: 'https://atrimages.azureedge.net/cdn/contact/call/0/02/02ATR0301-IM0002-atr-safari-call-1475.jpg' },
                    { image: 'https://atrimages.azureedge.net/cdn/contact/call/0/03/03ATR0301-IM0003-atr-safari-call-1475.jpg' },
                    { image: 'https://atrimages.azureedge.net/cdn/contact/call/0/04/04ATR0301-IM0004-atr-safari-call-1475.jpg' },
                    { image: 'https://atrimages.azureedge.net/cdn/contact/call/0/05/05ATR0301-IM0005-atr-safari-call-1475.jpg' }
                ]
            }
        },
        methods: {
            scrollTo() {
                window.bus.$emit('half-page-hijack-nextSection');
            },
            scrollifyBeforeMove(index, sections){
                this.currentArticle.image = this.articlesArr[index].image;
            },
            setCurrentArticleImage(){
                this.$store.commit(window.store.SET_CURRENT_ARTICLE_IMAGE, this.currentArticle.image);
            }
        },
        mounted(){

            this.$store.commit(window.store.SET_PAGE_TITLE, this.$route.meta['page_title']);
            this.$store.commit(window.store.SET_META_DESCRIPTION, this.$route.meta['meta_description']);

            if(!this.isMobile) {
                let lazyLoader = new LazyLoader();
                lazyLoader.loadArticleImages(this.articlesArr);
            }
        },
        computed:{
            isMobile(){
                return window.pnpHelper.bootstrap.isXs();
            },
            hardcodedUrls() {
                return GlobalVars.HARCODED_URLS;
            }
        },
        beforeDestroy(){
            window.bus.$emit('setShowBackButton', false);
        },
        created(){
            $(window).scrollTop(0);

            window.bus.$on('scrollify-beforeMove', this.scrollifyBeforeMove);
            window.bus.$on('back-btn-click', this.setCurrentArticleImage);
            this.declarePageIsReady();
        },
        destroyed() {
            window.bus.$off('scrollify-beforeMove', this.scrollifyBeforeMove);
            window.bus.$off('back-btn-click', this.setCurrentArticleImage);
        }
    }
</script>