<template>
    <mounting-portal v-if="shouldMountPortal" name="testing" mount-to=".mapboxgl-popup div[data-popover-marker-id] .caption">
        <map-popover :id="markerId" :type="markerType" />
    </mounting-portal>
</template>

<script>
    import GlobalVars from "../../../global/GlobalVars";

    export default {
        name: "MapPopoverPortal",
        data() {
            return {
                shouldMountPortal: false,
                markerId: false,
                markerType: false
            }
        },
        created() {
            $(window).on('reload-portal', (e, args) => {
                this.reloadPortal(args.markerId, args.markerType)
            });
        },
        methods: {
            reloadPortal(markerId,markerType) {
                if(!markerType){
                    var tempType = this.$store.state.search.criteria.params.type;

                    if(tempType === 'lodge' || pnpHelper.page.isDirectoryPage()){
                        markerType = GlobalVars.LODGE;

                    }else if(tempType === 'location'){
                        markerType = GlobalVars.LOCATION;
                    }else{
                        console.error('No type found');
                    }
                }

                this.markerId = markerId;
                this.markerType = markerType.toString();
                this.shouldMountPortal = false;

                Vue.nextTick(() => {
                    this.shouldMountPortal = true;
                });
            }
        }
    }
</script>