/*!
 * fullpage.js Parallax Effect Extension 0.0.9
 * https://github.com/alvarotrigo/fullPage.js
 *
 * This code has been bought from www.alvarotrigo.com/fullPage/extensions/ and it is not free to use or distribute.
 * Copyright (C) 2016 alvarotrigo.com - A project by Alvaro Trigo
 */
! function(n) {
    window.fp_parallaxExtension = function() {
        var $heightElem = jQuery('#first-load-veil');

        function windowHeight() {
            if(window.pnpHelper.bootstrap.isXs()) {
                return $heightElem.height();
            } else {
                return jQuery(window).height();
            }
        }

        function e(n) {
            return {
                cover: {
                    offsetNormal: n ? 0 : O
                },
                reveal: {
                    offsetNormal: n ? -O : 0
                }
            }
        }

        function t(n) {
            return {
                cover: {
                    offsetNormal: n ? 0 : F
                },
                reveal: {
                    offsetNormal: n ? -F : 0
                }
            }
        }

        function o(n) {
            return n * B / 100
        }

        function i(n) {
            return n * E / 100
        }

        function a() {
            if (H) {
                var e = n(W).length ? n(W) : n(K).first();
                q(e, 0)
            }
        }

        function l() {
            var t = n(W).length ? n(W) : n(K).first(),
                o = T === tn,
                i = o ? t.nextAll() : t.prevAll(),
                a = o ? t.prevAll() : t.nextAll();
            i.each(function() {
                q(n(this), e(o)[T].offsetNormal, "silent")
            }), a.each(function() {
                q(n(this), 0, "silent")
            }), bn && n(K).each(function() {
                var e = n(this).find(Y);
                if (e.length) {
                    var t = n(this).find(Z).length ? n(this).find(Z) : n(this).find(Y).first();
                    f(t)
                }
            })
        }

        function f(e) {
            var o = T === tn,
                i = o ? e.nextAll() : e.prevAll(),
                a = o ? e.prevAll() : e.nextAll();
            i.each(function() {
                I(n(this), t(o)[T].offsetNormal, "silent")
            }), a.each(function() {
                I(n(this), 0, "silent")
            })
        }

        function r(n, e) {
            E = wn.width(), e && !j.scrollBar ? (y(), S()) : A()
        }

        function s(n) {
            V.destroy(), mn = !1
        }

        function c() {
            n(Q).on("setAutoScrolling", r).on("destroy", s).on("onScroll", x).on("afterResponsive", u).on("onGrab", p).on("onContinuousVertical", h).on("onResize", b).on("afterRender", d).on("afterRebuild", d), bn && n(Q).on("onContinuosHorizontal", m).on("onResetSliders", v).on("onEndResetSliders", g)
        }

        function d() {
            var e = j.verticallyCentered ? ".fp-tableCell" : ".fp-scrollable";
            n(e).each(function() {
                n(this).find(en).prependTo(n(this).parent())
            })
        }

        function p(n, e) {
            e ? A() : yn = !0
        }

        function u(e, t) {
            n(en).attr("data-final-x", 0), n(en).attr("data-final-y", 0), k = document.querySelectorAll(K), l()
        }

        function v(n, e) {
            Sn = !0, q(e, 0, "silent"), un = !0
        }

        function g(n, e) {
            V.afterSlideLoads(e.find(Y).first()), Sn = !1
        }

        function m(e, t) {
            hn = !0;
            var o = "left" === t.xMovement ? n(W).find(Y).first() : n(W).find(Y).last();
            f(o), setTimeout(function() {
                V.applyHorizontal(t)
            })
        }

        function h(n, e) {
            xn = !0, l(), setTimeout(function() {
                V.apply(e)
            })
        }

        function x() {
            !cn && mn && (j.scrollBar || !j.autoScrolling || D.usingExtension("dragAndMove")) && (requestAnimationFrame(R), cn = !0)
        }

        function y() {
            var n = ".fp-bg{transition: transform " + j.scrollingSpeed + "ms " + j.easingcss3 + "}.fp-slide, .fp-section{will-change: transform;transition: background-position " + j.scrollingSpeed + "ms " + j.easingcss3 + ";}";
            j.autoScrolling && !j.scrollBar && z(ln, n)
        }

        function S() {
            var n = ".fp-bg-animate{ transition: all " + j.scrollingSpeed + "ms " + j.easingcss3 + "}";
            z(rn, n)
        }

        function A() {
            n(fn).remove()
        }

        function b() {
            clearTimeout(Mn), Mn = setTimeout(w, 350)
        }

        function w() {
            B = windowHeight(), E = wn.width(), O = o(j.parallaxOptions.percentage), F = i(j.parallaxOptions.percentage), a(), l(), M()
        }

        function M() {
            n(en).height(B)
        }

        function R() {
            var t = D.usingExtension("dragAndMove") ? Math.abs(n.fn.fullpage.dragAndMove.getCurrentScroll()) : n(window).scrollTop(),
                o = dn > t,
                i = n(W).index(K),
                a = B + t;
            dn = t;
            for (var l = 0; l < k.length; ++l) {
                var f = k[l],
                    r = B + f.offsetTop;
                !o && f.offsetTop <= a ? i = l : o && r >= t && f.offsetTop < t && k.length > l + 1 && (i = l + 1)
            }
            var s = B - (k[i].offsetTop - t),
                c = s * O / B;
            T !== tn && (i -= 1);
            var d = T !== tn ? c : -O + c;
            q(n(K).eq(i), d), i - 1 >= 0 && q(n(k[i - 1]), e(!1)[T].offsetNormal), "undefined" != typeof k[i + 1] && q(n(k[i + 1]), e(!0)[T].offsetNormal), cn = !1
        }

        function C(n) {
            return Math.round(2 * n) / 2
        }

        function q(n, e, t) {
            var o = C(e, 1),
                i = n.find(Y);
            if (i.length) {
                var a = i.filter(_);
                n = a.length ? a : i.first()
            }
            if (H) n.css({
                "background-position-y": o + "px"
            });
            else if (!n.hasClass(X) || n.hasClass(U) || "undefined" != typeof t) {

                if(!window.$('body').hasClass('modal-open') && !window.$('body').hasClass('nav-open')) {
                    var l = n.find(en),
                        f = "undefined" != typeof l.attr("data-final-x") ? l.attr("data-final-x") : 0;
                    l.toggleClass($, "undefined" != typeof t).css({
                        transform: "translate3d(" + f + "px, " + o + "px, 0)"
                    }).attr("data-final-x", f).attr("data-final-y", o)
                }
            }
        }

        function I(n, e, t) {
            var o = C(e, 1),
                i = H ? n : n.find(en);
            if (!j.scrollBar && j.autoScrolling || i.addClass("fp-bg-animate"), H) i.toggleClass($, "undefined" != typeof t).css("background-position-x", o + "px");
            else {
                var a = 0,
                    l = i.attr("data-final-y");
                "none" !== l && "undefined" != typeof l && (a = l), i.toggleClass($, "undefined" != typeof t || Sn).css({
                    transform: "translate3d(" + o + "px, " + a + "px, 0)"
                }).attr("data-final-x", o).attr("data-final-y", a)
            }
        }

        function N(n) {
            return n.find(Y).length ? n.find(Y).length > 1 ? n.find(Y) : [n.find(Y)] : [n]
        }

        function z(e, t) {
            n("#" + e).length || n('<style id="' + e + '">' + t + "</style>").appendTo("head")
        }
        var T, O, F, B, E, k, H, L, V = this,
            G = n.fn.fullpage.getFullpageData(),
            j = G.options,
            D = G.internals,
            P = "fullpage-wrapper",
            Q = "." + P,
            U = "active",
            _ = "." + U,
            J = "fp-section",
            K = "." + J,
            W = K + _,
            X = "fp-slide",
            Y = "." + X,
            Z = Y + _,
            $ = "fp-notransition",
            nn = "fp-bg",
            en = "." + nn,
            tn = "reveal",
            on = "cover",
            an = "fp-parallax-stylesheet",
            ln = "fp-parallax-transitions",
            fn = "#" + ln,
            rn = "fp-parallax-transition-class",
            sn = "#" + rn,
            cn = !1,
            dn = 0,
            pn = !1,
            un = !1,
            vn = !0,
            gn = !0,
            mn = !1,
            hn = !1,
            xn = !1,
            yn = !1,
            Sn = !1,
            An = !1,
            bn = j.parallax === !0 || "slides" === j.parallax,
            wn = n(window);
        ! function() {
            var n = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
            window.requestAnimationFrame = n
        }(), V.apply = function(t) {
            if (yn && y(), !t.localIsResizing && !j.scrollBar && j.autoScrolling) {
                if (An = "up" !== t.yMovement && !t.sectionIndex || t.isMovementUp && !(t.leavingSection - 1), An && j.continuousVertical && !xn) return void(An = !1);
                pn = "up" === t.yMovement;
                var o = e(pn),
                    i = o[T].offsetNormal;
                q(n(K).eq(t.sectionIndex), 0), q(n(K).eq(t.leavingSection - 1), i), vn = 1 === Math.abs(t.leavingSection - 1 - t.sectionIndex);
                for (var a = Math.min(t.leavingSection - 1, t.sectionIndex) + 1; a < Math.max(t.leavingSection - 1, t.sectionIndex); a++) q(n(K).eq(a), 0, "silent")
            }
        }, V.applyHorizontal = function(n) {
            if (bn && (!n.localIsResizing || Sn) && "none" != n.xMovement) {
                if (L = "undefined" != typeof n.direction && n.direction !== n.xMovement, L && j.continuousHorizontal && !hn) return void(L = !1);
                un = L ? "left" === n.direction : "left" === n.xMovement;
                var e = t(un),
                    o = e[T].offsetNormal;
                if (I(n.section.find(Y).eq(n.slideIndex), 0), I(n.section.find(Y).eq(n.prevSlideIndex), o), gn = 1 === Math.abs(n.slideIndex - n.prevSlideIndex), !gn && !Sn)
                    for (var i = Math.min(n.slideIndex, n.prevSlideIndex) + 1; i < Math.max(n.slideIndex, n.prevSlideIndex); i++) I(n.section.find(Y).eq(i), 0, "silent")
            }
        }, V.init = function() {
            if (B = windowHeight(), E = wn.width(), T = j.parallaxOptions.type, O = o(j.parallaxOptions.percentage), F = i(j.parallaxOptions.percentage), k = document.querySelectorAll(j.sectionSelector), H = "background" === j.parallaxOptions.property, M(), c(), !H) {
                var n = ".fp-bg{top:0;bottom:0;width: 100%;position:absolute;z-index: -1;}.fp-section, .fp-slide, .fp-section.fp-table, .fp-slide.fp-table, .fp-section .fp-tableCell, .fp-slide .fp-tableCell {position:relative;overflow: hidden;}";
                z(an, n), y()
            }
            S(), a(), l(), mn = !0
        }, V.destroy = function() {
            A(), n(sn).remove(), O = o(0), F = i(0), l(), n(en).height(""), n(Q).off("setAutoScrolling").off("destroy").off("onScroll").off("afterResponsive").off("onGrab").off("onContinuousVertical").off("onResize").off("afterRender").off("afterRebuild"), bn && n(Q).off("onContinuosHorizontal").off("onResetSliders").off("onEndResetSliders")
        }, V.setOption = function(n, e) {
            "offset" === n ? (j.parallaxOptions.percentage = e, O = o(e), F = i(e)) : "type" === n && (j.parallaxOptions.type = e, T = e), l()
        };
        var Mn;
        V.applyProperties = q, V.afterSlideLoads = function(e) {
            var o = "undefined" != typeof e ? e : n(W).find(Z),
                i = o.closest(K),
                a = H ? N(i) : i.find(en);
            if (a.removeClass("fp-bg-animate"), (hn || L) && (f(o), hn = !1), !gn) {
                var l = t(un),
                    r = l[T].offsetNormal,
                    s = o,
                    c = un ? s.nextAll() : s.prevAll();
                (T === tn && un || T === on && !un || Sn) && c.each(function() {
                    I(n(this), r, "silent")
                })
            }
        }, V.afterLoad = function() {
            if ((!j.scrollBar || !j.autoScrolling || D.usingExtension("dragAndMove")) && ((xn || L) && (l(), xn = !1), !vn)) {
                var t = e(pn),
                    o = t[T].offsetNormal,
                    i = pn ? n(W).nextAll() : n(W).prevAll();
                (T === tn && pn || T === on && !pn) && i.each(function() {
                    q(n(this), o, "silent")
                })
            }
        }, V.c = D.c;
        var Rn = V["common".charAt(0)];
        return "complete" === document.readyState && Rn("parallax"), n(window).on("load", function() {
            Rn("parallax")
        }), V
    }
}(jQuery);