<template>
    <div>
        <h2 class="panel-header heading2">
            <span v-if="currentMonth == 0">
                Select Month
            </span>
            <span else>
                {{ getMonth(currentMonth) }}
            </span>
        </h2>

        <div class="panel-results">
            <template v-if="computedMonths">

                <div class="seasonality-graph">
                    <a class="data-point"
                         v-for="(month, i) in computedMonths"
                         v-on:click="setCurrentMonth(i+1, true);">
                        <div class="label"
                           v-bind:class="{active: currentMonth === i+1}"
                            :style="{ fontWeight: month >= 8 ? 'bold' : 'normal',opacity: (month/10) < 0.3 ? '0.3' : month/10 }">
                            <span>{{globals.months[i+1].shortName}}</span>

                            <i class="ion-record"></i>
                        </div>

                        <div class="bar"
                           v-bind:style="{height: (month * 10) + '%', backgroundColor: '#f4b400', opacity: (month/10) < 0.3 ? '0.3' : month/10}">
                        </div>
                    </a>

                    <div class="clearfix"></div>
                </div>

            </template>
            <template v-else>
                <div class="text-center">
                    Loading ...
                </div>
            </template>
        </div>

        <footer class="panel-footer -month">
            <div class="form-group checkbox-container">
                <input type="checkbox" id="any_month" v-bind:checked="defaultMonth === 0 || currentMonth === 0"
                       v-bind:disabled="defaultMonth === 0 || currentMonth === 0"
                       v-on:change="setCurrentMonth(0, true)"/>
                <label for="any_month">
                    SELECT ANY MONTH
                </label>
            </div>
        </footer>

    </div>
</template>

<script>
    export default {
        props: ['months', 'defaultMonth', 'setCurrentMonthCallback'],

        data() {
            return {
                globals: window.globals,
                currentMonth: null
            };
        },
        mounted() {
            this.setCurrentMonth(this.defaultMonth, false);
        },
        computed: {
            computedMonths() {
                if(this.months && this.months.length > 0){
                    return this.months;
                }else{
                    return [0,0,0,0,0,0,0,0,0,0,0,0];
                }
            }
        },
        methods: {
            setCurrentMonth(newMonth, doCallback){
                this.currentMonth = newMonth;

                if(doCallback){
                    this.setCurrentMonthCallback(newMonth);
                }
            },
            getMonth(num){
                var monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];
                return monthNames[num - 1];
            }
        }
    }
</script>