<template>
    <div>
        <p><strong>It will be an absolute pleasure to get back to you by phone and have a chat about 
your trip ideas.</strong></p>

        <p><strong>In order to figure out exactly which one of our safari experts should call you
back, we need to ask you to first provide answers to five quick questions.</strong></p>

        <p><strong>You should be able to do this in less than thirty secconds, thanks so much for
your patience.</strong></p>
    </div>
    
</template>

<script>

    export default {
        props: {
            progress: {
                type: Number,
                required: false,
                default: 0,
            },
        },
    }

</script>