export default class PrimitiveUtilities
{
    constructor() {
        require("seedrandom");
    }

    /**
     * If the same string is passed the same value is returned
     * @param string
     * @param min
     * @param max
     * @returns {number}
     */
    getRandomNumberFromString(string, min = 0, max = 100) {
        let rand = new Math.seedrandom(string)();

        console.log(string);
        console.log(rand);

        return parseInt(_.clamp(rand * 100, min, max));
    }

    /**
     * Strip all html tags, leaves the inner content preserved
     * @param string
     * @returns {string}
     */
    stripHtmlFromString(string) {
        return string ? string.replace(/(<([^>]+)>)/ig, "") : null;
    }
}