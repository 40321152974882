<script>
    import FullPage from '../../libs/fullpage-vue/FullPage';
    import AtrFullpage from '../../global/AtrFullpage';

    export default {
        render(createElement) {
            return createElement(FullPage, {
                ref: 'fullpage',
                attrs: {
                    id: this.id
                },
                props: {
                    options: this.fullpageArgs
                }
            }, [this.$slots.default]);
        },
        data() {
            return {
                sidenav : null
            }
        },
        props: {
            id: {
                required: true
            }
        },
        computed: {
            fullpageArgs: function () {
                if (_.isUndefined(this.atrFullpage)) {
                    /**
                     * Save AtrFullpage() to this. we don't
                     * use Vue's data function because we don't
                     * need it to be reactive
                     */
                    this.atrFullpage = new AtrFullpage();
                }

                return this.atrFullpage.getArgs();
            }
        },
        mounted: function () {
            if (!window._.isUndefined(window.pnpHelper.map)) {
                window.$html.addClass('load-lodge-page'); //.addClass('interactive-map');
            }

            // if(window.fullpage) {
            //     window.fullpage.destroy('all');
            // }


            window.$body.addClass('page-scroll-hijacked fullpage-not-loaded');
            $('#lodge-page').show();
            window.fullpage = this.$refs.fullpage;

            //
            // if(pnpHelper.bootstrap.isXs()) {
            //     var height = window.innerHeight;
            //     var a = setInterval(function () {
            //         $(window).scrollTop(-1);
            //         resize();
            //     }, 500); // Don't lower more than 500ms, otherwise there will be animation-problems with the  Safari toolbar
            //
            //     $(window).on('resize', resize);
            //
            //     var resize = function () {
            //         if (window.innerHeight != height) {
            //             height = window.innerHeight;
            //             alert(height);
            //             $('.section').css('height', height + 'px');
            //         }
            //     };
            // }
        },
        beforeDestroy() {
            var destroyFullPage = function(){
                if(window.pnpHelper.sidenav){
                    window.pnpHelper.sidenav.destroy();
                }

                if (!window._.isUndefined(window.pnpHelper.map)) {
                    window.$html.removeClass('load-lodge-page'); //.addClass('interactive-map');
                }

                if (!window._.isUndefined(window.pnpHelper.sidenav)) {
                    $('#sidenav').hide();
                }

                window.$body.removeClass('page-scroll-hijacked fullpage-not-loaded -half-map -add-nav-veil -has-side-nav modal-open');
                $('.header-container').removeClass('-modal-close');

                setTimeout(function(){
                    window.fullpage.destroy('all');
                },300);
            };

            window.bus.$once('do-blanket-tasks',destroyFullPage);
            //setTimeout(destroyFullPage, 3000); //Destroy it incase we're not using a transition to move away from this page
        },
        created: function () {
            window.bus.$on('fullPageInit', this.fullPageInitEvent);
            window.bus.$on('fullPageSilentMoveTo', this.fullPageSilentMoveToEvent);
            window.bus.$on('fullPageDestroying', this.fullPageDestroyingEvent);
            window.bus.$on('fullPageDestroyed', this.fullPageDestroyedEvent);
            window.bus.$on('fullPageAfterDomReady', this.fullPageAfterDomReadyEvent);
            window.bus.$on('fullPageMoveSectionDown', this.fullPageMoveSectionDownEvent);
            window.bus.$on('fullPageMoveSectionUp', this.fullPageMoveSectionUpEvent);
            window.bus.$on('lockScrolling', this.lockScrollingEvent);
            window.bus.$on('unlockScrolling', this.unlockScrollingEvent);
        },
        destroyed: function () {
            window.bus.$off('fullPageInit', this.fullPageInitEvent);
            window.bus.$off('fullPageSilentMoveTo', this.fullPageSilentMoveToEvent);
            window.bus.$off('fullPageDestroying', this.fullPageDestroyingEvent);
            window.bus.$off('fullPageDestroyed', this.fullPageDestroyedEvent);
            window.bus.$off('fullPageAfterDomReady', this.fullPageAfterDomReadyEvent);
            window.bus.$off('fullPageMoveSectionDown', this.fullPageMoveSectionDownEvent);
            window.bus.$off('fullPageMoveSectionUp', this.fullPageMoveSectionUpEvent);
            window.bus.$off('lockScrolling', this.lockScrollingEvent);
            window.bus.$off('unlockScrolling', this.unlockScrollingEvent);
        },
        methods: {
            init: function () {
                this.$refs.fullpage.update();

                window.pnpHelper.lazyLoad.loadImages();
                $('html,body').scrollTop(0);

                $('.header-container .header').fadeIn();
                $('.component-text.-lodge-info .inner').css('display', 'block');
                window.$body.css('overflow', 'hidden');

                var sidenav = window.$.sidenav({
                    showActiveLabel: true
                });

                $('.component-text.-lodge-info .inner').addClass('-in');
                $('.map-container').css('z-index', '0');
                $('#sidenav').show();
            },
            reInit: function () {
                window.pnpHelper.lazyLoad.loadImages();
                this.$refs.fullpage.update();
            },
            fullPageInitEvent(args){
                let _this = this;
                this.initArgs = args;
                Vue.nextTick(() => {
                    _this.reInit();
                });
            },
            fullPageSilentMoveToEvent(sectionIndex){
                this.$refs.fullpage.silentMoveTo(sectionIndex);
            },
            fullPageDestroyingEvent(args){
                this.initArgs = args;
                this.$refs.fullpage.destroy('all');
            },
            fullPageDestroyedEvent(){
                let _this = this;

                if (!window._.isUndefined(_this.initArgs) && !window._.isUndefined(_this.initArgs.promise)) {
                    _this.initArgs.promise.resolve('done');
                }
            },
            fullPageAfterDomReadyEvent(){
                let _this = this;

                Vue.nextTick(() => {
                    window.$body.removeClass('fullpage-not-loaded');

                    $('body').trigger('fullPageAfterDomInit');
                    if (!window._.isUndefined(_this.initArgs) && !window._.isUndefined(_this.initArgs.promise)) {
                        _this.initArgs.promise.resolve('done');
                    }
                });
            },
            fullPageMoveSectionDownEvent(){
                window.fullpage.moveSectionDown();
            },
            fullPageMoveSectionUpEvent(){
                window.fullpage.moveSectionUp();
            },
            lockScrollingEvent(){
                window.fullpage.setAllowScrolling(false);
            },
            unlockScrollingEvent(){
                window.fullpage.setAllowScrolling(true);
            }
        }
    }
</script>