<template>
    <modal-global ref="modal" style="display: inline-block;" classes="gs-modal -full video-container-modal" slot="custom-btn" :div_id="div_id">
        <template slot="modal-button">
            <div class="btn -videoPlayer -read-more -animated">Video</div>
        </template>

        <template slot="modal-body">
            <video-player
                    @finished="$refs['modal'].closeModal()"
                    :src_low="url"
                    :src_medium="url"
                    :src_high="url" />
        </template>
    </modal-global>
</template>

<script>
    export default {
        props: ['data_style', 'url', 'classes', 'icon', 'is_icon_left', 'type'],
        computed: {
            div_id() {
                return _.random(0, 1000);
            }
        }
    }
</script>
