<template>
    <div v-if="resource">
        <section class="right-side map-container article-images-container vignette -show-article -half -article-in">
            <div class="article-images -in -showing-images vh-fix-once">
                <article-image-presentational
                        :title="resource.title"
                        :image="resource.images.sizes.extralarge">
                    <div class="caption">
                        <div class="width-container">
                            <h1 class="heading" v-html="resource.title" :contenteditable="editing.heading" :data-block-id="resource.id" v-if="resource.edit == false"></h1>

                            <div class="on-page-editor-container" v-else>
                                <p class="heading" v-html="resource.title" :contenteditable="editing.heading" :data-block-id="resource.id"></p>
                                <div class="smallEditButtons -horizontal" :class="{ '-editing': editing.heading }">
                                    <div @click="createEditor('heading')" class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton"></div>
                                    <div @click="saveEditor('heading')" class="icon ion-ios-checkmark onPageEditor-smallButton btn-success smallSaveButton"
                                         v-if="editing.heading==true"></div>
                                    <div @click="cancelEditor('heading')" class="icon ion-ios-close onPageEditor-smallButton btn-danger smallCancelButton"
                                         v-if="editing.heading==true"></div>
                                </div>
                            </div>

                            <template v-if="resource.content != ''">
                                <div class="sub-heading" v-html="resource.content" :contenteditable="editing.subHeading" :data-block-id="resource.id"
                                     v-if="resource.edit == false"></div>
                                <div class="on-page-editor-container" v-else>
                                    <div class="sub-heading" v-html="resource.content" :contenteditable="editing.subHeading" :data-block-id="resource.id"></div>
                                    <div class="smallEditButtons -horizontal" v-bind:class="{ '-editing': editing.subHeading }">
                                        <div @click="createEditor('sub-heading')" class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton"></div>
                                        <div @click="saveEditor('sub-heading')" class="icon ion-ios-checkmark onPageEditor-smallButton btn-success smallSaveButton"
                                             v-if="editing.subHeading==true"></div>
                                        <div @click="cancelEditor('sub-heading')" class="icon ion-ios-close onPageEditor-smallButton btn-danger smallCancelButton"
                                             v-if="editing.subHeading==true"></div>
                                    </div>
                                </div>
                            </template>

                            <p class="buttons">
                                <button-social></button-social>
                            </p>
                        </div>
                    </div>
                </article-image-presentational>
            </div>

            <div class="article-content vh-fix">
                <div class="row info-window content-panel">
                    <transition-group name="fade" mode="in-out">
                        <div v-if="resourceLoaded" key="half_resource_1">
                            <article-presentational
                                    :title="resource.article.title"
                                    :content="resource.article.content"
                                    type="multi_re_article"
                                    :edit="resource.edit"></article-presentational>


                            <next-up-half-page
                                    v-if="showOuts"
                                    page="trip_collection"
                                    :item="resource"
                                    :data_heading="'Next'"
                                    :data_next_lodge="true"
                                    :text="'Next'"
                                    text_next_item="Next"
                                    text_explore="Explore Trip Intelligence"
                                    position="right">
                            </next-up-half-page>
                        </div>
                        <div v-else key="half_resource_2" class="loading">
                        </div>
                    </transition-group>
                </div>
            </div>
        </section>
        <public-edit-mode-toggle/>
        <!-- <transition-wipe/> -->
    </div>
</template>

<script>
    import pageIsReadyMixin from '../../mixins';
    import store from '../../store';
    import NavScrollMixin from "../../global/NavScrollMixin";
    import GlobalVars from "../../global/GlobalVars";

    export default {
        mixins: [pageIsReadyMixin, NavScrollMixin],
        data() {
            return {
                editing: {
                    heading: false,
                    subHeading: false,
                },
                editorName: null,
                first: true,
                showOuts: true,
                resourceLoaded: false
            }
        },
        methods: {
            createEditor(which) {
                if (!window._.isUndefined(this.editorName) && this.editorName !== null) {
                    alert('You are currently editing a different bit on the page.\nSave or Cancel it before starting editing on a new instance.');
                } else {
                    if (which === 'heading') {
                        this.editing.heading = true;
                        this.editing.subHeading = false;
                    } else {
                        this.editing.heading = false;
                        this.editing.subHeading = true;
                    }
                    Vue.nextTick(() => {
                        var editor = window.CKEDITOR.inline(this.$el.querySelector('.on-page-editor-container .' + which), window.ckeditorConfig);
                        this.$el.querySelector('.on-page-editor-container .' + which).click();
                        this.editorName = editor.name;

                        this.$store.commit(window.store.SET_EDITOR_CKEDITOR, true, {root: true});
                    });
                }
            },
            cancelEditor(which) {
                this.editing.heading = false;
                this.editing.subHeading = false;
                if (this.editorName !== null) {
                    if (which === 'heading') {
                        window.CKEDITOR.instances[this.editorName].container.$.innerHTML = this.resource.title;
                    } else {
                        window.CKEDITOR.instances[this.editorName].container.$.innerHTML = this.resource.content;
                    }
                    window.CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;

                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, {root: true});
                }
            },
            saveEditor(which) {
                this.editing.heading = false;
                this.editing.subHeading = false;

                if (this.editorName !== null) {
                    let newContent = window.CKEDITOR.instances[this.editorName].container.$.innerHTML;
                    let resourceObject = window._.defaultsDeep({}, this.$store.state.singleMultiResource.resource);
                    if (which === 'heading') {
                        resourceObject.title = newContent;
                    } else if (which === 'custom-section-content') {
                        resourceObject.top_section.content = newContent;
                    } else {
                        resourceObject.content = newContent;
                    }
                    this.$store.dispatch(window.store.SET_EDITOR_DIRTY, window.editorTypes.MULTI_RESOURCE, {root: true});
                    this.$store.commit(window.store.SET_MULTI_RESOURCE, resourceObject);
                    window.CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;

                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, {root: true});
                }
            },
            initResource() {
                this.resourceLoaded = true;
                window.$body.addClass('showing-article-half showing-article');


                if (Object.keys(this.$route.params).length > 0 && this.$route.params.hasOwnProperty('showOuts')) {
                    this.showOuts = this.$route.params.showOuts
                }

                $(document).scrollTop(0);
                $('.info-window').animate({
                    scrollTop: 0
                }, 700);
                this.declarePageIsReady();
            },
            scroll() {
                window.bus.$emit('fullPageMoveSectionDown');
            }
        },
        computed: {
            resource() {
                if (this.resourceLoaded) {
                    return this.$store.state.singleMultiResource.resource;
                } else if (this.currentArticle.title &&
                    (!this.currentArticle.vue_route_params.data ||
                        this.currentArticle.vue_route_params.data.slug &&
                        this.currentArticle.vue_route_params.data.slug === this.$route.params.slug)) {
                    //Half page articles

                    return {
                        title: this.currentArticle.title,
                        content: this.currentArticle.content,
                        edit: false,
                        display_type: this.currentArticle.display_type,

                        article: {
                            title: this.currentArticle.title,
                            content: "Loading ...",
                        },
                        images: {
                            sizes: {
                                extralarge: this.currentArticle.images.data.sizes ? this.currentArticle.images.data.sizes.extralarge : null,
                            }
                        },
                    }
                } else {
                    return false;
                }
            },
            currentArticle() {
                return this.$store.state.activeArticle.article;
            },
            bg_img_url() {
                if (this.resource && this.resource.images.sizes.extralarge) {
                    return "background-image:url('" + this.resource.images.sizes.extralarge + "');";
                } else {
                    return;
                }
            },
            last_bg_img_url() {
                if (this.resource && this.resource.images && this.resource.images.sizes && this.resource.images.sizes.extralarge) {
                    return this.resource.images.sizes.extralarge;
                } else {
                    return '';
                }
            },
            isMobile() {
                return pnpHelper.bootstrap.isXs();
            }
        },
        beforeRouteUpdate(to, from, next) {
            if ($('body').hasClass('-disable-page-wipe')) {

                this.$store.dispatch(window.store.FETCH_AND_SET_MULTI_RESOURCE, {type: GlobalVars.TRIP_COLLECTION, slug: to.params['slug']}).then(() => {
                    this.initResource();
                    next();
                }).catch(() => {
                    this.$router.push({name: 'page404'});

                }).finally(() => {
                    this.loadingNextResult = false;

                    setTimeout(() => {
                        $('body').removeClass('-disable-page-wipe')
                    }, 1000);
                });

            } else {
                next();
            }
        },
        beforeRouteLeave(to, from, next) {
            if (to['name'] === 'single-resource') {
                /** Before we attempt to enter this route trigger an update from the store */
                store.dispatch(window.store.FETCH_AND_SET_RESOURCE, to.params['slug']).then(() => {
                    this.initResource();
                    next();
                }).catch(() => {
                    this.$router.push({name: 'page404'});
                });
            } else {
                next();
            }
        },
        mounted() {

            this.$store.commit(window.store.SET_SEARCH_TYPE, 'trip_collection');
            this.$store.commit(window.store.SET_SEARCH_LOCATION, {id: 0});
            this.$store.commit(window.store.SET_SEARCH_MONTH, 0);
            this.$store.dispatch(window.store.FETCH_AND_SET_SEARCH_RESULTS);

            window.bus.$once('fullPageAfterDomReady', this.declarePageIsReady);

            //Set the resource to temporarily be the current article whilist it's downloaded
            if (this.resource.display_type == 0) {
                window.$body.addClass('showing-article-half showing-article');
            }

            this.$store.dispatch(window.store.FETCH_AND_SET_MULTI_RESOURCE, {
                type: GlobalVars.TRIP_COLLECTION,
                slug: this.$route.params['slug']
            }).then(this.initResource);
        },
        beforeDestroy() {
            window.bus.$emit('setShowBackButton', false);
            window.$body.removeClass('showing-article');
            window.$body.removeClass('showing-article-half');
            window.$body.removeClass('showing-article-full');
        }
    }
</script>
