<template>
    <div class="seasonality-graph-container">
        <div class="key" v-if="graphKey">
            <i class="ion-record key-colour" v-bind:style="{color: dataSet[0].colour}"></i>
            {{graphKey}}
        </div>

        <div v-if="dataSet.length > 1">
            <div class="key" v-for="data in dataSet">
                <i class="ion-record key-colour" v-bind:style="{color: data.colour}"></i>
                {{data.graphKey}}
            </div>
        </div>

        <div class="seasonality-graph">
            <div class="data-point"
                 v-bind:class="{multi : dataSet.length > 1}"
                 v-for="i in 12">

                <a class="label"
                   v-bind:class="{active: currentMonth === i}"
                   v-on:click="setCurrentMonth(i, true)">
                    {{globals.months[i].shortName}}
                </a>

                <a class="bar -bar-1"
                   v-on:click="setCurrentMonth(i, true)"
                   v-bind:style="{height: (dataSet[0].data[i-1] * 10) + '%', backgroundColor: dataSet[0].colour, opacity: (dataSet.length > 1 ? 1 : (dataSet[0].data[i-1]/10 + 0.2))}">
                    <span v-if="dataSet[0].tooltips" class="graph-tooltip"
                          v-html="dataSet[0].tooltips[i-1]">

                    </span>
                </a>

                <a class="bar -bar-2"
                   v-if="dataSet.length > 1"
                   v-on:click="setCurrentMonth(i, true)"
                   v-bind:style="{height: (dataSet[1].data[i-1] * 10) + '%', backgroundColor: dataSet[1].colour, opacity: (dataSet.length > 1 ? 1 : (dataSet[1].data[i-1]/10 + 0.2))}">
                    <span v-if="dataSet[1].tooltips" class="graph-tooltip"
                          v-html="dataSet[1].tooltips[i-1]">

                    </span>
                </a>
            </div>

            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['dataSet', 'defaultMonth', 'graphKey'],

        data() {
            return {
                globals: window.globals,
                currentMonth: ''
            };
        },

        mounted() {
            this.setCurrentMonth(this.defaultMonth, false);
        },

        methods: {
            setCurrentMonth(newMonth, broadcastUpdate){
                this.currentMonth = newMonth;

                if(broadcastUpdate){
                    bus.$emit('seasonality.updateMonth', newMonth);
                }
            }
        },

        watch: {
            defaultMonth: function(newVal, oldVal) {
                this.setCurrentMonth(newVal, false);
            }
        }
    }
</script>