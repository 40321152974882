<template>
    <div class="pnp-popover srch-panel -compact -experience vh-fix-xs"
         :id="'srch-experience-panel' + this.sentenceId"
         v-observe-visibility="visibilityChanged">

        <mob-popup-header text="SELECT EXPERIENCE"></mob-popup-header>

        <header class="panel-search">
            <input type="text"
                   ref="input"
                   v-if="!toggleWishlist"
                   v-model="experienceAutocomplete"
                   @keyup="doExperienceAutocomplete()"
                   :class="{ dirty: experienceAutocomplete }"
                   class="form-control" placeholder="Search Experience">

            <input type="text"
                   v-else
                   ref="input"
                   v-model="experienceAutocomplete"
                   @keyup="doWishlistAutocomplete()"
                   :class="{ dirty: experienceAutocomplete }"
                   class="form-control" placeholder="Search Experience">
        </header>

        <div class="panel-results" :class="{'-max-reached': this.experiences.length === maxExperiences}">
            <template v-if="options.experiences
                && !autocompleteExperienceResults
                && toggleWishlist === false">

                <!--SELECTED EXPERIENCES-->
                <template v-if="enableMultiSelection">
                    <div class="form-group checkbox-container" :class="{'active': experienceIsSelected(anyExperience)}">
                        <input type="checkbox" :checked="experienceIsSelected(anyExperience)"/>

                        <label class="filter-option" @click="toggleExperiences(anyExperience)">
                            {{anyExperience.name}}
                        </label>
                    </div>

                    <div class="form-group checkbox-container" :class="{'active': experienceIsSelected(experience)}"
                         v-for="experience in initialExperiences"
                        v-if="experience.id != 3">

                        <input type="checkbox" :checked="experienceIsSelected(experience)"/>

                        <label class="filter-option" @click="toggleExperiences(experience)">
                            {{experience.name}}
                        </label>
                    </div>
                </template>
                <template v-else-if="params.experience">
                    <a class="filter-option active"
                       @click="setExperience(params.experience)">
                        {{params.experience.name}}
                    </a>
                </template>

                <!--MAIN EXPERIENCE LISTING-->
                <template v-if="enableMultiSelection">

                    <div class="form-group checkbox-container" :class="{'active': experienceIsSelected(experience)}"
                         v-for="experience in options.experiences"
                         v-if="!experienceInInitialExperiences(experience) && experience.id != 3">

                        <input type="checkbox" :checked="experienceIsSelected(experience)"/>

                        <label class="filter-option" @click="toggleExperiences(experience)">
                            {{experience.name}}
                        </label>
                    </div>
                </template>
                <template v-else>
                    <template v-if="params.experience">
                        <a class="filter-option"
                           v-for="experience in options.experiences"
                           v-if="experience.id != params.experience.id"
                           v-on:click="setExperience(experience)">

                            {{experience.name }}

                        </a>
                    </template>
                </template>
            </template>

            <!--EXPERIENCES SEARCH RESULTS-->
            <template v-else-if="autocompleteExperienceResults && !toggleWishlist">
                <template v-if="enableMultiSelection">

                    <div class="form-group checkbox-container" :class="{'active': experienceIsSelected(experience)}"
                         v-for="experience in autocompleteExperienceResults">

                        <input type="checkbox" :id="experience.id" :checked="experienceIsSelected(experience)"/>

                        <label :for="experience.id" class="filter-option" @click="toggleExperiences(experience)">
                            {{experience.name}}
                        </label>
                    </div>

                </template>
                <template v-else>
                    <a class="filter-option"
                       v-for="experience in autocompleteExperienceResults"
                       v-on:click="setExperience(experience)">
                        {{experience.name}}
                    </a>
                </template>
            </template>

            <!--WISHLIST-->
            <template v-else-if="toggleWishlist && !autocompleteWishlistResults">
                <p v-if="wishlistExperiences.length === 0">You do not have any experiences in your wishlist.</p>

                <template v-else-if="enableMultiSelection">

                    <div class="form-group checkbox-container" :class="{'active': experienceIsSelected(experience)}"
                         v-for="experience in wishlistExperiences">

                        <input type="checkbox" :checked="experienceIsSelected(experience)"/>

                        <label class="filter-option" @click="toggleExperiences(experience)">
                            {{experience.title}}
                        </label>
                    </div>
                </template>
                <template v-else>
                    <a class="filter-option"
                       v-for="experience in wishlistExperiences"
                       @click="setExperience(experience)">
                        {{experience.title}}
                    </a>
                </template>
            </template>

            <!--WISHLIST SEARCH RESULTS-->
            <template v-else-if="toggleWishlist && autocompleteWishlistResults">
                <p v-if="wishlistExperiences.length === 0">You do not have any experiences in your wishlist.</p>

                <template v-else-if="enableMultiSelection">

                    <div class="form-group checkbox-container" :class="{'active': experienceIsSelected(experience)}"
                         v-for="experience in autocompleteWishlistResults">

                        <input type="checkbox" :checked="experienceIsSelected(experience)"/>

                        <label class="filter-option" @click="toggleExperiences(experience)">
                            {{experience.title}}
                        </label>
                    </div>
                </template>
                <template v-else>
                    <a class="filter-option"
                       v-for="experience in autocompleteWishlistResults"
                       @click="setExperience(experience)">
                        {{experience.title}}
                    </a>
                </template>
            </template>

            <template v-else>
                <div class="text-center">
                    Loading ...
                </div>
            </template>
        </div>

        <footer class="panel-footer" :class="{'-apply-mode': enableMultiSelection && experiencesAreDirty, '-multi-mode': enableMultiSelection}">
            <div class="form-group checkbox-container wishlist-checkbox-container">
                <input type="checkbox" id="wishlist_experience" v-model="toggleWishlist"/>

                <label for="wishlist_experience">
                    <div class="wishlsit-select">
                        &nbsp;
                        <span class="wishlist-single">SHOW ONLY EXPERIENCES IN MY WISHLIST</span>
                        <span class="wishlist-multi">SHOW WISHLIST</span>
                    </div>
                </label>
            </div>

            <a @click="setExperiences" class="btn -main-cta -small -ripple apply-btn apply">
                <span>
                    <span>Apply</span> <i class="ion-ios-arrow-thin-right"></i>
                </span>
            </a>
        </footer>

    </div>
</template>
<script>
    import UrlMap from '../../../global/UrlMap';
    import router from '../../../router';

    export default {
        props: {
            params: {
                type: Object,
            },
            options: {
                type: Object
            },
            shouldSearch: {
                default: true,
                type: Boolean
            },
            sentenceId: {
                default: '',
                type: String
            },
            enableMultiSelection: {
                default: false,
                type: Boolean
            }
        },
        data() {
            return {
                globals: window.globals,
                experienceAutocomplete: '',
                autocompleteExperienceResults: '',
                autocompleteWishlistResults: '',
                toggleWishlist: false,
                experiences: [],
                maxExperiences: 5,
                initialExperiences: [],
                anyExperience:{
                    id: 3,
                    name: "Any Experience"
                },
                oldSelectedExperiences: []
            };
        },
        methods: {
            doExperienceAutocomplete() {
                if (this.experienceAutocomplete && this.experienceAutocomplete.length >= 1) {

                    this.autocompleteExperienceResults = this.sort(this.options.experiences, 'name');

                } else {
                    this.autocompleteExperienceResults = null;
                }
            },
            doWishlistAutocomplete(){
                if (this.experienceAutocomplete && this.experienceAutocomplete.length >= 1) {

                    this.autocompleteWishlistResults = this.sort(this.wishlistExperiences, 'title');

                } else {
                    this.autocompleteWishlistResults = null;
                }
            },
            sort(options, key){
                let results, resultsMiddleMatch = [];

                // Match results starting at first letter of first word
                results = options.filter((obj) => {
                    return new RegExp('\\b^' + this.experienceAutocomplete, "gi").test(obj[key]);
                });

                // Sort A-Z
                results.sort((a, b) => {
                    return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
                });

                // Match results starting anywhere
                resultsMiddleMatch = options.filter((obj) => {
                    return new RegExp(this.experienceAutocomplete, "gi").test(obj[key]);
                });

                // Removed first word results from, anywhere results
                resultsMiddleMatch = resultsMiddleMatch.filter((obj) => {
                    return results.indexOf(obj) === -1;
                });

                // Sort A-Z
                resultsMiddleMatch.sort((a, b) => {
                    return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
                });

                let concatResults = results.concat(resultsMiddleMatch);

                return concatResults;
            },
            experienceIsSelected(experience){
                return this.getExperienceIndexInExperiences(experience) > -1;
            },
            getExperienceIndexInExperiences(experience){
                for(let i=0; i<this.experiences.length; i++){
                    if(this.experiences[i].id == experience.id){
                        return i;
                    }
                }

                return -1;
            },
            experienceInInitialExperiences(experience){
                for(let i=0; i<this.initialExperiences.length; i++){
                    if(this.initialExperiences[i].id === experience.id){
                        return true;
                    }
                }

                return false;
            },
            toggleExperiences(experience){
                let experienceIndex = this.getExperienceIndexInExperiences(experience);

                if(experience.id === 3){
                    if(experienceIndex > -1){
                        //Removing any experience to the search criteria
                        if(this.oldSelectedExperiences.length > 0){
                            //Restore the experiences to their state before Any Experience was added
                            this.experiences = this.oldSelectedExperiences;
                            this.oldSelectedExperiences = [];
                        }else{
                            this.experiences.splice(experienceIndex, 1);
                        }

                    }else{
                        //Adding any experience to the search criteria
                        this.oldSelectedExperiences = this.experiences;

                        this.experiences = [this.anyExperience];
                    }
                }else {
                    this.oldSelectedExperiences = [];

                    if (experienceIndex > -1) {
                        this.experiences.splice(experienceIndex, 1);
                    } else {
                        if (this.experiences.length < this.maxExperiences) {
                            this.experiences.push({
                                id: experience.id,
                                name: experience.name ? experience.name : experience.title
                            });

                            if(this.experienceIsSelected(this.anyExperience)){
                                let anyExperienceIndex = this.getExperienceIndexInExperiences(this.anyExperience);
                                this.experiences.splice(anyExperienceIndex, 1);
                            }
                        }
                    }
                }

                if(this.experiences.length === 0){
                    this.experiences = [this.anyExperience];
                }
            },
            setExperiences(){
                this.$store.commit(window.store.SET_SEARCH_EXPERIENCES, this.experiences);
                this.$store.commit(window.store.SET_SEARCH_EXPERIENCE, this.experiences[0]);

                let urlMap = new UrlMap();

                let queryParams = window._.defaultsDeep({
                    experiences: urlMap.urlEncode(this.experiences),
                    experience: this.experiences[0].id,
                    ename: this.experiences[0].name,
                    eprimary: true,
                }, urlMap.searchStateToUrlParams(this.$store.state.search));

                router.push({name: 'finder', query: queryParams});

                window.bus.$emit('searchDoSearch');
            },
            visibilityChanged(isVisible){
                if(isVisible) {
                    if(!pnpHelper.bootstrap.isXs()) {
                        this.$refs.input.focus();
                    }

                    this.experiences = [...this.selectedExperiences];

                    this.initialExperiences = [...this.selectedExperiences];
                } else {
                    this.experienceAutocomplete = '';
                    this.autocompleteExperienceResults= false;
                    this.toggleWishlist = false;
                }
            },
            setExperience(experience) {
                if (this.shouldSearch) {

                    let newExperience = experience;

                    if(experience.wishlist) {
                        newExperience = {
                            ...newExperience,
                            name: experience.title
                        }
                    }

                    this.$store.commit(window.store.SET_SEARCH_EXPERIENCE, newExperience, { root: true });
                    this.$store.commit(window.store.SET_SEARCH_EXPERIENCES, [newExperience]);
                    
                    let urlMap = new UrlMap();

                    let queryParams = window._.defaultsDeep({
                        experience: newExperience.id,
                        ename: newExperience.name,
                        eprimary: newExperience.primary,
                        experiences: urlMap.urlEncode([newExperience])
                    }, new UrlMap().searchStateToUrlParams(this.$store.state.search));

                    router.push({name: 'finder', query: queryParams});

                    window.bus.$emit('searchDoSearch');
                } else {
                    this.params.experience = experience;
                }

                // window.bus.$emit('searchSentenceDirtyUpdate', {
                //     isDirty: true,
                //     key: 'experience'
                // });
            },
        },
        computed: {
            selectedExperiences(){
                if(this.enableMultiSelection) {

                    this.experiences = [this.anyExperience];

                    if (this.$store.state.search.criteria.params.experiences.length === 0) {
                        this.$store.commit(window.store.SET_SEARCH_EXPERIENCES, this.experiences);
                    }
                }

                return this.$store.state.search.criteria.params.experiences;
            },
            wishlistExperiences(){
                let wishlist = this.$store.state.wishlist.selected;

                wishlist = wishlist.filter((el) => {
                    return el.type === 'experience';
                }).sort((a, b) => {
                    return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
                });

                return wishlist;
            },
            experiencesAreDirty(){
                if(this.experiences.length !== this.selectedExperiences.length){
                    return true;
                }else{
                    for(let i=0; i<this.experiences.length; i++){
                        let found = false;

                        for(let j=0; j<this.selectedExperiences.length; j++){
                            if(this.experiences[i].id === this.selectedExperiences[j].id){
                                found = true;
                            }
                        }

                        if(found === false){
                            return true;
                        }
                    }
                }

                return false;
            }
        },
        watch:{
            toggleWishlist(){
                this.experienceAutocomplete = '';
                this.autocompleteExperienceResults = '';
                this.autocompleteWishlistResults = '';
            }
        }
    }
</script>