<template>
    <portal to="modals">
        <div class="modal fade" :id="'linkEditorModal_' + _id" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" :id="'linkEditorModal_' + _id">&times;</button>
                    <h4 class="modal-title">Links</h4>
                </div>
                <div class="modal-body">
                    <div :id="'currentLinks_' + _id">
                        <template v-for="(link, index) in localLinks">
                            <div>
                                <div class="panel panel-info">
                                    <div class="panel-heading">{{ link.text }}
                                        <button class="btn btn-info pull-right" data-toggle="collapse" :data-target="'#_' + _id + '_modal_link_' + index">Edit
                                            Link
                                        </button>
                                    </div>
                                    <div class="panel-body">
                                        <div :id="'_' + _id +'_modal_link_' + index" class="collapse">
                                            <div class="row form-inline">

                                                <div class="form-group col-md-6">
                                                    <label class="control-label">Link Text</label>
                                                    <input class="form-control" type="text" name="button-text" value="Link Text" v-model="localLinks[index].text"/>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label class="control-label">Link URL</label>
                                                    <input class="form-control" type="text" name="button-text" value="Link Text" v-model="localLinks[index].url"/>
                                                </div>
                                                <div class="col-md-12">
                                                    <button class="btn btn-sm btn-danger pull-right" @click="removeMe(index)"><i class="ion ion-trash-b"></i> Delete</button>
                                                    <button class="btn btn-sm btn-success pull-right" @click="updateLink(index)"><i class="ion ion-ios-checkmark"></i> Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="panel panel-warning mt-5">
                        <div class="panel-heading">Add New Link
                            <button class="btn btn-warning pull-right" data-toggle="collapse" :data-target="'#_' + _id + '_addNewLink' + _id">Add Link
                            </button>
                        </div>
                        <div class="panel-body">
                            <div :id="'_' + _id + '_addNewLink' + _id" class="row form-inline collapse">
                                <div class="form-group col-md-6">
                                    <label class="control-label">Link Text</label>
                                    <input class="form-control" type="text" name="button-text" value="Link Text" v-model="link.text"/>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="control-label">Link URL</label>
                                    <input class="form-control" type="text" name="button-text" value="Link Text" v-model="link.url"/>
                                </div>
                                <div class="form-group col-md-12">
                                    <button class="btn btn-sm btn-primary pull-right addButtonToPage" @click="addNewLink()"><i class="ion ion-plus"></i> Add</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    </portal>
</template>


<script>
    import StateUtils from '../../global/StateUtils'

    export default {
        props: ['type', '_id', 'links', 'section_id'],
        data() {
            return {
                link: {
                    text: 'Link text',
                    url: '',
                },
                linkDefaults: {
                    text: 'Link text',
                    url: '',
                },
                localLinks: [],
                sectionContent: [],

            };
        },
        created: function() {
            this.localLinks = this.links[0] == null ? [{ text: null, url: null }] : _.defaultsDeep({}, this.links);
        },
        methods: {
            addNewLink() {
                let linkData = {
                    url: this.link.url,
                    text: this.link.text,
                };

                this.updateLink(null, linkData);

                this.link.text = this.linkDefaults.text;
                this.link.url = this.linkDefaults.url;

            },
            removeMe(index) {
                var stateObject = new StateUtils(this.type, this.$store).getStateObject();
                if (stateObject !== null) {
                    this.localLinks.splice(index, 1);

                    if(this.type.indexOf('_trips')!==-1){
                        stateObject.trips_section.content.splice(index, 1);
                    } else if(this.type.indexOf('_other_lodges')!==-1){
                        stateObject.other_lodges_section.content.splice(index, 1);
                    }

                    new StateUtils(this.type, this.$store).setStateObject(stateObject, null);
                }
            },
            updateLink(index, link) {
                if(window._.isUndefined(link)) {
                    var linkData = {
                        text: this.localLinks[index].text,
                        url: this.localLinks[index].url
                    };
                } else {
                    var linkData = window._.defaultsDeep({}, link);
                }
                var stateObject = new StateUtils(this.type, this.$store).getStateObject();
                if (stateObject !== null) {
                    if(this.type.indexOf('_trips')!==-1){
                        if(index === null){
                            index = stateObject.trips_section.content.length;
                        }
                        this.localLinks[index] = linkData;
                        stateObject.trips_section.content[index] = linkData;
                    } else if(this.type.indexOf('_other_lodges')!==-1){
                        if(index === null){
                            index = stateObject.other_lodges_section.content.length;
                        }
                        this.localLinks[index] = linkData;
                        stateObject.other_lodges_section.content[index] = linkData;
                    }

                    new StateUtils(this.type, this.$store).setStateObject(stateObject, null);
                }
            }
        }
    }
</script>