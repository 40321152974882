<template>
    <div style="margin-top:18px;">
        <div class="messages-container">
            <div class="errors-container" v-if="error">
                <p>Please select a year and month.</p>
            </div>
        </div>
        <ul class="buttons-list -dates" style="margin-bottom: 20px;">
            <li v-for="(year, index) in years" :key="index">
                <input type="radio" :id="'year-'+index" :name="'year_of_travel'" :value="year" @change="setValue" v-model="selectedYear">
                <label :for="'year-'+index">
                    {{ year }}
                </label>
            </li>
        </ul>

        <ul class="buttons-list -dates wrapper-display" style="flex-wrap: wrap;">
            <li v-for="(month, index) in months" :key="index" :class="!isMonthAvailable(month) ? 'disabled' : ''">
                <input type="radio" :id="'month-'+index" :name="'month_of_travel'" :value="index" @change="setValue" v-model="selectedMonth">
                <label :for="'month-'+index" style="text-transform: uppercase;">{{ index }}</label>
                <div class="label-display">{{ month }}</div>
            </li>
        </ul>
        <slot name="additionalText" />
    </div>
    
</template>

<style>
.vue-daterange-picker .reportrange-text{
    display:none;
}
</style>

<script>

    export default {
        props: ['formContent', 'stepIndex', 'step', 'checkValidation'],
        data() {
            return {
                error: false,
                years: null,
                months: null,
                selectedYear: null,
                selectedMonth: null,
                currentMonth: null,
            }
        },
        mounted(){
            this.years = this.getYears();
            this.months = this.getMonths();
            this.selectedYear = this.formContent.year_of_travel || null;
            this.selectedMonth = this.formContent.month_of_travel || null;

            // this.setValue({currentTarget: {getAttribute: () => 'year'}});

            this.currentMonth = new Date().getMonth();

            if(this.stepIndex === this.step.index && typeof this.formContent.year_of_travel !== "undefined") {
                this.validateStep();
            }

            window.addEventListener('validateStep', e => {
                if(e.detail !== this.step.index) {
                    return;
                }
                this.validateStep();
            })

        },
        watch: {
            checkValidation: function(e){
                if(e) {
                    this.validateStep();
                }
                
            },
        },
        methods:{
            setValue(e){
                const field = e.currentTarget.getAttribute('name');
                if(field === 'year_of_travel'){
                    if(typeof e === 'undefined' || e.length === 0 || e === null){
                        this.$emit('update-data', {key: 'year_of_travel', value: null});
                        return;
                    }

                    if(this.isMonthAvailable(this.selectedMonth)){
                        this.selectedMonth = null;
                        this.$emit('update-data', {key: 'month_of_travel', value: null});
                    }

                    this.$emit('update-data', {key: 'year_of_travel', value: this.selectedYear});
                } else {
                    if(typeof e === 'undefined' || e.length === 0 || e === null){
                        this.$emit('update-data', {key: 'month_of_travel', value: null});
                        return;
                    }

                    // if(this.isMonthAvailable(this.selectedMonth)){
                    //     this.selectedMonth = null;
                    //     this.$emit('update-data', {key: 'month_of_travel', value: null});
                    // }

                    this.$emit('update-data', {key: 'month_of_travel', value: this.selectedMonth});
                }

                
                this.validateStep(field !== 'year_of_travel');
                
                
                
            },
            getYears(){
                let years = [];
                let currentYear = new Date().getFullYear();
                for(let i = 0; i < 3; i++){
                    years.push(currentYear + i);
                }
                return years;
            },
            getMonths(){
                return {
                    'January': 'Jan',
                    'February': 'Feb',
                    'March': 'Mar',
                    'April': 'Apr',
                    'May': 'May',
                    'June': 'Jun',
                    'July': 'Jul',
                    'August': 'Aug',
                    'September': 'Sep',
                    'October': 'Oct',
                    'November': 'Nov',
                    'December': 'Dec',
                }
            },
            getAvailableMonthsLeftInCurrentYear(){
                let months = [];
                let currentMonth = new Date().getMonth();
                let monthsLeft = 12 - currentMonth;
                for(let i = 0; i < monthsLeft; i++){
                    months.push(Object.values(this.months)[currentMonth + i]);
                }
                return months;
            },
            isMonthAvailable(month){
                if(this.selectedYear === new Date().getFullYear()){
                    return this.getAvailableMonthsLeftInCurrentYear().includes(month);
                }

                return true;
            },

            validateStep(showError = true){

                let isValid = false; 

                // If we have a year and month selected then the step is valid
                if(typeof this.selectedYear !== "undefined" && this.selectedYear !== null){

                    if(typeof this.selectedMonth !== "undefined" && this.selectedMonth !== null){
                        isValid = true;
                    }
                }

                // If we have exact dates selected then the step is valid
                // if(typeof this.formContent.exact_dates___start_date !== "undefined" && this.formContent.exact_dates___start_date !== null){
                    
                //     if(typeof this.formContent.exact_dates___end_date !== "undefined" && this.formContent.exact_dates___end_date !== null){
                //         isValid = true;
                //     }
                // }

                if(!isValid){
                    if(showError) {
                        this.error = true;
                    }

                    this.$emit('update-validation', {step: this.step.index, value: false});
                } else {
                    this.error = false;
                    console.log('validating: ', this.step.index, true);
                    this.$emit('update-validation', {step: this.step.index, value: true});
                    
                }
            }

        },
    }

</script>