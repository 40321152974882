import Vuex from 'vuex';
import PayloadParser from './global/PayloadParser';
import AtrStorage from './global/AtrStorage';
import LazyLoader from './global/LazyLoader';
import SeoManager from "./global/SeoManager";
import GlobalVars from "./global/GlobalVars";
import router from "./router";
import SchemaData from "./global/SchemaData";
import UrlMap from '@/global/UrlMap';

require('./libs/notify');
import ObjectCache from './global/ObjectCache';
import GlobalUtils from "./global/GlobalUtils";
// import router from './Router';
// import UrlMap from './UrlMap';

Vue.use(Vuex);

function deepMap(obj, context) {
    return _.transform(obj, function (result, val, key) {
        result[key] = _.isObject(val);// /*&& !_.isDate(val)*/ ?
        deepMap(val, context);// :
        // iterator.call(context, val, key, obj);
    });
}

_.mixin({
    deepMap: deepMap
});

/**
 * Define a global object here so you can get a broad
 * overview of what actions are possible by typing
 * window.store with your IDE rather than having
 * to remember strings
 */
window.store = {
    /**
     * API GET
     */
    FETCH_AND_RETURN_LODGE: 'singleLodge/FETCH_AND_RETURN_LODGE',
    FETCH_AND_RETURN_LODGE_PARENTS: 'singleLodge/FETCH_AND_RETURN_LODGE_PARENTS',
    FETCH_AND_SET_LODGE: 'singleLodge/FETCH_AND_SET_LODGE',
    FETCH_AND_RETURN_LOCATION: 'singleLocation/FETCH_AND_RETURN_LOCATION',
    FETCH_AND_RETURN_LOCATION_PARENTS: 'singleLocation/FETCH_AND_RETURN_LOCATION_PARENTS',
    FETCH_AND_SET_LOCATION: 'singleLocation/FETCH_AND_SET_LOCATION',
    FETCH_AND_SET_RESOURCE: 'singleResource/FETCH_AND_SET_RESOURCE',
    FETCH_AND_SET_START_ARTICLES: 'startPage/FETCH_AND_SET_START_ARTICLES',
    FETCH_AND_SET_EXPERTS: 'startPage/FETCH_AND_SET_EXPERTS',
    FETCH_AND_SET_EXPERIENCE: 'singleExperience/FETCH_AND_SET_EXPERIENCE',
    FETCH_AND_SET_PAGE: 'singlePage/FETCH_AND_SET_PAGE',
    FETCH_AND_SET_SEARCH_RESULTS: 'search/FETCH_AND_SET_SEARCH_RESULTS',
    FETCH_AND_SET_SEARCH_CRITERIA: 'search/FETCH_AND_SET_SEARCH_CRITERIA',
    FETCH_AND_SET_SEARCH_RESULTS_COUNT: 'search/FETCH_AND_SET_SEARCH_RESULTS_COUNT',
    FETCH_AND_SET_SEARCH_CRITERIA_OPTIONS: 'search/FETCH_AND_SET_SEARCH_CRITERIA_OPTIONS',
    FETCH_AND_RETURN_SEARCH_CRITERIA_OPTIONS: 'search/FETCH_AND_RETURN_SEARCH_CRITERIA_OPTIONS',
    FETCH_AND_SET_SEARCH_DEFAULTS: 'search/FETCH_AND_SET_SEARCH_DEFAULTS',
    FETCH_AND_RETURN_SEARCH_DEFAULTS: 'search/FETCH_AND_RETURN_SEARCH_DEFAULTS',
    FETCH_AND_RETURN_SLIDESHOW: 'search/FETCH_AND_RETURN_SLIDESHOW',
    FETCH_AND_SET_TRIPS_FOR_TRIP_FAMILY: 'search/FETCH_AND_SET_TRIPS_FOR_TRIP_FAMILY',
    FETCH_AND_SET_MAP_MARKERS: 'search/FETCH_AND_SET_MAP_MARKERS',
    FETCH_AND_SET_DIRECTORY: 'directory/FETCH_AND_SET_DIRECTORY',
    FETCH_AND_SET_USER: 'user/FETCH_AND_SET_USER',
    FETCH_AND_SET_QUOTE: 'singleQuote/FETCH_AND_SET_QUOTE',
    FETCH_AND_SET_MULTI_RESOURCE: 'singleMultiResource/FETCH_AND_SET_MULTI_RESOURCE',


    /**
     * NO API CALLS
     */
    SET_LODGE: 'singleLodge/SET_LODGE',
    SET_LODGE_ARTICLES: 'singleLodge/SET_LODGE_ARTICLES',
    SET_LODGE_SLUG: 'singleLodge/SET_LODGE_SLUG',
    SET_LOCATION: 'singleLocation/SET_LOCATION',
    SET_LOCATION_ARTICLES: 'singleLocation/SET_LOCATION_ARTICLES',
    SET_LOCATION_SLUG: 'singleLocation/SET_LOCATION_SLUG',
    SET_RESOURCE: 'singleResource/SET_RESOURCE',
    SET_RESOURCE_ARTICLES: 'singleResource/SET_RESOURCE_ARTICLES',
    SET_RESOURCE_SLUG: 'singleResource/SET_RESOURCE_SLUG',
    SET_EXPERIENCE: 'singleExperience/SET_EXPERIENCE',
    SET_EXPERIENCE_ARTICLES: 'singleExperience/SET_EXPERIENCE_ARTICLES',
    SET_EXPERIENCE_SLUG: 'singleExperience/SET_EXPERIENCE_SLUG',
    SET_PAGE: 'singlePage/SET_PAGE',
    SET_START_ARTICLES: 'startPage/SET_START_ARTICLES',
    SET_EXPERTS: 'startPage/SET_EXPERTS',
    SET_ACTIVE_EXPERT: 'startPage/SET_ACTIVE_EXPERT',
    SET_USER: 'user/SET_USER',
    SET_CURRENCY: 'user/SET_CURRENCY',
    SET_HAS_SEEN_SENTENCE: 'user/SET_HAS_SEEN_SENTENCE',
    FETCH_HAS_SEEN_SENTENCE: 'user/FETCH_HAS_SEEN_SENTENCE',
    SET_HAS_OPENED_NAV: 'user/SET_HAS_OPENED_NAV',
    FETCH_HAS_OPENED_NAV: 'user/FETCH_HAS_OPENED_NAV',
    SET_WISHLIST: 'wishlist/SET_WISHLIST',
    SET_WISHLIST_ITEM: 'wishlist/SET_WISHLIST_ITEM',
    SET_WISHLIST_ITEM_BY_ID: 'wishlist/SET_WISHLIST_ITEM_BY_ID',
    REMOVE_WISHLIST_ITEM: 'wishlist/REMOVE_WISHLIST_ITEM',
    REMOVE_ITEM: 'wishlist/REMOVE_ITEM',
    FETCH_AND_SET_WISHLIST: 'wishlist/FETCH_AND_SET_WISHLIST',
    FETCH_WISHLIST: 'wishlist/FETCH_WISHLIST',
    SET_WISHLIST_API: 'wishlist/SET_WISHLIST_API',
    SET_WISHLIST_TYPE: 'wishlist/SET_WISHLIST_TYPE',
    SET_PAGE_TITLE: 'seo/SET_PAGE_TITLE',
    SET_CANONICAL_URL: 'seo/SET_CANONICAL_URL',
    SET_META_DESCRIPTION: 'seo/SET_META_DESCRIPTION',
    UPDATE_JSON_SCHEMA: 'seo/UPDATE_JSON_SCHEMA',
    UPDATE_SEO_VALUES: 'seo/UPDATE_SEO_VALUES',

    SET_EXPLORE_TYPE: 'search/SET_EXPLORE_TYPE',
    SET_SEARCH_LOCATION: 'search/SET_SEARCH_LOCATION',
    SET_SEARCH_LOCATIONS: 'search/SET_SEARCH_LOCATIONS',
    SET_SEARCH_EXPERIENCE: 'search/SET_SEARCH_EXPERIENCE',
    SET_SEARCH_EXPERIENCES: 'search/SET_SEARCH_EXPERIENCES',
    SET_SEARCH_MONTH: 'search/SET_SEARCH_MONTH',
    SET_SEARCH_DURATION: 'search/SET_SEARCH_DURATION',
    SET_SEARCH_BUDGET_RANGE: 'search/SET_SEARCH_BUDGET_RANGE',
    SET_SEARCH_TRIP_BUDGET_RANGE: 'search/SET_SEARCH_TRIP_BUDGET_RANGE',
    SET_SEARCH_SORT_ORDER: 'search/SET_SEARCH_SORT_ORDER',
    SET_SEARCH_TYPE: 'search/SET_SEARCH_TYPE',
    SET_SEARCH_RESULTS: 'search/SET_SEARCH_RESULTS',
    SET_SEARCH_RESULTS_COUNT: 'search/SET_SEARCH_RESULTS_COUNT',
    SET_SEARCH_CRITERIA_OPTIONS: 'search/SET_SEARCH_CRITERIA_OPTIONS',
    SET_SEARCH_DEFAULTS_LOADED: 'search/SET_SEARCH_DEFAULTS_LOADED',
    SET_TRIPS_FOR_TRIP_FAMILY: 'search/SET_TRIPS_FOR_TRIP_FAMILY',
    SET_ACTIVE_TRIP_ACCORDION: 'search/SET_ACTIVE_TRIP_ACCORDION',
    SET_ACTIVE_TRIP_ACCORDION_PAGE: 'search/SET_ACTIVE_TRIP_ACCORDION_PAGE',
    SET_MAP_MARKERS: 'search/SET_MAP_MARKERS',
    SET_SEARCH_LOCATION_AND_URL: 'search/SET_SEARCH_LOCATION_AND_URL',
    SET_SEARCH_TYPE_URL: 'search/SET_SEARCH_TYPE_URL',
    SET_EDITOR: 'editor/SET_EDITOR',
    SET_EDITOR_CKEDITOR: 'editor/SET_EDITOR_CKEDITOR',
    SET_EDITOR_DIRTY: 'editor/SET_EDITOR_DIRTY',
    SET_TRIP: 'singleTrip/SET_TRIP',
    SET_MAP: 'singleTrip/SET_MAP',
    SET_LODGE_TABLE: 'singleTrip/SET_LODGE_TABLE',
    SET_MULTI_RESOURCE: 'singleMultiResource/SET_MULTI_RESOURCE',
    SET_MULTI_RESOURCE_ARTICLES: 'singleMultiResource/SET_MULTI_RESOURCE_ARTICLES',
    SET_MULTI_RESOURCE_SLUG: 'singleMultiResource/SET_MULTI_RESOURCE_SLUG',
    SET_MULTI_RESOURCE_TYPE: 'singleMultiResource/SET_MULTI_RESOURCE_TYPE',

    SET_QUOTE: 'singleQuote/SET_QUOTE',
    SET_QUOTE_MAP: 'singleQuote/SET_QUOTE_MAP',
    SET_QUOTE_LODGE_TABLE: 'singleQuote/SET_QUOTE_LODGE_TABLE',

    SET_RESULTS: 'globalSearch/SET_RESULTS',
    CLEAR_RESULTS: 'globalSearch/CLEAR_RESULTS',
    SET_AND_FETCH_RESULTS: 'globalSearch/SET_AND_FETCH_RESULTS',
    CLEAR_AND_RETURN_RESULTS: 'globalSearch/CLEAR_AND_RETURN_RESULTS',

    SET_DIRECTORY_SEARCH_RESULTS: 'directorySearch/SET_DIRECTORY_SEARCH_RESULTS',
    CLEAR_DIRECTORY_SEARCH_RESULTS: 'directorySearch/CLEAR_DIRECTORY_SEARCH_RESULTS',
    SET_AND_FETCH_DIRECTORY_SEARCH_RESULTS: 'directorySearch/SET_AND_FETCH_DIRECTORY_SEARCH_RESULTS',
    CLEAR_AND_RETURN_DIRECTORY_SEARCH_RESULTS: 'directorySearch/CLEAR_AND_RETURN_DIRECTORY_SEARCH_RESULTS',

    SET_DIRECTORY: 'directory/SET_DIRECTORY',
    SET_LOCATION_COUNT: 'directory/SET_LOCATION_COUNT',
    SET_LODGE_COUNT: 'directory/SET_LODGE_COUNT',
    SET_OPEN_NODE_ID: 'directory/SET_OPEN_NODE_ID',

    SET_ACTIVE_ARTICLE: 'activeArticle/SET_ACTIVE_ARTICLE',
    CLEAR_ACTIVE_ARTICLE: 'activeArticle/CLEAR_ACTIVE_ARTICLE',

    SET_TRANSITION: 'transitionManager/SET_TRANSITION',
    SET_CURRENT_ARTICLE: 'transitionManager/SET_CURRENT_ARTICLE',
    SET_CURRENT_ARTICLE_IMAGE: 'transitionManager/SET_CURRENT_ARTICLE_IMAGE',
    SET_SCROLL_POSITION: 'transitionManager/SET_SCROLL_POSITION',
    SET_FROM_PAGE: 'transitionManager/SET_FROM_PAGE',
    INCREMENT_PAGE_DEPTH: 'transitionManager/INCREMENT_PAGE_DEPTH',
    RESET_PAGE_DEPTH: 'transitionManager/RESET_PAGE_DEPTH',
    SET_ACCORDION_HISTORY: 'transitionManager/SET_ACCORDION_HISTORY',

    /**
     * API PUT/PATCH/POST
     */
    STORE_LODGE: 'singleLodge/STORE_LODGE',
    STORE_LOCATION: 'singleLocation/STORE_LOCATION',
    STORE_RESOURCE: 'singleResource/STORE_RESOURCE',
    STORE_EXPERIENCE: 'singleExperience/STORE_EXPERIENCE',
    STORE_DATA: 'editor/STORE_DATA',
    STORE_MULTI_RESOURCE: 'singleMultiResource/STORE_MULTI_RESOURCE',

    /**
     * CONTACT FORMS
     */
    SEND_EMAIL: 'contactInfo/SEND_EMAIL',
    SEND_CALLBACK_REQUEST: 'contactInfo/SEND_CALLBACK_REQUEST',
    ADD_WISHLIST_TO_QUOTE: 'contactInfo/ADD_WISHLIST_TO_QUOTE',
    REMOVE_WISHLIST_FROM_QUOTE: 'contactInfo/REMOVE_WISHLIST_FROM_QUOTE',
    SEND_QUOTE_REQUEST: 'contactInfo/SEND_QUOTE_REQUEST'
};

window.editorTypes = {
    LODGE: 1,
    LOCATION: 2,
    RESOURCE: 3,
    EXPERIENCE: 4,
    PAGE: 5,
    MULTI_RESOURCE: 6
};

const store = new Vuex.Store({
    strict: true,
    modules: {
        transitionManager: {
            namespaced: true,
            state: {
                transitionToUse: false,
                currentArticle: {},
                fromSearch: false,
                pageDepth: 0,
                accordionHistory: {},
            },
            mutations: {
                SET_TRANSITION: function (state, transitionName) {
                    state.transitionToUse = transitionName;
                },
                SET_CURRENT_ARTICLE: function(state, currentArticle) {
                    state.currentArticle = currentArticle;
                },
                SET_CURRENT_ARTICLE_IMAGE: function(state, image) {
                    if(state.currentArticle){
                        state.currentArticle.image = image;
                    }
                },
                SET_FROM_PAGE(state, payload) {
                    state.fromSearch = payload;
                },
                INCREMENT_PAGE_DEPTH(state) {
                    state.pageDepth += 1;
                },
                RESET_PAGE_DEPTH(state) {
                    state.pageDepth = 0;
                },
                SET_ACCORDION_HISTORY(state, payload){
                    state.accordionHistory[Object.keys(payload)] = payload[Object.keys(payload)]
                }
            }
        },
        activeArticle:{
            namespaced: true,
            state: {
                article: {
                    type: 'resource',
                    display_type: 1,
                    lastId: 0
                }
            },
            mutations: {
                SET_ACTIVE_ARTICLE: function (state, payload) {
                    state.article = {...state.article, ...payload};
                },
                CLEAR_ACTIVE_ARTICLE: function (state, payload) {
                    state.article = {};
                }
            }
        },
        singleLodge: {
            namespaced: true,
            state: {
                lodge: {
                    top_section: {
                        id: null
                    },
                    sections: {
                        data: []
                    },
                    allSections: {
                        data: []
                    },
                    hiddenSections: {
                        data: []
                    },
                    trips_section: {},
                    other_lodge_section: {},
                    edit: false,
                    articleLinks: {
                        data: []
                    },
                    full_url: null,
                    resource_meta: {
                        page_title: '',
                        meta_description: '',
                    },
                    page_meta: [],
                },
                articles: [],
                slug: ''
            },
            mutations: {
                SET_LODGE: function (state, payload) {
                    var payloadParser = new PayloadParser(payload);
                    var p = payloadParser.process();
                    state.lodge = window._.defaultsDeep({}, p);
                    new AtrStorage().store('lodge_' + state.slug, p);
                    this.commit(window.store.SET_LODGE_ARTICLES, payloadParser.extractArticles(), {root: true});

                    this.commit(window.store.UPDATE_SEO_VALUES, {
                        page_title: state.lodge.resource_meta.page_title,
                        meta_description: state.lodge.resource_meta.meta_description,
                        canonical_url: state.lodge.full_url,
                        schema_data: new SchemaData().getLodgeSchema(state.lodge),
                        variable_dictionary: {
                            name: state.lodge.title
                        },
                    });
                },
                SET_LODGE_ARTICLES: function (state, payload) {
                    state.articles = window._.defaultsDeep({}, payload);
                },
                SET_LODGE_SLUG: function (state, slug) {
                    state.slug = slug;
                }
            },
            actions: {
                FETCH_AND_RETURN_LODGE: function (context, id) {
                    let url = window.API_URL + 'searchitem/lodge/' + id;

                    let data = new AtrStorage().fetch('lodge_' + id);

                    if (data !== false) {
                        return data;
                    } else {
                        return axios.get(url).then(({ data }) => {
                            // new AtrStorage().store('lodge_' + id, data.data[0]);
                            return data.data[0];
                        });
                    }
                },
                FETCH_AND_RETURN_LODGE_PARENTS(context, id) {
                    let url = window.API_URL + 'lodge-slug/' + id + '/parents';

                    return axios.get(url).then(({ data }) => {
                        return data;
                    });
                },
                FETCH_AND_SET_LODGE: function (context, slug) {
                    context.commit(window.store.SET_LODGE_SLUG, slug, {root: true});

                    let isEdit = window.location.href.indexOf('edit=1');

                    let url = window.API_URL + 'lodge-slug/' + slug + (isEdit !== -1 ? '?edit=1' : '');

                    var data = new AtrStorage().fetch('lodge_' + slug);

                    if (data !== false) {
                        context.commit(window.store.SET_LODGE, data, {root: true});
                    } else {
                        return axios.get(url).then(function (response) {
                            context.commit(window.store.SET_LODGE, response.data, {root: true});
                        });
                    }
                },
                STORE_LODGE: function(context, slug) {
                    new AtrStorage().purge('lodge_' + slug);
                    return axios.patch(window.API_URL + 'lodge-slug/' + slug, {
                        item: context.state.lodge,
                        articles: context.state.articles
                    })
                    .then(function(response) {
                        $.notify(response.data.message, response.data.type);
                        context.commit(window.store.SET_EDITOR, {
                            isDirty: false,
                            type: window.editorTypes.LODGE
                        }, {root: true});
                    })
                    .catch((err) => {
                        $.notify('There was an error processing your request', 'error');
                    });
                }
            }
        },
        singleLocation: {
            namespaced: true,
            state: {
                location: {
                    top_section: {
                        id: null
                    },
                    sections: {
                        data: []
                    },
                    allSections: {
                        data: []
                    },
                    hiddenSections: {
                        data: []
                    },
                    full_url: null,
                    trips_section: {},
                    other_locations_section: {},
                    articleLinks: {
                        data: []
                    },
                    resource_meta: {
                        page_title: '',
                        meta_description: '',
                    },
                    page_meta: [],
                },
                articles: [],
                slug: '',
            },
            mutations: {
                SET_LOCATION: function (state, payload) {
                    var payloadParser = new PayloadParser(payload);

                    var p = payloadParser.process();

                    state.location = window._.defaultsDeep({}, p);

                    new AtrStorage().store('location_' + state.slug, p);

                    this.commit(window.store.SET_LOCATION_ARTICLES, payloadParser.extractArticles(), {root: true});

                    this.commit(window.store.UPDATE_SEO_VALUES, {
                        page_title: state.location.resource_meta.page_title,
                        meta_description: state.location.resource_meta.meta_description,
                        canonical_url: state.location.full_url,
                        schema_data: new SchemaData().getLocationSchema(state.location),
                        variable_dictionary: {
                            name: state.location.title
                        },
                    });
                },
                SET_LOCATION_ARTICLES: function (state, payload) {
                    state.articles = window._.defaultsDeep({}, payload);
                },
                SET_LOCATION_SLUG: function (state, slug) {
                    state.slug = slug;
                }
            },
            actions: {
                FETCH_AND_RETURN_LOCATION: function (context, id) {
                    let url = window.API_URL + 'searchitem/location/' + id

                    var data = new AtrStorage().fetch('location_' + id);

                    if (data !== false) {
                        return data;
                    } else {
                        return axios.get(url).then(function ({data}) {
                            // new AtrStorage().store('location_' + id, data.data[0]);
                            return data.data[0];
                        });
                    }
                },
                FETCH_AND_RETURN_LOCATION_PARENTS(context, id) {
                    let url = window.API_URL + 'location-slug/' + id + '/parents';

                    return axios.get(url).then(({ data }) => {
                        return data;
                    });
                },
                FETCH_AND_SET_LOCATION: function (context, slug) {
                    context.commit(window.store.SET_LOCATION_SLUG, slug, {root: true});

                    let isEdit = window.location.href.indexOf('edit=1');

                    let url = window.API_URL + 'location-slug/' + slug + (isEdit !== -1 ? '?edit=1' : '');

                    var data = new AtrStorage().fetch('location_' + slug);

                    if (data !== false) {
                        context.commit(window.store.SET_LOCATION, data, {root: true});
                    } else {
                        return axios.get(url).then(function (response) {
                            context.commit(window.store.SET_LOCATION, response.data, {root: true});
                        });
                    }
                },
                STORE_LOCATION: function (context, slug) {
                    new AtrStorage().purge('location_' + slug);
                    return axios.patch(window.API_URL + 'location-slug/' + slug, {
                        item: context.state.location,
                        articles: context.state.articles
                    })
                        .then(function (response) {
                            $.notify(response.data.message, response.data.type);
                            context.commit(window.store.SET_EDITOR, {
                                isDirty: false,
                                type: window.editorTypes.LOCATION
                            }, {root: true});
                        })
                        .catch((err) => {
                            $.notify('There was an error processing your request', 'error');
                        });
                }
            }
        },
        singleResource: {
            namespaced: true,
            state: {
                resource: {
                    id: '',
                    parent_id: '',
                    identifier: '',
                    article_id: '',
                    title: '',
                    content: '',
                    full_url: null,
                    image: '',
                    images: {
                        sizes: {
                            extralarge: null
                        }
                    },
                    article: {
                        title: '',
                        content: ''
                    },
                    sections: {
                        data: []
                    },
                    allSections: {
                        data: []
                    },
                    hiddenSections: {
                        data: []
                    },
                    edit: false,
                    articleLinks: {
                        data: []
                    },
                    top_section: {
                        id: null,
                        content: null,
                        tag: null,
                        title: null
                    },
                    resource_meta: {
                        page_title: '',
                        meta_description: '',
                    },
                    page_meta: [],
                },
                articles: [],
                slug: '',
            },
            mutations: {
                SET_RESOURCE: function (state, payload) {
                    var payloadParser = new PayloadParser(payload);
                    var p = payloadParser.process();
                    state.resource = window._.defaultsDeep({}, p);
                    new AtrStorage().store('resource_' + state.slug, p);
                    this.commit(window.store.SET_RESOURCE_ARTICLES, payloadParser.extractArticles(), {root: true});

                    this.commit(window.store.UPDATE_SEO_VALUES, {
                        page_title: state.resource.resource_meta.page_title,
                        meta_description: state.resource.resource_meta.meta_description,
                        canonical_url: state.resource.full_url,
                        schema_data: new SchemaData().getResourceSchema(state.resource),
                        variable_dictionary: {
                            name: state.resource.title
                        },
                    });
                },
                SET_RESOURCE_ARTICLES: function (state, payload) {
                    state.articles = window._.defaultsDeep({}, payload);
                },
                SET_RESOURCE_SLUG: function (state, slug) {
                    state.slug = slug;
                }
            },
            actions: {
                FETCH_AND_SET_RESOURCE: function (context, slug) {
                    context.commit(window.store.SET_RESOURCE_SLUG, slug, {root: true});
                    let isEdit = window.location.href.indexOf('edit=1');
                    var bit = "?include=allSections,article";
                    if (isEdit !== -1) {
                        bit += '&edit=1';
                    }
                    let url = window.API_URL + 'resource-slug/' + slug + bit;
                    var data = new AtrStorage().fetch('resource_' + slug);
                    if (data !== false) {
                        context.commit(window.store.SET_RESOURCE, data, {root: true});
                    } else {
                        return axios.get(url).then(function (response) {
                            context.commit(window.store.SET_RESOURCE, response.data, {root: true});
                        });
                    }
                },
                STORE_RESOURCE: function (context, slug) {
                    new AtrStorage().purge('resource_' + slug);
                    return axios.patch(window.API_URL + 'resource-slug/' + slug, {
                        item: context.state.resource,
                        articles: context.state.articles
                    })
                        .then(function (response) {
                            $.notify(response.data.message, response.data.type);
                            context.commit(window.store.SET_EDITOR, {
                                isDirty: false,
                                type: window.editorTypes.RESOURCE
                            }, {root: true});
                        })
                        .catch((err) => {
                            $.notify('There was an error processing your request', 'error');
                        });
                }
            }
        },
        singleMultiResource: {
            namespaced: true,
            state: {
                resource: {
                    id: '',
                    type_id: '',
                    parent_id: '',
                    identifier: '',
                    article_id: '',
                    title: '',
                    content: '',
                    full_url: null,
                    image: '',
                    images: {
                        sizes: {
                            extralarge: null
                        }
                    },
                    article: {
                        title: '',
                        content: ''
                    },
                    sections: {
                        data: []
                    },
                    allSections: {
                        data: []
                    },
                    hiddenSections: {
                        data: []
                    },
                    edit: false,
                    articleLinks: {
                        data: []
                    },
                    top_section: {
                        id: null,
                        content: null,
                        tag: null,
                        title: null
                    },
                    resource_meta: {
                        page_title: '',
                        meta_description: '',
                    },
                    page_meta: [],
                },
                articles: [],
                slug: '',
                type: GlobalVars.ARTICLES,
            },
            mutations: {
                SET_MULTI_RESOURCE: function (state, payload) {
                    var payloadParser = new PayloadParser(payload);
                    var p = payloadParser.process();
                    state.resource = window._.defaultsDeep({}, p);
                    new AtrStorage().store('multi_resource_' + + state.type + '_' + state.slug, p);
                    this.commit(window.store.SET_MULTI_RESOURCE_ARTICLES, payloadParser.extractArticles(), {root: true});

                    this.commit(window.store.UPDATE_SEO_VALUES, {
                        page_title: state.resource.resource_meta.page_title,
                        meta_description: state.resource.resource_meta.meta_description,
                        canonical_url: state.resource.full_url,
                        schema_data: new SchemaData().getResourceSchema(state.resource),
                        variable_dictionary: {
                            name: state.resource.title
                        },
                    });
                },
                SET_MULTI_RESOURCE_ARTICLES: function (state, payload) {
                    state.articles = window._.defaultsDeep({}, payload);
                },
                SET_MULTI_RESOURCE_SLUG: function (state, slug) {
                    state.slug = slug;
                },
                SET_MULTI_RESOURCE_TYPE: function (state, type) {
                    state.type = type;
                }
            },
            actions: {
                FETCH_AND_SET_MULTI_RESOURCE: function (context, params) {
                    context.commit(window.store.SET_MULTI_RESOURCE_SLUG, params.slug, {root: true});
                    context.commit(window.store.SET_MULTI_RESOURCE_TYPE, params.type, {root: true});
                    let isEdit = window.location.href.indexOf('edit=1');
                    var bit = "?include=allSections,article";
                    if (isEdit !== -1) {
                        bit += '&edit=1';
                    }
                    let url = window.API_URL + 'multi-resource-slug/' + params.type + '/' + params.slug + bit;
                    var data = new AtrStorage().fetch('multi_resource_' + params.type + '_' + params.slug);
                    if (data !== false) {
                        context.commit(window.store.SET_MULTI_RESOURCE, data, {root: true});
                    } else {
                        return axios.get(url).then(function (response) {
                            context.commit(window.store.SET_MULTI_RESOURCE, response.data, {root: true});
                        });
                    }
                },
                STORE_MULTI_RESOURCE: function (context, slug) {
                    new AtrStorage().purge('multi_resource_' + context.state.resource.type_id + '_' + slug);
                    return axios.patch(window.API_URL + 'multi-resource-slug/' + context.state.resource.type_id + '/' + slug, {
                        item: context.state.resource,
                        articles: context.state.articles
                    })
                        .then(function (response) {
                            $.notify(response.data.message, response.data.type);
                            context.commit(window.store.SET_EDITOR, {
                                isDirty: false,
                                type: window.editorTypes.MULTI_RESOURCE
                            }, {root: true});
                        })
                        .catch((err) => {
                            $.notify('There was an error processing your request', 'error');
                        });
                }
            }
        },
        singleExperience: {
            namespaced: true,
            state: {
                experience: {
                    title: '',
                    rating: 0,
                    guide_price: 0,
                    conservation_rating: 0,
                    image: '',
                    full_url: null,
                    images: {
                        sizes: {
                            extralarge: null
                        }
                    },
                    top_section: {
                        id: null
                    },
                    sections: {
                        data: []
                    },
                    allSections: {
                        data: []
                    },
                    hiddenSections: {
                        data: []
                    },
                    trips_section: {},
                    other_experiences_section: {},
                    edit: false,
                    articleLinks: {
                        data: []
                    },
                    resource_meta: {
                        page_title: '',
                        meta_description: '',
                    },
                    page_meta: [],
                },
                articles: [],
                slug: ''
            },
            mutations: {
                SET_EXPERIENCE: function (state, payload) {
                    var payloadParser = new PayloadParser(payload);
                    var p = payloadParser.process();
                    state.experience = window._.defaultsDeep({}, p);
                    new AtrStorage().store('experience_' + state.slug, p);
                    this.commit(window.store.SET_EXPERIENCE_ARTICLES, payloadParser.extractArticles(), {root: true});

                    this.commit(window.store.UPDATE_SEO_VALUES, {
                        page_title: state.experience.resource_meta.page_title,
                        meta_description: state.experience.resource_meta.meta_description,
                        canonical_url: state.experience.full_url,
                        schema_data: new SchemaData().getExperienceSchema(state.experience),
                        variable_dictionary: {
                            name: state.experience.title
                        },
                    });
                },
                SET_EXPERIENCE_ARTICLES: function (state, payload) {
                    state.articles = window._.defaultsDeep({}, payload);
                },
                SET_EXPERIENCE_SLUG: function (state, slug) {
                    state.slug = slug;
                }
            },
            actions: {
                FETCH_AND_SET_EXPERIENCE: function (context, slug) {
                    context.commit(window.store.SET_EXPERIENCE_SLUG, slug, {root: true});

                    let isEdit = window.location.href.indexOf('edit=1');

                    let url = window.API_URL + 'experience-slug/' + slug + (isEdit != -1 ? '?edit=1' : '');

                    let data = new AtrStorage().fetch('experience_' + slug);

                    if (data !== false) {
                        context.commit(window.store.SET_EXPERIENCE, data, {root: true});
                    } else {
                        return axios.get(url).then(function (response) {
                            context.commit(window.store.SET_EXPERIENCE, response.data, {root: true});
                        });
                    }
                },
                STORE_EXPERIENCE: function (context, slug) {
                    new AtrStorage().purge('experience_' + slug);
                    return axios.patch(window.API_URL + 'experience-slug/' + slug, {
                        item: context.state.experience,
                        articles: context.state.articles
                    })
                        .then(function (response) {
                            $.notify(response.data.message, response.data.type);
                            context.commit(window.store.SET_EDITOR, {
                                isDirty: false,
                                type: window.editorTypes.EXPERIENCE
                            }, {root: true});
                        })
                        .catch((err) => {
                            $.notify('There was an error processing your request', 'error');
                        });
                }
            }
        },
        singleTrip: {
            namespaced: true,
            state: {
                trip: {}
            },
            mutations: {
                SET_TRIP(state, trip) {
                    state.trip = trip;
                },
                SET_MAP(state, trip) {
                    if(!_.isUndefined(pnpHelper.map)) {
                        pnpHelper.map.renderTripSummary(trip, null, true);
                    }
                },
                SET_LODGE_TABLE(state, lodgeInfo){
                    let stateLocations = window._.defaultsDeep([], state.trip.locations);

                    let location = stateLocations.filter((location) => {
                        return location.id === lodgeInfo.locationId;
                    });

                    location[0].lodges.forEach((lodge, index) => {
                        lodge.selected = index === lodgeInfo.lodgeIndex;
                    });

                    state.trip.locations = stateLocations;
                }
            },
            actions: {
                FETCH_AND_SET_TRIP(context, data) {
                    let url = window.API_URL + 'trip/' + data.slug;
                    if(data.hasOwnProperty('experience')){
                        url+='?experience=' + data.experience;
                    }
                    return axios.get(url).then(function (response) {
                        context.commit(window.store.SET_TRIP, response.data, {root: true});
                    });
                }
            }
        },
        singleQuote: {
            namespaced: true,
            state: {
                quote: {}
            },
            mutations: {
                SET_QUOTE(state, quote) {
                    state.quote = quote;
                },
                SET_QUOTE_MAP(state, quote) {
                    if(!_.isUndefined(pnpHelper.map)) {
                        pnpHelper.map.renderTripSummary(quote, null, true);
                    }
                },
                SET_QUOTE_LODGE_TABLE(state, lodgeInfo){
                    let stateLocations = window._.defaultsDeep([], state.quote.locations);

                    let location = stateLocations.filter((location) => {
                        return location.id === lodgeInfo.locationId;
                    });

                    location[0].lodges.forEach((lodge, index) => {
                        lodge.selected = index === lodgeInfo.lodgeIndex;
                    });

                    state.quote.locations = stateLocations;
                }
            },
            actions: {
                FETCH_AND_SET_QUOTE: function (context, slug) {
                    let url = window.API_URL + 'quote/' + slug;
                    return axios.get(url).then(function (response) {
                        context.commit(window.store.SET_QUOTE, response.data, {root: true});
                    });
                },
            }
        },
        editor: {
            namespaced: true,
            state: {
                editor: {
                    isDirty: false,
                    type: window.editorTypes.LODGE
                },
                CKEDITOR: false
            },
            mutations: {
                SET_EDITOR: function (state, payload) {
                    state.editor = window._.defaultsDeep({}, payload);
                },
                SET_EDITOR_CKEDITOR: function (state, payload) {
                    state.CKEDITOR = payload;
                }
            },
            actions: {
                STORE_DATA: function (context, slug) {
                    let actionKey;
                    let resourceType;

                    switch (context.state.editor.type) {
                        case window.editorTypes.RESOURCE:
                            resourceType = 'resource';
                            actionKey = window.store.STORE_RESOURCE;
                        break;
                        case window.editorTypes.EXPERIENCE:
                            resourceType = 'experience';
                            actionKey = window.store.STORE_EXPERIENCE;
                        break;
                        case window.editorTypes.LODGE:
                            resourceType = 'lodge';
                            actionKey = window.store.STORE_LODGE;
                        break;
                        case window.editorTypes.LOCATION:
                            resourceType = 'location';
                            actionKey = window.store.STORE_LOCATION;
                        break;
                        case window.editorTypes.MULTI_RESOURCE:
                            resourceType = 'multi_resource_' + context.rootState.singleMultiResource.resource.type_id;
                            actionKey = window.store.STORE_MULTI_RESOURCE;
                        break;
                    }

                    new AtrStorage().purge(resourceType + '_' + slug);
                    return context.dispatch(actionKey, slug, {root: true});
                },
                SET_EDITOR_DIRTY: function (context, type) {
                    context.commit(window.store.SET_EDITOR, {
                        isDirty: true,
                        type: type
                    }, {
                        root: true
                    });
                }
            }
        },
        singlePage: {
            namespaced: true,
            state: {
                page: {
                    sections: {
                        data: []
                    },
                    edit: false
                },
                articles: [],
                slug: ''
            },
            mutations: {
                SET_PAGE: function (state, payload) {
                    state.page = window._.defaultsDeep({}, payload);
                }
            },
            actions: {
                FETCH_AND_SET_PAGE: function (context, slug) {
                    var data = {
                        sections: {
                            data: [
                                {
                                    id: 'home_section_1',
                                    name: 'Welcome',
                                    internal_name: 'welcome',
                                    delay: null,
                                    autoplay: true,
                                    hidden: 0,
                                    see_more: 0,
                                    see_all: [],
                                    order: '',
                                    bg_img_url: '/assets/img/temp/image1_lion.jpg',
                                    content: 'Answers to some of those common opening questions.',
                                    actions: [],
                                    buttons: [
                                        {
                                            text: 'View',
                                            style: 'large_round_arrow_right',
                                            action: 'internal',
                                            link: '/safari-basics',
                                            article: null,
                                            type: 'openExternal',
                                            modal: null
                                        }
                                    ],
                                    slides: {
                                        data: [
                                            {
                                                id: 'home_slide_1',
                                                caption: 'What is safari',
                                                img_url: '/assets/img/temp/safari-basics/image1_1.jpg',
                                                hidden: 0,
                                                lazy_load: true,
                                                content: 'What is safari',
                                                buttons: [],
                                                actions: [],
                                            }
                                        ]
                                    },
                                    allSlides: {
                                        data: []
                                    },
                                },
                                {
                                    id: 'home_section_2',
                                    name: 'Inspiration',
                                    internal_name: 'inspiration',
                                    delay: null,
                                    autoplay: true,
                                    hidden: 0,
                                    see_more: 0,
                                    see_all: [],
                                    order: '',
                                    bg_img_url: '/assets/img/temp/image5.jpg',
                                    content: 'A quirky smorgasbord of ideas, articles and news items<br/> for the African adventurer.',
                                    actions: [],
                                    buttons: [],
                                    slides: {
                                        data: []
                                    },
                                    allSlides: {
                                        data: []
                                    },
                                },
                                {
                                    id: 'home_section_3',
                                    name: 'Getting Started',
                                    internal_name: 'getting_started',
                                    delay: null,
                                    autoplay: true,
                                    hidden: 0,
                                    see_more: 0,
                                    see_all: [],
                                    order: '',
                                    bg_img_url: '/assets/img/temp/image2.jpg',
                                    content: 'Simple steps to help you on your way<br/> to the perfect trip.',
                                    actions: [],
                                    buttons: [
                                        {
                                            text: 'View',
                                            style: 'large_round_arrow_right',
                                            action: 'internal',
                                            link: '/safari-resources/getting-started',
                                            article: null,
                                            type: 'openExternal',
                                            modal: null
                                        }
                                    ],
                                    slides: {
                                        data: []
                                    },
                                    allSlides: {
                                        data: []
                                    },
                                },
                                {
                                    id: 'home_section_4',
                                    name: 'Exploration',
                                    internal_name: 'exploration',
                                    delay: null,
                                    autoplay: true,
                                    hidden: 0,
                                    see_more: 0,
                                    see_all: [],
                                    order: '',
                                    bg_img_url: '/assets/img/temp/image9.jpg',
                                    content: 'All the best locations, lodges and trips<br/> ready for you to explore.',
                                    actions: [],
                                    buttons: [
                                        {
                                            text: 'View',
                                            style: 'large_round_arrow_right',
                                            action: 'internal',
                                            link: '/explore',
                                            article: null,
                                            type: 'openExternal',
                                            modal: null
                                        }
                                    ],
                                    slides: {
                                        data: []
                                    },
                                    allSlides: {
                                        data: []
                                    },
                                },
                                {
                                    id: 'home_section_5',
                                    name: 'Resources',
                                    internal_name: 'resources',
                                    delay: null,
                                    autoplay: true,
                                    hidden: 0,
                                    see_more: 0,
                                    see_all: [],
                                    order: '',
                                    bg_img_url: '/img/temp/image6.jpg',
                                    content: 'A resource of practical travel information<br/> to help you on your way.',
                                    actions: [],
                                    buttons: [],
                                    slides: {
                                        data: []
                                    },
                                    allSlides: {
                                        data: []
                                    },
                                },
                                {
                                    id: 'home_section_6',
                                    name: 'Contact',
                                    internal_name: 'contact',
                                    delay: null,
                                    autoplay: true,
                                    hidden: 0,
                                    see_more: 0,
                                    see_all: [],
                                    order: '',
                                    bg_img_url: '/img/temp/image12.jpg',
                                    content: 'It\'s never too soon to get in touch,<br/> we are always ready to help.',
                                    actions: [],
                                    buttons: [],
                                    slides: {
                                        data: []
                                    },
                                    allSlides: {
                                        data: []
                                    },
                                }
                            ]
                        }
                    };
                    context.commit(window.store.SET_PAGE, data, {root: true});
                }
            }
        },
        search: {
            namespaced: true,
            state: {
                criteria: {
                    options: {
                        locations: [],
                        experiences: [],
                        months: []
                    },
                    params: {
                        type: null,
                        experience: null,
                        experiences: [],
                        location: null,
                        locations: [],
                        month: 0,
                        duration: 12,
                        budgetFrom: 0,
                        budgetTo: 5000,
                        tripBudgetFrom: 0,
                        tripBudgetTo: 10000,
                        sortOrder: {
                            order: null,
                            label: null
                        }
                    }
                },
                results: {
                    count: null,
                    type: null,
                    data: []
                },
                selectedResult: {},
                defaultsLoaded: false,
                trips: {},
                tripMeta: {
                    activeAccordion: null,
                    currentPage: 1,
                },
                mapMarkers: [],
                exploreType: ''
            },
            getters: {
                getBuget: (state) => (rate) => {
                    return state.todos.find(todo => todo.id === id)
                },
                getResultById: (state) => (id) => {
                    return _.find(state.results.data, (result) => {
                        return result.id == id;
                    });
                }
            },
            mutations: {
                SET_EXPLORE_TYPE(state, payload){
                    state.exploreType = payload;
                },
                SET_SEARCH_TYPE_URL: (state, type) => {
                    window.bus.$emit('searchSetSearchType', {
                        type: type,
                        preventSearch: true
                    });

                    let queryParams = window._.defaultsDeep(
                        { type: type },
                        new UrlMap().searchStateToUrlParams(state)
                    );

                    if(state.exploreType == 'directory' && (type === 'lodge' || type === 'location')){

                        state.criteria.params.type = type;
                        router.push({ name: 'directory' });
                        return;

                    } else if(!((router.currentRoute.name === 'directory') && (type === 'lodge' || type === 'location')) &&

                        !(router.currentRoute.name === 'wishlist' && type !== 'resource')) {

                        router.push({name: 'finder', query: queryParams});

                    } else if(pnpHelper.page.isWishlistPage()) {

                        router.push({name: 'wishlist', query: {type: type}});

                    }
                },
                SET_SEARCH_LOCATION_AND_URL: (state, payload) => {
                    state.criteria.params.location = payload;
                    state.criteria.params.locations = [payload];

                    let urlMap = new UrlMap();

                    let queryParams = _.defaultsDeep({
                        location: payload.id,
                        lname: payload.name,
                        lprimary: payload.lprimary,
                        locations: urlMap.urlEncode([payload])
                    }, urlMap.searchStateToUrlParams(state));

                    router.push({name: 'finder', query: queryParams});
                },
                SET_MAP_MARKERS: (state, markers) => {
                    state.mapMarkers = markers;
                },
                SET_ACTIVE_TRIP_ACCORDION: function (state, tripId) {
                    state.tripMeta.activeAccordion = tripId;
                },
                SET_ACTIVE_TRIP_ACCORDION_PAGE: function (state, newPageNumber) {
                    state.tripMeta.currentPage = newPageNumber;
                },
                SET_SEARCH_LOCATION: function (state, payload) {
                    state.criteria.params.location = payload;
                },
                SET_SEARCH_LOCATIONS: function (state, payload){
                    state.criteria.params.locations = payload;
                },
                SET_SEARCH_EXPERIENCE: function (state, payload) {
                    state.criteria.params.experience = payload;
                },
                SET_SEARCH_EXPERIENCES: function (state, payload) {
                    state.criteria.params.experiences = payload;
                },
                SET_SEARCH_MONTH: function (state, payload) {
                    state.criteria.params.month = payload;
                },
                SET_SEARCH_DURATION: function (state, payload) {
                    state.criteria.params.duration = payload;
                },
                SET_SEARCH_SORT_ORDER: function (state, payload) {
                    state.criteria.params.sortOrder = payload;
                },
                SET_SEARCH_BUDGET_RANGE: function (state, payload){
                    state.criteria.params.budgetFrom = payload[0];
                    state.criteria.params.budgetTo = payload[1];
                },
                SET_SEARCH_TRIP_BUDGET_RANGE: function (state, payload){
                    state.criteria.params.tripBudgetFrom = payload[0];
                    state.criteria.params.tripBudgetTo = payload[1];
                },
                SET_SEARCH_TYPE: function (state, payload) {
                    state.criteria.params.type = payload;
                },
                SET_SEARCH_RESULTS: function (state, payload) {
                    if(payload.type === 'resource'){
                        let  p =window._.defaultsDeep({}, payload.data);
                        new AtrStorage().store('resource_results', p, 43200);
                    }

                    ObjectCache.set(state.criteria.params, payload.data);

                    state.results.data = payload.data;
                    state.results.type = payload.type;
                },
                SET_SEARCH_RESULTS_COUNT: function (state, payload) {
                    if(state.criteria.params.type === 'resource'){
                        new AtrStorage().store('resource_count', payload, 43200);
                    }

                    state.results.count = payload;
                },
                SET_SEARCH_CRITERIA_OPTIONS: function (state, payload) {
                    state.criteria.options = payload;
                },
                SET_SEARCH_SELECTED_RESULT: function (state, payload) {
                    state.selectedResult = payload;
                },
                SET_SEARCH_DEFAULTS_LOADED: function (state, payload) {
                    state.defaultsLoaded = payload;
                },
                SET_TRIPS_FOR_TRIP_FAMILY: function (state, payload) {
                    if (_.isUndefined(payload['family_id'])) {
                        state.trips = payload;
                        return;
                    }

                    let familyId = payload['family_id'];
                    delete payload['family_id'];

                    state.trips = {
                        ...state.trips,
                        [familyId]: payload
                    };
                }
            },
            actions: {
                FETCH_AND_SET_MAP_MARKERS: (context, typeId) => {
                    return axios.get(window.API_URL + 'geo?includes=' + typeId).then((response) => {
                        context.commit(window.store.SET_MAP_MARKERS, response.data.data[typeId].data, {root: true});
                    });
                },
                FETCH_AND_SET_SEARCH_RESULTS_COUNT: function (context) {
                    let searchCriteria = window._.defaultsDeep({}, context.state.criteria.params);
                    if (context.state.criteria.params.month !== null ||
                        context.state.criteria.params.location !== null ||
                        context.state.criteria.params.experience !== null) {

                        let count = new AtrStorage().fetch('resource_count');

                        if(count !== false && context.state.criteria.params.type === 'resource'){
                            context.commit(window.store.SET_SEARCH_RESULTS_COUNT, count, {root: true});
                        } else {
                            return axios.post(window.API_URL + 'searchcount', searchCriteria).then(function (response) {
                                context.commit(window.store.SET_SEARCH_RESULTS_COUNT, response.data.total, {root: true});
                            });
                        }
                    }
                },
                FETCH_AND_RETURN_SEARCH_DEFAULTS: function (context) {
                    return axios.get(window.API_URL + 'search/lodge/defaults')
                        .then(response => {
                            return {
                                experience: response.data.experience,
                                area: response.data.area,
                                month: response.data.month
                            }
                        });
                },
                FETCH_AND_SET_SEARCH_DEFAULTS: function (context) {
                    if (!context.state.defaultsLoaded) {
                        return context.dispatch(window.store.FETCH_AND_RETURN_SEARCH_DEFAULTS, null, {root: true}).then(function (response) {
                            context.commit(window.store.SET_SEARCH_EXPERIENCE, response.experience, {root: true});
                            context.commit(window.store.SET_SEARCH_LOCATION, response.area, {root: true});
                            context.commit(window.store.SET_SEARCH_MONTH, response.month, {root: true});
                            context.commit(window.store.SET_SEARCH_DEFAULTS_LOADED, true, {root: true});
                        });
                    }
                    return true;
                },
                FETCH_AND_RETURN_SEARCH_CRITERIA_OPTIONS: function (context, payload) {
                    return axios.post(window.API_URL + 'search/criteria', payload).then(function (response) {
                        let months = [];

                        if (response.data.months != undefined) {
                            for (var t in response.data.months) {
                                months.push(parseInt(response.data.months[t].rating));
                            }
                        }

                        return {
                            locations: response.data.locations,
                            experiences: response.data.experience,
                            months: months
                        }
                    });
                },
                FETCH_AND_SET_SEARCH_CRITERIA_OPTIONS: function (context) {
                    let searchCriteria = window._.defaultsDeep({}, context.state.criteria.params);
                    return context.dispatch(window.store.FETCH_AND_RETURN_SEARCH_CRITERIA_OPTIONS, searchCriteria, {root: true}).then(function (response) {
                        context.commit(window.store.SET_SEARCH_CRITERIA_OPTIONS, {
                            locations: response.locations,
                            experiences: response.experiences,
                            months: response.months
                        }, {root: true});
                    });
                },
                FETCH_AND_SET_TRIPS_FOR_TRIP_FAMILY: function (context, tripFamilyId) {
                    let searchCriteria = window._.defaultsDeep({}, context.state.criteria.params);

                    searchCriteria['type'] = 'trip_sample';
                    searchCriteria['family_id'] = tripFamilyId;
                    searchCriteria['meta'] = {
                        take: 48,
                        offset: 0,
                        take_trips: 48,
                        offset_trips: 0
                    };

                    return axios.post(window.API_URL + 'search', searchCriteria).then(function (response) {
                        context.commit(window.store.SET_TRIPS_FOR_TRIP_FAMILY, {
                            ...response.data.data,
                            family_id: tripFamilyId
                        }, {root: true});
                    });
                },
                FETCH_AND_SET_SEARCH_RESULTS: function (context) {
                    let searchCriteria = window._.defaultsDeep({}, context.state.criteria.params);

                    if(searchCriteria.type === 'trip'){
                        searchCriteria['meta'] = {
                            take: 48,
                            offset: 0,
                            take_trips: 48,
                            offset_trips: 0
                        };
                    }

                    if (ObjectCache.exists(context.state.criteria.params)) {
                        context.commit(
                            window.store.SET_SEARCH_RESULTS, {
                                type: context.state.criteria.params.type,
                                data: ObjectCache.get(context.state.criteria.params)
                            },
                            {root: true}
                        );
                        return;
                    }

                    if (context.state.criteria.params.type === 'resource') {
                        let data = new AtrStorage().fetch('resource_results');
                        if(data !== false){
                            context.commit(window.store.SET_SEARCH_RESULTS, {
                                type: 'resource',
                                data: data
                            }, {root: true});
                        } else {
                            return axios.get(window.API_URL + 'search/resources')
                                .then((response) => {
                                    var resources = response.data.resources.data;
                                    context.commit(window.store.SET_SEARCH_RESULTS_COUNT, response.data.count, {root: true});
                                    context.commit(window.store.SET_SEARCH_RESULTS, {
                                        type: 'resource',
                                        data: new GlobalUtils().listToTreeResource(resources)
                                    }, {root: true});
                                    context.commit(window.store.SET_ACTIVE_ARTICLE, resources[0], {root: true});
                                })
                                .catch((err) => {

                                });
                        }
                    } else if (context.state.criteria.params.type === 'trip_collection') {
                        let data = new AtrStorage().fetch('resource_results_trip_collection');
                        if(data !== false){
                            context.commit(window.store.SET_SEARCH_RESULTS, {
                                type: 'trip_collection',
                                data: data
                            }, {root: true});
                        } else {
                            return axios.post(window.API_URL + 'search', searchCriteria)
                                .then((response) => {
                                    var resources = response.data.resources.data;
                                    context.commit(window.store.SET_SEARCH_RESULTS_COUNT, response.data.count, {root: true});
                                    context.commit(window.store.SET_SEARCH_RESULTS, {
                                        type: 'trip_collection',
                                        data: new GlobalUtils().listToTreeResource(resources)
                                    }, {root: true});
                                    context.commit(window.store.SET_ACTIVE_ARTICLE, resources[0], {root: true});
                                })
                                .catch((err) => {

                                });
                        }
                    } else if (context.state.criteria.params.type === 'article') {
                        let data = new AtrStorage().fetch('resource_results_article');
                        if(data !== false){
                            context.commit(window.store.SET_SEARCH_RESULTS, {
                                type: 'article',
                                data: data
                            }, {root: true});
                        } else {
                            return axios.post(window.API_URL + 'search', searchCriteria)
                                .then((response) => {
                                    var resources = response.data.resources.data;
                                    context.commit(window.store.SET_SEARCH_RESULTS_COUNT, response.data.count, {root: true});
                                    context.commit(window.store.SET_SEARCH_RESULTS, {
                                        type: 'article',
                                        data: new GlobalUtils().listToTreeResource(resources)
                                    }, {root: true});
                                    context.commit(window.store.SET_ACTIVE_ARTICLE, resources[0], {root: true});
                                })
                                .catch((err) => {

                                });
                        }
                    } else {
                        return axios.post(window.API_URL + 'search', searchCriteria).then(function (response) {
                            if(window.globals.oldDevice){
                                if(response.data.data.length > 10) {
                                    response.data.data = response.data.data.slice(0, 10);
                                }
                            }

                            context.commit(window.store.SET_SEARCH_RESULTS, {
                                type: response.data.type,
                                data: response.data.data
                            }, {root: true});

                            if(response.data.data) {
                                //context.commit(window.store.SET_ACTIVE_ARTICLE, response.data.data[0], {root: true});
                            }
                        });
                    }
                },
                FETCH_AND_RETURN_SLIDESHOW: function (context, payload) {
                    if (payload.id == -1) {
                        return new Promise((resolve) => {
                            return resolve({
                                data: [
                                    '/img/temp/slider-placholders/athol.jpg',
                                    '/img/temp/slider-placholders/jburg.jpg',
                                    '/img/temp/slider-placholders/sabi.jpg',
                                    '/img/temp/slider-placholders/singita.jpg'
                                ]
                            });
                        });
                    }
                    let offset = _.isUndefined(payload.offset)?0:payload.offset;
                    let limit = _.isUndefined(payload.limit)?99999:payload.limit;
                    return axios.get(window.API_URL + 'search/images/' + payload.type + '/' + payload.id + '?offset=' + offset + '&' + 'limit=' + limit);
                }
            }
        },
        globalSearch: {
            namespaced: true,
            state: {
                results: {
                    data: []
                }
            },
            mutations: {
                SET_RESULTS(state, results) {
                    state.results = results;
                },
                CLEAR_RESULTS(state) {
                    state.results.data = [];
                }
            },
            actions: {
                SET_AND_FETCH_RESULTS({commit}, searchTerm) {
                    return axios.get(window.API_URL + 'search/quicksearch?q=' + searchTerm).then((response) => {
                        //Cap search results on mobile to 20 for a speedy experience
                        if(window.pnpHelper.bootstrap.isXs() && response.data.data && response.data.data.length === 100){
                            response.data.data = response.data.data.slice(0,20);
                        }

                        commit(window.store.SET_RESULTS, response.data, {root: true});

                        return response.data.data;
                    });
                },
                CLEAR_AND_RETURN_RESULTS({commit}, searchTerm){
                    return new Promise((resolve, reject) => {
                        commit(window.store.CLEAR_RESULTS, {},  {root: true});
                        resolve([]);
                    });
                }
            }
        },
        directorySearch: {
            namespaced: true,
            state: {
                results: {
                    data: []
                }
            },
            mutations: {
                SET_DIRECTORY_SEARCH_RESULTS(state, results) {
                    state.results = results;
                },
                CLEAR_DIRECTORY_SEARCH_RESULTS(state) {
                    state.results.data = [];
                }
            },
            actions: {
                SET_AND_FETCH_DIRECTORY_SEARCH_RESULTS({commit}, args) {
                    return axios.get(window.API_URL + 'search/directorysearch', {
                        params: {
                            q: args.searchTerm,
                            restrict_to_type: args.restrictToType
                        }
                    }).then((response) => {
                        //Cap search results on mobile to 20 for a speedy experience
                        if(window.pnpHelper.bootstrap.isXs() && response.data.data && response.data.data.length === 100){
                            response.data.data = response.data.data.slice(0,20);
                        }

                        commit(window.store.SET_RESULTS, response.data, {root: true});

                        return response.data.data;
                    });
                },
                CLEAR_AND_RETURN_DIRECTORY_SEARCH_RESULTS({commit}, searchTerm){
                    return new Promise((resolve, reject) => {
                        commit(window.store.CLEAR_RESULTS, {},  {root: true});
                        resolve([]);
                    });
                }
            }
        },
        startPage: {
            namespaced: true,
            state: {
                articles: [],
                experts: [],
                activeExpert: null
            },
            mutations: {
                SET_START_ARTICLES: function (state, payload) {
                    new AtrStorage().store('start_articles', payload, GlobalVars.TWELVE_HOURS);

                    state.articles = payload;
                },
                SET_EXPERTS: function (state, payload) {
                    new AtrStorage().store('experts', payload, GlobalVars.TWELVE_HOURS);

                    state.experts = payload
                },
                SET_ACTIVE_EXPERT: function (state, payload) {
                    state.activeExpert = payload
                }
            },
            actions: {
                FETCH_AND_SET_START_ARTICLES({commit}, articlesArr){

                    let data = new AtrStorage().fetch('start_articles');

                    if(data !== false) {

                        return new Promise((resolve, reject) => {
                            commit(window.store.SET_START_ARTICLES, data, { root: true });

                            resolve(articlesArr);
                        });

                    }else {

                        let apiArray, articlesIdArr = [];

                        articlesIdArr = articlesArr.filter((el) => {
                            return !el.hasOwnProperty('is_static');
                        });

                        return axios.post(window.API_URL + 'batch', {
                            "actions": [
                                "items"
                            ],
                            "items": articlesIdArr
                        }).then((res) => {
                            apiArray = res.data.data[0].items.map((el, index) => {
                                let articles = {
                                    ...el.data,
                                    ...articlesIdArr[index],
                                    image: el.data.images.data.sizes.extra_large,
                                    content: el.data.images.data.caption,
                                    vue_route_params: {
                                        name: 'single-resource',
                                        params: {
                                            slug: el.data.vue_route_params.data.slug
                                        }
                                    }
                                };

                                articles.images.data.sizes.extralarge = el.data.images.data.sizes.extra_large;

                                return articles;
                            });

                            articlesArr = articlesArr.map((el, index) => {
                                if (!el.hasOwnProperty('is_static')) {
                                    return {...apiArray.filter((item) => item.id === el.id)[0]};
                                } else {
                                    return el;
                                }
                            }).sort((a, b) => {
                                return a.index - b.index;
                            });

                            return new Promise((resolve, reject) => {
                                commit(window.store.SET_START_ARTICLES, articlesArr, {root: true});

                                resolve(articlesArr);
                            });
                        });
                    }
                },
                FETCH_AND_SET_EXPERTS({commit}, id) {
                    let data = new AtrStorage().fetch('experts');

                    if(data !== false) {

                        return new Promise((resolve, reject) => {
                            let randExpertIndex = Math.floor(Math.random() * (data.length - 1));

                            commit(window.store.SET_EXPERTS, data, { root: true });
                            commit(window.store.SET_ACTIVE_EXPERT, data[randExpertIndex], {root: true});

                            resolve();
                        });

                    }else {

                        return axios.post(window.API_URL + 'batch', {
                            "actions": [
                                "salesreps"
                            ]
                        }).then((res) => {

                            let experts = res.data.data[0].salesreps.data;

                            let randExpertIndex = Math.floor(Math.random() * (experts.length - 1));

                            //Store the experts in a consistent object to that of articles
                            for (var i in experts) {
                                experts[i].image = experts[i].images.data.sizes.extralarge;

                                experts[i].vue_route_params = {
                                    name: "single-resource",
                                    params: {
                                        slug: experts[i].vue_route_params.data.slug
                                    }
                                }
                            }

                            commit(window.store.SET_EXPERTS, experts, {root: true});

                            //Choose an expert at random and set them as the active expert
                            commit(window.store.SET_ACTIVE_EXPERT, experts[randExpertIndex], {root: true});

                            return experts;
                        });
                    }
                }
            }
        },
        contactInfo: {
            namespaced: true,
            state: {
                personal: {
                    firstName: '',
                    familyName: '',
                    email: '',
                    location: '',
                    comments: '',
                },
                // account: {
                //     password: '',
                //     confirmPassword: '',
                //     subscribe: false
                // },
                call: {
                    time: '',
                    timeZone: '',
                    number: '',
                    backUpNumber: ''
                },
                quote: {
                    itinerary: '',
                    wishlist: []
                    // safariSub: false,
                    // customer: false
                },
                // inquiry: {
                //     firstTime: false,
                //     firstTimeComments: '',
                //     privateGroup: false,
                //     privateGroupComments: '',
                //     numberPeople: '1-4',
                //     reason: '',
                //     tripLength: '',
                //     priceRange: '',
                //     accommodation: []
                // },
                email: {
                    content: ''
                }
            },
            mutations: {
                SET_STATE(state, obj) {
                    let keys = Object.keys(obj);

                    state[keys] = Object.assign({}, {...state[keys]}, obj[keys]);
                },
                ADD_WISHLIST_TO_QUOTE(state, payload){
                    state.quote.wishlist.push(payload);
                },
                REMOVE_WISHLIST_FROM_QUOTE(state, payload){
                    state.quote.wishlist = state.quote.wishlist.filter((el) => {
                        return el.id != payload.id;
                    })
                }
            },
            actions: {
                SEND_EMAIL: function (context) {
                    let payload = {
                        content: context.state.personal.email + "\n\n" + context.state.email.content,
                        googleTracking: new AtrStorage().fetch('google_tracking')
                    };

                    return axios.post(window.API_URL + 'contact/email', payload);
                },
                SEND_CALLBACK_REQUEST: function (context) {
                    let allowedFormData = [
                        'time', 'number', 'timeZone', 'backUpNumber',
                        'firstName', 'familyName', 'email', 'location',
                        'comments'
                    ];

                    let payload = Object.assign(
                        {},
                        { call: _.pick(context.state.call, allowedFormData) },
                        { personal: _.pick(context.state.personal, allowedFormData) },
                        { googleTracking: new AtrStorage().fetch('google_tracking') }
                    );

                    return axios.post(window.API_URL + 'contact/callback', payload);
                },
                SEND_QUOTE_REQUEST: function (context, payload) {
                    let allowedFormData = [
                        'firstName', 'familyName', 'email', 'location',
                        'number', 'backUpNumber', 'timeZone', 'itinerary'
                    ];

                    let newPayload = Object.assign(
                        {},
                        context.state.quote,
                        { personal: _.pick(context.state.personal, allowedFormData) },
                        { call: _.pick(context.state.call, allowedFormData) },
                        { wishlist: [...payload] },
                        { googleTracking: new AtrStorage().fetch('google_tracking') }
                    );

                    return axios.post(window.API_URL + 'contact/inquiry', newPayload);
                }
            }
        },
        directory: {
            namespaced: true,
            state: {
                directory: {},
                lodgeCount: 0,
                openNodeId: null,
                locationCount: 0
            },
            mutations: {
                SET_DIRECTORY: function (state, payload) {
                    // var p = window._.defaultsDeep({}, payload);
                    // new AtrStorage().store('directory', p);
                    state.directory = window._.defaultsDeep({}, payload.directory);
                },
                SET_SELECTED_DIRECTORY_ITEM: function (state, payload) {
                    state.directory = window._.defaultsDeep({}, payload);
                },
                SET_LODGE_COUNT: function(state, payload) {
                    state.lodgeCount = payload;
                },
                SET_OPEN_NODE_ID: function(state, payload) {
                    state.openNodeId = payload;
                },
                SET_LOCATION_COUNT: function(state, payload) {
                    state.locationCount = payload;
                },
            },
            actions: {
                FETCH_AND_SET_DIRECTORY: function (context) {
                    if(!context.state.directory[0]){
                        return axios.get(window.API_URL + 'search/directory').then(response => {
                            let lodgeCount = 0;
                            let locationCount = 0;

                            response.data.data.map((el) => {
                                if(el.type == 13) { lodgeCount++}
                                if(el.type == 7) { locationCount++}
                                return el.type;
                            });


                            function listToTree(list) {
                                let map = {}, node, roots = [], i;

                                for (i = 0; i < list.length; i++) {
                                    map[list[i].id] = i;
                                    list[i].children = [];
                                    list[i].isOpen = false;

                                    if(list[i].rating === null){
                                        list[i].rating = "N/A";
                                    }

                                    if(list[i].price === null){
                                        list[i].price = "N/A";
                                    }
                                }

                                for (i = 0; i < list.length; i++) {
                                    node = list[i];

                                    if (node.parent_id != undefined && node.parent_id != 0 && map[node.parent_id]!=undefined) {
                                        list[map[node.parent_id]].children.push(node);
                                    } else {
                                        roots.push(node);
                                    }
                                }

                                return roots;
                            }

                            let newDirectory = {
                                locationCount: locationCount,
                                lodgeCount: lodgeCount,
                                directory: listToTree(response.data.data)
                            }

                            context.commit(window.store.SET_LOCATION_COUNT, locationCount, { root: true });
                            context.commit(window.store.SET_LODGE_COUNT, lodgeCount, { root: true });
                            context.commit(window.store.SET_DIRECTORY, newDirectory, { root: true });
                        });
                    }
                }
            }
        },
        navigation: {
            namespaced: true,
            state: {
                subheading: false
            },
            mutations: {
                SET_SUBHEADING(state, payload) {
                    state.subheading = payload;
                }
            }
        },
        user: {
            namespaced: true,
            state: {
                user: {
                    can_edit: false,
                    selected_currency: 'USD',
                    hasOpenedNav: false,
                    hasSeenSentence: false,
                }
            },
            mutations: {
                SET_USER(state, payload) {
                    new AtrStorage().store('meet_the_team_id', Math.floor(Math.random()*(19-7+1)+7), GlobalVars.TWELVE_HOURS);
                    new AtrStorage().store('user', payload, GlobalVars.TWELVE_HOURS);

                    state.user = {...state.user, ...payload};
                },
                SET_CURRENCY(state, payload) {
                    new AtrStorage().store('selected_currency', payload, GlobalVars.F0REVER);
                    state.user.selected_currency = payload;
                },
                SET_HAS_SEEN_SENTENCE(state, payload){
                    new AtrStorage().store('has_seen_sentence', payload, GlobalVars.F0REVER);
                    state.user.hasSeenSentence = payload;
                },
                SET_HAS_OPENED_NAV(state, payload){
                    new AtrStorage().store('has_opened_nav', payload, GlobalVars.F0REVER);
                    state.user.hasOpenedNav = payload;
                }
            },
            actions: {
                FETCH_HAS_OPENED_NAV(context){
                    let hasOpenedNav = new AtrStorage().fetch('has_opened_nav');

                    if(hasOpenedNav === undefined){
                        context.commit(window.store.SET_HAS_OPENED_NAV, false, {root: true});
                    }else{
                        context.commit(window.store.SET_HAS_OPENED_NAV, hasOpenedNav, {root: true});
                    }
                },
                FETCH_HAS_SEEN_SENTENCE(context){
                    let hasSeenSentence = new AtrStorage().fetch('has_seen_sentence');

                    if(hasSeenSentence === undefined){
                        context.commit(window.store.SET_HAS_SEEN_SENTENCE, false, {root: true});
                    }else{
                        context.commit(window.store.SET_HAS_SEEN_SENTENCE, hasSeenSentence, {root: true});
                    }
                },
                FETCH_AND_SET_USER(context, user) {
                    let url = window.API_URL + 'user';

                    let selectedCurrency = new AtrStorage().fetch('selected_currency');

                    if(selectedCurrency !== false){
                        context.commit(window.store.SET_CURRENCY, selectedCurrency, {root: true});
                    }

                    if(!user.user || !user.user.rates) {
                        return axios.get(url).then((response) => {
                            let rates = {};

                            response.data.rates = response.data.rates.filter((el) => {
                                return el.code !== 'EUR1'
                            });

                            response.data.rates.map((el, index) => {
                                rates[el.code] = el.rate;
                            });

                            response.data.rates = rates;

                            if(response.data.geo_data && response.data.geo_data.currency){
                                response.data.default_currency = response.data.geo_data.currency;
                            }

                            context.commit(window.store.SET_USER, response.data, {root: true});

                            if(selectedCurrency === false){
                                context.commit(window.store.SET_CURRENCY, response.data.default_currency, {root: true});
                            }
                        });
                    } else {
                        return;
                    }
                }
            }
        },
        seo: {
            namespaced: true,
            state: {
                pageTitle: ''
            },
            mutations: {
                UPDATE_SEO_VALUES(state, payload) {
                    let pageTitle = payload['page_title'] != null ? payload['page_title'] : router.currentRoute.meta.page_title;
                    let metaDescription = payload['meta_description'] != null ? payload['meta_description'] : router.currentRoute.meta.meta_description;
                    let canonicalUrl = payload['canonical_url'] != null ? payload['canonical_url'] : '/';

                    this.commit(window.store.SET_CANONICAL_URL, canonicalUrl);

                    this.commit(window.store.SET_PAGE_TITLE, SeoManager.findReplaceVariables(
                        payload['variable_dictionary'],
                        pageTitle
                    ));

                    this.commit(window.store.SET_META_DESCRIPTION, SeoManager.findReplaceVariables(
                        payload['variable_dictionary'],
                        metaDescription
                    ));

                    this.commit(window.store.UPDATE_JSON_SCHEMA, payload['schema_data']);
                },
                SET_PAGE_TITLE(state, payload) {
                    SeoManager.pageTitle = payload;
                    state.pageTitle = payload;
                },
                SET_META_DESCRIPTION(state, payload) {
                    SeoManager.metaDescription = payload;
                },
                SET_CANONICAL_URL(state, payload) {
                    SeoManager.canonicalUrl = payload;
                },
                UPDATE_JSON_SCHEMA(state, payload) {
                    SeoManager.removeJsonSchema('single');
                    SeoManager.addJsonSchema(payload, 'single');
                },
            }
        },
        wishlist: {
            namespaced: true,
            state: {
                selected: [],
                type: ''
            },
            mutations: {
                SET_WISHLIST: function (state, payload) {
                    new AtrStorage().store('wishlist',  payload, 150000000);

                    state.selected = payload;
                },
                SET_WISHLIST_ITEM: function(state, payload){
                    let localStorageWishlist = new AtrStorage().fetch('wishlist') || [];

                    if (payload.vue_route_params) {
                        let data = _.isUndefined(payload.vue_route_params['data']) ? payload.vue_route_params : payload.vue_route_params.data;

                        window.GoogleAnalytics.fireEvent(
                            'Add ' + window.GoogleAnalytics.parseVueRouteParams(data),
                            'Add to Wishlist',
                            router.history.current.path
                        );
                    }

                    state.selected.push(payload);

                    localStorageWishlist.push(payload);

                    new AtrStorage().store('wishlist', localStorageWishlist);
                },
                REMOVE_WISHLIST_ITEM: function(state, payload){
                    state.selected = state.selected.filter((item) => {
                        return item.id != payload.id;
                    });

                    new AtrStorage().store('wishlist', [...state.selected]);
                },
                FETCH_AND_SET_WISHLIST: function (state, payload) {
                    let data = new AtrStorage().fetch('wishlist');

                    if(data !== false) {
                        state.selected = data;
                    } else {
                        let s = window._.defaultsDeep([], state.selected);
                        new AtrStorage().store('wishlist', s);
                    }
                },
                SET_WISHLIST_TYPE: function(state, payload){
                    state.type = payload;
                }
            },
            actions: {
                SET_WISHLIST_ITEM_BY_ID: function (context, payload) {
                    if(payload.type === 'lodge'){
                        let data = new AtrStorage().fetch('lodge_' + payload.id);

                        if (data !== false) {
                            context.commit(window.store.SET_WISHLIST_ITEM, {...data, type: payload.type}, {root: true});
                        } else {
                            return axios.post(window.API_URL + 'batch', {
                                "actions": [
                                    "wishlist"
                                ],
                                "items": [
                                    {
                                        "type": GlobalVars.LODGE,
                                        "id": payload.id
                                    }
                                ]
                            }).then((res) => {
                                context.commit(window.store.SET_WISHLIST_ITEM, {...res.data.data[0].wishlist[0].data, type: payload.type}, {root: true});
                            });
                        }
                    } else if (payload.type === 'location') {
                        let data = new AtrStorage().fetch('location_' + payload.id);

                        if (data !== false) {
                            context.commit(window.store.SET_WISHLIST_ITEM, {...data, type: payload.type}, {root: true});
                        } else {
                            return axios.post(window.API_URL + 'batch', {
                                "actions": [
                                    "wishlist"
                                ],
                                "items": [
                                    {
                                        "type": GlobalVars.LOCATION,
                                        "id": payload.id
                                    }
                                ]
                            }).then((res) => {
                                context.commit(window.store.SET_WISHLIST_ITEM, {...res.data.data[0].wishlist[0].data, type: payload.type}, {root: true});
                            });
                        }
                    } else if (payload.type === 'experience') {
                        return axios.post(window.API_URL + 'batch', {
                            "actions": [
                                "wishlist"
                            ],
                            "items": [
                                {
                                    "type": GlobalVars.EXPERIENCE,
                                    "id": payload.id
                                }
                            ]
                        }).then((res) => {
                            context.commit(window.store.SET_WISHLIST_ITEM, {...res.data.data[0].wishlist[0].data, type: payload.type}, {root: true});
                        });
                    } else if (payload.type === 'trip') {
                        return axios.post(window.API_URL + 'batch', {
                            "actions": [
                                "wishlist"
                            ],
                            "items": [
                                {
                                    "type": GlobalVars.TRIP,
                                    "id": payload.id
                                }
                            ]
                        }).then((res) => {
                            context.commit(window.store.SET_WISHLIST_ITEM, {
                                ...res.data.data[0].wishlist[0].data,
                                type: payload.type,
                                geographical_summary: res.data.data[0].wishlist[0].data.geographical_summary.data,
                                vue_route_params: {
                                    slug: res.data.data[0].wishlist[0].data.vue_route_params.data.slug,
                                }
                            }, {root: true});
                        });
                    }
                }
            }
        }
    }
});

export default store;
