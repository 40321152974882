<script>
    import StateSentence from '../presentational/search/Sentence.vue';

    export default {
        props: {
            shouldSearch: {
                default: true,
                type: Boolean
            },
            sentenceType:{
                default: 'lodge',
                type: String
            },
            lockType:{
                default: false,
                type: Boolean
            },
            portalTo: {
                default: 'sentencePopups',
                type: String
            },
            sentenceId: {
                default: '',
                type: String
            }
        },
        render(createElement) {
            var props = {};

            props.shouldSearch = this.shouldSearch;
            props.sentenceType = this.sentenceType;
            props.lockType = this.lockType;
            props.portalTo = this.portalTo;
            props.sentenceId = this.sentenceId;
            if(props.shouldSearch){
                props.searchState = this.$store.state.search;
            } else {
                props.searchState =  {
                    criteria: {
                        options: {
                            locations: [],
                            experiences: [],
                            months: []
                        },
                        params: {
                            type: this.sentenceType,
                            experience: null,
                            experiences: [],
                            location: null,
                            locations: [],
                            month: null,
                            duration:12,
                            budgetFrom: 0,
                            budgetTo: 5000,
                            tripBudgetFrom: 0,
                            tripBudgetTo: 10000,
                            sortOrder: {
                                order: null,
                                label: null
                            }
                        }
                    },
                    results: {
                        count: null,
                        type: null,
                        data: []
                    },
                    selectedResult: {},
                    defaultsLoaded: false
                };
            }

            return createElement(StateSentence, {props: props}, this.$slots.default);
        },
    }
</script>