import LazyLoader from "./LazyLoader";

export default class GlobalUtils {
    listToTreeResource(list) {
        var images = [];
        var map = {}, node, tree = [], i;

        for (i = 0; i < list.length; i++) {
            map[list[i].id] = i;
            list[i].children = [];
            list[i].isOpen = false;
        }

        for (i = 0; i < list.length; i++) {
            node = list[i];

            if (node.parent_id != undefined && node.parent_id != 0 && map[node.parent_id] != undefined) {
                images.push(node.image);
                list[map[node.parent_id]].children.push(node);
            } else {
                images.push(node.image);
                tree.push(node);
            }
        }

        let lazyLoader = new LazyLoader();
        lazyLoader.loadImages(images);
        return tree;
    }
}
