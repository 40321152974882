<template>
    <div class="pnp-popover srch-panel -range vh-fix-xs" :id="'budget-range-panel' + this.sentenceId" v-observe-visibility="visibilityChanged">
        <mob-popup-header text="SELECT PRICE RANGE"></mob-popup-header>

        <div class="range-header inner-panel">
            <h2 class="panel-header heading2">
                Guide Price
            </h2>

            <p v-if="searchType !== 'trip'" class="panel-body">An estimation of the total cost per person per night for accommodation, the usual meal, drink and activity inclusions, plus expected transport costs and park fees.</p>
            <p v-else class="panel-body">An estimation of the total cost per person for a trip, including the cost of accommodation, the usual meals, drinks and activities, transport costs and park fees. But excluding long-haul flights, visas, tips, insurance and optional extras.</p>
        </div>

        <hr>

        <div class="inner-panel">
            <currency-selector></currency-selector>

            <span class="budget-format-value">
                <h2 class="panel-header heading2">{{ formattedValue }}</h2>

                <!--<p class="sub-text">USD <i class="ion-android-arrow-dropdown"></i></p>-->
            </span>

            <p class="sub-text" v-if="searchType === 'trip'">Per Person</p>
            <p class="sub-text" v-else>Per Person Per Night</p>
        </div>

        <div class="range-slider inner-panel">
            <!--<div class="results-range">-->
                <!--<div class="bar" :style="`height: ${item}%`" v-for="item in getSearchValues"></div>-->
            <!--</div>-->

            <vue-slider
                    ref="slider"
                    v-model="value"
                    v-bind="options"
                    v-if="showSlider"
            ></vue-slider>
        </div>

        <div class="budget-button-group inner-panel">
            <button class="btn -plain" @click="closeModal">Cancel</button>

            <button @click="apply" class="btn -main-cta -small -ripple apply-btn apply">
                <span>
                    <span>Apply</span> <i class="ion-ios-arrow-thin-right"></i>
                </span>
            </button>
        </div>
    </div>
</template>
<script>
    import UrlMap from '../../../global/UrlMap';
    import router from '../../../router';
    import vueSlider from 'vue-slider-component';
    import { mapState } from 'vuex';

    export default {
        components: {
            vueSlider
        },
        props: {
            params: {
                type: Object,
            },
            shouldSearch: {
                default: true,
                type: Boolean
            },
            sentenceId: {
                default: '',
                type: String
            }
        },
        data() {
            return {
                value: this.searchType === 'trip' ? [0, 10000] : [0, 5000],
                showSlider: false,
                valuesLength: 0
            }
        },
        methods: {
            visibilityChanged(isVisible){
                if(isVisible) {
                    this.showSlider = true;
                } else {
                    this.showSlider = false;
                }
            },
            setValues(value) {
                if(this.searchType === 'trip'){
                    this.$store.commit(window.store.SET_SEARCH_TRIP_BUDGET_RANGE, value);

                }else{
                    this.$store.commit(window.store.SET_SEARCH_BUDGET_RANGE, value);

                }
            },
            apply(){
                let budgetFrom = this.divideValue(this.value[0]);
                let budgetTo = this.divideValue(this.value[1]);

                if(this.shouldSearch) {
                    this.setValues([budgetFrom, budgetTo]);

                    let queryParams;

                    if(this.searchType === 'trip'){
                        queryParams = window._.defaultsDeep({
                            tripbfrom: budgetFrom,
                            tripbto: budgetTo
                        }, new UrlMap().searchStateToUrlParams(this.$store.state.search));

                    }else{
                        queryParams = window._.defaultsDeep({
                            bfrom: budgetFrom,
                            bto: budgetTo
                        }, new UrlMap().searchStateToUrlParams(this.$store.state.search));
                    }

                    router.push({name: 'finder', query: queryParams});

                    window.bus.$emit('searchDoSearch');
                }

                if(this.searchType === 'trip') {
                    if(budgetFrom == 0 && budgetTo == 10000){
                        window.bus.$emit('searchSentenceDirtyUpdate', {
                            isDirty: false,
                            key: 'range_trip'
                        });
                    }else{
                        window.bus.$emit('searchSentenceDirtyUpdate', {
                            isDirty: true,
                            key: 'range_trip'
                        });
                    }
                }else{
                    if(budgetFrom == 0 && budgetTo == 5000){
                        window.bus.$emit('searchSentenceDirtyUpdate', {
                            isDirty: false,
                            key: 'range'
                        });
                    }else{
                        window.bus.$emit('searchSentenceDirtyUpdate', {
                            isDirty: true,
                            key: 'range'
                        });
                    }
                }

                this.closeModal();
            },
            closeModal(){
                // window.pnpHelper.popover.close(el);

                let el = $('#budget-range-panel' + this.sentenceId);
                el.removeClass('open-facet-panel');
                window.pnpHelper.veil.hide();
                el.fadeOut(150);
                pnpHelper.scroll.unlockIos();

            },
            valCalc(val){
                let rate = this.rates[this.selected] || 1;



                return Math.round(item * rate);
            },
            divideValue(val){
                let rate = this.rates[this.selected] || 1;

                return Math.round(val / rate);
            }
        },
        computed: {
            values(){
                let valArr = [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1250, 1500, 1750, 2000, 2500, 3000, 4000, 5000];

                if(this.searchType === 'trip'){
                    return valArr.map((el) => el * 10);
                }

                return valArr;
            },
            options() {
                return {
                    sliderStyle: {'backgroundColor': '#fff','border': '1px #F7BE16 solid', 'box-shadow': 'rgba(0, 0, 0, 0.22) 0.5px 0.5px 7px 0px'},
                    processStyle: {'backgroundColor': '#F7BE16', 'border-radius': '5px'},
                    bgStyle: {'backgroundColor': '#CFCFCF'},
                    dotSize: 40,
                    tooltip: false,
                    data: this.computedRates
                }
            },
            formattedValue(){
                let value1 = this.value[0];
                let value2 = this.value[1];

                return `${value1.toLocaleString()} -  ${value2.toLocaleString()}`;
            },
            computedRates(){
                if(this.rates === false){
                    return;
                }

                let values = this.values;

                return values.map((item) => {
                    let rate = this.rates[this.selected] || 1;

                    // if(this.searchType === 'trip') {
                    //     return Math.round(item * rate) * 10000;
                    // }

                    return Math.round(item * rate);
                });
            },
            getSearchValues(){
                // let rangeObject = {};
                // let valueArray = [];
                // let newValues = [];
                //
                // this.values.forEach((el, index) => {
                //     //Create value array with in between values
                //     newValues.push(el);
                //
                //     // calc the diff between numbers
                //     let numDiff = el > 0 ? el - ((el - this.values[index - 1]) / 2) : 0;
                //
                //     if(newValues.includes(numDiff) === false){
                //         newValues.push(numDiff);
                //     }
                // });
                //
                // newValues.sort((a, b ) => a - b);
                //
                // let prices = this.results.map((el) => {
                //     return parseInt(el.price.replace(/,/g, ""));
                // }).sort((a, b) => a - b);
                //
                // prices.forEach((el) => {
                //     // Add value to key on object to create array in range
                //     newValues.forEach((val, index) => {
                //         if(el >= val && el < newValues[index + 1]) {
                //             let range = rangeObject[val] ? rangeObject[val] : [];
                //
                //             rangeObject[val] = [...range, el];
                //         }
                //     });
                // });
                //
                // // Update rangeObject to store length of arrays
                // for(let keys in rangeObject){
                //     rangeObject[keys] = rangeObject[keys].length
                // }
                //
                // newValues.forEach((val) => {
                //     //Check if value in range matches a value in teh range object
                //     if(rangeObject.hasOwnProperty(val)) {
                //         valueArray.push(rangeObject[val]);
                //     } else {
                //         valueArray.push(0);
                //     }
                // });
                //
                // // Set relative 100% to length of longest array
                // let max = Math.max(...valueArray);
                //
                // valueArray = valueArray.map((val) => {
                //     // Convert value into percentage
                //     if(val > 0){
                //
                //         // val += 10;
                //
                //         return Math.round((val / max) * 100);
                //     } else {
                //         return 0;
                //     }
                // });
                //
                // return valueArray;

                return [];
            },
            ...mapState({
                rates: state => state.user.user.rates,
                selected: state => state.user.user.selected_currency,
                budgetFrom: state => state.search.criteria.params.budgetFrom,
                budgetTo: state => state.search.criteria.params.budgetTo,
                tripBudgetFrom: state => state.search.criteria.params.tripBudgetFrom,
                tripBudgetTo: state => state.search.criteria.params.tripBudgetTo,
                results: state => state.search.results.data,
                searchType: state => state.search.criteria.params.type
            })
        },
        mounted(){
            if(this.rates === false){
                if(this.searchType === 'trip') {
                    this.value = [this.valCalc(this.tripBudgetFrom), this.valCalc(this.tripBudgetTo)];
                }else{
                    this.value = [this.valCalc(this.budgetFrom), this.valCalc(this.budgetTo)];
                }

                this.valuesLength = this.computedRates.length;
            }
        },
        watch: {
            showSlider(value){
                if (value) {
                    // this.$nextTick(() => this.$refs.slider.refresh());
                }

                let rate = this.rates[this.selected] || 1;
                let bfrom, bto;

                if(this.searchType === 'trip') {
                    bfrom = Math.round(this.tripBudgetFrom * rate);
                    bto = Math.round(this.tripBudgetTo * rate);
                }else {
                    bfrom = Math.round(this.budgetFrom * rate);
                    bto = Math.round(this.budgetTo * rate);
                }

                this.value = [bfrom, bto];
            },
            value(value){
                // this.setValues(value);
            },
            selected(){
                this.value = [this.value[0], this.value[1]];
            }
        }
    }
</script>