<template>
    <div style="margin-top:18px;">
        <div class="messages-container">
            <div class="errors-container" v-if="error">
                <p>Please select a duration.</p>
            </div>
        </div>
        <ul class="buttons-list -duration">
            <li v-for="(duration, index) in durations" :key="index">
                <input type="radio" :id="'duration-'+index" name="proposed_trip_duration" :value="duration.value" @change="setValue" v-model="selectedValue">
                <label :for="'duration-'+index">
                    {{ duration.value }}
                </label>
            </li>
        </ul>
        <slot name="additionalText" />
    </div>
</template>


<script>

    export default {
        props: ['formContent', 'checkValidation', 'stepIndex', 'step'],
        data() {
            return {
                selectedValue: null,
                durations: null,
                error: false,
            }
        },
        mounted() {
            this.getDurations();
            this.selectedValue = this.formContent.proposed_trip_duration;

            if(this.stepIndex === this.step.index && typeof this.formContent.proposed_trip_duration !== "undefined") {
                this.validateStep();
            }
        },
        watch: {
            checkValidation: function(e){
                if(e) {
                    this.validateStep();
                }
                
            },
        },
        methods:{
            getDurations() {
                let durations = window.localStorage.getItem('hs_durations');
   
                if(typeof durations === 'undefined' || durations === null || durations === '') {
                    
                    let promise = axios.get(`${process.env.VUE_APP_HUBSPOT_API_URL}property/proposed_trip_duration`);

                    promise.then((response) => {
                        const data = JSON.stringify(response.data.options);
                        durations = data;
                        window.localStorage.setItem('hs_durations', data)
                    }).catch((error) => {
                        console.log(error);
                    });

                }

                this.durations = JSON.parse(durations);
            },
            setValue(e){
                if(typeof e === 'undefined' || e.length === 0 || e === null){
                    this.$emit('update-data', {key: 'proposed_trip_duration', value: null});
                    return;
                }
                this.validateStep();
                this.$emit('update-data', {key: 'proposed_trip_duration', value: this.selectedValue});

            },
            validateStep(){
                if(typeof this.selectedValue === "undefined" || this.selectedValue === null || this.selectedValue.length === 0){
                    this.error = true;

                    this.$emit('update-validation', {step: this.step.index, value: false});
                } else {
                    this.error = false;

                    this.$emit('update-validation', {step: this.step.index, value: true});
                    
                }
            }
        }
    }

</script>