<template>
    <button-internal-presentational v-on:btn-internal-clicked="setTripsSearch()"
                                    classes="smart-wishlist">

        <span class="link">Best trips to Africa</span>

    </button-internal-presentational>
</template>

<script>
    export default {
        methods: {
            setTripsSearch(){
                this.$store.commit(window.store.SET_SEARCH_TYPE_URL, 'trip_collection');

                setTimeout(() => {
                    window.bus.$emit("searchRefresh");

                    $('html,body').animate({
                        scrollTop: 0
                    }, 500);

                }, 100);
            }
        }
    }
</script>

<style scoped>

</style>
