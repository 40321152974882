<template>
    <div style="margin-top:18px;">
        <div class="messages-container">
            <div class="errors-container" v-if="error">
                <p>Please select at least one location.</p>
            </div>
        </div>

        <div class="form-group relative">
        <v-select multiple 
                  :options="destinations" 
                  placeholder="" 
                  v-model="selectedValue"
                  :required="!selectedValue" 
                  @input="setValue"></v-select>

        <span class="country-select-placeholder">Add a destination</span>
        </div>
        <slot name="additionalText" />
    </div>
</template>

<style>

.v-select.searching{
    padding: 0 !important;
}
.v-select .dropdown-toggle{
    height: 52px;
    border-color: #272727;
}

.v-select .dropdown-menu{
    max-height: 200px !important;
}

.v-select .dropdown-toggle .vs__selected-options{
    bottom: -60px;
}
.v-select .dropdown-toggle .vs__selected-options input[type="search"]{
    position: absolute;
    top: -60px;
    height: 52px;
    width: 100%;
    margin-top:0;
}
.v-select .dropdown-toggle .vs__selected-options input[type="search"]::placeholder{
    color: #7f7f7f;
}
.v-select .dropdown-toggle .vs__selected-options .selected-tag{
    padding:0 10px 0 20px;
    background-color: #F7BE16;
    color: white;
    border: none;
}
.v-select .dropdown-toggle .vs__selected-options .selected-tag button{
    color: white;
    margin-left: 15px;
    padding-top:2px;
    opacity: 1;
}

.v-select .dropdown-toggle .vs__actions{
    padding-right:15px;
}

.v-select .dropdown-toggle .vs__actions .open-indicator:before{
    display:none;
}

.v-select .dropdown-toggle .vs__actions .open-indicator{
    width:24px;
    padding-top:4px;
}

.v-select .dropdown-toggle .vs__actions .open-indicator:after{
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%237f7f7f' d='M443.5 420.2L336.7 312.4c20.9-26.2 33.5-59.4 33.5-95.5 0-84.5-68.5-153-153.1-153S64 132.5 64 217s68.5 153 153.1 153c36.6 0 70.1-12.8 96.5-34.2l106.1 107.1c3.2 3.4 7.6 5.1 11.9 5.1 4.1 0 8.2-1.5 11.3-4.5 6.6-6.3 6.8-16.7.6-23.3zm-226.4-83.1c-32.1 0-62.3-12.5-85-35.2-22.7-22.7-35.2-52.9-35.2-84.9 0-32.1 12.5-62.3 35.2-84.9 22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2c22.7 22.7 35.2 52.9 35.2 84.9 0 32.1-12.5 62.3-35.2 84.9-22.7 22.7-52.9 35.2-85 35.2z'/%3E%3C/svg%3E");
    width: 24px;
    height: 24px;
}

.v-select .dropdown-menu li{
    padding-left:0;
}

.v-select .dropdown-menu a:hover, .v-select .dropdown-menu li.highlight a{
    background-color: #535353;
}

.v-select .dropdown-menu li a{
    padding-left:10px;
}

</style>

<script>

    export default {
        props: ['formContent', 'checkValidation', 'stepIndex', 'step'],
        data() {
            return {
                destinations: null,
                selectedValue: null,
                error: false,
            }
        },
        mounted() {
            setTimeout(() => {
                this.getDestinations();
            }, 1000);
            
            this.selectedValue = this.formContent.destinations_of_interest;

            if(this.stepIndex === this.step.index && typeof this.formContent.destinations_of_interest !== "undefined") {
                this.validateStep();
            }
            
            window.addEventListener('validateStep', e => {
                if(e.detail !== this.step.index) {
                    return;
                }
                this.validateStep();
            })
        },
        watch: {
            checkValidation: function(e){
                if(e) {
                    this.validateStep();
                }
                
            },
            selectedValue: function(e){
                this.getDestinations();
            }
        },
        methods: {
            getDestinations() {
                let destinations = window.localStorage.getItem('hs_destinations');
   
                if(typeof destinations === 'undefined' || destinations === null || destinations === '') {
                    
                    let promise = axios.get(`${process.env.VUE_APP_HUBSPOT_API_URL}property/destinations_of_interest`);

                    promise.then((response) => {
                        const data = JSON.stringify(response.data.options);
                        destinations = data;
                        window.localStorage.setItem('hs_destinations', data)
                        return destinations;
                    }).then(() => {
                        this.destinations = JSON.parse(destinations).filter(destination => {
                            return !this.selectedValue || !this.selectedValue.includes(destination.value);
                        });
                        
                    }).catch((error) => {
                        console.log(error);
                    });

                } else {
                    this.destinations = JSON.parse(destinations).filter(destination => {
                        return !this.selectedValue || !this.selectedValue.includes(destination.value);
                    });
                }

            },
            setValue(e){
                
                if(e.length === 0){
                    this.$emit('update-data', {key: 'destinations_of_interest', value: null});
                    this.validateStep(false);
                } else {
                    let destinations = [];

                    e.forEach((item) => {
                        if(typeof item.value !== 'undefined') {
                            destinations.push(item.value);
                        } else {
                            destinations.push(item);
                        }
                        
                    });

                    this.$emit('update-data', {key: 'destinations_of_interest', value: destinations});

                    this.validateStep();
                }

            },
            validateStep(showError = true){
                if(typeof this.formContent.destinations_of_interest === "undefined" || this.formContent.destinations_of_interest === null || this.formContent.destinations_of_interest.length === 0){
                    if(showError) {
                        this.error = true;
                    }
                    
                    this.$emit('update-validation', {step: this.step.index, value: false});
                } else {
                    this.error = false;
                    this.$emit('update-validation', {step: this.step.index, value: true});
                    
                }
            }
        },
    }

</script>