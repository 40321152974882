<template>
    <div>
        <p>In a moment you will be able to describe your trip plans in more detail. Before that, it would be great if you could please provide your basic contact details.</p>
        <div class="your-details-container">
            <div class="input-wrap">
                <input type="text" placeholder="First Name" name="firstname" v-model="firstname" @change="setValue" required />
                <div class="errors-container" v-if="error.includes('first_name') && showErrors" style="margin-top: -7%">
                    <p>Please enter your first name</p>
                </div>
            </div>
            <div class="input-wrap">
                <input type="text" placeholder="Surname / Family Name" name="lastname" v-model="lastname" @change="setValue" required />
                <div class="errors-container" v-if="error.includes('last_name') && showErrors" style="margin-top: -7%">
                    <p>Please enter your last name</p>
                </div>
            </div>
            <div class="input-wrap">
                <input type="email" placeholder="Email Address" name="email" v-model="email" @change="setValue" required />
                <div class="errors-container" v-if="error.includes('email') && showErrors" style="margin-top: -7%">
                    <p>Please enter your email address</p>
                </div>
            </div>
            <div class="input-wrap">
                <vue-tel-input v-model="phone" :preferredCountries="preferredCountries" :defaultCountry="mobile_country_code" @input="setPhone"></vue-tel-input>
                <div class="errors-container" v-if="error.includes('phone') && showErrors">
                    <p>Please enter your country and phone number</p>
                </div>
            </div>
        </div>
        <div>
            <ul class="multi-step-radios-list">
                <!-- <li>
                    <input type="checkbox" id="opted_into_receiving_atr_safari_update_emails" name="opt_into_safari_update_emails" value="true" v-model="optedIn" @change="setOptIn">
                    <input type="text" name="opted_into_receiving_atr_safari_update_emails" :value="optedIn ? 'true' : 'false'" style="opacity:0; height:0; padding:0; margin:0;" />
                    <label for="opted_into_receiving_atr_safari_update_emails"><span>Sign me up for ATR's occasional safari update emails</span></label>
                </li> -->
                <li>
                    <input type="checkbox" id="preferred_call_method" name="preferred_call_method" value="true" v-model="receiveCall" @change="setReceiveCall">
                    <label for="preferred_call_method"><span style="margin-top:-5px; color:#676767;"><p>The usual next step is for one of our Safari Experts to call you for a chat between 9am-5pm your time. That should enable both of us to quickly figure out whether or not we are a good match for this trip and get us off to a flying start with the tailor-making process. On the next page you can let us know of any times and dates which are convenient for a call. If you specifically do not want us to call yet, then please uncheck this button.</p></span></label>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['formContent', 'checkValidation', 'stepIndex', 'step'],
        data() {
            return {
                phone: '',
                mobile_country_code: 'us',
                preferredCountries: ["us", "gb"],
                firstname: '',
                lastname: '',
                email: '',
                optedIn: false,
                receiveCall: false,
                phoneData: {valid: false, number: ''},
                error: [],
                showErrors: false,
            }
        },
        mounted() {
            this.firstname = this.formContent.firstname || '';
            this.lastname = this.formContent.lastname || '';
            this.email = this.formContent.email || '';
            this.phone = this.formContent.phone || '';
            this.mobile_country_code = this.formContent.mobile_country_code || 'us';
            this.optedIn = this.formContent.opted_into_receiving_atr_safari_update_emails || false;
            this.receiveCall = this.formContent.preferred_call_method == 'Yes' || false;

            if(!this.receiveCall){
                this.$emit('update-data', { key: 'preferred_call_method', value: 'No' });
            }
        },
        watch: {
            checkValidation: function(e){
                if(e) {
                    this.validateStep(true);
                }
            },
        },
        methods: {
            setValue(e) {
                if(typeof e === 'undefined' || e === null || e === ''){
                    return;
                }

                if(e.target.getAttribute('name') === 'firstname'){
                    this.validateFirstName(e.target);
                }

                if(e.target.getAttribute('name') === 'lastname'){
                    this.validateLastName(e.target);
                }

                if(e.target.getAttribute('name') === 'email'){
                    this.validateEmail(e.target);
                }

                this.$emit('update-data', { key: e.target.getAttribute('name'), value: e.target.value });
            },
            setPhone(num, e) {
                
                if(typeof e === 'undefined' || e === null || e === ''){
                    return;
                }
                if(typeof e.valid === "undefined" || e.valid === null || !e.valid) {
                    return;
                }
                if(typeof e.number === "undefined" || e.number === null || e.number === '') {
                    return;
                }

                this.validatePhone(e);
                this.phoneData = e;

                const countryName = e.country.name.split('(')[0].trim();

                this.$emit('update-data', { key: 'phone', value: e.number });
                this.$emit('update-data', { key: 'mobile_country_code', value: countryName });
            },
            setOptIn(e) {
                if(typeof e === 'undefined' || e === null || e === ''){
                    return;
                }

                if(e.target.checked){
                    this.$emit('update-data', { key: 'opted_into_receiving_atr_safari_update_emails', value: true });
                } else {
                    this.$emit('update-data', { key: 'opted_into_receiving_atr_safari_update_emails', value: false });
                }
                
            },
            setReceiveCall(e) {
                if(typeof e === 'undefined' || e === null || e === ''){
                    return;
                }

                if(e.target.checked){
                    this.$emit('update-data', { key: e.target.getAttribute('name'), value: 'Yes' });
                } else {
                    this.$emit('update-data', { key: e.target.getAttribute('name'), value: 'No' });
                }
                
            },
            validateStep(showErrors = false){
                this.validateFirstName({value: this.formContent.firstname});
                this.validateLastName({value: this.formContent.lastname});
                this.validateEmail({value: this.formContent.email});
                this.validatePhone(this.phoneData);

                if(showErrors){
                    this.showErrors = true;
                }
            },
            validateFirstName(elem){

                if(typeof elem.value === 'undefined' || elem.value === null || elem.value === ''){
                    this.error.push('first_name');
                } else {
                    this.error = this.error.filter(item => item !== 'first_name');
                }

                this.checkErrors();
            },
            validateLastName(elem){

                if(typeof elem.value === 'undefined' || elem.value === null || elem.value === ''){
                    this.error.push('last_name');
                } else {
                    this.error = this.error.filter(item => item !== 'last_name');
                }

                this.checkErrors();
            },
            validateEmail(elem){

                if(typeof elem.value === 'undefined' || elem.value === null || elem.value === '' || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(elem.value))){
                    this.error.push('email');
                } else {
                    this.error = this.error.filter(item => item !== 'email');
                }

                this.checkErrors();
            },
            validatePhone(elem){

                if(typeof elem.valid === "undefined" || elem.valid === null || !elem.valid) {
                    this.error.push('phone');
                    return;
                } else {
                    this.error = this.error.filter(item => item !== 'phone');
                }

                this.checkErrors();
            },
            checkErrors(){

                let errors = 0;

                if(typeof this.formContent.firstname === "undefined" || this.error.includes('first_name')){
                    errors++;
                }

                if(typeof this.formContent.lastname === "undefined" || this.error.includes('last_name')){
                    errors++;
                }

                if(typeof this.formContent.email === "undefined" || this.error.includes('email')){
                    errors++;
                }

                if(typeof this.formContent.phone === "undefined" || this.error.includes('phone')){
                    errors++;
                }

                if(errors === 0){
                    this.$emit('update-validation', {step: this.step.index, value: true});
                } else {
                    this.$emit('update-validation', {step: this.step.index, value: false});
                }
            },
        },
    }

</script>