<template>
    <div>
        <div class="links-search-sentence-container">
            <search-sentence portalTo="sentencePopups" sentenceId="mainSearchPage" />
        </div>

        <sticky-bar id="search-bar" classes="search-bar row no-gutter">
            <div class="col-xs-8 bar-content lodges">
                <div class="count">
                    {{ wishlist.length }} {{ type }}
                </div>
            </div>

            <div class="col-xs-4 bar-content text-right">
                <a class="link -scroll-top" @click="scrollToTop">TOP <i class="ion-android-arrow-dropup"></i></a>
            </div>

            <div class="clearfix"></div>
        </sticky-bar>

        <public-search-results mode="MainSearch"></public-search-results>

        <div class="panel-padding helpful-message" v-if="wishlist.length > 0">
            <h2 class="heading heading2">Make a travel inquiry</h2>
            <p>You can choose which of your wishlisted items to include when you complete the inquiry form.</p>
            <p>
                <button-internal-presentational
                        classes="btn -main-cta -small -ripple"
                        :router_object="{ name: 'quote' }">
                    <span><span>Inquire</span> <i class="ion-ios-arrow-thin-right"></i></span>
                </button-internal-presentational>
            </p>
        </div>

        <div id="veil" class="veil" ref="veil"></div>

        <transition-wipe/>
    </div>
</template>

<script>
    import LazyLoader from '../../../global/LazyLoader';
    import pageIsReadyMixin from '../../../mixins';

    export default {
        mixins: [pageIsReadyMixin],
        data() {
            return {
                initalLoad: false,
                timeOutId: null,
            };
        },
        methods: {
            scrollToTop() {
                window.pnpHelper.scroll.doScroll(0);
            },
            searchDoSearchEvent(){
                // this.doSearch();
                // this.timeOutId = setTimeout(this.doSearch, 450);
            },
            doSearch() {
                this.$store.commit(window.store.SET_WISHLIST_TYPE, this.search.criteria.params.type);

                if (this.search.criteria.params.type === "experience" && this.wishlist.length > 0) {
                    //Set the first result to load on the right hand side

                    // this.$store.commit(window.store.SET_SEARCH_RESULTS, {type: 'experience'});

                    this.$store.commit(window.store.SET_ACTIVE_ARTICLE, this.wishlist[0], {root: true});

                } else {
                    if (this.wishlist.length > 0 && (this.search.criteria.params.type === "lodge" || this.search.criteria.params.type === "location") && !_.isUndefined(pnpHelper.map)) {

                        pnpHelper.map.displaySearchResults(this.wishlist);

                    } else if (this.search.criteria.params.type === "trip") {
                        // pnpHelper.map.renderTripSummary(this.wishlist[0].trip);
                    }
                }
            }
        },
        computed: {
            search() {
                return this.$store.state.search;
            },
            wishlist() {
                let type = this.$store.state.search.criteria.params.type;
                let wishlist = this.$store.state.wishlist.selected;

                return wishlist.filter((el) => el.type === type).sort((a, b) => {
                    if (_.isUndefined(a['title']) || _.isUndefined(b['title'])) {
                        return true;
                    }

                    return a['title'].toLowerCase().localeCompare(b['title'].toLowerCase());
                });
            },
            wishlistLength() {
                if(this.wishlist){
                    return this.wishlist.length;
                }
            },
            type() {
                if(this.wishlistLength < 2){
                    return this.$store.state.search.criteria.params.type;
                } else {
                    return this.$store.state.search.criteria.params.type + 's';
                }
            }
        },
        mounted() {
            this.$store.commit(window.store.SET_PAGE_TITLE, this.$route.meta['page_title']);
            this.$store.commit(window.store.SET_META_DESCRIPTION, this.$route.meta['meta_description']);
            this.$store.dispatch(window.store.FETCH_AND_RETURN_SEARCH_DEFAULTS, null, {root: true})

            window.bus.$emit('setShowBackButton', false);
            this.timeOutId = setTimeout(() => {
                // this.doSearch();
                this.declarePageIsReady();
            }, 1500);

        },
        created() {
            window.bus.$on('wishlistSearch', this.searchDoSearchEvent);
        },
        beforeRouteLeave(to, from, next) {
            if (to.name == 'single-resource') {
                window.bus.$emit('setShowBackButton', true);
            }

            if (to.name == 'finder' || to.path.indexOf('/finder/') > -1) {
                window.bus.$emit('searchDoSearch', true);
            }

            next();
        },
        destroyed() {
            window.bus.$off('searchDoSearch', this.searchDoSearchEvent);
            clearTimeout(this.timeOutId);
        },
        watch: {
            type(){
                // if(this.initalLoad) {
                this.doSearch();
                // }
            }
        }
    }
</script>