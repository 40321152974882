<template>
    <div v-if="searchResult" v-waypoint="{ active: waypointActive, callback: onWaypoint, options: intersectionOptions }" :class="{'card-active' : isActive}">

        <div class="button-fav visible-xs"
             @click="toggleItem(searchResult, $event)"
             :class="{ on: checkIfOn(searchResult) }"
             aria-label="Add to Wishlist">
        </div>

        <!--This reserves space for the slider on mobile-->
        <img src="/assets/img/placeholder.png" alt="Placeholder" class="visible-xs img-responsive mob-placeholder"/>

        <div class="col-caption">
            <div class="heading2 heading">
                <button-internal-presentational :router_object="{ name: 'single-experience', params: params }">
                    {{searchResult.title}}
                </button-internal-presentational>

                <div class="button-fav hidden-xs"
                     @click="toggleItem(searchResult, $event)"
                     :class="{ on: checkIfOn(searchResult) }"
                     aria-label="Add to Wishlist">
                </div>
            </div>

            <button-internal-presentational :router_object="{ name: 'single-experience', params: params }">
                <div class="content" v-html="searchResult.description"></div>
            </button-internal-presentational>
        </div>

        <div class="stats">
            <info-container classes="col-rating" :value="searchResult.rating ? searchResult.rating : '0.0'">
                <template slot="header">
                    <div class="sub-text">EXPERIENCE <br/>RATING</div>
                </template>

                <template slot="popup-header">
                    <p>Experience rating</p>
                </template>

                <template slot="popup-text">
                    <p>
                        This number represents the peak rating for <strong>{{ searchResult.title.toLowerCase() }}</strong> at any lodge in <strong>{{ computedLocation }}</strong>
                        <span v-if="month <= 0">&nbsp;in <strong>any month</strong></span>
                        <span v-else>&nbsp;in <strong>{{ months[month - 1] }}</strong></span>
                        and is a simple indication of how good this experience can get.
                    </p>

                    <div class="links">
                        <button-internal-presentational
                                classes="link"
                                :router_object="{path: hardcodedUrls.resources.overallRating}"
                                transition_name="wipe">
                            Our ratings system
                        </button-internal-presentational>
                    </div>
                </template>
            </info-container>

            <div class="col-price" :class="{'min-price' : searchResult.min_price}">
                <price :price="searchResult.price" :minPrice="searchResult.min_price">
                    <template slot="header">
                         <span class="sub-text" v-if="searchResult.min_price">
                            GUIDE PRICE {{ this.$store.state.user.user.selected_currency}}<br/> PER PERSON PER NIGHT
                        </span>
                        <span v-else class="sub-text">
                            GUIDE PRICE <br/>{{ this.$store.state.user.user.selected_currency}} PPPN
                        </span>
                    </template>

                    <template slot="popup-header">
                        Experience guide price
                    </template>

                    <template slot="popup-content">
                        <p>
                            These numbers represent
                            the approximate range of price per person per night at all of the <strong>lodges</strong> in
                            <strong>{{ computedLocation }}</strong> which are able to deliver the
                            <strong>{{ searchResult.title.toLowerCase() }}</strong> experience
                            to a high level in
                            <span v-if="month <= 0"><strong>any month</strong></span>
                            <span v-else><strong>{{ months[month - 1] }}</strong></span>.
                            The lower end is the average low season <strong>lodge</strong> price. The upper end is the average high season
                            <strong>lodge</strong> price plus any extras which usually need to be included, such as meals,
                            drinks and activities, transport costs and park fees.
                        </p>

                        <div class="links">
                            <button-internal-presentational
                                    classes="link"
                                    :router_object="{path: hardcodedUrls.resources.priceGuarantee}"
                                    transition_name="wipe">
                                Price guarantee</button-internal-presentational> :

                            <button-internal-presentational
                                    classes="link"
                                    :router_object="{path: hardcodedUrls.resources.howMuchToSpend}"
                                    transition_name="wipe">
                                How much to spend</button-internal-presentational> :

                            <button-internal-presentational
                                    classes="link"
                                    :router_object="{path: hardcodedUrls.resources.howToSaveMoney}"
                                    transition_name="wipe">
                                How to save money</button-internal-presentational>
                        </div>
                    </template>
                </price>
            </div>

            <info-container v-if="searchResult.conservation_rating >= 7"
                            classes="col-rating hidden-xs"
                            :value="searchResult.conservation_rating">

                <template slot="header">
                    <div class="sub-text">CONSERVATION <br/>RATING</div>
                </template>

                <template slot="popup-header">
                    <p>Experience conservation rating</p>
                </template>

                <template slot="popup-text">
                    <p>
                        The conservation rating is an indication of how much a particular location or lodge needs your visit to further the protection
                        of their landscapes and wildlife.
                    </p>
                    <p>
                        We recommend that you consider including at least one lodge with a high conservation rating into your trip.
                    </p>
                    <p>
                        So this particular number represents the <strong>peak conservation rating</strong> in
                        <span v-if="month <= 0"><strong>any month</strong></span>
                        <span v-else><strong>{{ months[month - 1] }}</strong></span>
                        for lodges in <strong>{{ computedLocation }}</strong> which are able to deliver the <strong>{{ searchResult.title.toLowerCase() }}</strong> experience to a high level.
                    </p>

                    <div class="links">
                        <button-internal-presentational
                                classes="link"
                                :router_object="{path: hardcodedUrls.resources.conservation}"
                                transition_name="wipe">
                            Conservation rating
                        </button-internal-presentational>
                    </div>
                </template>
            </info-container>
        </div>

        <public-search-image-slider type="experience"
                                    v-if="searchResult.images && searchResult.images.data[1]"
                                    :cover-image="searchResult.images.data[1].sizes.low"
                                    :caption="searchResult.images.data[1].caption"
                                    :startOffset="2"
                                    :id="Number(searchResult.id)"
                                    :button="searchResult.images.data[1].buttons.data[0]"
        >
        </public-search-image-slider>

        <div class="sr-footer">
            <a class="sr-cta -dropdown" :class="{'-open': visibleRelatedResults.includes(searchResult.id)}" @click="getRelated">
                Smart links
                <i class="ion-ios-arrow-down"></i>
            </a>

            <button-internal :router_object="link" classes="sr-cta -arrow">
                Go to experience
                <i class="ion-ios-arrow-thin-right"></i>
            </button-internal>

            <div class="clearfix"></div>

            <transition name="slide-down">
                <div class="related-searches-container" v-if="visibleRelatedResults.includes(searchResult.id)">

                    <outs-wishlist :classes="['smart-wishlist']"
                                   :list="[{...searchResult,type:'experience'}]"
                                   :item="{...searchResult, type:'experience'}"
                                   itemType="EXPERIENCE">
                    </outs-wishlist>

                    <outs-list :classes="['related-searches']"
                               :prefix="['Best locations for', 'Best lodges for']"
                               :list="[...relatedItems]"
                               itemType="experience"
                               :types="['location', 'lodge', 'trip']"
                               :retainSearch="true">
                    </outs-list>

                    <best-trips-link></best-trips-link>

                    <ul class="related-searches">
                        <li>
                            <button-internal-presentational
                                    class="link"
                                    :router_object="{ name: 'contact-call' }"
                                    v-on:btn-internal-clicked="googleAnalytics.fireEvent('Call', 'Click Contact Call Us', $route.path)">
                                Call us
                            </button-internal-presentational>
                        </li>
                        <li>
                            <button-internal-presentational
                                    class="link"
                                    :router_object="{ name: 'call-back' }"
                                    v-on:btn-internal-clicked="googleAnalytics.fireEvent('Call Back', 'Click Contact Call Back', $route.path)">
                                Get us to call you
                            </button-internal-presentational>
                        </li>
                        <li>
                            <button-internal-presentational
                                    class="link"
                                    :router_object="{ name: 'quote' }"
                                    v-on:btn-internal-clicked="googleAnalytics.fireEvent('Quote', 'Click Contact Inquiry', $route.path)">
                                Make a travel inquiry
                            </button-internal-presentational>
                        </li>
                        <!--<li>-->
                            <!--<button-internal-presentational-->
                                    <!--class="link"-->
                                    <!--:router_object="{ name: 'email' }"-->
                                    <!--v-on:btn-internal-clicked="googleAnalytics.fireEvent('Email', 'Click Contact Email', $route.path)">-->
                                <!--Send us an email-->
                            <!--</button-internal-presentational>-->
                        <!--</li>-->
                    </ul>
                </div>
            </transition>
        </div>
    </div>

</template>

<script>
    import ButtonInternal from "../../partials/presentational/ButtonInternal";
    import GlobalVars from "../../../global/GlobalVars";
    import { mapState } from 'vuex';

    export default {
        props: ['searchResult', 'visibleRelatedResults', 'toggleRelatedSearches'],
        data(){
            return {
                months: [],
                relatedItems: [],
                intersectionOptions: {
                    root: null,
                    rootMargin: '0px',
                    threshold: 0.7,
                    currentId: null
                }
            }
        },
        components: {
            ButtonInternal
        },
        methods: {
            checkIfOn(obj) {
                return this.wishlist.some((el) => {
                    return el.id == obj.id;
                });
            },
            onWaypoint({ direction, el }){
                //console.log(this.searchResult.title, direction);
                if(direction && this.$store.state.activeArticle.article.id !== this.searchResult.id) {
                    this.$store.commit(window.store.SET_ACTIVE_ARTICLE, this.searchResult, {root: true});
                }
            },
            toggleItem(searchItem, event) {
                event.preventDefault();
                event.stopPropagation();

                let newSearchItem = {
                    ...searchItem,
                    type: 'experience',
                    wishlist: true
                };

                let newSelectedItems = [...this.wishlist];

                let check = newSelectedItems.some((el) => {
                    return el.id == newSearchItem.id;
                });

                if(check){
                    //Remove from the search criteria
                    newSelectedItems = newSelectedItems.filter((el) => {
                        return el.id != newSearchItem.id;
                    });

                    this.$store.commit(window.store.SET_WISHLIST, newSelectedItems);
                } else {
                    newSelectedItems.push(newSearchItem);
                    this.$store.commit(window.store.SET_WISHLIST, newSelectedItems);
                }
            },
            getRelated(){
                if(this.visibleRelatedResults.includes(this.searchResult.id)){
                    this.toggleRelatedSearches(this.searchResult.id);
                }else {
                    this.relatedItems = this.searchResult;
                    this.toggleRelatedSearches(this.searchResult.id);
                }
            }
        },
        mounted(){
            this.months = GlobalVars.MONTHS;

            if(this.visibleRelatedResults.includes(this.searchResult.id)) {
                this.relatedItems = this.searchResult;
            }
        },
        computed: {
            hardcodedUrls() {
                return GlobalVars.HARCODED_URLS;
            },
            wishlist() {
                return this.$store.state.wishlist.selected;
            },
            link() {
                let image = this.searchResult.images.data[0] ? this.searchResult.images.data[0].sizes.extralarge : '';

                return {
                    name: 'single-experience',
                    params: {
                        ...this.searchResult,
                        image: image,
                        ...this.searchResult.vue_route_params.data
                    }
                }
            },
            params(){
                let image = this.searchResult.images.data[0] ? this.searchResult.images.data[0].sizes.extralarge : '';

                return {
                    ...this.searchResult,
                    image: image,
                    ...this.searchResult.vue_route_params.data
                }
            },
            waypointActive(){
              return pnpHelper.bootstrap.isSm();
            },
            isActive(){
              return this.$store.state.activeArticle.article.id === this.searchResult.id;
            },
            computedLocation(){
              return this.searchParams.location ? this.searchParams.location.name : 'Africa';
            },
            computedExperience(){
              return this.searchParams.experience ? this.searchParams.experience.name : 'Any Experience';
            },
            googleAnalytics() {
                return window.GoogleAnalytics;
            },
            ...mapState({
                searchType: state => state.search.criteria.params.type,
                month: state => state.search.criteria.params.month,
                wishlist: state => state.wishlist.selected,
                searchParams: state => state.search.criteria.params,
            })
        }
    }
</script>
