class BtableHelper {

    constructor(ctx) {
        console.log(ctx);
        this.params = {};
        if(ctx.perPage != undefined){
            this.params.per_page = ctx.perPage;
        } else {
            this.params.per_page = 5;
        }
        if(ctx.currentPage != undefined){
            this.params.page = ctx.currentPage;
        } else {
            this.params.page = 1;
        }

        if(ctx.sortBy != undefined){
            this.params.sortBy = ctx.sortBy;
        }
        if(ctx.sortDesc != undefined){
            this.params.sortDesc = ctx.sortDesc;
        }
        if(ctx.filter != undefined){
            this.params.filter = ctx.filter;
        }
    }
    getUrlParams(){
        var prms = [];
        for (var key in this.params) {
            if (this.params.hasOwnProperty(key)) {
                prms.push(key + '=' + this.params[key]);
            }
        }
        return prms.join('&');
    }
}
export default BtableHelper;