<template>
    <span>
        <a :href="url" :class="classes" :target="target" :data-style="data_style" class="btn -read-more -animated" @click="maybeEmitClick">
            <i v-if="icon != false && is_icon_left" :class="icon"></i>
            <slot></slot>
            <i v-if="icon != false && !is_icon_left" :class="icon"></i>
        </a>
    </span>
</template>

<script>
    export default {
        data() {
            return {
                target : ''
            }
        },
        props: ['data_style', 'url', 'classes', 'icon', 'is_icon_left', 'type'],
        created(){
            if(this.url && this.url.startsWith('http')){
                this.target = "_blank";
            }
        },
        methods: {

            maybeEmitClick(e) {
                if (_.isUndefined(this.url)) {
                    this.$emit('click');
                    e.preventDefault();
                }
            }
        }
    }
</script>