import { render, staticRenderFns } from "./SearchLodgeCard.vue?vue&type=template&id=b1bc2eae&"
import script from "./SearchLodgeCard.vue?vue&type=script&lang=js&"
export * from "./SearchLodgeCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports