require('./scss/app.scss');
require('./constants');
require('./includes');

import Vue from 'vue';
import 'babel-polyfill';
import BootstrapVue from 'bootstrap-vue';
import VueTextareaAutosize from 'vue-textarea-autosize';
import bFormSlider from 'vue-bootstrap-slider';
import TransitionManager from './global/TransitionManager';
import PageReady from './global/PageReady';
import PortalVue from 'portal-vue';
import VueLazyload from 'vue-lazyload';
import VueWaypoint from 'vue-waypoint';
import HoverIntent from 'vue2-hover-intent';
import VueObserveVisibility from 'vue-observe-visibility';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

require('./libs/fixto').default;

Vue.use(BootstrapVue);
Vue.use(VueTextareaAutosize);
Vue.use(bFormSlider);
Vue.use(PortalVue);
Vue.use(VueWaypoint);
Vue.use(VueObserveVisibility);
Vue.use(VueLazyload, {
    preLoad: pnpHelper.bootstrap.isXs() ? 2 : 3,
    error: '/assets/img/placeholder.png',
    loading: '/assets/img/placeholder.png',
    attempt: 1,
    adapter: {
        loaded ({ bindType, el, naturalHeight, naturalWidth, $parent, src, loading, error, Init }) {
            //$(el).removeClass('image-loading').removeClass('image-error').addClass('image-loaded');
            $(el).closest('.search-image-slider').removeClass('image-loading').removeClass('image-error').addClass('image-loaded');
        },
        loading (listender, Init) {
            //$(listender.el).removeClass('image-loaded').removeClass('image-error').addClass('image-loading');
            $(listender.el).closest('.search-image-slider').removeClass('image-loaded').removeClass('image-error').addClass('image-loading');
        },
        error (listender, Init) {
            //$(listender.el).removeClass('image-loading').removeClass('image-loaded').addClass('image-error');
            $(listender.el).closest('.search-image-slider').removeClass('image-loading').removeClass('image-loaded').addClass('image-error');
        }
    }
});

Vue.directive('hover-intent', HoverIntent);

//This will disable the browsers ability to set the old scroll position when using the pushstate. We manage this in transition manager.
if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
}

new Vue({
    mixins: [TransitionManager, PageReady],
    router,
    store,
    el: '#app',
    computed:{
        transitionKeyUrl(){
            return this.$route.path;
        }
    }
});