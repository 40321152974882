<template>
    <section v-if="position !== 'right' && position !== 'full-page'" class="component-text -outs next-up-half-outs section"
             :class="classes"
             :data-pnp-next-lodge="data_next_lodge"
             data-pnp-half-map="true"
             data-pnp-interactive-map="true">

        <!--<div class="fp-bg full-size pnp-bg vignette vignette1"-->
             <!--:style="{'background-image': 'url(' + image_url + ')' }">-->
        <!--</div>-->

        <div class="inner text-center" :data-pnp-heading="data_heading" v-if="this.nextResult" @click="showLoading">
            <button-internal-presentational
                    :router_object="routerObject"
                    transition_name="article-to-fullpage">
                <span class="-center">
                    <p class="sub-text" v-if="!loadingNext">{{ text }}</p>

                    <p class="sub-text" v-else>LOADING ...</p>

                    <p class="heading4" :class="{'link-disabled': loadingNext}">
                       <span class=" -underline-2"> {{ nextResult.title }}</span>
                    </p>
                </span>
            </button-internal-presentational>
        </div>

        <div class="inner text-center" :data-pnp-heading="data_heading" @click="showLoading" v-else>

            <button-internal-presentational
                    :router_object="routerObject"
                    classes="heading4">
                <span class="-center">
                    <p class="sub-text" v-if="!loadingNext">{{ text_next_item }}</p>

                    <p class="sub-text" v-else>LOADING ...</p>

                    <p class="heading4" :class="{'link-disabled': loadingNext}">
                        <span class="-underline-2">{{ text_explore }}</span>
                    </p>
                </span>
            </button-internal-presentational>
        </div>
    </section>

    <section v-else-if="position == 'full-page'" class="component-text section next-up-outs"
             :class="classes"
             :data-pnp-next-lodge="data_next_lodge"
             data-pnp-interactive-map="true">

        <div class="fp-bg full-size pnp-bg vignette vignette1"
             :style="{'background-image': 'url(' + image_url + ')' }">
        </div>

        <div class="inner text-center"
             :data-pnp-heading="data_heading"
             v-if="this.nextResult"
             @click="showLoading">

            <button-internal-presentational
                    :router_object="routerObject"
                    transition_name="article-to-fullpage">
                <span class="-center">

                    <p class="sub-text -light" v-if="!loadingNext">{{ text }}</p>
                    <p class="sub-text -light" v-else>LOADING ...</p>

                    <p class="heading4 -light" :class="{'link-disabled': loadingNext}">{{ nextResult.title }}</p>
                </span>
            </button-internal-presentational>
        </div>

        <div class="inner text-center"
             :data-pnp-heading="data_heading"
             @click="showLoading"
             v-else>

            <button-internal-presentational
                    :router_object="routerObject"
                    classes="heading4">
                <span class="-center">
                    <p class="sub-text -light" v-if="!loadingNext">{{ text_next_item }}</p>
                    <p class="sub-text -light" v-else>LOADING ...</p>

                    <p class="heading4 -light" :class="{'link-disabled': loadingNext}">{{ text_explore }}</p>
                </span>
            </button-internal-presentational>
        </div>

    </section>

    <section v-else class="next-up-outs">
        <div v-if="nextResult" class="next-half-page" @click="showLoading">
            <button-internal-presentational
                    :router_object="routerObject">

                <span class="-center">
                    <p class="sub-text" v-if="!loadingNext">{{ text }}</p>
                    <p class="sub-text" v-else>LOADING ...</p>

                    <p class="heading4" :class="{'link-disabled': loadingNext}">
                        <span class="-underline-2">{{ nextResult.title }}</span>
                    </p>
                </span>
            </button-internal-presentational>
        </div>

        <div v-else class="next-half-page" @click="showLoading">
            <button-internal-presentational
                    :router_object="routerObject">

                <span class="-center">
                    <p class="sub-text" v-if="!loadingNext">{{ text_next_item }}</p>
                    <p class="sub-text" v-else>LOADING ...</p>
                    <p class="heading4" :class="{'link-disabled': loadingNext}">
                        <span class="-underline-2">{{ text_explore }}</span>
                    </p>
                </span>
            </button-internal-presentational>
        </div>
    </section>
</template>

<script>
    import GlobalVars from '../../../global/GlobalVars';
    export default {
        props: [
            'data_heading',
            'data_next_lodge',
            'next_url',
            'text',
            'classes',
            'page',
            'item',
            'position',
            'text_explore',
            'image_url',
            'text_next_item'
        ],
        data() {
            return {
                loadingNext: false,
            }
        },
        methods: {
            highlightMap(entity) {
                pnpHelper.map.focusOnMarker(entity.id);
            },
            showLoading(){
                this.loadingNext = true;

                $('body').addClass('-disable-page-wipe');
            },
            hideLoading(){
                this.loadingNext = false;
            },
            recursiveGetAllChildren(items, item){
                if(!_.isUndefined(item.children) && item.children.length > 0){
                    for(let k in item.children){
                        if(!_.isUndefined(item.children[k].children) && item.children[k].children.length > 0){
                            this.recursiveGetAllChildren(items, item.children[k])
                        } else {
                            items[items.length] = item.children[k];
                        }
                    }
                }
                return items;
            }
        },
        computed: {
            searchResults(){
                if(this.$store.state.search.results){
                    return this.$store.state.search.results.data;
                }
            },
            type(){
                return this.$store.state.search.criteria.params.type;
            },
            currentIndex(){
                if((this.page !== 'resource' || this.page !== 'trip_collection' || this.page !== 'article') && this.searchResults){
                    return this.searchResults.findIndex(x => x.title == this.item.title);
                }
            },
            nextResult(){
                if(this.page !== this.type || this.searchResults === false){
                    return;
                }

                if(this.page === 'resource' && this.searchResults){
                    for (let key in this.searchResults) {
                        let childrenArr =  this.searchResults[key].children || [];

                        let index = childrenArr.findIndex(x => {
                            return x.title == this.item.title
                        });

                        if(index >= 0) {
                            return childrenArr[index + 1];
                        }
                    }
                } else if((this.page === 'trip_collection' || this.page === 'article') && this.searchResults) {
                    let allItems = [];
                    for (let key in this.searchResults) {
                        this.recursiveGetAllChildren(allItems, this.searchResults[key]);
                    }
                    // console.log(allItems);
                    let index = allItems.findIndex(x => {
                        return x.identifier === this.item.identifier;
                    });
                    if(index >= 0){
                        return allItems[index + 1];
                    }
                } else {
                    return this.searchResults[this.currentIndex + 1];
                }
                return;
            },
            routerObject(){
                // return true if next result is not set
                if(!this.nextResult){
                    if(this.page === 'resource'){
                        return {path: GlobalVars.HARCODED_URLS.finder.resources};
                    } else if(this.page === 'article'){
                        return {path: GlobalVars.HARCODED_URLS.finder.articles};
                    } else if(this.page === 'trip_collection'){
                        return {path: GlobalVars.HARCODED_URLS.finder.trip_collections};
                    } else {
                        return {path: GlobalVars.HARCODED_URLS.finder.experiences};
                    }
                } else {
                    if(this.nextResult.vue_route_params){
                        if(this.type === 'trip_collection'){
                            return {
                                name: `single-trip-intelligence`,
                                params: {
                                    ...this.nextResult,
                                    ...this.nextResult.vue_route_params.data
                                }
                            }
                        }
                        if(this.type === 'article'){
                            return {
                                name: `single-article`,
                                params: {
                                    ...this.nextResult,
                                    ...this.nextResult.vue_route_params.data
                                }
                            }
                        }
                        return {
                            name: `single-${this.type}`,
                            params: {
                                ...this.nextResult,
                                ...this.nextResult.vue_route_params.data
                            }
                        }
                    }

                    return {
                        name: `single-${this.type}`,
                        params: {
                            ...this.nextResult,
                        }
                    }

                }
            }
        },
        mounted(){
            // console.log(this.page !== this.type || !this.nextResult)
        },
        created(){
            window.bus.$on('pageIsReady', this.hideLoading);
        },
        destroyed(){
            window.bus.$off('pageIsReady', this.hideLoading);
        },
        watch: {
            'image_url'(){
                this.loadingNext = false;
            }
        }
    }
</script>
