<template>
    <div>
        <div class="col-caption">
            <div class="heading2 heading">

                <div class="button-fav"
                     @click="toggleItem(trip, $event, searchResult)"
                     :class="{ on: checkIfOn(trip) }"
                     aria-label="Add to Wishlist">
                </div>

                <button-internal-presentational :router_object="{ name: 'single-trip', params: params }">

                    <span class="trip-location"
                          v-for="(location,index) in trip.geographical_summary"
                          :class="{'-highlighting-location': currentTrip.id === trip.id && currentTripLocationIndex == index && highlightTrip}"
                          v-hover-intent:100="highlightTripLocation.bind(null, location.lat, location.lng, index)"
                        >
                            {{location.location}}<span v-if="index < (trip.geographical_summary.length - 1)">, </span>

                    </span>
                </button-internal-presentational>
            </div>

            <button-internal-presentational :router_object="{ name: 'single-trip', params: params }" class="trip-link">
                <p class="sub-text">
                    <span class="trip-location"
                          v-for="(location,index) in trip.geographical_summary"
                          :class="{'-highlighting-location': currentTrip.id === trip.id && currentTripLocationIndex == index && highlightTrip}"
                          v-hover-intent:100="highlightTripLocation.bind(null, location.lat, location.lng, index)"
                    >{{location.title}} ({{location.numnights}})<span v-if="index < (trip.geographical_summary.length - 1)">,&nbsp;</span>
                    </span>
                </p>

                <div class="content" v-html="trip.content"></div>
            </button-internal-presentational>
        </div>

        <div class="stats" :class="{'-show-4' : trip.consrating >= 7}">
            <info-container classes="col-price" :value="trip.numnights ? trip.numnights : '0'">
                <template slot="header">
                    <div class="sub-text">TRIP <br/>NIGHTS</div>
                </template>

                <template slot="popup-header">
                    <p>Trip nights</p>
                </template>

                <template slot="popup-text">
                    <p>This number represents the number of nights on this trip, but does not include any nights needed in transit when traveling to and from Africa.</p>
                </template>
            </info-container>


            <div class="col-price">
                <price :price="trip.price">
                    <template slot="header">
                             <span class="sub-text">
                                GUIDE PRICE <br/>{{ selectedCurrency }} PP
                            </span>
                    </template>

                    <template slot="popup-header">
                        Trip guide price
                    </template>

                    <template slot="popup-content">
                        <span v-if="month <= 0">This number represents the approximate price per person in any month to undertake this trip. </span>
                        <span v-else>This number represents the approximate price per person in {{ GlobalVars.MONTHS[month - 1] }} to undertake this trip. </span>

                        It includes the cost of accommodation, the usual meals, drinks and activities, transport costs and park fees.
                        On top of this guide price you will need to consider potentially significant additional costs such as long-haul
                        flights to Africa, visas, tips, insurance and any expensive optional
                        extras that you choose to include, such as balloon safari and diving.
                    </template>
                </price>
            </div>

            <info-container classes="col-rating" :value="trip.rating ? trip.rating : '0.0'">
                <template slot="header">
                    <div class="sub-text">TRIP <br/>RATING</div>
                </template>

                <template slot="popup-header">
                    <p>Trip rating</p>
                </template>

                <template slot="popup-text">
                    <p>
                        This number represents an indication of the overall quality of a trip.
                        It is calculated from a weighted average of the overall ratings for each of the
                        lodges in the trip during
                        <span v-if="month <= 0"> any month,</span>
                        <span v-else>{{ GlobalVars.MONTHS[month - 1] }},</span> which take into account all the various
                        component ratings of each property, their locations, builds, styles, service
                        levels and experiences.
                    </p>
                </template>
            </info-container>

            <info-container classes="col-rating" v-if="trip.consrating >= 7" :value="trip.consrating ? trip.consrating : '0.0'">
                <template slot="header">
                    <div class="sub-text">CONSERVATION <br/>RATING</div>
                </template>

                <template slot="popup-header">
                    <p>Trip conservation rating</p>
                </template>

                <template slot="popup-text">
                    <p>
                        The conservation rating is an indication of how much a particular lodge needs your visit in
                        <span v-if="month <= 0">any month</span>
                        <span v-else>{{ GlobalVars.MONTHS[month - 1] }}</span>
                        to further the protection of their landscapes and wildlife. We recommend that you try to include at least one lodge with a high conservation rating into your trip if you can. This trip conservation rating is therefore the peak value achieved by any of the lodges in your trip.
                    </p>
                </template>
            </info-container>
        </div>

        <div class="sr-footer">
            <a class="sr-cta -dropdown" :class="{'-open': relatedVisible}"  @click="showRelated">
                Smart links
                <i class="ion-ios-arrow-down"></i>
            </a>

            <button-internal-presentational :router_object="{ name: 'single-trip', params: params }"  classes="sr-cta -arrow">
                Go to trip
                <i class="ion-ios-arrow-thin-right"></i>
            </button-internal-presentational>

            <div class="clearfix"></div>

            <transition name="slide-down">
                <div class="related-searches-container" v-if="relatedVisible">
                    <ul class="related-searches">
                        <li>
                            <button-internal-presentational @click.native="toggleItem(trip, $event, searchResult)" classes="link">
                                <div v-if="!checkIfOn(trip)">Add this trip to your wishlist</div>
                                <div v-else>Remove this trip from your wishlist</div>
                            </button-internal-presentational>
                        </li>
                        <!--<li>-->
                            <!--<button-internal-presentational :router_object="{ name: 'quote'}" classes="link">-->
                                <!--<div>Get a detailed quotation for this trip</div>-->
                            <!--</button-internal-presentational>-->
                        <!--</li>-->
                        <!--<li>-->
                            <!--<button-internal-presentational :router_object="{ name: 'contact-call'}" classes="link">-->
                                <!--<div>Speak with someone about this trip</div>-->
                            <!--</button-internal-presentational>-->
                        <!--</li>-->
                        <!--<li>-->
                            <!--<button-internal-presentational :router_object="{ name: 'contact'}" classes="link">-->
                                <!--<div>Get in touch</div>-->
                            <!--</button-internal-presentational>-->
                        <!--</li>-->
                    </ul>

                    <ul class="related-searches">
                        <li>
                            <button-internal-presentational
                                    class="link"
                                    :router_object="{ name: 'contact-call' }"
                                    v-on:btn-internal-clicked="googleAnalytics.fireEvent('Call', 'Click Contact Call Us', $route.path)">
                                Call us
                            </button-internal-presentational>
                        </li>
                        <li>
                            <button-internal-presentational
                                    class="link"
                                    :router_object="{ name: 'call-back' }"
                                    v-on:btn-internal-clicked="googleAnalytics.fireEvent('Call Back', 'Click Contact Call Back', $route.path)">
                                Get us to call you
                            </button-internal-presentational>
                        </li>
                        <li>
                            <button-internal-presentational
                                    class="link"
                                    :router_object="{ name: 'quote' }"
                                    v-on:btn-internal-clicked="googleAnalytics.fireEvent('Quote', 'Click Contact Inquiry', $route.path)">
                                Make a travel inquiry
                            </button-internal-presentational>
                        </li>
                        <!--<li>-->
                            <!--<button-internal-presentational-->
                                    <!--class="link"-->
                                    <!--:router_object="{ name: 'email' }"-->
                                    <!--v-on:btn-internal-clicked="googleAnalytics.fireEvent('Email', 'Click Contact Email', $route.path)">-->
                                <!--Send us an email-->
                            <!--</button-internal-presentational>-->
                        <!--</li>-->
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import GlobalVars from '../../../global/GlobalVars';
    import { mapState } from 'vuex';

    export default {
        data(){
            return {
                GlobalVars: GlobalVars,
                relatedVisible: false,
                currentTripLocationIndex: null
            }    
        },
        props: ['trip', 'searchResult', 'search', 'currentTrip', 'highlightTrip'],
        methods: {
            checkIfOn(obj) {
                return this.wishlist.some((el) => {
                    return el.id === obj.id;
                });
            },
            showRelated(){
                this.relatedVisible = !this.relatedVisible;
            },
            toggleItem(searchItem, event, trip) {
                event.preventDefault();

                let newSearchItem = {
                    ...searchItem,
                    type: this.$store.state.search.criteria.params.type,
                    trip: trip
                };

                let wishlistPayload = {
                    id: newSearchItem.id,
                    type: 'trip'
                };

                let newSelectedItems = [...this.wishlist];

                let check = newSelectedItems.some((el) => {
                    return el.id === newSearchItem.id;
                });

                if(check){
                    //Remove from the search criteria
                    newSelectedItems = newSelectedItems.filter((el) => {
                        return el.id !== newSearchItem.id;
                    });

                    this.$store.commit(window.store.REMOVE_WISHLIST_ITEM, wishlistPayload);
                } else {
                    newSelectedItems.push(newSearchItem);
                    this.$store.dispatch(window.store.SET_WISHLIST_ITEM_BY_ID, wishlistPayload);
                }

                this.selectedItems = newSelectedItems;
            },
            highlightTripLocation(lat, lng, locationIndex, id) {
                if(this.currentTrip.id === this.trip.id) {
                    if (!locationIndex) {
                        for (let i = 0; i < this.trip.geographical_summary.length; i++) {
                            if (this.trip.geographical_summary[i].lat == lat && this.trip.geographical_summary[i].lng == lng) {
                                locationIndex = i;
                                break;
                            }
                        }
                    }

                    this.currentTripLocationIndex = locationIndex;
                    pnpHelper.map.focusOnMarker(locationIndex);

                    window.bus.$once('tripSummaryRendered', () => {
                        this.currentTripLocationIndex = locationIndex;
                        pnpHelper.map.focusOnMarker(locationIndex);
                    });
                }
            },
        },
        mounted(){
            // console.log(this.searchResult);
        },
        computed: {
            wishlist() {
                return this.$store.state.wishlist.selected;
            },
            params(){
                return {
                    slug: this.trip.vue_route_params.slug,
                };
            },
            googleAnalytics() {
                return window.GoogleAnalytics;
            },
            // outParams(){
            //     return {
            //         tripCode: this.trip.trip_code
            //     }
            // },
            ...mapState({
                searchType: state => state.search.criteria.params.type,
                month: state => state.search.criteria.params.month,
                wishlist: state => state.wishlist.selected,
                searchLocation: state => state.search.criteria.params.location.name,
                selectedCurrency:  state => state.user.user.selected_currency
            })
        },
        created(){
            bus.$on('highlightTripLocation', this.highlightTripLocation);
        },
        destroyed(){
            bus.$off('highlightTripLocation', this.highlightTripLocation);
        }
    }
</script>