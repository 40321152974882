<template>
    <div>
        <ul class="multi-step-radios-list">
            <li>
                <input type="radio" id="get_in_touch-online" name="get_in_touch" value="Webform" @change="updateGetInTouch" v-model="selectedOption">
                <label for="get_in_touch-online"><span>Start building your trip via our website form</span></label>
            </li>
            <li>
                <input type="radio" id="get_in_touch-call" name="get_in_touch" value="Scheduled a call via webform" @change="updateGetInTouch" v-model="selectedOption">
                <label for="get_in_touch-call"><span>Speak to one of our safari experts now</span></label>
            </li>
        </ul>

        <div class="trade-enquiry-details">
            Alternatively give us a call now. We are available to talk to on the phone Monday to Friday from 0900-2100hrs London / 0400-1600hrs New York / 0100-1300hrs Los Angeles.
            <br><br>
            <strong>USA & Candada Toll Free: 888-487 5418</strong><br>
            <strong>UK & Europe: +44 (0)1306 880770</strong><br>
            <strong>Australia Toll Free: +61 800 821 832</strong>
        </div>
    </div>
    
</template>

<script>

    export default {
        props: ['formContent', 'selected', 'stepIndex', 'step'],
        data(){
            return {
                selectedOption: 'Webform',
            }
        },
        methods: {
            updateGetInTouch(e){
                const showSteps = {
                    'Webform': [0, 4, 5, 6, 7, 8, 9, 10, 12, 13],
                    'Scheduled a call via webform': [0, 2, 4, 5, 6, 7, 8, 9, 15, 16, 17],
                }

                Object.entries(showSteps).forEach(([key, value]) => {
                    if(key === e.target.value){
                        this.$emit('update-steps', {'type': 'getInTouch', 'key': key, 'value': value});
                    }
                });

                const method = e.target.value || 'Webform';

                this.$emit('update-data', { key: 'method_of_enquiry', value: method });

            }
        },
        mounted(){
            this.selectedOption = this.formContent.method_of_enquiry || this.selected;
            if(this.stepIndex === this.step.index && typeof this.formContent.method_of_enquiry !== "undefined") {
                this.updateGetInTouch({target: {value: this.selectedOption}});
            }
            
        }
    }

</script>