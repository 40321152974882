<template>
   <div class="directory-links">
       <a ref='directoryLink'
          @click.prevent="toggleCard"
          :href="subDirectory.url"
          class="btn -main-cta -small -ripple apply-btn apply">
           Preview
       </a>

       <button-internal-presentational :router_object="{ name: this.type === 13 ? 'single-lodge' : 'single-location', params : params}"
                                       classes="btn -main-cta -small -ripple apply-btn apply">
           View
       </button-internal-presentational>
   </div>
</template>

<script>
    export default {
        props: [
            'subDirectory',
            'search',
            'toggleItem',
            'toggleRelatedSearches',
            'selectedItems',
            'visibleRelatedResults',
            'type',
            'level',
            'currentId'
        ],
        data(){
            return {
                show: this.subDirectory.isCardOpen,
                searchResult: {},
                loading: true
            }
        },
        methods: {
            close(){
                this.show = !this.show;
                this.$emit('cardToggle', this.show, this.subDirectory);

                // if(this.level == 1) {
                //     // pnpHelper.map.clearMap();
                //     // pnpHelper.map.resetMapViewport();
                // }
            },
            toggleCard(preventZoom){
                if(this.type == 13) {
                    //If lodge
                    this.$store.dispatch(window.store.FETCH_AND_RETURN_LODGE, this.subDirectory.id).then((result) => {
                        this.searchResult = result;
                        this.show = !this.show;
                        this.loading = false;
                        this.$emit('cardToggle', this.show, this.subDirectory, preventZoom);

                        if(this.show && result && this.currentId !== this.subDirectory.id) {
                            if(window.pnpHelper.map && !preventZoom) {
                                // console.log('doing center');
                                // pnpHelper.map.centerOnCoords([result.lng, result.lat], 8);
                                // pnpHelper.map.addSingleMarkerToMap(result.lng, result.lat);
                            }
                        } else {
                            if(this.level == 1 && this.subDirectory) {
                                // pnpHelper.map.clearMap();
                                pnpHelper.map.resetMapViewport();
                            }
                        }
                    });
                } else {
                    //If location
                    // pnpHelper.map.clearMap();

                    this.$store.dispatch(window.store.FETCH_AND_RETURN_LOCATION, this.subDirectory.id).then((result) => {
                        // pnpHelper.map.clearMap();
                        this.searchResult = result;
                        this.show = !this.show;
                        this.loading = false;
                        this.$emit('cardToggle', this.show, this.subDirectory);

                        let zoom;

                        if(this.level == 1) { zoom = 5; }
                        else if(this.level == 2) { zoom = 6; }
                        else if(this.level == 3) { zoom = 7; }
                        else if(this.level == 4) { zoom = 8; }

                        if(this.show) {
                            // if(window.pnpHelper.map) {
                                //pnpHelper.map.centerOnCoords([result.lng, result.lat], zoom);
                            // }
                        } else {
                            if(this.level == 1) {
                                // pnpHelper.map.clearMap();
                                pnpHelper.map.resetMapViewport();
                            }
                        }
                    });
                }
            }
        },
        computed: {
            params(){
                return {
                    directory: true,
                    ...this.subDirectory.vue_route_params['data']
                }
            }
        },
        mounted(){

            if(this.subDirectory.isCardOpen) {
                if(this.subDirectory.type === 13) {
                    this.$store.dispatch(window.store.FETCH_AND_RETURN_LODGE, this.subDirectory.id).then((result) => {
                        this.searchResult = result;
                        this.loading = false;

                        pnpHelper.map.centerOnCoords([result.lng, result.lat], 8);
                        // pnpHelper.map.addSingleMarkerToMap(result.lng, result.lat);
                    });
                } else {
                    this.$store.dispatch(window.store.FETCH_AND_RETURN_LOCATION, this.subDirectory.id).then((result) => {
                        this.searchResult = result;
                        this.loading = false;

                        let zoom;

                        if(this.level == 1) { zoom = 4; }
                        else if(this.level == 2) { zoom = 5; }
                        else if(this.level == 3) { zoom = 6; }
                        else if(this.level == 4) { zoom = 8; }

                        pnpHelper.map.centerOnCoords([result.lng, result.lat], zoom);
                    });
                }
            }
        },
        created(){
            window.bus.$on('openCard' + this.subDirectory.id, this.toggleCard);
        },
        destroyed(){
            window.bus.$off('openCard' + this.subDirectory.id, this.toggleCard);
        }
    }
</script>