import GlobalVars from "../global/GlobalVars";

window.MAP_STYLES = {
    terrain: 'mapbox://styles/james-pnp/ciz4aevzn006s2rp8yi28mstp',
    satellite: 'mapbox://styles/mapbox/satellite-v9'
};

window.MAP_TYPES = {
    journey: 'journey',
    search: 'search'
};


if(window.location.href.indexOf('africatravelresource.com') > -1){
    window.MAP_KEY = 'pk.eyJ1IjoiZGF6am9obnNvbiIsImEiOiI5Nzc4YWYwYjg2YWZjMmU5NTcwMDRkYjk5NTU4OGVlZCJ9.x4xcYANhcKBiiSBqwWxlKw';
} else {
    window.MAP_KEY = 'pk.eyJ1IjoiamFtZXMtcG5wIiwiYSI6Ikc0bVhFckUifQ.wn99C0bWhKeMSOqHCQ8Akg';
}

//Create a map type function
export default class AtrMap
{
    constructor(mapType, mapId, mapZoom)
    {
        this.PINS_TO_SHOW = window.globals.oldDevice ? 100 : 200;

        this.map;
        this.defaultMapZoom =  mapZoom ?  mapZoom : 3;
        this.useMapBox = true;
        this.currentFocussedId = null;
        // this.nextLodgeIsPeeking = false;
        this.mapItems = [];
        this.mapSearchItems = [];
        this.mapLoaded = false;
        this.showMarkersOnDemand = false;
        this.popup;
        this.popupId;
        this.tempMarker;
        this.tripId;
        this.shiftOffset; //Stores the number of pixels the map has been shifted
        this.currentMarkerId;
        // this._this = this;
        this.disablePopover = false;
        this.polyline;
        this.mapUrlState;
        this.isFirstMapMove = true;


        if (this.useMapBox == false || (this.useMapBox && mapboxgl && mapboxgl.supported())) {
            var _this = this;
            var isDefaultMap = true;
            var defaultMapOptions = {
                container: mapId,
                style: MAP_STYLES.terrain,
                center: [28.6405843,-13.9662578],
                zoom: this.defaultMapZoom,
                maxZoom: 14,
                interactive: (window.pnpHelper.page.isSearchPage() || window.pnpHelper.page.isDirectoryPage()),
                scrollZoom:  (window.pnpHelper.page.isSearchPage() || window.pnpHelper.page.isDirectoryPage()),
                boxZoom: false,
                dragRotate: false,
                keyboard: false,
                zoomControl: (window.pnpHelper.page.isSearchPage() || window.pnpHelper.page.isDirectoryPage()),
                doubleClickZoom:  (window.pnpHelper.page.isSearchPage() || window.pnpHelper.page.isDirectoryPage()),
                touchZoomRotate: (window.pnpHelper.page.isSearchPage() || window.pnpHelper.page.isDirectoryPage()) && !pnpHelper.isTouch()
            };

            if(!window.pnpHelper.page.isSearchPage() && !window.pnpHelper.page.isDirectoryPage()){
                defaultMapOptions.center =[28.6405843,-13.9662578];
                defaultMapOptions.zoom = 4;
            }

            if(this.useMapBox) {
                //Init the map using MapBox
                mapboxgl.accessToken = window.MAP_KEY;

                this.map = new mapboxgl.Map(defaultMapOptions);

                // if(window.pnpHelper.page.isSearchPage()) {
                var nav = new mapboxgl.NavigationControl();
                this.map.addControl(nav, 'bottom-left');
                // }

                //Close the popover when map is clicked
                this.map.on('click', function(e){
                    if(e.originalEvent.target && e.originalEvent.target.className === "mapboxgl-canvas") {
                        _this.hidePopover();
                        window.bus.$emit('clear-map-search');
                    }
                });


                //Only show a max of PINS_TO_SHOW pins on the map at any one time
                this.map.on('moveend', _.debounce((e) => {
                    // console.log(this.map.getZoom());
                    this.refreshOnDemandMarkers();

                    //Dont set the map params the first time it moves into position
                    if(this.isFirstMapMove === false) {
                        //Append URL parameters to record map position
                        this.setMapPositionInUrl();
                    }else{
                        this.isFirstMapMove = false;
                    }

                }, 500));


                // this.map.on('load', function(){
                // });
                //
                // this.map.on('click', function (e) {
                //     console.log(JSON.stringify(e.lngLat));
                // });
            }else {

                this.map = L.map(mapId).setView([-13.9662578, 28.6405843], 4);
                //L.tileLayer('http://{s}.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png', {
                //attribution: '&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="http://www.openstreetthis.map.org/copyright">OpenStreetMap</a>',
                L.tileLayer('https://api.mapbox.com/styles/v1/james-pnp/ciz4aevzn006s2rp8yi28mstp.html?fresh=true&title=true&access_token=pk.eyJ1IjoiamFtZXMtcG5wIiwiYSI6Ikc0bVhFckUifQ.wn99C0bWhKeMSOqHCQ8Akg#14.3/-3.199838/35.463954/0', {
                    attribution: '&copy; <a href="http://www.openstreetthis.map.org/copyright">OpenStreetMap</a>',
                    maxZoom: 16,
                    zoomSnap: 0.1
                }).addTo(this.map);
            }
            this.mapLoaded = true;
            window.bus.$emit('MapLoaded');

        } else {
            //Fallback!!!!
        }

        window.bus.$on('close-map-popover', _this.hidePopover);

        this.getMapPositionInUrl();
    }

    inBounds(marker){
        var bounds = this.map.getBounds();
        var lng = (marker.lng - bounds._ne.lng) * (marker.lng - bounds._sw.lng) < 0;
        var lat = (marker.lat - bounds._ne.lat) * (marker.lat - bounds._sw.lat) < 0;
        return lng && lat;
    }

    refreshOnDemandMarkers(){
        var _this = this;

        if(this.showMarkersOnDemand) {
            if (this.mapItems && Object.keys(this.mapItems).length > 0) {
                var j = 0;

                if(this.mapSearchItems.length) {
                    for (var k=0; k < this.mapSearchItems.length; k++) {
                        var mapItem = this.mapItems[this.mapSearchItems[k]];

                        if (_this.inBounds(mapItem.markerData) && (j < this.PINS_TO_SHOW)) {
                            if(!mapItem.markerData.visible) {
                                // console.log('adding marker to map', mapItem);

                                mapItem.marker.addTo(this.map);
                                mapItem.markerData.visible = true;
                            }

                            j++;
                        }else{
                            mapItem.marker.remove();
                            mapItem.markerData.visible = false;
                        }
                    }
                }

                for (var i in this.mapItems) {
                    var mapItem = this.mapItems[i];

                    //Ignore markers in the search results as these are take care of above
                    if(!mapItem.markerData.inSearchResults) {
                        if (_this.inBounds(mapItem.markerData) && ((j < this.PINS_TO_SHOW) || this.map.getZoom() > 6 || mapItem.markerData.forceVisible)) {
                            if (!mapItem.markerData.visible) {
                                mapItem.marker.addTo(this.map);
                                mapItem.markerData.visible = true;
                            }

                            j++;
                        } else {
                            mapItem.marker.remove();
                            mapItem.markerData.visible = false;
                        }
                    }
                }

                // console.log(j + ' pins shown on map');
            }
        }
    }

    markerClickHandler(){
        var markerId = this.getAttribute('data-pnp-marker-id');

        pnpHelper.map.showPopover(markerId, true);

        if(window.pnpHelper.page.isDirectoryPage()) {

            window.bus.$emit('expand-directory-node', {directoryId: markerId, preventZoom: true});

        }else if(window.pnpHelper.page.isSearchPage()){

            if($('#sr-' + markerId).length) {
                window.bus.$emit('activate_waypoints', false);
                window.pnpHelper.scroll.scrollTo('#sr-' + markerId, function () {
                    window.bus.$emit('activate_waypoints', true);
                });
                // window.pnpHelper.map.focusOnMarker(markerId);
            }
        }
    }

    tripMarkerClickHandler(){
        window.bus.$emit('highlightTripLocation',
            this.getAttribute('data-pnp-marker-lat'),
            this.getAttribute('data-pnp-marker-lng'),
            this.getAttribute('data-pnp-marker-id')
        );
    }

    markerMouseEnterHandler(event){
        // if(!window.pnpHelper.page.isSearchPage()) {
        // }
    }

    // markerMouseLeaveHandler(){
    //     if(window.pnpHelper.page.isSearchPage()) {
    //         this.hidePopover();
    //     }
    // }

    showPopover(id, preventLodgeHighlight, mapItem){
        var _this = this;
        if(this.popupId != id) {//&& !this.disablePopover) {
            var markerData;

            var doShowPopover = function(){
                if (_this.popup) {
                    _this.popup.remove();
                }

                _this.popup = new mapboxgl.Popup({closeOnClick: false});
                _this.popup.setLngLat([markerData.lng, markerData.lat]);
                _this.popup.setHTML(
                    '<div data-popover-marker-id="' + markerData.id + '">' +
                    '<div class="caption"></div>' +
                    '</div>'
                );
                _this.popup.addTo(_this.map);

                $(window).trigger('reload-portal', {markerId: id, markerType: markerData.type});

                if(!preventLodgeHighlight) {
                    _this.focusOnMarker(id);
                }

                setTimeout(function(){
                    $('.mapboxgl-popup').addClass('-in');
                },400);

                _this.popupId = id;
            };

            if(this.mapItems[id] || mapItem) {
                markerData = mapItem ? mapItem.markerData : _this.mapItems[id].markerData;

                doShowPopover();
            }else{
                //We're dealing with a location which isn't in the results and not passed in
                $.get(window.API_URL + 'searchitem/location/' + id, function(response){
                    var location = response.data[0];

                    markerData = {
                        lng: location.lng,
                        lat: location.lat,
                        id: location.id,
                        name: location.title,
                        type: GlobalVars.LOCATION
                    };

                    //Create a temp map marker
                    // create a DOM element for the marker
                    var el = document.createElement('div');
                    el.className = 'marker marker-'+markerData.id+' -marker-white';

                    el.setAttribute("data-pnp-marker-id", markerData.id);
                    // el.addEventListener('click', this.markerClickHandler);

                    // add marker to map
                    _this.tempMarker = new mapboxgl.Marker(el)
                        .setLngLat({
                            "lng": markerData.lng,
                            "lat": markerData.lat
                        });

                    _this.tempMarker.addTo(_this.map);

                    doShowPopover();
                });
            }

        }

        this.setMapPositionInUrl();
    }

    hidePopover(){
        $('.mapboxgl-popup').removeClass('-in');

        if(this.tempMarker){
            this.tempMarker.remove();
            this.tempMarker = null;
        }

        setTimeout(() => {
            if (!_.isUndefined(this.popup)) {
                this.popup.remove();
                this.popupId = null;

                this.setMapPositionInUrl();
            }
        },300);
    }

    hidePopoverInstant(){
        $('.mapboxgl-popup').hide();
    }

    //Turns the search results into yellow markers
    searchResultsToMarkers(markers, searchResults){

        this.clearMap();

        if(markers) {

            if(markers.length > this.PINS_TO_SHOW){
                this.showMarkersOnDemand = true;
            }

            let i=0;

            if(!searchResults && this.mapUrlState){
                this.moveMapToPositionInUrl();
            }

            //Iterate through the results and render the results on a map
            for (var t in markers){
                var srcData = markers[t];
                let isInSearchResults = !_.isUndefined(_.find(searchResults, ['id', srcData.id]));
                var markerData = {
                    lat: srcData.lat,
                    lng: srcData.lng,
                    // image: srcData.image,
                    id: srcData.id,
                    visible: false,
                    forceVisible: false,
                    inSearchResults: isInSearchResults
                    // title: srcData.title,
                    // price: srcData.price,
                    // rating: srcData.rating
                };

                let opacityClass = '';

                if(srcData.rating >= 8 && srcData.rating < 9){
                    opacityClass = "-opacity-80";
                }else if(srcData.rating >= 7 && srcData.rating < 8){
                    opacityClass = "-opacity-60";
                }else if(srcData.rating >= 0 && srcData.rating < 7){
                    opacityClass = "-opacity-40";
                }


                let isSearchResultClass = isInSearchResults ? ' in-search-results' : ' not-in-search-results';

                // create a DOM element for the marker
                var el = document.createElement('div');
                el.className = 'marker marker-' + markerData.id + ' -marker-highlighted ' + opacityClass + isSearchResultClass;
                el.setAttribute("data-pnp-marker-id", markerData.id);

                el.addEventListener('click', this.markerClickHandler);

                // add marker to map
                var marker = new mapboxgl.Marker(el)
                    .setLngLat({
                        "lng": markerData.lng,
                        "lat": markerData.lat
                    });

                if(!this.showMarkersOnDemand) {
                    marker.addTo(this.map);
                }

                this.mapItems[markerData.id] = {
                    "marker": marker,
                    "markerData": markerData,
                    "element": el
                };

                if(this.showMarkersOnDemand && isInSearchResults) {
                    this.mapSearchItems.push(markerData.id);
                }
            }
        }
    };

    clearMap(){

        this.hidePopover();

        if(this.mapItems && Object.keys(this.mapItems).length > 0) {

            for (var i in this.mapItems) {
                var mapItem = this.mapItems[i];
                mapItem.element.removeEventListener('click', this.markerClickHandler);
                mapItem.element.removeEventListener('click', this.tripMarkerClickHandler);
                mapItem.element.removeEventListener('mouseenter', this.markerMouseEnterHandler);
                // mapItem.element.removeEventListener('mouseleave', this.markerMouseLeaveHandler);

                mapItem.marker.remove();
            }
        }

        //Try removing the trip lines between stops
        try {
            this.map.removeSource('route');
            this.map.removeLayer('route');
        }catch(err){

        }

        this.mapItems = [];
        this.mapSearchItems = [];
    };

    clearMapMarker(id){
        this.hidePopover();

        if(this.mapItems && Object.keys(this.mapItems).length > 0) {
            var mapItem = this.mapItems[id];

            if(mapItem) {
                mapItem.element.removeEventListener('click', this.markerClickHandler);
                mapItem.element.removeEventListener('click', this.tripMarkerClickHandler);
                mapItem.element.removeEventListener('mouseenter', this.markerMouseEnterHandler);
                // mapItem.element.removeEventListener('mouseleave', this.markerMouseLeaveHandler);

                mapItem.marker.remove();
            }
        }

        delete this.mapItems[id];
    };

    resetMapViewport(){
        pnpHelper.map.hidePopover();

        this.map.flyTo({
            center: [28.6405843,-13.9662578],
            zoom: 3,
            duration: 3000
        });
    };

    setMapPositionInUrl(){
        if(!this.mapUrlState) {
            window.bus.$emit('setMapState', {
                lat: this.map.getCenter().lat.toFixed(6),
                lng: this.map.getCenter().lng.toFixed(6),
                zoom: this.map.getZoom().toFixed(2),
                popover: this.popupId ? this.popupId : -1
            });
        }
    }

    getMapPositionInUrl(){
        var mapState = pnpHelper.getUrlParameter('map');
        var popover = pnpHelper.getUrlParameter('popover');

        if(mapState || popover) {
            if (mapState) {
                mapState = mapState.split('|');
            } else {
                mapState = [];
            }

            this.mapUrlState = {
                lat: mapState[0] ? mapState[0] : null,
                lng: mapState[1] ? mapState[1] : null,
                zoom: mapState[2] ? mapState[2] : null,
                id: popover ? popover : -1
            }
        }
    }

    moveMapToPositionInUrl(){

        if(this.mapUrlState) {
            var animationDuration = 2300;
            if (this.mapUrlState.lat) {
                //If we know where the map should be centered
                this.map.flyTo({
                    center: [this.mapUrlState.lng, this.mapUrlState.lat],
                    zoom: this.mapUrlState.zoom,
                    duration: animationDuration
                });

                if(this.mapUrlState.id > -1){
                    var tempMapState = this.mapUrlState;

                    setTimeout(function () {
                        pnpHelper.map.showAutoCompleteMapItem(tempMapState, null, false);
                    }, animationDuration);

                }

            }else if(this.mapUrlState.id > -1) {
                pnpHelper.map.showAutoCompleteMapItem(this.mapUrlState, null, 8);


                // If we dont know where the map should be centered
                // var mapItem = this.mapItems[popoverToShow];
                //
                // this.map.flyTo({
                //     center: [mapItem.markerData.lng, mapItem.markerData.lat],
                //     zoom: 8,
                //     offset: [0, -Math.abs(window.$window.outerHeight() / 5)],
                //     duration: animationDuration
                // });

                // pnpHelper.map.showPopover(popoverToShow);


                // setTimeout(function () {
                //
                // }, animationDuration);
            }
            this.mapUrlState = null;
        }else{
            console.warn('No map state found');
        }
    }

    displaySearchResults(searchResults, markers){

        if(searchResults[0]) {
            var firstMarkerId = searchResults[0]['id'];
            this.disablePopover = true;

            if (!markers) {
                markers = [];

                for (var i in searchResults) {
                    var sr = searchResults[i];

                    markers.push({
                        id: sr.id,
                        lat: sr.lat,
                        lng: sr.lng,
                        rating: sr.rating
                    });
                }
            }


            this.searchResultsToMarkers(markers, searchResults);
            this.undoFocusOnMarker();


            var numMapItems = Object.keys(this.mapItems).length;

            if (numMapItems > 1) {
                var bounds = new mapboxgl.LngLatBounds();

                if (this.mapSearchItems.length > 0) {

                    for (var i = 0; i < this.mapSearchItems.length; i++) {
                        bounds.extend(this.mapItems[this.mapSearchItems[i]].marker._lngLat);
                    }

                } else {

                    for (var i in this.mapItems) {
                        bounds.extend(this.mapItems[i].marker._lngLat);
                    }
                }

                var animationDuration = 2300;


                if(this.mapUrlState){

                    this.moveMapToPositionInUrl();

                }else {

                    this.map.fitBounds(bounds, {
                        padding: {
                            left: 125,
                            bottom: 125,
                            top: 125,
                            right: 125
                        }, duration: animationDuration
                    });

                    this.focusOnMarker(firstMarkerId);
                }

                setTimeout(function () {
                    this.disablePopover = false;
                }, animationDuration);
            } else if (numMapItems === 1) {
                this.centerOnCoords(null, 8);
            } else {
                console.warn('No map markers found');
            }
        }else{
            console.warn('No search results to display on map');
        }
    };

    fitBounds = function(bounds, options){
        this.map.fitBounds(bounds, options);
    };

    //Shift the focus on the map to the right
    shiftMapRight = function(center, zoom){
        if(!center){
            if(this.mapItems) {
                for (var i in this.mapItems) {
                    center = this.mapItems[i].marker._lngLat;
                    break;
                }
            }else{
                center = this.map.getCenter();
            }
        }

        this.shiftOffset = window.$window.outerWidth()/4;

        this.map.flyTo({
            center: center,
            zoom: zoom ? zoom : this.map.getZoom() + 1,
            offset: [this.shiftOffset, 0],
            duration: 4000
        });
    };

    setZoom = function(zoom){
        if(zoom) {
            this.map.setZoom(zoom)
        } else {
            console.log(zoom  + ' is not a valid zoom level');
        }
    };

    //Shift the focus on the map to the right
    centerOnCoords = function(center, zoom, animationDuration=4000){
        if(!center){
            if(this.mapItems) {
                for (var i in this.mapItems) {
                    center = this.mapItems[i].marker._lngLat;
                    break;
                }
            }else{
                center = this.map.getCenter();
            }
        }

        this.map.flyTo({
            center: center,
            zoom: zoom ? zoom : this.map.getZoom() + 1,
            duration: animationDuration
        });
    };

    //Shift the focus on the map back to the center
    unShiftMapRight = function(){
        var center;

        if(this.mapItems) {
            for (var i in this.mapItems) {
                center = this.mapItems[i].marker._lngLat;
                break;
            }
        }else{
            center = this.map.getCenter();
        }

        this.map.flyTo({
            center: center,
            offset: [-Math.abs(this.shiftOffset/2), 0],
            zoom: this.map.getZoom() - 1,
            duration: 4000
        });
    };

    showAutoCompleteMapItem = function(data, directoryMode, zoom){
        var mapItem = this.mapItems[data.id];
        var _this = this;
        _this.hidePopover();

        if(mapItem) {
            if (!mapItem.markerData.visible) {
                mapItem.marker.addTo(this.map);
                mapItem.markerData.visible = true;
                mapItem.markerData.forceVisible = true;
            }

            if(pnpHelper.page.isDirectoryPage()){
                data.type = GlobalVars.LODGE
            }

            //The popover needs to know what type it is
            mapItem.markerData['type'] = data.type;

            if(zoom) {
                this.map.flyTo({
                    center: mapItem.marker._lngLat,
                    duration: 1000,
                    zoom: zoom,
                    offset: [0, -Math.abs(window.$window.outerHeight() / 5)]
                });

                setTimeout(function () {
                    _this.showPopover(data.id, true, mapItem);
                }, 1000);
            }else{

                setTimeout(function () {
                    _this.showPopover(data.id, true, mapItem);
                }, 500);
            }
        }else{

            setTimeout(function () {
                _this.showPopover(data.id, true, null, zoom);
            }, 1000);

            if(zoom){
                this.map.flyTo({
                    center: [data.lng, data.lat],
                    duration: 1000,
                    zoom: zoom,
                    offset: [0, -Math.abs(window.$window.outerHeight() / 5)]
                });
            }
        }

    };

    showNextMapItem = function(lng, lat, markerId){
        // Add the next marker to the map
        var markerData = {
            lat: lat,
            lng: lng
        };

        var el = document.createElement('div');
        el.className = 'marker marker-' + markerId + ' -marker-highlighted';
        el.setAttribute("data-pnp-marker-id", markerId);

        var marker = new mapboxgl.Marker(el)
            .setLngLat({
                "lng": lng,
                "lat": lat
            })
            .addTo(this.map);

        this.mapItems[markerId] = {
            "marker": marker,
            "markerData": markerData,
            "element": el
        };

        //Move the map to focus on both markers
        var bounds = new mapboxgl.LngLatBounds();

        for (var i in this.mapItems) {
            bounds.extend(this.mapItems[i].marker._lngLat);
        }

        this.map.fitBounds(bounds, {
            padding: {
                left: 250,
                bottom: 250,
                top: 250,
                right: 250
            }, duration: 4000
        });
    };

    hideNextMapItem = function(markerId){
        this.clearMapMarker(markerId);
    };

    renderTripSummary = function(tripFamily, isTripFamily, isTripPage){
        if(this.tripId != tripFamily.id) {
            var _this = this;
            var lineCoords = [];
            this.disablePopover = true;
            this.clearMap();
            this.tripId = tripFamily.id;
            this.undoFocusOnMarker();

            //Iterate through the results and render the results on a map
            for (var t in tripFamily.geographical_summary){
                var srcData = tripFamily.geographical_summary[t];

                lineCoords.push([srcData.lng, srcData.lat]);

                var markerData = {
                    lat: srcData.lat,
                    lng: srcData.lng,
                    title: srcData.title,
                    id: t
                };

                // create a DOM element for the marker
                var el = document.createElement('div');

                if(isTripPage){
                    let additionalClasses = '';

                    if(t == 0) {
                        additionalClasses += ' -start-location';
                    }else if(t == (tripFamily.geographical_summary.length - 1)){
                        additionalClasses += ' -end-location';
                    }

                    el.className = 'marker marker-' + markerData.id + ' -marker-highlighted -trip' + additionalClasses;
                }else {
                    el.className = 'marker marker-' + markerData.id + ' -marker-highlighted -trip';
                }

                el.setAttribute("data-pnp-marker-id", markerData.id);
                el.setAttribute("data-pnp-marker-lat", markerData.lat);
                el.setAttribute("data-pnp-marker-lng", markerData.lng);
                el.addEventListener('click', this.tripMarkerClickHandler);

                // add marker to map
                var marker = new mapboxgl.Marker(el)
                    .setLngLat({
                        "lng": markerData.lng,
                        "lat": markerData.lat
                    })
                    .addTo(this.map);

                this.mapItems[markerData.id] = {
                    "marker": marker,
                    "markerData": markerData,
                    "element": el
                };
            }

            if (Object.keys(this.mapItems).length > 1) {

                var bounds = new mapboxgl.LngLatBounds();
                var animationDuration = 3000;

                for (var i in this.mapItems) {
                    bounds.extend(this.mapItems[i].marker._lngLat);
                }

                if(isTripPage){

                    this.map.fitBounds(bounds, {
                        padding: {
                            left: 500,
                            bottom: 500,
                            top:500,
                            right: 500
                        }, duration: 0
                    });

                    this.map.fitBounds(bounds, {
                        padding: {
                            left: pnpHelper.bootstrap.isLg() ? 250 : 100,
                            bottom: pnpHelper.bootstrap.isLg() ? 250 : 100,
                            top: pnpHelper.bootstrap.isLg() ? 250 : 100,
                            right: pnpHelper.bootstrap.isLg() ? 250 : 100
                        }, duration: 5000
                    });

                    setTimeout(function () {
                        this.disablePopover = false;
                        window.bus.$emit('tripSummaryRendered');
                    }, 5000);

                }else {
                    var animationDuration = 3000;
                    this.map.fitBounds(bounds, {
                        padding: {
                            left: pnpHelper.bootstrap.isLg() ? 250 : 100,
                            bottom: pnpHelper.bootstrap.isLg() ? 250 : 100,
                            top: pnpHelper.bootstrap.isLg() ? 250 : 100,
                            right: pnpHelper.bootstrap.isLg() ? 250 : 100
                        }, duration: animationDuration
                    });

                    setTimeout(function () {
                        this.disablePopover = false;
                        window.bus.$emit('tripSummaryRendered');
                    }, animationDuration);
                }

            }else if (Object.keys(this.mapItems).length === 1) {

                this.map.flyTo({
                    center: this.mapItems[Object.keys(this.mapItems)[0]].marker._lngLat,
                    duration: 3000,
                    zoom: 5
                });

            } else {
                console.warn('No map markers found');
            }

            //Draw lines between markers
            if(this.mapLoaded && !isTripFamily) {
                this.map.addLayer({
                    "id": "route",
                    "type": "line",
                    "source": {
                        "type": "geojson",
                        "data": {
                            "type": "Feature",
                            "properties": {},
                            "geometry": {
                                "type": "LineString",
                                "coordinates": lineCoords
                            }
                        }
                    },
                    "layout": {
                        "line-join": "round",
                        "line-cap": "round"
                    },
                    "paint": {
                        "line-color": "rgba(136, 136, 136, 0)",
                        "line-width": 2,
                    }
                });

                setTimeout(function () {
                    var timeout = 10;
                    //Fade animate the line in
                    for (let i = 1; i <= 100; i++) {
                        setTimeout(function () {
                            if (_this.map.getLayer('route')) {
                                _this.map.setPaintProperty('route', 'line-color', 'rgba(136, 136, 136, ' + (i / 100) + ')');
                            }
                        }, timeout * i);
                    }
                }, 1000);
            }
        }
    };

    focusOnMarker = function(markerId, setToCurrent){
        if(this.currentFocussedId !== markerId) {
            if (!markerId && this.mapItems) {
                for (var i in this.mapItems) {
                    markerId = i;
                    break;
                }
            }

            if (setToCurrent) {
                this.currentMarkerId = markerId;
            }

            this.undoFocusOnMarker();
            $('.marker-' + markerId).addClass('-marker-focussed').removeClass('-marker-highlighted');
            $('.marker-' + markerId).append("<div class='dot'></div><div class='dot-bubble'></div>");

            this.currentFocussedId = markerId;

            window.bus.$emit('focussedOnMarker', markerId);
        }
    };

    centerOnMarker = function(markerId, shiftUpForMob){
        if(!markerId){
            for (var i in this.mapItems) {
                markerId = i;
                break;
            }
        }

        if(this.mapItems[markerId]) {
            var options = {
                center: this.mapItems[markerId].marker._lngLat,
                duration: 3000
            };

            if (shiftUpForMob && !window.pnpHelper.bootstrap.isMd()) {
                options['offset'] = [0, -Math.abs(window.$window.outerHeight() / 5)];
            }

            if (this.mapItems[markerId]) {
                this.map.flyTo(options);
            }
        }
    };

    addSingleMarkerToMap = function(lng, lat){
        this.clearMap();

        var markerData = {
            lat: lat,
            lng: lng
        };

        // create a DOM element for the marker
        var el = document.createElement('div');
        el.className = 'marker marker-0 -marker-highlighted -marker-focussed';

        el.setAttribute("data-pnp-marker-id", 0);
        $(el).append("<div class='dot'></div><div class='dot-bubble'></div>");

        // add marker to map
        var marker = new mapboxgl.Marker(el)
            .setLngLat({
                "lng": lng,
                "lat": lat
            })
            .addTo(this.map);

        this.mapItems[0] = {
            "marker": marker,
            "markerData": markerData,
            "element": el
        };
    };

    undoFocusOnMarker = function(){
        $('.marker.-marker-focussed').removeClass('-marker-focussed').addClass('-marker-highlighted -no-trans').html('');
    };

    loadLodgePage = function(searchResultId){
        window.pnpHelper.map.hidePopover();

        var zoom;

        if(this.map.getZoom() === this.defaultMapZoom){
            zoom = 7;
        }else{
            // zoom = this.map.getZoom();
            zoom = pnpHelper.page.isLodgePage() ? 17 : 7;
        }

        var options = {
            duration: 4000,
            center: [this.mapItems[searchResultId].markerData.lng, this.mapItems[searchResultId].markerData.lat],
            zoom: zoom
        };

        //On mobile shift the vertical alignment of the marker higher
        if(!window.pnpHelper.bootstrap.isMd()){
            options['offset'] = [0, -Math.abs(window.$window.outerHeight()/5)];
        }

        this.focusOnMarker(searchResultId, true);

        this.map.flyTo(options);

        this.map.resize();
    };

}
