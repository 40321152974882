<template>
    <div ref="slider-container" class="fade-transition" :class="{'edit-mode' : safariObject.edit}">
        <div class="slider-transform-container" :style="transitionStyle" :class="{'no-transitions': !transitions}">
            <carousel :per-page="1" :navigation-enabled="true" :pagination-enabled="false" :mouse-drag="false"
                      @transitionEnd="transitionEnd"
                      :navigate-to="activeSlide">
                <slide><slot /></slide>
                <template v-for="section in safariObject.sections.data">
                    <slide :key="section.id">
                        <section-container
                                :key="section.id"
                                v-if="!section.hidden"
                                :section="section"
                                :edit="safariObject.edit"
                                :item_title="safariObject.title"
                                :firstVideo="safariObject.firstVideo"
                                :classes="'section'"
                                style="z-index: 1;"
                                :type="safariObjectType" />
                    </slide>
                </template>
            </carousel>

            <transition name="fade">
                <div class="next-slide-container -left" @click="goToPrevSlide" v-if="currentSlideNumber > 1">
                    <div class="-icon-container">
                        <i class="ion-ios-arrow-left"></i>
                    </div>
                </div>
            </transition>
        </div>

        <div class="next-slide-container" @click="goToNextSlide">
            <div class="text">
                <template v-if="currentSlideNumber > 1">
                    <div>{{ slideTitle }}</div>
                    <div>{{ currentSlideNumber - 1 }}/{{ totalSlides - 1 }}</div>
                </template>
            </div>

            <div class="-icon-container" :class="{'-yellow': !slidesEnd}">
                <i class="ion-ios-arrow-right"></i>
            </div>
        </div>

        <div class="scroll-btn-container" @click="scroll">
            <div class="single-scroll-btn" :class="{'-yellow': slidesEnd}">
                <i class="ion-ios-arrow-thin-down"></i>
            </div>
        </div>
    </div>
</template>

<script>
  import slide from '../../../libs/vue-carousel/src/Slide';
  import carousel from '../../../libs/vue-carousel/src/Carousel';

  export default {
        name: "NewCarousel",
        props: ['safariObject', 'safariObjectType'],
        components: {
          carousel,
          slide
        },
        data() {
            return {
                activeSlide: 0,
                currentChildSlide: 0,
                currentSlideNumber: 1,
                slidesEnd: false,
                transitionRight: false,
                transitions: true
            };
        },
        mounted() {
            window.bus.$on('goToNextSlide', () => {
                setTimeout(this.goToNextSlide, 50);
            });
            if(_.isUndefined(this.$route.query.edit) || this.$route.query.edit === '0') {
                window.addEventListener('keyup', this.goToNextSlide);
                window.addEventListener('keyup', this.goToPrevSlide);
            }
        },
        methods: {
            scroll() {
                window.bus.$emit('fullPageMoveSectionDown');
            },
            goToSlide(carouselSlideIndex, innerSlideIndex = null) {
                window.pnpHelper.imageGallery.closeGallery('up');

                let nextSlideGallery = $('.VueCarousel-slide').eq(carouselSlideIndex).find('[data-pnp-showing-gallery].component-image.-gallery');
                let nextSlideHasGallery = nextSlideGallery.length;

                this.activeSlide = carouselSlideIndex;
                this.currentSlideNumber = (carouselSlideIndex + $('.VueCarousel-slide:lt(' + carouselSlideIndex + ') .pnp-slide').length) + 1;

                if (nextSlideHasGallery) {
                    window.pnpHelper.imageGallery.initGallery(nextSlideGallery);

                    if (innerSlideIndex != null) {
                        window.pnpHelper.imageGallery.moveToImage(nextSlideGallery, innerSlideIndex);
                    }
                }
            },
            transitionEnd(){
                if(this.activeSlide == 0 && this.slidesEnd){
                    this.transitions = false;
                    this.transitionRight = false;
                } else {
                    this.transitions = true;
                }
            },
            goToPrevSlide(event) {
                if (!_.isUndefined(event) && event.type == 'keyup' && event.keyCode != 37) {
                    return;
                }

                this.transitions = true;
                this.slidesEnd = false;

                if (this.currentSlideNumber <= 1) {
                    this.goToSlide($('.VueCarousel-slide').length - 1);
                    return;
                }

                let currentSlide = $('.VueCarousel-slide-active');
                let prevSlide = currentSlide.prev();
                let currentSlideGallery = currentSlide.find('[data-pnp-showing-gallery].component-image.-gallery');
                let prevSlideGallery = prevSlide.find('[data-pnp-showing-gallery].component-image.-gallery');
                let slideHasGallery = currentSlideGallery.length;
                let prevSlideHasGallery = prevSlideGallery.length;
                let currentGallerySlideIndex = window.pnpHelper.imageGallery.getCurrentIndex();

                this.currentSlideNumber--;

                if (!slideHasGallery && !prevSlideHasGallery) {
                    // console.log('slide and prev slide have no gallery - going to prev slide');
                    this.activeSlide--;
                    return;
                }

                if (currentGallerySlideIndex > 0) {
                    // console.log('going back a gallery slide in current slide');
                    window.pnpHelper.imageGallery.moveToImage(currentSlideGallery, currentGallerySlideIndex - 1);
                    return;
                }

                if (currentGallerySlideIndex == 0) {
                    // console.log('bottom of current gallery - closing');
                    window.pnpHelper.imageGallery.closeGallery('up');
                    return;
                }

                if (prevSlideHasGallery) {
                    // console.log('at bottom of current gallery and prev slide has a gallery - moving to the end of that gallery');
                    window.pnpHelper.imageGallery.initGallery(prevSlideGallery);
                    window.pnpHelper.imageGallery.moveToEnd(prevSlideGallery);
                    this.activeSlide--;
                    return;
                }

                if (!prevSlideHasGallery) {
                    // console.log('previous slide has no gallery so just go to it');
                    this.activeSlide--;
                    return;
                }
            },
            goToNextSlide(event) {
                if (!_.isUndefined(event) && event.type == 'keyup' && event.keyCode != 39) {
                    return;
                }

                this.transitions = true;
                this.transitionRight = false;
                this.slidesEnd = false;

                if (this.currentSlideNumber >= this.totalSlides) {
                    $('.VueCarousel-slide .pnp-slide.-in').removeClass('-in');

                    this.transitionRight = true;

                    this.slidesEnd = true;
                    if(pnpHelper.bootstrap.isXs()){
                        this.goToSlide(1);
                    } else {
                        this.goToSlide(0);
                    }
                    return;
                }


                let currentSlide = $('.VueCarousel-slide-active');
                let gallery = currentSlide.find('[data-pnp-showing-gallery].component-image.-gallery');
                let slideHasGallery = gallery.length;
                let isGalleryInitalised = gallery.hasClass('-show-gallery');
                let currentGallerySlideIndex = window.pnpHelper.imageGallery.getCurrentIndex();
                let currentGallerySlide = currentGallerySlideIndex + 1;
                let numberOfGallerySlides = gallery.find('.component-image.pnp-slide').length;

                this.currentSlideNumber++;

                if (!slideHasGallery) {
                    // console.log('no gallery - going to next slide');
                    this.activeSlide++;
                    return;
                }

                if (!isGalleryInitalised) {
                    // console.log('has gallery initialising now...');
                    window.pnpHelper.imageGallery.initGallery(gallery);
                    window.pnpHelper.imageGallery.moveToImage(gallery, 0);
                    return;
                }

                if (currentGallerySlide < numberOfGallerySlides) {
                    // console.log('next gallery slide');
                    window.pnpHelper.imageGallery.moveToImage(gallery, currentGallerySlideIndex + 1);
                    return;
                }

                if (currentGallerySlide >= numberOfGallerySlides) {
                    // console.log('end of gallery going to next slide');
                    window.pnpHelper.imageGallery.closeGallery('down');
                    this.activeSlide++;
                    return;
                }
            },
        },
        computed: {
            slideTitle() {
                let slide = this.safariObject.sections.data[this.activeSlide - 1 < 0 ? 0 : this.activeSlide - 1];
                return _.isUndefined()['name'];
            },
            totalSlides() {
                return _.reduce(this.safariObject.allSections.data, (currentTotal, safariObject) => {
                    let numberOfVisibleSlides = 0;

                    if (!_.isUndefined(safariObject.slides.data) && safariObject.slides.data.length) {
                        numberOfVisibleSlides = _.filter(safariObject.slides.data, slide => !slide.hidden).length;
                    }

                    return currentTotal + 1 + numberOfVisibleSlides;
                }, 1);
            },
            transitionStyle(){
                let transition;

                if(this.activeSlide == 0 & !this.transitionRight){
                    transition = 'translateX(100%)';
                } else if(this.activeSlide == 0 & this.transitionRight){
                    transition = 'translateX(-100%)';
                } else {
                    transition = 'translateX(0)';
                }

                return { transform: transition }
            }
        },
        watch: {
            activeSlide(to, from){
                // console.log('to:',to, '  from:', from, '   ',  to > from);

                // if(from !== 0 && to > from){
                //     this.transitionRight = false;
                // } else {
                //     this.transitionRight = true;
                // }
            }
        }
    }
</script>

<style scoped>

</style>
