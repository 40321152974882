<template>
    <div class="component-image -large-caption" :class="outerClasses" v-bind="data_attrs">

        <image-large-caption-presentational v-if="components == null"
                                  :title="title"
                                  :text="text"
                                  :buttons="buttons"
                                  :lazy_load="lazy_load"
                                  :bg_img_url="bg_img_url"
                                  :is_last_slide="is_last_slide"
                                  :last_slide_text="last_slide_text">
        </image-large-caption-presentational>

        <div v-else class="slide">
            <image-large-caption-presentational :title="title"
                                      :text="text"
                                      :buttons="buttons"
                                      :lazy_load="lazy_load"
                                      :bg_img_url="bg_img_url"
                                      :is_last_slide="is_last_slide"
                                      :last_slide_text="last_slide_text">
            </image-large-caption-presentational>
        </div>

        <div v-if="components != null" v-for="component in components" class="slide">
            <component :is="component.name" :content="component.content"></component>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'components', 'data_attrs', '_class',
            'type', 'title', 'text', 'buttons', 'lazy_load',
            'bg_img_url', 'is_last_slide', 'last_slide_text'
        ],
        computed: {
            outerClasses: function() {
                var classes = '';

                if (this._class != null) {
                    classes += this._class;
                }

                if (this.type != 'slide') {
                    classes += ' section';
                }

                return classes;
            }
        }
    }
</script>