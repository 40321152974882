<template>
    <select>
        <slot></slot>
    </select>
</template>

<script>
    import select2 from 'select2';

    export default {
        props: ['data', 'options', 'value'],
        mounted: function () {
            var vm = this;
            var selectricOpts = { data: this.data };

            if(this.options){
                Object.assign(selectricOpts, {
                    minimumResultsForSearch: Infinity
                }, this.options);
            }else{
                Object.assign(selectricOpts, {
                    minimumResultsForSearch: Infinity
                });
            }

            //console.log(selectricOpts);

            $(this.$el)
            // init select2
                .select2(selectricOpts)
                .val(this.value)
                .trigger('change')
                // emit event on change.
                .on('change', function () {
                    var val = this.value;

                    if(parseInt(val) != 'NaN'){
                        val = parseInt(val);
                    }

                    vm.$emit('input', val);
                })
        },
        watch: {
            value: function (value) {
                // update value
                $(this.$el).val(value)
            },
            data: function (data) {
                var selectricOpts = { data: this.data };

                if(this.options){
                    Object.assign(selectricOpts, {
                        minimumResultsForSearch: Infinity
                    }, this.options);
                }

                // update options
                $(this.$el).empty().select2(selectricOpts)
            }
        },
        destroyed: function () {
            $(this.$el).off().select2('destroy')
        }
    }
</script>