import { render, staticRenderFns } from "./SingleTripIntelligence.vue?vue&type=template&id=716c8a28&"
import script from "./SingleTripIntelligence.vue?vue&type=script&lang=js&"
export * from "./SingleTripIntelligence.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports