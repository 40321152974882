var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"about-atr"},[_c('div',{staticClass:"top-section container"},[_vm._m(0),_c('div',{staticClass:"footer-section"},[_vm._m(1),_c('button-internal-presentational',{attrs:{"classes":"btn -main-cta -ripple","router_object":{path: _vm.hardcodedUrls.resources.gettingStarted}}},[_c('span',[_c('span',[_vm._v("Start Planning Your Safari")]),_c('i',{staticClass:"ion-ios-arrow-thin-right"})])])],1)]),_c('div',{staticClass:"reviews-content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"reviews-container"},[_c('div',{staticClass:"text-content"},[_c('h3',{staticClass:"heading"},[_vm._v("What our guests say")]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('button-internal-presentational',{attrs:{"classes":"btn -main-cta -ripple","router_object":{path: _vm.hardcodedUrls.resources.gettingStarted}}},[_c('span',[_c('span',[_vm._v("Start Planning Your Safari")]),_c('i',{staticClass:"ion-ios-arrow-thin-right"})])])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-content"},[_c('h3',{staticClass:"heading"},[_vm._v("About us")]),_c('p',[_vm._v("ATR is widely recognised as the world's leading tailor-made safari company. We will help you to find a more authentic, more exciting, better value safari. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"information-grid"},[_c('div',{staticClass:"information-item"},[_c('h4',{staticClass:"heading"},[_vm._v("1999")]),_c('p',[_vm._v("The year we started building great safaris")])]),_c('div',{staticClass:"information-item"},[_c('h4',{staticClass:"heading"},[_vm._v("Expert")]),_c('p',[_vm._v("Our safari experts are uncommonly knowledgable and experienced")])]),_c('div',{staticClass:"information-item"},[_c('h4',{staticClass:"heading"},[_vm._v("124,755")]),_c('p',[_vm._v("The number of superb safaris we have created")])]),_c('div',{staticClass:"information-item"},[_c('h4',{staticClass:"heading"},[_vm._v("Seamless")]),_c('p',[_vm._v("Arranging your trip should be almost as much fun as your safari")])]),_c('div',{staticClass:"information-item"},[_c('h4',{staticClass:"heading"},[_vm._v("Value")]),_c('p',[_vm._v("Your trip should cost you no more than if you booked it direct")])]),_c('div',{staticClass:"information-item"},[_c('h4',{staticClass:"heading"},[_vm._v("Tailored")]),_c('p',[_vm._v("Your trip will be perfectly tuned to meet your requirements")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reviews"},[_c('div',{staticClass:"review"},[_c('p',{staticClass:"review-content"},[_c('em',[_vm._v("“ATR are experts in their field and I wouldn't trust anyone else.”")])]),_c('div',{staticClass:"name-container"},[_c('strong',{staticClass:"review-name"},[_vm._v("Tara Stewart")]),_c('div',{staticClass:"stars"},[_c('strong',[_vm._v("★")]),_c('strong',[_vm._v("★")]),_c('strong',[_vm._v("★")]),_c('strong',[_vm._v("★")]),_c('strong',[_vm._v("★")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reviews"},[_c('div',{staticClass:"review"},[_c('p',{staticClass:"review-content"},[_c('em',[_vm._v("“We've also used many different travel organizers and agents, but one truly stands like a giraffe, head and shoulders above all others.”")])]),_c('div',{staticClass:"name-container"},[_c('strong',{staticClass:"review-name"},[_vm._v("Linda Goddard")]),_c('div',{staticClass:"stars"},[_c('strong',[_vm._v("★")]),_c('strong',[_vm._v("★")]),_c('strong',[_vm._v("★")]),_c('strong',[_vm._v("★")]),_c('strong',[_vm._v("★")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reviews"},[_c('div',{staticClass:"review"},[_c('p',{staticClass:"review-content"},[_c('em',[_vm._v("“ATR are honest in their appraisal of individual lodges, you can trust their judgement”")])]),_c('div',{staticClass:"name-container"},[_c('strong',{staticClass:"review-name"},[_vm._v("Graeme Gales")]),_c('div',{staticClass:"stars"},[_c('strong',[_vm._v("★")]),_c('strong',[_vm._v("★")]),_c('strong',[_vm._v("★")]),_c('strong',[_vm._v("★")]),_c('strong',[_vm._v("★")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"total-reviews"},[_c('div',{staticClass:"review-summary"},[_c('strong',[_vm._v("4.9")]),_c('div',{staticClass:"stars"},[_c('strong',[_vm._v("★")]),_c('strong',[_vm._v("★")]),_c('strong',[_vm._v("★")]),_c('strong',[_vm._v("★")]),_c('strong',[_vm._v("★")])])]),_c('strong',[_vm._v("70 Google Reviews")])])
}]

export { render, staticRenderFns }