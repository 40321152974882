<template>
    <div v-if="!isOldDevice" id="map" :class="classes"></div>

    <div v-else id="static-mapbox" class="mapbox-static-container vignette vignette-static-map" :style="styleObject">
        <div class="marker" :style="markerStyles">
            <div class="dot"></div>
            <!--<div class="dot-bubble"></div>-->
        </div>
    </div>
</template>

<script>
    import AtrMap from './../../../old/map';
    import GlobalVars from '@/global/GlobalVars';

    let previousArgId;

    export default {
        props: ['args', 'classes', 'lazy_load', 'type'],
        data() {
            return {
                mapWidth: 0,
                mapHeight: 0
            }
        },
        watch: {
            args() {
                if(window.pnpHelper.map && this.args && this.args[0].id && this.args[0].id != previousArgId && !this.isOldDevice) {
                    previousArgId = this.args[0].id;
                    window.pnpHelper.map.searchResultsToMarkers(this.args);
                    window.pnpHelper.map.loadLodgePage(this.args[0].id); // Focus on the first marker

                    // let typeId;
                    //
                    // console.log('arg watch');
                    //
                    // if (this.$store.state.search.criteria.params.type == 'lodge') {
                    //     typeId = GlobalVars.LODGE;
                    // } else if (this.$store.state.search.criteria.params.type == 'location') {
                    //     typeId = GlobalVars.LOCATION;
                    // }
                    //
                    // this.$store.dispatch(window.store.FETCH_AND_SET_MAP_MARKERS, typeId).then(() => {
                    //     window.pnpHelper.map.searchResultsToMarkers(this.$store.state.search.mapMarkers);
                    // });
                }
            },
            mapWidth(){
                this.setWidth();
            }
        },
        methods: {
            loadMap(){
                let zoom, typeId;

                if(this.args && this.args[0].zoom){
                   zoom = this.args[0].zoom;
                } else {
                   zoom = 3;
                }

                window.pnpHelper.map = new AtrMap(window.MAP_TYPES.search, 'map', zoom);

                // if (this.$store.state.search.criteria.params.type == 'lodge') {
                //     typeId = GlobalVars.LODGE;
                // } else if (this.$store.state.search.criteria.params.type == 'location') {
                //     typeId = GlobalVars.LOCATION;
                // }
                //
                // if (this.$store.state.search.criteria.params.type == 'lodge' || this.$store.state.search.criteria.params.type == 'location') {
                //
                //     this.$store.dispatch(window.store.FETCH_AND_SET_MAP_MARKERS, typeId).then(() => {
                //         console.log('map init');
                //
                //         window.pnpHelper.map.searchResultsToMarkers(this.$store.state.search.mapMarkers);
                //     });
                // }
            },
            setWidth(){
                this.mapWidth = $("#static-mapbox").width()/2
            },
            setHeight(){
                this.mapHeight = $("#static-mapbox").height()/2
            },
            setMapState(state){
                // console.log(state);
                if(pnpHelper.page.isDirectoryPage() || pnpHelper.page.isSearchPage()) {
                    this.$router.replace({query: Object.assign({}, this.$route.query, {map: state.lat + '|' + state.lng + '|' + state.zoom, popover: state.popover})});
                }
            }
        },
        computed: {
          url(){
              let lat = this.args[0].lat;
              let lng = this.args[0].lng;

              if(!lng  && !this.isOldDevice){
                  return false;
              }

              let imageUrl = 'https://africatravelresource.com/assets/img/marker-highlighted.png';
              let encodedUrl = encodeURIComponent(imageUrl);

              let zoomLevel = this.type === 'lodge' ? '12' : '6';

              let url = 'https://api.mapbox.com/styles/v1/james-pnp/ciz4aevzn006s2rp8yi28mstp/static/' +

              ''+ lng +','+ lat +','+ zoomLevel +',0,0/' +

              '1280x800@2x' +

              '?access_token='+window.MAP_KEY+'&logo=false';

              return lng ? url : '';
            },
            isOldDevice(){
              return false;
            },
            styleObject(){
              return {
                  'background-image': 'url("'+this.url+'")',
              }
            },
            markerStyles(){
              if(this.url === false && !this.isOldDevice) {
                  return;
              }
              return {
                  'transform' : 'translate(-50%, -50%) translate('+this.mapWidth+'px, '+this.mapHeight+'px)'
              }
            }
        },
        destroyed(){
            previousArgId = null;

            window.removeEventListener('resize', this.setWidth);
            window.removeEventListener('resize', this.setHeight);
            window.bus.$off('setMapState', this.setMapState);
        },
        created(){
            window.bus.$on('setMapState', this.setMapState);
        },
        mounted(){
            if (this.lazy_load && !this.isOldDevice){
                setTimeout(this.loadMap, 1500);
            } else if(!this.isOldDevice) {
                setTimeout(this.loadMap(), 100);
            }

            if(this.isOldDevice) {
                this.$nextTick(() => {
                    window.addEventListener('resize', this.setWidth);
                    window.addEventListener('resize', this.setHeight);

                    this.mapWidth = $("#static-mapbox").width()/2;
                    this.mapHeight = $("#static-mapbox").height()/2;
                });
            }
        }
    }
</script>