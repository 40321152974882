import vSelect from 'vue-select'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
/**
 * Search components
 */
Vue.component('search-sentence', require('../components/partials/container/SearchSentence').default);
Vue.component('search-location-popup', require('../components/partials/Search/LocationPopup').default);
Vue.component('search-type-popup', require('../components/partials/Search/TypePopup').default);
Vue.component('search-experience-popup', require('../components/partials/Search/ExperiencePopup').default);
Vue.component('search-month-popup', require('../components/partials/Search/MonthPopup').default);
Vue.component('search-nights-popup', require('../components/partials/Search/NightsPopup').default);
Vue.component('search-budget-popup', require('../components/partials/Search/BudgetPopup').default);
Vue.component('search-budget-range-popup', require('../components/partials/Search/BudgetRangePopup').default);
Vue.component('currency-popup', require('../components/partials/Search/CurrencyPopup').default);
Vue.component('search-lodge-card', require('../components/partials/presentational/SearchLodgeCard.vue').default);
Vue.component('search-experience-card', require('../components/partials/presentational/SearchExperienceCard.vue').default);
Vue.component('search-location-card', require('../components/partials/presentational/SearchLocationCard.vue').default);
Vue.component('search-experience-card', require('../components/partials/presentational/SearchExperienceCard.vue').default);
Vue.component('search-trip-card', require('../components/partials/presentational/SearchTripCard.vue').default);
Vue.component('directory-link', require('../components/partials/presentational/DirectoryLink.vue').default);
Vue.component('directory-search', require('../components/partials/presentational/AutoCompleteSearch.vue').default);

/** Get in touch*/
Vue.component('get-in-touch', require('../components/pages/GetInTouch.vue').default);
Vue.component('get-in-touch-step', require('../components/partials/contact/GetInTouchStep.vue').default);
Vue.component('call-method-step', require('../components/partials/contact/CallMethodStep.vue').default);
Vue.component('call-intro-step', require('../components/partials/contact/CallIntroStep.vue').default);
Vue.component('call-now-step', require('../components/partials/contact/CallNowStep.vue').default);
Vue.component('choose-country-step', require('../components/partials/contact/ChooseCountryStep.vue').default);
Vue.component('select-dates-step', require('../components/partials/contact/SelectDatesStep.vue').default);
Vue.component('duration-step', require('../components/partials/contact/DurationStep.vue').default);
Vue.component('guests-step', require('../components/partials/contact/GuestsStep.vue').default);
Vue.component('budget-step', require('../components/partials/contact/BudgetStep.vue').default);
Vue.component('have-we-met-step', require('../components/partials/contact/HaveWeMetStep.vue').default);
Vue.component('your-details-step', require('../components/partials/contact/YourDetailsStep.vue').default);
Vue.component('contact-method-step', require('../components/partials/contact/ContactMethodStep.vue').default);
Vue.component('tell-us-more-step', require('../components/partials/contact/TellUsMoreStep.vue').default);
Vue.component('final-step', require('../components/partials/contact/FinalStep.vue').default);
Vue.component('call-request-details-step', require('../components/partials/contact/CallRequestDetailsStep.vue').default);
Vue.component('trade-enquiry-step', require('../components/partials/contact/TradeEnquiryStep.vue').default);
Vue.component('header-progress', require('../components/partials/contact/HeaderProgress.vue').default);
Vue.component('multi-step-form', require('../components/partials/contact/MultiStepForm.vue').default);


/** Pages */
Vue.component('single-new-resource', require('../components/pages/NewSingleResource.vue').default);
Vue.component('new-carousel', require('../components/partials/presentational/NewCarousel.vue').default);
Vue.component('shortcodes', require('../components/partials/presentational/Shortcodes.vue').default);

// Vue.component('public-lodge', require('../components/public/Lodge.vue').default);
Vue.component('single-lodge', require('../components/pages/SingleLodge.vue').default);
Vue.component('single-trip', require('../components/pages/SingleTrip.vue').default);
Vue.component('single-quote', require('../components/pages/SingleQuote.vue').default);
Vue.component('single-location', require('../components/pages/SingleLocation.vue').default);
Vue.component('single-resource', require('../components/pages/SingleResource.vue').default);
Vue.component('single-article', require('../components/pages/SingleArticle.vue').default);
Vue.component('single-trip-intelligence', require('../components/pages/SingleTripIntelligence.vue').default);
Vue.component('single-experience', require('../components/pages/SingleExperience.vue').default);
Vue.component('public-search', require('../components/pages/Search.vue').default);
Vue.component('home-page', require('../components/pages/HomePage.vue').default);
Vue.component('public-search-results', require('../components/public/SearchResults.vue').default);
Vue.component('public-search-item', require('../components/public/SearchItem.vue').default);
Vue.component('start-page', require('../components/pages/StartPage.vue').default);
Vue.component('contact-page', require('../components/pages/ContactPage.vue').default);
Vue.component('explore', require('../components/pages/Explore.vue').default);
Vue.component('call-page', require('../components/pages/CallPage.vue').default);
Vue.component('callback-page', require('../components/pages/CallBackPage.vue').default);
Vue.component('quote-page', require('../components/pages/QuotePage.vue').default);
Vue.component('inquire-page', require('../components/pages/InquirePage.vue').default);
Vue.component('email-page', require('../components/pages/EmailPage.vue').default);
Vue.component('directory', require('../components/pages/Directory.vue').default);
Vue.component('page404', require('../components/pages/404.vue').default);

Vue.component('atr-fullpage', require('../components/partials/AtrFullpage.vue').default);

/** Container/clever components */
Vue.component('image-large-caption-static-container', require('../components/partials/container/ImageLargeCaptionStatic.vue').default);
Vue.component('image-large-caption-container', require('../components/partials/container/ImageLargeCaption.vue').default);
Vue.component('section-container', require('../components/partials/container/Section.vue').default);
Vue.component('section-outs-container', require('../components/partials/container/SectionOuts.vue').default);
Vue.component('slide-container', require('../components/partials/container/Slide.vue').default);
Vue.component('button-container', require('../components/partials/container/Button.vue').default);
Vue.component('map-popover-portal', require('../components/partials/container/MapPopoverPortal.vue').default);

/** Presentational/dumb components */
Vue.component('article-presentational', require('../components/partials/presentational/Article.vue').default);
Vue.component('article-image-presentational', require('../components/partials/presentational/ArticleImage.vue').default);
Vue.component('article-card', require('../components/partials/presentational/ArticleCard.vue').default);
Vue.component('button-action-presentational', require('../components/partials/presentational/ButtonAction.vue').default);
Vue.component('button-back-presentational', require('../components/partials/presentational/ButtonBack.vue').default);
Vue.component('button-external-presentational', require('../components/partials/presentational/ButtonExternal.vue').default);
Vue.component('buttons-loop', require('../components/partials/presentational/ButtonsLoop.vue').default);
Vue.component('button-internal-presentational', require('../components/partials/presentational/ButtonInternal.vue').default);
Vue.component('button-grid', require('../components/partials/presentational/ButtonGrid.vue').default);
Vue.component('button-social', require('../components/partials/presentational/ButtonSocial.vue').default);
Vue.component('grid-image', require('../components/partials/presentational/GridImage.vue').default);
Vue.component('global-search', require('../components/partials/presentational/GlobalSearch.vue').default);
Vue.component('global-search-card', require('../components/partials/presentational/GlobalSearchCard.vue').default);
Vue.component('image-large-caption-static-presentational', require('../components/partials/presentational/ImageLargeCaptionStatic.vue').default);
Vue.component('image-large-caption-presentational', require('../components/partials/presentational/ImageLargeCaption.vue').default);
Vue.component('lodge-card', require('../components/partials/presentational/LodgeCard.vue').default);
Vue.component('lodge-select', require('../components/partials/presentational/LodgeSelect.vue').default);
Vue.component('lodge-price-table', require('../components/partials/presentational/LodgePriceTable.vue').default);
Vue.component('map-presentational', require('../components/partials/presentational/Map.vue').default);
Vue.component('modal-presentational', require('../components/partials/presentational/Modal.vue').default);
Vue.component('modal-global', require('../components/partials/presentational/ModalGlobal.vue').default);
Vue.component('modal-body', require('../components/partials/presentational/ModalBody.vue').default);
Vue.component('modal-button', require('../components/partials/presentational/ModalButton.vue').default);
Vue.component('modal-presentational', require('../components/partials/presentational/Modal.vue').default);
Vue.component('mute-button', require('../components/partials/presentational/MuteButton.vue').default);
Vue.component('nav-bar', require('../components/partials/presentational/NavBar.vue').default);
Vue.component('next-up-presentational', require('../components/partials/presentational/NextUp.vue').default);
Vue.component('next-up-half-page', require('../components/partials/presentational/NextUpHalfPage.vue').default);
Vue.component('section-presentational', require('../components/partials/presentational/Section.vue').default);
Vue.component('section-outs-presentational', require('../components/partials/presentational/SectionOuts.vue').default);
Vue.component('section-intro-presentational', require('../components/partials/presentational/SectionIntro.vue').default);
Vue.component('sidenav-presentational', require('../components/partials/presentational/Sidenav.vue').default);
Vue.component('slide-presentational', require('../components/partials/presentational/Slide.vue').default);
Vue.component('vignette-presentational', require('../components/partials/presentational/Vignette.vue').default);
Vue.component('video-player', require('../components/partials/presentational/Video.vue').default);
Vue.component('welcome', require('../components/partials/presentational/Welcome.vue').default);
Vue.component('wishlist-button', require('../components/partials/presentational/WishlistButton.vue').default);
Vue.component('wishlist-add-presentational', require('../components/partials/presentational/WishlistAdd.vue').default);
Vue.component('wishlist', require('../components/partials/presentational/Wishlist.vue').default);
Vue.component('alternatives', require('../components/partials/presentational/Alternatives.vue').default);
Vue.component('sticky-bar', require('../components/partials/presentational/StickyBar.vue').default);
Vue.component('currency-selector', require('../components/partials/presentational/CurrencySelector.vue').default);
Vue.component('transition-wipe', require('../components/partials/presentational/TransitionWipe.vue').default);
Vue.component('outs-list', require('../components/partials/presentational/OutsList.vue').default);
Vue.component('outs-wishlist', require('../components/partials/presentational/OutsWishlist.vue').default);
Vue.component('best-trips-link', require('../components/partials/presentational/BestTripsLink.vue').default);
Vue.component('best-trip-out', require('../components/partials/presentational/BestTripOut.vue').default);
Vue.component('half-page-scroll-hijack', require('../components/partials/presentational/HalfPageScrollHijack.vue').default);
Vue.component('price', require('../components/partials/presentational/Price.vue').default);
Vue.component('info-container', require('../components/partials/presentational/InfoContainer.vue').default);
Vue.component('info-popup', require('../components/partials/presentational/InfoPopup.vue').default);
Vue.component('contact-form-error', require('../components/partials/presentational/ContactFormError.vue').default);
Vue.component('mob-popup-header', require('../components/partials/presentational/MobPopupHeader.vue').default);
Vue.component('modal-nav', require('../components/partials/presentational/ModalNav.vue').default);
Vue.component('auto-complete-search', require('../components/partials/presentational/AutoCompleteSearch.vue').default);
Vue.component('map-popover', require('../components/partials/presentational/MapPopover.vue').default);
Vue.component('explore-btn', require('../components/partials/presentational/ExploreButton.vue').default);
Vue.component('contact-btn', require('../components/partials/presentational/ContactButton.vue').default);
Vue.component('call-menu', require('../components/partials/presentational/CallMenu.vue').default);
Vue.component('site-footer', require('../components/partials/footer/Footer.vue').default)
Vue.component('about-atr', require('../components/partials/footer/AboutAtr.vue').default);
Vue.component('locations-list', require('../components/partials/footer/LocationsList.vue').default);
Vue.component('trip-builder-cta', require('../components/partials/footer/TripBuilderCta.vue').default); 

/** Transitions */
Vue.component('fade-in', require('../components/partials/transitions/FadeIn.vue').default);

/** Admin Data */
Vue.component('acp-urlmapper', require('../components/acp/UrlMapper.vue').default);

/** Public pages */
Vue.component('public-directory-listing', require('../components/partials/DirectoryListing.vue').default);
Vue.component('public-editor-buttons', require('../components/partials/EditorButtons.vue').default);
Vue.component('public-editor-links', require('../components/partials/EditorLinks.vue').default);
Vue.component('public-editor-meta', require('../components/partials/EditMeta.vue').default);
Vue.component('public-select2', require('../components/partials/Select2.vue').default);
Vue.component('public-getting-started-note', require('../components/partials/GettingStartedNote.vue').default);
//Vue.component('public-seasonality', require('../components/partials/Seasonality.vue').default);
Vue.component('public-seasonality-facet-graph', require('../components/partials/SeasonalityFacetGraph.vue').default);
Vue.component('public-seasonality-overview-graph', require('../components/partials/SeasonalityOverviewGraph.vue').default);
Vue.component('public-search-month-graph', require('../components/partials/SearchMonthGraph.vue').default);
Vue.component('public-search-image-slider', require('../components/partials/SearchImageSlider.vue').default);
Vue.component('public-edit-mode-toggle', require('../components/partials/EditModeToggle.vue').default);
Vue.component('public-resource-listing', require('../components/partials/ResourceListing.vue').default);

/** Libs */
Vue.component('slide-up-down', require('vue-slide-up-down').default);
Vue.component('v-select', vSelect);
Vue.use(VueTelInput);
