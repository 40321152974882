<template>
    <div @click.prevent="clicked" class="global-modal-btn">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: ['div_id'],
        methods: {
            clicked() {
                window.bus.$emit('open_modal_' + this.div_id);
            }
        }
    }
</script>