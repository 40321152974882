<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
  import * as constants from './constants';
  import {
    camelToKebab,
  } from './helpers';

  import fullPageMixin from './fullPageMixin';

/**
 * vue-fullpage.js
 * https://github.com/alvarotrigo/vue-fullpage.js
 * @license https://github.com/alvarotrigo/vue-fullpage.js#license
 *
 * Copyright (C) 2018 alvarotrigo.com - A project by Alvaro Trigo
 */
  export default {
    mixins: [fullPageMixin],
    props: {
      options: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },

    data() {
      return {
        events: constants.EVENTS.reduce((eventsHandlers, event) => {
          return {
            ...eventsHandlers,

            [event]: (...args) => {
              return this.emitEvent(event, args);
            }
          };
        }, {}),
      }
    },

    watch: {
      options: {
        deep: true,
        handler() {
          this.update();
        },
      },
    },

    mounted() {
      // this.update();
    },

    methods: {
      destroy() {
        if(typeof $.fn.fullpage.destroy !== 'undefined'){
            $.fn.fullpage.destroy('all');
        }
      },

      emitEvent(name, args) {
        // Emit event on Vue way
        this.$emit.apply(this, [camelToKebab(name), ...args]);

        // // Run event's handler with non Vue way
        if (this.options.hasOwnProperty(name)) {
          this.options[name].apply($('.fp-section.active'), args);
        }
      },

      update() {
          $(this.$el).fullpage({
              ...this.events,
              ...this.options,
          });
      },
    },
  };
</script>