import GlobalVars from "./global/GlobalVars";

Vue.filter('monthToString', function (value) {
    if(value == 0){
        return "any month";
    }else if(value >= 1 && value <=12){
        return GlobalVars.MONTHS[parseInt(value-1)];
    }else{
        console.warn('monthToString filter: ' + value + ' is not a valid month.');
        return '';
    }
});

Vue.filter('striphtml', function (value) {
    let div = document.createElement("div");
    div.innerHTML = value;
    return div.textContent || div.innerText || "";
});

Vue.filter('filterWords', function (content) {
    _.forEach(window.CONTENT_WORD_DICTIONARY, (value) => {
        content = content.replace(new RegExp(value.old, 'g'), value.new);
    });

    return content;
});
