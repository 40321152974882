export default class PayloadParser {
    constructor(payload) {
        this.dataPayload = payload;
    }

    process() {
        var payload = window._.defaultsDeep({}, this.dataPayload);
        payload.hiddenSections = {};
        payload.hiddenSections.data = [];
        payload.sections = {};
        payload.sections.data = [];
        if (payload.allSections != undefined && payload.allSections.data) {
            for (var x in payload.allSections.data) {
                if (payload.allSections.data[x].hidden === 1) {
                    payload.hiddenSections.data.push(payload.allSections.data[x]);
                } else {
                    if (!_.isUndefined(payload.allSections.data[x].allSlides) && !_.isUndefined(payload.allSections.data[x].allSlides.data)) {
                        payload.allSections.data[x].hiddenSlides = {};
                        payload.allSections.data[x].hiddenSlides.data = [];
                        payload.allSections.data[x].slides = {};
                        payload.allSections.data[x].slides.data = [];
                        for (var t in payload.allSections.data[x].allSlides.data) {
                            if (payload.allSections.data[x].allSlides.data[t].hidden === 1) {
                                payload.allSections.data[x].hiddenSlides.data.push(window._.defaultsDeep({}, payload.allSections.data[x].allSlides.data[t]));
                            } else {
                                payload.allSections.data[x].slides.data.push(window._.defaultsDeep({}, payload.allSections.data[x].allSlides.data[t]));
                            }
                        }
                    }
                    payload.sections.data.push(payload.allSections.data[x]);
                }
            }
        }
        return payload;
    }

    extractArticles() {
        var articles = [];
        //var articlesUnique = [];
        if (!window._.isUndefined(this.dataPayload.allSections.data)) {
            for (var x in this.dataPayload.allSections.data) {
                if (!window._.isUndefined(this.dataPayload.allSections.data[x].actions)) {
                    for (var y in this.dataPayload.allSections.data[x].actions) {
                        if (this.dataPayload.allSections.data[x].actions[y].type === 'article') {
                            //if(!window._.includes(articlesUnique, this.dataPayload.allSections.data[x].actions[y].media)) {
                                let art = {
                                    id: this.dataPayload.allSections.data[x].actions[y].media,
                                    title: this.dataPayload.allSections.data[x].actions[y].article.title,
                                    content: this.dataPayload.allSections.data[x].actions[y].article.content,
                                    action: this.dataPayload.allSections.data[x].actions[y].action
                                };
                                articles.push(art);
                                //articlesUnique.push(this.dataPayload.allSections.data[x].actions[y].media);
                           // }
                        }
                    }
                }
                if (!window._.isUndefined(this.dataPayload.allSections.data[x].allSlides.data)) {
                    for (var y in this.dataPayload.allSections.data[x].allSlides.data) {
                        if (!window._.isUndefined(this.dataPayload.allSections.data[x].allSlides.data[y].actions)) {
                            for(var t in this.dataPayload.allSections.data[x].allSlides.data[y].actions){
                                if (this.dataPayload.allSections.data[x].allSlides.data[y].actions[t].type === 'article') {
                                    //if(!window._.includes(articlesUnique, this.dataPayload.allSections.data[x].allSlides.data[y].actions[t].media)) {
                                        let art = {
                                            id: this.dataPayload.allSections.data[x].allSlides.data[y].actions[t].media,
                                            title: this.dataPayload.allSections.data[x].allSlides.data[y].actions[t].article.title,
                                            content: this.dataPayload.allSections.data[x].allSlides.data[y].actions[t].article.content,
                                            action: this.dataPayload.allSections.data[x].allSlides.data[y].actions[t].action
                                        };
                                        articles.push(art);
                                       //articlesUnique.push(this.dataPayload.allSections.data[x].allSlides.data[y].actions[t].media);
                                  //  }

                                }
                            }
                        }
                    }
                }
            }
        }
        return articles;
    }
}