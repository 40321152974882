import LinkHashes from "./LinkHashes";

export default class InternalLinkWatcher
{
    constructor(vueRouter) {
        this.vueRouter = vueRouter;

        /** setTimeout to allow Vue to initialise */
        setTimeout(() => {
            /** Hook into document rather than direct xPath this allows us to avoid rebinding each time */
            $(document).on('click', (e) => {
                let activeElement = e.delegateTarget.activeElement;

                if (!_.isUndefined(activeElement.hash) && activeElement.hash != '') {
                    LinkHashes.scrollTo(activeElement.hash, e);
                } else {
                    return activeElement.localName === 'a' ? !this.maybeRedirect(activeElement.href) : true;
                }
            });
        }, 1000);
    }

    maybeRedirect(location) {
        let urlObj = new URL(location);

        if (this.isLinkInternal(urlObj) === true) {
            this.vueRouter.push({
                path: urlObj.pathname
            });

            return true;
        }

        return false;
    }

    /** Private */
    isLinkInternal(urlObj) {
        //console.log(urlObj);
        return urlObj.hostname === window.location.hostname && _.isEmpty(urlObj.search);
    }
}