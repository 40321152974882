<template>
    <div>
        <section-intro-presentational>
            <div class="component-text -lodge-info home-page-section vh-fix-once ">
                <div class="fp-bg full-size pnp-bg vignette vignette1 hidden-xs"
                     :style="'background-image:url(\'assets/img/temp/image1_lion.jpg\');'">
                </div>

                <div class="fp-bg full-size pnp-bg vignette vignette1 visible-xs"
                     :style="'background-image:url(\'assets/img/temp/image1_lion_mob.jpg\');'">
                </div>

                <div class="inner"> <!--TODO:Add the -in class on a delay so we see the animation on page load-->
                    <div class="inner-container">
                        <div class="width-container">
                            <h1 class="heading3 heading custom-section-title">The world’s leading tailor-made safari company</h1>

                            <div class="sub-heading">
                                Helping you to find a more authentic, more exciting and better value safari
                            </div>
                        </div>
                    </div>

                    <div class="scroll-btn-container ">
                        <div class="home-scroll-btn" @click="scroll">
                            <i class="ion-ios-arrow-thin-down"></i>
                        </div>
                    </div>
                </div>
            </div>
        </section-intro-presentational>

        <!-- <section class="container" id="welcome-section">
            <div class="row">
                <div class="col-sm-12">
                
                  <grid-image
                            image_url="assets/img/temp/mountain-herd.jpg"
                            :router_object="{ path: hardcodedUrls.resources.gettingStarted }"
                            text=""
                            zoom="central">

                        <div class="home-contact">
                            <h2 class="contact-title">Welcome</h2>

                            <p class="contact-text">A quick introduction to who we are and what we do</p>

                            <div class="buttons contact-buttons">

                                <button-internal-presentational
                                        classes="btn -main-cta -ripple"
                                        :router_object="{ path: hardcodedUrls.resources.gettingStarted }">
                                    <span><span>Start</span> <i class="ion-ios-arrow-thin-right"></i></span>
                                </button-internal-presentational>
                            </div>
                        </div>
                    </grid-image>
                </div>
            </div>
        </section>

        <hr class="divider mob-divider"> -->

        <!-- Best Trips -->
        <section class="container pop-locations">
            <div class="home-headings">
                <h3 class="header">Sample trips to Africa</h3>
                <p>Inspiration for an incredibly diverse range of trips, including all the key safari areas</p>
            </div>

            <div class="grid-image-container">
                <div class="row">
                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.botswana}"
                                    image_url="assets/img/new-homepage/botswana.jpg"
                                    mob_image_url="assets/img/new-homepage/botswana.jpg"
                                    text="Botswana">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.congo}"
                                    image_url="assets/img/new-homepage/congo.jpg"
                                    mob_image_url="assets/img/new-homepage/congo.jpg"
                                    text="Congo">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.egypt, params: {showOuts: false}}"
                                    image_url="assets/img/new-homepage/egypt.jpg"
                                    mob_image_url="assets/img/new-homepage/egypt.jpg"
                                    text="Egypt">
                        </grid-image>
                    </div>

                    <!---->

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.kenya}"
                                    image_url="assets/img/new-homepage/kenya.jpg"
                                    mob_image_url="assets/img/new-homepage/kenya.jpg"
                                    text="Kenya">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.malawi}"
                                    image_url="assets/img/new-homepage/malawi.jpg"
                                    mob_image_url="assets/img/new-homepage/malawi.jpg"
                                    text="Malawi">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.madagascar}"
                                    image_url="assets/img/new-homepage/madagascar.jpg"
                                    mob_image_url="assets/img/new-homepage/madagascar.jpg"
                                    text="Madagascar">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.namibia}"
                                    image_url="assets/img/new-homepage/namibia.jpg"
                                    mob_image_url="assets/img/new-homepage/namibia.jpg"
                                    text="Namibia">
                        </grid-image>
                    </div>

                    <!---->

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.rwanda}"
                                    image_url="assets/img/new-homepage/rwanda.jpg"
                                    mob_image_url="assets/img/new-homepage/rwanda.jpg"
                                    text="Rwanda">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.southAfrica}"
                                    image_url="assets/img/new-homepage/south-africa.jpg"
                                    mob_image_url="assets/img/new-homepage/south-africa.jpg"
                                    text="South Africa">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.uganda}"
                                    image_url="assets/img/new-homepage/uganda.jpg"
                                    mob_image_url="assets/img/new-homepage/uganda.jpg"
                                    text="Uganda">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.zambia}"
                                    image_url="assets/img/new-homepage/zambia.jpg"
                                    mob_image_url="assets/img/new-homepage/zambia.jpg"
                                    text="Zambia">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.zimbabwe}"
                                    image_url="assets/img/new-homepage/zimbabwe.jpg"
                                    mob_image_url="assets/img/new-homepage/zimbabwe.jpg"
                                    text="Zimbabwe">
                        </grid-image>
                    </div>
                </div>
            </div>
        </section>
        <section class="container pop-locations">
            <div class="home-headings">
                <h3 class="header">Sample trips to Antarctica, Asia and South America</h3>
                <p>Applying that same authentic safari ethos to some of the world's most exciting countries</p>
            </div>

            <div class="grid-image-container">
                <div class="row">
                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.antarctica}"
                                    image_url="assets/img/new-homepage/antarctica.jpg"
                                    mob_image_url="assets/img/new-homepage/antarctica.jpg"
                                    text="Antarctica">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.argentina}"
                                    image_url="assets/img/new-homepage/argentina.jpg"
                                    mob_image_url="assets/img/new-homepage/argentina.jpg"
                                    text="Argentina">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.bhutan, params: {showOuts: false}}"
                                    image_url="assets/img/new-homepage/bhutan.jpg"
                                    mob_image_url="assets/img/new-homepage/bhutan.jpg"
                                    text="Bhutan">
                        </grid-image>
                    </div>

                    <!---->

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.bolivia}"
                                    image_url="assets/img/new-homepage/bolivia.jpg"
                                    mob_image_url="assets/img/new-homepage/bolivia.jpg"
                                    text="Bolivia">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.brazil}"
                                    image_url="assets/img/new-homepage/brazil.jpg"
                                    mob_image_url="assets/img/new-homepage/brazil.jpg"
                                    text="Brazil">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.chile}"
                                    image_url="assets/img/new-homepage/chile.jpg"
                                    mob_image_url="assets/img/new-homepage/chile.jpg"
                                    text="Chile">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.ecuador}"
                                    image_url="assets/img/new-homepage/ecuador.jpg"
                                    mob_image_url="assets/img/new-homepage/ecuador.jpg"
                                    text="Equador">
                        </grid-image>
                    </div>

                    <!---->

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.galapagos}"
                                    image_url="assets/img/new-homepage/galapagos.jpg"
                                    mob_image_url="assets/img/new-homepage/galapagos.jpg"
                                    text="Galapagos">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.india}"
                                    image_url="assets/img/new-homepage/india.jpg"
                                    mob_image_url="assets/img/new-homepage/india.jpg"
                                    text="India">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.maldives}"
                                    image_url="assets/img/new-homepage/maldives.jpg"
                                    mob_image_url="assets/img/new-homepage/maldives.jpg"
                                    text="Maldives">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.peru}"
                                    image_url="assets/img/new-homepage/peru.jpg"
                                    mob_image_url="assets/img/new-homepageperu.jpg"
                                    text="Peru">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-2">
                        <grid-image :router_object="{path: hardcodedUrls.trips.sriLanka}"
                                    image_url="assets/img/new-homepage/sri-lanka.jpg"
                                    mob_image_url="assets/img/new-homepage/sri-lanka.jpg"
                                    text="Sri Lanka">
                        </grid-image>
                    </div>
                </div>
            </div>
        </section>


        <!-- Best Lodges -->
        <section class="container lodges">
            <div class="home-headings">
                <h3 class="header">Best lodges</h3>
                <p>Discover the lodges in Africa which will best fit into your trip …</p>
            </div>

            <div class="grid-image-container">
                <div class="row">
                    <div class="col-sm-6 col-md-4 short-grid">
                        <grid-image :router_object="{
                                        name: 'finder',
                                        query: {
                                            type: 'lodge',
                                            location: 155,
                                            lname: 'Zambia',
                                            experience: 8,
                                            ename: 'Leopards'
                                        }
                                    }"
                                    image_url="assets/img/temp/best-lodges/leopard.jpg"
                                    mob_image_url="assets/img/temp/best-lodges/mob/leopard.jpg"
                                    text="The best lodges in Zambia for leopards">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 short-grid">
                        <grid-image :router_object="{
                                        name: 'finder',
                                        query: {
                                            type: 'lodge',
                                            location: 7061,
                                            lname: 'India',
                                            experience: 107,
                                            ename: 'Tigers'
                                        }
                                    }"
                                    image_url="assets/img/temp/best-lodges/tiger.jpg"
                                    mob_image_url="assets/img/temp/best-lodges/tiger.jpg"
                                    text="The best lodges in India for tigers">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 short-grid">
                        <grid-image :router_object="{
                                        name: 'finder',
                                        query: {
                                            type: 'lodge',
                                            location: 6483,
                                            lname: 'Argentina',
                                            experience: 37,
                                            ename: 'Hiking'
                                        }
                                    }"
                                    image_url="assets/img/temp/best-lodges/hiking.jpg"
                                    mob_image_url="assets/img/temp/best-lodges/hiking.jpg"
                                    text="The best lodges in Argentina for hiking">
                        </grid-image>
                    </div>

                    <!---->

                    <div class="col-sm-6 col-md-4 short-grid">
                        <grid-image :router_object="{
                                        name: 'finder',
                                        query: {
                                            type: 'lodge',
                                            location: 125,
                                            lname: 'Tanzania',
                                            experience: 12,
                                            ename: 'Migration'
                                        }
                                    }"
                                    image_url="assets/img/temp/best-lodges/migrations.jpg"
                                    mob_image_url="assets/img/temp/best-lodges/migrations.jpg"
                                    text="The best lodges in Tanzania for migration">
                        </grid-image>
                    </div>
                    <div class="col-sm-6 col-md-4 short-grid">
                        <grid-image :router_object="{
                                        name: 'finder',
                                        query: {
                                            type: 'lodge',
                                            location: 1,
                                            lname: 'Botswana',
                                            experience: 6,
                                            ename: 'Elephants'
                                        }
                                    }"
                                    image_url="assets/img/temp/best-lodges/elephant-tusk.jpg"
                                    mob_image_url="assets/img/temp/best-lodges/elephant-tusk.jpg"
                                    text="The best lodges in Botswana for elephants">
                        </grid-image>
                    </div>
                    <div class="col-sm-6 col-md-4 short-grid">
                        <grid-image :router_object="{
                                        name: 'finder',
                                        query: {
                                            type: 'lodge',
                                            location: 157,
                                            lname: 'Zimbabwe',
                                            experience: 71,
                                            ename: 'Walking Safari'
                                        }
                                    }"
                                    image_url="assets/img/temp/best-lodges/walking-safari.jpg"
                                    mob_image_url="assets/img/temp/best-lodges/walking-safari.jpg"
                                    text="The best lodges in Zimbabwe for walking safari">
                        </grid-image>
                    </div>

                    <div class="col-sm-6 col-md-4 short-grid">
                        <grid-image :router_object="{
                                        name: 'finder',
                                        query: {
                                            type: 'lodge',
                                            location: 7343,
                                            lname: 'Bhutan',
                                            experience: 39,
                                            ename: 'Historic Sites'
                                        }
                                    }"
                                    image_url="assets/img/temp/best-lodges/culture.jpg"
                                    mob_image_url="assets/img/temp/best-lodges/culture.jpg"
                                    text="The best lodges in Bhutan for culture">
                        </grid-image>
                    </div>
                    
                    <div class="col-sm-6 col-md-4 short-grid">
                        <grid-image :router_object="{
                                        name: 'finder',
                                        query: {
                                            type: 'lodge',
                                            location: 6544,
                                            lname: 'Chile',
                                            experience: 106,
                                            ename: 'Pumas'
                                        }
                                    }"
                                    image_url="assets/img/temp/best-lodges/pumas.jpg"
                                    mob_image_url="assets/img/temp/best-lodges/pumas.jpg"
                                    text="The best lodges in Chile for pumas">
                        </grid-image>
                    </div>

                    
                    <div class="col-sm-6 col-md-4 short-grid">
                        <grid-image :router_object="{
                                        name: 'finder',
                                        query: {
                                            type: 'lodge',
                                            location: 6805,
                                            lname: 'Peru',
                                            experience: 39,
                                            ename: 'Historic Sites'
                                        }
                                    }"
                                    image_url="assets/img/temp/best-lodges/archaeology.jpg"
                                    mob_image_url="assets/img/temp/best-lodges/archaeology.jpg"
                                    text="The best lodges in Peru for archaeology">
                        </grid-image>
                    </div>
                </div>

                <button-internal-presentational
                        classes="btn -main-cta -ripple"
                        :router_object="{path: hardcodedUrls.finder.lodges}">
                    <span><span>Explore all lodges</span> <i class="ion-ios-arrow-thin-right"></i></span>
                </button-internal-presentational>
            </div>
        </section>

        <!-- Magazine section -->
        <!-- <section class="container lodges">
            <div class="row">
                <div class="col-sm-12">
                    <grid-image
                            image_url="assets/img/new-homepage/magazine.jpg"
                            :router_object="{name: 'safari-articles'}"
                            text=""
                            zoom="central">

                        <div class="home-contact">
                            <h2 class="contact-title">Semasema</h2>

                            <p class="contact-text">The ATR Safari magazine</p>

                            <div class="buttons contact-buttons">

                                <button-internal-presentational
                                        classes="btn -main-cta -ripple"
                                        :router_object="{ name: 'safari-articles' }">
                                    <span><span>View</span> <i class="ion-ios-arrow-thin-right"></i></span>
                                </button-internal-presentational>
                            </div>
                        </div>
                    </grid-image>
                </div>
            </div>
        </section>

        <hr class="divider hidden-xs"> -->

        <!-- Popular Animals -->
        <!-- <section class="container animals hidden-xs">
            <div class="home-headings">
                <h3 class="header">Popular animals</h3>
                <p>Are there any particular animals you want to encounter?</p>
            </div>

            <div class="grid-image-container three-image">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <grid-image :router_object="{path: hardcodedUrls.experiences.leopards}"
                                    image_url="assets/img/temp/animals/leopards.jpg"
                                    mob_image_url="assets/img/temp/animals/mob/leopard.jpg"
                                    text="Leopards">
                        </grid-image>
                    </div>

                    <router-link to="{params: searchLinkToUrlParams}"></router-link>

                    <router-link :to="{name: 'finder', query: getLodgeSearchUrl()}"></router-link>

                    //router.push({name: 'finder', query: queryParams});

                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <grid-image :router_object="{path: hardcodedUrls.experiences.migration}"
                                    image_url="assets/img/temp/animals/migration.jpg"
                                    mob_image_url="assets/img/temp/animals/mob/migration.jpg"
                                    text="Migration">
                        </grid-image>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <grid-image :router_object="{path: hardcodedUrls.experiences.rhinos}"
                                    image_url="assets/img/temp/animals/rhinos.jpg"
                                    mob_image_url="assets/img/temp/animals/mob/rhino.jpg"
                                    text="Rhinos">
                        </grid-image>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <grid-image :router_object="{path: hardcodedUrls.experiences.cheetahs}"
                                    image_url="assets/img/temp/animals/cheetahs.jpg"
                                    mob_image_url="assets/img/temp/animals/mob/cheetahs.jpg"
                                    text="Cheetahs">
                        </grid-image>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <grid-image :router_object="{path: hardcodedUrls.experiences.wildDogs}"
                                    image_url="assets/img/temp/animals/hyena.jpg"
                                    mob_image_url="assets/img/temp/pop-locations/mob/bots.jpg"
                                    text="Wild Dogs">
                        </grid-image>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <grid-image :router_object="{path: hardcodedUrls.experiences.meerkats}"
                                    image_url="assets/img/temp/animals/meerkats.jpg"
                                    mob_image_url="assets/img/temp/pop-locations/mob/tribe.jpg"
                                    text="Meerkats">
                        </grid-image>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <grid-image :router_object="{path: hardcodedUrls.experiences.lions}"
                                    image_url="assets/img/temp/animals/lion-cubs.jpg"
                                    mob_image_url="assets/img/temp/pop-locations/mob/bots.jpg"
                                    text="Lions">
                        </grid-image>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <grid-image :router_object="{path: hardcodedUrls.experiences.elephants}"
                                    image_url="assets/img/temp/animals/elephants.jpg"
                                    mob_image_url="assets/img/temp/pop-locations/mob/tribe.jpg"
                                    text="Elephants">
                        </grid-image>
                    </div>
                </div>

                <button-internal-presentational
                        classes="btn -main-cta -ripple"
                        :router_object="{path: hardcodedUrls.finder.experiences}">
                    <span><span>Explore all experiences</span> <i class="ion-ios-arrow-thin-right"></i></span>
                </button-internal-presentational>
            </div>
        </section>

        <hr class="divider hidden-xs"> -->

        <!-- Popular Activities -->
        <!-- <section class="container activities hidden-xs">
            <div class="home-headings">
                <h3 class="header">Popular experiences</h3>
                <p>Are there any specific activities that you want to include?</p>
            </div>

            <div class="grid-image-container three-image">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <grid-image :router_object="{path: hardcodedUrls.experiences.vehicleSafari}"
                                    image_url="assets/img/temp/activities/elehpant-car.jpg"
                                    mob_image_url="assets/img/temp/activities/mob/elephant.jpg"
                                    text="Vehicle safari">
                        </grid-image>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <grid-image :router_object="{path: hardcodedUrls.experiences.walkingSafari}"
                                    image_url="assets/img/temp/activities/group-giraffe.jpg"
                                    mob_image_url="assets/img/temp/activities/mob/giraffe.jpg"
                                    text="Walking safari">
                        </grid-image>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <grid-image :router_object="{path: hardcodedUrls.experiences.balloonSafari}"
                                    image_url="assets/img/temp/activities/balloon-safari.jpg"
                                    mob_image_url="assets/img/temp/activities/mob/balloon.jpg"
                                    text="Balloon safari">
                        </grid-image>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <grid-image :router_object="{path: hardcodedUrls.experiences.nightVehicleSafari}"
                                    image_url="assets/img/temp/activities/sunset-trees.jpg"
                                    mob_image_url="assets/img/temp/activities/mob/sun-set.jpg"
                                    text="Night vehicle safari">
                        </grid-image>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <grid-image :router_object="{path: hardcodedUrls.experiences.motorboatSafari}"
                                    image_url="assets/img/temp/activities/river-elephants.jpg"
                                    mob_image_url="assets/img/temp/pop-locations/mob/bots.jpg"
                                    text="Motorboat safari">
                        </grid-image>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <grid-image :router_object="{path: hardcodedUrls.experiences.horseRiding}"
                                    image_url="assets/img/temp/activities/sunset-horse-ride.jpg"
                                    mob_image_url="assets/img/temp/pop-locations/mob/tribe.jpg"
                                    text="Horse-riding">
                        </grid-image>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <grid-image :router_object="{path: hardcodedUrls.experiences.hides}"
                                    image_url="assets/img/temp/activities/water-hole.jpg"
                                    mob_image_url="assets/img/temp/pop-locations/mob/bots.jpg"
                                    text="Hides">
                        </grid-image>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <grid-image :router_object="{path: hardcodedUrls.experiences.mokoro}"
                                    image_url="assets/img/temp/activities/lake-boat.jpg"
                                    mob_image_url="assets/img/temp/pop-locations/mob/tribe.jpg"
                                    text="Mokoro canoe safari">
                        </grid-image>
                    </div>
                </div>

                <button-internal-presentational
                        classes="btn -main-cta -ripple"
                        :router_object="{path: hardcodedUrls.finder.experiences}">
                    <span><span>Explore all experiences</span> <i class="ion-ios-arrow-thin-right"></i></span>
                </button-internal-presentational>
            </div>
        </section>

        <hr class="divider hidden-sm"> -->

        <!--Contact-->
        <!-- <section class="container -contact">
            <div class="col-sm-12">
                <grid-image
                        image_url="assets/img/temp/elephants-hill.jpg"
                        :router_object="{name: 'contact'}"
                        text=""
                        zoom="central">

                    <div class="home-contact">
                        <h2 class="contact-title">Get in touch</h2>

                        <p class="contact-text">It’s never too soon to get in touch, we are always eager to help out!</p>

                        <div class="buttons contact-buttons">

                            <button-internal-presentational
                                    classes="btn -main-cta -ripple"
                                    :router_object="{ name: 'contact' }">
                                <span><span>Contact</span> <i class="ion-ios-arrow-thin-right"></i></span>
                            </button-internal-presentational>
                        </div>
                    </div>
                </grid-image>
            </div>
        </section> -->
        <site-footer />
        <!-- <div id="veil" class="veil"></div>
        <transition-wipe></transition-wipe> -->
    </div>
</template>

<script>
    import slide from '../../libs/vue-carousel/src/Slide';
    import carousel from '../../libs/vue-carousel/src/Carousel';
    import pageIsReadyMixin from '../../mixins';
    import GlobalVars from '../../global/GlobalVars';
    import UrlMap from '../../global/UrlMap';
    import SeoManager from "../../global/SeoManager";
    import SchemaData from "../../global/SchemaData";
    import NavScrollMixin from "../../global/NavScrollMixin";

    export default {
        mixins: [pageIsReadyMixin, NavScrollMixin],
        data: function(){
            return {
                locationList: GlobalVars.FULL_LOCATIONS_LIST,
            }
        },
        components: {
            carousel,
            slide
        },
        methods: {
            oldDeviceCheck() {
                return window.globals.oldDevice;
            },
            scroll(){
                $("html, body").animate({ scrollTop: $('#welcome-section').offset().top }, 1000);
            }
        },
        computed: {
            hardcodedUrls() {
                return GlobalVars.HARCODED_URLS;
            },
        },
        mounted() {
            window.bus.$emit('setShowBackButton', false);

            this.$store.commit(window.store.SET_PAGE_TITLE, this.$route.meta['page_title']);
            this.$store.commit(window.store.SET_META_DESCRIPTION, this.$route.meta['meta_description']);

            SeoManager.addJsonSchema(new SchemaData().getHomepageSchema(), 'single');

            this.declarePageIsReady();

            setTimeout(function(){
                $('.-lodge-info').find('.inner').delay(1000).addClass('-in');
            }, 2000);
        },
        destroyed(){
            SeoManager.removeJsonSchema('single');
        }
    }
</script>
