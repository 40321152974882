<template>
    <div v-if="resource">
        <div v-if="resource.display_type == 0">
            <section class="right-side map-container article-images-container vignette -show-article -half -article-in">
                <div class="article-images -in -showing-images vh-fix-once">
                    <article-image-presentational
                            :title="resource.title"
                            :image="resource.images.sizes.extralarge">
                        <div class="caption">
                            <div class="width-container">
                                <h1 class="heading" v-html="resource.title" :contenteditable="editing.heading" :data-block-id="resource.id" v-if="resource.edit == false"></h1>

                                <div class="on-page-editor-container" v-else>
                                    <p class="heading" v-html="resource.title" :contenteditable="editing.heading" :data-block-id="resource.id"></p>
                                    <div class="smallEditButtons -horizontal" :class="{ '-editing': editing.heading }">
                                        <div @click="createEditor('heading')" class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton"></div>
                                        <div @click="saveEditor('heading')" class="icon ion-ios-checkmark onPageEditor-smallButton btn-success smallSaveButton" v-if="editing.heading==true"></div>
                                        <div @click="cancelEditor('heading')" class="icon ion-ios-close onPageEditor-smallButton btn-danger smallCancelButton" v-if="editing.heading==true"></div>
                                    </div>
                                </div>

                                <template v-if="resource.content != ''">
                                    <div class="sub-heading" v-html="resource.content" :contenteditable="editing.subHeading" :data-block-id="resource.id" v-if="resource.edit == false"></div>
                                    <div class="on-page-editor-container" v-else>
                                        <div class="sub-heading" v-html="resource.content" :contenteditable="editing.subHeading" :data-block-id="resource.id"></div>
                                        <div class="smallEditButtons -horizontal" v-bind:class="{ '-editing': editing.subHeading }">
                                            <div @click="createEditor('sub-heading')" class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton"></div>
                                            <div @click="saveEditor('sub-heading')" class="icon ion-ios-checkmark onPageEditor-smallButton btn-success smallSaveButton" v-if="editing.subHeading==true"></div>
                                            <div @click="cancelEditor('sub-heading')" class="icon ion-ios-close onPageEditor-smallButton btn-danger smallCancelButton" v-if="editing.subHeading==true"></div>
                                        </div>
                                    </div>
                                </template>

                                <p class="buttons">
                                    <button-social></button-social>
                                </p>
                            </div>
                        </div>
                    </article-image-presentational>
                </div>

                <div class="article-content vh-fix">
                    <div class="row info-window content-panel">
                        <transition-group name="fade" mode="in-out">
                            <div v-if="resourceLoaded" key="half_resource_1">
                                <article-presentational
                                        :title="resource.article.title"
                                        :content="resource.article.content"
                                        type="resource_article"
                                        :edit="resource.edit"></article-presentational>
                                <next-up-half-page
                                        v-if="showOuts"
                                        page="resource"
                                        :item="resource"
                                        :data_heading="'Next'"
                                        :data_next_lodge="true"
                                        :text="'Next'"
                                        text_next_item="Next"
                                        text_explore="Explore resources"
                                        position="right">
                                </next-up-half-page>
                            </div>
                            <div v-else key="half_resource_2" class="loading">
                            </div>
                        </transition-group>
                    </div>
                </div>
            </section>
            <public-edit-mode-toggle />
        </div>
        <div v-else>
            <atr-fullpage id="lodge-page" ref="atrFullpage" class="page-full-resource" :style="`background-image:url('${this.last_bg_img_url}')`">
                <section-intro-presentational >
                    <div class="component-text -lodge-info custom-section">
                        <div class="fp-bg full-size pnp-bg vignette vignette1"
                             :style="bg_img_url">
                        </div>
                        <div class="inner">
                            <div class="inner-container">
                                <div class="width-container">
                                    <h1 class="heading3 heading custom-section-title" :data-pnp-heading="resource.top_section.tag" v-html="resource.title"></h1>

                                    <div class="summary custom-section-content" v-html="resource.top_section.content" v-filter-content :contenteditable="editing.subHeading" v-if="resource.edit === false"></div>

                                    <div class="on-page-editor-container" v-else>
                                        <div class="summary custom-section-content" v-html="resource.top_section.content" v-filter-content :contenteditable="editing.subHeading"></div>
                                        <div class="smallEditButtons -horizontal" v-bind:class="{ '-editing': editing.subHeading }">
                                            <div v-on:click="createEditor('custom-section-content')"
                                                 class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton"></div>
                                            <div v-on:click="saveEditor('custom-section-content')" class="icon ion-ios-checkmark onPageEditor-smallButton btn-success smallSaveButton"
                                                 v-if="editing.subHeading===true"></div>
                                            <div v-on:click="cancelEditor('custom-section-content')" class="icon ion-ios-close onPageEditor-smallButton btn-danger smallCancelButton"
                                                 v-if="editing.subHeading===true"></div>
                                        </div>
                                    </div>
                                </div>

                                <p class="buttons">
                                    <button-social></button-social>
                                </p>

                                <div class="scroll-btn-container ">
                                    <div class="single-scroll-btn" @click="scroll">
                                        <i class="ion-ios-arrow-thin-down"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section-intro-presentational>

                <section-container
                        v-if="resourceLoaded"
                        v-for="section in resource.sections.data"
                        :key="section.id"
                        :section="section"
                        :classes="'section'"
                        :edit="resource.edit"
                        :item_title="resource.title"
                        type="resource">
                </section-container>

                <next-up-half-page
                        v-if="showOuts"
                        page="resource"
                        :item="resource"
                        :data_heading="'Next'"
                        :data_next_lodge="true"
                        :text="'Next'"
                        text_next_item="Next"
                        text_explore="Explore resources"
                        :image_url="last_bg_img_url"
                        position="full-page">
                </next-up-half-page>
            </atr-fullpage>

            <div v-if="resource.edit == true">
                <template v-for="section in resource.sections.data">
                    <public-editor-buttons type="resource_section" :section_id="section.id" :_id="section.id" :buttons="section.buttons"></public-editor-buttons>
                    <template v-for="slide in section.slides.data">
                        <public-editor-buttons type="resource_slide" :section_id="section.id" :_id="slide.id" :buttons="slide.buttons"></public-editor-buttons>
                    </template>
                </template>
            </div>

            <div id="modal-veil"></div>

            <public-edit-mode-toggle />
        </div>
        <!-- <transition-wipe/> -->
    </div>
</template>

<script>
    import pageIsReadyMixin from '../../mixins';
    import store from '../../store';
    import NavScrollMixin from "../../global/NavScrollMixin";

    export default {
        mixins: [pageIsReadyMixin, NavScrollMixin],
        data() {
            return {
                editing: {
                    heading: false,
                    subHeading: false,
                },
                editorName: null,
                first: true,
                showOuts: true,
                resourceLoaded: false
            }
        },
        methods: {
            createEditor(which) {
                if(!window._.isUndefined(this.editorName) && this.editorName!==null){
                    alert('You are currently editing a different bit on the page.\nSave or Cancel it before starting editing on a new instance.');
                } else {
                    if (which === 'heading') {
                        this.editing.heading = true;
                        this.editing.subHeading = false;
                    } else {
                        this.editing.heading = false;
                        this.editing.subHeading = true;
                    }
                    Vue.nextTick(() => {
                        var editor = window.CKEDITOR.inline(this.$el.querySelector('.on-page-editor-container .' + which), window.ckeditorConfig);
                        this.$el.querySelector('.on-page-editor-container .' + which).click();
                        this.editorName = editor.name;

                        this.$store.commit(window.store.SET_EDITOR_CKEDITOR, true, {root: true});
                    });
                }
            },
            cancelEditor(which) {
                this.editing.heading = false;
                this.editing.subHeading = false;
                if (this.editorName !== null) {
                    if (which === 'heading') {
                        window.CKEDITOR.instances[this.editorName].container.$.innerHTML = this.resource.title;
                    } else {
                        window.CKEDITOR.instances[this.editorName].container.$.innerHTML = this.resource.content;
                    }
                    window.CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;

                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, { root: true });
                }
            },
            saveEditor(which) {
                this.editing.heading = false;
                this.editing.subHeading = false;

                if (this.editorName !== null) {
                    let newContent = window.CKEDITOR.instances[this.editorName].container.$.innerHTML;
                    let resourceObject = window._.defaultsDeep({}, this.$store.state.singleResource.resource);
                    if (which === 'heading') {
                        resourceObject.title = newContent;
                    } else if (which === 'custom-section-content') {
                        resourceObject.top_section.content = newContent;
                    } else {
                        resourceObject.content = newContent;
                    }
                    this.$store.dispatch(window.store.SET_EDITOR_DIRTY, window.editorTypes.RESOURCE, { root: true });
                    this.$store.commit(window.store.SET_RESOURCE, resourceObject);
                    window.CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;

                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, { root: true });
                }
            },
            initResource(){
                this.resourceLoaded = true;

                //Set the resource to temporarily be the current article whilist it's downloaded
                if(this.resource.display_type==0) {
                    window.$body.addClass('showing-article-half showing-article');
                }else{
                    window.$body.removeClass('showing-article-half showing-article');
                }

                if(Object.keys(this.$route.params).length > 0 && this.$route.params.hasOwnProperty('showOuts')) {
                    this.showOuts = this.$route.params.showOuts
                }

                $(document).scrollTop(0);
                $('.info-window').animate({
                    scrollTop: 0
                }, 700);

                // store.dispatch(window.store.FETCH_AND_SET_RESOURCE, this.$route.params['slug']).then(() => {
                if(!this.resource.display_type==0) {
                    if($('html').hasClass('fp-enabled')){
                        window.bus.$emit('fullPageDestroying');
                        window.bus.$emit('fullPageDestroyed');
                        window.bus.$emit('fullPageInit');
                    }
                    /**
                     * We need to check if it's undefined because
                     * if a user aborts before the transition is complete
                     * it will try to execute anyway
                     */
                    setTimeout(() => {
                        if (this.$refs.atrFullpage && !$('html').hasClass('fp-enabled')) {
                            this.$refs.atrFullpage.init();
                        }
                    });
                }else{
                    this.declarePageIsReady();
                }
            },
            scroll(){
                window.bus.$emit('fullPageMoveSectionDown');
            }
        },
        computed: {
            resource() {
                if(this.resourceLoaded) {
                    return this.$store.state.singleResource.resource;

                }else if(this.currentArticle.title &&
                    (!this.currentArticle.vue_route_params.data ||
                    this.currentArticle.vue_route_params.data.slug &&
                    this.currentArticle.vue_route_params.data.slug  === this.$route.params.slug)){

                    //Return a temporary response whilst the real resource is loaded.
                    if(this.currentArticle.display_type === 0) {
                        //Half page articles

                        return {
                            title: this.currentArticle.title,
                            content: this.currentArticle.content,
                            edit: false,
                            display_type: this.currentArticle.display_type,

                            article: {
                                title: this.currentArticle.title,
                                content: "Loading ...",
                            },
                            images: {
                                sizes: {
                                    extralarge: this.currentArticle.images.data.sizes ? this.currentArticle.images.data.sizes.extralarge : null,
                                }
                            },
                        }
                    }else{
                        //Full page articles

                        return {
                            title: this.currentArticle.title,
                            top_section: {
                                tag: null,
                                content: this.currentArticle.images.data.caption,
                            },
                            images: {
                                sizes: {
                                    extralarge: this.currentArticle.images.data.sizes ? this.currentArticle.images.data.sizes.extralarge : null,
                                }
                            },
                            display_type: this.currentArticle.display_type,
                            sections: {
                                data: []
                            },
                            allSections: {
                                data: []
                            },
                            edit: false
                        }
                    }
                }else{
                    return false;
                }
            },
            currentArticle() {
                return this.$store.state.activeArticle.article;
            },
            bg_img_url() {
                if(this.resource && this.resource.images.sizes.extralarge) {
                    return "background-image:url('" + this.resource.images.sizes.extralarge + "');";
                }else{
                    return;
                }
            },
            last_bg_img_url() {
                if(this.resource && this.resource.images && this.resource.images.sizes && this.resource.images.sizes.extralarge){
                    return this.resource.images.sizes.extralarge;
                }else {
                    return '';
                }
            },
            isMobile(){
                return pnpHelper.bootstrap.isXs();
            }
        },
        beforeRouteUpdate(to, from, next) {
            if($('body').hasClass('-disable-page-wipe')) {

                this.$store.dispatch(window.store.FETCH_AND_SET_RESOURCE, to.params['slug']).then(() => {
                    this.initResource();
                    next();
                }).catch(() => {
                    this.$router.push({ name: 'page404' });

                }).finally(() => {
                    this.loadingNextResult = false;

                    setTimeout(() => {
                        $('body').removeClass('-disable-page-wipe')
                    },1000);
                });

            }else{
                next();
            }
        },
        beforeRouteLeave(to, from, next) {
            if (to['name'] === 'single-resource') {
                /** Before we attempt to enter this route trigger an update from the store */
                store.dispatch(window.store.FETCH_AND_SET_RESOURCE, to.params['slug']).then(() => {
                    this.initResource();
                    next();
                }).catch(() => {
                    this.$router.push({ name: 'page404' });
                });
            } else {
                next();
            }
        },
        mounted() {

            this.$store.commit(window.store.SET_SEARCH_TYPE, 'resource');
            this.$store.dispatch(window.store.FETCH_AND_SET_SEARCH_RESULTS);

            window.bus.$once('fullPageAfterDomReady', this.declarePageIsReady);

            //Set the resource to temporarily be the current article whilist it's downloaded
            if(this.resource.display_type==0) {
                window.$body.addClass('showing-article-half showing-article');
            }

            this.$store.dispatch(window.store.FETCH_AND_SET_RESOURCE, this.$route.params['slug']).then(this.initResource);
        },
        beforeDestroy() {
            window.bus.$emit('setShowBackButton', false);
            window.$body.removeClass('showing-article');
            window.$body.removeClass('showing-article-half');
            window.$body.removeClass('showing-article-full');
        }
    }
</script>