import Vue from "vue";
import Form from "./helpers/Form";
import BtableHelper from "./helpers/BtableHelper";
import GoogleAnalytics from "./global/GoogleAnalytics";
import iOSDeviceDetection from "./global/iOSDeviceDetection";

window.CONTENT_FILTER_DICTIONARY_DEFAULT = [
    {
        old: '<p>(>[\s]|[<br\\s\\/>])*<strong>',
        new: '<h3>',
    },
    {
        old: '<\/strong>(>[\s]|[<br\\s\\/>])*<\/p>',
        new: '</h3>',
    }
];

window.CONTENT_WORD_DICTIONARY = [
    {
        old: ' A ',
        new: ' a '
    },
    {
        old: ' In ',
        new: ' in '
    },
    {
        old: ' The ',
        new: ' the '
    }
];

let hasEnv = false;
let _branch = typeof branch === 'undefined' ? null : branch;

window.UA_CODE = 'NULL';

if(_branch != null){
    if(_branch === 'live-env'){
        window.BACKEND_URL = 'https://lb.africatravelresource.com';
        window.API_URL = 'https://lb.africatravelresource.com/api/v1/';
        hasEnv = true;
        window.UA_CODE = 'UA-227489-1'; //NOTE ALSO DEFINED IN INDEX.HTML 
    } else if(_branch === 'preproduction-env'){
        window.BACKEND_URL = 'https://lb.atr-preprod.com';
        window.API_URL = 'https://lb.atr-preprod.com/api/v1/';
        hasEnv = true;
        window.UA_CODE = 'UA-227489-2'; //NOTE ALSO DEFINED IN INDEX.HTML
    } else if(_branch === 'testing'){
        window.BACKEND_URL = 'https://lb.atr-preprod.com';
        window.API_URL = 'https://lb.atr-preprod.com/api/v1/';
        // window.BACKEND_URL = 'https://atr-api.pnpdev.co.uk';
        // window.API_URL = 'https://atr-api.pnpdev.co.uk/api/v1/';
        hasEnv = true;
    }
}

if(!hasEnv) {
    window.BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
    window.API_URL = process.env.VUE_APP_API_URL;
    window.CKEDITOR_BASEPATH = process.env.VUE_APP_CKEDITOR_BASEPATH;
}
window.environment = {
    isDev: (window.BACKEND_URL === 'https://lb.atr-preprod.com'),
    isPreProd: (window.BACKEND_URL === 'https://atr-api.pnpd.net' || window.BACKEND_URL === 'https://lb.atr-preprod.com'),
    isProd: (window.BACKEND_URL === 'https://lb.africatravelresource.com')
};

let device = new iOSDeviceDetection();

window.globals = {
    oldDevice: device.getDeviceId(),
    months: {
        '0': {
            name: 'any month',
            shortName: 'any month'
        },
        '1': {
            name: 'January',
            shortName: 'J'
        },
        '2': {
            name: 'February',
            shortName: 'F'
        },
        '3': {
            name: 'March',
            shortName: 'M'
        },
        '4': {
            name: 'April',
            shortName: 'A'
        },
        '5': {
            name: 'May',
            shortName: 'M'
        },
        '6': {
            name: 'June',
            shortName: 'J'
        },
        '7': {
            name: 'July',
            shortName: 'J'
        },
        '8': {
            name: 'August',
            shortName: 'A'
        },
        '9': {
            name: 'September',
            shortName: 'S'
        },
        '10': {
            name: 'October',
            shortName: 'O'
        },
        '11': {
            name: 'November',
            shortName: 'N'
        },
        '12': {
            name: 'December',
            shortName: 'D'
        }
    }
};

window.bus = new Vue();
window.Form = Form;
window.BtableHelper = BtableHelper;
window.GoogleAnalytics = new GoogleAnalytics(window.UA_CODE);

axios.defaults.headers.common = {
    'X-CSRF-TOKEN': window.Laravel.csrfToken,
    'X-Requested-With': 'XMLHttpRequest'
};

window.ckeditorConfig = {
    // Define changes to default configuration here.
    // For complete reference see:
    // http://docs.ckeditor.com/#!/api/CKEDITOR.config
    // toolbar_Basic : [{ name: 'document', items : [ 'Source','-','Save','NewPage','DocProps','Preview','Print','-','Templates' ] },
    //     { name: 'clipboard', items : [ 'Cut','Copy','Paste','PasteText','PasteFromWord','-','Undo','Redo' ] }],

    toolbarGroups: [
        { name: 'clipboard',   groups: [ 'clipboard', 'undo' ] },
        { name: 'editing',     groups: [ 'find', 'selection', 'spellchecker' ] },
        { name: 'links' },
        { name: 'insert'},
        { name: 'forms' },
        { name: 'tools' },
        { name: 'document',	   groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'others' },
        '/',
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
        { name: 'styles' },
        { name: 'colors' },
        { name: 'about' }
    ],

// Remove some buttons provided by the standard plugins, which are
// not needed in the Standard(s) toolbar.
    removeButtons: 'Underline,Subscript,Superscript',
    // toolbar: 'standard',
    extraAllowedContent: 'h1;h2;h3;h4;h5;h6;p;pre;a;br;b;u;i;span;hr;div;img;table;tr;td;tg;tbody;iframe[*]',
    extraPlugins: 'panel,floatpanel,listblock,button,richcombo,format,sourcedialog,listblock,tabletools',

    // Set the most common block elements.
    format_tags: 'p;h1;h2;h3;h4;h5;h6;pre',

    // Simplify the dialog windows.
    removeDialogTabs: 'image:advanced;link:advanced',
    customConfig: '',
    removePlugins: 'stylescombo',
    startupFocus: true,
    autoParagraph: 1,
    elementMode: 1
};
