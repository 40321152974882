<template>
    <div class="article-card">
        <img class="image" :src="image_url" alt="">

        <div class="tags">
            <span><a rel="nofollow" href="#">Lodges,</a></span>
            <span>28 May 2018</span>
        </div>

        <h3 class="title">{{ title }}</h3>

        <p class="preview">{{ preview }}</p>

        <a rel="nofollow" href="#" class="read-more-btn">Read More</a>
    </div>
</template>

<script>
    export default {
        props: {
            image_url: String,
            title: String,
            preview: String
        }
    }
</script>