<template>
    <div class="explore-btn-container" style="position: relative" @mouseenter="open = true" @mouseleave="open = false">
        <div @click="link($event)" class="btn -explore">
            Explore
        </div>
        <transition-group name="fade" mode="in-out" duration="500ms">
        <div class="explore-menu" v-show="open" :key="0">
            <ul>
                <li><div @click="link('Experience'); open = false;" class="explore-link">Experience</div></li>
                <li><div @click="link('Lodges'); open = false;" class="explore-link">Lodges</div></li>
                <li><div @click="link('Locations'); open = false;" class="explore-link">Locations</div></li>
                <li><div @click="link('Trips'); open = false;" class="explore-link">Trips</div></li>
                <li><div @click="link('Articles'); open = false;" class="explore-link">Articles</div></li>
                <li><div @click="link('Resources'); open = false;" class="explore-link">Resources</div></li>
                <li><div @click="link('About us'); open = false;" class="explore-link">About us</div></li>
            </ul>
        
        </div>
        </transition-group>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import GlobalVars from '../../../global/GlobalVars';

    export default {
        props:['backBtn'],
        data() {
            return {
                open: false
            }
        },
        methods: {
            link(e){
                if(typeof e === 'object') {
                    e.preventDefault();
                }

                let routeName = this.$route.name;

                if(this.backBtn && (routeName === 'single-lodge' || routeName === 'single-trip' || routeName === 'single-location' || routeName === 'single-experience')){
                    this.$router.go(-Math.abs(this.calcPageDepth));
                } else {

                    let path = this.hardcodedUrls.finder.lodges;

                    switch(e){
                        case 'Experience':
                            path = this.hardcodedUrls.finder.experiences;
                            break;
                        case 'Lodges':
                            path = this.hardcodedUrls.finder.lodges;
                            break;
                        case 'Locations':
                            path = this.hardcodedUrls.finder.locations;
                            break;
                        case 'Trips':
                            path = this.hardcodedUrls.finder.trip_collections;
                            break;
                        case 'Articles':
                            path = this.hardcodedUrls.finder.articles;
                            break;
                        case 'Resources':
                            path = this.hardcodedUrls.finder.resources;
                            break;
                        case 'About us':
                            path = this.hardcodedUrls.resources.aboutUs;
                            break;
                        default:
                            path = this.hardcodedUrls.finder.lodges;
                            break;
                    }

                    this.$router.push({
                        path: path
                    });

                    // if(routeName === 'single-experience') {
                    //     this.$router.push({
                    //         path: this.hardcodedUrls.finder.experiences
                    //     });
                    //
                    // }else if(routeName === 'single-location'){
                    //     this.$router.push({
                    //         path: this.hardcodedUrls.finder.locations
                    //     });
                    //
                    // }else if(routeName === 'single-trip'){
                    //     this.$router.push({
                    //         path: this.hardcodedUrls.finder.trips
                    //     });
                    //
                    // }else if(routeName === 'single-resource'){
                    //     this.$router.push({
                    //         path: this.hardcodedUrls.finder.resources
                    //     });
                    //
                    // }else {
                    //     this.$router.push({
                    //         path: this.hardcodedUrls.finder.lodges
                    //     });
                    // }
                }
            }
        },
        computed: {
            hardcodedUrls() {
                return GlobalVars.HARCODED_URLS;
            },
            calcPageDepth(){
                return -Math.abs(this.pageDepth);
            },
            ...mapState({
                fromSearch: state => state.transitionManager.fromSearch,
                transition: state => state.transitionManager.transitionToUse,
                pageDepth: state => state.transitionManager.pageDepth,
                searchType: state => state.search.criteria.params.type
            })
        }
    }
</script>
