<template>
    <div>
        <div class="your-details-container">
            <div class="input-wrap">
                <input type="text" placeholder="First Name" name="firstname" v-model="firstname" @change="setValue" />
                <div class="errors-container" v-if="error.includes('first_name')" style="margin-top: -7%">
                    <p>Please enter your first name</p>
                </div>
            </div>
            <div class="input-wrap">
                <input type="text" placeholder="Surname / Family Name" name="lastname" v-model="lastname" @change="setValue" />
                <div class="errors-container" v-if="error.includes('last_name')" style="margin-top: -7%">
                    <p>Please enter your last name</p>
                </div>
            </div>
            <div class="input-wrap">
                <input type="email" placeholder="Email Address" name="email" v-model="email" @change="setValue" />
                <div class="errors-container" v-if="error.includes('email')" style="margin-top: -7%">
                    <p>Please enter a valid email address</p>
                </div>
            </div>
            <div class="input-wrap">
                <vue-tel-input v-model="phone" :preferredCountries="preferredCountries" :defaultCountry="mobile_country_code" @input="setPhone"></vue-tel-input>
                <div class="errors-container" v-if="error.includes('phone')">
                    <p>Please enter a valid phone number</p>
                </div>
            </div>
            <div class="input-wrap">
                <input type="text" placeholder="Preferred date and time for a call" name="preferred_date_and_time_for_call" v-model="preferred_date_and_time_for_call" @change="setValue" />
                <div class="errors-container" v-if="error.includes('time')" style="margin-top: -7%">
                    <p>Please enter your preferred call time</p>
                </div>
            </div>
            <div class="input-wrap">
                <input type="text" placeholder="Nearest city" name="nearest_city" v-model="nearest_city" @change="setValue" />
                <div class="errors-container" v-if="error.includes('city')" style="margin-top: -7%">
                    <p>Please enter your nearest city</p>
                </div>
                
            </div>
        </div>
        <p>Naturally, we guarantee not to bombard you with endless emails and will never share your details with unrelated companies or organisations.</p> 
    </div>
</template>

<script>

    export default {
        props: ['formContent', 'checkValidation', 'stepIndex', 'step'],
        data() {
            return {
                phone: '',
                mobile_country_code: 'us',
                preferredCountries: ["us", "gb"],
                firstname: '',
                lastname: '',
                email: '',
                preferred_date_and_time_for_call: '',
                nearest_city: '',
                phoneData: {valid: false, number: ''},
                error: [],
            }
        },
        mounted() {
            this.firstname = this.formContent.firstname || '';
            this.lastname = this.formContent.lastname || '';
            this.email = this.formContent.email || '';
            this.phone = this.formContent.phone || '';
            this.mobile_country_code = this.formContent.mobile_country_code || 'us';
            this.preferred_date_and_time_for_call = this.formContent.preferred_date_and_time_for_call || '';
            this.nearest_city = this.formContent.nearest_city || '';

            if(this.stepIndex === this.step.index && this.inputsHaveContent) {
                this.validateStep();
            }
        },
        watch: {
            checkValidation: function(e){
                if(e) {
                    this.validateStep();
                }
                
            },
        },
        methods: {
            setValue(e) {
                if(typeof e === 'undefined' || e === null || e === ''){
                    return;
                }

                if(e.target.getAttribute('name') === 'firstname'){
                    this.validateFirstName(e.target);
                }

                if(e.target.getAttribute('name') === 'lastname'){
                    this.validateLastName(e.target);
                }

                if(e.target.getAttribute('name') === 'email'){
                    this.validateEmail(e.target);
                }

                if(e.target.getAttribute('name') === 'preferred_date_and_time_for_call'){
                    this.validateTime(e.target);
                }

                if(e.target.getAttribute('name') === 'nearest_city'){
                    this.validateCity(e.target);
                }

                this.$emit('update-data', { key: e.target.getAttribute('name'), value: e.target.value });
            },
            setPhone(num, e) {
                 
                if(typeof e === 'undefined' || e === null || e === ''){
                    return;
                }
                if(typeof e.valid === "undefined" || e.valid === null || !e.valid) {
                    return;
                }
                if(typeof e.number === "undefined" || e.number === null || e.number === '') {
                    return;
                }

                this.validatePhone(e);
                this.phoneData = e;

                this.$emit('update-data', { key: 'phone', value: e.number });
                this.$emit('update-data', { key: 'mobile_country_code', value: e.country.name });
            },
            validateStep(){
                this.validateFirstName({value: this.formContent.firstname});
                this.validateLastName({value: this.formContent.lastname});
                this.validateEmail({value: this.formContent.email});
                this.validatePhone(this.phoneData);
                this.validateTime({value: this.formContent.preferred_date_and_time_for_call});
                this.validateCity({value: this.formContent.nearest_city});
            },
            validateFirstName(elem){

                if(typeof elem.value === 'undefined' || elem.value === null || elem.value === ''){
                    this.error.push('first_name');
                } else {
                    this.error = this.error.filter(item => item !== 'first_name');
                }

                this.checkErrors();
            },
            validateLastName(elem){
                if(typeof elem.value === 'undefined' || elem.value === null || elem.value === ''){
                    this.error.push('last_name');
                } else {
                    this.error = this.error.filter(item => item !== 'last_name');
                }

                this.checkErrors();
            },
            validateEmail(elem){
                if(typeof elem.value === 'undefined' || elem.value === null || elem.value === '' || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(elem.value))){
                    this.error.push('email');
                } else {
                    this.error = this.error.filter(item => item !== 'email');
                }

                this.checkErrors();
            },
            validatePhone(elem){
                console.log(elem);
                if(typeof elem.valid === "undefined" || elem.valid === null || !elem.valid) {
                    this.error.push('phone');
                    return;
                } else {
                    this.error = this.error.filter(item => item !== 'phone');
                }

                this.checkErrors();
            },
            validateTime(elem){
                if(typeof elem.value === 'undefined' || elem.value === null || elem.value === ''){
                    this.error.push('time');
                } else {
                    this.error = this.error.filter(item => item !== 'time');
                }

                this.checkErrors();
            },
            validateCity(elem){
                if(typeof elem.value === 'undefined' || elem.value === null || elem.value === ''){
                    this.error.push('city');
                } else {
                    this.error = this.error.filter(item => item !== 'city');
                }

                this.checkErrors();
            },
            checkErrors(){
                let errors = 0;

                if(typeof this.formContent.firstname === "undefined" || this.formContent.firstname === null || this.error.includes('first_name')){
                    errors++;
                }

                if(typeof this.formContent.lastname === "undefined" || this.formContent.lastname === null || this.error.includes('last_name')){
                    errors++;
                }

                if(typeof this.formContent.email === "undefined" || this.formContent.email === null || this.error.includes('email')){
                    errors++;
                }

                if(typeof this.formContent.phone === "undefined" || this.formContent.phone === null || this.error.includes('phone')){
                    errors++;
                }

                if(typeof this.formContent.preferred_date_and_time_for_call === "undefined" || this.formContent.preferred_date_and_time_for_call === null || this.error.includes('time')){
                    errors++;
                }

                if(typeof this.formContent.nearest_city === "undefined" || this.formContent.nearest_city === null || this.error.includes('city')){
                    errors++;
                }

                console.log(errors);

                if(errors === 0){
                    this.$emit('update-validation', {step: this.step.index, value: true});
                } else {
                    this.$emit('update-validation', {step: this.step.index, value: false});
                }
            }
        },
        computed: {
            inputsHaveContent() {
                if(typeof this.formContent.firstname !== "undefined" && this.formContent.firstname !== null){
                    return true;
                }

                if(typeof this.formContent.lastname !== "undefined" && this.formContent.lastname !== null || this.error.includes('last_name')){
                    return true;
                }

                if(typeof this.formContent.email !== "undefined" && this.formContent.email !== null || this.error.includes('email')){
                    return true;
                }

                if(typeof this.formContent.phone !== "undefined" && this.formContent.phone !== null || this.error.includes('phone')){
                    return true;
                }

                if(typeof this.formContent.preferred_date_and_time_for_call !== "undefined" && this.formContent.preferred_date_and_time_for_call !== null){
                    return true;
                }

                if(typeof this.formContent.nearest_city !== "undefined" && this.formContent.nearest_city !== null){
                    return true;
                }

                return false;
            },
        }
    }

</script>