<template>
    <div class="srch-panel -type" :id="'srch-type-panel' + this.sentenceId">
        <div class="bg"></div>

        <a class="selectable"
           :class="{selected : params.type === 'experience'}"
           @click="setSearchType('experience')">experiences</a>

        <a class="selectable"
           :class="{selected : params.type === 'location'}"
           @click="setSearchType('location')">locations</a>

        <a class="selectable"
           :class="{selected : params.type === 'lodge'}"
           @click="setSearchType('lodge')">lodges</a>

<!--        <a class="selectable"-->
<!--           :class="{selected : params.type === 'trip'}"-->
<!--           @click="setSearchType('trip')">trips</a>-->
        <a class="selectable"
           :class="{selected : params.type === 'trip_collection'}"
           @click="setSearchType('trip_collection')">trips</a>

        <a class="selectable"
           :class="{selected : params.type === 'resource'}"
           @click="setSearchType('resource')">resources</a>


        <a class="selectable"
           :class="{selected : params.type === 'article'}"
           @click="setSearchType('article')">articles</a>
    </div>
</template>
<script>
    import UrlMap from '../../../global/UrlMap';
    // import router from '../../../router';
    import { mapState } from 'vuex';

    export default {
        props: {
            params: {
                type: Object,
            },
            options: {
                type: Object
            },
            shouldSearch: {
                default: true,
                type: Boolean
            },
            sentenceId: {
                default: '',
                type: String
            }
        },
        methods: {
            setSearchType(type) {
                window.bus.$emit('searchSentenceDirtyUpdate', {
                    isDirty: true,
                    key: 'type'
                });

                if (this.shouldSearch) {
                    this.$store.commit(window.store.SET_SEARCH_TYPE_URL, type);

                    setTimeout(() => {
                        window.bus.$emit("searchRefresh");
                    },0);
                } else {
                    this.params.type = type;
                }

                // if(type === 'trip') {
                //     this.$store.commit(window.store.SET_SEARCH_BUDGET_RANGE, [0, 50000]);
                // } else {
                //     this.$store.commit(window.store.SET_SEARCH_BUDGET_RANGE, [0, 5000]);
                // }
            }
        },
        computed: {
            ...mapState({
                exploreType: state => state.transitionManager.exploreType
            })
        }
    }
</script>
