<template>
    <ul :class="classes">
        <li v-for="out in outs" v-if="out.title">
            <span @click="toggleWishlist(out)">
                <div class="button-fav hidden-xs" :class="{on: check(out)}" ></div>

                <a class="link">
                    <span v-if="check(out)">Remove </span>
                    <span v-else>Add </span>
                    <span class="related-name">{{out.title}} </span>
                    <span v-if="check(out)">from </span>
                    <span v-else>to </span>
                    wishlist
                </a>
            </span>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            classes: {
                type: Array
            },
            item: {
                type: Object
            },
            type: {
                type: String
            },
            itemType: {
                type:String
            },
            list: {
                type: Array,
            }
        },
        methods: {
            toggleWishlist(item){

                let payload = {
                    id: item.id,
                    type: item.type ? item.type : (this.item && item.id === this.item.id ? this.itemType : 'location')
                };

                if(this.check(item)) {
                    this.$store.commit(window.store.REMOVE_WISHLIST_ITEM, payload);
                } else {
                    this.$store.dispatch(window.store.SET_WISHLIST_ITEM_BY_ID, payload);
                }
            },
            check(item){
                return this.wishlist.some((el) => {
                    return el.id == item.id;
                });
            }
        },
        computed: {
            outs(){
                if(this.list){
                    return this.list;
                }

                if(!this.item.parents){
                    return [];
                }

                let parents = this.item.parents.data

                let firstItem = parents[0];

                let lastItem = parents[parents.length - 1].title !== firstItem.title ? parents[parents.length - 1] : {};

                let criticalArrayLength = parents.filter((el) => el.critical).length;
                    criticalArrayLength = (criticalArrayLength - 1) < 0 ? 0 : criticalArrayLength - 1;

                let criticalItem = parents.filter((el) => {
                    return el.critical == true && (el.title !== lastItem.title || el.title !== firstItem.title)
                });

                    criticalItem = criticalItem[criticalArrayLength] !== undefined ? criticalItem[criticalArrayLength] : {};

                let outs = [
                    lastItem,
                    criticalItem,
                    firstItem
                ];

                return  _.uniqBy([this.item, ...outs], 'title');
            },
            wishlist(){
                return this.$store.state.wishlist.selected;
            }
        }
    }
</script>