export default class LazyLoader
{
    //Stores all images loaded in the session (to ensure the same aren't fetched again)
    loadedImages = [];

    constructor()
    {

    }

    loadImages(imgs)
    {
        if(imgs && imgs.length > 0 ) {
            for (var i = 0; i < imgs.length; i++) {
                if(!this.loadedImages.includes(imgs[i])) {
                    var img = new Image();
                    img.src = imgs[i];
                    this.loadedImages.push(imgs[i]);
                }
            }
        }else{
            console.warn('No images passed to LazyLoader.js');
        }
    }

    loadArticleImages(articles){
        let imgs = [];

        if(articles){
            for (let i in articles) {
                if(articles[i].image) {
                    imgs.push(articles[i].image);
                }
            }
        }

        this.loadImages(imgs);
    }
}