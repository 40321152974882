<template>
    <div class="nav-line-height mob-popup-header">
        <div class="burger-container">
            <div class="burger -in">
                <i></i>
            </div>
        </div>

        <span class="sub-text">
            {{text}}
        </span>
    </div>
</template>

<script>
    export default {
        props: ['text']
    }
</script>