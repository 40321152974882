<template>
    <div class="global-search-container" :class="{ '-search-active': dirtyInput }">
        <div class="code-version" v-if="isNotProduction">{{codeVersion}}</div>

        <div class="input-container align-top" >

            <transition-group name="global-tabs-fade" tag="div" class="global-tabs">
                <!--<p key="1" class="sub-text" v-if="!dirtyInput">Type to search</p>-->
                <span key="1" v-if="!dirtyInput"> </span>

                <p key="4" class="sub-text filter-container" v-else-if="dirtyInput">
                    <span class="filter-btn sub-text"
                          @click="filterResults(-1)"
                          :class="{'active-filter': !activeFilter}">
                        All<span class="hidden-sm"> ({{ counts.total }})</span>
                    </span>

                    <transition name="global-tabs-fade" class="global-tabs">
                        <span
                              @click="filterResults(globals.EXPERIENCE)"
                              :class="{'active-filter': activeFilter == globals.EXPERIENCE, 'link-disabled' : counts.experiences === 0}"
                              class="filter-btn sub-text">
                            EXPERIENCES<span class="hidden-sm"> ({{ counts.experiences }})</span>
                        </span>
                    </transition>

                    <transition name="global-tabs-fade" class="global-tabs">
                        <span
                                @click="filterResults(globals.LOCATION)"
                                :class="{'active-filter': activeFilter == globals.LOCATION, 'link-disabled' : counts.locations === 0}"
                                class="filter-btn sub-text">
                            LOCATIONS<span class="hidden-sm"> ({{ counts.locations }})</span>
                        </span>
                    </transition>

                    <transition name="global-tabs-fade" class="global-tabs">
                        <span
                              @click="filterResults(globals.LODGE)"
                              :class="{'active-filter': activeFilter == globals.LODGE, 'link-disabled' : counts.lodges === 0}"
                              class="filter-btn sub-text">
                            LODGES<span class="hidden-sm"> ({{ counts.lodges }})</span>
                        </span>
                    </transition>

                    <transition name="global-tabs-fade" class="global-tabs">
                        <span
                              @click="filterResults(globals.TRIPS)"
                              :class="{'active-filter': activeFilter == globals.TRIPS, 'link-disabled' : counts.trips === 0}"
                              class="filter-btn sub-text">
                            TRIPS<span class="hidden-sm"> ({{ counts.trips }})</span>
                        </span>
                    </transition>

                    <transition name="global-tabs-fade" class="global-tabs">
                        <span
                              @click="filterResults(globals.RESOURCE)"
                              :class="{'active-filter': activeFilter == globals.RESOURCE, 'link-disabled' : counts.resources === 0}"
                              class="filter-btn sub-text">
                            RESOURCES<span class="hidden-sm"> ({{ counts.resources }})</span>
                        </span>
                    </transition>

                    <transition name="global-tabs-fade" class="global-tabs">
                        <span
                              @click="filterResults(globals.MAGAZINE)"
                              :class="{'active-filter': activeFilter == globals.MAGAZINE, 'link-disabled' : counts.magazines === 0}"
                              class="filter-btn sub-text">
                            MAGAZINES<span class="hidden-sm"> ({{ counts.magazines }})</span>
                        </span>
                    </transition>
                </p>
            </transition-group>

            <div class="search-input-container">
                <input class="search-input form-control f40"
                       type="text"
                       ref="input"
                       :placeholder="placeholder"
                       v-model="searchTerm"
                       @keyup="throttledSearchSuggest">

                <transition name="fade">
                    <span v-if="searchTerm" class="sub-text clear-btn" @click="clearSearch">Clear</span>
                </transition>
            </div>
        </div>

        <transition name="fade-in-search" appear>
            <div class="global-search-results vh-fix" :class="{ active: this.searchTerm.length > 2}" v-if="showResults">
                <transition-group name="search-results-list" tag="div" class="search-results-list" mode="in-out" v-if="results.length > 0">
                    <global-search-card
                            v-for="(result, index) in results"
                            @close_modal="closeNav"
                            :result="result"
                            :index="index"
                            :key="index + result.title"
                            :searchTerm="searchTerm">
                    </global-search-card>
                </transition-group>

                <!--<transition name="fade-in-search" appear>-->
                    <!--<div class="no-results" v-if="this.loading">-->
                        <!--<p class="sub-text">Loading ...</p>-->
                    <!--</div>-->
                <!--</transition>-->


                <transition name="fade-in-search" appear v-if="results.length === 0">
                    <div class="search-results-list">
                        <div class="no-results">
                            <p class="sub-text">No results found</p>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>

        <div class="global-search-results">
            <nav class="mob-link-container">
                <div class="mob-link-container-inner -margin-bottom">
                    <p class="sub-text">YOUR SAFARI</p>

                    <router-link :to="{ name: 'home' }"  class="link" :class="{'-highlight-exact-only': (this.$route.name !== 'home')}">
                        <span class="inner" @click="tryCloseNav">Home</span>
                    </router-link>

                    <router-link :to="{ path: hardcodedUrls.resources.gettingStarted }"  class="link">
                        <span class="inner" @click="tryCloseNav">Getting started</span>
                    </router-link>

<!--                    <a class="link -finder"-->
<!--                       href="#"-->
<!--                                 @click="resourceLinkOnFinder"-->
<!--                                 :class="{'router-link-active-finder': this.$route.name == 'single-resource' || (pageIsFinder && currentSearchType === 'resource')}">-->
<!--                        <span class="inner" @click="tryCloseNav">Meet the team</span>-->
<!--                    </a>-->

                    <router-link :to="{ name: 'contact' }"  class="link">
                        <span class="inner" @click="tryCloseNav">Contact</span>
                    </router-link>
                </div>

                <div class="mob-link-container-inner">
                    <p class="sub-text">EXPLORE AFRICA</p>

                    <router-link :to="hardcodedUrls.finder.experiences" class="link -finder"
                                 :class="{'router-link-active-finder': this.$route.name == 'single-experience' || (pageIsFinder && currentSearchType === 'experience')}">
                        <span class="inner" @click="tryCloseNav">Experiences</span>
                    </router-link>

                    <router-link :to="hardcodedUrls.finder.lodges" class="link -finder"
                                 :class="{'router-link-active-finder': this.$route.name == 'single-lodge' || (pageIsFinder && currentSearchType === 'lodge')}">
                        <span class="inner" @click="tryCloseNav">Lodges</span>
                    </router-link>

                    <router-link :to="hardcodedUrls.finder.locations" class="link -finder"
                                 :class="{'router-link-active-finder': this.$route.name == 'single-location' || (pageIsFinder && currentSearchType === 'location')}">
                        <span class="inner" @click="tryCloseNav">Locations</span>
                    </router-link>

                    <router-link :to="hardcodedUrls.finder.trip_collections" class="link -finder"
                                 :class="{'router-link-active-finder': this.$route.name == 'safari-trip-collection' || (pageIsFinder && currentSearchType === 'trip')}">
                        <span class="inner" @click="tryCloseNav">Trips</span>
                    </router-link>

                    <router-link :to="hardcodedUrls.finder.articles" class="link -finder"
                                 :class="{'router-link-active-finder': this.$route.name == 'articles' || (pageIsFinder && currentSearchType === 'article')}">
                        <span class="inner" @click="tryCloseNav">Magazine</span>
                    </router-link>

                    <router-link :to="hardcodedUrls.finder.resources" class="link -finder"
                                 :class="{'router-link-active-finder': this.$route.name == 'single-resource' || (pageIsFinder && currentSearchType === 'resource')}">
                        <span class="inner" @click="tryCloseNav">Resources</span>
                    </router-link>

                    <router-link :to="{ name: 'wishlist' }"  class="link">
                        <span class="inner" @click="tryCloseNav">Wishlist<span class="wishlist-count" v-if="wishlistCount">{{wishlistCount}}</span></span>
                    </router-link>
                </div>

                <div class="nav-social-icons">
                    <a href="https://www.facebook.com/africatravelresource/">
                        <i class=" icon ion-social-facebook"></i>
                    </a>

                    <a href="https://www.instagram.com/atr.semasema/">
                        <span class=" icon insta-icon">
                           <span class="img-container">
                                <img src="/assets/img/icons/insta-icon.svg" alt="">
                           </span>
                        </span>
                    </a>

                    <a href="https://www.youtube.com/channel/UCVeuUMdt2aQWIDxUOxiQ__A">
                        <span class=" icon insta-icon">
                           <span class="img-container">
                                <img src="/assets/img/icons/youtube-icon.svg" alt="">
                           </span>
                        </span>
                    </a>
                </div>
            </nav>
        </div>

        <div class="in-context-menu vh-fix">
            <template v-if="!currentItem.type">
                <button-internal-presentational :router_object="{ path: '/' }" classes="in-context-image">
                    <div class="bg-image vignette -bottom" :style="{'background-image': 'url(' + currentItem.image_url + ')' }"></div>

                    <div class="in-context-caption">
                        <p class="heading heading3">{{currentItem.title}}</p>
                    </div>
                </button-internal-presentational>
            </template>
            <template v-else>
                <div class="in-context-image vignette vignette1" :style="{'background-image': 'url(' + currentItem.image_url + ')' }">
                    <div class="in-context-caption">
                        <p class="heading heading3">{{currentItem.title}}</p>
                    </div>
                </div>
            </template>

            <div class="smart-links">
                <div class="smart-links-inner">
                    <template v-if="currentItem.item && currentItem.type === 'location'">

                        <div class="sub-text">Save</div>
                        <outs-wishlist :classes="['smart-wishlist']"
                                       :item="currentItem.item"
                                       :itemType="currentItem.type">
                        </outs-wishlist>

                        <div class="sub-text">BEST</div>
                        <outs-list :classes="['smart-links-list']"
                                   :prefix="['Best lodges in']"
                                   type="best"
                                   :item="currentItem.item"
                                   :types="['lodge']"
                                   :itemType="currentItem.type">
                        </outs-list>

                        <div class="sub-text">Alternatives</div>
                        <outs-list :classes="['smart-links-list']"
                                   :prefix="['Best locations in']"
                                   type="location"
                                   :types="['location']"
                                   :item="currentItem.item"
                                   :itemType="currentItem.type">
                        </outs-list>
                    </template>

                    <template v-if="currentItem.item && currentItem.type === 'lodge'">

                        <div class="sub-text">Save</div>
                        <outs-wishlist :classes="['smart-wishlist']"
                                       :item="currentItem.item"
                                       :itemType="currentItem.type">
                        </outs-wishlist>

                        <div class="sub-text">Alternatives</div>
                        <outs-list :classes="['smart-links-list']"
                                   :prefix="['Best lodges in']"
                                   type="lodge"
                                   :types="['lodge']"
                                   :item="currentItem.item"
                                   :itemType="currentItem.type">
                        </outs-list>
                    </template>

                    <template v-if="currentItem.item && currentItem.type === 'experience'">

                        <div class="sub-text">Save</div>
                        <outs-wishlist :classes="['smart-wishlist']"
                                       :list="[...currentItem.item]"
                                       :item="currentItem.item"
                                       :itemType="currentItem.type">
                        </outs-wishlist>

                        <div class="sub-text">BEST</div>
                        <outs-list :classes="['smart-links-list']"
                                   :prefix="['Best locations for', 'Best lodges for']"
                                   :list="[...currentItem.item]"
                                   :itemType="currentItem.type"
                                   :types="['location', 'lodge']">
                        </outs-list>
                    </template>

                    <template v-if="currentItem.item && currentItem.type === 'trip'">

                        <div class="sub-text">Save</div>
                        <outs-wishlist :classes="['smart-wishlist']"
                                       :list="[...currentItem.item]"
                                       :item="currentItem.item"
                                       :itemType="currentItem.type">
                        </outs-wishlist>
                    </template>

                    <!--<template>-->
                        <!--<div class="sub-text">CONTACTS</div>-->

                        <!--<ul class="smart-links-list">-->
                            <!--<li>-->
                                <!--<button-internal-presentational classes="link" :router_object="{ name: 'contact-call' }"-->
                                    <!--v-on:btn-internal-clicked="googleAnalytics.fireEvent('Call', 'Click Contact Call Us', $route.path)">-->
                                    <!--Call us-->
                                <!--</button-internal-presentational>-->
                            <!--</li>-->

                            <!--<li>-->
                                <!--<button-internal-presentational classes="link" :router_object="{ name: 'call-back' }"-->
                                    <!--v-on:btn-internal-clicked="googleAnalytics.fireEvent('Call Back', 'Click Contact Call Back', $route.path)">-->
                                    <!--Get us to call you-->
                                <!--</button-internal-presentational>-->
                            <!--</li>-->

                            <!--<li>-->
                                <!--<button-internal-presentational classes="link" :router_object="{ name: 'quote' }"-->
                                    <!--v-on:btn-internal-clicked="googleAnalytics.fireEvent('Quote', 'Click Contact Inquiry', $route.path)">-->
                                    <!--Make a travel inquiry-->
                                <!--</button-internal-presentational>-->
                            <!--</li>-->

                            <!--<li>-->
                                <!--<button-internal-presentational classes="link" :router_object="{ name: 'email' }"-->
                                    <!--v-on:btn-internal-clicked="googleAnalytics.fireEvent('Email', 'Click Contact Email', $route.path)">-->
                                    <!--Send us an email-->
                                <!--</button-internal-presentational>-->
                            <!--</li>-->
                        <!--</ul>-->
                    <!--</template>-->
                </div>

                <div class="faqs-container -on-content-page">

                    <button-internal-presentational :router_object="{ path: hardcodedUrls.resources.gettingStarted }"  classes="in-context-image">
                        <div class="bg-image vignette -bottom" :style="{'background-image': 'url(/assets/img/nav/1-start.jpeg)' }"></div>

                        <div class="in-context-caption">
                            <div class="sub-text">GETTING STARTED</div>

                            <div class="link -faq" >
                                Who are you and what do you do?
                            </div>
                        </div>
                    </button-internal-presentational>

                    <button-internal-presentational :router_object="{ path: hardcodedUrls.resources.howToBook}" classes="in-context-image">
                        <div class="bg-image vignette -bottom" :style="{'background-image': 'url(/assets/img/nav/2-how-to-book.jpeg)' }"></div>

                        <div class="in-context-caption">
                            <div class="sub-text">HOW TO BOOK</div>

                            <div class="link -faq">
                                How do we get started arranging a great safari?
                            </div>
                        </div>
                    </button-internal-presentational>

                    <button-internal-presentational :router_object="{ path: hardcodedUrls.resources.howMuchToSpend }" classes="in-context-image">
                        <div class="bg-image vignette -bottom" :style="{'background-image': 'url(/assets/img/nav/3-how-much-to-spend.jpeg)' }"></div>

                        <div class="in-context-caption">
                            <div class="sub-text">HOW MUCH TO SPEND</div>

                            <div class="link -faq">
                                How much do we need to splash out for quality safari in Africa?
                            </div>
                        </div>
                    </button-internal-presentational>

                    <button-internal-presentational :router_object="{ path: hardcodedUrls.resources.howToSaveMoney }" classes="in-context-image">
                        <div class="bg-image vignette -bottom" :style="{'background-image': 'url(/assets/img/nav/4-save-money.jpeg)' }"></div>

                        <div class="in-context-caption">
                            <div class="sub-text">HOW TO SAVE MONEY</div>

                            <div class="link -faq">
                                How do we get a great safari trip without having to first rob a bank?
                            </div>
                        </div>
                    </button-internal-presentational>

                    <button-internal-presentational :router_object="{ path: hardcodedUrls.resources.whatMakesGood }" classes="in-context-image">
                        <div class="bg-image vignette -bottom" :style="{'background-image': 'url(/assets/img/nav/5-good-safari.jpeg)' }"></div>

                        <div class="in-context-caption">
                            <div class="sub-text">WHAT MAKES A GOOD SAFARI?</div>

                            <div class="link -faq">
                                How do we tell the difference between a good and a bad safari?
                            </div>
                        </div>
                    </button-internal-presentational>

                    <button-internal-presentational :router_object="{ path: hardcodedUrls.resources.firstTime }" classes="in-context-image">
                        <div class="bg-image vignette -bottom" :style="{'background-image': 'url(/assets/img/nav/6-first-time-safari.jpeg)' }"></div>

                        <div class="in-context-caption">
                            <div class="sub-text">FIRST TIME SAFARI</div>

                            <div class="link -faq">
                                Where should we go on our first Africa safari?
                            </div>
                        </div>
                    </button-internal-presentational>

                    <button-internal-presentational :router_object="{ path: hardcodedUrls.resources.advancedSafariOptions }" classes="in-context-image">
                        <div class="bg-image vignette -bottom" :style="{'background-image': 'url(/assets/img/nav/7-advanced-safari.jpeg)' }"></div>

                        <div class="in-context-caption">
                            <div class="sub-text">ADVANCED SAFARI OPTIONS</div>

                            <div class="link -faq">
                                Any pointers on some more off the beaten track locations?
                            </div>
                        </div>
                    </button-internal-presentational>

                    <button-internal-presentational :router_object="{ path: hardcodedUrls.resources.howLongToSpend }" classes="in-context-image">
                        <div class="bg-image vignette -bottom" :style="{'background-image': 'url(/assets/img/nav/8-how-long-to-spend.jpeg)' }"></div>

                        <div class="in-context-caption">
                            <div class="sub-text">HOW LONG TO SPEND IN AFRICA</div>

                            <div class="link -faq">
                                What length of time would be sensible to deploy on a safari trip?
                            </div>
                        </div>
                    </button-internal-presentational>

                    <button-internal-presentational :router_object="{ path: hardcodedUrls.resources.whenToTravel }" classes="in-context-image">
                        <div class="bg-image vignette -bottom" :style="{'background-image': 'url(/assets/img/nav/9-when-to-travel.jpeg)' }"></div>

                        <div class="in-context-caption">
                            <div class="sub-text">WHEN TO TRAVEL TO AFRICA</div>

                            <div class="link -faq">
                                What’s the best time of year to go on safari?
                            </div>
                        </div>
                    </button-internal-presentational>

                    <button-internal-presentational :router_object="{ path: hardcodedUrls.resources.kidsOnSafari }" classes="in-context-image">
                        <div class="bg-image vignette -bottom" :style="{'background-image': 'url(/assets/img/nav/10-kids-on-safari.jpeg)' }"></div>

                        <div class="in-context-caption">
                            <div class="sub-text">KIDS ON SAFARI</div>

                            <div class="link -faq">
                                Is a safari trip suitable for children?
                            </div>
                        </div>
                    </button-internal-presentational>

                    <button-internal-presentational :router_object="{ name: 'contact' }" classes="in-context-image">
                        <div class="bg-image vignette -bottom" :style="{'background-image': 'url(/assets/img/nav/11-get-in-touch.jpg)' }"></div>

                        <div class="in-context-caption">
                            <div class="sub-text">CONTACT</div>

                            <div class="link -faq">
                                How do I get in touch?
                            </div>
                        </div>
                    </button-internal-presentational>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import GlobalVars from "../../../global/GlobalVars";

    export default {
        data(){
            return {
                searchTerm: '',
                oldSearchTerm: '',
                showResults: false,
                results: [],
                activeFilter: '',
                loading: false,
                dirtyInput: false,
                currentItem: {
                    show:false
                },
                counts: {
                    total: 0,
                    locations: 0,
                    trips: 0,
                    lodges: 0,
                    experiences: 0,
                    resources: 0,
                    magazine: 0
                }
            }
        },
        methods: {
            searchSuggest(newTerm){
                this.oldSearchTerm = newTerm;

                if(this.searchTerm.length > 2) {
                    this.submitSearch(newTerm);
                } else if (this.searchTerm.length <= 2) {
                    setTimeout(() => {
                        this.$store.commit(window.store.CLEAR_RESULTS);
                        this.results = [];
                        this.showResults = false;
                    }, 500);
                }
            },
            resourceLinkOnFinder(event){
                this.$router.push({'name': 'finder', query: {
                    'type': 'resource',
                    'id': 6,
                    'experience': 3,
                    'ename': 'Any Experience',
                    'location': 0,
                    'lname': 'Africa'
                }});


              if(this.$route.name === 'finder' || !this.$route.name){
                  setTimeout(() => {
                      window.bus.$emit("searchRefresh");
                      setTimeout(() => {
                          pnpHelper.scroll.scrollTo('#search-results');
                      },0)
                  },100);
              }
            },
            throttledSearchSuggest: _.debounce(function(e) {
                //IF length different run search or not equal to prev search term
                if(this.oldSearchTerm.length !== this.searchTerm.length || this.searchTerm.localeCompare(this.oldSearchTerm) !== 0) {
                    this.searchSuggest(e.target.value);
                }

                if(this.searchTerm.length === 0){
                    this.dirtyInput = false;
                    $('body').removeClass('-global-search-results');
                }
            }, 800),
            clearSearch(){
                this.searchTerm = '';
                this.oldSearchTerm = '';
                this.showResults = false;
                this.$store.dispatch(window.store.CLEAR_AND_RETURN_RESULTS).then(() => {
                    this.results = [];
                });

                this.dirtyInput = false;
                $('body').removeClass('-global-search-results');

                if(!pnpHelper.bootstrap.isXs()) {
                    this.$refs.input.focus();
                }
            },
            submitSearch(){
                this.loading = true;
                this.$store.dispatch(window.store.CLEAR_AND_RETURN_RESULTS).then(() => {
                    this.showResults = false;

                    return this.$store.dispatch(window.store.SET_AND_FETCH_RESULTS, this.searchTerm)
                        .then((response) => {
                            let responseCopy = window._.defaultsDeep([],response);

                            this.results = responseCopy;
                            this.loading = false;
                            this.dirtyInput = true;
                            this.showResults = true;

                            $('body').addClass('-global-search-results');

                            //Set new counts
                            this.counts = {
                                total: this.results.length === 100 ? this.results.length + '+': this.results.length,
                                locations: this.typeCalc('LOCATIONS', GlobalVars.LOCATION),
                                lodges: this.typeCalc('LODGES', GlobalVars.LODGE),
                                trips: this.typeCalc('TRIPS', GlobalVars.TRIP_COLLECTION),
                                experiences: this.typeCalc('EXPERIENCES', GlobalVars.EXPERIENCE),
                                resources: this.typeCalc('RESOURCES', GlobalVars.RESOURCE),
                                magazines: this.typeCalc('MAGAZINES', GlobalVars.ARTICLES),
                            };
                        });
                });
            },
            typeCalc(typeName, typeNum){
                let results = window._.defaultsDeep([], this.globalSearchResults);
                let count = results.filter((el) => el.type === typeNum).length;

                if(count == 100) {
                    count = count + '+';
                }

                return count;
            },
            filterResults(filterType){
                let results = window._.defaultsDeep([], this.globalSearchResults);

                if(filterType < 0){
                    this.results = results;
                    this.activeFilter = '';
                    return;
                } else {
                    this.activeFilter = filterType;
                }

                let filteredList = this.globalSearchResults.filter((el) => {
                    if(el.type === filterType) {
                        return el;
                    }
                });

                this.results = filteredList;
            },
            closeNav(){
                window.bus.$emit('close_nav');
            },
            tryCloseNav(event){
                let $link = $(event.target).parent();

                if(($link.hasClass('router-link-active') && !$link.hasClass('-highlight-exact-only')) ||
                  ($link.hasClass('router-link-exact-active') && $link.hasClass('-highlight-exact-only'))){
                  this.closeNav();
                }

                if($link.hasClass('-finder')) {
                  pnpHelper.scroll.doScroll(0);
                }
            },
            setCurrentItem(){
                let currentItem = {
                    show: true,
                    type: null,
                    title: 'Africa Travel Resource',
                    image_url: '/assets/img/temp/image1_lion_mob.jpg',
                    item: null
                };

                if(this.$route.name === "single-lodge"){

                    currentItem.item = this.$store.state.singleLodge.lodge;
                    currentItem.show = true;
                    currentItem.type = "lodge";
                    currentItem.title = currentItem.item.title;
                    currentItem.image_url = currentItem.item.images && currentItem.item.images.sizes ? currentItem.item.images.sizes.low : null;

                }else if(this.$route.name === "single-location"){

                    currentItem.item = this.$store.state.singleLocation.location;
                    currentItem.show = true;
                    currentItem.type = "location";
                    currentItem.title = currentItem.item.title;
                    currentItem.image_url = currentItem.item.images && currentItem.item.images.sizes ? currentItem.item.images.sizes.low : null;


                }else if(this.$route.name === "single-experience") {

                    currentItem.item = this.$store.state.singleExperience.experience;
                    currentItem.show = true;
                    currentItem.type = "experience";
                    currentItem.title = currentItem.item.title;
                    currentItem.image_url = currentItem.item.image ? currentItem.item.image : null;

                }else if(this.$route.name === "single-trip") {
                    currentItem.item = {...this.$store.state.singleTrip.trip};
                    currentItem.show = true;
                    currentItem.type = "trip";
                    currentItem.title = currentItem.item.trip_code ? currentItem.item.trip_code : currentItem.item.slug;
                    currentItem.item.title = currentItem.title;
                }

                this.currentItem = currentItem;
            }
        },
        computed: {
            globalSearchResults() {
                return this.$store.state.globalSearch.results.data;
            },
            hardcodedUrls() {
                return GlobalVars.HARCODED_URLS;
            },
            placeholder(){
                if(pnpHelper.bootstrap.isXs()){
                    return "Search";
                }else{
                    return "Search for experiences, lodges, locations and resources";
                }
            },
            pageIsFinder(){
              return this.$route.name == 'finder' || this.$route.path.indexOf('/finder/') > -1;
            },
            currentSearchType(){
                return this.$store.state.search.criteria.params.type;
            },
            wishlistCount() {
                let wishlist = [...this.$store.state.wishlist.selected];

                return wishlist.length;
            },
            codeVersion(){
                return GlobalVars.CODE_VERSION;
            },
            isNotProduction(){
                return !window.environment.isProd;
            },
            googleAnalytics() {
                return window.GoogleAnalytics;
            },
            analyticsEventCategory() {
                return 'Add ' + window.GoogleAnalytics.parseVueRouteParams(this.$route.params);
            }
        },
        mounted(){
            if(!pnpHelper.bootstrap.isXs()) {
                this.$refs.input.focus();
            }

            this.globals = {
                'LOCATION': GlobalVars.LOCATION,
                'LODGE': GlobalVars.LODGE,
                'EXPERIENCE': GlobalVars.EXPERIENCE,
                'TRIPS': GlobalVars.TRIP_COLLECTION,
                'RESOURCE': GlobalVars.RESOURCE,
                'MAGAZINE': GlobalVars.ARTICLES,
            };

            this.setCurrentItem();

            //
            // if(pnpHelper.bootstrap.isXs() && $('body').hasClass('page-scroll-hijacked') && window.fullpage){
            //     //Trigger a rebuild of fullpage to cater for more scroll elements
            //     setTimeout(window.fullpage.reBuild, 500);
            // }
        },
        created() {
            window.bus.$on('doingSearch', this.closeNav);
            this.$store.commit(window.store.FETCH_AND_SET_WISHLIST);
        },
        destroyed() {
            $('body').removeClass('-global-search-results');
            window.bus.$off('doingSearch', this.closeNav);
        }
    }
</script>
