(function($) {
    require('./../libs/jquery.hoverIntent');

    //Sidenav bits
    window.$.sidenav = function(opts){
        var numSlides; //Add one for 0
        var $sidenav;
        var $fpWrapper;
        var $sidenavBar;
        var navIsOpen;
        var _this = this;
        //
        // if(!$('#sidenav').hasClass('-init') && !$('body').hasClass('page-home')) {
        //     $('#sidenav').addClass('-init');
        //
        //     console.log('calling init');
        //     //init();
        //
        //     $('#sidenav').show();
        // }

        function init(){

            numSlides = ($('#content .section:not([data-pnp-skip-sidenav])').length); //Add one for 0
            $sidenav = $('#sidenav');
            $fpWrapper = $('.fullpage-wrapper');
            $sidenavBar = $sidenav.find('.bar');
            navIsOpen = false;

            $('body').addClass('-has-side-nav');

            //Generate the mobile menu
            var mobMenuHtml = "";

            if (opts && opts.showActiveLabel) {
                $sidenav.addClass('-show-active-label');
            }

            //Generate the anchor points for desktop
            var pipsHtml = "";
            var i = 0;

            $('#content .section').each(function(index){
                //If we're not meant to skip this section
                if(!$(this).attr('data-pnp-skip-sidenav')) {

                    var top = ((100 / numSlides) * i).toFixed(2);
                    var $thisSection = $(document).find('#content .section').eq(index);
                    var slideId = 'pnp-section-' + index;

                    $thisSection.attr('id', slideId);

                    //Build the label
                    if (i < 9) {
                        var label = "0" + (i + 1);
                    } else {
                        var label = (i + 1);
                    }

                    var text = $thisSection.find('[data-pnp-heading]').attr('data-pnp-heading');

                    if (!text || text.length === 0) {
                        text = $thisSection.find('[data-pnp-heading]:first').text();
                    }

                    label = "<span class='num'>" + label + "</span>" +
                        "<span class='lbl'>" + text + "</span>";

                    //Desktop
                    pipsHtml += "<span class='pip' id='pip_" + slideId + "' style='top:" + top + "%'></span>";
                    pipsHtml += "<a href='#' class='pip-text' data-pnp-pip-id='" + slideId + "' id='pip-text_" + slideId + "' style='top:" + top + "%'>" + label + "</a>"

                    // //Build any sub-sections (e.g. gallery within a section)
                    // var $subSections = $thisSection.find('.pnp-slide');
                    // if($subSections.length){
                    //     //console.log('found subsections ', $subSections.length);
                    //     pipsHtml += "<span id='sub-bar_" + slideId + "' class='sub-bar' style='top:" + top + "%'></span>";
                    // }

                    //Mobile
                    var $bgImage = $thisSection.find('.fp-bg');
                    var image = $bgImage.css('background-image');
                    if(!image || image.length === 0 || image === "none"){
                        image = "url(" + $bgImage.data('lazy-bg-image') + ");";
                    }

                    mobMenuHtml += "<a class='mob-item' id='mob-pip_" + slideId + "' data-pnp-pip-id='" + slideId + "'>";
                    //mobMenuHtml += "<a class='mob-item vignette vignette-mob-1' data-pnp-pip-id='" + slideId + "' style='background-image:" + image + "'>";
                    mobMenuHtml += "<span class='inner'>" + label + "</span>";
                    mobMenuHtml += "</a>";

                    i++;
                }
            });
            //console.log(pipsHtml,$sidenavBar.length);
            $sidenavBar.append(pipsHtml);
            $sidenav.find('.menu').append("<div class='mob-container'>" + mobMenuHtml + "</div>");


            if(pnpHelper.bootstrap.isSm()) {
                //Show the sidenav options by default on load, and then hide them again
                window.pnpHelper.sidenav.open();

                setTimeout(function () {
                    window.pnpHelper.sidenav.close();
                }, 2000);
            }
        }

        //Generate the onpage nav options
        window.pnpHelper.sidenav = {
            refresh: function(){

                $('#sidenav').find('.menu').html("");
                $('#sidenav').find('.bar').html("");

                //numSlides = ($('#content .section:not([data-pnp-skip-sidenav])').length);

                init();

                //window.pnpHelper.sidenav.setSlide(newSectionId);

            },
            //Starts at 0
            setSlide : function(slideId, minorIndex, minorIndexMax){

                // console.info('Setting slide to ' + slideId + ' (Sub section ' + (minorIndex + 1) + ' of ' + minorIndexMax + ')');

                // $('.sub-bar').css('height', '0');

                var $currPip = $('.pip.-active, .pip-text.-active');
                // var closePip = false;

                //Optionally show the active slide
                if ($sidenav.hasClass('-show-active-label')) {
                    var $nextPip = $('#pip-text_' + slideId);

                    //Hide the old pip
                    if(!navIsOpen) {
                        $currPip.css('transform', 'translateX(0)');
                        //TODO: If current pip does not have children
                            //$currPip.css('opacity', '0');

                    } else {
                        // closePip = true;
                        $('.sub-bar').css({
                            transitionTimingFunction: 'linear',
                            height: 0
                        });
                    }


                    // this.closeSubBar(slideId);
                    $nextPip.css('transform', 'translateX(-' + ($nextPip.outerWidth()) + 'px)');
                    //$nextPip.css('opacity', '1');
                }

                $('.pip.-active, .pip-text.-active, .mob-item.-active').removeClass('-active');
                $('#pip_' + slideId + ',#pip-text_' + slideId + ',#mob-pip_'+ slideId).addClass('-active');

                //Get the index of the nav to scroll to
                var navIndex = -1;
                $('.pip').each(function (index) {
                    if ($(this).attr('id') === 'pip_' + slideId) {
                        navIndex = index;
                        return;
                    }
                });

                if (navIndex === 0) {
                    $sidenavBar.css('height', '0');
                } else {
                    // if (closePip) {
                    //     setTimeout(function() {
                    //         $sidenavBar.css('height', Math.round((100 / numSlides) * navIndex) + '%');
                    //     }, 1200);
                    // } else {
                        $sidenavBar.css('height', Math.round((100 / numSlides) * navIndex) + '%');
                    // }
                }

                if ((typeof minorIndex != undefined && minorIndex > -1) &&
                    (typeof minorIndexMax != undefined && minorIndexMax > -1)) {

                    //If we dont already have a sub-bar for these sections, create one
                    //Build any sub-sections (e.g. gallery within a section)
                    if($('#sub-bar_' + slideId).length === 0){
                        var top = Math.round((100 / numSlides) * navIndex);
                        $('#sidenav .bar').append("<span id='sub-bar_" + slideId + "' class='sub-bar' style='top:" + top + "%'></span>");
                    }

                    //console.log(minorIndex, minorIndexMax);
                    var $subBar = $('#sub-bar_' + slideId);
                    var gapPercent = Math.round(100 / numSlides);
                    $subBar.css('height', ((gapPercent / minorIndexMax) * (minorIndex)) + '%');
                }

                if(!navIsOpen){
                    this.clear();
                }

                if (!_.isUndefined(window.bus)) {
                    window.bus.$emit('changedSlide');
                }

            },
            closeSubBar : function(id){
              $('#sub-bar_' + id).css('height', 0);
            },
            closeAllSubBars : function(disableTransition){
                $('.sub-bar').fadeOut(200, function(){
                    $('.sub-bar').css('height', 0);
                    setTimeout(function(){
                        $('.sub-bar').show();
                    },1000);
                });
            },
            open : function(){
                window.bus.$emit('close_nav');
                navIsOpen = true;
                window.$html.addClass('sidenav-open');
                $sidenav.addClass('-open');

                if (typeof window.fullpage == 'undefined') {
                    $.fn.fullpage.setAllowScrolling(false);
                } else {
                    window.fullpage.setAllowScrolling(false);
                }

                $sidenav.find('.pip-text').each(function(index){
                    var _this = $(this);

                    setTimeout(function(){
                        if(navIsOpen) {
                            _this.css('transform', 'translateX(-' + (_this.outerWidth()) + 'px)');
                            _this.css('opacity', '1');
                        }
                    },70 * index);
                });
            },
            close : function () {
                navIsOpen = false;
                window.$html.removeClass('sidenav-open');
                $sidenav.removeClass('-open');

                if (typeof window.fullpage == 'undefined') {
                    $.fn.fullpage.setAllowScrolling(true);
                } else {
                    window.fullpage.setAllowScrolling(true);
                }

                this.clear();
            },
            clear : function(){
                $sidenav.find('.pip-text').each(function(){
                    if(!($sidenav.hasClass('-show-active-label') && $(this).hasClass('-active'))) {
                        $(this).css('transform', 'translateX(0)');
                        $(this).css('opacity', '0');
                    }
                });
            },
            destroy : function() {
                /** remove() will also unhook all events associated with that element */
                //$sidenav.remove();
                _this.destroy();
            }
        };

        function clickPip(){
            //console.log('clicked ', $(this).data('pnp-pip-id'));

            window.pnpHelper.imageGallery.insideGallery = false;
            var slideId = $(this).data('pnp-pip-id');

            //Get the index of the slide
            var sectionIndex = -1;
            $('#content .section').each(function(index){
                if($(this).attr('id') === slideId){
                    sectionIndex = index;
                    return;
                }
            });

            var oldSectionIndex = $('#content .section.active').index() - 1;

            //If scrolling down
            if(sectionIndex > oldSectionIndex) {
                window.pnpHelper.sidenav.closeAllSubBars();
            } else {
                for (var i = 1; i <= oldSectionIndex; i++) {
                    $('#pnp-section-' + i).removeClass('-show-gallery black-bg').find('.pnp-slide.-in').removeClass('-in');
                }
            }

            window.pnpHelper.imageGallery.closeGallery();

            if (typeof window.fullpage == 'undefined') {
                $.fn.fullpage.moveTo(sectionIndex + 1);
            } else {
                window.fullpage.moveTo(sectionIndex + 1);
            }

            window.pnpHelper.imageGallery.closeGallery();

            window.pnpHelper.imageGallery.insideGallery = true;

            if(window.pnpHelper.bootstrap.isXs()) {
                window.pnpHelper.sidenav.close();
            }

            //Iterate through all the image galleries above this one and open them on the last image
            pnpHelper.imageGallery.moveGalleriesToEnd(sectionIndex);

            return false;
        }

        function pageAfterDomInit(){
            window.pnpHelper.sidenav.refresh();

            //Initialise the first pip
            window.pnpHelper.sidenav.setSlide($('#content .section').eq(0).attr('id'));

            var activeSectionIndex = window.$('.fp-section.active').index();
            if (window.$('.fp-section.active').data('pnp-showing-gallery')) {
                var activeSlideIndex = window.$('.fp-section.active').find('.pnp-slide.-in').index() - 2;
                var activeMaxIndex = window.$('.fp-section.active').find('.pnp-slide').length;
                window.pnpHelper.sidenav.setSlide('pnp-section-' + activeSectionIndex, activeSlideIndex, activeMaxIndex);
            } else {
                window.pnpHelper.sidenav.setSlide('pnp-section-' + activeSectionIndex, -1, -1);
            }

            window.bus.$emit('sidenavReady');
        }

        function clickMobileSideNav(){
            //console.log('click');
            if(navIsOpen){
                window.pnpHelper.sidenav.close();
            }else {
                window.pnpHelper.sidenav.open();
            }

            return false;
        }

        var isHoveringOverSidenav = false;

        //Bind hover over nav to open
        $('#sidenav,.header-right').hoverIntent(function(){
            if(window.pnpHelper.bootstrap.isSm()) {
                isHoveringOverSidenav = true;
                window.pnpHelper.sidenav.open();
            }
        }, function(){
            if(window.pnpHelper.bootstrap.isSm()) {
                isHoveringOverSidenav = false;
                setTimeout(function () {
                    if(!isHoveringOverSidenav) {
                        window.pnpHelper.sidenav.close();
                    }
                }, 500);
            }
        });

        //Bind anchor clicks
        $('body').on('fullPageAfterDomInit', pageAfterDomInit);
        window.$document.on('click', '[data-pnp-pip-id]', clickPip);
        window.$document.on('click', '#sidenav .veil', window.pnpHelper.sidenav.close);
        window.$document.on('click', '.mob-sidenav-toggle', clickMobileSideNav);

        this.destroy = function(){
            //console.log("DESTORY");
            window.bus.$once('do-blanket-tasks', function(){
                $('body').removeClass('-has-side-nav');
            });

            $('body').off('fullPageAfterDomInit', pageAfterDomInit);
            window.$document.off('click', '[data-pnp-pip-id]', clickPip);
            window.$document.off('click', '#sidenav .veil', window.pnpHelper.sidenav.close);
            window.$document.off('click', '.mob-sidenav-toggle', clickMobileSideNav);

            //Unbind hover intent
            $('#sidenav, .header-right').unbind("mouseenter").unbind("mouseleave");
            $('#sidenav, .header-right').removeProp('hoverIntent_t');
            $('#sidenav, .header-right').removeProp('hoverIntent_s');
        }
    }

}(jQuery));
