<template>
    <section class="component-text -outs section" data-pnp-half-map="true" data-pnp-interactive-map="true">
        <div class="left-panel no-padding">
            <div class="inner" :data-pnp-heading="tag">
                <h2 class="heading4 out-editor-title" :data-block-id="_id" v-html="title" :contenteditable="editing" v-if="edit === false"></h2>
                <div class="on-page-editor-container" v-else>
                    <h2 class="heading4 out-editor-title" :data-block-id="_id" v-html="title" :contenteditable="editing"></h2>
                    <div class="smallEditButtons -horizontal" v-bind:class="{ '-editing': editing }">
                        <div v-on:click="createEditor('out-editor-title')" class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton"></div>
                        <div v-on:click="saveEditor('out-editor-title')" class="icon ion-ios-checkmark onPageEditor-smallButton btn-success smallSaveButton"
                             v-if="editing===true"></div>
                        <div v-on:click="cancelEditor('out-editor-title')" class="icon ion-ios-close onPageEditor-smallButton btn-danger smallCancelButton"
                             v-if="editing===true"></div>
                    </div>
                </div>
            </div>

            <div class="suggested">
                <div class="on-page-editor-container" v-if="edit === true">
                    <ul class="terms out-editor-links">
                        <li v-for="(link, key) in content">
                            <a :href="link == null ? '#' : link.url" :id="'outs-link-' + key + '-' + _id">
                                {{ link == null ? '' : link.text }}
                            </a>
                        </li>
                    </ul>
                    <div class="smallEditButtons show-on-top">
                        <div data-toggle="modal" :data-target="'#linkEditorModal_' + _id"
                             class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton"
                             :data-block-id="_id"></div>
                    </div>
                </div>
                <ul class="terms out-editor-links" v-else>
                    <li v-for="(link, key) in content">
                        <a :href="link == null ? '#' : link.url" :id="'outs-link-' + key + '-' + _id">
                            {{ link == null ? '' : link.text }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
    import StateUtils from '../../../global/StateUtils'
    export default {
        props: ['content', 'tag', 'title', '_id', 'edit', 'type'],
        data(){
            return {
                editing: false,
            }
        },
        methods: {
            createEditor(which) {
                if(!window._.isUndefined(this.editorName) && this.editorName!==null){
                    alert('You are currently editing a different bit on the page.\nSave or Cancel it before starting editing on a new instance.');
                } else {
                    this.editing = true;
                    Vue.nextTick(() => {
                        var editor = CKEDITOR.inline(this.$el.querySelector('.on-page-editor-container .' + which), window.ckeditorConfig);
                        this.$el.querySelector('.on-page-editor-container .' + which).click();
                        this.editorName = editor.name;
                        this.$store.commit(window.store.SET_EDITOR_CKEDITOR, true, {root: true});
                    });
                }
            },
            cancelEditor(which) {
                this.editing = false;
                if (this.editorName !== null) {
                    CKEDITOR.instances[this.editorName].container.$.innerHTML = this.lodge.top_section.content;
                    CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;
                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, { root: true });
                }
            },
            saveEditor(which) {

                var stateObject = new StateUtils(this.type, this.$store).getStateObject();

                if (this.editorName !== null) {
                    if(this.type.indexOf('_trips')!==-1){
                        stateObject.trips_section.title = CKEDITOR.instances[this.editorName].container.$.innerHTML;
                    } else if(this.type.indexOf('_other_lodges')!==-1){
                        stateObject.other_lodges_section.title = CKEDITOR.instances[this.editorName].container.$.innerHTML;
                    }

                    new StateUtils(this.type, this.$store).setStateObject(stateObject, null);
                    this.editing = false;
                    CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;
                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, { root: true });
                }

            }
        }
    }
</script>
