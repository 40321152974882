<template>
    <div class="modal fade" id="page_metadata_modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">Page Meta</h4>
                </div>
                <div class="modal-body">
                    <div class="row form-inline">
                        <div class="form-group col-md-4">
                            <label class="control-label">Meta Type</label>
                            <select class="form-control" name="button-action" v-model="meta[0].type">
                                <option value="video">Video</option>
                            </select>
                        </div>
                        <div class="form-group col-md-8">
                            <label class="control-label">Video URL</label>
                            <input class="form-control" type="text" name="button-text" value="Read More" v-model="meta[0].data"/>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="form-group col-md-12">
                        <button class="btn btn-sm btn-primary pull-right" @click="savePageMeta"><i class="ion ion-android-checkmark-circle"></i> Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import StateUtils from '../../global/StateUtils'

    export default {
        props: ['type'],
        computed: {
            stateObject: function () {
                return new StateUtils(this.type, this.$store).getStateObject();
            }
        },
        data(){
            return {
                meta: [
                    {
                        type: "video",
                        data: ""
                    }
                ]
            }
        },
        methods: {
            savePageMeta(){
                let obj = this.stateObject;
                obj.page_meta = this.meta;
                new StateUtils(this.type, this.$store).manageState(obj);
            },
        },
        mounted() {
            if(this.stateObject.page_meta.length) {
                this.meta = [];
                for (let x in this.stateObject.page_meta) {
                    let t = _.defaultsDeep(this.stateObject.page_meta[x], {});
                    this.meta.push(t);
                }
            }
        }
    }
</script>
