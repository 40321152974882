<template>

    <div v-if="!isLoading" class="map-popover">
        <!--<p v-if="type == 'location'" @click="updateSearch">Best lodges in this location</p>-->

        <div class="popover-container">
            <div class="icon-row">
                <span class="wishlist-icon"
                      :class="{ '-active': checkIfOn(data) }"
                      @click="toggleItem(data, $event)">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         width="512px" height="512px" viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve">
                    <path d="M359.385,80C319.966,80,277.171,97.599,256,132.8C234.83,97.599,192.034,80,152.615,80C83.647,80,32,123.238,32,195.779
                        c0,31.288,12.562,71.924,40.923,105.657c28.359,33.735,45.229,51.7,100.153,88C228,425.738,256,432,256,432s28-6.262,82.924-42.564
                        c54.923-36.3,71.794-54.265,100.153-88C467.438,267.703,480,227.067,480,195.779C480,123.238,428.353,80,359.385,80z"/>
                    </svg>

                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve">
                    <path  stroke-width="30" d="M359.385,80C319.966,80,277.171,97.599,256,132.8C234.83,97.599,192.034,80,152.615,80C83.647,80,32,123.238,32,195.779
                        c0,31.288,12.562,71.924,40.923,105.657c28.359,33.735,45.229,51.7,100.153,88C228,425.738,256,432,256,432s28-6.262,82.924-42.564
                        c54.923-36.3,71.794-54.265,100.153-88C467.438,267.703,480,227.067,480,195.779C480,123.238,428.353,80,359.385,80z
                         M426.83,291.141c-26.852,31.94-42.18,48.895-96.729,84.947c-40.237,26.596-65.252,36.301-74.102,39.233
                        c-8.85-2.933-33.864-12.639-74.102-39.233c-54.548-36.052-69.876-53.006-96.729-84.948c-12.065-14.351-21.857-31.274-28.316-48.943
                        C51.062,226.355,48,210.304,48,195.779c0-30.284,10.131-55.643,29.297-73.335c9.14-8.437,20.158-14.992,32.752-19.484
                        C122.994,98.341,137.315,96,152.615,96c38.018,0,73.217,17.682,89.674,45.046L256,163.844l13.711-22.798
                        C286.169,113.682,321.368,96,359.385,96c15.3,0,29.621,2.341,42.566,6.959c12.594,4.493,23.612,11.048,32.752,19.484
                        C453.869,140.136,464,165.495,464,195.779c0,14.525-3.062,30.576-8.854,46.418C448.688,259.865,438.896,276.789,426.83,291.141z"/>
                    </svg>
                </span>

                <span class="info-icon" @click="toggleInfo">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" >
                        <path d="M256 90c44.3 0 86 17.3 117.4 48.6C404.7 170 422 211.7 422 256s-17.3 86-48.6 117.4C342 404.7 300.3 422 256 422s-86-17.3-117.4-48.6C107.3 342 90 300.3 90 256s17.3-86 48.6-117.4C170 107.3 211.7 90 256 90m0-42C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"/>
                        <path d="M277 360h-42V235h42v125zm0-166h-42v-42h42v42z"/>
                    </svg>
                </span>
            </div>

            <public-search-image-slider
                    class="-popover"
                    :type="typeString"
                    v-if="data.images"
                    :cover-image="data.images.data.sizes.low"
                    :caption="data.images.data.caption"
                    :showCaptions="true"
                    :subTitle="type == globalVars.LODGE ? criticalParent.title : null"
                    :title="data.title"
                    :id="Number(id)"/>

            <div class="stats">
                <div class="rating">
                    <span class="stat">{{ data.rating }}</span>
                    <br>
                   <span class="sub-text">LODGE <br>RATING</span>
                </div>

                <div class="price">
                    <span class="stat">{{ priceCalc }}</span>
                    <br>
                    <span class="sub-text">GUIDE PRICE <br> RANGE {{ this.$store.state.user.user.selected_currency}} PPPN</span>
                </div>
            </div>

            <div class="map-pop-info" :class="{'-in': showInfo}">

                <span class="icon-row">
                    <i class="ion-ios-close-empty" @click="toggleInfo"></i>
                </span>

                <h4 class="heading">{{data.title}}</h4>

                <div>
                    <span v-html="data.content">
                    </span>
                </div>
            </div>
        </div>

        <div class="links">
            <div class="link">
                <button-internal-presentational
                        v-if="type == globalVars.LODGE"
                        :router_object="{ name: 'single-lodge', params: data.vue_route_params.data }"
                        icon="ion-ios-arrow-thin-right">
                    Go to lodge

                </button-internal-presentational>

                <button-internal-presentational
                        v-if="type == globalVars.LOCATION"
                        :router_object="{ name: 'single-location', params: data.vue_route_params.data }"
                        icon="ion-ios-arrow-thin-right">
                    Go to location

                </button-internal-presentational>
            </div>

            <div class="link -best" v-if="criticalParent.id !== currentSearchLocationId">
                <button-internal-presentational
                        @btn-internal-clicked="emitUpdateSearchEvent()"
                        :router_object="{
                            name: 'finder',
                            query: {
                                type: 'lodge',
                                location: criticalParent.id,
                                lname: criticalParent.title,
                                experience: 3,
                                ename: 'Any Experience'
                            }
                        }"
                        icon="ion-ios-arrow-thin-right">
                    Best lodges in this location
                </button-internal-presentational>
            </div>
        </div>
    </div>
</template>

<script>
    import UrlMap from "../../../global/UrlMap";
    import { mapState } from 'vuex';
    import GlobalVars from "../../../global/GlobalVars";

    export default {
        name: "MapPopover",
        props: {
            id: {
                required: true
            },
            type: {
                required: true
            }
        },
        data() {
            return {
                data: {},
                isLoading: true,
                hideTitle: false,
                showInfo: false,
                showPopup: false
            }
        },
        methods: {
            arrowHover(bool){
                this.hideTitle = bool;
            },
            emitUpdateSearchEvent(){

                $('html,body').animate({
                    scrollTop: 0
                }, 500);

                window.bus.$emit('close-map-popover');

                if(this.$route.name !== 'directory'){
                    setTimeout(() => {
                        window.bus.$emit("searchRefresh");
                    }, 100);
                }
            },
            updateSearch() {
                switch (this.type) {
                    case GlobalVars.LODGE :
                        // TODO: Add other half of search here
                    break;
                    case GlobalVars.LOCATION :
                        /** Find best lodges in 'location' */
                        let location = {
                            id: this.data.id,
                            name: this.data.title
                        };

                        this.$store.commit(window.store.SET_SEARCH_TYPE, 'lodge');
                        this.$store.commit(window.store.SET_SEARCH_LOCATION, location);
                        this.$store.commit(window.store.SET_SEARCH_LOCATIONS, [location]);

                        this.$router.push({
                            name: 'finder',
                            query: new UrlMap().searchStateToUrlParams(this.$store.state.search)
                        });

                        window.bus.$emit('searchDoSearch');
                    break;
                }
            },
            toggleInfo(){
                this.showInfo = !this.showInfo;
            },
            checkIfOn(obj) {
                return this.wishlist.some((el) => {
                    return el.id == obj.id;
                });
            },
            toggleItem(searchItem, event) {
                event.preventDefault();

                let newSearchItem =  _.defaultsDeep(searchItem, {
                    type: this.type == GlobalVars.LODGE ? 'lodge' : 'location'
                });

                let newSelectedItems = [...this.wishlist];

                let check = newSelectedItems.some((el) => {
                    return el.id == newSearchItem.id;
                });

                if(check){
                    newSelectedItems = newSelectedItems.filter((el) => {
                        return el.id != newSearchItem.id;
                    });

                    this.$store.commit(window.store.SET_WISHLIST, newSelectedItems);
                } else {
                    newSelectedItems.push(newSearchItem);
                    this.$store.commit(window.store.SET_WISHLIST, newSelectedItems);
                }
            }
        },
        computed: {
            globalVars() {
                return GlobalVars;
            },
            isTypeValid() {
                return this.type != GlobalVars.LODGE && this.type != GlobalVars.LOCATION ? false : true;
            },
            currentSearchLocationId(){
                if(this.$store.state.search.criteria.params.location){
                    return this.$store.state.search.criteria.params.location.id;
                }else{
                    return -1;
                }
            },
            typeString(){
              if(this.type == GlobalVars.LODGE){
                  return "lodge";
              }else if(this.type == GlobalVars.LOCATION){
                  return "location";
              }else{
                  return "";
              }
            },
            priceCalc(){
                if(!this.rates) {
                    return;
                }
                let price = this.data.price.length > 0 ? parseInt(this.data.price.replace(/,/g, '')) : 0;
                let minPrice =  this.data.min_price.length > 0 ? parseInt(this.data.min_price.replace(/,/g, '')) : false;
                let rate = this.rates[this.selected] || 1;

                price = Math.round( price * rate);
                minPrice = Math.round( minPrice * rate);

                if(minPrice && minPrice < price) {
                    return minPrice + ' - ' + price;
                }

                return price > 0 ? price.toLocaleString() : 'n/a';
            },
            criticalParent(){
              if(this.type == GlobalVars.LODGE){
                  return this.data.critical_parent.data;
              }else if(this.type == GlobalVars.LOCATION){
                  return {
                      id: this.data.id,
                      title: this.data.title
                  };
              }
            },
            ...mapState({
                rates: state => state.user.user.rates,
                selected: state => state.user.user.selected_currency,
                directory: state => state.directory,
                wishlist: state => state.wishlist.selected,
            })
        },
        mounted() {

            var _this = this;

            if (!this.isTypeValid) {
                return;
            }
            this.$store.dispatch(this.type == GlobalVars.LODGE ? window.store.FETCH_AND_RETURN_LODGE : window.store.FETCH_AND_RETURN_LOCATION, this.id)
                .then((data) => {
                    this.data = data;
                    this.isLoading = false;
                });
        }
    }
</script>