<template>
    <div class="header-progress">
        <h2 class="heading5-large" :id="id">{{ title }}</h2>
        <div class="progress-bar">
            <div class="progress-bar__inner" :style="{ width: `${progress}%` }"></div>
        </div>
    </div>
    
</template>

<style>
.header-progress .heading5-large{
    margin-bottom: 22px !important;
    color: black;
}
.progress-bar{
    width: 100%;
    height:2px;
    overflow:hidden;
    position: relative;
    background-color: #999;
}

.progress-bar__inner{
    height:100%;
    background-color: #F7BE16;
    position: absolute;
    top:0;
    left:0;
    transition: width 0.5s ease-in-out;
}
</style>

<script>
export default {
    props: {
        title: {
            required: true,
        },
        id: {
            type: String,
            required: false,
            default: '',
        },
        progress: {
            type: Number,
            required: false,
            default: 20,
        },
    },
}
</script>