<template>
        <div>
            <button-internal v-if="is_link" :router_object="route_params" :transition_name="transition_name">
                <div :class="classes">
                    <div :style="{ 'background-image': 'url(' + image + ')' }" class="article-bg-img"></div>

                    <slot v-if="hasSlot"></slot>

                    <div v-else class="caption">
                        <div class="width-container">
                            <h2 class="heading" v-html="title" v-bind:class="{ '-solo': content == ''}"></h2>

                            <div class="sub-heading" v-if="content != ''" v-filter-content v-html="content"></div>

                            <p class="buttons">
                                <button-internal :router_object="route_params" :transition_name="transition_name" classes="btn-round">
                                    <span>View</span>

                                    <i class="ion-ios-arrow-thin-right"></i>
                                </button-internal>
                            </p>
                        </div>
                    </div>
                </div>
            </button-internal>

            <div :class="classes" v-else>
                <div :style="{ 'background-image': 'url(' + image + ')' }" class="article-bg-img"></div>
                <slot v-if="hasSlot"></slot>

                <div v-else class="caption">
                    <p class="heading" v-html="title" v-bind:class="{ '-solo': content == ''}"></p>

                    <div class="sub-heading" v-if="content != ''" v-filter-content v-html="content"></div>

                    <slot name="custom-btn"></slot>
                </div>
            </div>
        </div>
</template>

<script>
    import ButtonInternal from "./ButtonInternal";
    export default {
        components: {ButtonInternal},
        props: {
            image: String,
            title: String,
            content: String,
            route_params: Object,
            transition_name: [String, Function],
            is_link: {
                type: Boolean,
                default() {
                    return !_.isUndefined(this.route_params);
                }
            },
        },
        data() {
            return {
                classes:  ['article-image', 'vignette']
            }
        },
        mounted() {
            if ($('body').hasClass('changed-route')) {
                this.classes.push('disable-transitions');
                $('body').removeClass('changed-route');
            }
        },
        computed: {
            hasSlot () {
                return !_.isEmpty(this.$slots['default']);
            }
        }
    }
</script>