<template>
    <div>
        <a rel="nofollow" href="#" class="mob-sidenav-toggle">
            <i class="ion-android-more-vertical"></i>

            <span class="burger -in">
                <i></i>
            </span>
        </a>

        <aside id="sidenav">
            <div class="veil"></div>

            <div class="menu">
                <!--Generated by js-->
            </div>

            <div class="bar-container">
                <div class="bar">
                    <!--Generated by js-->
                </div>
            </div>
        </aside>
    </div>
</template>

<script>
    export default {
        name: "Sidenav"
    }
</script>