<template>
    <span class="currency-container">
        <span class="currency-selector" v-if="rates">
            <span class="currency sub-text hidden-xs">CHANGE CURRENCY : </span>
            <span
               v-for="currency in Object.keys(rates)"
               class="currency sub-text"
               @click="selectCurrency(currency, $event)"
               :class="{active: selected === currency}">
                <span>{{currency}}</span>
                <br>
            </span>
        </span>
    </span>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        methods: {
            selectCurrency(currency, e){
                if(e){
                    e.preventDefault();
                    e.stopPropagation();
                }

                this.$store.commit(window.store.SET_CURRENCY, currency);

                this.$emit('currencyChanged');
            }
        },
        computed: {
            ...mapState({
                selected: state => state.user.user.selected_currency,
                rates: state => state.user.user.rates
            })
        }
    }
</script>