(function($) {

    $('.component-image.-large-caption.-wider .caption').hover(function(){
        $(this).closest('.fp-tableCell').find('.fp-bg').addClass('-in');
    },function(){
        $(this).closest('.fp-tableCell').find('.fp-bg').removeClass('-in');
    });

    window.pnpHelper.imageGallery = {
        insideGallery: true,

        getCurrentIndex : function(){
            var currentIndex = -1;

            $('.component-image.-gallery.active .pnp-slide').each(function(index){
                if($(this).hasClass('-in')){
                    currentIndex = index;
                }
            });

            return currentIndex;
        },

        showRemainingImages: function(){
            var $gallery = $('.component-image.-gallery.active');
            $gallery.find('.component-image[data-pnp-hidden="1"]').each(function(){
                $(this).data('pnp-hidden', 0);
            });

            if (typeof window.fullpage == 'undefined') {
                $.fn.fullpage.moveSectionDown();
            } else {
                window.fullpage.moveSectionDown();
            }
        },

        closeGallery: function(direction){
            var $gallery = $('.component-image.-gallery.active');

            if(direction === "down") {
                $gallery.removeClass('-disable-transition');
            }else{
                $gallery.find('.pnp-slide.-in').removeClass('-in');
                $gallery.removeClass('-show-gallery');
            }

            if ($('.no-scroll-hijack').length) {
                $gallery.removeClass('active');
                $gallery.removeClass('-show-gallery');
            }

            window.bus.$emit('closedGallery');
        },

        initGallery: function($gallery){
            //-show-gallery will hide the caption, and -disable-transitions stops a bug with parallax

            if ($('.no-scroll-hijack').length) {
                $gallery.addClass('active');
            }

            $gallery.addClass('-show-gallery');
            $gallery.addClass('-disable-transition');
            $gallery.attr('data-active-slide', 0);
        },

        moveToEnd: function($gallery){
            $gallery.addClass('-show-gallery black-bg -disable-transition');
            $gallery.find('.pnp-slide.-in').removeClass('-in');
            $gallery.find('.pnp-slide').last().addClass('-in');
        },

        //For all galleries up to the end, move them all to the end of their galleries
        moveGalleriesToEnd: function(index){
            for(var i=0; i<index; i++){
                let $thisSection = $('#content .section').eq(i);

                if($thisSection.attr('data-pnp-showing-gallery')){
                    pnpHelper.imageGallery.moveToEnd($thisSection);
                }
            }
        },

        moveToImage: function($gallery, imageIndex){
            $gallery.addClass('-show-gallery black-bg -disable-transition');
            $gallery.find('.pnp-slide.-in').removeClass('-in');
            $gallery.find('.pnp-slide').eq(imageIndex).addClass('-in');
            $gallery.attr('data-active-slide', imageIndex);

            pnpHelper.sidenav.setSlide($gallery.attr('id'), imageIndex, $gallery.find('.pnp-slide').length);
        },

        freezeScroll: function(){
            //Disable scrolling so you can't scroll past the images on a magic mouse
            window.fullpage.setAllowScrolling(false);
            setTimeout(function(){
                window.fullpage.setAllowScrolling(true);
            }, 700);
        },

        changeImage : function(direction, index, nextIndex){
            //console.log('change image (' + index + ') ', nextIndex);
            if(!nextIndex){
                nextIndex = index + 1;
            }

            //Grab the whole current gallery
            var $gallery = $('.component-image.-gallery.active');

            //Grab all the images in this section
            var $galleryImages = $gallery.find('.pnp-slide');
            var i;


            if (direction == null) {
                i = nextIndex - 1;
            } else {
                //Get the current index of the visible image
                i = this.getCurrentIndex($galleryImages);
            }

            //If we're on the penulatimate slide, fix a bug with the parallax plugin, and transform the next section
            if(((i+1) === ($galleryImages.length-1) && direction === "down" && (index + 1 === nextIndex))){
                var $nextBg = $('.fullpage-wrapper .section').eq(nextIndex - 1).find('.fp-bg:first');

                if($nextBg.length){
                    $nextBg.css('transform', 'translate3d(0px, -355px, 0px)');
                }
            }

            // If we're at the end of the slides and moving down, end
            if(((i+1) === $galleryImages.length && direction === "down")
                || this.insideGallery === false){
                this.closeGallery(direction);
                this.freezeScroll();
                $gallery.addClass('-disable-transition');
                return true;
            }

            //If we're at the start of the slides and moving up, end
            //console.log(i + ' ------------------- ' + direction);
            if(i === 0 && direction === "up"){
                $gallery.removeClass('black-bg');
                this.closeGallery(direction);
                this.freezeScroll();
                return false;
            }

            if (i === -1 && direction === "down") {
                /** After slide fade in css transition */
                setTimeout(function() {
                    $gallery.addClass('black-bg');
                }, 1000);
            }

            //If the next image in the gallery is hidden, end
            if(($galleryImages.eq(i+1).data('pnp-hidden') === 1 && direction === "down")) {
                this.freezeScroll();
                return true;
            }

            //If we're at the beginning of the slides and trying to scroll up, go to the start of the slide
            if(i === -1 && direction === "up") {
                this.freezeScroll();
                return true;
            }

            this.initGallery($gallery);

            //Hide the old image
            $galleryImages.eq(i).removeClass('-in');

            if(direction === "down"){
                i++;
            }else if(direction === "up"){
                i--;
            }

            window.pnpHelper.sidenav.setSlide($('#content .section').eq(index-1).attr('id'), i, $galleryImages.length);

            //Show the new image
            $galleryImages.eq(i).addClass('-in');

            this.freezeScroll();

            return false;
        }
    };

}(jQuery));