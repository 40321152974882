<template>
    <a  class="component-card -global-search row no-gutter" @click="routeCheck($event)">
        <div class="result-image col-sm-5">
            <div class="image-wrap">
                <img v-lazy="result.images.data.sizes.thumbnail" :alt="result.title">
            </div>
        </div>

        <div class="col-md-9">
            <div class="row result-text">
                <div class="col-sm-12">
                    <p class="sub-text">{{ type }}</p>

                    <div class="f40 location">{{ result.title }}</div>

                    <span v-html="result.body" v-if="result.body"></span>
                    <span v-html="result.images.data.caption" v-else-if="result.images.data.caption"></span>
                </div>

                <!--<button-internal-presentational-->
                        <!--:router_object="router_object"-->
                        <!--class="btn -read-more -animated -with-arrow"-->
                        <!--icon="ion-ios-arrow-thin-right">-->
                    <!--<span>Go to page</span>-->
                <!--</button-internal-presentational>-->
            </div>
        </div>
    </a>
</template>

<script>
    import GlobalVars from "../../../global/GlobalVars";
    import { mapState } from 'vuex';

    export default {
        props: [
            'result',
            'index',
            'searchTerm'
        ],
        data(){
            return {
                show: false,
            }
        },
        methods: {
            close(){
                // this.$emit('close_modal');
                window.bus.$emit('close_nav');
            },
            routeCheck(e){
                e.preventDefault();

                this.googleAnalytics.fireEvent('Search', 'Result Click', this.searchTerm);

                if(this.$route.params && this.$route.params.slug && (this.result.vue_route_params.data.slug === this.$route.params.slug)){
                    this.close();
                    return;
                }

                if(!_.isUndefined(this.router_object)) {
                    this.$router.push(this.router_object);
                    this.$store.commit(window.store.SET_FROM_PAGE, false);
                }
            },
            getSlug(){
                this.type
            },
            onClick(){
                this.$store.commit(window.store.SET_FROM_PAGE, false);
            }
            // goToUrl(){
            //     if(!_.isUndefined(this.url)) {
            //         console.log(this.url);
            //         this.$router.push(this.url);
            //     }
            // }
        },
        computed: {
            type(){
                switch (this.result.type) {
                    case GlobalVars.LOCATION:
                        return 'Location';
                        break;
                    case GlobalVars.EXPERIENCE:
                        return 'Experience';
                        break;
                    case GlobalVars.LODGE:
                        return 'Lodge';
                        break;
                    case GlobalVars.RESOURCE:
                        return 'Resource';
                        break;
                    case GlobalVars.ARTICLES:
                        return 'Magazine';
                        break;
                    case GlobalVars.TRIP_COLLECTION:
                        return 'Trip';
                        break;
                    default:
                        return '';
                        break;
                }
            },
            slug(){
                switch (this.result.type) {
                    case GlobalVars.LOCATION:
                        return this.locationSlug;
                        break;
                    case GlobalVars.EXPERIENCE:
                        return this.experienceSlug;
                        break;
                    case GlobalVars.LODGE:
                        return this.lodgeSlug;
                        break;
                    case GlobalVars.RESOURCE:
                        return this.resourceSlug;
                        break;
                    case GlobalVars.ARTICLES:
                        return this.articleSlug;
                        break;
                    case GlobalVars.TRIP_COLLECTION:
                        return this.tripSlug;
                        break;
                    default:
                        return '';
                        break;
                }
            },
            routeName(){
                switch (this.result.type) {
                    case GlobalVars.LOCATION:
                        return 'single-location';
                        break;
                    case GlobalVars.EXPERIENCE:
                        return 'single-experience';
                        break;
                    case GlobalVars.LODGE:
                        return 'single-lodge';
                        break;
                    case GlobalVars.RESOURCE:
                        return 'single-resource';
                        break;
                    case GlobalVars.TRIP_COLLECTION:
                        return 'single-trip-intelligence';
                        break;
                    case GlobalVars.ARTICLES:
                        return 'single-article';
                        break;
                    default:
                        return '';
                        break;
                }
            },
            router_object(){
                return  {
                    name: this.routeName,
                    params: this.result.vue_route_params.data
                };
            },
            googleAnalytics() {
                return window.GoogleAnalytics;
            },
            ...mapState({
                lodgeSlug: state => state.singleLodge.slug,
                locationSlug: state => state.singleLocation.slug,
                resourceSlug: state => state.singleResource.slug,
                experienceSlug: state => state.singleExperience.slug,
                tripSlug: state => state.singleTrip.slug,
                articleSlug: state => state.activeArticle.slug,
            })
        }
    }
</script>
