<template>
    <div class="note material">
        <div class="group">
            <textarea-autosize type="text" rows="1" required class="form-control" v-model="localModel" @input="updateModel()"></textarea-autosize>
            <span class="highlight"></span>
            <span class="bar"></span>
            <label>Add your notes here ...</label>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['value'],
        data() {
            return {
                localModel: null
            };
        },
        mounted() {
            this.localModel = this.model;
        },
        methods: {
            updateModel() {
                this.$emit('input', this.localModel)
            }
        }
    }
</script>