import scrollify from 'jquery-scrollify';
import Scroll from "../helpers/Scroll";

(function($) {
    window.pnpHelper.lazyLoad = {
        loadImages : function(){
            setTimeout(function(){
                var timeoutInterval = 100;
                var nextTimeout = 0;
                var $lazyImages = $('[data-lazy-bg-image]');
                var imagesLoaded = 0;

                var loaded = function(){
                    imagesLoaded++;

                    if(imagesLoaded === $lazyImages.length){
                        window.bus.$emit('lazyloadAllLoaded');
                    }
                };

                //Timeout the loading after 10 seconds
                setTimeout(function(){
                    if(imagesLoaded !== $lazyImages.length) {
                        window.bus.$emit('lazyloadAllLoaded');
                    }
                },10000);

                //Load all the section images first
                $lazyImages.each(function(index) {
                    var $this = $(this);

                    setTimeout(function(){
                        var img = new Image();

                        img.onload = function(){
                            //console.log('lazyloaded 2' + img.src);

                            // image  has been loaded
                            $this.css('background-image', 'url(' + img.src + ')');
                            loaded();
                        };

                        img.src = $this.attr('data-lazy-bg-image');
                    },nextTimeout);

                    nextTimeout = timeoutInterval * index;
                });
            },1);
        }
    };

    //Helper functions for bootstrap
    window.pnpHelper.bootstrap = {
        xs: 768,
        md: 992,
        lg: 1200,
        xlg: 1500,
        ipadLandscape: 1112,
        ipadPortrait: 768,

        isXs: function(){
            return window.$window.width() < window.pnpHelper.bootstrap.xs;
        },

        isSm: function(){
            return window.$window.width() >= window.pnpHelper.bootstrap.xs;
        },

        isMd: function(){
            return window.$window.width() >= window.pnpHelper.bootstrap.md;
        },

        isLg: function(){
            return window.$window.width() >= window.pnpHelper.bootstrap.lg;
        },
        isXlg:function() {
            return window.$window.width() >= window.pnpHelper.bootstrap.xlg;
        },
        isIpadOrSmaller(){
            return window.$window.width() <= window.pnpHelper.bootstrap.ipadLandscape;
        },
        isIpadPortraitOrSmaller(){
            return window.$window.width() <= window.pnpHelper.bootstrap.ipadPortrait;
        }
    };

    window.pnpHelper.page = {
        isSearchPage: function(){
            return $('body').hasClass('page-finder') || $('body').hasClass('page-wishlist');
        },
        isDirectoryPage: function(){
            return $('body').hasClass('page-directory');
        },
        isLodgePage: function(){
            return $('body').hasClass('page-single-lodge') || $('body').hasClass('page-single-new-lodge');
        },
        isLocationPage: function(){
            return $('body').hasClass('page-single-location') || $('body').hasClass('page-single-new-location');
        },
        isWishlistPage: function(){
            return $('body').hasClass('page-wishlist');
        }
    },

    //An event for when resize of the window has finished
    window.pnpHelper.onResizeFinished = function(callback, runOnLoad){
        var resizeTimer;

        window.$window.on('resize', function(e) {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(callback, 100);
        });

        if(runOnLoad)
            callback.call();
    };

    //For easy scrolling to elements
    window.pnpHelper.scroll = {
        y: null,
        position: 0,

        //Scrolls to the bottom of the given element
        scrollPast: function(selector, $selector){
            if($selector)
                var $elem = $selector;
            else
                var $elem = $(selector);

            var scrollTop = $elem.offset().top + $elem.outerHeight(true) - window.$navBar.outerHeight();

            window.pnpHelper.scroll.doScroll(scrollTop);
        },

        //Scrolls to the top of the given element
        scrollTo: function(selector, callback){
            $navBar = $('.top-nav');

            var scrollTop = $(selector).offset().top;

            if(window.$navBar.length)
                scrollTop -= window.$navBar.outerHeight();

            window.pnpHelper.scroll.doScroll(scrollTop, callback);
        },

        //Scroll to the given scroll top int
        doScroll: function(scrollTop, callback) {
            if(!callback)
                callback = null;

            if (scrollTop || scrollTop==0) {
                $('html,body').animate({
                    scrollTop: scrollTop
                }, 1000, callback);
            }
        },

        //Scroll within a given element to particular position
        doElementScroll: function($element, scrollTop, callback) {
            if(!callback)
                callback = null;

            if (scrollTop || scrollTop==0) {
                $element.animate({
                    scrollTop: scrollTop
                }, 1000, callback);
            }
        },

        preventDefault: function(e) {
            e = e || window.event;
            if (e.preventDefault)
                e.preventDefault();
            e.returnValue = false;
        },

        lock: function() {
            scrollify.disable();
            $('body').addClass('scrollify-disabled');

            if (!_.isUndefined(window.fullpage)) {
                window.fullpage.setAllowScrolling(false);
            }

            new Scroll().disableScroll();
        },

        unlock: function() {
            scrollify.enable();
            $('body').removeClass('scrollify-disabled');

            if (!_.isUndefined(window.fullpage)) {
                window.fullpage.setAllowScrolling(true);
            }
            new Scroll().enableScroll();
        },

        lockIos: function(){
            if(pnpHelper.bootstrap.isIpadOrSmaller() && pnpHelper.isTouch()) {
                window.pnpHelper.scroll.y = $(window).scrollTop();
                $('html').addClass('ios-lock-scroll');
            }
        },

        unlockIos: function(){
            if(pnpHelper.bootstrap.isIpadOrSmaller() && pnpHelper.isTouch()) {
                $('html').removeClass('ios-lock-scroll');
                $(window).scrollTop(window.pnpHelper.scroll.y);
            }
        },

        offset: function(){
            if ($(window).scrollTop() > $(window).outerHeight()/3) {
                setTimeout(function(){
                    $('.header-container').addClass('-scroll-100');
                }, 30)
            }

            window.pnpHelper.scroll.position = $(window).scrollTop()

            var offsetTop = $(window).scrollTop();

            var offset = offsetTop - (offsetTop*2);

            $('body').css('top', offset);
        },

        resetOffset: function(){
            var offsetTop = window.pnpHelper.scroll.position;
            $('body').css('top', "")

            window.scrollTo(0, offsetTop);
        }
    };

    //Veil bits
    window.pnpHelper.veil = {
        show : function(){
            $('#veil').fadeIn(200, function() {
                $('#veil').addClass('-in');
            });
        },
        hide : function(){
            $('#veil').fadeOut(200, function(){
                $(this).removeClass('-in');
            });
        }
    };

    //For easy image preloading
    window.pnpHelper.preloadImage = function(imgUrl){

        var img = new Image();
        img.src = imgUrl;
    };

	/* Use this to check for dependencies in your JS
	 *
	 * For Example:
	 *
	 * if(window.pnpHelper.dependencyCheck.requireDependencies('Showcase (name of component - only used for logging)', [
	 * 	window.pnpHelper.dependencyCheck.fixTo,
	 * 	window.pnpHelper.dependencyCheck.viewportChecker
	 * ])) return;
	 *
	 * */
    window.pnpHelper.dependencyCheck = {
        fixTo: 'fixTo',
        viewportChecker: 'viewportChecker',
        jqueryCookie: 'jqueryCookie',
        slickSlider: 'slickSlider',
        hoverIntent: 'hoverIntent',
        bootstrapModal: 'bootstrapModal',
        fullPageJs: 'fullPageJs',

        //Returns true if there are missing dependencies
        requireDependencies: function(component, dependencies){
            if(dependencies && dependencies.length) {
                var isMissingDependencies = false;

                function logMissingDependency(dependency){
                    console.error("MISSING DEPENDENCY: The component '"+component+"' requires the JS library " +
                        "'"+dependency+"'. Please add it to the project.");

                    isMissingDependencies = true;
                }

                for (var i in dependencies) {
                    var dependency = dependencies[i];

                    if(dependency === window.pnpHelper.dependencyCheck.fixTo){
                        if (!window.fixto) {
                            logMissingDependency(dependency);
                        }

                    }else if(dependency === window.pnpHelper.dependencyCheck.viewportChecker){
                        if (!$.fn.viewportChecker) {
                            logMissingDependency(dependency);
                        }


                    }else if(dependency === window.pnpHelper.dependencyCheck.jqueryCookie){
                        if (!$.cookie) {
                            logMissingDependency(dependency);
                        }

                    }else if(dependency === window.pnpHelper.dependencyCheck.slickSlider){
                        if (!$.fn.slick) {
                            logMissingDependency(dependency);
                        }

                    }else if(dependency === window.pnpHelper.dependencyCheck.hoverIntent){
                        if (!$.fn.hoverIntent) {
                            logMissingDependency(dependency);
                        }

                    }else if(dependency === window.pnpHelper.dependencyCheck.bootstrapModal){
                        if (!$.fn.modal) {
                            logMissingDependency(dependency);
                        }

                    }else if(dependency === window.pnpHelper.dependencyCheck.fullPageJs){
                        if (!$.fn.fullpage) {
                            logMissingDependency(dependency);
                        }

                    }else{
                        console.warn("A check for dependency '"+dependency+"' was not found.");
                    }
                }

                return isMissingDependencies;
            }else{
                console.warn('No dependencies passed to dependencyCheck.requireDependencies() function');
                return false;
            }
        }
    };

    //For browser detection
    window.pnpHelper.browser = {
        isFirefox: false,
        isChrome: false,
        isSafari: false,
        isIOS: false,
        isAndroid: false,
        isIE: false,
        version: {
            ie: false,
            ios: false
        }
    };

    //Init the browser helper
    function doBrowserCheck(addCssClass){
        //Detect the browser (Chrome, FF, Safari or IE)
        if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
            window.pnpHelper.browser.isFirefox = true;

            if(addCssClass){
                window.$html.addClass('browser-firefox');
            }

        }else if(/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)){
            window.pnpHelper.browser.isChrome = true;

            if(addCssClass){
                window.$html.addClass('browser-chrome');
                window.$html.addClass('browser-webkit');
            }

        }else if(/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)){
            window.pnpHelper.browser.isSafari = true;

            if(addCssClass){
                window.$html.addClass('browser-safari');
                window.$html.addClass('browser-webkit');
            }

        }else{
            var ie =  false;
            function getIeVersion(){
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf('MSIE ');
                if (msie > 0) {
                    // IE 10 or older => return version number
                    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
                }

                var trident = ua.indexOf('Trident/');
                if (trident > 0) {
                    // IE 11 => return version number
                    var rv = ua.indexOf('rv:');
                    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
                }

                var edge = ua.indexOf('Edge/');
                if (edge > 0) {
                    // Edge (IE 12+) => return version number
                    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
                }

                // other browser
                return false;
            };

            ie = getIeVersion();

            if(ie){
                window.pnpHelper.browser.isIE = true;
                window.pnpHelper.browser.version.ie = ie;

                if(addCssClass){
                    window.$html.addClass('browser-ie');
                    window.$html.addClass('browser-ie-' + ie);
                    window.$html.addClass('browser-lt-ie-'+(ie+1));

                    if(ie < 12){
                        window.$html.addClass('browser-lt-edge');

                    }else if(ie == 12){
                        window.$html.addClass('browser-edge');
                    }
                }
            }
        }

        //Detect IOS
        if(!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)){
            window.pnpHelper.browser.isIOS = true;

            if(navigator.userAgent.match(/(iPad|iPhone|iPod touch);.*CPU.*OS 7_\d/i)) {
                window.pnpHelper.browser.version.ios = 7;

                if (addCssClass) {
                    window.$html.addClass('os-ios-7');
                }
            }

            if(addCssClass){
                window.$html.addClass('os-ios');
            }
        }

        //Detect android
        var ua = navigator.userAgent.toLowerCase();
        if(ua.indexOf("android") > -1){
            if (addCssClass) {
                window.$html.addClass('os-android');
                window.pnpHelper.browser.isAndroid = true;
            }
        }
    }
    doBrowserCheck(true);

    // Console shim for older browsers
    (function () {
        var f = function () {};
        if (!window.console) {
            window.console = {
                log:f, info:f, warn:f, debug:f, error:f
            };
        }
    }());

    //Patch for Modernizr bug to detect touch devices
    window.pnpHelper.isTouch = function(){
        return 'ontouchstart' in window        // works on most browsers
            || navigator.maxTouchPoints;       // works on IE10/11 and Surface
    };

    if(window.pnpHelper.isTouch()){
        window.$html.addClass('touch');

        if(window.outerWidth <= 1112){
            window.$html.addClass('no-hover');
        }
    }else{
        window.$html.addClass('no-touch');
    }

    window.pnpHelper.getUrlParameter = function(name, url){
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    };

    window.pnpHelper.popover = function(type){
        //Type = over || inline || adjacent

        var _this = this;
        var $popoverPanel, $popoverTriggerText, $popoverIndicator; //vars to maintain the position of the open facet

        if(!type){
            type = "over";
        }

        this.open = function (popoverTriggerSelector, popoverPanelSelector) {
            $popoverTriggerText = $(popoverTriggerSelector);
            $popoverPanel = $(popoverPanelSelector);
            $popoverIndicator = $popoverPanel.find('.caret-indicator');

            updatePosition();

            if(pnpHelper.bootstrap.isXs() && $popoverPanel.hasClass('vh-fix-xs')) {
                pnpHelper.scroll.lockIos();
            }

            window.pnpHelper.veil.show();

            if(pnpHelper.bootstrap.isXs()) {
                $popoverPanel.show();
            }else{
                $popoverPanel.fadeIn(250);
            }

            $popoverPanel.addClass('open-facet-panel');
            window.$window.resize(updatePosition);
            $popoverTriggerText.addClass('-open');

            //Focus on a textbox if present
            // if($popoverPanel.find('.form-control').length){
            //     $popoverPanel.find('.form-control:first').focus();
            // }

            window.$document.one('click', '#veil', function () {
                _this.close();
            });

            window.$document.one('click', '#veil', function () {
                _this.close();
            });

            window.$document.one('change', '.select-container', function () {
                _this.close();
            });

            window.$document.one('click', '.open-facet-panel .cancel', function () {
                _this.close();
                return false;
            });

            window.$document.one('click', '.open-facet-panel .apply', function () {
                _this.close();
                return false;
            });

            window.$document.one('click', 'a.selectable, a.filter-option, a.data-point, .mob-popup-header .burger, a.night', function () {
                window.pnpHelper.veil.hide();
                _this.close();
                return false;
            });

            window.$document.one('change', '#any_month', function () {
                window.pnpHelper.veil.hide();
                _this.close();
                return false;
            });
        };

        this.close = function () {
            $popoverPanel.removeClass('open-facet-panel');
            window.$window.off("resize", updatePosition);
            window.pnpHelper.veil.hide();

            if(pnpHelper.bootstrap.isXs()) {
                $popoverPanel.hide();
            }else{
                $popoverPanel.fadeOut(250);
            }

            $popoverTriggerText.removeClass('-open');

            if(pnpHelper.bootstrap.isXs() && $popoverPanel.hasClass('vh-fix-xs')) {
                pnpHelper.scroll.unlockIos();
            }
        };

        this.resetScroll = function() {
            if($popoverPanel.find('.panel-results')[0]){
                $popoverPanel.find('.panel-results')[0].scrollTop = 0;
            }
        };

        function updatePosition() {
            if (type === 'inline') {

                //Work out the position
                var top = ($popoverTriggerText.position().top);

                var padding = 90;
                if(($window.outerHeight() - padding) <= $popoverPanel.outerHeight()){
                    padding=0;
                }

                if(top < padding){
                    top = padding + $(window).scrollTop();
                }

                if(($popoverPanel.outerHeight() + top) >= ($window.outerHeight() - padding)){
                    top = $window.outerHeight() - $popoverPanel.outerHeight() - padding;
                }

                $popoverPanel.css('top', top);

                var left;
                if(pnpHelper.bootstrap.isXs()){
                    left = ($window.outerWidth() - $popoverPanel.outerWidth()) / 2;
                }else {
                    left = ($popoverTriggerText.offset().left);

                    //Don't allow popover to run off the page
                    if ((left + $popoverPanel.outerWidth()) > $window.outerWidth()) {
                        left = left - (left + $popoverPanel.outerWidth() - $window.outerWidth()) - 50;
                    }
                }

                $popoverPanel.css('left', left);

            } else if (type === 'over') {

                //Calculates the left and top of the panels to show
                var panelTop = $popoverTriggerText.position().top - ($popoverTriggerText.outerHeight() * 2);
                $popoverPanel.css('top', panelTop);

                if(window.pnpHelper.bootstrap.isMd()) {
                    var panelWidth = window.$window.width() / 2;
                    panelWidth -= 50;
                    $popoverPanel.css('width', panelWidth);

                    $popoverPanel.css('left', '25px');
                }else{
                    $popoverPanel.css('width', 'auto');
                }

            } else if (type === 'adjacent') {
                //Calculates the left and top of the panels to show
                var panelTop = $popoverTriggerText.offset().top;
                $popoverPanel.css('top', panelTop - 60);

                var panelLeft = ($popoverTriggerText.offset().left);
                panelLeft += $popoverTriggerText.outerWidth() + ($popoverTriggerText.outerWidth()/2);
                $popoverPanel.css('left', panelLeft);
            }
        };
    };

    //Handling clicks on the basic filters within the search
    window.$document.on('click', '[data-pnp-popover-panel]', function (event) {
        if(event.target.className.indexOf('remove-target') === -1) {

            if($(this).attr('data-pnp-popover-type')) {
                var popover = new window.pnpHelper.popover($(this).attr('data-pnp-popover-type'));
            }else{
                var popover = new window.pnpHelper.popover();
            }

            popover.open('#'+$(this).attr('id'), $(this).attr('data-pnp-popover-panel'));

            popover.resetScroll();
        }

        return false;
    });

}(jQuery));

//Create the a remove() function on native javascript elements
Element.prototype.remove = function() {
    this.parentElement.removeChild(this);
};

NodeList.prototype.remove = HTMLCollection.prototype.remove = function() {
    for(var i = this.length - 1; i >= 0; i--) {
        if(this[i] && this[i].parentElement) {
            this[i].parentElement.removeChild(this[i]);
        }
    }
};

(function($){
    $.fn.offsetRelative = function(top){
        var $this = $(this);
        var $parent = $this.offsetParent();
        var offset = $this.position();
        if(!top) return offset; // Didn't pass a 'top' element
        else if($parent.get(0).tagName == "BODY") return offset; // Reached top of document
        else if($(top,$parent).length) return offset; // Parent element contains the 'top' element we want the offset to be relative to
        else if($parent[0] == $(top)[0]) return offset; // Reached the 'top' element we want the offset to be relative to
        else { // Get parent's relative offset
            var parent_offset = $parent.offsetRelative(top);
            offset.top += parent_offset.top;
            offset.left += parent_offset.left;
            return offset;
        }
    };
    $.fn.positionRelative = function(top){
        return $(this).offsetRelative(top);
    };
}(jQuery));
