<template>
    <div class="price-container" :class="{zHide: zHide}">
        <span class="price">
            <span class="currency-select">
                <span @click.prevent="showPopup">
                   <span class="content heading2 price">
                        {{ priceCalc }}
                    </span>

                    <slot name="header"></slot>
                </span>

                <transition name="fade" :duration="fade">
                     <currency-popup :params="search.criteria.params"
                                     v-if="show"
                                     @currencyChanged="closeVeil"
                                     :id="id">

                         <template slot="header">
                             <slot name="popup-header"></slot>
                        </template>

                        <template slot="content">
                             <slot name="popup-content"></slot>
                        </template>
                    </currency-popup>
                </transition>
            </span>

            <div class="veil -currency-veil"
                 :ref="'currency-veil' + id"
                 @click="closeVeil($event)">
            </div>
        </span>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        props: {
            price: {
                type: String,
                default: '',
            },
            minPrice: {
                type: String,
                default: ''
            }
        },
        data(){
            return {
                show: false,
                zHide: false,
                fade: 200
            }
        },
        methods:{
            showPopup(e){
                window.bus.$emit('popupOpen', this.id);

                if(e){
                    e.preventDefault();
                    e.stopPropagation();
                }

                if(!this.show){
                  $(this.$refs['currency-veil' + this.id]).fadeIn(this.fade, function() {
                      $(this).addClass('-in');
                  });
                } else {
                  $(this.$refs['currency-veil' + this.id]).fadeOut(this.fade, function() {
                      $(this).removeClass('-in');
                  });
                }

                this.show = !this.show;
            },
            closeVeil(e){
                window.bus.$emit('popupClose');

                if(e){
                    e.preventDefault();
                    e.stopPropagation();
                }

                $(this.$refs['currency-veil' + this.id]).fadeOut(this.fade, function() {
                    $(this).removeClass('-in');
                });

                this.show = false;
            },
            updatedZindex(id){
                if(id !== this.id){
                    this.zHide = true;
                } else {
                    this.zHide = false;
                }
            },
            popupClose(){
                setTimeout(()=>{
                    this.zHide = false;
                }, this.fade)
            }
        },
        computed: {
            priceCalc(){
                if(!this.rates) {
                    return;
                }

                let price = parseInt(this.price.replace(/,/g, ''));
                let minPrice =  this.minPrice ? parseInt(this.minPrice.replace(/,/g, '')) : false;
                let rate = this.rates[this.selected] || 1;

                price = Math.round( price * rate);
                minPrice = Math.round( minPrice * rate);

                if(minPrice && minPrice < price) {
                     return minPrice + ' - ' + price;
                }

                return price > 0 ? price.toLocaleString() : 'n/a';
            },
            id(){
                let id = String(Math.floor(Math.random() * 1000));

                id = '217';

                return id;
            },
            classObject(){
                if(this.zHide) {
                    return [this.classes, 'zHide'];
                }

                return this.classes;
            },
            ...mapState({
                rates: state => state.user.user.rates,
                selected: state => state.user.user.selected_currency,
                search: state => state.search,
            })
        },
        created() {
            window.bus.$on('popupOpen', this.updatedZindex);
            window.bus.$on('popupClose', this.popupClose);
        },
        destroyed() {
            window.bus.$off('popupOpen', this.updatedZindex);
            window.bus.$off('popupClose', this.popupClose);
        }
    }
</script>