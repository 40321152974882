import AtrFullpage from "../global/AtrFullpage";

(function($) {

    window.pnpHelper.modal_ = {
        open: function(idToOpen){
            var $modal = $(idToOpen);

            $modal.scrollTop(0);

            $modal.addClass('-in');

            $('.header-container').addClass('-modal-close');
            $modal.closest('.section .fp-tableCell, .VueCarousel-slide .component-image').append('<div id="modal-veil"></div>');

            setTimeout(function(){
                $('#modal-veil').addClass('-in');
            },1);

            $('body').addClass('modal-open');

            if($modal.hasClass('-video')){
                $('.header-container').addClass('-no-border');
                $modal.find('video')[0].play();
            }else{
            }

            window.bus.$emit('modal-open');
        },
        close: function(){
            var $modal = $('.modal:visible');

            $modal.removeClass('-in');
            $('.header-container').removeClass('-modal-close');
            $('#modal-veil.-in').removeClass('-in');

            setTimeout(function(){
                $('#modal-veil').remove();
            },600);

            $('body').removeClass('modal-open');

            if($modal.hasClass('-video')){
                $('.header-container').removeClass('-no-border');
                $modal.find('video')[0].pause();
            }

            window.bus.$emit('modal-close');
        }
    };

    window.$document.on('click', '[data-pnp-modal-open]', function () {
        /** Scrolling is locked in AtrFullpage.js to avoid setTimeout of 1500 */
        window.pnpHelper.modal_.open($(this).data('pnp-modal-open'));
        // window.bus.$emit('open_modal_' + $(this).data('pnp-modal-open').replace('#', ''));
        return false;
    });

    window.$document.on('click', '.header-container.-modal-close,#modal-veil', function(){
        Vue.nextTick(() => {window.fullpage.setAllowScrolling(true)});
        window.pnpHelper.modal_.close();
        return false;
    });

    $(document).on('click', '[data-toggle][data-target]', function() {
        window.pnpHelper.modal_.open($(this).attr('target'));
    });

    $(document).on('click', '[data-dismiss="modal"]', function() {
        window.pnpHelper.modal_.close('#' + $(this).attr('id'));
    });

}(jQuery));
