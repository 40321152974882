<template>
    <div class="single-trip-page">
        <div class="nav-veil"></div>

        <div class="left-side" v-if="loaded">

            <div v-waypoint="{ active: !isMobile, callback: recenterMap, options: intersectionOptions }">
                <div class="component-card -sr-single-trip -sr-trip -full-page -intro-card vh-fix"
                    :data-id="quote.id">
                    <div>
                        <div class="col-caption">
                            <div class="sub-text">
                                TRIP CODE : {{quote.id}}
                            </div>
                            <div class="heading4 heading -single-trip-heading">
                                {{quote.title}}
                            </div>

                            <p class="sub-text">
                                {{ quote.lodge_title }}
                            </p>

                            <!--<div class="trip-content">-->
                                <!--{{trip.content}}-->
                            <!--</div>-->
                        </div>

                        <!--TODO make the stats module in SearchTripCard.vue reusable and put here? It's the same-->
                        <div class="stats" :class="{'-show-4': quote.consrating >= 7}">
                            <info-container classes="col-price" :value="quote.num_nights ? quote.num_nights : '0'">
                                <template slot="header">
                                    <div class="sub-text">TRIP <br/>NIGHTS</div>
                                </template>

                                <template slot="popup-header">
                                    <p>Trip nights</p>
                                </template>

                                <template slot="popup-text">
                                    <p>This number represents the number of nights on this trip, but does not include any nights needed in transit when traveling to and from Africa.</p>
                                </template>
                            </info-container>

                            <!--<div class="col-price">-->
                                <!--<price :price="quote.price">-->
                                    <!--<template slot="header">-->
                                         <!--<span class="sub-text">-->
                                            <!--GUIDE PRICE <br/>{{ selectedCurrency }} PPPN-->
                                         <!--</span>-->
                                    <!--</template>-->

                                    <!--<template slot="popup-header">-->
                                        <!--Trip guide price-->
                                    <!--</template>-->

                                    <!--<template slot="popup-content">-->
                                        <!--<span v-if="month <= 0">This number represents the approximate price per person in any month to undertake this trip. </span>-->
                                        <!--<span v-else>This number represents the approximate price per person in {{ months[month - 1] }} to undertake this trip. </span>-->

                                        <!--It includes the cost of accommodation, the usual meals, drinks and activities, transport costs and park fees.-->
                                        <!--On top of this guide price you will need to consider potentially significant additional costs such as long-haul-->
                                        <!--flights to Africa, visas, tips, insurance and any expensive optional-->
                                        <!--extras that you choose to include, such as balloon safari and diving.-->
                                    <!--</template>-->
                                <!--</price>-->
                            <!--</div>-->

                            <info-container classes="col-rating" :value="quote.rating ? quote.rating : '0.0'">
                                <template slot="header">
                                    <div class="sub-text">TRIP <br/>RATING</div>
                                </template>

                                <template slot="popup-header">
                                    <p>Trip rating</p>
                                </template>

                                <template slot="popup-text">
                                    <p>
                                        This number represents an indication of the overall quality of a trip.
                                        It is calculated from a weighted average of the overall ratings for each of the
                                        lodges in the trip during
                                        <span v-if="month <= 0"> any month,</span>
                                        <span v-else>{{ GlobalVars.MONTHS[month - 1] }},</span> which take into account all the various
                                        component ratings of each property, their locations, builds, styles, service
                                        levels and experiences.
                                    </p>
                                </template>
                            </info-container>

                            <!--<info-container classes="col-rating" :value="quote.safari_rating ? quote.safari_rating : '0.0'">-->
                                <!--<template slot="header">-->
                                    <!--<div class="sub-text">SAFARI <br/>RATING</div>-->
                                <!--</template>-->

                                <!--<template slot="popup-header">-->
                                    <!--<p>Trip rating</p>-->
                                <!--</template>-->

                                <!--<template slot="popup-text">-->
                                    <!--<p>-->
                                        <!--This number represents the rating for Safari for this trip in-->
                                        <!--<span v-if="month <= 0">any month</span>-->
                                        <!--<span v-else>{{ GlobalVars.MONTHS[month - 1] }}</span>-->
                                        <!--and should be a good indication of the level of experience that you can expect when visiting at that time.-->
                                    <!--</p>-->
                                <!--</template>-->
                            <!--</info-container>-->

                            <info-container classes="col-rating" v-if="quote.consrating && quote.consrating >= 7" :value="quote.consrating ? quote.consrating : '0.0'">
                                <template slot="header">
                                    <div class="sub-text">conservation <br/>RATING</div>
                                </template>

                                <template slot="popup-header">
                                    <p>Quote conservation rating</p>
                                </template>

                                <template slot="popup-text">
                                    <p>
                                        The conservation rating is an indication of how much a particular lodge needs your visit in
                                        <span v-if="month <= 0">any month</span>
                                        <span v-else>{{ GlobalVars.MONTHS[month - 1] }}</span>
                                        to further the protection of their landscapes and wildlife. We recommend that you try to include at least one lodge with a high conservation rating into your trip if you can. This trip conservation rating is therefore the peak value achieved by any of the lodges in your trip.
                                    </p>
                                </template>
                            </info-container>
                        </div>
                    </div>
                    <div class="scroll-btn">
                        <button-external-presentational
                                @click="scrollTo"
                                icon="ion-ios-arrow-thin-down"
                                classes="scroll-down-arrow">

                        </button-external-presentational>
                    </div>
                </div>
            </div>

            <hr>

            <div class="container -full-page summary" ref="summary" v-waypoint="{ active: !isMobile, callback: recenterMap, options: intersectionOptions }">
                <div class="yellow-bar summary"></div>
                <div class="heading2 heading">Overview</div>

                <div v-if="quote.sections"
                     v-html="quote.sections.summary.content">
                </div>
            </div>

            <hr>

            <template v-for="(location,index) in quote.locations">
                <div v-waypoint="{ active: !isMobile, callback: onWaypoint, options: intersectionOptions }">
                    <div :data-id="location.id"
                         :data-lat="location.lodges ? location.lodges[0].lat : location.lat"
                         :data-lng="location.lodges ? location.lodges[0].lng : location.lng"
                         :data-type="location.type"
                         class="component-card -sr-single-trip -sr-trip -full-page -sr-lodge  -mobile-flip"
                         :class="{'-transit-location' :  location.type === GlobalVars.TRANSIT_LOCATION}"
                         :data-index="index"
                        :id="'cc-id-'+index">
                        <div>
                            <p class="sub-text -highlight visible-xs"><span class="yellow-bar"></span>{{ location.location_label }}</p>

                            <div class="heading2 heading visible-xs">
                                {{location.title}}
                            </div>

                            <div class="button-fav visible-xs"
                                 v-if="location.type != GlobalVars.TRANSIT_LOCATION && location.type > 0"
                                 v-on:click="toggleWishlist(location.id, 'location')"
                                 v-bind:class="{ on: inWishlist(location.id) }"
                                 aria-label="Add to Wishlist">
                            </div>

                            <!--This reserves space for the slider on mobile-->
                            <img v-if="location.type != GlobalVars.TRANSIT_LOCATION && location.type > 0" src="/assets/img/placeholder.png" alt="Placeholder" class="visible-xs img-responsive mob-placeholder"/>

                            <p class="sub-text -highlight hidden-xs"><span class="yellow-bar"></span>{{ location.location_label }}</p>

                            <div class="col-caption">
                                <div class="heading2 heading hidden-xs">
                                    {{location.title}}

                                    <!--<p class="sub-text" v-if="location.lodges">-->
                                        <!--&lt;!&ndash;DO NOT RE FORMAT&ndash;&gt;-->
                                        <!--<span v-for="(lodge, index) in location.lodges"><template v-if="index > 0">,&nbsp;</template>{{lodge.title}}</span>-->
                                    <!--</p>-->

                                    <div class="button-fav hidden-xs"
                                         v-if="location.type != GlobalVars.TRANSIT_LOCATION && location.type > 0"
                                         v-on:click="toggleWishlist(location.id, 'location')"
                                         v-bind:class="{ on: inWishlist(location.id) }"
                                         aria-label="Add to Wishlist">
                                    </div>
                                </div>

                                <div class="content" v-html="location.content"></div>
                            </div>

                            <div v-if="location.type >= 0">
                                <public-search-image-slider type="location" v-if="location.images" :cover-image="location.images.sizes.low" :caption="location.images.caption" :id="location.id"></public-search-image-slider>

                                <div class="sr-footer" v-if="location.type != GlobalVars.TRANSIT_LOCATION && location.type > 0">
                                    <div class="btn-container">
                                        <!--<button-internal-presentational :router_object="{ name: 'single-location', params: location.vue_router_params['data'] }" classes="sr-cta -arrow">-->
                                            <!--<div>Go to Location</div>-->
                                            <!--<i class="ion-ios-arrow-thin-right"></i>-->
                                        <!--</button-internal-presentational>-->

                                        <button-internal-presentational classes="sr-cta -arrow" :router_object="{ name: 'single-location', params: location.vue_route_params }">
                                            <div>Go to location</div>
                                            <i class="ion-ios-arrow-thin-right"></i>
                                        </button-internal-presentational>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <template v-if="index < (quote.locations.length-1)">
                    <hr >
                </template>
                <template v-else>
                    <br><br>
                </template>

                <div v-if="location.lodges">
                    <div v-waypoint="{ active: !isMobile, callback: onWaypoint, options: intersectionOptions }" v-for="lodge in location.lodges" :key="lodge.id">
                        <lodge-select :locationId="location.id" :trip="quote" :lodge="lodge" :month="quote.month != null ? quote.month : 0"></lodge-select>
                    </div>

                </div>
            </template>

            <!--<div class="seasonality -full-page"-->
                 <!--v-waypoint="{ active: true, callback: recenterMap, options: intersectionOptions }">-->
                <!--<div>-->
                    <!--<div class="container">-->
                        <!--<div class="heading2 heading">Seasonality</div>-->
                    <!--</div>-->

                    <!--<div class="chart-img">-->
                        <!--<img src="/assets/img/temp/season-chart.png" alt="">-->
                    <!--</div>-->

                    <!--<div class="container">-->
                        <!--<div v-if="this.$store.state.singleTrip.trip.sections"-->
                             <!--v-html="this.$store.state.singleTrip.trip.sections.seasonality.content">-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->

            <!--<hr>-->


        </div>

        <section class="right-side map-container -half-page-map" v-if="!isMobile">
            <map-presentational :args="mapArgs" :classes="['vignette', 'vignette-half-map', 'vignette-map']"></map-presentational>
        </section>
        <!-- <transition-wipe/> -->
    </div>
</template>

<script>
    import GlobalVars from '../../global/GlobalVars';
    import pageIsReadyMixin from '../../mixins';
    import { mapState } from 'vuex';

    export default {
        name: "SingleQuote",
        mixins: [pageIsReadyMixin],
        data(){
            return {
                selectedItems: [],
                intersectionOptions: {
                    root: null,
                    rootMargin: "0px",
                    threshold: [0.5],
                    currentId: null
                },
                lodgeNumber: 0,
                loaded: false,
                GlobalVars: GlobalVars,
                isScroll: false
            }
        },
        methods: {
            scrollTo() {
                window.pnpHelper.scroll.scrollTo(this.$refs.summary);
            },
            onWaypoint({ direction, el }) {
                if(this.isScroll){
                    return true;
                }

                let element = $(el).find('.component-card');
                let id = element.attr('data-id');
                let lat = element.attr('data-lat') ? element.attr('data-lat') : '';
                let lng = element.attr('data-lng') ? element.attr('data-lng') : '';
                let type = element.attr('data-type') ? element.attr('data-type') : '';

                if(this.intersectionOptions.currentId !== id && id === this.quote.id && direction !== undefined) {
                    this.centerMap();
                } else if(this.intersectionOptions.currentId !== id && direction !== undefined && lat && lng) {

                    let zoom;
                    let animationDuration;

                    // Higher the number close the zoom
                    if(type == GlobalVars.LODGE || type === 'lodge') {
                        zoom = 11;
                    } else if(type == GlobalVars.LOCATION || type === 'location') {
                        zoom = 9;
                    } else if(type == GlobalVars.TRANSIT_LOCATION) {
                        zoom = 7;
                        animationDuration = 5000;
                    } else {
                        zoom = 7;
                    }

                    pnpHelper.map.centerOnCoords([lng, lat], zoom, animationDuration);
                }

                this.intersectionOptions.currentId = id;
            },
            recenterMap({ direction, el }){
                if(direction != undefined && this.intersectionOptions.currentId !== 0) {
                   this.centerMap();
                    this.intersectionOptions.currentId = 0;
                }
            },
            centerMap(){
                if(pnpHelper.map) {
                    let bounds = new mapboxgl.LngLatBounds();

                    for (let i in this.quote.geographical_summary) {
                        bounds.extend([this.quote.geographical_summary[i].lng, this.quote.geographical_summary[i].lat]);
                    }

                    pnpHelper.map.fitBounds(bounds, {
                        padding: {
                            left: 175,
                            bottom: 175,
                            top: 175,
                            right: 175
                        },
                        duration: 4000
                    });
                }
            },
            lodgeBack(){
                if(this.lodgeNumber > 0) {
                    this.lodgeNumber--;
                }
            },
            lodgeForward(){
                this.lodgeNumber += 1;
            },
            toggleWishlist(id, type){
                let payload = {
                    id: id,
                    type: type ? type : this.type
                };

                if (this.inWishlist(id)) {
                    this.$store.commit(window.store.REMOVE_WISHLIST_ITEM, payload);
                } else {
                    this.$store.dispatch(window.store.SET_WISHLIST_ITEM_BY_ID, payload);
                }
            },
            inWishlist(id){
                return this.wishlist.some((el) => {
                    return el.id == id;
                });
            },
            scrollToSection(l, b, index){
                this.isScroll = true;

                let element = $("[data-index='" + index + "']");

                let id = element.attr('data-id');
                let lat = element.attr('data-lat') ? element.attr('data-lat') : '';
                let lng = element.attr('data-lng') ? element.attr('data-lng') : '';
                let type = element.attr('data-type') ? element.attr('data-type') : '';

                if(this.intersectionOptions.currentId !== id && id === this.quote.id && direction !== undefined) {
                    this.centerMap();
                    this.isScroll = false;
                } else if(this.intersectionOptions.currentId !== id && lat && lng) {

                    let zoom;
                    let animationDuration;

                    // Higher the number close the zoom
                    if(type == GlobalVars.LODGE || type === 'lodge') {
                        zoom = 11;
                    } else if(type == GlobalVars.LOCATION || type === 'location') {
                        zoom = 9;
                    } else if(type == GlobalVars.TRANSIT_LOCATION) {
                        zoom = 7;
                        animationDuration = 5000;
                    } else {
                        zoom = 7;
                    }

                    pnpHelper.map.centerOnCoords([lng, lat], zoom, animationDuration);
                    pnpHelper.scroll.scrollTo('#cc-id-'+index);

                }

                setTimeout(() => {
                    this.isScroll = false;
                }, 1000);

                this.intersectionOptions.currentId = id;
            }
        },
        computed: {
            mapArgs() {
                return [{
                    lat: '-7.44713873',
                    lng: '35.23497118',
                    image: 'https://atrimages.azureedge.net/cdn/05explore/locations-and-lodges/africa/tanzania/s/ruaha/kichaka-ruaha/0/stills/02location/01TKKR-IM0201-kichaka-ruaha-1024.jpg',
                    id: 927,
                    title: 'Trip Lodge',
                    price: '2,000',
                    rating: '11/10',
                }];
            },
            quote(){
                return this.$store.state.singleQuote.quote;
            },
            isMobile(){
                return pnpHelper.bootstrap.isXs();
            },
            ...mapState({
                search: state => state.search,
                searchType: state => state.search.criteria.params.type,
                month: state => state.search.criteria.params.month,
                wishlist: state => state.wishlist.selected,
                searchLocation: state => state.search.criteria.params.location.name,
                selectedCurrency:  state => state.user.user.selected_currency
            })
        },
        mounted(){
            this.$store.dispatch('singleQuote/FETCH_AND_SET_QUOTE', this.$route.params.slug).then(() => {
                this.$store.commit(window.store.SET_QUOTE_MAP, this.quote, {root: true});
                this.loaded = true;
                this.declarePageIsReady();
            });

            window.bus.$on('highlightTripLocation', this.scrollToSection);
        },
        created(){
            this.$store.commit(window.store.FETCH_AND_SET_WISHLIST);
        },
        destroyed(){
            window.bus.$off('highlightTripLocation', this.scrollToSection);
        }
    }
</script>
