<template>
    <button-internal-presentational :router_object="router_object" :transition_name="transition_name">
        <div class="grid-image" :class="{'full-width': hasSlotData, '-zoom' : zoom === 'bottom-left', '-zoom-central' : zoom === 'central', }">
            <div class="grid-image-bg" v-lazy:background-image="image_url"></div>

            <div class="image-overlay"></div>

            <div v-if="mob_image_url">
                <img class="mob-image" :alt="text" v-lazy="mob_image_url">
            </div>

            <p class="text" v-if="text.length">{{ text }}</p>

            <div class="grid-image-slot" v-if="hasSlotData">
                <slot></slot>
            </div>
        </div>
    </button-internal-presentational>
</template>

<script>
    import GlobalVars from "../../../global/GlobalVars";

    export default {
        name: "GridImage",
        props: {
            image_url: String,
            mob_image_url: String,
            router_object: Object,
            text: {
                default: '',
                type: String
            },
            zoom: {
                default: 'bottom-left',
                type: String
            },
            transition_name: String
        },
        computed: {
            hasSlotData() {
                return this.$slots.default;
            }
        },
        methods: {
            clicked(e) {
                // e.preventDefault();
                this.$router.push(this.router_object);
            }
        }
    }
</script>