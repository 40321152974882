<template>
    <div class="container -full-page -in">
        <span class="yellow-bar"></span>
        <div class="heading4 heading">Something went wrong</div>

        <p class="content">Unfortunately this form appears not to be working at the moment, which is extremely rare. We apologise for this inconvenience.</p>
        <p class="content">
            <a class="-next-step" @click="retry">
                <div>Try the form again</div>
                <i class="ion-ios-arrow-thin-right"></i>
            </a>
        </p>

        <!--<textarea name="content"-->
                  <!--class="form-control -xsmall"-->
                  <!--placeholder="Message">{{contactInfo}}-->
        <!--</textarea>-->

        <p class="content">
            <a class="-next-step" href="mailto:salesoffice@africatravelresource.com">
                <div>Send us an email instead</div>
                <i class="ion-ios-arrow-thin-right"></i>
            </a>
        </p>
    </div>
</template>


<script>
    export default {
        props: ['retry'],
        computed: {
            contactInfo(){
                return this.$store.state.contactInfo;
            }
        }
    }
</script>