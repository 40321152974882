<template>
    <section class="search-controls" v-if="loaded">
        <div class="sentence">

            <div class="veil" :class="{'-first-time-search': !haveSeen}" @click="closeFirstTime"></div>

            <span class="veil-popup -first-time-search" v-if="showFirstTimePopup">
                <info-popup>
                    <template slot="text">
                        Click on the underlined items to edit the sentence and explore Africa
                        <br/>
                        <a class="btn -ripple -not-rounded -yellow" @click="closeFirstTime">Got it</a>
                    </template>
                </info-popup>
            </span>

            <template v-if="search.criteria.params.type === 'location' ||
                            search.criteria.params.type === 'lodge' ||
                            search.criteria.params.type === 'experience'">

                <template v-if="this.$route.name === 'directory'">
                    Show all
                </template>
                <template v-else-if="this.$route.name === 'wishlist'">
                    Show all the
                </template>
                <template v-else>
                    Find the best
                </template>
            </template>

            <template v-if="search.criteria.params.type === 'resource'">
                Show all
            </template>
            <template v-else-if="search.criteria.params.type === 'article'">
                <!--Show all issues of the ATR safari-->
                Show all
            </template>
            <template v-else-if="search.criteria.params.type === 'trip_collection'">
                Show all
            </template>

            <!--White space-->
            <!--<div class="spacer" v-if="search.criteria.params.type === 'trip' && this.$route.name !== 'wishlist'"></div>-->

            <span v-if="!this.lockType"
                  class="editable srch-type"
                  :id="'srch-type' + this.sentenceId"
                  :class="{ '-dirty': dirtyFormKeys['type'] }"
                  :data-pnp-popover-panel="'#srch-type-panel' + this.sentenceId"
                  data-pnp-popover-type="inline">
                {{ sentenceTypeByKey(search.criteria.params.type) }}
            </span>

            <span class="editable srch-type"
                  :id="'srch-type' + this.sentenceId"
                  v-else>
                {{sentenceTypeByKey(search.criteria.params.type)}}
            </span>

            <template v-if="search.criteria.params.type === 'resource'">
              in directory format
            </template>

            <template v-if="search.criteria.params.type == 'trip' && this.$route.name !== 'wishlist'">
                for

                <span v-if="this.$route.name !== 'directory'" class="editable srch-experience"
                      :id="'srch-experience' + this.sentenceId"
                      :data-pnp-popover-panel="'#srch-experience-panel' + this.sentenceId"
                      data-pnp-popover-type="inline"
                      :class="{ '-dirty':
                        (!sentenceHasMultipleExperiences && search.criteria.params.experience && search.criteria.params.experience.id != 3) ||

                        (sentenceHasMultipleExperiences && (
                                search.criteria.params.experiences.length > 1 ||
                                (search.criteria.params.experiences.length === 1 && search.criteria.params.experiences[0].id !== 3)
                            )
                        )}">

                    <template v-if="sentenceHasMultipleExperiences && search.criteria.params.experiences.length > 0">
                        <template v-if="search.criteria.params.experiences.length <= 3">
                            <span v-for="experiencePart in experienceParts" v-html="experiencePart"></span>
                        </template>
                        <template v-else>
                            <span>{{numberToWord(search.criteria.params.experiences.length)}} experiences</span>
                        </template>
                    </template>
                    <template v-else>

                        <template v-if="search.criteria.params.experience">
                            <span v-for="experiencePart in experienceParts" v-html="experiencePart"></span>
                        </template>
                        <template v-else>
                            <span>any experience</span>
                        </template>

                    </template>
                </span>
            </template>

            <template v-if="search.criteria.params.type === 'location'
                            || search.criteria.params.type === 'lodge'
                            || search.criteria.params.type === 'experience'
                            || search.criteria.params.type === 'trip'" >

                <template v-if="search.criteria.params.type === 'trip' && this.$route.name !== 'wishlist'">
                    including
                </template>
                <template v-else-if="this.$route.name === 'wishlist'">
                    in my wishlist
                </template>
                <template v-else>
                    in
                </template>

                <template v-if="this.$route.name === 'directory'">
                    directory format
                </template>

                <template v-if="this.$route.name === 'wishlist' || this.$route.name === 'directory'">

                </template>
                <template v-else>
                    <span class="editable srch-location"
                          :id="'srch-location' + this.sentenceId"
                          :data-pnp-popover-panel="'#srch-location-panel' + this.sentenceId"
                          data-pnp-popover-type="inline"
                          :class="{ '-dirty':
                            (!sentenceHasMultipleLocations && search.criteria.params.location && search.criteria.params.location.id != 0) ||

                            (sentenceHasMultipleLocations && (
                                    search.criteria.params.locations.length > 1 ||
                                    (search.criteria.params.locations.length === 1 && search.criteria.params.locations[0].id != 0)
                                )
                            )}">

                        <template v-if="sentenceHasMultipleLocations && search.criteria.params.locations.length > 0">
                            <template v-if="search.criteria.params.locations.length <= 3">
                                <span v-for="locationPart in locationParts" v-html="locationPart"></span>
                            </template>
                            <template v-else>
                                <span>{{numberToWord(search.criteria.params.locations.length)}} locations</span>
                            </template>
                        </template>
                        <template v-else>

                            <template v-if="search.criteria.params.location">
                                <span v-for="locationPart in locationParts" v-html="locationPart"></span>
                            </template>
                            <template v-else>
                                <span>Africa</span>
                            </template>

                        </template>
                    </span>
                </template>

                <template v-if="search.criteria.params.type !== 'experience'
                                && search.criteria.params.type !== 'trip'
                                && this.$route.name !== 'directory'
                                && this.$route.name !== 'wishlist'">
                    for

                    <span v-if="this.$route.name !== 'directory'" class="editable srch-experience"
                          :id="'srch-experience' + this.sentenceId"
                          :data-pnp-popover-panel="'#srch-experience-panel' + this.sentenceId"
                          data-pnp-popover-type="inline"
                          :class="{ '-dirty': search.criteria.params.experience && search.criteria.params.experience.id != 3 }">

                        <template v-if="search.criteria.params.experience">
                            <span v-for="experiencePart in experienceParts" v-html="experiencePart"></span>
                        </template>

                        <template v-else>
                            <span>any experience</span>
                        </template>
                    </span>
                </template>

                <template v-if="search.criteria.params.type === 'trip' && this.$route.name !== 'wishlist'">
                    for

                    <span class="editable srch-nights"
                          :id="'srch-nights'  + this.sentenceId"
                          :data-pnp-popover-panel="'#srch-nights-panel' + this.sentenceId"
                          :class="{ '-dirty': search.criteria.params.duration != 12 }"
                          data-pnp-popover-type="inline"
                          v-show="search.criteria.params.type === 'trip'">

                    <span>
                        {{search.criteria.params.duration}} night<span v-if="search.criteria.params.duration > 1">s</span>
                    </span>
                </span>
                </template>

                <span v-if="this.$route.name !== 'directory' && this.$route.name !== 'wishlist'">&nbsp;in&nbsp;</span>

                <span v-if="this.$route.name !== 'directory' && this.$route.name !== 'wishlist'"
                      class="editable srch-month"
                      :id="'srch-month' + this.sentenceId"
                      :data-pnp-popover-panel="'#srch-month-panel' + this.sentenceId"
                      :class="{ '-dirty': search.criteria.params.month != 0 }"
                      data-pnp-popover-type="inline">

                        <template v-if="globals.months[search.criteria.params.month]">
                            <span>{{globals.months[search.criteria.params.month].name}}</span>
                        </template>
                </span>

                <!--<template v-if="search.criteria.params.type === 'trip' && this.$route.name !== 'wishlist'">-->
                    <!--to suit a-->

                    <!--<span class="editable srch-budget"-->
                          <!--:id="'srch-budget' + this.sentenceId"-->
                          <!--:data-pnp-popover-panel="'#srch-budget-panel' + this.sentenceId"-->
                          <!--data-pnp-popover-type="inline"-->
                          <!--v-show="search.criteria.params.type === 'trip'"-->
                          <!--:class="{ '-dirty': dirtyFormKeys['budget'] }">{{search.criteria.params.budget}}</span>-->
                <!--</template>-->
            </template>


            <template v-if="search.criteria.params.type === 'trip_collection'">
              in directory format
            </template>
            <template v-if="search.criteria.params.type === 'article'">
              in directory format
            </template>

            <!--<template v-if="search.criteria.params.type === 'location' || search.criteria.params.type === 'lodge'">-->
            <!--in the price range-->
            <!--<span class="editable budget-range"-->
            <!--:id="'budget-range'  + this.sentenceId"-->
            <!--:data-pnp-popover-panel="'#budget-range-panel' + this.sentenceId"-->
            <!--:class="{ '-dirty': dirtyFormKeys['range'] }"-->
            <!--data-pnp-popover-type="inline">-->

            <!--<span v-if="search.criteria.params.budgetTo != null">-->
            <!--USD {{search.criteria.params.budgetFrom}}-{{search.criteria.params.budgetTo}} pppn-->
            <!--</span>-->
            <!--</span>-->
            <!--</template>-->

            <div class="options-container"
                 v-if="(this.$route.name !== 'directory') &&
                 (search.criteria.params.type === 'location' || search.criteria.params.type === 'lodge' || search.criteria.params.type === 'trip')
                 && this.$route.name !== 'wishlist'">

                <template>
                    <transition-group name="location-fade">
                        <div class="option-tag -price-option"
                             :class="{ 'tag-highlight': dirtyFormKeys['range'+(search.criteria.params.type === 'trip' ? '_trip' : '')] || !checkBudgetRange}"
                             :id="'budget-range' + sentenceId"
                             :data-pnp-popover-panel="'#budget-range-panel' + sentenceId"
                             data-pnp-popover-type="inline"
                             :key="sentenceId">

                            <span class="option-name">
                                Price: {{ this.$store.state.user.user.selected_currency }}
                                {{ formatPrice }}
                            </span>

                            <!--<i v-if="dirtyFormKeys['range'] || !checkBudgetRange" @click="clearBudget" class="ion-android-close"></i>-->
                        </div>

                        <!--<div class="option-tag -add-option"-->
                        <!--v-if="selectedLocations.length > 0 || dirtyFormKeys.location"-->
                        <!--:id="'srch-additional-location' + this.sentenceId"-->
                        <!--:data-pnp-popover-panel="'#srch-location-panel' + this.sentenceId"-->
                        <!--data-pnp-popover-type="inline"-->
                        <!--@click="extraLocation(true)"-->
                        <!--key="1">-->

                        <!--<i class="ion-android-add-circle"></i>-->

                        <!--<span class="option-name">Location</span>-->
                        <!--</div>-->

                        <!--<div v-for="(location, index) in selectedLocations" class="option-tag" :key="location.id">-->
                        <!--<span class="option-name" v-if="location">{{ location.name  }}</span>-->

                        <!--<i @click="deleteExtraLocation(location.id)" class="ion-android-close"></i>-->
                        <!--</div>-->
                    </transition-group>
                </template>
            </div>

            <button class="btn -yellow -ripple" v-if="!this.shouldSearch" v-on:click="goToSearch">
                <span>Search</span>
            </button>
        </div>

        <portal :to="this.portalTo">
            <search-location-popup :params="search.criteria.params"
                                   :options="search.criteria.options"
                                   :shouldSearch="this.shouldSearch"
                                   :sentenceId="this.sentenceId"
                                   :enableMultiSelection="sentenceHasMultipleLocations">
            </search-location-popup>

            <search-month-popup :params="search.criteria.params"
                                :options="search.criteria.options"
                                :shouldSearch="this.shouldSearch"
                                :sentenceId="this.sentenceId">
            </search-month-popup>

            <search-nights-popup :params="search.criteria.params"
                                 :shouldSearch="this.shouldSearch"
                                 :sentenceId="this.sentenceId">
            </search-nights-popup>

            <search-type-popup :params="search.criteria.params"
                               :shouldSearch="this.shouldSearch"
                               :sentenceId="this.sentenceId">
            </search-type-popup>

            <search-experience-popup :params="search.criteria.params"
                                     :options="search.criteria.options"
                                     :shouldSearch="this.shouldSearch"
                                     :sentenceId="this.sentenceId"
                                     :enableMultiSelection="sentenceHasMultipleExperiences">
            </search-experience-popup>

            <!--<search-budget-popup :params="search.criteria.params" :shouldSearch="this.shouldSearch" :sentenceId="this.sentenceId"></search-budget-popup>-->

            <search-budget-range-popup :params="search.criteria.params"
                                       :shouldSearch="this.shouldSearch"
                                       :sentenceId="this.sentenceId">
            </search-budget-range-popup>
        </portal>
    </section>
</template>
<script>
    import UrlMap from '../../../../global/UrlMap'
    import ButtonInternal from "../ButtonInternal";
    import { mapState } from 'vuex';
    import GlobalVars from '../../../../global/GlobalVars';
    import AtrStorage from '../../../../global/AtrStorage';

    export default {
        components: {ButtonInternal},
        props: {
            shouldSearch: {
                default: true,
                type: Boolean
            },
            sentenceType: {
                default: 'lodge',
                type: String
            },
            lockType: {
                default: false,
                type: Boolean
            },
            portalTo: {
                default: 'sentencePopups',
                type: String
            },
            searchState: {
                type: Object,
                required: true
            },
            sentenceId: {
                default: '',
                type: String
            }
        },
        data() {
            return {
                globals: window.globals,
                search: this.searchState,
                dirtyFormKeys: {
                    type: false,
                    nights: false,
                    location: false,
                    experience: false,
                    month: false,
                    budget: false,
                    range: false,
                    wishlist: false
                },
                loaded: true,
                showFirstTimePopup: false,
                haveSeen: true,
                priceRangeMode: null,
                tripBudget: null,
                lodgeLocationBudget: null
            };
        },
        methods:{
            sentenceTypeByKey(key){
                return GlobalVars.SENTENCE_KEY_VALUES(key);
            },
            refreshSearch() {
                if(this.shouldSearch) {
                    // if (this.search.results.data && this.search.results.data.length === 0) {
                    if (this.search.results.data) {

                        if (!new UrlMap().urlToSearchState(this.$route, this.$store)) {
                            let type = this.storeType ? this.storeType : this.sentenceType;

                            this.searchSetSearchType(type);
                        }
                        //this.setBudgetRange(this.storeType);
                    }
                } else {

                    this.searchSetSearchType(this.sentenceType);
                }
            },
            searchSentenceDirtyUpdateEvent(data){
                this.dirtyFormKeys[data.key] = data.isDirty;
            },
            searchSetSearchTypeEvent(searchType){
                this.searchSetSearchType(searchType);
            },
            searchSetSearchType(args){
                let searchType, preventSearch;

                if(typeof args === "string"){
                    searchType = args;
                }else if(typeof args === "object"){
                    searchType = args.type;
                    preventSearch = args.preventSearch;
                }

                //console.log('set search type from ' + this.storeType + ' to ' + searchType);

                // if(searchType === "trip"){
                //     this.setBudgetRange(searchType, true);
                //
                // }else if(this.storeType === "trip" && (searchType === 'lodge' || searchType === 'location')){
                //     this.setBudgetRange(searchType, true);
                // }

                if((this.$route.name === 'directory') && (searchType === 'lodge' || searchType === 'location')){

                    this.$store.commit(window.store.SET_SEARCH_TYPE, searchType);

                } else if(this.$route.name === 'wishlist' && searchType !== 'resource'){

                    this.$router.push({name: 'wishlist'});

                    window.bus.$emit('wishlistSearch');

                    this.$store.commit(window.store.SET_SEARCH_TYPE, searchType);

                } else if(!preventSearch){

                    if (this.shouldSearch) {
                        this.$store.commit(window.store.SET_SEARCH_TYPE, searchType);
                        this.$store.dispatch(window.store.FETCH_AND_SET_SEARCH_DEFAULTS, null, {root: true}).then(() => {
                            window.bus.$emit('searchDoSearch');
                        });
                    } else {
                        this.$store.dispatch(window.store.FETCH_AND_RETURN_SEARCH_DEFAULTS, null, {root: true}).then((result) => {
                            this.search.criteria.params.location = result.area;
                            this.search.criteria.params.experience = result.experience;
                            this.search.criteria.params.month = result.month;

                            this.$store.dispatch(window.store.FETCH_AND_RETURN_SEARCH_CRITERIA_OPTIONS, null, {root: true}).then((result) => {
                                this.search.criteria.options = result;
                            });
                        });
                    }
                }
            },
            goToSearch() {
                var queryParams = new UrlMap().searchStateToUrlParams(this.search);
                this.$router.push({
                    name: 'finder',
                    query: queryParams
                });
                new UrlMap().urlToSearchState(this.$route, this.$store);
            },
            setFirsTimePopupVisibility(show){
                this.showFirstTimePopup = show;
            },
            closeFirstTime(){
                this.$store.commit(window.store.SET_HAS_SEEN_SENTENCE, true);
                this.haveSeen = true;
                window.bus.$emit('setFirsTimePopupVisibility', false);
                $('body').removeClass('first-time-search');
            },
            numberToWord(n){
                let wordArr = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];

                if(n >= 0 && n <= 10){
                    return wordArr[n-1];
                }else{
                    return n + " not in range";
                }
            },
            wordToWords(s){
                if(s) {
                    let words = [];
                    let stringArr = s.split(' ');

                    if (stringArr.length > 1) {
                        for (let i = 0; i < stringArr.length; i++) {
                            let word = stringArr[i];

                            if (i < (stringArr.length - 1)) {
                                word += "&nbsp;"
                            }

                            words.push(word);
                        }

                        return words;
                    } else {
                        return [s];
                    }
                }

                return;
            }
        },
        computed: {
            // isDirty(key) {
            //     return typeof this.dirtyFormKeys[key] != 'undefined' && this.dirtyFormKeys[key] == true;
            // },
            selectedLocations(){
                return this.$store.state.search.criteria.params.locations;
            },
            checkBudgetRange(){
                if(this.storeType === 'trip') {
                    return this.tripBudgetFrom === 0 && this.tripBudgetTo === 10000;
                }else{
                    return this.budgetFrom <= 100 && this.budgetTo >= 5000;
                }
            },
            formatPrice(){
                if(!this.rates){
                    return;
                } else {
                    let rate = this.rates[this.selected] || 1;
                    let budgetFrom, budgetTo;

                    if(this.storeType === 'trip'){
                        budgetFrom = Math.round(this.tripBudgetFrom * rate);
                        budgetTo = Math.round(this.tripBudgetTo * rate);

                    }else{
                        budgetFrom = Math.round(this.budgetFrom * rate);
                        budgetTo = Math.round(this.budgetTo * rate);
                    }

                    return `${budgetFrom.toLocaleString()} - ${budgetTo.toLocaleString()}`
                }
            },
            sentenceHasMultipleLocations(){
                return this.storeType === 'trip';
            },
            sentenceHasMultipleExperiences(){
                return this.storeType === 'trip';
            },
            locationParts(){
                if(this.sentenceHasMultipleLocations){
                    let words = [];
                    let locations = this.$store.state.search.criteria.params.locations;

                    if(locations.length === 1){
                        return this.wordToWords(locations[0].name);

                    }else if (locations.length === 2){
                        let location1 = this.wordToWords(locations[0].name);
                        let location2 = this.wordToWords(locations[1].name);

                        return [...location1, "&nbsp;and&nbsp;", ...location2];

                    }else if (locations.length === 3){
                        let location1 = this.wordToWords(locations[0].name);
                        let location2 = this.wordToWords(locations[1].name);
                        let location3 = this.wordToWords(locations[2].name);

                        return [...location1, ",&nbsp;", ...location2, "&nbsp;and&nbsp;", ...location3];
                    }

                    return words;
                }else{
                    return this.wordToWords(this.$store.state.search.criteria.params.location.name);
                }
            },
            experienceParts(){
                if(this.sentenceHasMultipleExperiences){
                    let words = [];
                    let experiences = this.$store.state.search.criteria.params.experiences;

                    if(experiences.length === 1){
                        return this.wordToWords(experiences[0].name);

                    }else if (experiences.length === 2){
                        let experience1 = this.wordToWords(experiences[0].name);
                        let experience2 = this.wordToWords(experiences[1].name);

                        return [...experience1, "&nbsp;and&nbsp;", ...experience2];

                    }else if (experiences.length === 3){
                        let experience1 = this.wordToWords(experiences[0].name);
                        let experience2 = this.wordToWords(experiences[1].name);
                        let experience3 = this.wordToWords(experiences[2].name);

                        return [...experience1, ",&nbsp;", ...experience2, "&nbsp;and&nbsp;", ...experience3];
                    }

                    return words;
                }else{
                    return this.wordToWords(this.$store.state.search.criteria.params.experience.name);
                }
            },
            ...mapState({
                budgetFrom: state => state.search.criteria.params.budgetFrom,
                budgetTo: state => state.search.criteria.params.budgetTo,
                tripBudgetFrom: state => state.search.criteria.params.tripBudgetFrom,
                tripBudgetTo: state => state.search.criteria.params.tripBudgetTo,
                storeType: state => state.search.criteria.params.type,
                rates: state => state.user.user.rates,
                selected: state => state.user.user.selected_currency,
                user: state => state.user.user
            })
        },
        mounted(){
            this.$store.dispatch(window.store.FETCH_HAS_SEEN_SENTENCE, this.$store.state.user);

            if(this.$route.name !== 'directory' && this.$route.name !== 'wishlist') {
                this.haveSeen = this.user.hasSeenSentence;
                if (this.haveSeen === false) {
                    $('body').addClass('first-time-search');
                    window.bus.$emit('setFirsTimePopupVisibility', true);
                } else {
                    this.$store.commit(window.store.SET_HAS_SEEN_SENTENCE, true);
                }
            }
        },
        created() {
            window.bus.$on('searchSentenceDirtyUpdate', this.searchSentenceDirtyUpdateEvent);
            window.bus.$on('searchSetSearchType', this.searchSetSearchTypeEvent);
            window.bus.$on('searchRefresh', this.refreshSearch);
            //window.bus.$on('searchSetBudgetRange', this.refreshSearch);
            window.bus.$on('setFirsTimePopupVisibility', this.setFirsTimePopupVisibility);
            // window.bus.$on('doingSearch', this.updateBudgetRange);

            this.refreshSearch();

            //Note the below is now called in PageReady.js
            // this.$store.dispatch(window.store.FETCH_AND_SET_USER, this.user);
        },
        destroyed() {
            window.bus.$off('searchSentenceDirtyUpdate', this.searchSentenceDirtyUpdateEvent);
            window.bus.$off('searchSetSearchType', this.searchSetSearchTypeEvent);
            window.bus.$off('searchRefresh', this.refreshSearch);
            // window.bus.$off('searchSetBudgetRange', this.refreshSearch);
            // window.bus.$off('doingSearch', this.updateBudgetRange);
        }
    }
</script>
