<template>
    <div class="directory-search">
        <div class="count">
            <button class="mobile-search-button" @click="openSearch();">
                <i class="ion-android-search"></i>
            </button>
            <div class="input-container" v-show="!isMobile || searchShow">
                <button type="button" class="close-search-container" @click="$refs['input'].blur(); searchShow = false;">Close</button>
                <transition name="slide-in">
                    <input type="text"
                           ref="input"
                           placeholder="Search"
                           :class="{'-active': showResults, '-has-search-term': searchTerm}"
                           v-model="searchTerm"
                           value=""
                           @focus="setFocus(true)"
                           @blur="setFocus(false)"
                           @keyup="throttledSearchSuggest"
                           @keydown.esc="clearSearch"
                           @keydown.down.prevent="incrementIndex"
                           @keydown.up.prevent="decrementIndex"
                           @keydown.enter.prevent="enterSearch"
                    >
                </transition>

                <!-- <div class="icon" @click="toggleSearch"></div> -->

                <transition name="slide-down">
                    <div class="results" v-if="isDropdownOpen" :class="{'-border-full': results.length > 0}" @keyup='incrementIndex' @mouseenter="unlockScroll">
                        <div class="result-list">
                            <div class="result-item"
                                 v-for="(result, index) in results"
                                 :ref="'result' + result.id"
                                 :class="{'-active': index == currentResultIndex}"
                                 @click="resultSelected(result)"
                                 @mouseover="setIndex(index, result)"
                            >

                                {{result.title|filterWords}}

                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import GlobalVars from "@/global/GlobalVars";

    export default {
        props: {
            restrictToType: {
                default: GlobalVars.LODGE + ',' + GlobalVars.LOCATION
            },
        },
        data(){
            return {
                searchTerm: '',
                oldSearchTerm: '',
                isFocused: false,
                showSearch: false,
                showResults: false,
                results: [],
                idList: [],
                searchResult: {},
                focusResultId: null,
                currentResultIndex: 0,
                scrollTop: 50,
                loading: false,
                searchShow: false,
                isMobile: false,
            }
        },
        methods: {
            setFocus(value) {
                let name = this.$route.name;
                setTimeout(() => {
                    
                    this.isFocused = false;
                    this.searchTerm = '';
                    
                    if(value){
                        if(name && name.includes('location') || name.includes('lodge') || name.includes('home')){
                            pnpHelper.scroll.offset();
                        }
                        pnpHelper.scroll.lock()
                    } else {
                        pnpHelper.scroll.unlock()
                        if(name && name.includes('location') || name.includes('lodge') || name.includes('home')){
                            pnpHelper.scroll.resetOffset();
                        }
                    }

                }, 500);
            },
            openSearch(){
                this.showSearch = true;
                this.searchShow = true;
                // this.$nextTick(() => this.$refs.input.focus())
            },
            resultSelected(result) {
                this.searchTerm = '';
                this.showResults = false;
                this.searchShow = false;
                this.results = [];
                if ($('body').hasClass('page-finder') && (result.type == GlobalVars.LOCATION || result.type == GlobalVars.LODGE)) {
                    pnpHelper.map.hidePopoverInstant();
                    this.toggleSearch();
                    window.bus.$emit('smallGlobalSearch', result);
                } else {

                    this.loading = false;

                    this.$router.push({
                        name: GlobalVars.SINGLE_URL(result.type),
                        params: result.vue_route_params.data
                    });
                }
            },
            clearSearch(){
                this.searchTerm = '';
                this.showResults = false;
                this.results = [];
                this.scrollTop = 50;
            },
            setIndex(index){
                this.currentResultIndex = index;
            },
            incrementIndex () {
                // Going down
                if(this.currentResultIndex < (this.results.length-1)){
                    this.currentResultIndex++;
                    this.searchTerm = this.results[this.currentResultIndex].title;
                    this.focusResultId = this.results[this.currentResultIndex].id;
                }

                if(this.currentResultIndex > 6){
                    $('.results').scrollTop(this.scrollTop);
                    this.scrollTop += 42;
                }
            },
            decrementIndex () {
                // Going up
                if(this.currentResultIndex > 0){
                    this.currentResultIndex--;
                    this.searchTerm = this.results[this.currentResultIndex].title;
                    this.focusResultId = this.results[this.currentResultIndex].id;

                    if(this.scrollTop > 40){
                        $('.results').scrollTop(this.scrollTop);

                        this.scrollTop -= 42;
                    } else {
                        this.scrollTop = 40;
                    }
                }
            },
            enterSearch($event){

                let id = 'result' + this.focusResultId;

                $(this.$refs[id]).click();

                $($event.target).blur();

                setTimeout(()=>{
                    $($event.target).blur();
                }, 500)

                let result = this.results[this.currentResultIndex];

                this.resultSelected(result);
            },
            toggleSearch(){
                this.showSearch = this.showSearch ? false : true;

                if(!this.showSearch) {
                    this.clearSearch();
                } else {
                    setTimeout(() => {
                        this.$nextTick(() => this.$refs.input.focus())
                    }, 200);
                }

            },
            searchSuggest(newTerm){
                this.oldSearchTerm = newTerm;
                this.currentResultIndex = 0;

                if(this.searchTerm.length > 2) {
                    this.showResults = true;
                    this.submitSearch(newTerm);
                    this.oldSearchTerm = "";
                } else if (this.searchTerm.length <= 2) {

                    //setTimeout(() => {
                        this.$store.commit(window.store.CLEAR_DIRECTORY_SEARCH_RESULTS);
                        this.showResults = false;
                        this.results = [];

                    //}, 200);
                }
            },
            throttledSearchSuggest: _.debounce(function(e) {
                if(e.keyCode == 40 || e.keyCode == 38){
                    return;
                }

                //IF length different run search or not equal to prev search term
                if(this.oldSearchTerm.length !== this.searchTerm.length || this.searchTerm.localeCompare(this.oldSearchTerm) !== 0) {
                    this.searchSuggest(e.target.value);
                }

                if(this.searchTerm.length === 0){
                    this.dirtyInput = false;
                    $('body').removeClass('-global-search-results');
                }
            }, 300),
            submitSearch(){
                this.loading = true;
                this.$store.dispatch(window.store.CLEAR_AND_RETURN_DIRECTORY_SEARCH_RESULTS).then(() => {
                    this.showResults = false;
                    return this.$store.dispatch(window.store.SET_AND_FETCH_DIRECTORY_SEARCH_RESULTS, {
                            searchTerm: this.searchTerm,
                            restrictToType: this.restrictToType
                        })
                        .then((response) => {
                            this.loading = false;
                            this.results = response;
                            this.showResults = true;
                            this.unlockScroll();

                        });
                });
            },
            unlockScroll(){
                if(this.results.length > 6){
                    pnpHelper.scroll.unlock();
                } else {
                    pnpHelper.scroll.lock();
                }
            },
        },
        computed: {
            Vue() {
                return Vue;
            },
            isDropdownOpen() {
                return (this.showResults || this.loading);
            },
        },
        created(){
            window.bus.$on('clear-map-search', this.clearSearch);
        },
        mounted() {
            this.isMobile = window.outerWidth < 768;
            let prevWidth = window.outerWidth;
            window.addEventListener('resize', () => {
                if(prevWidth !== window.outerWidth){
                    prevWidth = window.outerWidth;
                    this.isMobile = window.outerWidth < 768;

                    if(this.isMobile){
                        this.searchShow = false;
                        this.showResults = false;
                        this.results = [];
                    }
                }
                
            });

            // Vue js Click event handler to close .results if click outside of .directory-search
            // document.addEventListener('click', e => {
            //     if(e.target.closest('.directory-search') === null) {
            //         this.showResults = false;
            //         this.searchShow = false;
            //         this.results = [];
            //     }
            // })
            
        },  
        destroyed() {
            window.bus.$off('clear-map-search', this.clearSearch);
        }
    }
</script>
