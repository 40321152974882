<template>
    <div class="pnp-popover srch-panel -compact -nights vh-fix-xs" :id="'srch-nights-panel' + this.sentenceId">
        <mob-popup-header text="SELECT NIGHTS"></mob-popup-header>

        <h2 class="panel-header heading2 ">
            <span class="hidden-xs">Number of nights</span>
        </h2>

        <div class="panel-results">
            <div class="night-container">
                <a v-for="index in 41" class="night" v-if="index >= 7" :class="{'-on': duration === index}" @click="setDuration(index)">
                    <span>{{index}}</span>
                </a>
            </div>

            <p>Not including any nights needed in transit</p>
        </div>
    </div>
</template>
<script>
    import UrlMap from '../../../global/UrlMap';
    import router from '../../../router';
    export default {
        props: {
            params: {
                type: Object,
            },
            shouldSearch: {
                default: true,
                type: Boolean
            },
            sentenceId: {
                default: '',
                type: String
            }
        },
        // data() {
        //     return {
        //         duration: null
        //     };
        // },
        methods:{
            setDuration(duration) {
                duration = parseInt(duration);

                // window.bus.$emit('searchSentenceDirtyUpdate', {
                //     isDirty: true,
                //     key: 'nights'
                // });

                if(this.shouldSearch) {
                    this.$store.commit(window.store.SET_SEARCH_DURATION, duration, {root: true});
                    var queryParams = window._.defaultsDeep({duration: duration}, new UrlMap().searchStateToUrlParams(this.$store.state.search));
                    router.push({name: 'finder', query: queryParams});
                    window.bus.$emit('searchDoSearch');
                } else {
                    this.params.duration = duration;
                }
            }
        },
        computed: {
            duration(){
                return this.params.duration;
            }
        }
    }
</script>