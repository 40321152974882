<template>
    <div class="component-image -large-caption pnp-slide"
         :data-pnp-parent-section="section_id"
         :data-pnp-hidden="hidden"
         :data-block-id="_id"
         v-show="hidden === 0"
         v-if="!isOldDevice"
        >
        <vignette-presentational :data_heading="section_name"
                                 :lazy_load="false"
                                 :img_url="img_url"
                                 :bg_img_url_mobile="bg_img_url_large"
                                 :alt_text="getAltText"
                                 css_class="lazy-slide">
        </vignette-presentational>

        <div class="caption" style="opacity: 1 !important;">
            <p class="sub-heading pnp-slide-caption"
               v-html="content"
               :data-block-id="_id" v-if="edit === false"
               :contenteditable="editing"></p>
            <div class="on-page-editor-container heading-editor" v-else>
                <p class="sub-heading pnp-slide-caption"
                   v-html="content"
                   :data-block-id="_id"
                   :contenteditable="editing"></p>
                <div class="smallEditButtons">
                    <div v-on:click="createEditor()" class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton"></div>
                    <div v-on:click="saveEditor()" class="icon ion-ios-checkmark onPageEditor-smallButton btn-success smallSaveButton" v-if="editing==true"></div>
                    <div v-on:click="cancelEditor()" class="icon ion-ios-close onPageEditor-smallButton btn-danger smallCancelButton" v-if="editing==true"></div>
                </div>
            </div>

            <div class="buttons">
                <div class="on-page-editor-container" v-if="edit===true">
                    <buttons-loop :firstVideo="firstVideo" :buttons="buttons" :type="type" :edit="edit" :section_id="section_id" :slide_id="_id"></buttons-loop>
                    <div class="smallEditButtons">
                        <div data-toggle="modal" :data-target="'#buttonModal_' + _id"
                             class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton addEditButton"
                             :data-block-id="_id"></div>
                    </div>
                </div>
                <buttons-loop :firstVideo="firstVideo" :buttons="buttons" v-else :type="type" :edit="edit" :section_id="section_id" :slide_id="_id"></buttons-loop>
            </div>
        </div>
        <template v-for="action in actions">
            <modal-presentational
                    v-if="action.type != 'seasonality'"
                    :div_id="action.action"
                    :key="action.action"
                    :article_editor="true"
                    :data_key="action.media"
                    :for_section_id="_id">
                <article-presentational
                        :title="action.article.title"
                        :content="action.article.content"
                        class="row info-window"
                        :_id="action.action"
                        :media_key="action.media"
                        :type="type"
                        :edit="edit"
                        :slide_id="_id"
                        :section_id="section_id"
                >
                </article-presentational>
            </modal-presentational>
            <!--<modal-presentational v-else-->
                                  <!--:div_id="action.action"-->
                                  <!--:key="action.action"-->
                                  <!--:article_editor="true"-->
                                  <!--:data_key="action.media"-->
                                  <!--:for_section_id="_id">-->
                <!--<public-seasonality></public-seasonality>-->
            <!--</modal-presentational>-->
        </template>
    </div>
</template>

<script>
    import StateUtils from "../../../global/StateUtils";

    export default {
        props: {
            'section_id': {type: String, required: true},
            'hidden': {type: Number, required: true},
            '_id': {type: String, required: true},
            'section_name': {type: String, required: true},
            'lazy_load': {type: Boolean, required: true},
            'img_url': {type: String, required: true},
            'content': {type: String, required: true},
            'buttons': {type: Array, required: true},
            'edit': {type: Boolean, required: true},
            'type': {type: String, required: true},
            'actions': {type: Array, required: true},
            'item_title': {type: String, required: true},
            'slide_index' : {type: Number, required: true},
            'bg_img_url_large' : {type: String, required: false},
            'firstVideo' : {}
        },
        data() {
            return {
                editing: false,
                editorName: null,
                foo: 'bar'
            }
        },
        computed: {
            getAltText(){
                return this.item_title + ', ' + this.section_name + ' Image ' + (this.slide_index + 1);
            },
            isOldDevice(){
                return window.globals.oldDevice;
            }
        },
        methods: {
            createEditor() {
                if(!window._.isUndefined(this.editorName) && this.editorName!==null){
                    alert('You are currently editing a different bit on the page.\nSave or Cancel it before starting editing on a new instance.');
                } else {
                    this.editing = true;
                    Vue.nextTick(() => {
                        var editor = CKEDITOR.inline(this.$el.querySelector('.on-page-editor-container .sub-heading'), window.ckeditorConfig);
                        this.$el.querySelector('.on-page-editor-container .sub-heading').click();
                        this.editorName = editor.name;
                        this.$store.commit(window.store.SET_EDITOR_CKEDITOR, true, {root: true});
                    });
                }
            },
            cancelEditor() {
                this.editing = false;
                if (this.editorName !==  null) {
                    CKEDITOR.instances[this.editorName].container.$.innerHTML = this.content;
                    CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;

                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, { root: true });
                }
            },
            saveEditor() {
                this.editing = false;
                if (this.editorName !== null) {
                    let stateObject = new StateUtils(this.type, this.$store).getStateObject();
                    let newContent = CKEDITOR.instances[this.editorName].container.$.innerHTML;
                    if(stateObject!==null) {
                        for (var x in stateObject.allSections.data) {
                            if (stateObject.allSections.data[x].id == this.section_id) {
                                for (var y in stateObject.allSections.data[x].allSlides.data) {
                                    if (stateObject.allSections.data[x].allSlides.data[y].id == this._id) {
                                        stateObject.allSections.data[x].allSlides.data[y].content = newContent;
                                    }
                                }
                            }
                        }
                        new StateUtils(this.type, this.$store).setStateObject(stateObject, null);
                    }
                    CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;

                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, { root: true });
                }
            }
        }
    }
</script>