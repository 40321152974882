<template>
    <form
      :id="id"
      class="multi-step-form"
      :name="name"
      @submit="postForm"
    >
    
      <div
        v-if="!noHeader"
        class="form-header"
      >

      
        <slot
          name="header" 
          :progress="progress"
          :step="currentStep"
          :id="headerId"
        />
      </div>

      <slot
        name="messages"
      />
      
  
      <div
        v-for="(step,index) in stepData"
        :key="index"
      >
      
        <div
          v-show="index === stepIndex" 
          class="vue-form-step"
          :class="index === stepIndex ? 'current' : ''"
          :key="stepIndex"
        >
          <slot
            :id="'step'+index"
            :name="'step'+index"
            :currentStep="thisStep(index)"
            :stepIndex="stepIndex"
          />
        </div>

      </div>
  
      <div
        v-if="!noFooter"
        class="form-footer"
      >
        <slot
          name="footer"
          :isFirstStep="isFirstStep"
          :isLastStep="isLastStep"
          :currentStep="currentStep"
          :stepIndex="stepIndex"
        >
          <button
            type="button"
            class="btn btn-md btn-back"
            @click="prevStep"
          >
            {{ 'Back' }}
          </button>
  
          <div class="next-container">
            <button
              :type="currentStep.submit_form ? 'submit' : 'button'"
              class="btn btn-md btn-next"
              @click="nextStep"
            >
              {{ isLastStep ? 'End' : (currentStep.submit_form ? 'Submit' : 'Next') }}
            </button>
          </div>
          
        </slot>
      </div>
    </form>
  </template>
  <style>
  .form-footer .btn
  .form-footer .btn.btn-back{
    background-color: #696969;
  }

  .form-footer .btn.btn-next{
    background-color: #F7BE16;
    color: #fff;
  }

  .next-container{
    &:only-child{
      flex: 1 0 0%;
      display:flex;
      justify-content: flex-end;
    }
  }

  </style>
  <script>
  export default {
      props:{
          id: { 
              type: String, 
              default: 'vue_multi_step_form',
                required: false,
          },
          name: { 
              type: String, 
              default: 'vue_multi_step_form',
              required: false,
          },
          action: { 
              type: String, 
              default: '',
                required: false,
          },
          method: { 
              type: String, 
              default: 'POST',
                required: false,
          },
          stepData: { 
              type: Array, 
              default: () => [],
                required: true, 
          },
          noHeader: { 
              type: Boolean, 
              default: false,
                required: false,
          },
          noFooter: { 
              type: Boolean, 
              default: false,
                required: false,
          }
      },
      data(){
          return{
              stepIndex:0,
              currentStepIndex: 1,
              emitFunctions:[],
              errors: [],
          };
      },
      computed:{
          totalSteps(){
              return this.stepData.filter(el => el.step_skip === false).length;
          },
          currentStep(){
              let basic_step_data = {
                  show_back_button: false,
                  show_next_button: false,
                  step_valid: true,
                  step_skip: false,
                  last_step: false,
                  hs_submit: false,
                  submit_form: false,
              };
              return this.stepData[this.stepIndex] 
                  ? Object.assign(basic_step_data,this.stepData[this.stepIndex]) 
                  : basic_step_data;
          },
          isFirstStep(){
              if(!this.stepData.length) return false;
              else if(!this.stepData[0].stepskip && this.stepIndex!==0) return false;
              else if(!this.stepData[0].stepskip && this.stepIndex===0) return true;
              return this.stepIndex === this.stepData.findIndex(el=>el.stepskip===false);
          },
          isLastStep(){
              // if(this.stepIndex === (this.totalSteps-1)) return true;
        
              return (this.currentStep && this.currentStep.last_step) ? this.currentStep.last_step : false;
          },
          progress(){
              if(this.stepIndex == 0){
                return 0;
              }

              return ((this.currentStepIndex)/(this.totalSteps))*100;
          },
          headerId() {
              return this.stepData[this.stepIndex].step_title.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase().slice(0, -1);
          }
      },
      watch:{
          stepData(val){
              if(val.length){
                  let i=0;
                  while(val[i].stepskip === true){
                      this.stepIndex++;
                      i++;
                  }
                  val[i].backbutton = false;
              }
          },
      },
      methods:{
          nextStep(){
              if(!this.currentStep.step_valid){
                  this.$emit('validateStep', this.stepIndex);
                  return false;
              }
              if(this.currentStep.hs_submit){
                  this.submitForm();
              }
              if(this.currentStep.submit_form){
                  this.$emit('finalSubmit');
              }
              if(this.isLastStep){
                  this.$emit('onComplete');
                  return false;
              }
              this.stepIndex++;
              this.currentStepIndex++;
              while(this.currentStep.step_skip === true){
                  this.stepIndex++;
              }

              this.$emit('onNext');
          },
          prevStep(){
              if(this.isFirstStep){
                  window.history.go(-1); return false;
              }
              this.stepIndex--;
              this.currentStepIndex--;
              while(this.currentStep.step_skip===true){
                  this.stepIndex--;
              }
          },
          submitForm(){
              this.$emit('hsSubmit');
          },
          postForm(e){
                console.log('post form');
                e.preventDefault();
                window.GoogleAnalytics.fireEvent('Quote', 'Send Request', this.$route.path);
          },
          thisStep(index){
              return {index, ...this.stepData[index]};
          }
      }
  };
  </script>