<template>
    <div class="inner" :class="classes">
        <h2 class="heading3 heading" v-html="title" :contenteditable="editing.title" v-if="edit === false"></h2>
        <div class="on-page-editor-container" v-else>
            <h2 class="heading3 heading" v-html="title" :contenteditable="editing.title" v-if="edit === true"></h2>
            <div class="smallEditButtons -horizontal" v-bind:class="{ '-editing': editing.title }">
                <div v-on:click="createEditor('heading3')" class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton"></div>
                <div v-on:click="saveEditor('heading3')" class="icon ion-ios-checkmark onPageEditor-smallButton btn-success smallSaveButton" v-if="editing.title==true"></div>
                <div v-on:click="cancelEditor('heading3')" class="icon ion-ios-close onPageEditor-smallButton btn-danger smallCancelButton" v-if="editing.title==true"></div>
            </div>
        </div>
        <hr>
        <div class="editor-content" v-filter-content :contenteditable="editing.content" v-if="edit === false">
            <shortcodes :content="content" />
        </div>
        <div class="on-page-editor-container" v-else>
            <div class="editor-content" v-filter-content v-html="content" v-show="true" :contenteditable="editing.content" v-if="edit === true"></div>
            <div class="smallEditButtons -horizontal" v-bind:class="{ '-editing': editing.content }">
                <div v-on:click="createEditor('editor-content')" class="icon ion-android-create onPageEditor-smallButton btn-info smallEditButton"></div>
                <div v-on:click="saveEditor('editor-content')" class="icon ion-ios-checkmark onPageEditor-smallButton btn-success smallSaveButton"
                     v-if="editing.content==true"></div>
                <div v-on:click="cancelEditor('editor-content')" class="icon ion-ios-close onPageEditor-smallButton btn-danger smallCancelButton"
                     v-if="editing.content==true"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import StateUtils from '../../../global/StateUtils';
    import Shortcodes from "./Shortcodes";

    export default {
        components: {Shortcodes},
        props: ['title', 'content', 'type', 'edit', 'classes', '_id', 'media_key', 'slide_id', 'section_id'],
        data() {
            return {
                editing: {
                    title: false,
                    content: false
                },
                editorName: null
            }
        },
        methods: {
            createEditor(which) {
                if(!window._.isUndefined(this.editorName) && this.editorName!==null){
                    alert('You are currently editing a different bit on the page.\nSave or Cancel it before starting editing on a new instance.');
                } else {
                    if (which == 'heading3') {
                        this.editing.title = true;
                        this.editing.content = false;
                    } else {
                        this.editing.title = false;
                        this.editing.content = true;
                    }
                    Vue.nextTick(() => {
                        var editor = CKEDITOR.inline(this.$el.querySelector('.on-page-editor-container .' + which), window.ckeditorConfig);
                        this.$el.querySelector('.on-page-editor-container .' + which).click();
                        this.editorName = editor.name;
                        this.$store.commit(window.store.SET_EDITOR_CKEDITOR, true, { root: true });
                    });
                }
            },
            cancelEditor(which) {
                this.editing.title = false;
                this.editing.content = false;
                if (this.editorName !== null) {
                    if (which == 'heading3') {
                        CKEDITOR.instances[this.editorName].container.$.innerHTML = this.title;
                    } else {
                        CKEDITOR.instances[this.editorName].container.$.innerHTML = this.content;
                    }
                    CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;
                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, { root: true });
                }
            },
            saveEditor(which) {
                this.editing.title = false;
                this.editing.content = false;

                var stateObject = new StateUtils(this.type, this.$store).getStateObject();

                if (this.editorName !== null) {
                    let newContent = CKEDITOR.instances[this.editorName].container.$.innerHTML;
                    if (this.type === 'resource_article' || this.type === 'multi_re_article') {
                        if (which === 'heading3') {
                            stateObject.article.title = newContent;
                        } else {
                            stateObject.article.content = newContent;
                        }
                    } else {
                        if (stateObject.allSections) {
                            for (var x in stateObject.allSections.data) {
                                for(let xy in stateObject.allSections.data){
                                    for (let t in stateObject.allSections.data[xy].actions) {
                                        if (stateObject.allSections.data[xy].actions[t].media === this.media_key) {
                                            if (which === 'heading3') {
                                                stateObject.allSections.data[xy].actions[t].article.title = newContent;
                                            } else {
                                                stateObject.allSections.data[xy].actions[t].article.content = newContent;
                                            }
                                        }
                                    }
                                    if(!window._.isUndefined(stateObject.allSections.data[xy].allSlides)) {
                                        for (let y in stateObject.allSections.data[xy].allSlides.data) {
                                            for (let t in stateObject.allSections.data[xy].allSlides.data[y].actions) {
                                                if (stateObject.allSections.data[xy].allSlides.data[y].actions[t].media === this.media_key) {
                                                    if (which === 'heading3') {
                                                        stateObject.allSections.data[xy].allSlides.data[y].actions[t].article.title = newContent;
                                                    } else {
                                                        stateObject.allSections.data[xy].allSlides.data[y].actions[t].article.content = newContent;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        if(stateObject.articleLinks){
                            for(let t in stateObject.articleLinks.data){
                                if(stateObject.articleLinks.data[t].article_id === this.media_key){
                                    if (which === 'heading3') {
                                        stateObject.articleLinks.data[t].title = newContent;
                                    } else {
                                        stateObject.articleLinks.data[t].content = newContent;
                                    }
                                }
                            }
                        }
                    }
                    new StateUtils(this.type, this.$store).setStateObject(stateObject, null);
                    CKEDITOR.instances[this.editorName].destroy();
                    this.editorName = null;
                    this.$store.commit(window.store.SET_EDITOR_CKEDITOR, false, { root: true });
                }

            }
        }
    }
</script>
