<template>
    <div class="container-fluid container-100">
        <div class="row">
            <b-form-fieldset horizontal label="Per Page" class="col-6" :label-size="'6'">
                <b-form-select :options="[{text:25,value:25},{text:50,value:50},{text:100,value:100}]" v-model="pagination.per_page"></b-form-select>
            </b-form-fieldset>
            <b-form-fieldset horizontal label="Filter" class="col-6" :label-size="'2'">
                <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            </b-form-fieldset>
            <b-form-fieldset horizontal label="Type" class="col-6" :label-size="'2'" v-if="this.seoTypes.length > 0">
                <b-form-select :options="this.seoTypes" v-model="resource_type" placeholder="Select Resource Type"></b-form-select>
            </b-form-fieldset>
        </div>
        <div class="row">
            <div class="col-md-12">
                <b-alert v-bind:variant="globalAlertVariant" dismissible :show="globalAlert" @dismissed="globalAlert=false">
                    {{ globalAlertMsg }}
                </b-alert>
            </div>
        </div>
        <div class="row">
            <b-table id="itemsTable" ref="itemsTable" :items="itemsProvider" :fields="fields" :current-page="pagination.current_page" :per-page="pagination.per_page" :filter="filter"
                     :busy.sync="isBusy"
                     show-empty striped hover bordered outline dark foot-clone>
                <template slot-scope="urlPart">
                    {{ item.value }}
                </template>
                <template slot-scope="clientUrl">
                    {{ item.value }}
                </template>
                <template slot-scope="serverUrl">
                    {{ item.value }}
                </template>
                <template slot-scope="resourceId">
                    {{ item.value }}
                </template>
                <template slot-scope="created">
                    {{ item.value }}
                </template>
                <template slot-scope="updated">
                    {{ item.value }}
                </template>
                <template>
                    <a :href="item.editUrl" class="btn btn-sm btn-primary" target="_blank">Edit</a>
                </template>
            </b-table>
        </div>
        <div class="justify-content-center row mt-3 mb-1">
            <b-pagination size="md" variant="success" :total-rows="pagination.total" :per-page="pagination.per_page" v-model="pagination.current_page"/>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isBusy: false,
                items: [],
                fields: {
                    serverUrl: {
                        label: 'Server URL',
                        sortable: true
                    },
                    urlPart: {
                        label: 'Local URL',
                        sortable: true,
                    },
                    clientUrl: {
                        label: 'Client URL',
                        sortable: true
                    },
                    resourceId: {
                        label: 'Resource Id',
                        sortable: true
                    },
                    created: {
                        label: 'Created',
                        sortable: true
                    },
                    updated: {
                        label: 'Updated',
                        sortable: true
                    },
                    id: {
                        label: 'Options',
                        sortable: false,
                        class: 'action',
                        formatter: (value, key, item) => {
                            return '<a href="' + item.editUrl + '" class="btn btn-sm btn-primary" target="_blank">Edit</a>';
                        }
                    }
                },
                pagination: {
                    total: 0,
                    count: 0,
                    per_page: 25,
                    current_page: 1,
                    total_pages: 0
                },
                filter: null,
                resource_type: null,
                mode: 'view',
                item: false,
                alert: false,
                alertMsg: '',
                alertVariant: 'success',
                globalAlert: false,
                globalAlertMsg: '',
                globalAlertVariant: 'success',
                seoTypes: [],
            };
        },
        watch: {
            resource_type: function(){
                this.refreshTable();
            }
        },
        mounted() {
            this.getSeoTypes();
        },
        methods: {
            getSeoTypes(){
                let promise = axios.get(window.API_URL + 'acp/seo-types');
                promise.then((response) => {
                    this.seoTypes = response.data.data;
                });
            },
            itemsProvider(ctx) {
                let tableHelper = new BtableHelper(ctx);
                this.isBusy = true;

                let extraParams = tableHelper.getUrlParams();
                if(this.resource_type!=null) {
                    if (extraParams != '') {
                        extraParams+= '&seoType=' + this.resource_type;
                    } else {
                        extraParams= 'seoType=' + this.resource_type;
                    }
                }
                // console.log(extraParams);
                let promise = axios.get(window.API_URL + 'acp/url' + '?' + extraParams);

                let ofThis = this;

                return promise.then((response) => {
                    const items = response.data.data;
                    this.isBusy = false;
                    ofThis.items = items;
                    ofThis.pagination = response.data.meta.pagination;
                    return (items);
                });
            },
            showItemDetails(item) {
                this.mode = 'view';
                $('#modal').modal('show');

                this.item = item;
            },
            closeModal() {
                this.alert = false;
                this.item = false;
                $('#modal').modal('hide');
            },
            refreshTable() {
                this.$refs.itemsTable.refresh();
            }

        }
    }
</script>


<style lang="css">
    .container-100{
        width: 100%;
    }
</style>